<template>
    <div class="app-container">
        <el-row class="main-flex">
            <el-col :span="3" class="side-search" v-if="isopenShow">
                <operatorTreeVue @emitToParent='getTreeData' @isShow="isHidden"></operatorTreeVue>
            </el-col>

            <el-col v-if="!isopenShow" class="small-side">
                <div class="change-in" @click="isopenShow = !  isopenShow "></div>
            </el-col>

            <!-- 搜索区域 -->
            <el-col :span="21" class="mian-content">
                <div class="head-section">
                    <!--   <el-input placeholder="请从左侧选择运营商" v-model.trim="treeData.operatorNameTree"
                                  disabled></el-input>-->
                    <el-col :span="7">
                        <el-form :inline="true">
                            <el-form-item label="换电站名称：">
                                <el-input v-model.trim="stationValue" placeholder="请输入换电站名称"></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="7">
                        <el-form :inline="true" class="left-style">
                            <el-form-item label="换电站编码：">
                                <el-input v-model.trim="stationNo" placeholder="请输入换电站编码"></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="10">
                        <div class="btn-end">
                            <span class="btn-rest">
                                <el-button @click="restFunction" plain
                                           v-hasCode="['base:upgrade:query']">重置</el-button>
                            </span>
                            <span class="btn-submit">
                            <el-button @click="submitCheck" type="primary" plain
                                       v-hasCode="['base:upgrade:query']">查询</el-button>
                            </span>
                        </div>
                    </el-col>
                </div>
                <!--  表格部分-->
                <div class="section-main">
                    <el-col class="choose-mian">
                        <div class="section-title">
                            远程升级列表
                        </div>
                    </el-col>
                    <!--table-->
                    <el-col :span="24">
                        <el-row class="table-box">
                            <el-table ref="forceTable" id="statiNo" @selection-change="batchList" :data="tableData"
                                       :header-cell-style="headerStyleEvent"
                                      :cell-class-name="goodsInfo">
                                <el-table-column align="center" label="编号" :index="renIndex" type="index"
                                                 width="50"></el-table-column>
                                <el-table-column align="center" prop="operatorName" label="归属运营商"
                                                 :show-overflow-tooltip="true"
                                                 min-width="110"></el-table-column>
                                <el-table-column align="center" prop="stationNo" label="换电站编码"
                                                 min-width="110"></el-table-column>
                                <el-table-column align="center" prop="stationName" label="换电站名称"
                                                 :show-overflow-tooltip="true"
                                                 min-width="110"></el-table-column>
                                <el-table-column align="center" prop="stationSftVer" label="当前软件版本"
                                                 min-width="110"></el-table-column>

                                <el-table-column align="center" label="操作" width="180">
                                    <template slot-scope="scope">
                                            <span @click="compileForce(scope.row)"
                                                  class="edit-style" v-hasCode="['base:upgrade:detail']">
                                                版本详情
                                            </span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-row>
                    </el-col>

                    <!--pagination-->
                    <el-col :span="24">
                        <el-row class="pagin">
                            <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="query.pageNum"
                                :page-sizes="[10, 30, 50,100]"
                                :page-size="query.pageSize"
                                layout="total, sizes, prev, pager, next"
                                :total="total">
                            </el-pagination>
                        </el-row>
                    </el-col>
                </div>

            </el-col>
            <!-- <el-col :span="20" style="margin-top: 5px;margin-bottom: 5px">
                <el-col :span="5">
                    <el-row>
                        <el-button @click="submitCheck" type="primary" plain  icon="el-icon-search">查询</el-button>
                        <el-button @click="restFunction" plain icon="el-icon-refresh-right">重置</el-button>
                    </el-row>
                </el-col>
            </el-col> -->


        </el-row>


        <el-dialog
            :title="changeForce?'远程升级':'远程升级'"
            :visible.sync="isforce"
            width="50%"
            @close="close">
            <el-form size="middle" ref="diaForm" :model="diaForm" label-width="150px">
                <el-row>
                    <el-col :span="11">
                        <el-form-item label="换电站名称:" prop="stationName">
                            <el-input :disabled="true" v-model="diaForm.stationName"></el-input>
                        </el-form-item>
                        <el-form-item label="换电站编码:" prop="stationSn">
                            <el-input :disabled="true" v-model="diaForm.stationNo"></el-input>
                        </el-form-item>
                        <el-form-item label="归属运营商:" prop="operatorName">
                            <el-input :disabled="true" v-model="diaForm.operatorName"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="11">
                        <el-form-item label="当前版本号: " prop="principal">
                            <el-input :disabled="true" v-model="diaForm.stationSftVer"></el-input>
                        </el-form-item>
                        <el-form-item label="选择要升级的版本:" prop="sbmId">
                            <el-select v-model="diaForm.stationSftVer" placeholder="选择要升级的版本">
                                <el-option
                                    clearable
                                    v-for="item in versionList"
                                    :key="item.id"
                                    :label="`${item.versionName} - ${item.versionNo}`"
                                    :value="item.versionNo"
                                ></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="" prop="principal">
                            <el-button @click="upgrade" type="success" plain icon="el-icon-upload"
                                       v-hasCode="['base:upgrade:send']">
                                开始升级
                            </el-button>
                        </el-form-item>

                    </el-col>
                </el-row>
            </el-form>


            <el-row style="margin-top: 20px;">
                <el-table ref="diaTableData" id="upgradeNo" :data="diaTableData"
                          :header-cell-style="{'text-align':'center'}"
                          :cell-style="{'text-align':'center'}"
                          style="background-color: rgba(228, 235, 241, 1);">
                    <el-table-column label="编号" :index="renIndex" type="index" width="50"></el-table-column>
                    <el-table-column prop="stationNo" label="换电站编码" min-width="110"></el-table-column>
                    <el-table-column prop="stationName" label="换电站名称" :show-overflow-tooltip="true"
                                     min-width="110"></el-table-column>
                    <el-table-column prop="versionNoBefore" label="升级前版本号" min-width="110"></el-table-column>
                    <el-table-column prop="versionNo" label="升级后版本号" min-width="110"></el-table-column>
                    <el-table-column prop="status" label="升级状态" min-width="110"
                                     :formatter="statusFormat"></el-table-column>
                    <el-table-column prop="createTime" label="升级时间" min-width="140"></el-table-column>
                </el-table>
            </el-row>

            <el-col :span="20">
                <el-row>
                    <el-pagination
                        @size-change="diaHandleSizeChange"
                        @current-change="diaHandleCurrentChange"
                        :current-page="diaQuery.pageNum"
                        :page-sizes="[10, 30, 50,100]"
                        :page-size="diaQuery.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="diaTotal">
                    </el-pagination>
                </el-row>
            </el-col>

            <span slot="footer" class="dialog-footer">
        <el-button @click="isforce = false">取 消</el-button>
        <el-button type="primary" @click="newAddOrset">确 定</el-button>
      </span>
        </el-dialog>


    </div>
</template>

<script>
import '@/assets/font/font.css';
import {checkForce} from '@/utils/station';
import operatorTreeVue from "@/components/tree/operatorTree"
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {remoteUpgrade, stationOperatorVersionList, upgradeInfoPage, versionHistoryList} from "@/utils/versionManege";

export default {
    name: 'upgradeInfo',
    components: {operatorTreeVue},
    data() {
        return {
            isopenShow: true,
            stationValue: '',
            stationNo: "",
            query: {
                pageNum: 1,
                pageSize: 10,
            },
            changeForce: true,
            isforce: false,
            batchNum: [],
            tableData: [],
            total: 0,
            isPage: true,
            isdisabled: false,
            forceForm: {
                operatorName: "",
                stationNo: '',
                stationName: '',
                stationSftVer: "",
            },
            treeData: {
                operatorNameTree: "",
                operatorIdTree: null,
                operatorOptions: undefined,  //运营商树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
            },
            versionList: [],
            softVersionList: [],
            diaForm: {
                operatorName: "",
                stationNo: '',
                stationName: '',
                stationSftVer: "",
            },
            versionNoBefore: null,
            diaTableData: [],
            diaTotal: 0,
            diaQuery: {
                stationNo: "",
                pageNum: 1,
                pageSize: 10,
            },
        };

    },
    created() {
        this.getForce()
    },
    computed: {},
    methods: {
        headerStyleEvent({row, column, rowIndex, columnIndex}) {
            if (columnIndex == 1) {
                return 'padding:0px;'
            }
        },
        goodsInfo(row) {
            if (row.columnIndex == 1) {
                return 'goodsInfo'
            }
        },
        isHidden(message) {
            this.isopenShow = message
        },
        restFunction() {
            this.treeData.operatorNameTree = "";
            this.treeData.operatorIdTree = null;
            this.stationValue = ''
            this.stationNo = ''
            this.isPage = true
            this.query = {
                pageNum: 1,
                pageSize: 10,
            }
            this.getForce()
        },

        batchList(list) {
            this.batchNum = list
        },
        handleSizeChange(val) {
            this.query.pageSize = val
            this.getForce()
        },
        handleCurrentChange(val) {
            this.query.pageNum = val
            this.getForce()
        },

        renIndex(index) {
            return (this.query.pageNum - 1) * this.query.pageSize + index + 1
        },

        async getForce() {
            try {
                const data = {
                    page: this.query.pageNum,
                    size: this.query.pageSize
                }
                var url = "page=" + this.query.pageNum + "&size=" + this.query.pageSize;

                if (this.stationNo != '' && this.stationNo != null) {
                    url += "&stationNo=" + this.stationNo
                }
                if (this.stationValue != '' && this.stationValue != null) {
                    url += "&stationName=" + this.stationValue
                }
                if (this.treeData.operatorNameTree != "" && this.treeData.operatorNameTree != null) {
                    url += "&operatorId=" + this.treeData.operatorIdTree
                }
                data.url = url;

                const res = await checkForce(data)
                if (res.data.code !== 200) return this.$msg.error('获取场站基础信息失败!')
                let newList = res.data.data.list;
                this.tableData = newList;
                this.total = res.data.data.total;
            } catch (e) {
            }
        },

        async submitCheck() {
            try {
                this.query.pageNum = 1;
                this.getForce()
            } catch (e) {
                console.log(e);
            }
        },

        getTreeData(data) {
            this.treeData = data
        },

        newAddOrset() {
            this.versionNoBefore = null;
            //this.$msg.success('修改信息成功!');
        },

        diaHandleSizeChange(val) {
            this.diaQuery.pageSize = val
            this.getUpgradeInfoPage()
        },
        diaHandleCurrentChange(val) {
            this.diaQuery.pageNum = val
            this.getUpgradeInfoPage()
        },


        close() {
            this.diaForm = {
                operatorName: "",
                stationNo: '',
                stationName: '',
                stationSftVer: "",
            };
            this.versionNoBefore = null;
            this.diaTableData = [];
            this.diaTotal = 0;
            this.diaQuery = {
                stationNo: "",
                pageNum: 1,
                pageSize: 10,
            };
        },

        async compileForce(data) {
            this.diaTableData = [];
            this.isforce = true;
            this.diaForm = data;
            this.versionNoBefore = this.diaForm.stationSftVer;
            this.diaQuery.stationNo = data.stationNo;
            this.getVersionList();
            this.getUpgradeInfoPage();
        },

        async upgrade() {
            const version = this.diaForm.stationSftVer + "";

            const versionData = this.versionList.filter((item) => {
                return item.versionNo == version;
            })
            if (versionData == null || versionData == "" || versionData.length < 1) {
                return this.$msg.error('当前版本已失效，请重新选择版本号升级!')
            }
            const upgradeData = {};
            upgradeData.operatorId = this.diaForm.operatorId;
            upgradeData.operatorName = this.diaForm.operatorName;
            upgradeData.stationNo = this.diaForm.stationNo;
            upgradeData.stationName = this.diaForm.stationName;
            upgradeData.versionNo = version;
            upgradeData.versionNoBefore = this.versionNoBefore;
            upgradeData.upgradeWay = 1;
            upgradeData.status = 0;
            upgradeData.unionCode = versionData[0].unionCode;
            const result = await remoteUpgrade(upgradeData);
            if (result.data.code != 200) {
                return this.$msg.error('发送升级指令失败!' + result.data.msg)
            }
            this.$msg.success('发送升级指令成功!');
            this.getUpgradeInfoPage()
        },

        async getVersionList() {
            var operatorId = this.diaForm.operatorId;
            if (operatorId == null || operatorId == "") {
                return this.$msg.error('获取当前运营商信息失败!')
            }
            const result = await stationOperatorVersionList(operatorId);
            if (result.data.data.code == 200) {
                this.versionList = result.data.data.data;
            } else {
                return this.$msg.error('获取运营商历史版本失败!')
            }
        },

        statusFormat(row) {
            if (row.status === "0") {
                return "升级中";
            }
            if (row.status === "0x0B") {
                return "升级成功";
            }
            return "未知";
        },

        async getUpgradeInfoPage() {
            try {
                const data = {
                    page: this.diaQuery.pageNum,
                    size: this.diaQuery.pageSize
                }
                var url = "page=" + this.diaQuery.pageNum + "&size=" + this.diaQuery.pageSize;

                if (this.diaQuery.stationNo != '' && this.diaQuery.stationNo != null) {
                    url += "&stationNo=" + this.diaQuery.stationNo
                }
                data.url = url;
                const res = await upgradeInfoPage(data)
                //todo
                if (res.data.code !== 200) return //this.$msg.error('获取场站升级信息失败!')
                let newList = res.data.data.data.data.list;
                this.diaTableData = newList
                this.diaTotal = res.data.data.data.total;
            } catch (e) {
            }

        }
    },

};
</script>

<style lang='less' scoped>
.app-container {
    background: rgba(255, 255, 255, 0);
    padding-left: 0px;
}

.inner-container {
    width: 100%;
    height: var(--tableHeight);
    // height: 900px;
    overflow: hidden;
}

//重构新增
.side-search {
    width: 200px;
    background: #FFFFFF;
    border-right: #d3dce6 1px solid;
}

.small-side {
    width: 36px;
    height: 36px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.14);
    border-right: 1px solid #EEE;
}

.mian-content {
    flex: 1;
    background: #F5F7FA;
}

.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.head-section {
    padding: 16px;
    background: #FFFFFF;
    overflow: hidden;
}

.btn-end {
    display: flex;
    justify-content: flex-end;
}

.section-main {
    width: 100%;
    //height: calc(100vh - 24px);
    background: #FFFFFF;
    margin-top: 16px;
    padding: 24px 16px 16px 16px;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.choose-mian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
        display: inline-block;
        margin-left: 16px;
    }
}

.alert-box {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
}

.pagin {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.main-flex {
    display: flex;

}

.left-style {
    padding-left: 43px;
}

.edit-style {
    color: #0D6CE4;
    font-size: 14px;
    cursor:pointer;
}
</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';
</style>

