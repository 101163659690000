//站场管理
import {createAPI} from '@/utils/request'
const urlStr2 = '/cloud-platform'
const urlStr1 = '/service-baseinfo'
const urlSrr='/service-mqtt'

//根据换电站编码查询换电站信息
export const getStationById = stationNo => createAPI(urlStr2 + '/cloudPlatform/getInfoById/'+ stationNo, 'get')
//根据换电站编码查询闸机
export const findGateMacByStationNo=stationNo=>createAPI(urlStr2+ '/cloudPlatform/findGateMacByStationNo/'+ stationNo, 'get')

//根据换电站编码查询仓门
export const findBinGateByStationNo=stationNo=>createAPI(urlStr2+ '/cloudPlatform/findBinGateByStationNo/'+ stationNo, 'get')


//分页查询电池包数据
//export const getStationTypePage = (current, size, data)=>createAPI(urlStr2+'/cloudPlatform/StationTypePage/' + current + "/" + size,'post',data)
export const batteryDataInPage = (current, size, data)=>createAPI(urlStr2+'/cloudPlatform/StationTypePage/' + current + "/" + size,'post',data)

//获取全部信息

//获取全部车辆型号信息
export const findAllCarTypeInfo = () => createAPI(urlStr2 + '/cloudPlatform/findAllCarTypeInfo', 'get')
//添加缓存
export const getRedis = () => createAPI(urlStr2 + '/cloudPlatform/getRedis', 'get')

//换电站类型
//分页查询
export const getStationTypePage = data =>createAPI(urlStr1+'/stationType/page' + data.url,'get')
//添加信息
export const addStationType= data => createAPI(urlStr1 + '/stationType/addStationType', 'post',data)
//修改信息
export const updateStationType = data => createAPI(urlStr1 + '/stationType/updateStationType', 'post',data)
//删除信息
export const deleteStationType= id => createAPI(urlStr1 + '/stationType/batch?ids=' + id, 'delete')


//根据换电站编码查询换电站状态信息
export const getStationStatueById = stationNo => createAPI(urlStr2 + '/cloudPlatform/findStationSwapStatusLog/'+ stationNo, 'get')

//换电站设备分页查询
export const getEqmBase = (current, size, data)=>createAPI(urlStr2+'/cloudPlatform/getEqmBasePage/' + current + "/" + size,'post',data)

//换电站空调数据信息
export const getAcData = (current, size, data)=>createAPI(urlStr2+'/cloudPlatform/findAcDataPage/' + current + "/" + size,'post',data)

//换电站充电机数据信息
export const getChargeDevData = (current, size, data)=>createAPI(urlStr2+'/cloudPlatform/findChargeDevDataPage/' + current + "/" + size,'post',data)

//换电站环境数据信息信息
export const getEvmData = (current, size, data)=>createAPI(urlStr2+'/cloudPlatform/findEvmDataPage/' + current + "/" + size,'post',data)


//根据名称状态分页查询
export const getNodePage = data =>createAPI(urlStr1+'/node/page?' + data.url,'get')
//获取云平台区域信息
export const getNode = data => createAPI(urlStr1 + '/node/listEnabled', 'get',data)
//添加云平台区域信息
export const addNode = data => createAPI(urlStr1 + '/node/addNode', 'post',data)
//修改云平台区域信息
export const updateNode = data => createAPI(urlStr1 + '/node/updateNode', 'post',data)
//删除云平台区域信息
export const deleteNode = id => createAPI(urlStr1 + '/node/batch?ids=' + id, 'delete')

//闸机控制
export const gateControl = data => createAPI(urlSrr + '/sendMessage', 'post', data)


//换电站设备型号信息
//新增换电站设备型号信息
export const addEquipmentModel = data => createAPI(urlStr2 + '/cloudPlatform/addEqmTypeInStnInfo', 'post', data)
//获取换电站设备型号信息
export const getEquipmentModel = () => {
  return createAPI(
    urlStr2 + '/cloudPlatform/findEqmTypeInStnInfoList',
    'get',
  )
}
//修改换电站设备型号信息
export const setEquipmentModel = data => createAPI(urlStr2 + '/cloudPlatform/updateEqmTypeInStnInfo', 'post', data)
//删除换电站设备型号信息
export const deleteEquipmentModel = id => createAPI(urlStr2 + '/cloudPlatform/deleteEqmTypeInStnInfo/'+ id, 'delete')
//批量删除换电站设备型号信息
export const deleteList = id => createAPI(urlStr2 + '/cloudPlatform/deleteList/'+ id, 'delete')
//根据名称状态分页查询
export const checkEquipmentModelPage = (current, size, data)=>createAPI(urlStr2+'/cloudPlatform/eqmTypeInStnInfoAllList/' + current + "/" + size,'post',data)

//换电站车辆型号信息
//根据名称状态分页查询
export const checkVehicleModelsPage = (current, size, data)=>createAPI(urlStr2+'/cloudPlatform/CarTypeList/' + current + "/" + size,'post',data)
//新增换电站车辆型号信息
export const addVehicleModels = data => createAPI(urlStr2 + '/cloudPlatform/addCarType', 'post', data)
//修改换电站设备型号信息
export const setVehicleModels = data => createAPI(urlStr2 + '/cloudPlatform/updateCarType', 'post', data)
//批量删除换电站设备型号信息
export const deleteVehicleModelsList = id => createAPI(urlStr2 + '/cloudPlatform/deleteCarTypeList/'+ id, 'delete')
//获取所有换电站设备型号信息
export const  getVehicleModelsAll = ()=>createAPI(urlStr2+'/cloudPlatform/findEqmTypeInStnInfoList','get')

//监控设备信息
//根据名称状态分页查询
export const checkMonitoringPage = (current, size, data)=>createAPI(urlStr2+'/cloudPlatform/MonitoringEqipmentList/' + current + "/" + size,'post',data)
//新增换电站车辆型号信息
export const addMonitoring = data => createAPI(urlStr2 + '/cloudPlatform/addMonitoringEqipment', 'post', data)
//修改换电站设备型号信息
export const setMonitoring = data => createAPI(urlStr2 + '/cloudPlatform/updateMonitoringEqipment', 'post', data)
//批量删除换电站设备型号信息
export const deleteMonitoringList = id => createAPI(urlStr2 + '/cloudPlatform/deleteMonitoringList/'+ id, 'delete')

//充电设备信息
//根据名称状态分页查询
export const checkRechagerPage = (current, size, data)=>createAPI(urlStr2+'/cloudPlatform/ChagerInfoList/' + current + "/" + size,'post',data)
//新增换电站车辆型号信息
export const addRechager= data => createAPI(urlStr2 + '/cloudPlatform/addChagerInfo', 'post', data)
//修改换电站设备型号信息
export const setRechager = data => createAPI(urlStr2 + '/cloudPlatform/updateChagerInfo', 'post', data)
//批量删除换电站设备型号信息
export const deleteRechagerList = id => createAPI(urlStr2 + '/cloudPlatform/deleteChagerList/'+ id, 'delete')

//放电设备信息
//根据名称状态分页查询
export const checkPcsEqmPage = (current, size, data)=>createAPI(urlStr2+'/cloudPlatform/PcsEqmInfoList/' + current + "/" + size,'post',data)
//新增换电站车辆型号信息
export const addPcsEqm= data => createAPI(urlStr2 + '/cloudPlatform/addPcsEqmInfo', 'post', data)
//修改换电站设备型号信息
export const setPcsEqm = data => createAPI(urlStr2 + '/cloudPlatform/updatePcsEqmInfo', 'post', data)
//批量删除换电站设备型号信息
export const deletePcsEqmList = id => createAPI(urlStr2 + '/cloudPlatform/deletePcsEqmList/'+ id, 'delete')

//配电设备信息
//根据名称状态分页查询
export const checkPwrDtbCabinetPage = (current, size, data)=>createAPI(urlStr2+'/cloudPlatform/PwrDtbCabinetInfoList/' + current + "/" + size,'post',data)
//新增换电站车辆型号信息
export const addPwrDtbCabinet= data => createAPI(urlStr2 + '/cloudPlatform/addPwrDtbCabinetInfo', 'post', data)
//修改换电站设备型号信息
export const setPwrDtbCabinet = data => createAPI(urlStr2 + '/cloudPlatform/updatePwrDtbCabinetInfo', 'post', data)
//批量删除换电站设备型号信息
export const deletePwrDtbCabinetList = id => createAPI(urlStr2 + '/cloudPlatform/deletePwrDtbCabinetList/'+ id, 'delete')

//供电设备信息
//根据名称状态分页查询
export const checkPwrUnitPage = (current, size, data)=>createAPI(urlStr2+'/cloudPlatform/PwrUnitInfoList/' + current + "/" + size,'post',data)
//新增换电站车辆型号信息
export const addPwrUnit= data => createAPI(urlStr2 + '/cloudPlatform/addPwrUnitInfo', 'post', data)
//修改换电站设备型号信息
export const setPwrUnit = data => createAPI(urlStr2 + '/cloudPlatform/updatePwrUnitInfo', 'post', data)
//批量删除换电站设备型号信息
export const deletePwrUnitList = id => createAPI(urlStr2 + '/cloudPlatform/deletePwrUnitList/'+ id, 'delete')

  //暖通设备信息
//根据名称状态分页查询
export const checkHvacSystemPage = (current, size, data)=>createAPI(urlStr2+'/cloudPlatform/HvacSystemInfoList/' + current + "/" + size,'post',data)
//新增换电站车辆型号信息
export const addHvacSystem= data => createAPI(urlStr2 + '/cloudPlatform/addHvacSystemInfo', 'post', data)
//修改换电站设备型号信息
export const setHvacSystem = data => createAPI(urlStr2 + '/cloudPlatform/updateHvacSystemInfo', 'post', data)
//批量删除换电站设备型号信息
export const deleteHvacSystemList = id => createAPI(urlStr2 + '/cloudPlatform/deleteHvacSystemList/'+ id, 'delete')

//消防设备信息
//根据名称状态分页查询
export const checkfireControlPage = (current, size, data)=>createAPI(urlStr2+'/cloudPlatform/fireControlInfoList/' + current + "/" + size,'post',data)
//新增换电站车辆型号信息
export const addfireControl= data => createAPI(urlStr2 + '/cloudPlatform/addFireControlInfo', 'post', data)
//修改换电站设备型号信息
export const setfireControl = data => createAPI(urlStr2 + '/cloudPlatform/updateFireControlInfo', 'post', data)
//批量删除换电站设备型号信息
export const deletefireControlList = id => createAPI(urlStr2 + '/cloudPlatform/deleteFireControlList/'+ id, 'delete')

//大屏设备信息
//根据名称状态分页查询
export const checklargeScreenPage = (current, size, data)=>createAPI(urlStr2+'/cloudPlatform/largeScreenInfoList/' + current + "/" + size,'post',data)
//新增换电站车辆型号信息
export const addlargeScreen= data => createAPI(urlStr2 + '/cloudPlatform/addLargeScreenInfo', 'post', data)
//修改换电站设备型号信息
export const setlargeScreen = data => createAPI(urlStr2 + '/cloudPlatform/updateLargeScreenInfo', 'post', data)
//批量删除换电站设备型号信息
export const deletelargeScreenList = id => createAPI(urlStr2 + '/cloudPlatform/deleteLargeScreenList/'+ id, 'delete')

//仓门设备管理
//根据名称状态分页查询
export const checkbinGatePage = (current, size, data)=>createAPI(urlStr2+'/cloudPlatform/binGateInfoList/' + current + "/" + size,'post',data)
//新增换电站车辆型号信息
export const addbinGate= data => createAPI(urlStr2 + '/cloudPlatform/addBinGateInfo', 'post', data)
//修改换电站设备型号信息
export const setbinGate = data => createAPI(urlStr2 + '/cloudPlatform/updateBinGateInfo', 'post', data)
//批量删除换电站设备型号信息
export const deletebinGateList = id => createAPI(urlStr2 + '/cloudPlatform/deleteBinGateList/'+ id, 'delete')
//根据换电站编号查询对应仓门列表
export const getbinGateByStationNo = id => createAPI(urlStr2 + '/cloudPlatform/findBinGateInfoByStationNo/'+ id, 'get')



//闸机设备管理
//根据名称状态分页查询
export const checkgateMacPage = (current, size, data)=>createAPI(urlStr2+'/cloudPlatform/gateMacInfoList/' + current + "/" + size,'post',data)
//新增换电站车辆型号信息
export const addgateMac= data => createAPI(urlStr2 + '/cloudPlatform/addGateMacInfo', 'post', data)
//修改换电站设备型号信息
export const setgateMac = data => createAPI(urlStr2 + '/cloudPlatform/updateGateMacInfo', 'post', data)
//批量删除换电站设备型号信息
export const deletegateMacList = id => createAPI(urlStr2 + '/cloudPlatform/deleteGateMacList/'+ id, 'delete')
//根据换电站编号查询对应闸机列表
export const getgateMacByStationNo = id => createAPI(urlStr2 + '/cloudPlatform/findGateMacInfoByStationNo/'+ id, 'get')

//识别设备管理
//根据名称状态分页查询
export const checkviEqmPage = (current, size, data)=>createAPI(urlStr2+'/cloudPlatform/viEqmInfoList/' + current + "/" + size,'post',data)
//新增换电站车辆型号信息
export const addviEqm= data => createAPI(urlStr2 + '/cloudPlatform/addViEqmInfo', 'post', data)
//修改换电站设备型号信息
export const setviEqm = data => createAPI(urlStr2 + '/cloudPlatform/updateViEqmInfo', 'post', data)
//批量删除换电站设备型号信息
export const deleteviEqmList = id => createAPI(urlStr2 + '/cloudPlatform/deleteViEqmList/'+ id, 'delete')

//辅助设备管理
//根据名称状态分页查询
export const checkauxEqmPage = (current, size, data)=>createAPI(urlStr2+'/cloudPlatform/auxEqmInfoList/' + current + "/" + size,'post',data)
//新增换电站车辆型号信息
export const addauxEqm= data => createAPI(urlStr2 + '/cloudPlatform/addAuxEqmInfo', 'post', data)
//修改换电站设备型号信息
export const setauxEqm = data => createAPI(urlStr2 + '/cloudPlatform/updateAuxEqmInfo', 'post', data)
//批量删除换电站设备型号信息
export const deleteauxEqmList = id => createAPI(urlStr2 + '/cloudPlatform/deleteAuxEqmList/'+ id, 'delete')

//能耗设备管理
//根据名称状态分页查询
export const checkenergyEqmPage = (current, size, data)=>createAPI(urlStr2+'/cloudPlatform/energyEqmInfoList/' + current + "/" + size,'post',data)
//新增换电站车辆型号信息
export const addenergyEqm= data => createAPI(urlStr2 + '/cloudPlatform/addEnergyEqmInfo', 'post', data)
//修改换电站设备型号信息
export const setenergyEqm = data => createAPI(urlStr2 + '/cloudPlatform/updateEnergyEqmInfo', 'post', data)
//批量删除换电站设备型号信息
export const deleteenergyEqmList = id => createAPI(urlStr2 + '/cloudPlatform/deleteEnergyEqmList/'+ id, 'delete')

//报警阀值管理
//根据名称状态分页查询
export const checkalarmThresholdPage = (current, size, data)=>createAPI(urlStr2+'/cloudPlatform/alarmThresholdValueInfoList/' + current + "/" + size,'post',data)
//新增换电站车辆型号信息
export const addalarmThreshold= data => createAPI(urlStr2 + '/cloudPlatform/addAlarmThresholdValueInfo', 'post', data)
//修改换电站设备型号信息
export const setalarmThreshold = data => createAPI(urlStr2 + '/cloudPlatform/updateAlarmThresholdValueInfo', 'post', data)
//批量删除换电站设备型号信息
export const deletealarmThresholdList = id => createAPI(urlStr2 + '/cloudPlatform/deleteAlarmThresholdValueList/'+ id, 'delete')


//金额计算分子
//分页查询
export const stationMoneyFactorPage = (current, size, data)=>createAPI(urlStr2+'/cloudPlatform/stationMoneyFactorPage/' + current + "/" + size,'post',data)
//添加信息
export const addStationMoneyFactor= data => createAPI(urlStr2 + '/cloudPlatform/addStationMoneyFactor', 'post',data)
//修改信息
export const updateStationMoneyFactor = data => createAPI(urlStr2 + '/cloudPlatform/updateStationMoneyFactor', 'post',data)
//删除信息
export const deleteStationMoneyFactor= id => createAPI(urlStr2 + '/cloudPlatform/deleteStationMoneyFactor/'+ id, 'delete')
