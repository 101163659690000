<template>
    <div class="app-container">
        <el-row class="main-flex">
            <!--运营商场站树-->
            <el-col :span="3" class="side-search" v-if="isopenShow">
                <operatorStationTreeVue @emitToParent='getTreeData' @isShow="isHidden"></operatorStationTreeVue>
            </el-col>

            <el-col v-if="!isopenShow" class="small-side">
                <div class="change-in" @click="isopenShow = !  isopenShow "></div>
            </el-col>

            <!-- 搜索区域 -->
            <el-col :span="21" class="mian-content">
                <!--                    <el-input placeholder="请从左侧选择运营商/场站" v-model.trim="treeData.operatorStationNameTree"-->
                <!--                              disabled></el-input>-->

                <div class="head-section">
                    <el-col :span="6">
                        <el-form :inline="true">
                            <el-form-item label="用户名：" label-width="82px">
                                <el-input placeholder="请输入用户名" v-model.trim="userName" clearable></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="7">
                        <el-form :inline="true" class="left-style">
                            <el-form-item label="手机号： " label-width="95px">
                                <el-input placeholder="请输入手机号" v-model.trim="phone" clearable></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="7">
                        <el-form :inline="true" class="left-style">
                            <el-form-item label="车牌号：" label-width="95px">
                                <el-input placeholder="请输入车牌号" v-model.trim="carNo" clearable></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="4">
                        <div class="btn-end">
                            <span class="more-search" @click="showMore=!showMore"><div>展开</div>
                            <i :class="showMore==true?'el-icon-arrow-down':'el-icon-arrow-up'"></i> </span>
                            <span class="btn-rest">
                             <el-button @click="restFunction" plain>重置</el-button>
                            </span>
                            <span class="btn-submit">
                           <el-button type="primary" @click="getBatDatPage" plain>查询</el-button>
                            </span>
                        </div>
                    </el-col>
                </div>
                <transition name="el-zoom-in-top">
                    <div v-show="showMore">
                        <div class="head-section head-section2">
                            <el-col :span="6">
                                <el-form :inline="true">
                                    <el-form-item label="预约站点：" label-width="82px">
                                        <el-input placeholder="请输入预约站点" v-model.trim="station"
                                                  clearable></el-input>
                                    </el-form-item>
                                </el-form>
                            </el-col>
                            <el-col :span="7">
                                <el-form :inline="true">
                                    <el-form-item label="站点编码：" class="left-style" label-width="95px">
                                        <el-input placeholder="请输入站点编码" v-model.trim="stationNo"
                                                  clearable></el-input>
                                    </el-form-item>
                                </el-form>
                            </el-col>
                            <el-col :span="7">
                                <el-form :inline="true">
                                    <el-form-item label="审核状态：" label-width="95px" class="left-style">
                                        <el-select placeholder="请选择审核状态" v-model="stateValue" clearable>
                                            <el-option
                                                v-for="item in options"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-form>
                            </el-col>
                        </div>
                        <div class="head-section head-section2">
                            <el-col :span="6">
                                <el-form :inline="true">
                                    <el-form-item label="审核日期：" label-width="82px">
                                        <el-date-picker
                                            v-model="approvalDate"
                                            type="datetimerange"
                                            format="MM-dd HH:mm"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            :default-time="['00:00:00', '23:59:59']"
                                            range-separator="至"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-form>
                            </el-col>
                            <el-col :span="7">
                                <el-form :inline="true">
                                    <el-form-item label="预约日期：" class="left-style" label-width="95px">
                                        <el-date-picker
                                            v-model="appointmentDate"
                                            type="datetimerange"
                                            format="MM-dd HH:mm"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            :default-time="['00:00:00', '23:59:59']"
                                            range-separator="至"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-form>

                            </el-col>

                            <el-col :span="7">
                                <el-form :inline="true">
                                    <el-form-item label="发起日期：" class="left-style" label-width="95px">
                                        <el-date-picker
                                            v-model="launchDate"
                                            type="datetimerange"
                                            format="MM-dd HH:mm"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            :default-time="['00:00:00', '23:59:59']"
                                            range-separator="至"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-form>
                            </el-col>
                        </div>
                    </div>
                </transition>
                <!--  表格部分-->
                <div class="section-main">
                    <el-col class="choose-mian">
                        <div class="section-title">预约列表
                        </div>
                    </el-col>
                    <!--table-->
                    <el-col :span="24">
                        <el-row class="table-box">
                            <el-table ref="chargeDevTable" id="statiNo" :data="tableData"
                                      :header-cell-style="headerStyleEvent" :cell-class-name="goodsInfo">
                                <el-table-column align="center" label="编号" :index="renIndex" type="index"
                                                 width="50"></el-table-column>
                                <el-table-column align="center" prop="userName" label="用户名"
                                                 width="100"></el-table-column>
                                <el-table-column align="center" prop="phone" label="手机号"
                                                 width="120"></el-table-column>
                                <el-table-column align="center" prop="stationName" label="预约站点"
                                                 min-width="120"></el-table-column>
                                <el-table-column align="center" prop="carNo" label="车牌号"
                                                 min-width="100"></el-table-column>

                                <el-table-column align="center" label="预约时间" width="280">
                                    <template slot-scope="scope">
                                        <div>
                                            {{
                                                scope.row.startTime.substr(0, 16)
                                            }}-{{ scope.row.endTime.substr(0, 16) }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" prop="createTime" label="发起时间" width="160">
                                    <template slot-scope="scope">
                                        <div>
                                            {{ scope.row.createTime?scope.row.createTime.substr(0, 16):'--' }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" prop="auditTime" label="审核时间"
                                                 width="160"></el-table-column>
                                <el-table-column align="left" prop="auditStatus" label="审核状态" width="120">
                                    <template slot-scope="scope">
                                        <div v-if="scope.row.status == 1">
                                        <span v-if="scope.row.auditStatus == 0" class="states"
                                              style="color:#FA9600;"><i
                                            style="background-color:#FA9600;"></i> 待审核 </span>
                                        <span v-else-if="scope.row.auditStatus == 1" class="states"
                                              style="color:#0D6CE4;"><i
                                            style="background-color:#0D6CE4;"></i> 审核通过</span>
                                        <span v-else class="states"
                                              style="color:#FF6165;"><i
                                            style="background-color:#FF6165;"></i> 审核驳回</span>
                                        </div>
                                        <span  v-else class="states" style="color:#26C3C0;"><i
                                            style="background-color:#26C3C0;"></i> 预约取消</span>
                                    </template>
                                </el-table-column>

                                <el-table-column label="操作" min-width="80" style="align-items: center;"
                                                 fixed="right" align="center">
                                    <template slot-scope="scope">
                                        <div style="display: flex;justify-content: center">
                                            <el-button @click="detail(scope.row)" class="btn-color"
                                                       v-if="scope.row.status == 1 && scope.row.auditStatus==0 && scope.row.expired==false"
                                                       type="text">审核
                                            </el-button>
                                            <el-button @click="detail(scope.row,1)" class="btn-color"
                                                       v-else
                                                       type="text">查看
                                            </el-button>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-row>
                    </el-col>

                    <!--pagination-->
                    <el-col :span="24">
                        <el-row class="pagin">
                            <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="query.pageNum"
                                :page-sizes="[10, 30, 50,100]"
                                :page-size="query.pageSize"
                                layout="total, sizes, prev, pager, next"
                                :total="total">
                            </el-pagination>
                        </el-row>
                    </el-col>
                </div>
            </el-col>

        </el-row>
        <el-dialog
            title="预约审核"
            :visible.sync="dialogVisible"
            :before-close="handleClose" @close="cancelAudit">
            <ul class="car-inf">
                <li>车牌号：</li>
                <li>{{ dialogCarNo }}</li>
                <li>预约时间：</li>
                <li>{{ dialogOrderTime }}</li>
            </ul>
            <el-form ref="form" :rules="rules" label-position="top" :model="form" label-width="80px" class="dialog-form">
                <el-form-item label="审核结果：" prop="check">
                    <el-select v-model="form.check" placeholder="请选择审核结果" :disabled="examineValue==1">
                        <el-option label="通过" value="1"></el-option>
                        <el-option label="驳回" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="备注：" v-if="form.check==2">
                    <el-input v-model="form.remark" :disabled="examineValue==1"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer-box" v-if="examineValue!=1">
    <el-button class="cancel-btn" @click="cancelAudit">取消</el-button>
    <el-button class="confirm-btn" type="primary" @click="confirmAudit">确定</el-button>
  </span>
        </el-dialog>
    </div>
</template>

<script>

import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import operatorStationTreeVue from "@/components/tree/operatorStationTree";
import {getReservation, toAudit} from "@/utils/station";

export default {
    name: 'powerChangeReservation',
    components: {operatorStationTreeVue},
    data() {
        return {
            showMore: false,
            isopenShow: true,
            query: {
                pageNum: 1,
                pageSize: 10,
            },

            dataShow: {},
            tableData: [],
            total: 0,

            //左侧 运营商-场站树
            treeData: {
                operatorStationOptions: undefined,  //运营商-场站树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
                operatorStationNameTree: "",
                operatorStationIdTree: null,
                defaultExpandAll: true,
            },
            dialogVisible: false,
            form: {
                check: '',
                remark: ''
            },
            dialogCarNo: null,
            dialogOrderTime: null,
            orderId: null,
            //     search
            carNo: '',
            userName: '',
            phone: '',
            station: '',
            stateValue: null,
            options: [
                {
                    label: '待审核',
                    value: 0
                },
                {
                    label: '审核通过',
                    value: 1
                }, {
                    label: '审核驳回',
                    value: 2
                },
                {
                    label: '已取消',
                    value: 3
                },
            ],
            appointmentDate: '',
            launchDate: '',
            approvalDate: '',
            examineValue:0,
            stationNo:'',
            rules: {
                check: [
                    {required: true, message: '请选择审核结果', trigger: 'change'},
                ],
            }
        };

    },
    created() {
        this.getBatDatPage();
    },
    // watch: {
    //     'form.check': {
    //         handler(newValue) {
    //             console.log(newValue,'33333')
    //         },
    //         // deep: true
    //     }
    // },
    computed: {},
    methods: {
        handleClose(done) {
            done();
            this.form.remark = ''
            this.form.check = ''
        },
        headerStyleEvent({row, column, rowIndex, columnIndex}) {
            if (columnIndex == 1) {
                return 'padding:0px;'
            } else if (columnIndex == 8) {
                return 'padding-left:10px;'
            }
        },
        goodsInfo(row) {
            if (row.columnIndex == 1) {
                return 'goodsInfo'
            }
        },
        isHidden(message) {
            this.isopenShow = message
        },
        async getBatDatPage() {
            try {
                let operatorId;
                let stationId;
                let id = this.treeData.operatorStationIdTree;
                if (id !== "" && id !== null) {
                    if (id.indexOf("operator") !== -1) {
                        id = id.slice(9);
                        operatorId = +Number(id);
                    } else {
                        id = id.slice(8);
                        stationId = Number(id)
                    }
                }
                let startTime = null;
                let endTime = null;
                if (this.appointmentDate) {
                    startTime = this.appointmentDate[0];
                    endTime = this.appointmentDate[1];
                }

                let createStartTime = null;
                let createEndTime = null;
                if (this.launchDate) {
                    createStartTime = this.launchDate[0];
                    createEndTime   = this.launchDate[1];
                }

                let auditStartTime = null;
                let auditEndTime = null;
                if (this.approvalDate) {
                    auditStartTime = this.approvalDate[0]
                    auditEndTime   = this.approvalDate[1];
                }

                let auditStatus = null;
                let status = null;
                if(this.stateValue != null && this.stateValue === 3){
                    status = 0;
                }else if(this.stateValue != null){
                    status = 1;
                    auditStatus = this.stateValue;
                }

                let data = {
                    pageSize: this.query.pageSize,
                    pageNum: this.query.pageNum,
                    operatorId: operatorId,
                    stationId: stationId,
                    carNo: this.carNo,
                    userName: this.userName,
                    phone: this.phone,
                    stationName: this.station,
                    auditStatus: auditStatus,
                    status: status,
                    stationNo:this.stationNo,
                    //预约开始时间
                    startTime: startTime,
                    endTime: endTime,
                    // 发起时间
                    createStartTime: createStartTime,
                    createEndTime: createEndTime,
                    // 审核时间
                    auditStartTime: auditStartTime,
                    auditEndTime: auditEndTime
                }
                const res = await getReservation(data)
                if (res.data.code !== 200) return this.$msg.error('获取场站基础信息失败!')
                this.tableData = res.data.data.list
                this.total = res.data.data.total
            } catch (e) {
            }
        },
        // 审核
        detail(data,state) {
            // 查看為1
            this.examineValue=state
            if (state == 1){
                this.form.check =data.auditStatus!=0?String(data.auditStatus):''
                this.form.remark = data.reason

            }
            this.dialogVisible = true
            this.dialogVisible = true
            this.dialogCarNo = data.carNo ? data.carNo : '--'
            this.dialogOrderTime = data.startTime.substr(0, 16) + '-' + data.endTime.substr(0, 16)
            this.orderId = data.id
        },
        cancelAudit(){
            this.dialogVisible = false
            this.form.remark = ''
            this.form.check = ''
            this.$refs['form'].resetFields();
        },
        // audit
        confirmAudit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    let data = {
                        id: this.orderId,
                        auditStatus: this.form.check,
                        reason: this.form.remark
                    }
                    toAudit(data).then((res) => {
                        this.dialogVisible = false
                        this.$message({
                            message: res.data.msg,
                            type: 'success'
                        });
                        this.getBatDatPage();
                        this.form.remark = ''
                        this.form.check = ''

                    }).catch(err => {
                        this.$message({
                            message: err.data.msg,
                            type: 'warning'
                        });
                    })

                } else {
                    console.log('error submit!!');
                    return;
                }
            });

        },

        restFunction() {
            //左侧 运营商-场站树
            this.treeData = {
                operatorStationOptions: undefined,  //运营商-场站树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
                operatorStationNameTree: "",
                operatorStationIdTree: null,
                defaultExpandAll: true,
            };
            this.query = {
                pageNum: 1,
                pageSize: 10,
            }
            this.carNo = ''
            this.userName = ''
            this.phone = ''
            this.station = ''
            this.stateValue = null
            this.appointmentDate = ''
            this.launchDate = ''
            this.approvalDate = ''
            this.stationNo=''
            this.getBatDatPage()
        },

        handleSizeChange(val) {
            this.query.pageSize = val
            this.getBatDatPage()
        },
        handleCurrentChange(val) {
            this.query.pageNum = val
            this.getBatDatPage()
        },

        renIndex(index) {
            return (this.query.pageNum - 1) * this.query.pageSize + index + 1
        },

        close() {

        },

        cleanList() {
            this.$refs.chargeDevTable.clearSelection()
        },

        getTreeData(data) {
            this.treeData = data
        },

        /** 查询运营商下拉树结构 */
        async getOperatorTree() {
            try {
                this.treeData.operatorOptions = [];
                let res = await operatorTree();
                if (res.status !== 200) return this.$msg.error("获取运营商信息失败");
                this.treeData.operatorOptions.push(res.data.data)
                this.treeData.operatorIdTree = res.data.data.id
                this.treeData.operatorNameTree = res.data.data.label
            } catch (error) {
            }
        },
    },


};
</script>

<style lang='less' scoped>
.app-container {
    background: rgba(255, 255, 255, 0);
    padding-left: 0px;
}

::v-deep .fixstyle {
    padding-left: 0 !important;
}

.el-table {
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
        width: 10px; /*滚动条宽度*/
        height: 10px; /*滚动条高度*/
    }

    /*定义滚动条轨道 内阴影+圆角*/

    /deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
        //   box-shadow: 0px 1px 3px #fff inset; /*滚动条的背景区域的内阴影*/
        border-radius: 10px; /*滚动条的背景区域的圆角*/
        background-color: #fff; /*滚动条的背景颜色*/
    }

    /*定义滑块 内阴影+圆角*/

    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
        //   box-shadow: 0px 1px 3px #dddee0 inset; /*滚动条的内阴影*/
        border-radius: 10px; /*滚动条的圆角*/
        background-color: #dddee0; /*滚动条的背景颜色*/
    }
}

.inner-container {
    // min-width: 100% + 60px;
    height: var(--tableHeight);
    overflow: hidden;
}

.bm-view {
    width: 100%;
    height: 400px;
}


li {
    list-style: none;
}

.el-select, .el-cascader {
    width: 100%;
}

.el-dropdown {
    margin-left: 25px;
}

.states {
    > i {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-right: 4px;
        //margin-top: 3px;

    }
}

.box1 {
    display: flex;
    align-items: center;
    margin: 0 !important;

    span {
        position: relative;
        font-size: 18px;
        font-weight: 700;
        margin-left: 20px;
    }

    .text::before {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        background-color: #1890ff;
        border-radius: 50%;
        top: 50%;
        left: -13px;
        transform: translate(0, -50%);
    }
}

.content-hander {
    height: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-left: 3px solid #2980e1;
    padding-left: 15px;

    > h3 {
        font-size: 16px;
        font-weight: 700;
    }
}

.content-title {
    height: 200px;

    .el-col {
        height: 100%;

        > img {
            width: 100%;
            height: 100%;
            box-shadow: 0 0 25px #333;
        }
    }

    .content-title-right {
        margin-left: 50px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        > ul {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 100%;
            height: 100%;
        }
    }
}

.msg-title {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 0;
    margin: 0 !important;

    span {
        color: #333;
    }

    .text1 {
        color: #595959;
        margin-left: 15px;
    }
}

.content-map {
    width: 100%;
    height: 100%;
    border: 1px solid #eee;
}

.el-divider--horizontal {
    margin-bottom: 0;
}

.fileImg {
    display: flex;
    align-items: flex-start;

    div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 120px;
        height: 120px;
        border: 1px dashed #ccc;
        border-radius: 6px;
        margin-right: 20px;
        cursor: pointer;

        > img {
            width: 100%;
            height: 100%;
            border-radius: 6px;
        }
    }

    div:hover {
        border: 1px dashed #1b80e1;

        i {
            color: #1b80e1;
        }
    }

}

.dowload {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
        display: flex;
        align-items: center;

        &:first-child {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            > span {
                font-size: 12px;
                color: #e10505;
            }
        }

        > button {
            margin-top: 0 !important;
        }
    }
}

.out-container {
    height: 100%;
}

//重构新增
.side-search {
    width: 200px;
    background: #FFFFFF;
    border-right: #d3dce6 1px solid;
}

.small-side {
    width: 36px;
    height: 36px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.14);
    border-right: 1px solid #EEE;
}

.mian-content {
    flex: 1;
    background: #F5F7FA;
}

.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.head-section {
    padding: 16px;
    background: #FFFFFF;
    overflow: hidden;
}

.btn-end {
    display: flex;
    justify-content: flex-end;
}

.section-main {
    width: 100%;
    //height: calc(100vh - 24px);
    background: #FFFFFF;
    margin-top: 16px;
    padding: 24px 16px 16px 16px;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.choose-mian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
        display: inline-block;
        margin-left: 16px;
    }
}

.alert-box {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
}

.pagin {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.main-flex {
    display: flex;

}

.left-style {
    padding-left: 43px;
}

.edit-style {
    color: #0D6CE4;
    font-size: 14px;
    cursor: pointer;
}

.btn-color {
    color: #0D6CE4;
}

/deep/ .el-dialog__title {
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    color: #2F3133;

}

/deep/ .el-dialog__header {
    padding: 24px 24px 0 24px;
}

/deep/ .el-dialog__body {
    padding: 16px 24px 24px 24px;
}

.car-inf {
    font-size: 14px;
    font-weight: 400;
    color: #585858;

    li {
        margin-bottom: 8px;
    }

    li:last-child {
        margin-bottom: 0px;
    }
}

/deep/ .el-dialog {
    width: 360px;
}

.dialog-form {
    /deep/ .el-form-item__label {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #585858;
        padding: 16px 0 8px 0;
    }
}

.dialog-footer-box {
    display: flex;
    justify-content: space-between;
}

/deep/ .el-form-item {
    margin-bottom: 0;
}

/deep/ .el-form-item__content {
    width: 312px;
}

/deep/ .el-dialog__footer {
    padding: 0 24px 24px 24px;
}


.cancel-btn {
    border: 1px solid #0D6CE4;
    color: #0D6CE4;
    padding: 8px 30px;
    line-height: 20px;
    width: 88px;
}

.confirm-btn {
    background: #0D6CE4;
    color: #FFFFFF;
    padding: 8px 30px;
    line-height: 20px;
    width: 88px;
}

.more-search {
    display: flex;
    color: #0D6CE4;
    font-size: 14px;
    font-weight: 400;
    line-height: 36px;
    cursor: pointer;
    position: relative;
    left: -15px;

    i {
        line-height: 36px;
    }
}

.head-section {
    /deep/ .el-form-item__content {
        width: 250px !important;
    }

    /deep/ .el-input__inner { //更改input大小
        width: 250px !important;
        height: 36px;
        line-height: 36px;
    }
}

/deep/ .el-range-separator {
    width: 10%;
}

.head-section2 {
    overflow: hidden;
    padding: 0 16px 16px 16px;
    height: 100%;
}
/deep/.el-date-editor .el-range-separator{
    line-height: 28px;
}
/deep/.el-date-editor .el-range__icon{
    line-height: 1;
}

</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';
</style>

