//站场管理
import request, { createAPI } from '@/utils/request'
const urlStr = '/service-baseinfo'
const urlStr10 = '/service-file';

export const uploadVersionFile = data => createAPI('/service-openapi/upload/uploadFile', 'post', data)

export const versionManageList = data => createAPI(urlStr + `/versionManage/page`, 'get',data)
export const selectVersionManage = (id) => createAPI(urlStr + '/versionManage/query/' + id, 'get')
export const addVersionManage = (data) => createAPI(urlStr + '/versionManage/add' , 'post', data)
//更新运营商版本
export const updateVersionManage = (data) => createAPI(urlStr + '/versionManage/update' , 'post', data)

//运营商查看升级记录
export const versionHistoryPage = data => createAPI(urlStr + `/versionManage/historyPage`+data, 'get' )
export const batchDelVersionManage =data=>createAPI(urlStr+'/versionManage/batch?ids='+data,'delete')

//选择站控的升级文件
export const stationOperatorVersionList = data => createAPI(urlStr + `/upgrade/stationVersionOptions?operatorId=` + data , 'get')

//查看站控升级历史
//查询场站升级信息
export const upgradeInfoPage = data=>createAPI(urlStr+`/upgrade/specificPage?`+data.url,'get')

export const remoteUpgrade = data=>createAPI(urlStr+`/upgrade/release`,'post',data)

export const fileMergeRequest = data=>createAPI(urlStr10 +`/fileUpload/file/merge`,'post',data)

