// const CryptoJS = require('crypto-js'); //引用AES源码js
const CryptoJS = require('./crypto-js.js'); //引用AES源码js
const initKey = '5F6B2AK33DASD1235E74C231B47AC8F6';
/**
 * AES加密
 */
export const AESEncrypt = (str, key) => {
  let _key = key || initKey;
  const __key = CryptoJS.enc.Utf8.parse(_key) //将秘钥转换成Utf8字节数组
  // const iv = CryptoJS.enc.Utf8.parse(_key.substr(0, 16))
  //加密
  const encrypt = CryptoJS.AES.encrypt(JSON.stringify(str), __key, {
    // iv: iv,
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  })
  return encrypt.toString()
}

/**
 * AES解密
 */
export const AESDecrypt = (str, key) => {
  let _key = key || initKey;
  const __key = CryptoJS.enc.Utf8.parse(_key) //将秘钥转换成Utf8字节数组

  let base64data = CryptoJS.enc.Base64.parse(str);
  let src = CryptoJS.enc.Base64.stringify(base64data);
  const decrypt = CryptoJS.AES.decrypt(src, __key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  })
  const _decrypt = JSON.parse(decrypt.toString(CryptoJS.enc.Utf8)) //解密后的数据
  return _decrypt
}