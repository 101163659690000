import { createAPI } from '@/utils/request'
const urlStr = '/service-baseinfo'
const urlTbox = '/service-tbox'
    //----------------车辆基础信息----------------------------------------------------------------------
//获取所有车辆基础信息
export const getCarBaseAll = ()=>createAPI(urlStr+'/carBaseInfo/list','get')

export const getTeamCars = data =>createAPI(urlStr+'/carBaseInfo/getTeamCars?teamId=' + data,'get')
//车辆批量导入
export const vehicleLeadFile = data =>createAPI(urlStr+'/carBaseInfo/excelImport','post',data)


//分页获取车辆信息
export const getCarPage =data =>createAPI(urlStr+'/carBaseInfo/specificPage' + data.url,'get')

 // 新增车辆基础信息
export const addCarBaseInfo = data => createAPI(urlStr + "/carBaseInfo/add", 'POST', data)

// 删除车辆基础信息
export const batchDeleteCar = data => createAPI(urlStr + "/carBaseInfo/batchDelete" , 'post',data)

// 更新车辆基础信息
export const upCarBaseInfo = data => createAPI(urlStr + "/carBaseInfo/update", 'PUT', data)

// 根据条件分页查询车辆基础信息
export const getCarBaseInfoOfState = data => createAPI(urlStr + '/carBaseInfo/specificPage'+ data.url,'post',data)

export const getCarStatistics = data => createAPI(urlStr + '/carBaseInfo/carStatistics?operatorId=' +  data.operatorId,'get')
export const teamCarTree = data=>createAPI(urlStr+'/team/teamCarTree?operatorId=' + data.operatorId,'GET')

//根据车牌上传图片
export const getimgPath = data =>createAPI(urlStr+'/carBaseInfo/editPic','post',data)


//根据车牌获取每天换电次数
export const getCarDaysPage = data=>createAPI(urlStr+'/carDaySwapNumberResult/dayCount?carNo='+data)

//根据车牌查询每天换电记录
// export const checkDayRecord =data=>createAPI(urlStr+'/carDaySwapAmountResult/timeZone','get',data)
export const checkDayRecord =data=>createAPI(urlStr+'/carDaySwapNumberResult/timeZone','get',data)
//根据车牌获取每月换电次数
export const getCarMonthPage = data=>createAPI(urlStr+'/carMonthSwapNumberResult/monthAndYearCount?carNo='+data)

//根据车牌查询每月换电记录
export const checkMonthRecord =data=>createAPI(urlStr+'/carMonthSwapNumberResult/timeZone','get',data)


//根据车牌查询每天消费记录
export const searchCarDayElectr = data=>createAPI(urlStr+'/carDaySwapAmountResult/timeZone','get',data)

//根据车牌查询每月消费记录
export const searchCarMonthElectr = data=>createAPI(urlStr+'/carMonthSwapAmountResult/timeZone','get',data)

//获取消费金额统计
export const getElectrMonth =data=>createAPI(urlStr+'/carMonthSwapAmountResult/monthAndYearCount?carNo='+data,'get')

//获取消费金额统计
export const getElectrDay =data=>createAPI(urlStr+'/carDaySwapAmountResult/dayCount?carNo='+data,'get')



//车队基础信息
//新增车队
export const addTeam = data=>createAPI(urlStr+'/team/addTeam','POST',data)
//修改车队信息
export const updateTeam=data=>createAPI(urlStr+'/team/updateTeam','POST',data)
//删除车队信息
export const delTeam= id=>createAPI(urlStr+'/team/batch/?ids='+id,'delete')
//查询所有车队信息
export const getTeamList= (data)=>createAPI(urlStr+'/team/selectTeamRelatedByOperatorId' + data.url,'GET')
//分页查询车队信息
export const teamPage= data=>createAPI(urlStr+"/team/page?page="+data.page+"&size="+data.size+data.url,'get')


//车辆黑白名单信息
//分页查询名单信息
export const getCarBlackWhiteListPage = data => createAPI(urlStr + '/carBlackWhiteList/page'+ data.url,'post',data)
//新增名单信息
export const addCarBlackWhiteList = data=>createAPI(urlStr+'/carBlackWhiteList/add','POST',data)
//修改名单信息
export const updateCarBlackWhiteList = data=>createAPI(urlStr+'/carBlackWhiteList/update','POST',data)
//删除名单信息
export const batchDeleteCarBlackWhiteList= ids=>createAPI(urlStr+'/carBlackWhiteList/batchDelete/?ids='+ids,'get')

// 车辆分页查询 -- 分别查询在线和离线的车辆列表
export const carPosition= (data)=>createAPI(urlTbox+'/tbox/monitor/selectCarPoints','get',data)
// 车辆轨迹
export const carTrack= (data)=>createAPI(urlTbox+'/tbox/monitor/carTrack','get',data)
// 报警信息
export const alarmsPage= (data)=>createAPI(urlTbox+'/tbox/monitor/alarmsPage','get',data)
//车辆状态统计 在线数量、离线数量、报警数量
export const selectCarStatusTotal= (data)=>createAPI(urlTbox+'/tbox/monitor/selectCarStatusTotal','get',data)
// 整车实时数据
export const selectCompleteCarPage= (data)=>createAPI(urlTbox+'/tbox/monitor/completeCarPage','get',data)
