import { createAPI } from '@/utils/request'
const urlStr = '/springcloud-service-provider03'

// -------------------------能表累计值信息------------------------------------------
export const getChangeHoureTime =data =>createAPI(urlStr+'/rdElecMeterHourEnergyValue/queryByArea','get',data)

// /rdElecMeterTotalEnergyValue/timezone
// 查询时间段之内的
export const getValueOfTime = data => createAPI(urlStr + '/rdElecMeterDayEnergyValue/queryByArea', 'get',data)

//电能表累计值查询
export const searchTotalTime = data => createAPI(urlStr + '/rdElecMeterTotalEnergyValue/timeZoneAndId', 'get',data)


// --------------------------电能表变化值信息---------------------------------
//电能表变化值
export const getChangeTime = data => createAPI(urlStr + '/rdElecMeterRealEnergyValue/timeZoneAndId', 'get',data)

//换电站换电次数环比
export const getAndendTime =data=>createAPI(urlStr+`/saStationDaySwapRunResult/queryByArea`,'get',data)

export const getAndendAll =()=>createAPI(urlStr+'/saStationDaySwapRunResult/findAll','get')

export const getTimeAll = data =>createAPI(urlStr +'/saStationDaySwapRunResult/findByStarttimrAndEndtime','get',data)
