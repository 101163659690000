import { createAPI } from '@/utils/request'
const urlStr = '/service-baseinfo'
// POST
// /cartypes/addcarType
// 车辆型号管理增加
export const addNewCarType=data=>createAPI(urlStr+'/cartypes/addcarType','post',data)
// DELETE
// /cartypes/batch
// 车辆型号管理批量删除
export const delCarType=id=>createAPI(urlStr+'/cartypes/batch?ids='+id,'delete')
// POST
// /cartypes/excelImport/cartypes
// 车辆型号管理批量导入
export const importExcelCarTypes=data=>createAPI(urlStr+'/cartypes/excelImport/cartypes','post',data)
// GET
// /cartypes/findAll
// 车辆型号管理查询所有
export const getCarAll = data=>createAPI(urlStr+'/cartypes/list' + data.url,'get')
// GET
// /cartypes/page
// 分页查询车辆型号
export const getAllCarTypes=data=>createAPI(urlStr+'/cartypes/page?page='+data.page+'&size='+data.size,'get')
// POST
// /cartypes/updateCarType
// 车辆型号管理更新
export const upCarType=data=>createAPI(urlStr+'/cartypes/updateCarType','post',data)

// /cartypes/specifcPage 按照名字和状态分页查询车辆信号
export const getCartypePage=data=>createAPI(urlStr+'/cartypes/specificPage'+data.url,'GET')
