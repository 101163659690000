<template>
    <div class="app-container">
        <el-row class="main-flex">
            <el-col :span="3" class="side-search" v-if="isopenShow">
                <operatorTreeVue @emitToParent='getTreeData' @isShow="isHidden"></operatorTreeVue>
            </el-col>

            <el-col v-if="!isopenShow" class="small-side">
                <div class="change-in" @click="isopenShow = !  isopenShow "></div>
            </el-col>

            <!-- 搜索区域 -->
            <el-col :span="21" class="mian-content">

                <!--                <el-col :span="5">-->
                <!--                    <el-input placeholder="请从左侧选择运营商" v-model.trim="treeData.operatorNameTree" disabled></el-input>-->
                <!--                </el-col>-->
                <div class="head-section">
                    <el-col :span="7">
                        <el-form :inline="true">
                            <el-form-item label="电池序列号：">
                                <el-input v-model.trim="btyNoValue" placeholder="请输入电池序列号" clearable></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="7">
                        <el-form :inline="true" class="left-style">
                            <el-form-item label="电池型号：">
                                <el-select v-model="btyTypeValue" placeholder="请选择电池型号" clearable>
                                    <el-option
                                        clearable
                                        v-for="item in btyTypeList"
                                        :key="item.id"
                                        :label="item.btyTypeName"
                                        :value="item.id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="10">
                        <div class="btn-end">
                            <span class="btn-rest">
                            <el-button @click="restFunction" plain>重置</el-button>
                            </span>
                            <span class="btn-submit">
                            <el-button type="primary" @click="getSearch" plain>查询</el-button>
                            </span>
                        </div>
                    </el-col>
                </div>
                <!--  表格部分-->
                <div class="section-main">
                    <el-col class="choose-mian">
                        <div class="section-title">
                            电池列表
                        </div>
                        <div>
                            <!--选择项栏-->
                            <span class="alert-box">
                          已选择{{ selectList.length }}项
                            <span @click="toggleSelection" style="margin-left:8px;color:#0D6CE4;cursor:pointer;">清空</span>
                        </span>
                            <span class="pain-btn">
                        <el-button
                                   @click="download" plain
                                   v-hasCode="['base:battery:export']">
                            导出
                        </el-button>
                    </span>
                            <span class="pain-btn">
                        <el-button @click="dialogImport = true"
                                   plain
                                   v-hasCode="['base:battery:import']">导入
                        </el-button>
                    </span>
                            <span class="pain-btn">
                                <el-button @click="delFunctionOfbtytype" plain
                                           v-hasCode="['base:battery:delete']">删除
                        </el-button>
                            </span>
                            <span class="add-btn">
                               <el-button type="primary" @click="addBattery" plain
                                          v-hasCode="['base:battery:add']">新增
                        </el-button>
                            </span>
                        </div>

                        <!-- <el-row>
                          <el-col :span="5">
                            <el-button type="primary" icon="el-icon-plus" @click="addBattery" size="small" plain
                                       v-hasCode="['base:battery:add']">新增
                            </el-button>
                            <el-button size="small" icon="el-icon-delete" type="danger" @click="delFunctionOfbtytype" plain
                                       v-hasCode="['base:battery:delete']">选中删除
                            </el-button>
                          </el-col>
                          <el-col :span="10">
                            <el-button type="primary" icon="el-icon-upload2" @click="dialogImport = true" style="margin-left: 15px" plain
                                       size="small" v-hasCode="['base:battery:import']">批量导入
                            </el-button>
                            <el-button type="primary" icon="el-icon-download" size="small" style="margin-left: 15px" @click="download" plain
                                       v-hasCode="['base:battery:export']">
                              批量导出
                            </el-button>
                          </el-col>
                        </el-row> -->
                    </el-col>
                    <el-col :span="24">
                        <el-row class="table-box">
                            <el-table ref="multipleTable" :data="tableData" @selection-change="selectionChange"
                                      :header-cell-style="headerStyleEvent" :cell-class-name="goodsInfo" height="100%">
                                <el-table-column align="center" type="selection" fixed></el-table-column>
                                <el-table-column align="center" type="index" :index="renIndex" label="编号" width="50"></el-table-column>
                                <el-table-column align="center" prop="operatorName" label="归属运营商" :show-overflow-tooltip="true"
                                                 min-width="120px"></el-table-column>
                                <el-table-column align="center" prop="btyNo" label="电池序列号" :show-overflow-tooltip="true"
                                                 min-width="80"></el-table-column>
                                <el-table-column align="center" prop="btyFeature" label="电池性质"></el-table-column>

                                <el-table-column align="center" prop="btyType" label="电池型号名称" :formatter="btyTypeLevelFormat"
                                                 min-width="110">
                                </el-table-column>

                                <el-table-column align="center" prop="bmsHardwareVersion" label="BMS硬件版本"
                                                 min-width="100"></el-table-column>
                                <el-table-column align="center" prop="bmsSoftwareVersion" label="BMS软件版本"
                                                 min-width="100"></el-table-column>
                                <el-table-column align="center" prop="bmsSupplierCode" label="BMS供应商代码"
                                                 min-width="110"></el-table-column>
                                <el-table-column align="center" prop="btyCoresProduceDate" label="电芯生产日期"
                                                 min-width="140"></el-table-column>
                                <el-table-column align="center" prop="btyCoresSupplierCode" label="电芯供应商代码"
                                                 min-width="120"></el-table-column>
                                <el-table-column align="center" prop="btyPackProduceDate" label="电池包生产日期"
                                                 min-width="140"></el-table-column>
                                <el-table-column align="center" prop="btyPackSupplierCode" label="电池包供应商代码"
                                                 min-width="120"></el-table-column>
                                <el-table-column align="center" prop="btyPackTempTestingCount" label="电池包温度检测数量"
                                                 min-width="130"></el-table-column>
                                <el-table-column align="center" prop="btyProdBatch" label="电池生产批次" min-width="120"></el-table-column>
                                <el-table-column align="center" prop="etpSocialCreditCode" label="运营企业统一社会信用代码"
                                                 :show-overflow-tooltip="true"
                                                 min-width="160"></el-table-column>
                                <el-table-column align="center" prop="moduleCount" label="模组数量"></el-table-column>
                                <el-table-column align="center" prop="operationEnterprise" label="电池包所属运营企业" min-width="130"
                                                 :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column align="center" prop="ownerBy" label="电池所属者"></el-table-column>
                                <el-table-column align="center" prop="produceDate" label="生产时间"></el-table-column>
                                <el-table-column align="center" prop="producer" label="生产厂家" min-width="130"
                                                 :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column align="center" prop="ratedCapacity" label="额定容量"></el-table-column>
                                <el-table-column align="center" prop="ratedVoltage" label="额定电压"></el-table-column>
                                <el-table-column align="center" prop="sglBtyRatedVoltage" label="单体蓄电池额定电压"
                                                 min-width="130"></el-table-column>
                                <el-table-column align="center" prop="specification" label="电池规格"></el-table-column>
                                <!--        <el-table-column prop="type" label="电池类型" ></el-table-column>-->

                                <el-table-column align="center" label="操作" min-width="100" fixed="right">
                                    <template slot-scope="scope">
                                        <span class="edit-style"
                                            @click="handleEdit(scope.row)"
                                            v-hasCode="['base:battery:update']"
                                        >编辑
                                        </span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-row>
                    </el-col>
                    <el-col :span="24">
                        <el-row class="pagin">
                            <!-- 分页 -->
                            <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="pageNum"
                                :page-sizes="[10,30, 50,100]"
                                :page-size="pageSize"
                                layout="total, sizes, prev, pager, next"
                                :total="total"
                            ></el-pagination>
                        </el-row>
                    </el-col>

                </div>


            </el-col>


            <!--            <el-col :span="20" :xs="24">-->
            <!--                <el-row style="margin-bottom:5px; margin-top: 5px; margin-left: 8px;">

                                <el-button type="primary" icon="el-icon-plus" @click="addBattery" size="small" plain
                                           v-hasCode="['base:battery:add']">新增
                                </el-button>
                                <el-button size="small" icon="el-icon-delete" type="danger" @click="delFunctionOfbtytype" plain
                                           v-hasCode="['base:battery:delete']">选中删除
                                </el-button>

                                <el-button type="success" icon="el-icon-upload2" @click="dialogImport = true"
                                           style="margin-left: 10px" plain
                                           size="small" v-hasCode="['base:battery:import']">批量导入
                                </el-button>
                                <el-button type="success" icon="el-icon-download" size="small" style="margin-left: 10px"
                                           @click="download" plain
                                           v-hasCode="['base:battery:export']">
                                    批量导出
                                </el-button>

                            </el-row>-->
            <!--                <el-row>-->

            <!--                </el-row>-->
            <!--            </el-col>-->


        </el-row>

        <!-- 新增与电池基础信息 -->
        <el-dialog
            :title="changeBattery?'新增电池信息':'修改电池信息'"
            :visible.sync="addDialogVisible"
            width="70%"
            @close="close">
            <el-form size="small" ref="batteryform" :rules="batteryRules" :model="batteryForm" label-width="150px">
                <el-row>
                    <el-col :span="11">
                        <el-form-item label="归属运营商" prop="operatorId">
                            <TreeSelect v-model="batteryForm.operatorId" :options="treeData.operatorOptions"
                                        placeholder="请选择运营商"/>
                        </el-form-item>
                        <el-form-item label="电池序列号:" prop="btyNo">
                            <el-input v-model="batteryForm.btyNo"></el-input>
                        </el-form-item>
                        <el-form-item label="电池性质:" prop="btyFeature">
                            <el-input v-model="batteryForm.btyFeature" type="number"></el-input>
                        </el-form-item>
                        <el-form-item label="电池型号:" prop="btyType">
                            <el-select v-model="batteryForm.btyType">
                                <el-option
                                    v-for="item in batteryTypeListForm"
                                    :key="item.id"
                                    :label="item.btyTypeName"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="BMS硬件版本:" prop="bmsHardwareVersion">
                            <el-input v-model="batteryForm.bmsHardwareVersion"></el-input>
                        </el-form-item>
                        <el-form-item label="BMS软件版本:" prop="bmsSoftwareVersion">
                            <el-input v-model="batteryForm.bmsSoftwareVersion"></el-input>
                        </el-form-item>
                        <el-form-item label="BMS供应商代码:" prop="bmsSupplierCode">
                            <el-input v-model="batteryForm.bmsSupplierCode"></el-input>
                        </el-form-item>
                        <el-form-item label="电芯生产日期:" prop="btyCoresProduceDate">
                            <el-date-picker
                                v-model="batteryForm.btyCoresProduceDate"
                                type="datetime"
                                style="width:100%;"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                placeholder="选择日期时间">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="电芯供应商代码:" prop="btyCoresSupplierCode">
                            <el-input v-model="batteryForm.btyCoresSupplierCode"></el-input>
                        </el-form-item>
                        <el-form-item label="电池包生产日期:" prop="btyPackProduceDate">
                            <el-date-picker
                                v-model="batteryForm.btyPackProduceDate"
                                style="width:100%;"
                                type="datetime"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                placeholder="选择日期时间">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="电池包供应商代码:" prop="btyPackSupplierCode">
                            <el-input v-model="batteryForm.btyPackSupplierCode"></el-input>
                        </el-form-item>
                        <el-form-item label="电池包温度检测数量:" prop="btyPackTempTestingCount">
                            <el-input v-model="batteryForm.btyPackTempTestingCount" type="number"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item label="电池生产批次:" prop="btyProdBatch">
                            <el-input v-model="batteryForm.btyProdBatch"></el-input>
                        </el-form-item>
                        <el-form-item label="运营企业统一社会信用代码:" prop="etpSocialCreditCode">
                            <el-input v-model="batteryForm.etpSocialCreditCode"></el-input>
                        </el-form-item>
                        <el-form-item label="模组数量:" prop="moduleCount">
                            <el-input v-model="batteryForm.moduleCount" type="number"></el-input>
                        </el-form-item>
                        <el-form-item label="电池包所属运营企业:" prop="operationEnterprise">
                            <el-input v-model="batteryForm.operationEnterprise"></el-input>
                        </el-form-item>
                        <el-form-item label="电池所属者:" prop="ownerBy">
                            <el-input v-model="batteryForm.ownerBy"></el-input>
                        </el-form-item>
                        <el-form-item label="生产时间:" prop="produceDate">
                            <el-date-picker
                                v-model="batteryForm.produceDate"
                                style="width:100%;"
                                type="datetime"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                placeholder="选择日期时间">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="生产厂家:" prop="producer">
                            <el-input v-model="batteryForm.producer"></el-input>
                        </el-form-item>
                        <el-form-item label="额定容量:" prop="ratedCapacity">
                            <el-input v-model="batteryForm.ratedCapacity" type="number"></el-input>
                        </el-form-item>
                        <el-form-item label="额定电压:" prop="ratedVoltage">
                            <el-input v-model="batteryForm.ratedVoltage" type="number"></el-input>
                        </el-form-item>
                        <el-form-item label="单体蓄电池额定电压:" prop="sglBtyRatedVoltage">
                            <el-input v-model="batteryForm.sglBtyRatedVoltage" type="number"></el-input>
                        </el-form-item>
                        <el-form-item label="电池规格:" prop="specification">
                            <el-input v-model="batteryForm.specification"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="newAddOrset">确 定</el-button>
      </span>
        </el-dialog>

        <!-- 批量导入 -->
        <el-dialog title="请选择导入文件" :visible.sync="dialogImport" width="30%" @close="closeLoad">
            <el-upload
                class="upload-demo"
                ref="upload"
                action
                :on-change="handleChange"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :before-upload="beforeUpload"
                :file-list="fileList"
                :limit="1"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                :auto-upload="false"
            >
                <el-button size="small" type="primary">请选择 xlsx / xls 文件</el-button>
            </el-upload>
            <span slot="footer" class="dowload dialog-footer">
        <div>
          <el-button @click="dowtemplate">下载模版</el-button>
          <span>请不要擅自修改或删除模版</span>
        </div>
       <div>
          <el-button @click="dialogImport = false">取消</el-button>
        <el-button type="primary" :disabled="uploadDisabled" style="margin-top: 16px"
                   @click="handleUpload">开始上传</el-button>
       </div>
      </span>
        </el-dialog>

    </div>
</template>

<script>
import '@/assets/font/font.css';
import {
    batteryPage,
    addBattery,
    updateBattery,
    getBatteryTypeAll,
    getBatteryAll,
    batteryLeadFile,
    batchDelBattery,
} from "@/utils/batteryModelManagementApi";
import moment from 'moment';
import axios from "axios";
import operatorTreeVue from "@/components/tree/operatorTree"
import TreeSelect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {operatorTree} from "@/utils/operatorApi";

export default {
    name: 'batteryBasic',
    components: {TreeSelect, operatorTreeVue},
    data() {
        return {
            isopenShow: true,
            //查询参数
            btyNoValue: null,
            btyTypeValue: null,
            clientHeight: 0,

            total: 0,
            pageSize: 10,
            pageNum: 1,
            changeBattery: true,
            tableData: [],
            isPage: true,
            addDialogVisible: false,
            batteryForm: {
                operatorId: null,
                btyNo: "",
                btyFeature: 0,
                btyType: null,
                bmsHardwareVersion: null,
                bmsSoftwareVersion: null,
                bmsSupplierCode: null,
                btyCoresProduceDate: null,
                btyCoresSupplierCode: null,
                btyPackProduceDate: null,
                btyPackSupplierCode: null,
                btyPackTempTestingCount: null,
                btyProdBatch: null,
                etpSocialCreditCode: null,
                moduleCount: 0,
                operationEnterprise: null,
                ownerBy: null,
                produceDate: null,
                producer: null,
                ratedCapacity: 0,
                ratedVoltage: 0,
                sglBtyRatedVoltage: 0,
                specification: null

            },
            batteryTypeList: [],
            btyTypeList: [],
            batteryDownList: [],

            isEdit: false,
            batteryTypeListForm: [],//新增、编辑时选择不同运营商后对应的电池类型数组

            queryInput: "",
            statusSelect: null,
            uploading: false,

            batteryRules: {
                operatorId: [
                    {required: true, message: "请选择归属运营商", trigger: "blur"},
                ],
                btyNo: [
                    {required: true, message: "请输入电池序列号", trigger: "blur"},
                ],
                btyFeature: [
                    {required: true, message: "请选择电池性质", trigger: "blur"},
                ]
            },

            editTile: true,
            selectList: [],
            dialogImport: false,
            fileList: [],//上传的文件列表
            uploadDisabled: true,

            treeData: {
                operatorNameTree: "",
                operatorIdTree: null,
                operatorOptions: undefined,  //运营商树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
            },

        };
    },
    computed: {},
    created() {
        this.getOperatorTree();
        this.getBatteryPage();
        this.getBatteryTypeList();
        this.clientHeight = document.body.clientHeight - 270;

    },
    mounted() {
    },
    // 组件方法
    methods: {
        headerStyleEvent({row, column, rowIndex, columnIndex}) {
            if (columnIndex == 1) {
                return 'padding:0px;'
            }
        },
        goodsInfo(row) {
            if (row.columnIndex == 1) {
                return 'goodsInfo'
            }
        },
        isHidden(message) {
            this.isopenShow = message
        },
        //新增和修改电池信息
        newAddOrset() {
            this.$refs.batteryform.validate(async valid => {
                if (!valid) return this.$msg.info('电池信息不能为空!')
                try {
                    let data = JSON.parse(JSON.stringify(this.batteryForm))

                    if (this.changeBattery) {
                        const res = await addBattery(data)
                        if (res.data.code !== 200) return this.$msg.warning("新增电池信息失败，请重新添加! " + res.data.msg)
                        this.$msg.success('新增电池信息成功!')
                        this.addDialogVisible = false
                        this.getBatteryPage()
                    } else {
                        const res = await updateBattery(data)
                        if (res.data.code !== 200) return this.$msg.error('修改电池信息失败!' + res.data.msg)
                        this.$msg.success('修改电池信息成功!')
                        this.addDialogVisible = false
                        this.getBatteryPage()
                    }
                } catch (e) {
                }
            })
        },

        close() {
            const data = {};
            this.$refs.batteryform.resetFields();
            this.batteryForm = data;
            this.editTile = true
            this.addDialogVisible = false
            this.changeBattery=true
        },

        //换电站信息填充
        compileBattery(data) {
            this.addDialogVisible = true
            this.changeBattery = false
            var list = JSON.parse(JSON.stringify(data))
            this.batteryForm = list
        },

        async getBatteryPage() {
            let data = {page: this.pageNum, size: this.pageSize};
            var url = "page=" + this.pageNum + "&size=" + this.pageSize;
            if (this.treeData.operatorNameTree != "" && this.treeData.operatorNameTree != null) {
                url += "&operatorId=" + this.treeData.operatorIdTree
            } else if (this.treeData.operatorOptions.length > 0) {
                url += "&operatorId=" + this.treeData.operatorOptions[0].id
            }

            if (this.btyNoValue != "" && this.btyNoValue != null) {
                url += "&btyNo=" + this.btyNoValue
            }
            if (this.btyTypeValue != "" && this.btyTypeValue != null) {
                url += "&btyType=" + this.btyTypeValue
            }
            data.url = url;
            const res = await batteryPage(data);
            if (res.status != 200) return this.$msg.error("获取失败");
            this.total = res.data.data.totalElements;
            this.tableData = res.data.data.content;
        },

        getSearch() {
            this.pageNum = 1;
            this.getBatteryPage();
        },

        //获取所有电池型号信息
        async getBatteryTypeList() {
            var data = {};
            data.url = "";
            const res = await getBatteryTypeAll(data);
            this.batteryTypeList = res.data.data;
            this.btyTypeList = res.data.data;
        },

        //获取所有电池型号信息
        async getBatteryTypeListByOperator() {
            var data = {};
            data.url = "";
            this.treeData.operatorIdTree
            if (this.treeData.operatorIdTree != null) {
                data.url = "?operatorId=" + this.treeData.operatorIdTree;
            }
            const res = await getBatteryTypeAll(data);
            this.btyTypeList = res.data.data;
        },

        //修改信息填充
        async handleEdit(row) {
            var data = {};
            data.url = "?operatorId=" + row.operatorId;
            const res = await getBatteryTypeAll(data);
            if (res.data.code == 200) {
                this.batteryTypeListForm = res.data.data
                this.addDialogVisible = true;
                this.editTile = false
                this.isEdit = true
                this.changeBattery = false
                let rowData = this.copyDeep(row);
                this.batteryForm = rowData;
            } else {
                return this.$msg.error("获取运营商下的电池类型信息失败！" + res.data.msg);
            }

        },

        addBattery() {
            this.addDialogVisible = true;
            this.batteryTypeListForm = [];
            this.isEdit = false;
            this.resetForm();
        },

        resetForm() {
            this.batteryForm = {
                operatorId: null,
                btyNo: "",
                btyFeature: 0,
                btyType: null,
                bmsHardwareVersion: null,
                bmsSoftwareVersion: null,
                bmsSupplierCode: null,
                btyCoresProduceDate: null,
                btyCoresSupplierCode: null,
                btyPackProduceDate: null,
                btyPackSupplierCode: null,
                btyPackTempTestingCount: null,
                btyProdBatch: null,
                etpSocialCreditCode: null,
                moduleCount: 0,
                operationEnterprise: null,
                ownerBy: null,
                produceDate: null,
                producer: null,
                ratedCapacity: 0,
                ratedVoltage: 0,
                sglBtyRatedVoltage: 0,
                specification: null
            }
        },

        async delFunctionOfbtytype() {
            let rows = this.selectList;
            if (rows.length === 0) {
                this.$msg({
                    type: "info",
                    message: "请选中删除文件",
                });
            } else {
                const config = await this.$MessageBox
                    .confirm("此操作将永久删除选中项, 是否继续?", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                    .catch((e) => e);
                if (config !== "confirm") return;
                const arrayId = rows.map((item) => {
                    return item.id;
                }).join(",");
                const res = await batchDelBattery(arrayId);
                if (res.status != 200) return this.$msg.error("删除失败");
                this.tableData = this.tableData.filter((item, i) => {
                    return item.id !== arrayId[i];
                });
                this.$refs.multipleTable.clearSelection();
                if (this.pageNum > 1 && this.tableData.length === 0) {
                    this.pageNum = this.pageNum - 1;
                }
                this.$msg.success("删除成功");
                this.getBatteryPage()
            }
        },
        renIndex(index) {
            return (this.pageNum - 1) * this.pageSize + index + 1
        },

        // 分页事件
        async handleSizeChange(val) {
            this.pageSize = val;
            this.getBatteryPage();
        },
        async handleCurrentChange(val) {
            this.pageNum = val;
            this.getBatteryPage();
        },
        copyDeep(templateData) {
            // templateData 是要复制的数组或对象，这样的数组或者对象就是指向新的地址的
            return JSON.parse(JSON.stringify(templateData));
        },
        toggleSelection() {
            this.$refs.multipleTable.clearSelection();
        },
        selectionChange(rows) {
            this.selectList = rows;
        },

        restFunction() {
            this.btyTypeValue = null;
            this.btyNoValue = null;
            this.statusSelect = null;
            this.queryInput = "";
            this.pageNum = 1
            this.pageSize = 10
            this.isPage = true
            this.treeData.operatorNameTree = ""
            this.treeData.operatorIdTree = null
            this.getBatteryPage();
        },

        btyTypeLevelFormat(row) {
            var btyTypeLevelName = "未知"; //stationType
            this.batteryTypeList.filter(item => {
                if (item.id == row.btyType) {
                    btyTypeLevelName = item.btyTypeName
                    return btyTypeLevelName;
                }
            })
            return btyTypeLevelName;
        },

        //excel导出
        async download() {
            const confirm = await this.$MessageBox.confirm('是否导出文件', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(e => e)
            if (confirm !== 'confirm') return
            const list = [
                {
                    label: '归属运营商',
                    prop: 'operatorName'
                },
                {
                    label: '电池序列号',
                    prop: 'btyNo'
                },
                {
                    label: '电池性质',
                    prop: 'btyFeature'
                },
                {
                    label: '电池型号',
                    prop: 'btyType'
                },
                {
                    label: 'BMS硬件版本',
                    prop: 'bmsHardwareVersion'
                },
                {
                    label: 'BMS软件版本',
                    prop: 'bmsSoftwareVersion'
                },
                {
                    label: 'BMS供应商代码',
                    prop: 'bmsSupplierCode'
                },
                {
                    label: '电芯生产日期',
                    prop: 'btyCoresProduceDate'
                },
                {
                    label: '电芯供应商代码',
                    prop: 'btyCoresSupplierCode'
                },
                {
                    label: '电池包生产日期',
                    prop: 'btyPackProduceDate'
                },
                {
                    label: '电池包供应商代码',
                    prop: 'btyPackSupplierCode'
                },
                {
                    label: '电池包温度检测数量',
                    prop: 'btyPackTempTestingCount'
                },
                {
                    label: '电池生产批次',
                    prop: 'btyProdBatch'
                },
                {
                    label: '运营企业统一社会信用代码',
                    prop: 'etpSocialCreditCode'
                },
                {
                    label: '模组数量',
                    prop: 'moduleCount'
                },
                {
                    label: '电池包所属运营企业',
                    prop: 'operationEnterprise'
                },
                {
                    label: '电池所属者',
                    prop: 'ownerBy'
                },
                {
                    label: '生产时间',
                    prop: 'produceDate'
                },
                {
                    label: '生产厂家',
                    prop: 'producer'
                },
                {
                    label: '额定容量',
                    prop: 'ratedCapacity'
                },
                {
                    label: '额定电压',
                    prop: 'ratedVoltage'
                },
                {
                    label: '单体蓄电池额定电压',
                    prop: 'sglBtyRatedVoltage'
                },
                {
                    label: '电池规格',
                    prop: 'specification'
                }
            ]

            //需要异步等待后台请求
            const res = await getBatteryAll()
            if (res.data.code == 200) {
                this.batteryDownList = res.data.data
                this.$download(this.downloadList(), list, '电池信息')
            } else {
                return this.$msg.error("获取信息列表失败");
            }
        },

        downloadList() {
            if (this.selectList.length === 0) {
                return this.batteryDownList.map(item => {
                    //电池型号映射
                    this.batteryTypeList.some(item2 => {
                        if (item2.id === item.btyType) {
                            item.btyType = item2.btyTypeName;
                            return true;
                        }
                    })
                    return item
                })
            } else {
                return this.selectList.map(item => {

                    //电池型号映射
                    this.batteryTypeList.some(item2 => {
                        if (item2.id === item.btyType) {
                            item.btyType = item2.btyTypeName;
                            return true;
                        }
                    })
                    return item;
                })
            }


            /**
             区别单条导出和全部导出
             if (this.batchNum.length === 0) {
         return this.stationList.map(item => {
        } else {
          return this.batchNum.map(item => {
            switch (item.stationType) {
              case 1:
                item.stationType = '单通道左'
                break
              case 2:
                item.stationType = '单通道右'
                break
              case 3:
                item.stationType = '双通道'
                break
              default:
                break;
            }
            return item
          })
        }
             **/
        },

        //下载模版
        dowtemplate() {

            const a = document.createElement("a");
            a.href = "/template/batteryInfo.xlsx";
            a.download = "电池信息导入模板.xlsx";
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            a.remove();

        },

        cleanList() {
            this.$refs.forceTable.clearSelection()
        },
        //清空上传的文件
        closeLoad() {
            this.uploadDisabled = true
            this.$refs.upload.clearFiles();
        },
        //上传文件改变
        handleChange(file, fileList) {
            if (fileList.length > 0) {
                this.uploadDisabled = false
                this.fileList = fileList
            }
        },
        //上传文件之前
        beforeUpload(file) {
            let fileTemp = file.raw
            if (fileTemp) {
                if (fileTemp.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                    fileTemp.type === "application/vnd.ms-excel") {
                } else {
                    this.$msg.warning('附件格式错误，请删除后重新上传!')
                }
            }
        },
        //上传文件移除
        handleRemove(file, fileList) {
            this.fileList = fileList
            if (fileList.length < 1) {
                this.uploadDisabled = true
            }
        },
        //开始上传
        async handleUpload() {
            if (!this.fileList.length === 0) return this.$msg.info('请选择要上传的文件')
            let formData = new FormData()
            formData.append('file', this.fileList[0].raw)
            const res = await batteryLeadFile(formData)
            if (res.data.code !== 200) return this.$msg.error('文件上传失败' + res.data.msg)
            this.$msg.success('文件上传成功')
            this.dialogImport = false
            this.getBatteryPage()
        },
        //删除文件之前的钩子
        async beforeRemove(file) {
            return this.$MessageBox.confirm(`确定移除 ${file.name}？`)
        },

        getTreeData(data) {
            this.treeData = data;
            this.getBatteryTypeListByOperator();
        },

        /** 查询运营商下拉树结构 */
        async getOperatorTree() {
            try {
                this.treeData.operatorOptions = [];
                let res = await operatorTree();
                if (res.status !== 200) return this.$msg.error("获取运营商信息失败");
                this.treeData.operatorOptions.push(res.data.data)
                this.treeData.operatorIdTree = res.data.data.id
                this.treeData.operatorNameTree = res.data.data.label
            } catch (error) {
            }
        },

    },


    watch: {
        'batteryForm.operatorId': {
            async handler(newName, oldName) {
                //修改
                if (this.isEdit == true && newName != null && newName != undefined && (oldName == undefined || oldName == null)) {
                    return;
                }

                //新增、修改时改动过运营商
                if (newName !== null && newName != undefined) {
                    this.batteryForm.btyType = null;
                    let data = {}
                    data.url = "?operatorId=" + newName;
                    const res = await getBatteryTypeAll(data);
                    this.batteryTypeListForm = res.data.data
                }

            },
            deep: true,
            immediate: false
        }
    },


};
</script>

<style lang="less" scoped>
.app-container {
    background: rgba(255, 255, 255, 0);
    padding-left: 0px;
}

.inner-container {
    // min-width: 4000px;
    height: var(--tableHeight);
    overflow: hidden;
}

.el-table {
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
        width: 10px; /*滚动条宽度*/
        height: 10px; /*滚动条高度*/
    }

    /*定义滚动条轨道 内阴影+圆角*/

    /deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
        //   box-shadow: 0px 1px 3px #fff inset; /*滚动条的背景区域的内阴影*/
        border-radius: 10px; /*滚动条的背景区域的圆角*/
        background-color: #fff; /*滚动条的背景颜色*/
    }

    /*定义滑块 内阴影+圆角*/

    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
        //   box-shadow: 0px 1px 3px #dddee0 inset; /*滚动条的内阴影*/
        border-radius: 10px; /*滚动条的圆角*/
        background-color: #dddee0; /*滚动条的背景颜色*/
    }
}

.el-select {
    width: 100%;
}

.el-col {
    border-radius: 4px;
}

.bg-purple-dark {
    background: #ffffff;
}

.bg-purple {
    background: #d3dce6;
}

.bg-purple-light {
    background: #e5e9f2;
}

.grid-content {
    border-radius: 4px;
    min-height: 36px;
}

.row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
}

.alert-text {
    color: #62b2ff;
    margin-left: 10px;
    cursor: pointer;
}

.status-item1 {
    > span {
        padding: 0 3px;
    }

    :first-child {
        width: 5px;
        height: 5px;
        display: inline-block;
        border-radius: 50%;
        background-color: #ff4949;
        transform: translateY(-2px);
    }
}

.status-item2 {
    > span {
        padding: 0 3px;
    }

    :first-child {
        width: 5px;
        height: 5px;
        display: inline-block;
        border-radius: 50%;
        background-color: #6798f9;
        transform: translateY(-2px);
    }
}

.dowload {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
        display: flex;
        align-items: center;

        &:first-child {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            > span {
                font-size: 12px;
                color: #e10505;
            }
        }

        > button {
            margin-top: 0 !important;
        }
    }
}

//重构新增
.side-search {
    width: 200px;
    background: #FFFFFF;
    border-right: #d3dce6 1px solid;
}

.small-side {
    width: 36px;
    height: 36px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.14);
    border-right: 1px solid #EEE;
}

.mian-content {
    flex: 1;
    background: #F5F7FA;
}

.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.head-section {
    padding: 16px;
    background: #FFFFFF;
    overflow: hidden;
}

.btn-end {
    display: flex;
    justify-content: flex-end;
}

.section-main {
    width: 100%;
    //height: calc(100vh - 24px);
    background: #FFFFFF;
    margin-top: 16px;
    padding: 24px 16px 16px 16px;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.choose-mian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
        display: inline-block;
        margin-left: 16px;
    }
}

.alert-box {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
}

.pagin {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.main-flex {
    display: flex;

}

.left-style {
    padding-left: 43px;
}
.edit-style{
    color: #0D6CE4;
    font-size: 14px;
    cursor:pointer;
}
</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';
</style>
