<template>
    <div class="app-container">
        <el-row class="main-flex">
            <el-col :span="3" class="side-search" v-if="isopenShow">
                <monitoring @emitToParent='getTreeData' @isShow="isHidden"></monitoring>
            </el-col>
            <el-col v-if="!isopenShow" class="small-side">
                <div class="change-in" @click="isopenShow = !  isopenShow "></div>
            </el-col>
            <el-col :span="21" class="mian-content">
                <div class="head-section head-bottom">
                    <el-col :span="7">
                        <el-form :inline="true" :rules="rules">
                            <el-form-item label="围栏坐标1：" class="" prop="versions" label-width="101px">
                                <el-input placeholder="请输入围栏坐标1经纬度" v-model.trim="query.versions"
                                          clearable></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="7">
                        <el-form :inline="true" :rules="rules">
                            <el-form-item label="围栏坐标2：" class="" prop="versions" label-width="101px">
                                <el-input placeholder="请输入围栏坐标2经纬度" v-model.trim="query.versions"
                                          clearable></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="7">
                        <el-form :inline="true" :rules="rules">
                            <el-form-item label="围栏坐标3：" class="" prop="versions" label-width="101px">
                                <el-input placeholder="请输入围栏坐标3经纬度" v-model.trim="query.versions"
                                          clearable></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="3" class="add-bottom">
                        <div class="btn-end">
                            <span class="btn-rest">
                        <el-button plain @click="resetSearch">删除</el-button>
                            </span>
                            <span class="btn-submit">
                      <el-button type="primary" plain @click="searchPos">新增</el-button>
                            </span>
                        </div>
                    </el-col>
                    <div>
                        <el-col :span="7">
                            <el-form :inline="true">
                                <el-form-item label="围栏坐标4：" class="" label-width="101px">
                                    <el-input placeholder="请输入围栏坐标4经纬度" v-model.trim="query.versions"
                                              clearable></el-input>
                                </el-form-item>
                            </el-form>
                        </el-col>
                        <el-col :span="7">
                            <el-form :inline="true">
                                <el-form-item label="围栏坐标5：" class="" label-width="101px">
                                    <el-input placeholder="请输入围栏坐标5经纬度" v-model.trim="query.versions"
                                              clearable></el-input>
                                </el-form-item>
                            </el-form>
                        </el-col>
                        <el-col :span="7">
                            <el-form :inline="true">
                                <el-form-item label="围栏坐标6：" class="" label-width="101px">
                                    <el-input placeholder="请输入围栏坐标6经纬度" v-model.trim="query.versions"
                                              clearable></el-input>
                                </el-form-item>
                            </el-form>
                        </el-col>
                        <el-col :span="3">
                            <div class="btn-end">
                            <span class="open-btn">
                        <el-button plain @click="goMap">地图选点</el-button>
                            </span>
                                <span class="btn-rest change-pos">
                      <el-button plain @click="searchPos">下发</el-button>
                            </span>
                            </div>
                        </el-col>
                    </div>

                </div>
                <div class="head-section">
                    <el-col :span="7">
                        <el-form :inline="true">
                            <el-form-item label="车牌号：" class="" label-width="82px">
                                <el-input placeholder="请输入车牌号" v-model.trim="query.versions"
                                          clearable></el-input>
                            </el-form-item>
                        </el-form>

                    </el-col>
                    <el-col :span="7">
                        <el-form :inline="true">
                            <el-form-item label="车队：" class="">
                                <el-input placeholder="请输入车队名称" v-model.trim="query.versions"
                                          clearable></el-input>
                            </el-form-item>
                        </el-form>

                    </el-col>
                    <el-col :span="7">
                        <el-form :inline="true">
                            <el-form-item label="运营商：" class="">
                                <el-select v-model="query.versions" placeholder="请选择名单类型" clearable>
                                    <el-option v-for="item in typeList" :key="item.value" :label="item.label"
                                               :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="3" class="add-bottom">
                        <div class="btn-end">
                            <span class="btn-rest">
                        <el-button plain @click="resetSearch">重置</el-button>
                            </span>
                            <span class="btn-submit">
                      <el-button type="primary" plain @click="searchPos">查询</el-button>
                            </span>
                        </div>
                    </el-col>
                    <el-col :span="24">
                        <el-form :inline="true">
                            <el-form-item label="更新时间：" class="" label-width="82px">
                                <el-input placeholder="请选择运营商" v-model.trim="query.versions"
                                          clearable></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                </div>
                <div class="section-main">
                    <el-col class="choose-mian">
                        <div class="section-title">
                            电子围栏设置列表
                        </div>
                        <div>
                            <!--选择项栏-->
                            <span class="alert-box">
                            <!-- 已选择{{ selectList.length }}项-->
                          已选择0项
                            <span @click="clean" style="margin-left:8px;color:#0D6CE4;cursor:pointer;">清空</span>
                            </span>
                            <span class="pain-btn">
                                <el-button @click="removeAll" plain
                                           v-hasCode="['vehicle:auth:remove']">删除
                                </el-button>
                            </span>
                            <span class="add-btn">
                                <el-button type="primary" @click="addDialogFunction" plain
                                           v-hasCode="['vehicle:auth:add']">新增
                                </el-button>
                            </span>
                        </div>
                    </el-col>
                    <el-col :span="24">
                        <div class="table-box">
                            <el-table
                                :data="tableData"
                                ref="multipleTable"
                                @selection-change="selectionChange"
                                :header-cell-style="headerStyleEvent" :cell-class-name="goodsInfo"
                            >
                                <el-table-column align="center" type="selection" min-width="15"></el-table-column>
                                <el-table-column align="center" type="index" label="编号"
                                                 min-width="20"></el-table-column>
                                <el-table-column align="center" prop="teamVO.teamName" label="车牌号"
                                                 min-width="110"></el-table-column>
                                <el-table-column align="center" prop="phone" label="车队"
                                                 min-width="110"></el-table-column>
                                <el-table-column align="center" prop="carNo" label="运营商"
                                                 min-width="80"></el-table-column>
                                <el-table-column align="center" prop="vin" label="围栏坐标"
                                                 min-width="120"></el-table-column>
                                <el-table-column align="center" prop="mac" label="更新时间"
                                                 min-width="120"></el-table-column>

                                <el-table-column align="center" label="操作" min-width="80">
                                    <template slot-scope="scope">

                                        <el-button @click="handleDetail(scope.row)" type="text" class="btn-color"
                                                   v-hasCode="['miniapp:customervehicle:detail']">详情
                                        </el-button>

                                        <!-- <el-button @click="handleAudit(scope.$index, scope.row)" type="text"
                                                           v-if="scope.row.auditStatus == 0" class="btn-color"
                                                           v-hasCode="['miniapp:customervehicle:audit']">审核
                                                </el-button>

                                                <el-button @click="handleReason(scope.$index, scope.row)" type="text" class="btn-color"
                                                           v-if="scope.row.auditStatus == 2">驳回原因
                                                </el-button>-->
                                    </template>
                                </el-table-column>
                            </el-table>

                        </div>
                    </el-col>
                    <el-col :span="24">
                        <el-row class="pagin">
                            <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="query.pageNum"
                                :page-sizes="[10,30, 50, 100]"
                                :page-size="query.pageSize"
                                layout="total, sizes, prev, pager, next"
                                :total="totalPages"
                            ></el-pagination>
                        </el-row>
                    </el-col>
                </div>
            </el-col>
        </el-row>
        <!-- 地图-->
        <el-dialog class="check-track"
                   title="地图选点"
                   :visible.sync="dialogVisible"
                   @close="closeTrack"
                   width="80%">
            <div class="dialog-card">
                <div class="map-box" id="map-box"></div>
                <div class="btn-end">
                            <span class="btn-rest">
                        <el-button plain @click="closePoint">清除</el-button>
                            </span>
                    <span class="btn-rest change-pos">
                        <el-button plain @click="resetSearch">保存</el-button>
                            </span>
                    <span class="btn-submit">
                      <el-button type="primary" plain @click="pickPoint">选点</el-button>
                            </span>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import monitoring from '@/components/tree/monitoringCarTree.vue';
// import {customerVehiclePage} from "@/utils/customer";

export default {
    name: "vehicleRemoteSetting",
    components: {
        monitoring,
    },
    data() {
        return {
            showMore: false,
            isopenShow: true,
            query: {
                versions: '',
                pageNum: 1,
                pageSize: 10,
                resPageNum: 1,
                operatorId: null,
                teamId: null,
                auditStatus: null,
                phone: null,
                vin: null,
                mac: null,
                carNo: null
            },
            treeData: {
                operatorTeamOptions: [],  //运营商-车队树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
                operatorTeamNameTree: "",
                operatorTeamIdTree: null,
                defaultExpandAll: false,
            },
            selectList: [],
            tableData: [],
            totalPages: 0,
            typeList: [],
            rules: {
                versions: [
                    {required: true, message: ' ', trigger: 'blur',},
                ],
            },
            dialogVisible: false,
            mapData: null,
            mouseTool: null,
            path: [], // 当前绘制的多边形经纬度数组
            polygonItem: [], // 地图上绘制的所有多边形对象
            polyEditors: []  // 所有编辑对象数组

        }
    },
    mounted() {
    },
    destroyed() {

    },
    methods: {
        closePoint() {
            this.mouseTool.close(true)//关闭绘制功能
            // 取消编辑状态
            this.polyEditors.forEach(v => {
                v.close();
            });
            // this.polyEditors=[]
            console.log(this.polygonItem,'0000----=-==')
            this.mapData.clearMap(); // 删除地图所有覆盖物
        },
        //选点  绘制多边形
        pickPoint() {
            var that = this
            if (this.mouseTool) {
                this.mouseTool.close(true)
            }
            this.mouseTool = new AMap.MouseTool(this.mapData);
            console.log(that.mouseTool, 'this.mouseTool')
            //创建多边形围栏
            const polygon = this.mouseTool.polygon({
                fillColor: 'rgba(13, 108, 228, 0.2)',
                strokeColor: 'rgba(13, 108, 228, 1)',
                borderWeight: 3,
                isOutline: true,
                //同Polygon的Option设置
                strokeStyle: 'solid',

            });
            //监听draw事件可获取画好的覆盖物
            this.mouseTool.on('draw', function (event) {
                var polygonItem = event.obj;
                var paths = polygonItem.getPath();//取得绘制的多边形的每一个点坐标
                console.log('覆盖物对象绘制完成各个点的坐标', paths);
                var path = [];  // 编辑的路径
                paths.forEach(v => {
                    path.push([v.lng, v.lat])
                });
                that.path = path;
                that.editRectangle(that.path);
                that.polygonItem.push(event.obj);//存放绘制多边形数据
                that.mapData.remove(event.obj); // 删除多边形
                that.mouseTool.close(true)//关闭绘制功能
            })

        },
        // 编辑围栏
        editRectangle(paths) {
            var that=this
            const path = paths;
            console.log(AMap, 'AMap')
            var polygon = new AMap.Polygon({
                path: path,
                fillColor: 'rgba(13, 108, 228, 0.2)',
                strokeColor: 'rgba(13, 108, 228, 1)',
                borderWeight: 3,
                isOutline: true,
                //同Polygon的Option设置
                strokeStyle: 'solid',
            });

            this.mapData.add(polygon);
            this.polygonItem.push(polygon);
            console.log(this.polygonItem,'this.polygonItem----0')
            // 缩放地图到合适的视野级别
            this.mapData.setFitView([polygon]);

            this.polyEditor = new AMap.PolyEditor(this.mapData, polygon);
            this.polyEditor.open();
            this.polyEditors.push(this.polyEditor);

            this.polyEditor.on('addnode', function (event) {
                console.info('触发事件：addnode', event)
                console.info('修改后的经纬度：', polygon.getPath())
            });

            this.polyEditor.on('adjust', function (event) {
                console.info('触发事件：adjust', event)
                console.info('修改后的经纬度：', polygon.getPath())
            });

            this.polyEditor.on('removenode', function (event) {
                console.info('触发事件：removenode', event)
                console.info('修改后的经纬度：', polygon.getPath())
            });

            this.polyEditor.on('end', function (event) {
                console.info('触发事件： end', event)
                console.info('修改后的经纬度：', polygon.getPath())
                // event.target 即为编辑后的多边形对象
                this.polygonItem = event.target
            });

            //多边形添加双击事件
            polygon.on('dblclick', () => {
                if (this.polyEditor) {
                    this.polyEditor.setTarget(polygon);
                    this.polyEditor.open();
                }
            })
            //绑定右击事件——弹出右键菜单
            polygon.on('rightclick', function (e) {
                that.clearOne(e.lnglat, polygon); //创建右键菜单menu 删（删除多边形）
            });
            // //鼠标高亮效果
            // polygon.on('mouseover', function (e) {
            //   polygon.setOptions({
            //     fillColor: '#1791fc',
            //   })
            // });
            // polygon.on('mouseout', function (e) {
            //   polygon.setOptions({
            //     fillColor: '#FF8D8D',
            //   })
            // });
        },
        //创建右键菜单 删（删除多边形）
        clearOne(lnglat, onePolygon, oneLabel) {
            var that=this
            var rightMenu = new AMap.ContextMenu();
            rightMenu.addItem('删除', function (e) {
                // if (that.isEdit) {
                that.removePolygon(onePolygon);
                rightMenu.close()
                // } else {
                //   that.$messageBlock('error', '当前未在编辑状态');
                // }
            }, 0);
            rightMenu.open(this.mapData, lnglat);
        },
        //删除某个多边形
        removePolygon(onePolygon, oneLabel) {
            this.polyEditor.close(); //关闭编辑器
            this.mapData.remove(onePolygon);
            // this.mapData.remove(oneLabel);
            // 获取覆盖物
            var overlays = this.mapData.getAllOverlays('polygon');
            console.log(overlays,'overlays')
            this.polygonItem = overlays
        },
        closeTrack() {
            this.dialogVisible = false
            if (this.mapData) {
                this.mapData.destroy();
                console.log('销毁map')
            }
        },
        goodsInfo(row) {
            if (row.columnIndex == 1) {
                return 'goodsInfo'
            }
        },
        goMap() {
            this.dialogVisible = true
            new Promise((resolve, reject) => {
                this.AMap(resolve)
                console.log('AMap')
            }).then(() => {
                this.initMap()
                console.log('initMap')
            })
        },
        initMap() {
            this.mapData = new AMap.Map("map-box", {
                zooms: [5.7, 20],                                        //级别
                // center: [114.29893076, 30.60053945],                //中心点坐标
                viewMode: '2D',//使用2D视图
                // center: [113.425981, 35.423209], //中心点坐标
                // resizeEnable: true,
            });

        },
        handleCurrentChange(val) {
            this.query.pageNum = val;
            if (this.isPage) {
                this.getPage();
            } else {
                this.query.resPageNum = val
                this.queryFunction();
            }
        },
        // 分页事件
        handleSizeChange(val) {
            this.query.pageSize = val;
            if (this.isPage) {
                this.getPage();
            } else {
                this.queryFunction();
            }
        },
        headerStyleEvent({row, column, rowIndex, columnIndex}) {
            if (columnIndex == 1) {
                return 'padding:0px;'
            }
        },
        selectionChange(rows) {
            this.selectList = rows;
        },
        addDialogFunction() {

        },
        removeAll() {

        },
        clean() {

        },
        resetSearch() {
            // 取消编辑状态
            this.polyEditors.forEach(v => {
                v.close();
            });
            console.log(this.polygonItem,'this.polygonItem==保存')
        },
        searchPos() {

        },
        getTreeData(data) {
            console.log(data, 'data')
            // const index=data.operatorTeamIdTree.indexOf('_')
            this.treeData = data;
            console.log(this.treeData, 'bbbbbbbbbb')
            this.query.teamId = data.operatorTeamNameTree
            // this.operatorSider()//触发子组件方法更新点位
            // this.getCarStatusTotal()//变化车辆总数
            // this.getalarmsPage()//变化车辆总数
            console.log(this.treeData, '运营商/车队名称')
        },
        isHidden(message) {
            this.isopenShow = message
        },
    },

}
</script>

<style scoped lang="less">
.app-container {
    background: rgba(255, 255, 255, 0);
    padding-left: 0px;
    padding-bottom: 24px;
}

.side-search {
    width: 200px;
    background: #FFFFFF;
    border-right: #d3dce6 1px solid;
}

.small-side {
    width: 36px;
    height: 36px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.14);
    border-right: 1px solid #EEE;
}

.mian-content {
    flex: 1;
    background: #F5F7FA;
}

.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.head-section {
    padding: 16px;
    background: #FFFFFF;
    //height: 68px;
}

.head-section::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.btn-end {
    display: flex;
    justify-content: flex-end;
}

.section-main {
    width: 100%;
    //height: calc(100vh - 24px);
    background: #FFFFFF;
    margin-top: 16px;
    padding: 24px 16px 16px 16px;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.choose-mian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
        display: inline-block;
        margin-left: 16px;
    }
}

.alert-box {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
}

.pagin {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.main-flex {
    display: flex;

}

.left-style {
    padding-left: 43px;
}

.edit-style {
    color: #0D6CE4;
    font-size: 14px;
    cursor: pointer;
}

.more-search {
    display: flex;
    color: #0D6CE4;
    font-size: 14px;
    font-weight: 400;
    line-height: 40px;
    cursor: pointer;
    position: relative;
    left: -15px;

    i {
        line-height: 40px;
    }
}

.btn-color {
    color: #0D6CE4;
}

.head-bottom {
    margin-bottom: 16px;
}

.change-pos {
    margin-left: 16px;
}

.add-bottom {
    margin-bottom: 10px;
}

.open-btn {
    /deep/ .el-button {
        border: 1px solid #0D6CE4;
        color: #0D6CE4;
        background: #fff;
        padding: 8px 14px;
        line-height: 20px;
        font-size: 14px;
        width: 88px;
    }
}

.check-track {
    /deep/ .el-dialog__title {
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
    }
}

#map-box {
    width: 100%;
    height: 600px;
    margin-bottom: 16px;
}

</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';
</style>
