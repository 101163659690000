<template>
    <div class="app-container">
        <el-row class="main-flex">
            <el-col :span="3" class="side-search" v-if="isopenShow">
                <operatorStationTreeVue @emitToParent='getTreeData' @isShow="isHidden"></operatorStationTreeVue>
            </el-col>

            <el-col v-if="!isopenShow" class="small-side">
                <div class="change-in" @click="isopenShow = !  isopenShow "></div>
            </el-col>
            <!-- 搜索区域 -->
            <el-col :span="21" class="mian-content">
                <div class="head-section">
                    <el-col :span="6">
                        <el-form :inline="true">
                            <el-form-item label="车牌号：" label-width="82px">
                                <el-input placeholder="请输入车牌号" v-model.trim="query.carNo" clearable></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="7">
                        <el-form :inline="true">
                            <el-form-item label="VIN码：" class="left-style" label-width="82px">
                                <el-input placeholder="请输入车辆VIN码" v-model.trim="query.carVin"
                                          clearable></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>

                    <el-col :span="7">
                        <el-form :inline="true">
                            <el-form-item label="MAC地址：" class="left-style">
                                <el-input placeholder="请输入MAC地址" v-model.trim="query.mac" clearable></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="4">
                        <div class="btn-end">
                            <span class="more-search" @click="showMore=!showMore"><div>展开</div>
                            <i :class="showMore==true?'el-icon-arrow-down':'el-icon-arrow-up'"></i> </span>
                            <span class="btn-rest">
                        <el-button @click="restFunction" plain>重置</el-button>
                            </span>
                            <span class="btn-submit">
                      <el-button type="primary" @click="queryFunction" plain
                                 v-hasCode="['base:carList:query']">查询</el-button>
                            </span>
                        </div>
                    </el-col>
                </div>
                <transition name="el-zoom-in-top">
                    <div v-show="showMore">
                        <div class="head-section head-section2">
                            <el-col :span="6">
                                <el-form :inline="true">
                                    <el-form-item label="名单类型：" label-width="82px">
                                        <el-select v-model="query.type" placeholder="请选择名单类型" clearable>
                                            <el-option v-for="item in typeList" :key="item.value" :label="item.label"
                                                       :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-form>
                            </el-col>
                            <el-col :span="7">
                                <el-form :inline="true">
                                    <el-form-item label="状态：" class="left-style" label-width="82px">
                                        <el-select placeholder="请选择状态" v-model="query.state" clearable>
                                            <el-option v-for="item in stateList" :key="item.value" :label="item.label"
                                                       :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-form>
                            </el-col>

                        </div>
                    </div>
                </transition>

                <!--  表格部分-->
                <div class="section-main">
                    <el-col class="choose-mian">
                        <div class="section-title">
                            车辆黑白名单列表
                        </div>
                        <div>
                            <!--选择项栏-->
                            <span class="alert-box">
                          已选择{{ selectList.length }}项
                            <span @click="clean" style="margin-left:8px;color:#0D6CE4;cursor:pointer;">清空</span>
                        </span>
                            <span class="pain-btn">
                              <el-button @click="removeAll" plain v-hasCode="['base:carList:delete']">
                                删除
                            </el-button>
                            </span>
                            <span class="add-btn">
                             <el-button type="primary" plain @click="addCarList" v-hasCode="['base:carList:add']">
                                新增
                            </el-button>
                            </span>
                        </div>
                    </el-col>
                    <el-col :span="24">
                        <el-row class="table-box">
                            <el-table :data="tableData" ref="multipleTable" @selection-change="selectionChange"
                                       :header-cell-style="headerStyleEvent"
                                      :cell-class-name="goodsInfo" height="100%">
                                <el-table-column align="center" type="selection" fixed></el-table-column>
                                <el-table-column align="center" type="index" :index="renIndex" label="编号"
                                                 min-width="50"></el-table-column>

                                <el-table-column align="center" prop="operatorName" label="运营商名称"
                                                 :show-overflow-tooltip="true"
                                                 min-width="100"></el-table-column>
                                <el-table-column align="center" prop="stationName" label="场站名称"
                                                 :show-overflow-tooltip="true"
                                                 min-width="100"></el-table-column>
                                <el-table-column align="center" prop="carNo" label="车牌号"
                                                 min-width="100"></el-table-column>
                                <el-table-column align="center" prop="carVin" label="车辆VIN码"
                                                 min-width="100"></el-table-column>
                                <el-table-column align="center" prop="mac" label="mac地址" min-width="100"
                                                 :show-overflow-tooltip="true"></el-table-column>

                                <el-table-column align="center" prop="type" label="名单类型">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.type === 0" style="color:#0D6CE4">白名单</span>
                                        <span v-else-if="scope.row.type === 1" style="color:#FA9600;">黑名单</span>
                                        <span v-else style="color:#FFCD29;">未知</span>
                                    </template>
                                </el-table-column>

                                <el-table-column align="center" prop="auditState" label="状态" width="90">
                                    <template slot-scope="scope">
                                    <span v-if="scope.row.state === 0" style="color:#0D6CE4;">
                                        <i class="vehicle-item"></i> 已启用
                                    </span>
                                        <span v-else-if="scope.row.state === 1" style="color:#FA9600;">
                                        <i class="vehicle-item"></i> 未启用
                                    </span>
                                        <span v-else style="color:#FFCD29;">
                                        <i class="vehicle-item"></i>未知
                                    </span>
                                    </template>
                                </el-table-column>

                                <el-table-column align="center" prop="createTime" label="创建时间" width="160"></el-table-column>

                                <el-table-column align="center" label="操作" width="160" fixed="right" >
                                    <template slot-scope="scope">
                                        <span class="edit-style"
                                         @click="handleEdit(scope.row)"
                                         v-hasCode="['base:carList:update']">编辑
                                        </span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-row>
                    </el-col>

                    <el-col :span="24">
                        <el-row class="pagin">
                            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                                           :current-page="query.pageNum"
                                           :page-sizes="[10,30, 50, 100]"
                                           :page-size="query.pageSize"
                                           layout="total, sizes, prev, pager, next, jumper"
                                           :total="total"
                            ></el-pagination>
                        </el-row>
                    </el-col>

                </div>
                <!--                    <el-input placeholder="左侧选择运营商/场站" v-model.trim="treeData.operatorStationNameTree" disabled></el-input>-->
            </el-col>

            <!--            <el-col :span="20" :xs="24">-->
            <!--                <el-col :span="4">-->
            <!--                    <el-row>-->

            <!--                    </el-row>-->
            <!--                </el-col>-->
            <!--            </el-col>-->


            <el-dialog :title="isAdd?'新增场站车辆黑白名单信息':'修改场站车辆黑白名单信息'" :visible.sync="addDialog"
                       @close="close" width="38%"
                       class="popup_marker"
            >
                <el-form size="middle" ref="addForm" :model="addForm"  :rules="rules" label-position="top">
                    <el-row :gutter="16" :span="24" >
                        <el-col :span="12">
                            <el-form-item label="归属运营商" prop="operatorId">
                                <TreeSelect v-model="addForm.operatorId" :options="operatorTreeData.operatorOptions"
                                            placeholder="请选择运营商"/>
                            </el-form-item>
                        </el-col>
                            <el-col :span="12">
                            <el-form-item label="换电站:" prop="stationNo">
                                <el-select v-model="addForm.stationNo" placeholder="请选择换电站">
                                    <el-option
                                        v-for="item in stationList"
                                        :key="item.stationNo"
                                        :label="item.stationName"
                                        :value="item.stationNo"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            </el-col>
                    </el-row>
                        <el-row :gutter="16" :span="24" >
                        <el-col :span="12">
                            <el-form-item label="车牌号:" prop="carNo">
                                <el-input @change="changeCarNo(addForm.carNo)" v-model="addForm.carNo"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="VIN码:" prop="carVin">
                                <el-input v-model="addForm.carVin" maxlength="17"></el-input>
                            </el-form-item>
                        </el-col>
                        </el-row>
                    <el-row :gutter="16" :span="24" >
                        <el-col :span="12">
                            <el-form-item label="MAC地址:" prop="mac">
                                <el-input v-model="addForm.mac" maxlength="17"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="名单类型:" prop="type">
                                <el-select v-model="addForm.type">
                                    <el-option
                                        v-for="item in typeList"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    ></el-option>
                                </el-select>
                            </el-form-item></el-col>
                    </el-row>
                    <el-row :gutter="16" :span="24" >
                        <el-col :span="12" >
                            <el-form-item label="是否启用" prop="state" class="el-form-item_bottom">
                                <el-radio-group v-model="addForm.state">
                                    <el-radio
                                        v-for="item in stateList"
                                        :key="item.value"
                                        :label="item.value"
                                    >{{ item.label }}
                                    </el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12" >
                            <el-form-item label="备注信息:" prop="remark" class="el-form-item_bottom">
                                <el-input type="textarea" :rows="3" v-model="addForm.remark"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <span slot="footer" class="dialog-footer">
                <el-button @click="addDialog = false">取 消</el-button>
                <el-button type="primary" @click="addFunction">确 定</el-button>
                </span>
            </el-dialog>


        </el-row>
    </div>
</template>

<script>
import '@/assets/font/font.css';
import TreeSelect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {operatorTree} from "@/utils/operatorApi";
import operatorStationTreeVue from "@/components/tree/operatorStationTree";
import {getStationInfoList} from "@/utils/station";
import {
    addCarBlackWhiteList,
    batchDeleteCarBlackWhiteList,
    getCarBlackWhiteListPage,
    updateCarBlackWhiteList
} from "@/utils/vehiclApi";

export default {
    name: "vehicleBlackWhiteList",
    components: {TreeSelect, operatorStationTreeVue},
    data() {
        var licence = (rule, value, cb) => {
            //归属地2位+6位车牌号
            const Reg = /^([京津晋冀蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼渝川贵云藏陕甘青宁新][ABCDEFGHJKLMNPQRSTUVWXY][1-9DF][1-9ABCDEFGHJKLMNPQRSTUVWXYZ]\d{3}[1-9DF]|[京津晋冀蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼渝川贵云藏陕甘青宁新][ABCDEFGHJKLMNPQRSTUVWXY][\dABCDEFGHJKLNMxPQRSTUVWXYZ]{6})$/;
            if (value !== "" && value != null) {
                Reg.test(value) ? cb() : cb(new Error("车牌号格式错误"));
            } else {
                cb()
            }
        };

        //定义数据
        return {
            showMore: false,
            isopenShow: true,
            clientHeight: 0,
            query: {
                pageNum: 1,
                pageSize: 10,
                stationNo: "",
                carNo: "",
                carVin: "",
                mac: "",
                type: null,
                state: null
            },
            //状态列表:0:启用；1:禁用
            stateList: [
                {value: 0, label: "启用"},
                {value: 1, label: "禁用"}
            ],
            //类型列表:0:白名单;1:黑名单
            typeList: [
                {value: 0, label: "白名单"},
                {value: 1, label: "黑名单"}
            ],

            addDialog: false,
            isAdd: false, //是否编辑标志
            selectList: [],
            tableData: [],
            total: 0, //总数

            stationList: [],//运营商列表

            addForm: {
                operatorId: null,
                operatorName: null,
                stationNo: "",
                carNo: "",
                carVin: "",
                mac: "",
                type: null,
                state: null
            },
            rules: {
                operatorId: [
                    {required: true, message: "请选择归属运营商", trigger: "blur"},
                ],
                stationNo: [
                    {required: true, message: "请选择换电站", trigger: "change"},
                ],
                carVin: [
                    {required: false, message: "请输入车辆VIN码", trigger: "blur"},
                    {min: 17, message: '请正确输入17位vin', trigger: 'blur'},
                    {max: 17, message: '请正确输入17位vin', trigger: 'blur'}
                ],
                carNo: [{required: true, message: '请输入车牌号', trigger: "blur"}],
                mac: [
                    {required: false, message: "请输入mac", trigger: "blur"},
                    {min: 17, message: '请正确输入17位mac', trigger: 'blur'},
                    {max: 17, message: '请正确输入17位mac', trigger: 'blur'}
                ],
                type: [
                    {required: true, message: "请选择名单类型", trigger: "blur"},
                ],
                state: [
                    {required: true, message: "请选择状态", trigger: "blur"},
                ]
            },

            //左侧 运营商-场站树
            treeData: {
                operatorStationOptions: undefined,  //运营商-场站树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
                operatorStationNameTree: "",
                operatorStationIdTree: null,
                defaultExpandAll: true,
            },

            //新增、修改时运营商树选择
            operatorTreeData: {
                operatorNameTree: "",
                operatorIdTree: null,
                operatorOptions: undefined,  //运营商树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
            },

        };
    },
    computed: {},
    created() {
        this.clientHeight = document.body.clientHeight - 299;
        //this.getOperatorTeamTree();
        this.getOperatorTree();
        this.getPage();  //车辆列表查询
    },
    methods: {
        headerStyleEvent({row, column, rowIndex, columnIndex}) {
            if (columnIndex == 1) {
                return 'padding:0px;'
            }
        },
        goodsInfo(row) {
            if (row.columnIndex == 1) {
                return 'goodsInfo'
            }
        },
        isHidden(message) {
            this.isopenShow = message
        },
        async getStationList() {
            let data = [];
            data.url = "";
            const res = await getStationInfoList(data);
            if (res.data.code != 200) {
                return this.$msg.error('获取场站列表失败!')
            }
            this.stationList = res.data.data;
        },

        selectionChange(rows) {
            this.selectList = rows;
        },
        onAvater(file) {
            this.fileAvater = file
        },
        addCarList() {
            this.isAdd = true;
            this.stationList = [];
            this.addDialog = true;
            this.addForm = {
                operatorId: null,
                operatorName: null,
                stationNo: "",
                carNo: "",
                carVin: "",
                mac: "",
                type: null,
                state: null
            };
        },

        resetForm() {
            this.isAdd = false;
            this.stationList = [];
            this.addDialog = false;
            this.addForm = {
                operatorId: null,
                operatorName: null,
                stationNo: "",
                carNo: "",
                carVin: "",
                mac: "",
                type: null,
                state: null
            };
        },

        handleSizeChange(val) {
            this.query.pageSize = val;
            this.queryFunction();
        },
        handleCurrentChange(val) {
            this.query.pageNum = val;
            this.getPage();
        },
        close() {
            this.$refs.addForm.resetFields();
            this.addForm = {};
            this.isAdd = false;
            this.addDialog = false
        },
        clean() {
            this.$refs.multipleTable.clearSelection();
        },

        //新增与修改
        addFunction() {
            this.$refs.addForm.validate(async (valid) => {
                if (!valid) return this.$msg.info("名单信息不能为空");
                try {
                    let data = JSON.parse(JSON.stringify(this.addForm));
                    if (this.isAdd) {
                        let res = await addCarBlackWhiteList(data);
                        if (res.data.code != 200) {
                            this.$msg.error("添加名单失败!" + res.data.msg);
                        } else {
                            this.$msg.success("名单信息添加成功");
                            this.addDialog = false;
                            this.getPage();
                        }
                    } else {
                        let res = await updateCarBlackWhiteList(data);
                        if (res.data.code != 200) {
                            this.$msg.error("名单信息修改失败" + res.data.msg);
                        } else {
                            this.$msg.success("名单信息修改成功");
                            this.addDialog = false;
                            this.getPage();
                        }
                    }
                } catch (e) {
                }
            });
        },
        renIndex(index) {
            return (this.query.pageNum - 1) * this.query.pageSize + index + 1
        },


        async getPage() {
            try {
                var data = {};
                var url = "?page=" + this.query.pageNum + "&size=" + this.query.pageSize;
                data.url = url;
                var id = this.treeData.operatorStationIdTree;
                if (id != "" && id != null) {
                    if (id.indexOf("operator") != -1) {
                        id = id.slice(9);
                        data.operatorId = Number(id)
                    } else {
                        id = id.slice(8);
                        data.stationId = Number(id)
                    }
                }

                if (this.query.carNo != "") {
                    data.carNo = this.query.carNo;
                }
                if (this.query.carVin != "") {
                    data.carVin = this.query.carVin;
                }
                if (this.query.mac != "") {
                    data.mac = this.query.mac;
                }
                if (this.query.type != null) {
                    data.type = this.query.type;
                }
                console.log("==========(this.query.state=======[[", this.query.state, "]]============");
                if (this.query.state != null) {
                    console.log("==========(this.query.state2=======[[", this.query.state, "]]============");
                    data.state = this.query.state;
                    console.log("==========(this.query.state3=======[[", data.state, "]]============");
                }
                console.log("==========(this.query.state4=======[[", data, "]]============");
                let res = await getCarBlackWhiteListPage(data);
                if (res.data.code !== 200) return this.$msg.error("获取名单信息列表失败");
                if (res.data.data.data.data == null || res.data.data.data.total == 0) {
                    this.tableData = [];
                    this.total = 0;
                    return this.$msg.success("未查询到相关记录！");
                } else {
                    this.tableData = res.data.data.data.data.list;
                    this.total = res.data.data.data.total;
                }
            } catch (error) {
            }
        },

        async queryFunction() {
            this.query.pageNum = 1;
            this.getPage();
        },

        //修改信息填充
        async handleEdit(row) {
            this.isAdd = false;
            let data = JSON.parse(JSON.stringify(row));
            this.addForm = data;
            this.getStationList();
            this.addDialog = true;
        },

        //重置
        restFunction() {
            this.resetForm();
            this.treeData.operatorStationIdTree = null;
            this.treeData.operatorStationNameTree = "";
            this.operatorTreeData.operatorIdTree = null;
            this.operatorTreeData.operatorNameTree = "";
            this.query = {
                pageNum: 1,
                pageSize: 10,
                stationNo: "",
                carNo: "",
                carVin: "",
                mac: "",
                type: null,
                state: null
            }
            this.getPage();
        },

        changeCarNo(val) {
            this.addForm.carNo = val.toUpperCase();
        },
        async removeAll() {
            if (this.selectList.length === 0)
                return this.$msg.info("请选择需要删除的名单");
            const confirm = await this.$MessageBox.confirm(
                "此操作将删除选中的名单?",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            );
            if (confirm !== "confirm") return;
            const list = this.selectList.map((item) => {
                return item.id;
            });

            const res = await batchDeleteCarBlackWhiteList(list);
            if (res.data.code !== 200) return this.$msg.error("删除失败!" + res.data.msg);
            this.$msg.success("删除成功");
            this.getPage();
        },

        carTypeFormat(row) {
            var carTypeName = "未知";
            this.carModelList.filter(item => {
                if (item.id === row.carType) carTypeName = item.carTypeName
            })
            return carTypeName;
        },

        getTreeData(data) {
            this.treeData = data
        },

        /** 查询运营商下拉树结构 */
        async getOperatorTree() {
            try {
                this.operatorTreeData.operatorOptions = [];
                let res = await operatorTree();
                if (res.data.code !== 200) return this.$msg.error("获取运营商信息失败");
                this.operatorTreeData.operatorOptions.push(res.data.data)
                this.operatorTreeData.operatorIdTree = res.data.data.id
                this.operatorTreeData.operatorNameTree = res.data.data.label
            } catch (error) {
            }
        },
    },

    watch: {
        'addForm.operatorId': {
            async handler(newName, oldName) {
                //修改
                if (oldName == null && this.isAdd == false) {
                    return;
                }

                //新增、修改
                if (newName !== null && newName != undefined && newName != "") {
                    this.addForm.stationNo = null;
                    let data = {}
                    data.url = "?operatorId=" + newName;
                    const res = await getStationInfoList(data);
                    if (res.data.code != 200) {
                        return this.$msg.error('获取场站列表失败!')
                    }
                    this.stationList = res.data.data;
                    return;
                }
            },
            deep: true,
            immediate: false
        },
    },


};
</script>

<style lang='less' scoped>
.app-container {
    background: rgba(255, 255, 255, 0);
    padding-left: 0px;
}

.outer-container {
    width: 100%;
    /* height: var(--tableHeight);*/
    height: 580px;
    overflow: auto;
}

.inner-container {
    // min-width: 3500px;
    height: var(--tableHeight);
    overflow: hidden;
}

.el-table {
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
        width: 10px; /*滚动条宽度*/
        height: 10px; /*滚动条高度*/
    }

    /*定义滚动条轨道 内阴影+圆角*/

    /deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
        box-shadow: 0px 1px 3px #fff inset; /*滚动条的背景区域的内阴影*/
        border-radius: 10px; /*滚动条的背景区域的圆角*/
        background-color: #fff; /*滚动条的背景颜色*/
    }

    /*定义滑块 内阴影+圆角*/

    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
        box-shadow: 0px 1px 3px #eee inset; /*滚动条的内阴影*/
        border-radius: 10px; /*滚动条的圆角*/
        background-color: #eee; /*滚动条的背景颜色*/
    }
}

.el-select {
    width: 100%;
}

.vehicle-item {
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-right: 5px;
}

.clean {
    color: #409eff;
    margin-left: 10px;
    cursor: pointer;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}

.avatar-box {
    width: 100%;
    display: flex;
    align-items: flex-start;

    .avatar-item {
        width: 100px;
        height: 100px;
        border: 1px dashed #666;
        position: relative;
        border-radius: 10px;
        margin-right: 10px;

        > img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
        }

        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 30px;
            color: #666;
        }
    }

    .avatar-item:hover {
        border: 1px dashed #409eff;

        .icon {
            color: #409eff;
        }
    }
}

//重构新增
.side-search {
    width: 200px;
    background: #FFFFFF;
    border-right: #d3dce6 1px solid;
}

.small-side {
    width: 36px;
    height: 36px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.14);
    border-right: 1px solid #EEE;
}

.mian-content {
    flex: 1;
    background: #F5F7FA;
}

.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.head-section {
    padding: 16px;
    background: #FFFFFF;
    overflow: hidden;
}

.btn-end {
    display: flex;
    justify-content: flex-end;
}

.section-main {
    width: 100%;
    //height: calc(100vh - 24px);
    background: #FFFFFF;
    margin-top: 16px;
    padding: 24px 16px 16px 16px;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.choose-mian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
        display: inline-block;
        margin-left: 16px;
    }
}

.alert-box {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
}

.pagin {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.main-flex {
    display: flex;

}

.left-style {
    padding-left: 43px;
}

.edit-style {
    color: #0D6CE4;
    font-size: 14px;
    cursor: pointer;
}
.more-search {
    display: flex;
    color: #0D6CE4;
    font-size: 14px;
    font-weight: 400;
    line-height: 36px;
    cursor: pointer;
    position: relative;
    left: -15px;
    i{
        line-height: 36px;
    }
}
.head-section{
    /deep/.el-input__inner { //更改input大小
        width: 250px !important;
        height: 36px;
        line-height: 36px;
    }
}
.head-section2 {
    overflow: hidden;
    padding: 0 16px 16px 16px;
    height: 100%;
}

/*popup_marker弹窗标记*/
.popup_marker{

    /*   弹窗左右间距*/
    /deep/.el-dialog__header{
        padding: 24px 24px 0 24px;
    }
    /deep/ .el-dialog__body{
        padding: 24px;
    }
    /deep/.el-dialog__footer{
        padding: 0 24px 24px 24px;
    }
    /deep/.inline_element{
        margin-bottom: 16px;
    }
    /deep/.el-form-item--middle{
    margin-bottom: 16px;
}

    /*    弹窗文本框标题*/
    /deep/.el-form-item__label{
        line-height: 20px;
        margin-bottom: 8px;
        padding-bottom: 0;
    }
    /*    消除最后一排底部边框*/
    .el-form-item_bottom{
        margin-bottom: 0;
    }
}
</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';
</style>
