<template>
    <el-card>
        <el-row class="content-hander">
            <div>{{ objData.stationName }}设备信息<i @click="$router.go(-1)" class="iconfont icon-fanhui"></i></div>
        </el-row>
        <el-row style="margin-top: 15px">
            <el-col :span="4">
                <el-input placeholder="请输入换电设备编号" v-model.trim="eqmValue"></el-input>
            </el-col>

            <el-col :span="5" style="margin-left: 15px">
                <el-input placeholder="请输入换电设备名称" v-model.trim="eqmName"></el-input>
            </el-col>

            <el-col :span="4" style="margin-left: 15px">
                <el-button @click="getSearch" type="primary">查询</el-button>
                <el-button @click="resFuntion">重置</el-button>
            </el-col>
            <el-col :span="4">
                <el-button size="small" icon="el-icon-download" @click="updateEquip" type="primary"
                           v-hasCode="['station:equip:update']">设备更新</el-button>
                <el-button size="small" icon="el-icon-download" @click="excelAll" type="primary">批量导出</el-button>

            </el-col>
            <el-col :span="4">
            </el-col>

        </el-row>
        <el-row>

        </el-row>
        <el-row class="equipment">
            <el-table ref="equipTable" id="equipment" @selection-change="batchList" :data="tableData" height="100%">
                <el-table-column type="selection" width="50"></el-table-column>
                <el-table-column label="设备编号" prop="sn" min-width="110"></el-table-column>
                <el-table-column label="设备名称" prop="dn" min-width="110"></el-table-column>
                <el-table-column label="类型代码" prop="tc"></el-table-column>
                <el-table-column label="设备类型代码" prop="dc"></el-table-column>
                <el-table-column label="设备类型名称" prop="dt"></el-table-column>
                <el-table-column label="设备流水号" prop="ds"></el-table-column>
                <el-table-column label="设备型号" prop="en"></el-table-column>

<!--                <el-table-column label="设备状态" prop="ls" min-width="80">
                    <template slot-scope="scope">
                         <span v-if="scope.row.ls === '1'" style="color:#ff8d1a;"> 离线 </span>
                        <span v-else-if="scope.row.ls === '2'" style="color:#1890ff;"> 在线 </span>
                        <span v-else style="color:#808080;"> 未知 </span>
                    </template>
                </el-table-column>-->

                <el-table-column label="设备硬件版本号" prop="hv"></el-table-column>
                <el-table-column label="设备软件版本号" prop="sv"></el-table-column>

                <el-table-column label="更新时间" prop="ut" width="180"></el-table-column>
                <!--
                <el-table-column label="生产厂家" prop="mn"></el-table-column>
                <el-table-column label="安装位置" prop="ns"></el-table-column>
                <el-table-column label="安装时间" prop="ti"></el-table-column>
                -->
                <el-table-column label="操作" fixed="right" min-width="100">
                    <template slot-scope="scope">
                      <el-button size="small" plain round icon="el-icon-edit"
                                 @click="detailEqu(scope.row)" type="primary"
                                 v-hasCode="['base:car:update']">
                        编辑
                      </el-button>


                    </template>
                </el-table-column>
            </el-table>
        </el-row>


        <el-row class="pageY">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="query.pageNum"
                :page-sizes="[10, 30, 50, 100]"
                :page-size="query.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>
        </el-row>


        <!-- 新增与修改站场信息 -->
        <el-dialog
            :title="changeEqm?'设备详情':'设备详情'"
            :visible.sync="isDetail"
            width="50%"
            @close="close">
            <el-form size="middle" ref="eqmform"  :model="eqmForm" label-width="150px">
                <el-row>
                    <el-col :span="11">

                        <el-form-item label="设备编号" prop="sn">
                            <el-input v-model="eqmForm.sn"></el-input>
                        </el-form-item>

                        <el-form-item label="设备名称:" prop="dn">
                            <el-input v-model="eqmForm.dn"></el-input>
                        </el-form-item>

                        <el-form-item label="类型代码:" prop="tc">
                            <el-input v-model="eqmForm.tc"></el-input>
                        </el-form-item>

                        <el-form-item label="设备类型代码:" prop="tc">
                            <el-input v-model="eqmForm.tc"></el-input>
                        </el-form-item>

                        <el-form-item label="设备类型名称:" prop="tc">
                            <el-input v-model="eqmForm.dt"></el-input>
                        </el-form-item>

                        <el-form-item label="设备流水号:" prop="tc">
                            <el-input v-model="eqmForm.ds"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">

                        <el-form-item label="设备状态" prop="sn">
                            <el-select v-model="eqmForm.ls" placeholder="请选择站类型">
                                <el-option
                                    clearable
                                    v-for="item in statusList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                ></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="设备型号:" prop="tc">
                            <el-input v-model="eqmForm.en"></el-input>
                        </el-form-item>

                        <el-form-item label="设备硬件版本号:" prop="tc">
                            <el-input v-model="eqmForm.hv"></el-input>
                        </el-form-item>

                        <el-form-item label="设备软件版本号:" prop="tc">
                            <el-input v-model="eqmForm.sv"></el-input>
                        </el-form-item>

                        <el-form-item label="生产厂家:" prop="tc">
                            <el-input v-model="eqmForm.mn"></el-input>
                        </el-form-item>

                        <el-form-item label="安装位置:" prop="tc">
                            <el-input v-model="eqmForm.ns"></el-input>
                        </el-form-item>

                        <el-form-item label="安装时间:" prop="tc">
                            <el-input v-model="eqmForm.ti"></el-input>
                        </el-form-item>

                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer"></span>
        </el-dialog>

    </el-card>
</template>

<script>
import {eqmPage, setDevListMqtt} from '@/utils/station'

export default {
    name: 'equipement',
    data() {
        return {
            objData: {},
            eqmValue: "",
            eqmName: "",
            stateValue: '',
            stationValue: '',

            isDetail:false,
            changeEqm:true,
            eqmForm:{},

            query: {
                pageNum: 1,
                pageSize: 10,
                resPageNum: 1
            },
            total: 0,
            equipemt: false,
            isequipemt: true,
            isdisabled: false,
            batchNum: [],
            tableData: [],
            equipemtList: [],

            stationList: [],

            statusList:[
                {value: "0", label: "未知"},
                {value: "1", label: "离线"},
                {value: "2", label: "在线"}
            ],

            columns: [
                {prop: 'stationNo', label: '换电站编码'},
                {prop: 'sn', label: '设备编号'},
                {prop: 'dn', label: '设备名称'},
                {prop: 'tc', label: '类型代码'},
                {prop: 'dc', label: '设备类型代码'},
                {prop: 'dt', label: '设备类型名称'},
                {prop: 'ds', label: '设备流水号'},
                {prop: 'en', label: '设备型号'},
                {prop: 'hv', label: '设备硬件版本号'},
                {prop: 'sv', label: '设备软件版本'},
                {prop: 'mn', label: '生产厂家'},
                {prop: 'ti', label: '安装位置'},
                {prop: 'ls', label: '状态'},
                {prop: 'ut', label: '更新时间'}
            ],
            uploadDisabled: true,
        }

    },
    computed: {
        downloadList() {
            var eqmList = [];
            if (this.batchNum.length === 0) {
                eqmList = this.equipemtList;
            } else {
                eqmList = this.batchNum;
            }
            eqmList.forEach(item => {
                if(item.ls === "1"){
                    item.ls = "离线"
                }else if(item.ls === "2"){
                    item.ls = "在线"
                }else{
                    item.ls = "未知"
                }
            });
            return eqmList;
        }
    },
    created() {
        this.objData = JSON.parse(this.$route.query.data)
        this.getSearch();
    },
    methods: {
        async getSearch() {
            const data = {
                stationNo: this.objData.stationNo,
                eqmNo: this.eqmValue,
                eqmName: this.eqmName,
                page:this.query.pageNum,
                size:this.query.pageSize
            }

            /**
             if(this.eqmValue!=""){
                data.eqmNo = this.eqmValue
            }
             if(this.eqmName!=""){
                data.eqmName = this.eqmName
            }
             **/

            const result = await eqmPage(data)
            if (result.data.code !== 200) return this.$msg.error('获取设备列表信息失败!')

            /**
             result.data.data.content.forEach(item => {
                item.releaseDate = moment(item.releaseDate).format('YYYY-MM-DD HH:mm:ss')
            })
             **/
            this.tableData = result.data.data.data;
            this.total = result.data.data.total;
            this.equipemtList = result.data.data.data;
        },
        renIndex(index) {
            return (this.query.pageNum - 1) * this.query.pageSize + index + 1
        },
        batchList(list) {
            this.batchNum = list
        },
        close() {
            this.isdisabled = false
            this.isequipemt = true
        },

        statusFormat(row){
            var statusName = "未知";
            if (row.ls == 1) {
                statusName = "离线";
            }else if(row.ls == 2){
                statusName = "在线";
            }else{
                statusName = "未知";
            }
            return statusName;
        },

        async detailEqu(data) {
            this.isDetail = true;
            this.eqmForm = data;
        },

        resFuntion() {
            this.stationValue = '';
            this.eqmValue = '';
            this.eqmName = '';
            this.stateValue = ''
            this.query = {
                pageNum: 1,
                pageSize: 10,
                resPageNum: 1
            }
            this.getSearch()

        },

        handleSizeChange(val) {
            this.query.pageSize = val
            this.getSearch()
        },
        handleCurrentChange(val) {
            this.query.pageNum = val
            this.getSearch()
        },
        async excelAll() {
            const confirm = await this.$MessageBox.confirm('是否导出文件', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(e => e)
            if (confirm !== 'confirm') return
            this.$download(this.downloadList, this.columns, '换电设备管理')

        },

        async updateEquip() {
            const config = await this.$MessageBox.confirm(
                "向场站下发设备上次指令, 是否继续?",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }
            ).catch(e => e);
            if (config !== "confirm") return;

            const info = {
                "stationCode": this.objData.stationNo
            }
            const result = await setDevListMqtt(info);
            if (result.data.code !== 200) return this.$msg.error("操作失败")
            this.$msg.success("操作成功")
        },

        //下载模版
        dowtemplate() {
            const list = [
                {
                    stationNo: '00000AST00000000',
                    btySwapEqmNo: '00000',
                    btySwapEqmName: '00000',
                    brand: '00000',
                    eqmVer: '1.1.1',
                    sftVer: '1.1.1',
                    installationSite: '位置',
                    unitType: '00000000',
                    releaseDate: '1997-12-12 12:00:00',
                    supplierName: '000000',
                    electricity: 100,
                    voltage: 220,
                    frequency: 35,
                    remark: '0000000',
                }
            ]
            this.$download(list, this.columns, "换电设备管理")
        },

    },
}
</script>

<style lang='less' scoped>

.content-hander {
    height: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-left: 3px solid #2980e1;
    padding-left: 15px;
    font-weight: 700;

    .icon-fanhui {
        color: #2980e1;
        cursor: pointer;
        margin-left: 5px;
    }
}

.app-container {
    padding: 20px;
    background: #ffffff;
}

.app-container .el-col {
    margin-top: 5px;
}

.inner-container {
    min-width: 4000px;
    height: var(--tableHeight);
    overflow: hidden;
}


.el-select, .el-cascader {
    width: 100%;
}

.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 100%;
}

.dowload {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
        display: flex;
        align-items: center;

        &:first-child {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            > span {
                font-size: 12px;
                color: #e10505;
            }
        }

        > button {
            margin-top: 0 !important;
        }
    }
}
.pageY{
    margin-top: 15px;
}
.el-table {
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
        width: 8px; /*滚动条宽度*/
        height: 10px; /*滚动条高度*/
    }
    /*定义滚动条轨道 内阴影+圆角*/
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
        border-radius: 10px; /*滚动条的背景区域的圆角*/
        background-color: #fff; /*滚动条的背景颜色*/
    }
    /*定义滑块 内阴影+圆角*/
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
        border-radius: 10px; /*滚动条的圆角*/
        background-color: #DDDEE0; /*滚动条的背景颜色*/
    }
}
.equipment{
    /deep/.el-table,.el-table__body-wrapper {
        height: 590px !important;
        //background: #00baad;
    }
    /deep/.el-table__cell,th.el-table__cell > .cell {
        padding: 0px;
    }
    /deep/th.el-table__cell {//head
        border-bottom: 1px solid #EEE;
        background: #FAFAFA;
        color: #2F3133;
        font-size: 14px;
        //text-align: center;
        height: 50px;
        line-height: 50px;
    }
    /deep/.el-table__fixed-right-patch{
        background: rgba(255,255,255,0);
    }
    /deep/.el-table__row{
        height: 50px;
        line-height: 50px;
        color: #2F3133;
        font-size: 14px;
    }
    /deep/td.el-table__cell{
        border-bottom: 1px solid #EEE;
    }
    /deep/.el-table__fixed-right{
        right: 8px !important;
    }
    //解决表格滚动到底错行问题
    /deep/.el-table__fixed,.el-table__fixed-right{
        height: 100% !important;
        bottom: 0px !important;
    }
    /deep/ .el-table__body-wrapper {
        height: calc(100% - 8px) !important;
        overflow: auto;
    }
}
</style>
