<template>
  <div class="content-404">
          <div class="content-404-top">
              对不起您所访问的页面不存在或者已删除
          </div>
          <div class="content-404-bottom">
              {{time}}秒后 <span @click="goto">返回</span> 首页
               <!-- {{time}}秒后 <span @click="$router.push('/home')">返回</span> 首页-->
          </div>
  </div>
</template>

<script>
    export default {
    data(){
        return {
           time:5,
           timer:null
        }
    },
    mounted(){
     this.render()
    },
    methods:{
      goto(){
          //window.location.href = window.location.protocol + "//" + window.location.host + "/bigscreen/index.html"
          this.$router.push('/force')
      },

       render(){
          this.timer = setInterval(()=>{
               if(this.time  <= 0){
                   clearInterval(this.timer)
                   this.$router.push('/force')
                   return false
               }
               this.time--

          },1000)
       }
    }
    }
    </script>

<style lang='less' scoped>
 .content-404{
     width: 100%;
     height: 100%;
     background-color: #fff;
     display: flex;
     align-items: center;
     justify-content: center;
     flex-direction: column;
     .content-404-top{
         font-size: 30px;
         border-bottom: 1px solid #ccc;
     }
     .content-404-bottom{
         font-size: 26px;
         >span{
             color: red;
             cursor: pointer;
         }
     }
 }
</style>
