<template>
    <div class="app-container">
        <el-row class="main-flex">
            <!--运营商树-->
            <el-col :span="3" class="side-search" v-if="isopenShow">
                <operatorTeamTreeVue @emitToParent='getTreeData' @isShow="isHidden"></operatorTeamTreeVue>
            </el-col>

            <el-col v-if="!isopenShow" class="small-side">
                <div class="change-in" @click="isopenShow = !  isopenShow "></div>
            </el-col>

            <!-- 搜索区域 -->
            <el-col :span="21" class="mian-content">
                <div class="head-section">
                    <el-col :span="6">
                        <el-form :inline="true">
                            <el-form-item label="订单号：">
                                <el-input placeholder="请输入订单号" v-model.trim="query.orderSn"></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="7">
                        <el-form :inline="true" class="left-style">
                            <el-form-item label="订单类型：" label-width="90px">
                                <!--按照订单类型筛选-->
                                <el-select v-model="query.type" placeholder="请选择订单类型">
                                    <el-option clearable v-for="item in typeOptions" :key="item.value"
                                               :label="item.label" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="7">
                        <el-form :inline="true" class="left-style">
                            <el-form-item label="订单状态：" label-width="90px">
                                <!--按照订单状态筛选-->
                                <el-select v-model="query.status" placeholder="请选择订单状态">
                                    <el-option clearable v-for="item in statusOptions"
                                               :key="item.value" :label="item.label" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="4">
                        <div class="btn-end">
                            <span class="more-search" @click="showMore=!showMore">
                                <div>展开</div>
                            <i :class="showMore==true?'el-icon-arrow-down':'el-icon-arrow-up'"></i> </span>
                            <span class="btn-rest">
                            <el-button @click="resFuntion" plain>重置
                            </el-button>
                            </span>
                            <span class="btn-submit">
                            <el-button @click="getOrderList()" type="primary"
                                       plain>查询
                                </el-button>
                            </span>
                        </div>
                    </el-col>
                </div>
                <transition name="el-zoom-in-top">
                    <div v-show="showMore">
                        <div class="head-section head-section2">
                            <el-col :span="6">
                                <el-form :inline="true">
                                    <el-form-item label="车牌号：">
                                        <el-input placeholder="请输入车牌号" v-model.trim="query.carNo"></el-input>
                                    </el-form-item>
                                </el-form>
                            </el-col>
                            <el-col :span="7">
                                <el-form :inline="true">
                                    <el-form-item label="换电站：" class="left-style" label-width="90px">
                                        <el-select v-model="query.stationNo" filterable placeholder="请选择换电站">
                                            <el-option clearable v-for="item in stationList" :key="item.value"
                                                       :label="item.stationName" :value="item.stationNo"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-form>
                            </el-col>
                            <el-col :span="7">
                                <el-form :inline="true">
                                    <el-form-item label="日期：" class="left-style" label-width="90px">
                                        <el-date-picker v-model="query.date" :picker-options="pickerOptions"
                                                        type="daterange" range-separator="-"
                                                        start-placeholder="开始日期" end-placeholder="结束日期"
                                                        align="right" :clearable="true">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-form>
                            </el-col>
                        </div>
                    </div>
                </transition>
                <!--  表格部分-->
                <div class="section-main">
                    <el-col class="choose-mian">
                        <div class="section-title">
                            支付订单列表
                        </div>
                        <div>
                          <span class="pain-btn">
                                <el-button @click="batchExport()" plain
                                           v-hasCode="['pay:order:export']">
                              导出
                          </el-button>
                            </span>
                        </div>
                    </el-col>
                    <!-- table -->
                    <el-col :span="24">
                        <el-row class="table-box">
                            <el-table ref="orderTable" id="excel-tabel" @selection-change="batchList"
                                      :data="tableData" :header-cell-style="headerStyleEvent"
                                      :cell-class-name="goodsInfo" height="100%">
                                <el-table-column align="center" type="selection" width="46" fixed></el-table-column>
                                <el-table-column align="center" label="编号" :index="renIndex"
                                                 type="index"></el-table-column>

                                <el-table-column align="center" prop="operatorName" label="所属运营商"
                                                 :show-overflow-tooltip="true"></el-table-column>

                                <el-table-column align="center" min-width="90" prop="teamName" label="所属车队"
                                                 :show-overflow-tooltip="true">
                                </el-table-column>

                                <el-table-column prop="carNo" label="车牌号"></el-table-column>
<!--
                                <el-table-column align="center" prop="carVin" label="车辆vin"
                                                 min-width="160"></el-table-column>
                                -->

                                <el-table-column align="center" min-width="220" prop="orderSn"
                                                 label="订单编号"></el-table-column>

                                <!--
                                 <el-table-column prop="stationName" label="场站名称" :show-overflow-tooltip="true" ></el-table-column>
                                 <el-table-column prop="orderExtSn" label="外部订单编号" width="100px" :show-overflow-tooltip="true"></el-table-column>
                                 <el-table-column prop="tradeType" label="交易类型">
                                     <template slot-scope="scope">
                       <span v-if="scope.row.tradeType === 0" class="states">
                         待定
                       </span>
                                         <span v-if="scope.row.tradeType === 1" class="states">
                         微信
                       </span>
                                         <span v-else-if="scope.row.tradeType === 2" class="states">
                         支付宝</span
                                         >
                                     </template>
                                 </el-table-column>

                                 <el-table-column prop="customerName" label="客户"></el-table-column>
                                 <el-table-column prop="trCouponAmt" label="优惠金额"> </el-table-column>
                                 <el-table-column prop="recordTime" label="记录时间"> </el-table-column>

                                      <el-table-column prop="trAmt" label="交易金额"></el-table-column>
                                 -->

                                <!--
                                                            <el-table-column prop="type" label="订单类型">
                                                                <template slot-scope="scope">
                                                                    <span v-if="scope.row.type === 1" class="states"> 充值 </span>
                                                                    <span v-else-if="scope.row.type === 2" class="states">消费</span>
                                                                    <span v-else-if="scope.row.type === 3" class="states">预约</span>
                                                                </template>
                                                            </el-table-column>

                                                            -->

                                <el-table-column align="center" prop="status" label="状态" min-width="60px"
                                                 :show-overflow-tooltip="true">
                                    <template slot-scope="scope">
                                    <span v-if="scope.row.status === 0" class="states">
                                        未付款
                                    </span>
                                        <span v-if="scope.row.status === 1" class="states">
                                        已付款
                                    </span>
                                        <span v-if="scope.row.status === 2" class="states">
                                        付款失败
                                    </span>
                                        <span v-else-if="scope.row.status === 3" class="states">
                                        退款中
                                    </span>
                                        <span v-else-if="scope.row.status === 4" class="states">
                                        退款成功
                                    </span>
                                        <span v-else-if="scope.row.status === 5" class="states">
                                        退款失败
                                    </span>
                                        <span v-else-if="scope.row.status === 9" class="states">
                                        其他
                                    </span>
                                    </template>
                                </el-table-column>

                                <el-table-column align="center" prop="trRealAmt" label="付款金额" min-width="80px"
                                                 :show-overflow-tooltip="true">
                                </el-table-column>
                                <el-table-column align="center" prop="payTime" label="支付时间" min-width="140px">
                                </el-table-column>
                                <el-table-column align="center" label="操作" fixed="right" min-width="80px">
                                    <template slot-scope="scope">
                                      <span @click="handleDetail(scope.row)" class="edit-style edit-margin"
                                            v-hasCode="['pay:order:detail']">详情
                                      </span>


                                        <span @click="handleRefund(scope.row)" class="edit-style"
                                              v-hasCode="['pay:order:refund']" v-if="scope.row.type === 1">退款
                                      </span>

                                        <!--
                                                                            <el-button @click="handleRefund(scope.row)" type="info" plain
                                                                                size="mini" round v-hasCode="['pay:order:refund']" v-if="scope.row.status === 1">退款
                                                                            </el-button>
                                                                            -->

                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-row>
                    </el-col>

                    <!--分页栏-->
                    <el-col :span="24">
                        <el-row class="pagin">
                            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                                           :current-page="query.pageNum"
                                           :page-sizes="[10, 30, 50, 100]" :page-size="query.pageSize"
                                           layout="total, sizes, prev, pager, next" :total="total">
                            </el-pagination>
                        </el-row>
                    </el-col>
                </div>
            </el-col>

        </el-row>

        <!-- 详情弹出框 -->
        <el-dialog @close="detailClose" :title="true ? '订单详情' : '订单详情'" :visible.sync="isDetail" width="65%">
            <div class="dialogCenter1" style="width: 98%; margin: 0 auto">

                <div class="block_1" style="display: flex; justify-content: space-between">
                    <div class="block_1_order">
                        <span>订单号:</span>
                        <span style="margin-left: 10px; font-weight: bolder">{{ orderDetail.orderSn }}</span>
                    </div>
                    <div class="block_1_order">
                        <span>外部订单号:</span>
                        <span style="margin-left: 10px; font-weight: bolder">{{ orderDetail.orderExtSn }}</span>
                    </div>
                </div>

                <div class="block_5 circular">
                    <div class="block_5_box1">
                        <span>账户号:</span>
                        <span class="contentbox">{{ orderDetail.accountPhone }}</span>
                    </div>
                    <div class="block_5_box2">
                        <span>所属车队:</span>
                        <span class="contentbox">{{ orderDetail.teamName }}</span>
                    </div>
                    <div class="block_5_box3">
                        <span>所属运营商:</span>
                        <span class="contentbox">{{ orderDetail.operatorName }}</span>
                    </div>
                    <div class="block_5_box4">
                        <span>订单类型:</span>
                        <span v-if="orderDetail.type === 0" class="contentbox" style="color: #1890ff">扣费</span>
                        <span v-else-if="orderDetail.type === 1" class="contentbox" style="color: #1890ff">充值</span>
                        <span v-else-if="orderDetail.type === 2" class="contentbox"
                              style="color: #1890ff">换电消费</span>
                        <span v-else-if="orderDetail.type === 3" class="contentbox" style="color: #1890ff">预约</span>
                    </div>
                    <div class="block_5_box5">
                        <span>订单状态:</span>
                        <span v-if="orderDetail.status === 0" class="contentbox" style="color: #1890ff">未付款</span>
                        <span v-else-if="orderDetail.status === 1" class="contentbox" style="color: green">已付款</span>
                        <span v-else-if="orderDetail.status === 2" class="contentbox" style="color: red">付款失败</span>
                        <span v-if="orderDetail.status === 3" class="contentbox" style="color: #1890ff">退款中</span>
                        <span v-else-if="orderDetail.status === 4" class="contentbox"
                              style="color: green">退款成功</span>
                        <span v-else-if="orderDetail.status === 5" class="statcontentboxes"
                              style="color: red">退款失败</span>
                        <span v-else-if="orderDetail.status === 9" class="contentbox" style="color: #1890ff">其他</span>
                    </div>
                    <div class="block_5_box6">
                    </div>
                    <div class="block_5_box6">
                        <span>交易金额(元):</span>
                        <span class="contentbox">{{ orderDetail.trAmt }}</span>
                    </div>
                    <div class="block_5_box8">
                        <span>优惠金额(元):</span>
                        <span class="contentbox">{{ orderDetail.trCouponAmt }}</span>
                    </div>
                    <div class="block_5_box7">
                        <span>实付金额(元):</span>
                        <span class="contentbox">{{ orderDetail.trRealAmt }}</span>
                    </div>
                    <div class="block_5_box9">
                        <span>记录时间：</span>
                        <span class="contentbox">{{ orderDetail.recordTime }}</span>
                    </div>
                    <div class="block_5_box10">
                        <span>付款时间：</span>
                        <span class="contentbox">{{ orderDetail.createTime }}</span>
                    </div>
                </div>
                <div class="closeDialog" @click="detailClose">关闭</div>

            </div>
        </el-dialog>

        <!--   消费订单     -->
        <!-- 详情弹出框 -->
        <el-dialog @close="customerOrderClose" v-alterELDialogMarginTop="{ marginTop: '10px' }"
                   :title="true ? '支付订单详情' : '支付订单详情'"
                   :visible.sync="isCustomerOrderDetail" width="78%">
            <div class="dialogCenter" style="width: 98%; margin: 0 auto">
                <div class="block_1">
                    <div class="block_1_order">
                        <span>订单号</span>
                        <span style="margin-left: 10px; font-weight: bolder">{{ customerOrder.orderNo }}</span>
                    </div>
                </div>
                <div class="block_2 circular">
                    <div class="block_2_box1">
                        <span>账户号</span>
                        <span class="contentbox">{{ customerOrder.originalOrder.accountPhone }}</span>
                    </div>
                    <div class="block_2_box2">
                        <span>所属车队</span>
                        <span class="contentbox">{{ customerOrder.originalOrder.teamName }}</span>
                    </div>
                    <div class="block_2_box3">
                        <span>所属运营商</span>
                        <span class="contentbox">{{ customerOrder.originalOrder.operatorName }}</span>
                    </div>
                    <div class="block_2_box4">
                        <span>换电站名称</span>
                        <span class="contentbox">{{ customerOrder.stationName }}</span>
                    </div>
                    <div class="block_2_box5">
                        <span>换电站编码</span>
                        <span class="contentbox">{{ customerOrder.stationNo }}</span>
                    </div>
                </div>
                <div class="block_3 circular">
                    <div class="block_3_box1">
                        <span>车牌号</span>
                        <span class="contentbox">{{ customerOrder.staSwapRecord.cn }}</span>
                    </div>
                    <div class="block_3_box2">
                        <span>车VIN码</span>
                        <span class="contentbox">{{ customerOrder.staSwapRecord.vin }}</span>
                    </div>
                    <div class="block_3_box3">
                        <span>RFID卡号</span>
                        <span class="contentbox">{{ customerOrder.staSwapRecord.rfid }}</span>
                    </div>
                    <div class="block_3_box4">
                        <span>订单是否取消</span>
                        <span v-if="customerOrder.staSwapRecord.od === 0" class="contentbox"
                              style="color: #1890ff">正常</span>
                        <span v-else-if="customerOrder.staSwapRecord.od === 1" class="contentbox"
                              style="color: red">取消</span>
                        <span v-else class="contentbox" style="color: #1890ff"> 未知</span>
                    </div>
                    <div class="block_3_box5">
                        <span>是否成功换电</span>
                        <span v-if="customerOrder.staSwapRecord.ic === 1" class="contentbox"
                              style="color: #1890ff">成功</span>
                        <span v-else-if="customerOrder.staSwapRecord.ic === 0" class="contentbox"
                              style="color: red">失败</span>
                    </div>
                    <div class="block_3_box5">
                        <span>换电结果</span>
                        <span v-if="customerOrder.staSwapRecord.rs === 0" class="contentbox"
                              style="color: #1890ff">正常</span>
                        <span v-else-if="customerOrder.staSwapRecord.rs === 1" class="contentbox"
                              style="color: red">失败</span>
                    </div>
                    <div class="block_3_box5">
                        <span>失败原因</span>
                        <span v-if="customerOrder.staSwapRecord.fr === 0" class="contentbox"
                              style="color: #1890ff">无故障</span>
                        <span v-else-if="customerOrder.staSwapRecord.fr === 1" class="contentbox" style="color: red">一级故障</span>
                        <span v-else-if="customerOrder.staSwapRecord.fr === 2" class="contentbox" style="color: red">二级故障</span>
                        <span v-else-if="customerOrder.staSwapRecord.fr === 3" class="contentbox" style="color: red">三级故障</span>
                        <span v-else-if="customerOrder.staSwapRecord.fr === 4" class="contentbox" style="color: red">四级故障</span>
                        <span v-else-if="customerOrder.staSwapRecord.fr === 5" class="contentbox" style="color: red">五级故障</span>
                        <span v-else-if="customerOrder.staSwapRecord.fr === 6" class="contentbox" style="color: red">六级故障</span>
                        <span v-else class="stacontentboxtes" style="color: #ffeb3b; font-weight: bold">未知</span>
                    </div>
                    <div class="block_3_box5">
                        <span>取电池仓位号</span>
                        <span class="contentbox">{{ customerOrder.staSwapRecord.ucno }}</span>
                    </div>
                    <div class="block_3_box5">
                        <span>放电池仓位号:</span>
                        <span class="contentbox">{{ customerOrder.staSwapRecord.dcno }}</span>
                    </div>
                    <div class="block_3_box5">
                        <span>此次换电次序</span>
                        <span class="contentbox">{{ customerOrder.staSwapRecord.se }}</span>
                    </div>
                    <!--    装车和取车电车相反                -->
                    <div class="block_3_box5">
                        <span>拆卸电池</span>
                        <span class="contentbox">{{ customerOrder.staSwapRecord.udid }}</span>
                    </div>
                    <div class="block_3_box5">
                        <span>拆卸电池SOC</span>
                        <span class="contentbox">{{ customerOrder.staSwapRecord.usoc }}</span>
                    </div>

                    <!--      站控无法获取，屏蔽
                              <div class="block_3_box5">
                                <span>取车电池的SOE</span>
                                <span class="contentbox">{{
                                  customerOrder.staSwapRecord.dsoe
                                }}</span>
                              </div>
                                -->

                    <div class="block_3_box5">
                        <span>取车电池上次换电结算时的SOC</span>
                        <span class="contentbox">{{ customerOrder.staSwapRecord.lsoc }}</span>
                    </div>

                    <!--           站控无法获取，屏蔽
                              <div class="block_3_box5">
                                <span>取车电池上次换电结算时的SOE</span>
                                <span class="contentbox">{{
                                  customerOrder.staSwapRecord.lsoe
                                }}</span>
                              </div>
                               -->
                    <!--    装车和取车电车相反                -->
                    <div class="block_3_box5">
                        <span>装上电池</span>
                        <span class="contentbox">{{ customerOrder.staSwapRecord.dbid }}</span>
                    </div>
                    <div class="block_3_box5">
                        <span>装上电池SOC</span>
                        <span class="contentbox">{{ customerOrder.staSwapRecord.dsoc }}</span>
                    </div>

                    <!--                    站控无法获取，屏蔽
                                        <div class="block_3_box5">
                                            <span>装车电池的SOE</span>
                                            <span class="contentbox">{{
                                                    customerOrder.staSwapRecord.usoe
                                                }}</span>
                                        </div>

                                        -->
                    <div class="block_3_box5">
                        <span>车牌识别/进站时间(秒)</span>
                        <span class="contentbox">{{ customerOrder.staSwapRecord.ct }}</span>
                    </div>
                    <div class="block_3_box5">
                        <span>换电开始时刻</span>
                        <span class="contentbox">{{ customerOrder.staSwapRecord.st }}</span>
                    </div>
                    <div class="block_3_box5">
                        <span>换电结束时刻</span>
                        <span class="contentbox">{{ customerOrder.staSwapRecord.et }}</span>
                    </div>
                    <!-- <div class="block_3_box5">
                      <span>车辆离开时刻</span>
                      <span class="contentbox">{{ customerOrder.staSwapRecord.ot }}</span>
                    </div> -->
                    <div class="block_3_box5">
                        <span>本次换电时间(秒)</span>
                        <span class="contentbox">{{ customerOrder.staSwapRecord.wt }}</span>
                    </div>

                    <!--
                    <div class="block_3_box5">
                        <span>装车电池行驶里程</span>
                        <span class="contentbox">{{ customerOrder.staSwapRecord.tm }}</span>
                    </div>
                    <div class="block_3_box5">
                        <span>车辆总里程</span>
                        <span class="contentbox">{{ customerOrder.staSwapRecord.vtm }}</span>
                    </div>
                    <div class="block_3_box5">
                        <span>车辆累计换电次数</span>
                        <span class="contentbox">{{ customerOrder.staSwapRecord.vct }}</span>
                    </div>
                    <div class="block_3_box5">
                        <span>车辆累计放电量(kWh)</span>
                        <span class="contentbox">{{ customerOrder.staSwapRecord.vtdc }}</span>
                    </div>
                    <div class="block_3_box5">
                        <span>车辆累计回馈电量(kWh)</span>
                        <span class="contentbox">{{ customerOrder.staSwapRecord.vtfe }}</span>
                    </div>
                    <div class="block_3_box5">
                        <span>车辆累计插仓电量(kWh)</span>
                        <span class="contentbox">{{ customerOrder.staSwapRecord.vtcc }}</span>
                    </div>
                    <div class="block_3_box5">
                        <span>车辆累计综合能耗kwh)</span>
                        <span class="contentbox">{{ customerOrder.staSwapRecord.vtec }}</span>
                    </div>
                    <div class="block_3_box5">
                        <span>本次实时放电量(kWh)</span>
                        <span class="contentbox">{{ customerOrder.staSwapRecord.rtdc }}</span>
                    </div>
                    <div class="block_3_box5">
                        <span>上一次结算放电量(kWh)</span>
                        <span class="contentbox">{{ customerOrder.staSwapRecord.lsdc }}</span>
                    </div>
                    <div class="block_3_box5">
                        <span>本次实时回馈电量(kWh)</span>
                        <span class="contentbox">{{ customerOrder.staSwapRecord.rtfc }}</span>
                    </div>
                    <div class="block_3_box5">
                        <span>上一次结算回馈电量(kWh)</span>
                        <span class="contentbox">{{ customerOrder.staSwapRecord.lsfc }}</span>
                    </div>
                    <div class="block_3_box5">
                        <span>本次实时插仓充电量(kWh)</span>
                        <span class="contentbox">{{ customerOrder.staSwapRecord.rtcc }}</span>
                    </div>
                    <div class="block_3_box5">
                        <span>上一次插仓充电量(kWh)</span>
                        <span class="contentbox">{{ customerOrder.staSwapRecord.lscc }}</span>
                    </div>
                    <div class="block_3_box5">
                        <span>本次实时综合能耗(kWh)</span>
                        <span class="contentbox">{{ customerOrder.staSwapRecord.rtec }}</span>
                    </div>
                    <div class="block_3_box5">
                        <span>上一次结算综合能耗(kWh)</span>
                        <span class="contentbox">{{ customerOrder.staSwapRecord.vtfe }}</span>
                    </div>
                    <div class="block_3_box5">
                        <span>待结算电费电量(kWh)</span>
                        <span class="contentbox">{{ customerOrder.staSwapRecord.wsec }}</span>
                    </div>
                    -->

                </div>
                <div class="block_4 circular">
                    <div class="block_4_box1">
                        <span>订单类型</span>
                        <span class="contentbox">消费</span>
                    </div>
                    <div class="block_4_box3">
                        <span>交易金额(元)</span>
                        <span class="contentbox">{{ customerOrder.originalOrder.trAmt }}</span>
                    </div>
                    <div class="block_4_box5">
                        <span>优惠金额(元)</span>
                        <span class="contentbox">{{ customerOrder.originalOrder.trCouponAmt }}</span>
                    </div>
                    <div class="block_4_box4">
                        <span>实付金额(元)</span>
                        <span class="contentbox">{{ customerOrder.originalOrder.trRealAmt }}</span>
                    </div>
                    <div class="block_4_box5">
                        <span>记录时间</span>
                        <span class="contentbox">{{ customerOrder.originalOrder.recordTime }}</span>
                    </div>
                    <div class="block_4_box5" style="display: flex; flex-direction: row; width: 100%"
                         v-if="customerOrder.timeBillingVO.type === 2">
                        <div class="box5_1" style="width: 50%">
                            <span>上次换电订单号:</span>
                            <span class="contentbox">{{ customerOrder.beforeOrderNo }}</span>
                        </div>

                        <!--                站控无法获取，屏蔽
                                                <div class="box5_2" style="width: 50%">
                                                    <span>上次订单装车电池SOE:</span>
                                                    <span class="contentbox">{{ customerOrder.beforeUsoe }}</span>
                                                </div>

                                                -->
                    </div>
                    <div class="block_4_box5">
                        <span>计费模型名称</span>
                        <span class="contentbox">{{ customerOrder.timeBillingVO.name }}</span>
                    </div>

                    <div class="block_4_box5" style="width: 40%">
                        <div class="box1" style="display: flex"
                             v-if="customerOrder.timeBillingVO.type === 1">
                            <div class="box1_1">
                                <span>计费模型</span>
                                <span class="contentbox"> SOE差值(取换电池差值电量) </span>
                            </div>
                            <div class="box1_2">
                                <span>取换电池差值电量(kWh)</span>
                                <span class="contentbox">{{ customerOrder.soeDif }}</span>
                            </div>
                        </div>

                        <div class="box2" style="display: flex"
                             v-if="customerOrder.timeBillingVO.type === 2">
                            <div class="box1_1">
                                <span>计费模型</span>
                                <span class="contentbox"> 剩余SOE(单块电池耗电量) </span>
                            </div>
                            <div class="box1_2">
                                <span>单块电池耗电量(kWh)</span>
                                <span class="contentbox">{{ customerOrder.soeLeft }}</span>
                            </div>
                        </div>

                        <div class="box3" style="display: flex"
                             v-if="customerOrder.timeBillingVO.type === 3">
                            <div class="box1_1">
                                <span>计费模型</span>
                                <span class="contentbox"> 充电电量(电池充电电量) </span>
                            </div>
                            <div class="box1_2">
                                <span>电池充电电量(kWh)</span>
                                <span class="contentbox">{{ customerOrder.chargeEnergy }}</span>
                            </div>
                        </div>

                        <div class="box3" style="display: flex"
                             v-if="customerOrder.timeBillingVO.type === 4">
                            <div class="box1_1">
                                <span>计费模型</span>
                                <span class="contentbox"> SOC差值(取换电池SOC差值电量)</span>
                            </div>
                        </div>
                    </div>


                    <div class="block_4_box5" v-if="customerOrder.socType === 1">
                        <span>实际计费模型</span>
                        <span class="contentbox">SOC差值模型(换电订单)</span>
                    </div>

                    <div class="block_4_box5" v-if="customerOrder.socType === 2">
                        <span>实际计费模型</span>
                        <span class="contentbox">SOC差值模型(充电订单)</span>
                    </div>

                    <div class="block_4_box5" v-if="customerOrder.socType">
                        <span>SOC差值</span>
                        <span class="contentbox">{{ customerOrder.socDif }}</span>
                    </div>

                    <div class="block_4_box5" v-if="customerOrder.socType">
                        <span>SOC差值电量(kWh)</span>
                        <span class="contentbox">{{ customerOrder.socEnergy }}</span>
                    </div>


                    <div class="block_4_box5">
                        <span>平台服务费(元)</span>
                        <span class="contentbox">{{ customerOrder.timeBillingVO.platformFee }}</span>
                    </div>


                    <div class="block_4_box5">
                        <span>收取平台服务费(元)</span>
                        <span class="contentbox">{{ customerOrder.platformTotalFee }}</span>
                    </div>
                    <div class="block_4_box5">
                        <span>换电服务单价(元)</span>
                        <span class="contentbox">{{ customerOrder.timeBillingVO.price }}</span>
                    </div>
                    <div class="block_4_box5">
                        <span>收取换电服务费用(元)</span>
                        <span class="contentbox">{{ customerOrder.serviceTotalFee }}</span>
                    </div>
                    <div class="block_4_box5">
                        <span>电池服务单价(元)</span>
                        <span class="contentbox">{{ customerOrder.timeBillingVO.batFee }}</span>
                    </div>
                    <div class="block_4_box5">
                        <span>收取电池服务费用(元)</span>
                        <span class="contentbox">{{ customerOrder.batTotalFee }}</span>
                    </div>
                    <div class="block_4_box5">
                        <span>合计收取费用(元)</span>
                        <span class="contentbox">{{ customerOrder.totalFee }}</span>
                    </div>

                    <div class="block_4_box5">
                        <span>备注信息</span>
                        <span class="contentbox">{{ customerOrder.originalOrder.remark }}</span>
                    </div>
                </div>
            </div>
            <div class="closeDialog" @click="customerOrderClose">关闭</div>
        </el-dialog>
    </div>
</template>

<script>
import '@/assets/font/font.css';
import {
    customerOrderPage,
    detailByOrderNo,
    orderRefund, payOrderExport
} from "@/utils/order";
import {getForceList} from "@/utils/station";
import moment from "moment";
import operatorTeamTreeVue from "@/components/tree/operatorTeamTree";
import "@/utils/dateUtil";
import {getBeforeDay} from "@/utils/dateUtil";
import {myconfig} from "@/utils/config";
import {AESEncrypt} from "@/utils/encodes";
import * as myStore from "@/utils/stroage";
import axios from "axios";

export default {
    name: "orderName",
    components: {operatorTeamTreeVue},
    data() {
        return {
            showMore: false,
            isopenShow: true,
            statusOptions: [
                {
                    value: null,
                    label: "全部",
                },
                {
                    value: 0,
                    label: "未付款",
                },
                {
                    value: 1,
                    label: "已付款",
                },
                {
                    value: 2,
                    label: "付款失败",
                },
                {
                    value: 3,
                    label: "退款中",
                },
                {
                    value: 4,
                    label: "退款成功",
                },
                {
                    value: 5,
                    label: "退款失败",
                },
                {
                    value: 9,
                    label: "其他",
                },
            ],
            typeOptions: [
                {
                    value: null,
                    label: "全部",
                },
                {
                    value: 0,
                    label: "扣费",
                },
                {
                    value: 1,
                    label: "充值",
                },
                {
                    value: 2,
                    label: "换电消费",
                },
                {
                    value: 3,
                    label: "充电消费",
                },
                {
                    value: 4,
                    label: "其他",
                },
            ],
            query: {
                pageNum: 1,
                pageSize: 10,
                resPageNum: 1,
                date: [],
                operatorId: "",
                type: "",
                status: "",
                teamId: "",
                stationNo: "",
                carNo: "", //车牌号
                orderSn: "", //订单号
                orderExtSn: "", //外部订单号
            },
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }],
                // 时间不能大于当前时间
                disabledDate: (time) => {
                    return time.getTime() > Date.now()
                }
            },
            clientHeight: 0,
            tableData: [],
            batchNum: [],
            orderList: [],
            order: false,
            isOrder: true,
            isPage: true,
            total: 0, //数据总数
            stationList: [],
            isDetail: false,
            orderDetail: {},
            isCustomerOrderDetail: false,
            customerOrder: {
                originalOrder: {},
                timeBillingVO: {},
                staSwapRecord: {},
            }, //消费者订单详情

            //左侧运营商--车队树图
            treeData: {
                operatorTeamOptions: [], //运营商-车队树选项
                defaultProps: {
                    children: "children",
                    label: "label",
                },
                operatorTeamNameTree: "",
                operatorTeamIdTree: null,
                defaultExpandAll: false,
            },
        };
    },
    directives: {
        alterELDialogMarginTop: {
            inserted(el, binding, vnode) {
                el.firstElementChild.style.marginTop = binding.value.marginTop;
            },
        },
    },
    computed: {},
    created() {
        this.getDefaultTime();
        this.getStationList();
        this.getOrderList();
        this.clientHeight = document.body.clientHeight - 240;
        //this.getOrderListAll()
    },
    methods: {
        headerStyleEvent({row, column, rowIndex, columnIndex}) {
            if (columnIndex == 1) {
                return 'padding:0px;'
            }
        },
        goodsInfo(row) {
            if (row.columnIndex == 1) {
                return 'goodsInfo'
            }
        },
        isHidden(message) {
            this.isopenShow = message
        },
        async getOrderList() {
            if (this.query.date === null || this.query.date.length === 0) {
                return this.$msg.error("请选择开始时间和结束时间")
            }
            // if (this.query.type === -1) this.query.type = null
            // if (this.query.status === -1) this.query.status = null
            let id = this.treeData.operatorTeamIdTree;
            if (id !== "" && id !== null) {
                if (id.indexOf("operator") !== -1) {
                    id = id.slice(9);
                    this.query.operatorId = Number(id);
                    this.query.teamId = "";
                } else {
                    id = id.slice(5);
                    this.query.operatorId = "";
                    this.query.teamId = Number(id);
                }
            }

            let page = {
                size: this.query.pageSize,
                current: this.query.pageNum,
            };
            let order = {
                orderSn: this.query.orderSn,
                orderExtSn: this.query.orderExtSn,
                carNo: this.query.carNo,
                type: this.query.type,
                status: this.query.status,
                operatorId: this.query.operatorId,
                teamId: this.query.teamId,
                stationNo: this.query.stationNo,
                recordStartTimeTamp: this.query.date[0].getTime(),
                recordEndTimeTamp: this.query.date[1].getTime(),
                page: page,
            };
            const res = await customerOrderPage(order);
            if (res === undefined || res === null) {
                return this.$msg.error("获取订单信息为空");
            } else if (res.data.code !== 200) {
                return this.$msg.error("获取订单信息失败");
            }
            this.tableData = res.data.data.list;
            this.total = res.data.data.total;
        },

        //获取换电站列表
        async getStationList() {
            const res = await getForceList();
            this.stationList = res.data.data;
            console.log(this.stationList,'this.stationList')
        },

        getDefaultTime() {
            const today = new Date();
            this.query.date[0] = getBeforeDay(today, 30);
            this.query.date[1] = today;
        },

        //查看详情
        async handleDetail(row) {
            let data = {};
            data.id = row.id;
            console.log("===========row=======", row)
            if (row.type === 2) {
                data.orderNo = row.orderSn;
                data.recordTime = moment(row.recordTime).format("YYYY-MM-DD HH:mm:ss");
                const res = await detailByOrderNo(data)
                if (res.data.code === 200 && res.data.data !== null) {
                    this.customerOrder = res.data.data;
                    this.customerOrder.originalOrder = row;
                } else {
                    this.customerOrder = {
                        originalOrder: row,
                        timeBillingVO: {},
                        staSwapRecord: {},
                    }
                }
                this.isCustomerOrderDetail = true;
            } else {
                this.orderDetail = row;
                this.isDetail = true;
            }
        },

        //退款
        async handleRefund(row) {
            let that = this;
            console.log("handleRefund->id:", row.id);
            let param = {
                orderId: row.id,
                teamId: row.teamId,
                refundReason: "",
            };
            let paramStr = JSON.stringify(param);
            let encodeParamStr = AESEncrypt(paramStr, myconfig.restApiAesKey);
            const res = await orderRefund(encodeParamStr);
            if (res == undefined || res == null) {
                return that.$msg.error("退款结果返回空");
            } else if (res.data.code == 200) {
                await that.getOrderList();
            } else {
                return that.$msg.error("退款失败：" + res.data.msg);
            }
        },
        detailClose() {
            this.orderDetail = {};
            this.isDetail = false;
        },

        customerOrderClose() {
            this.customerOrder = {
                originalOrder: {},
                timeBillingVO: {},
                staSwapRecord: {},
            };
            this.isCustomerOrderDetail = false;
        },

        /**
         stationNameFormat(row){
     var stationName = "未知"; //stationType
     this.stationList.some(item => {
     if(item.value.trim() === row.sn.trim()) {
     stationName = item.label
     return true;
     }
     })
     return stationName;
     },
         **/

        batchList(list) {
            this.batchNum = list;
        },

        close() {
            this.$refs.orderFormRef.resetFields();
            this.orderForm = {};
            this.isOrder = true;
        },

        renIndex(index) {
            return (this.query.pageNum - 1) * this.query.pageSize + index + 1;
        },

        cleanList() {
            this.$refs.orderTable.clearSelection();
        },
        async changeSelect() {
        },
        resFuntion() {
            //充值
            this.isPage = true;
            this.treeData.operatorTeamNameTree = "";
            this.treeData.operatorTeamIdTree = null;
            this.query = {
                pageNum: 1,
                pageSize: 10,
                resPageNum: 1,
                date: [],
                operatorId: "",
                status: "",
                type: "",
                teamId: "",
                stationNo: "",
                carNo: "", //车牌号
                orderSn: "", //订单号
                orderExtSn: "", //外部订单号
            };
            this.getDefaultTime();
            this.getOrderList();
        },
        handleSizeChange(val) {
            //每页数量改变
            this.query.pageSize = val;
            if (this.isPage) {
                this.getOrderList();
            } else {
                this.submit();
            }
        },
        handleCurrentChange(val) {
            //当前页数改变
            this.query.pageNum = val;
            console.log("handleCurrentChange->pageNum:", this.query.pageNum);
            if (this.isPage) {
                this.getOrderList();
            } else {
                this.query.resPageNum = val;
                this.submit();
            }
        },

        getTreeData(data) {
            this.treeData = data;
        },

        async batchExport() {
            let id = this.treeData.operatorTeamIdTree;
            if (id !== "" && id !== null) {
                if (id.indexOf("operator") !== -1) {
                    id = id.slice(9);
                    this.query.operatorId = Number(id);
                    this.query.teamId = "";
                } else {
                    id = id.slice(5);
                    this.query.operatorId = "";
                    this.query.teamId = Number(id);
                }
            }
            let order = {
                orderSn: this.query.orderSn,
                orderExtSn: this.query.orderExtSn,
                carNo: this.query.carNo,
                type: this.query.type,
                status: this.query.status,
                operatorId: this.query.operatorId,
                teamId: this.query.teamId,
                stationNo: this.query.stationNo,
                recordStartTimeTamp: this.query.date[0].getTime(),
                recordEndTimeTamp: this.query.date[1].getTime(),
            };

            var url = myconfig.url + "/service-pay" + "/pay/order/export";
            const user = myStore.sessionGetItem("electric-token");
            var token = 'Bearer ' + user.data;
            await axios.post(url, order, {responseType: "blob", headers: {'Authorization': token}})
                .then(response => {
                    console.log("===============获取支付订单信息成功============");
                    const content = response.data;
                    const blob = new Blob([content]);
                    const fileName = "支付订单信息.xls";
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                    URL.revokeObjectURL(link.href);
                    //document.body.removeChild(link);
                }).catch(console.error);


        },

    },
};
</script>

<style lang="less" scoped>
.app-container {
    background: rgba(255, 255, 255, 0);
    padding-left: 0px;
}



::v-deep .el-dialog__header {
    background-color: #fff;
    padding: 24px 32px 0 32px;
    .el-dialog__title {
        font-weight: bolder;

        .dialogCenter1 .block_5 {
            background-color: #e4ebf1;
            display: flex;
            flex-direction: row;
            margin: 10px 0;
            flex-wrap: wrap;

            [class^="block_5_box"] {
                text-align: left;
                box-sizing: border-box;
                padding: 10px 10px;
                display: flex;
                width: 25%;
                flex-direction: column;

                .contentbox {
                    font-weight: bold;
                }
            }
        }
    }
}

.block_5 {
    background-color: #fff;
    display: flex;
    flex-direction: row;
    //margin: 20px 0;
    padding: 20px 20px;
    flex-wrap: wrap;

    [class^="block_5_box"] {
        text-align: center;

        box-sizing: border-box;
        padding: 16px 16px;
        padding-left: 30px;
        display: flex;
        width: 30%;
        // flex-direction: column;
        .contentbox {
            font-weight: bold;
            padding-left: 8px;
        }
    }
}

.block_2 {
    background-color: #e4ebf1;
    display: flex;
    flex-direction: row;
    margin: 10px 0;
    flex-wrap: wrap;

    [class^="block_2_box"] {
        text-align: left;
        box-sizing: border-box;
        padding: 10px 10px;
        display: flex;
        width: 20%;
        flex-direction: column;

        .contentbox {
            font-weight: bold;
        }
    }
}

.block_3 {
    background-color: #e4ebf1;
    display: flex;
    flex-direction: row;
    margin: 10px 0;
    flex-wrap: wrap;

    [class^="block_3_box"] {
        text-align: left;
        box-sizing: border-box;
        padding: 10px 10px;
        display: flex;
        width: 20%;
        flex-direction: column;

        .contentbox {
            font-weight: bold;
        }
    }
}

.block_4 {
    background-color: #e4ebf1;
    display: flex;
    flex-direction: row;
    margin: 10px 0;
    flex-wrap: wrap;

    [class^="block_4_box"] {
        text-align: left;
        box-sizing: border-box;
        padding: 10px 10px;
        display: flex;
        width: 20%;
        flex-direction: column;

        .contentbox {
            font-weight: bold;
        }
    }
}

.box1_1 {
    text-align: left;
    box-sizing: border-box;
    //padding: 10px 10px 10px 0px;
    display: flex;
    width: 50%;
    flex-direction: column;
}

.box1_2 {
    text-align: left;
    box-sizing: border-box;
    //padding: 10px 10px;
    display: flex;
    width: 50%;
    flex-direction: column;
}

.circular {
    border-radius: 5px;
}

.closeDialog {
    width: 80px;
    height: 30px;
    line-height: 30px;
    margin: 0 auto;
    //margin-top: 20px;
    text-align: center;
    color: #ffffff;
    background-color: #3981e2;
}

.el-table {
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
        width: 10px; /*滚动条宽度*/
        height: 10px; /*滚动条高度*/
    }

    /*定义滚动条轨道 内阴影+圆角*/

    /deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
        //   box-shadow: 0px 1px 3px #fff inset; /*滚动条的背景区域的内阴影*/
        border-radius: 10px; /*滚动条的背景区域的圆角*/
        background-color: #fff; /*滚动条的背景颜色*/
    }

    /*定义滑块 内阴影+圆角*/

    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
        //   box-shadow: 0px 1px 3px #dddee0 inset; /*滚动条的内阴影*/
        border-radius: 10px; /*滚动条的圆角*/
        background-color: #dddee0; /*滚动条的背景颜色*/
    }
}

.out-container {
    width: 100%;
    /* height: var(--tableHeight);*/
    height: 660px;
    overflow: auto;
}

.inner-container {
    // min-width: 2600px;
    height: var(--tableHeight);
    overflow: hidden;
    scroll-behavior: smooth;
}

//重构新增
.side-search {
    width: 200px;
    background: #FFFFFF;
    border-right: #d3dce6 1px solid;
}

/deep/ .el-dialog__body{
    padding: 24px 32px !important;
}

.small-side {
    width: 36px;
    height: 36px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.14);
    border-right: 1px solid #EEE;
}

.mian-content {
    flex: 1;
    background: #F5F7FA;
}

.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.head-section {
    padding: 16px;
    background: #FFFFFF;
    overflow: hidden;
}

.btn-end {
    display: flex;
    justify-content: flex-end;
}

.section-main {
    width: 100%;
    //height: calc(100vh - 24px);
    background: #FFFFFF;
    margin-top: 16px;
    padding: 24px 16px 16px 16px;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.choose-mian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
        display: inline-block;
        margin-left: 16px;
    }
}

.alert-box {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
}

.pagin {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.main-flex {
    display: flex;

}

.left-style {
    padding-left: 10px;
}

.edit-style {
    color: #0D6CE4;
    font-size: 14px;
    cursor: pointer;
}

.more-search {
    display: flex;
    color: #0D6CE4;
    font-size: 14px;
    font-weight: 400;
    line-height: 36px;
    cursor: pointer;
    position: relative;
    left: -15px;

    i {
        line-height: 36px;
    }
}

.edit-margin {
    margin-right: 6px;
}

.head-section {
    /deep/ .el-input__inner { //更改input大小
        width: 250px !important;
        height: 36px;
        line-height: 36px;
    }
}
.head-section2 {
    overflow: hidden;
    padding: 0 16px 16px 16px;
    height: 100%;
}
</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';

</style>

