import {createAPI} from '@/utils/request'
const urlStr = '/service-userinfo/dict/type'
const urlStr2 = '/service-userinfo/dict/data'

export const getDictTypeList = data => createAPI(urlStr + `/specificPage?` + data.url , 'post',data)
export const getDictType = (id) => createAPI(urlStr + '/query/' + id, 'get')
export const addDictType = (data) => createAPI(urlStr + '/add' , 'post', data)
export const editDictType = (data) => createAPI(urlStr + '/update' , 'post', data)
export const deleteDictType = (list) => createAPI(urlStr + '/delBatch/' + list, 'delete')
export const refreshDictTypeCache = () => createAPI(urlStr + '/refreshCache', 'get')
export const optionDictTypeSelect = () => createAPI(urlStr + '/optionselect', 'get')


export const getDictDataList = data => createAPI(urlStr2 + `/specificPage?` + data.url , 'post',data)
export const getDictData = (id) => createAPI(urlStr2 + '/query/' + id, 'get')
export const getDictDataByType = (type) => createAPI(urlStr2 + '/selectByType/' + type, 'get')
export const addDictData = (data) => createAPI(urlStr2 + '/add' , 'post', data)
export const editDictData = (data) => createAPI(urlStr2 + '/update' , 'post', data)
export const deleteDictData = (list) => createAPI(urlStr2 + '/delBatch/' + list, 'delete')
