<template>
    <div class="app-container">
        <el-row class="main-flex">
            <!--运营商场站树-->
            <el-col :span="3" class="side-search" v-if="isopenShow">
                <operatorStationTreeVue @emitToParent='getTreeData' @isShow="isHidden"></operatorStationTreeVue>
            </el-col>
            <el-col v-if="!isopenShow" class="small-side">
                <div class="change-in" @click="isopenShow = !isopenShow "></div>
            </el-col>
            <!-- 搜索区域 -->
            <el-col :span="21" class="mian-content">
                <div class="head-section">
                    <!--                    <el-input placeholder="请从左侧选择运营商/场站" v-model.trim="treeData.operatorStationNameTree" disabled>
                    </el-input>-->
                    <el-col :span="6">
                        <el-form :inline="true">
                            <el-form-item label="订单状态：" label-width="82px">
                                <el-select v-model="query.payStatus" placeholder="请选择订单状态" clearable>
                                    <el-option clearable v-for="item in statusOptions" :key="item.value" :label="item.label"
                                               :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="7">
                        <el-form :inline="true">
                            <el-form-item label="VIN码：" class="left-style" label-width="82px">
                                <el-input placeholder="请输入vin" v-model.trim="query.vin" clearable></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="7">
                        <el-form :inline="true">
                            <el-form-item label="订单号：" class="left-style">
                                <el-input placeholder="请输入订单号" v-model.trim="query.orderNo" clearable></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="4">
                        <div class="btn-end">
                            <span class="more-search" @click="showMore=!showMore">
                                <div>展开</div>
                            <i :class="showMore==true?'el-icon-arrow-down':'el-icon-arrow-up'"></i> </span>
                            <span class="btn-rest">
                        <el-button @click="resFuntion()" plain>重置
                    </el-button>
                            </span>
                            <span class="btn-submit">
                      <el-button @click="getSwapOrderList()" type="primary" plain
                                 >查询
                    </el-button>
                            </span>
                        </div>
                    </el-col>
                </div>
                <transition name="el-zoom-in-top">
                    <div v-show="showMore">
                        <div class="head-section head-section2">
                            <el-col :span="6">
                                <el-form :inline="true">
                                    <el-form-item label="车牌号：" label-width="82px">
                                        <el-input placeholder="请输入车牌号" v-model.trim="query.carNo" clearable></el-input>
                                    </el-form-item>
                                </el-form>

                            </el-col>
                            <el-col :span="7">
                                <el-form :inline="true">
                                    <el-form-item label="初始订单：" class="left-style" label-width="82px">
                                        <el-select v-model="query.init" placeholder="是否认证时初始订单" clearable>
                                            <el-option clearable v-for="item in initOptions" :key="item.value" :label="item.label"
                                                       :value="item.value"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-form>
                            </el-col>
                            <el-col :span="7">
                                <el-form :inline="true">
                                    <el-form-item label="选择月：" class="left-style">
                                        <el-date-picker value-format="yyyy-MM" v-model="query.recordTime" type="month"
                                                        placeholder="选择月(默认当前月)" clearable></el-date-picker>
                                    </el-form-item>
                                </el-form>
                            </el-col>
                        </div>
                        <div class="head-section head-section2">
                            <el-col :span="6">
                                <el-form :inline="true">
                                    <el-form-item label="手动扣款：" label-width="82px">
                                        <el-select v-model="query.isManual" placeholder="请选择扣款状态" clearable>
                                            <el-option clearable v-for="item in manualOptions" :key="item.value" :label="item.label"
                                                       :value="item.value"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-form>
                            </el-col>
                        </div>
                    </div>
                </transition>

                <!--  表格部分-->
                <div class="section-main">
                    <el-col class="choose-mian">
                        <div class="section-title">
                            换电订单列表
                        </div>
                        <div>
                            <span class="pain-btn">
                             <el-button @click="batchExport()" plain
                                        v-hasCode="['swap:order:export']">导出
                        </el-button>
                            </span>
                        </div>
                    </el-col>
                    <!-- table -->
                    <el-col :span="24">
                        <el-row class="table-box">

                            <el-table ref="orderTable" id="excel-tabel" :header-cell-style="headerStyleEvent"
                                      :cell-class-name="goodsInfo"
                                      :data="tableData" height="100%">
                                <el-table-column align="center" label="编号" :index="renIndex" type="index"></el-table-column>
                                <el-table-column align="center" prop="stationNo" label="场站编码" :show-overflow-tooltip="true"
                                                 min-width="100"></el-table-column>
                                <el-table-column align="center" min-width="120" prop="stationName" label="场站名称"
                                                 :show-overflow-tooltip="true">
                                </el-table-column>
                                <el-table-column align="center" prop="carNo" label="车牌号" :formatter="carNoFormat" min-width="80"></el-table-column>
                                <!--
                                <el-table-column align="center" prop="carVin" label="vin" :formatter="carVinFormat"
                                                 min-width="160"></el-table-column>
                                -->
                                <el-table-column align="center" prop="orderNo" label="换电订单号" min-width="250"></el-table-column>

                                <el-table-column align="center" prop="swapResult" label="换电结果" min-width="80px">
                                    <template slot-scope="scope">
                                    <span v-if="scope.row.staSwapRecord !== null && scope.row.staSwapRecord.ic === 1"
                                          class="states" style="color:#0D6CE4;"> 正常 </span>
                                        <span
                                            v-else-if="scope.row.staSwapRecord !== null && scope.row.staSwapRecord.ic === 0"
                                            class="states" style="color:#FF6165;">失败</span>
                                        <span v-else class="states"
                                              style="color:#FF6165;">未知</span>
                                    </template>
                                </el-table-column>

                                <!--
                                 <el-table-column prop="sfs" label="上报方式" :formatter="sfsFormat" ></el-table-column>
                                 <el-table-column prop="sflx" label="是否离线订单" :formatter="sflxFormat" min-width="100"></el-table-column>
                                 -->

                                <el-table-column align="center" prop="type" label="订单状态" min-width="70px">
                                    <template slot-scope="scope">
                                    <span v-if="scope.row.payStatus === 1" class="states"
                                          style="color:#0D6CE4;">付款成功</span>
                                        <span v-else-if="scope.row.payStatus === 2" class="states"
                                              style="color:#FF6165;">付款失败</span>
                                        <span v-else-if="scope.row.payStatus === 0" class="states"
                                              style="color:#FA9600;">尚未支付</span>
                                    </template>
                                </el-table-column>

                                <el-table-column align="center" prop="type" label="换电时长(秒)" min-width="80px">
                                    <template slot-scope="scope">
                                    <span v-if="scope.row.staSwapRecord !== null">
                                        {{scope.row.staSwapRecord.wt}}
                                    </span>
                                        <span v-else>未知</span>
                                    </template>
                                </el-table-column>

                                <!--
                                <el-table-column prop="type" label="计费模型" min-width="80px">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.costType === 1" class="states">SOE差值</span>
                                        <span v-else-if="scope.row.costType === 2" class="states">剩余SOE</span>
                                        <span v-else-if="scope.row.costType === 3" class="states">充电电量</span>
                                        <span v-else class="states">未知</span>
                                    </template>
                                </el-table-column>
                                -->

                                <el-table-column align="center" prop="createTime" label="创建时间" min-width="150px">
                                </el-table-column>

                                <el-table-column align="center" label="操作" fixed="right" min-width="120px">
                                    <template slot-scope="scope">
                                        <span @click="manualCharging(scope.row)" class="edit-style" v-hasCode="['swap:order:manual']"
                                              v-if="scope.row.init !== 0 && scope.row.payStatus !== 1 && scope.row.staSwapRecord !== null && scope.row.staSwapRecord.runModel !== 0">

                                            手动扣费
                                        </span>

                                        <span @click="handleDetail(scope.row)" class="edit-style">
                                            详情
                                        </span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-row>
                    </el-col>

                    <!--分页栏-->
                    <el-col :span="24">
                        <el-row class="pagin">
                            <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="query.pageNum"
                                :page-sizes="[10, 30, 50, 100]"
                                :page-size="query.pageSize"
                                layout="total, sizes, prev, pager, next"
                                :total="total">
                            </el-pagination>
                        </el-row>
                    </el-col>
                </div>
            </el-col>


        </el-row>


        <el-dialog @close="customerOrderClose" :title="true ? '换电订单详情' : '换电订单详情'"
                  :visible.sync="isCustomerOrderDetail" width="78%">
            <div class="dialogCenter" style="width: 98%;">
                <div class="block_1">
                    <div class="block_1_order">
                        <span>订单号:</span>
                        <span style="margin-left: 10px; font-weight: bolder">{{ swapOrder.orderNo }}</span>
                    </div>
                </div>

                <div class="block_2 circular">
                    <div class="block_2_box4">
                        <span>换电站名称:</span>
                        <span class="contentbox">{{ swapOrder.stationName }}</span>
                    </div>
                    <div class="block_2_box5">
                        <span>换电站编码:</span>
                        <span class="contentbox">{{ swapOrder.stationNo }}</span>
                    </div>

                    <div class="block_2_box5">
                        <span>计费模型:</span>
                        <span v-if="swapOrder.costType === 1" class="states"> SOE差值 </span>
                        <span v-else-if="swapOrder.costType === 2" class="states">剩余SOE</span>
                        <span v-else-if="swapOrder.costType === 3" class="states">充电电量</span>
                        <span v-else class="states">未知</span>
                    </div>


                    <div class="block_2_box5">
                        <span>创建时间:</span>
                        <span class="contentbox">{{ swapOrder.createTime }}</span>
                    </div>

                    <div class="block_2_box5">
                        <span></span>
                        <span class="contentbox"></span>
                    </div>

                    <div class="block_2_box5">
                        <div v-if="swapOrder.socType === 1">
                            <span>实际计费模型:</span><br>
                            <span class="contentbox">SOC差值模型(换电订单)</span>
                        </div>
                        <div v-else-if="swapOrder.socType === 2">
                            <span>实际计费模型:</span><br>
                            <span class="contentbox">SOC差值模型(充电订单)</span>
                        </div>
                    </div>

                    <div class="block_2_box5">
                        <div v-if="swapOrder.socType === 1 || swapOrder.socType === 2">
                            <span>SOC差值:</span><br>
                            <span class="contentbox">{{ swapOrder.socDif }}</span>
                        </div>
                    </div>
                    <div class="block_2_box5">
                        <div v-if="swapOrder.socType === 1 || swapOrder.socType === 2">
                            <span>SOC差值电量(kWh):</span><br>
                            <span class="contentbox">{{ swapOrder.socEnergy }}</span>
                        </div>
                    </div>


                </div>

                <div class="block_3 circular">
                    <div class="block_3_box1">
                        <span>车牌号:</span>
                        <span class="contentbox">{{ swapOrder.staSwapRecord.cn }}</span>
                    </div>
                    <div class="block_3_box2">
                        <span>车VIN码:</span>
                        <span class="contentbox">{{ swapOrder.staSwapRecord.vin }}</span>
                    </div>
                    <div class="block_3_box3">
                        <span>RFID卡号:</span>
                        <span class="contentbox">{{ swapOrder.staSwapRecord.rfid }}</span>
                    </div>
                    <div class="block_3_box4">
                        <span>订单是否取消</span>
                        <span v-if="swapOrder.staSwapRecord.od === 0" class="contentbox"
                              style="color: #1890ff">正常</span>
                        <span v-else-if="swapOrder.staSwapRecord.od === 1" class="contentbox"
                              style="color: red">取消</span>
                        <span v-else class="contentbox" style="color: orange"> 未知</span>
                    </div>
                    <div class="block_3_box5">
                        <span>是否成功换电</span>
                        <span v-if="swapOrder.staSwapRecord.ic === 1" class="contentbox" style="color: #1890ff">
                            成功
                        </span>
                        <span v-else-if="swapOrder.staSwapRecord.ic === 0" class="contentbox" style="color: red">
                            失败
                        </span>
                        <span v-else class="contentbox" style="color: orange">
                            未知
                        </span>
                    </div>
                    <div class="block_3_box5">
                        <span>换电结果</span>
                        <span v-if="swapOrder.staSwapRecord.ic === 1" class="contentbox" style="color: #1890ff">
                            正常
                        </span>
                        <span v-else-if="swapOrder.staSwapRecord.ic === 0" class="contentbox" style="color: red">
                            失败
                        </span>
                        <span v-else class="contentbox" style="color: orange">
                            未知
                        </span>
                    </div>
                    <div class="block_3_box5">
                        <span>失败原因</span>
                        <span v-if="swapOrder.staSwapRecord.fr === 0" class="contentbox"
                              style="color: #1890ff">无故障</span>
                        <span v-else-if="swapOrder.staSwapRecord.fr === 1" class="contentbox"
                              style="color: red">一级故障</span>
                        <span v-else-if="swapOrder.staSwapRecord.fr === 2" class="contentbox"
                              style="color: red">二级故障</span>
                        <span v-else-if="swapOrder.staSwapRecord.fr === 3" class="contentbox"
                              style="color: red">三级故障</span>
                        <span v-else-if="swapOrder.staSwapRecord.fr === 4" class="contentbox"
                              style="color: red">四级故障</span>
                        <span v-else-if="swapOrder.staSwapRecord.fr === 5" class="contentbox"
                              style="color: red">五级故障</span>
                        <span v-else-if="swapOrder.staSwapRecord.fr === 6" class="contentbox"
                              style="color: red">六级故障</span>
                        <span v-else class="stacontentboxtes" style="color: #ffeb3b; font-weight: bold"></span>
                    </div>
                    <div class="block_3_box5">
                        <span>取电池仓位号</span>
                        <span class="contentbox">{{ swapOrder.staSwapRecord.ucno }}</span>
                    </div>
                    <div class="block_3_box5">
                        <span>放电池仓位号:</span>
                        <span class="contentbox">{{ swapOrder.staSwapRecord.dcno }}</span>
                    </div>
                    <div class="block_3_box5">
                        <span>此次换电次序</span>
                        <span class="contentbox">{{ swapOrder.staSwapRecord.se }}</span>
                    </div>
                    <!--    装车和取车电车相反                -->
                    <div class="block_3_box5">
                        <span>拆卸电池:</span>
                        <span class="contentbox"> {{ swapOrder.staSwapRecord.udid }} </span>
                    </div>
                    <div class="block_3_box5">
                        <span>拆卸电池SOC</span>
                        <span class="contentbox">{{ swapOrder.staSwapRecord.usoc }}</span>
                    </div>

                    <div class="block_3_box5">
                        <span>拆卸电池上次换电结算时的SOC</span>
                        <span class="contentbox">{{ swapOrder.staSwapRecord.lsoc }}</span>
                    </div>

                    <!--    装车和取车电车相反                -->
                    <div class="block_3_box5">
                        <span>装上电池:</span>
                        <span class="contentbox">{{ swapOrder.staSwapRecord.dbid }}</span>
                    </div>
                    <div class="block_3_box5">
                        <span>装上电池SOC</span>
                        <span class="contentbox">{{ swapOrder.staSwapRecord.dsoc }}</span>
                    </div>

                    <div class="block_3_box5">
                        <span>车牌识别/进站时间</span>
                        <span class="contentbox">{{ swapOrder.staSwapRecord.ct }}</span>
                    </div>

                    <div class="block_3_box5">
                        <span>换电开始时刻</span>
                        <span class="contentbox">{{ swapOrder.staSwapRecord.st }}</span>
                    </div>

                    <div class="block_3_box5">
                        <span>换电结束时刻</span>
                        <span class="contentbox">{{ swapOrder.staSwapRecord.et }}</span>
                    </div>

                    <div class="block_3_box5">
                        <span>本次换电时间(秒)</span>
                        <span class="contentbox">{{ swapOrder.staSwapRecord.wt }}</span>
                    </div>

                    <!-- 站控无法提供数据，暂时隐藏
                    <div class="block_3_box5">
                        <span>装上电池行驶里程</span>
                        <span class="contentbox">{{ swapOrder.staSwapRecord.tm }}</span>
                    </div>

                    <div class="block_3_box5">
                        <span>车辆总里程</span>
                        <span class="contentbox">{{ swapOrder.staSwapRecord.vtm }}</span>
                    </div>

                    <div class="block_3_box5">
                        <span>车辆累计换电次数</span>
                        <span class="contentbox">{{ swapOrder.staSwapRecord.vct }}</span>
                    </div>
                    <div class="block_3_box5">
                        <span>车辆累计放电量(kWh)</span>
                        <span class="contentbox">{{ swapOrder.staSwapRecord.vtdc }}</span>
                    </div>
                    <div class="block_3_box5">
                        <span>车辆累计回馈电量(kWh)</span>
                        <span class="contentbox">{{ swapOrder.staSwapRecord.vtfe }}</span>
                    </div>
                    <div class="block_3_box5">
                        <span>车辆累计插仓电量(kWh)</span>
                        <span class="contentbox">{{ swapOrder.staSwapRecord.vtcc }}</span>
                    </div>
                    <div class="block_3_box5">
                        <span>车辆累计综合能耗kWh)</span>
                        <span class="contentbox">{{ swapOrder.staSwapRecord.vtec }}</span>
                    </div>
                    <div class="block_3_box5">
                        <span>本次实时放电量(kWh)</span>
                        <span class="contentbox">{{ swapOrder.staSwapRecord.rtdc }}</span>
                    </div>
                    <div class="block_3_box5">
                        <span>上一次结算放电量(kWh)</span>
                        <span class="contentbox">{{ swapOrder.staSwapRecord.lsdc }}</span>
                    </div>
                    <div class="block_3_box5">
                        <span>本次实时回馈电量(kWh)</span>
                        <span class="contentbox">{{ swapOrder.staSwapRecord.rtfc }}</span>
                    </div>
                    <div class="block_3_box5">
                        <span>上一次结算回馈电量(kWh)</span>
                        <span class="contentbox">{{ swapOrder.staSwapRecord.lsfc }}</span>
                    </div>
                    <div class="block_3_box5">
                        <span>本次实时插仓充电量(kWh)</span>
                        <span class="contentbox">{{ swapOrder.staSwapRecord.rtcc }}</span>
                    </div>

                    <div class="block_3_box5">
                        <span>上一次结算插枪充电量(kWh)</span>
                        <span class="contentbox">{{ swapOrder.staSwapRecord.lscc }}</span>
                    </div>
                    <div class="block_3_box5">
                        <span>本次实时综合能耗(kWh)</span>
                        <span class="contentbox">{{ swapOrder.staSwapRecord.rtec }}</span>
                    </div>
                    站控无法提供数据，暂时隐藏 -->


                    <div class="block_3_box5">
                        <span>上报方式</span>
                        <span v-if="swapOrder.staSwapRecord.sfs === 1" class="contentbox" style="color: #1890ff">
                            自动
                        </span>
                        <span v-else-if="swapOrder.staSwapRecord.sfs === 2" class="contentbox" style="color: #1890ff">
                            手动
                        </span>
                    </div>
                    <div class="block_3_box5">
                        <span>是否离线订单</span>
                        <span v-if="swapOrder.staSwapRecord.sflx === 0" class="contentbox" style="color: #1890ff">
                            否
                        </span>
                        <span v-else-if="swapOrder.staSwapRecord.sflx === 1" class="contentbox" style="color: #00baad">
                            是
                        </span>
                    </div>


                    <div class="block_3_box5">
                        <span>是否SOC差值</span>
                        <span v-if="swapOrder.socType === 1" class="contentbox" style="color: #1890ff">
                            是(换电SOC差值)
                        </span>
                        <span v-else-if="swapOrder.socType === 2" class="contentbox" style="color: #1890ff">
                            是(充电SOC差值)
                        </span>
                        <span v-else class="contentbox" style="color: #00baad">
                            否
                        </span>
                    </div>

                    <div class="block_3_box5">
                        <span>车辆认证结果</span>
                        <span v-if="swapOrder.staSwapRecord.authResult === 0" class="contentbox" style="color: #1890ff">
                            认证通过
                        </span>
                        <span v-else-if="swapOrder.staSwapRecord.authResult === 1" class="contentbox"
                              style="color: red">
                            认证失败
                        </span>
                        <span v-else class="contentbox" style="color: orange">
                            未知
                        </span>
                    </div>

                    <div class="block_3_box5" v-if="swapOrder.staSwapRecord.authResult === 1">
                        <span>车辆认证失败原因</span>
                        <span class="contentbox">{{ swapOrder.staSwapRecord.rk }}</span>
                    </div>

                    <div class="block_3_box5">
                        <span>支付结果</span>
                        <span v-if="swapOrder.payStatus === 1" class="contentbox" style="color: #1890ff">
                            已付款
                        </span>
                        <span v-else-if="swapOrder.payStatus === 2" class="contentbox"
                              style="color: red">
                            付款失败
                        </span>
                        <span v-else class="contentbox" style="color: orange">
                            未付款
                        </span>
                    </div>

                    <div class="block_3_box5" v-if="swapOrder.isManual === 1">
                        <span>是否手动扣款</span>
                        <span v-if="swapOrder.isManual === 1" class="contentbox" style="color: #1890ff">
                            是
                        </span>
                    </div>


                    <div class="block_3_box5">
                        <span>备注</span>
                        <span class="contentbox">{{ swapOrder.remark }}</span>
                    </div>

                </div>
            </div>
            <div class="closeDialog" @click="customerOrderClose">关闭</div>
        </el-dialog>


    </div>
</template>

<script>
import '@/assets/font/font.css';
import {swapOrderPage} from "@/utils/order";
import "@/utils/dateUtil";
import operatorStationTreeVue from "@/components/tree/operatorStationTree";
import axios from "axios";
import {myconfig} from "@/utils/config";
import moment from "moment";
import * as myStore from "@/utils/stroage";
import {swapOrderManualCharging} from "../../../utils/order";
export default {
    name: "swapOrder",
    components: {operatorStationTreeVue},
    data() {
        return {
            showMore: false,
            isopenShow: true,
            statusOptions: [
                {
                    value: 0,
                    label: "未付款",
                },
                {
                    value: 1,
                    label: "已付款",
                },
                {
                    value: 2,
                    label: "付款失败",
                }
            ],
            manualOptions:[
                {
                    value: 0,
                    label: "待扣款",
                },
                {
                    value: 1,
                    label: "已扣款",
                }
            ],

            initOptions: [
                {
                    value: null,
                    label: "全部",
                },
                {
                    value: 0,
                    label: "是",
                },
                {
                    value: 1,
                    label: "否",
                }
                ],

            query: {
                pageNum: 1,
                pageSize: 10,
                resPageNum: 1,
                operatorId: null,
                stationId: null,
                payStatus: null,
                carNo: "", //车牌号
                vin: "",//vin
                orderNo: "", //订单号
                recordTime: null,//日期
                costType: null,//计费模型
                init:1, // 是否初始订单:0是;1否
                isManual:null
            },
            pickerOptions: {
                disabledDate(time) {
                    //return time.getTime() < Date.now();
                },
            },
            clientHeight: 0,
            tableData: [],
            isPage: true,
            total: 0, //数据总数
            isCustomerOrderDetail: false,
            swapOrder: {
                swapBillingModelVO: {},
                staSwapRecord: {},
            }, //换电订单详情

            //左侧 运营商-场站树
            treeData: {
                operatorStationOptions: undefined,  //运营商-场站树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
                operatorStationNameTree: "",
                operatorStationIdTree: null,
                defaultExpandAll: true,
            },

        };
    },
    directives: {
        alterELDialogMarginTop: {
            inserted(el, binding, vnode) {
                el.firstElementChild.style.marginTop = binding.value.marginTop;
            },
        },
    },
    computed: {},
    created() {
        this.getSwapOrderList();
        this.clientHeight = document.body.clientHeight - 240;
        //this.getSwapOrderListAll()
    },
    methods: {
        headerStyleEvent({row, column, rowIndex, columnIndex}) {
            if (columnIndex == 1) {
                return 'padding:0px;'
            }
        },
        goodsInfo(row) {
            if (row.columnIndex == 1) {
                return 'goodsInfo'
            }
        },
        isHidden(message) {
            this.isopenShow = message
        },
        carNoFormat(row) {
            var carNo = "";
            if (row.staSwapRecord !== null && row.staSwapRecord.cn !== null) {
                carNo = row.staSwapRecord.cn;
            };
            return carNo;
        },

        carVinFormat(row) {
            var carVin = "";
            if (row.staSwapRecord !== null && row.staSwapRecord.vin !== null) {
                carVin = row.staSwapRecord.vin;
            };
            return carVin;
        },


        sfsFormat(row) {
            var sfs = "未知";
            if (row.staSwapRecord !== null && row.staSwapRecord.sfs !== null) {
                if (row.staSwapRecord.sfs === 1) {
                    sfs = "自动";
                } else if (row.staSwapRecord.sfs === 2) {
                    sfs = "人工手动";
                } else {
                    sfs = "未知";
                }
            };
            return sfs;
        },

        sflxFormat(row) {
            var sflx = "未知";
            if (row.staSwapRecord !== null && row.staSwapRecord.sflx !== null) {
                if (row.staSwapRecord.sflx === 0) {
                    sflx = "否";
                } else if (row.staSwapRecord.sflx === 1) {
                    sflx = "是";
                } else {
                    sflx = "未知";
                }
            };
            return sflx;
        },

        async getSwapOrderList() {
            var url = "?page=" + this.query.pageNum + "&size=" + this.query.pageSize;
            var id = this.treeData.operatorStationIdTree;
            if (id !=="" && id !== null) {
                if (id.indexOf("operator") !== -1) {
                    id = id.slice(9);
                    url += "&operatorId=" + Number(id);
                } else {
                    id = id.slice(8);
                    url += "&stationId=" + Number(id)
                }
            }
            if (this.query.payStatus !== "" && this.query.payStatus !== null) {
                url += "&payStatus=" + this.query.payStatus;
            }
            if (this.query.carNo !== "" && this.query.carNo !== null) {
                url += "&carNo=" + this.query.carNo;
            }
            if (this.query.vin !== "" && this.query.vin !== null) {
                url += "&vin=" + this.query.vin;
            }
            if (this.query.costType !== "" && this.query.costType !== null) {
                url += "&costType=" + this.query.costType;
            }
            if (this.query.orderNo !== "" && this.query.orderNo !== null) {
                url += "&orderNo=" + this.query.orderNo;
            }
            if (this.query.recordTime !== "" && this.query.recordTime !== null) {
                url += "&recordTime=" + this.query.recordTime;
            }
            if (this.query.init !== null) {
                url += "&init=" + this.query.init;
            }

            if(this.query.isManual !== null){
                url += "&isManual=" + this.query.isManual;
            }

            const res = await swapOrderPage(url);

            if (res.data.code !== 200) {
                return this.$msg.error("获取换电订单信息失败");
            }
            this.tableData = res.data.data.data;
            this.total = res.data.data.total;
        },
        resFuntion() {
            this.treeData.operatorStationNameTree = ""
            this.treeData.operatorStationIdTree = null
            this.query = {
                pageNum: 1,
                pageSize: 10,
                resPageNum: 1,
                operatorId: null,
                stationId: null,
                payStatus: null,
                carNo: "", //车牌号
                vin: "",//vin
                orderNo: "", //订单号
                recordTime: null,//日期
                costType: null,//计费模型
                init:1, // 是否初始订单:0是;1否
                isManual:null
            };

            this.swapOrder = {
                swapBillingModelVO: {},
                staSwapRecord: {},
            };

            this.getSwapOrderList();
        },

        async batchExport() {
            var requestVo = {};
            var id = this.treeData.operatorStationIdTree;
            if (id !== "" && id !== null) {
                if (id.indexOf("operator") !== -1) {
                    id = id.slice(9);
                    requestVo.operatorId = Number(id);
                } else {
                    id = id.slice(8);
                    requestVo.stationId = Number(id);
                }
            }

            if (this.query.recordTime !== "" && this.query.recordTime !== null) {
                requestVo.recordTime = this.query.recordTime;
            }
            if (this.query.payStatus !== "" && this.query.payStatus !== null) {
                requestVo.payStatus = this.query.payStatus;
            }
            if (this.query.carNo !== "" && this.query.carNo !== null) {
                requestVo.carNo = this.query.carNo;
            }
            if (this.query.vin !== "" && this.query.vin !== null) {
                requestVo.vin = this.query.vin;
            }
            if (this.query.costType !== "" && this.query.costType !== null) {
                requestVo.costType = this.query.costType;
            }
            if (this.query.orderNo !== "" && this.query.orderNo !== null) {
                requestVo.orderNo = this.query.orderNo;
            }
            if (this.query.init !== null) {
                requestVo.init = this.query.init;
            }

            var url2 = myconfig.url + "/service-baseinfo/swapOrder/export";
            const user = myStore.sessionGetItem("electric-token");
            var token = 'Bearer ' + user.data;
            await axios.get(url2, { params: requestVo,responseType: "blob",headers: {'Authorization': token}})
                .then(response => {
                console.log("===============获取换电订单信息成功============");
                const content = response.data;
                const blob = new Blob([content]);
                const fileName = "换电订单信息.xls";
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = fileName;
                link.click();
                URL.revokeObjectURL(link.href);
                //document.body.removeChild(link);
            }).catch(console.error);
        },


        //手动计费
        async manualCharging(row){
            const config = await this.$MessageBox
                .confirm("确定要进行云平台手动扣款?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                .catch((e) => e);
            if (config !== "confirm") return;

            var data = {};
            data.orderId = row.id;
            data.orderNo = row.orderNo;
            const res = await swapOrderManualCharging(data);
            if (res.data.code !== 200) {
                return this.$msg.error("换电订单手动扣费失败" + res.data.msg);
            }else{
                this.$msg.success("换电订单手动扣费成功");
                //刷新当前页
                this.getSwapOrderList();
            }
        },


        //查看详情
        async handleDetail(row) {
            console.log("========row============",row)
            this.swapOrder = row;
            if (row.staSwapRecord == null) {
                this.swapOrder.staSwapRecord = {};
            }
            this.isCustomerOrderDetail = true;
        },


        customerOrderClose() {
            this.swapOrder = {
                originalOrder: {},
                swapBillingModelVO: {},
                staSwapRecord: {},
            };
            this.isCustomerOrderDetail = false;
        },

        renIndex(index) {
            return (this.query.pageNum - 1) * this.query.pageSize + index + 1;
        },

        handleSizeChange(val) {
            //每页数量改变
            this.query.pageSize = val;
            this.getSwapOrderList();
        },

        handleCurrentChange(val) {
            //当前页数改变
            this.query.pageNum = val;
            this.getSwapOrderList();
        },

        getTreeData(data) {
            this.treeData = data;
        },
    },
};
</script>

<style lang="less" scoped>
.app-container {
    background: rgba(255, 255, 255, 0);
    padding-left: 0px;
}

::v-deep .el-dialog__header {
    background-color: #e4ebf1;

    .el-dialog__title {
        font-weight: bolder;

        .dialogCenter1 .block_5 {
            background-color: #e4ebf1;
            display: flex;
            flex-direction: row;
            margin: 10px 0;
            flex-wrap: wrap;

            [class^="block_5_box"] {
                text-align: left;
                box-sizing: border-box;
                padding: 10px 10px;
                display: flex;
                width: 25%;
                flex-direction: column;

                .contentbox {
                    font-weight: bold;
                }
            }
        }
    }
}

.block_5 {
    background-color: #e4ebf1;
    display: flex;
    flex-direction: row;
    margin: 20px 0;
    padding: 20px 20px;
    flex-wrap: wrap;

    [class^="block_5_box"] {
        text-align: center;

        box-sizing: border-box;
        padding: 15px 15px;
        padding-left: 30px;
        display: flex;
        width: 30%;
        // flex-direction: column;
        .contentbox {
            font-weight: bold;
            padding-left: 8px;
        }
    }
}

.block_2 {
    background-color: #e4ebf1;
    display: flex;
    flex-direction: row;
    margin: 10px 0;
    flex-wrap: wrap;

    [class^="block_2_box"] {
        text-align: left;
        box-sizing: border-box;
        padding: 10px 10px;
        display: flex;
        width: 20%;
        flex-direction: column;

        .contentbox {
            font-weight: bold;
        }
    }
}

.block_3 {
    background-color: #e4ebf1;
    display: flex;
    flex-direction: row;
    margin: 10px 0;
    flex-wrap: wrap;

    [class^="block_3_box"] {
        text-align: left;
        box-sizing: border-box;
        padding: 10px 10px;
        display: flex;
        width: 20%;
        flex-direction: column;

        .contentbox {
            font-weight: bold;
        }
    }
}

.block_4 {
    background-color: #e4ebf1;
    display: flex;
    flex-direction: row;
    margin: 10px 0;
    flex-wrap: wrap;

    [class^="block_4_box"] {
        text-align: left;
        box-sizing: border-box;
        padding: 10px 10px;
        display: flex;
        width: 20%;
        flex-direction: column;

        .contentbox {
            font-weight: bold;
        }
    }
}

.box1_1 {
    text-align: left;
    box-sizing: border-box;
    padding: 10px 10px 10px 0px;
    display: flex;
    width: 50%;
    flex-direction: column;
}

.box1_2 {
    text-align: left;
    box-sizing: border-box;
    padding: 10px 10px;
    display: flex;
    width: 50%;
    flex-direction: column;
}

.circular {
    border-radius: 5px;
}

.closeDialog {
    width: 80px;
    height: 30px;
    line-height: 30px;
    margin: 0 auto;
    margin-top: 20px;
    text-align: center;
    color: #ffffff;
    background-color: #3981e2;
}

.el-table {
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
        width: 10px; /*滚动条宽度*/
        height: 10px; /*滚动条高度*/
    }

    /*定义滚动条轨道 内阴影+圆角*/

    /deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
        //   box-shadow: 0px 1px 3px #fff inset; /*滚动条的背景区域的内阴影*/
        border-radius: 10px; /*滚动条的背景区域的圆角*/
        background-color: #fff; /*滚动条的背景颜色*/
    }

    /*定义滑块 内阴影+圆角*/

    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
        //   box-shadow: 0px 1px 3px #dddee0 inset; /*滚动条的内阴影*/
        border-radius: 10px; /*滚动条的圆角*/
        background-color: #dddee0; /*滚动条的背景颜色*/
    }
}

.out-container {
    width: 100%;
    /* height: var(--tableHeight);*/
    height: 660px;
    overflow: auto;
}

.inner-container {
    // min-width: 2600px;
    height: var(--tableHeight);
    overflow: hidden;
    scroll-behavior: smooth;
}
//重构新增
.side-search {
    width: 200px;
    background: #FFFFFF;
    border-right: #d3dce6 1px solid;
}

.small-side {
    width: 36px;
    height: 36px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.14);
    border-right: 1px solid #EEE;
}

.mian-content {
    flex: 1;
    background: #F5F7FA;
}

.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.head-section {
    padding: 16px;
    background: #FFFFFF;
    overflow: hidden;
}

.btn-end {
    display: flex;
    justify-content: flex-end;
}

.section-main {
    width: 100%;
    //height: calc(100vh - 24px);
    background: #FFFFFF;
    margin-top: 16px;
    padding: 24px 16px 16px 16px;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.choose-mian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
        display: inline-block;
        margin-left: 16px;
    }
}

.alert-box {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
}

.pagin {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.main-flex {
    display: flex;

}

.left-style {
    padding-left: 43px;
}

.edit-style {
    color: #0D6CE4;
    font-size: 14px;
    cursor: pointer;
}
.more-search {
    display: flex;
    color: #0D6CE4;
    font-size: 14px;
    font-weight: 400;
    line-height: 36px;
    cursor: pointer;
    position: relative;
    left: -15px;
    i{
        line-height: 36px;
    }
}
.head-section{
    /deep/.el-input__inner { //更改input大小
        width: 250px !important;
        height: 36px;
        line-height: 36px;
    }
}
.head-section2 {
    overflow: hidden;
    padding: 0 16px 16px 16px;
    height: 100%;
}
</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';
</style>
