import Vue from "vue";

const vm = new Vue();
import axios from "axios";
import router from "@/router/index";
import { checkToken, login } from "./user";
import { myconfig } from "@/utils/config";
import * as myStore from "@/utils/stroage";
const url =  'http://127.0.0.1:8090';
//const url = 'https://solutionauto.harmontronics.com'
//const url = 'https://passengercar.harmontronics.com'
const req = axios.create({
    baseURL: myconfig.url,
    //baseURL: url,
    //baseURL: '/myapi', //访问前缀
    timeout: 10000
});

window.isAccess = true;
//请求拦截器
req.interceptors.request.use(
    (req) => {
        //判断有没有token
        const user = myStore.sessionGetItem("electric-token");
        if (req.url === "/service-oauth2/oauth/token") {
            req.headers["content-type"] = "application/x-www-form-urlencoded";
        }
        if (req.url !== "/service-oauth2/oauth/token" && user) { //有则添加请求头
            //ret.headers.Authorization = 'Bearer ' + user.access_token
            req.headers.Authorization = "Bearer " + user.data;
        }

        console.log("-----请求拦截器-----", req);
        return req;
    }, (err) => {
        return Promise.reject(err);
    });

//相应拦截器
req.interceptors.response.use(
    (res) => {
        console.log("-----响应拦截器------", res);
        if (res.data.code === 401 || res.data.msg === "invalid_token") {
            window.localStorage.clear();
            window.sessionStorage.clear();
            router.push("/login");
        } else {
            return res;
        }

    }, async (err) => {
        if (err.response) {
            //错误处理
            switch (err.response.status) {
                case 401:
                    if (window.isAccess) {
                        window.isAccess = false;
                        refreshToken();
                    }
                    break;
                case 403:
                    vm.$msg.error("暂无权限");
                    break;
                case 404:
                    vm.$msg.error("页面不存在");
                    break;
                case 500 && err.response.data.error === "Internal Server Error":
                    vm.$msg.error("服务器错误");
                    break;
                case 501:
                    vm.$msg.error("网络未实现");
                    break;
                case 502:
                    vm.$msg.error("网络错误");
                    break;
                case 503:
                    vm.$msg.error("网络不可用");
                    break;
                case 504:
                    vm.$msg.error("网络超时");
                    break;
                case 505:
                    vm.$msg.error("http版本不支持该请求");
                    break;
                default:

                    break;
            }
        }
        return Promise.resolve(err.response);

    });

export const createAPI = (url, method, data) => {
    const config = {};
    if (method === "get") {
        config.params = data;
    } else {
        config.data = data;
    }
    return req({
        url,
        method,
        ...config
    });
};

async function refreshToken() {
    const user = JSON.parse(myStore.sessionGetItem("electric-token"));
    if (user && user.access_token) {
        let url = window.location.href.split("#")[1];
        const res = await checkToken(user.access_token);
        if (!res.data.active && url !== "/login") {
            // const data = {
            //     grant_type:'access_token',
            //     scope:'SCOPES',
            //     client_id:'OAUTH_CLIENT_ID',
            //     client_secret:'OAUTH_CLIENT_SECRET'
            // }
            // const res =await login()
            vm.$MessageBox.alert("你已被登出，请重新登录", "确定", {
                confirmButtonText: "确定",
                callback: () => {
                    router.push("/login");
                    myStore.sessionRemoveItem("electric-token");
                    myStore.sessionRemoveItem("electric-user");
                    // removeItem("electric-token");
                    // removeItem("electric-user");
                    window.isAccess = true;
                }
            });
        }
        setTimeout(() => {
            window.isAccess = true;
        }, 500000);
    }

}
