<template>
    <div class="app-container">
        <el-row class="main-flex">
            <!-- 搜索区域 -->
            <el-col :span="21" class="mian-content">
                <div class="head-section">
                    <el-col :span="6">
                        <el-form :inline="true">
                            <el-form-item label="用户姓名：">
                                <el-input placeholder="请输入用户姓名" v-model.trim="query.userName" clearable></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="7">
                        <el-form :inline="true" class="left-style">
                            <el-form-item label="手机号：" >
                                <el-input placeholder="请输入用户手机号" v-model.trim="query.iphoneNo" clearable></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="11">
                        <div class="btn-end">
                            <span class="btn-rest">
                            <el-button @click="restFunction" plain>重置</el-button>
                            </span>
                            <span class="btn-submit">
                            <el-button type="primary" @click="queryFunction" plain>查询</el-button>
                            </span>
                        </div>
                    </el-col>
                </div>
                <!--  表格部分-->
                <div class="section-main">
                    <el-col class="choose-mian">
                        <div class="section-title">
                            微信用户列表
                        </div>
                    </el-col>
                    <el-col :span="24">
                        <el-row class="table-box">
                            <el-table
                                :data="tableData"
                                style="width: 100%"
                                ref="multipleTable"
                                @selection-change="selectionChange"
                                 :header-cell-style="headerStyleEvent" :cell-class-name="goodsInfo" height="100%">
                                <el-table-column align="center" type="index" :index="renIndex" label="编号" min-width="50"></el-table-column>
                                <el-table-column align="center" prop="userName" label="用户姓名" min-width="100"></el-table-column>
                                <el-table-column align="center" prop="nickName" label="微信昵称" min-width="110"
                                                 :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column align="center" prop="iphoneNo" label="手机号" min-width="120"></el-table-column>
                                <el-table-column align="center" prop="openId" label="openId" min-width="200"
                                                 :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column align="center" prop="birthday" label="出生日期" min-width="120"></el-table-column>
                                <el-table-column align="center" prop="userAvatar" label="微信头像" min-width="120">
                                    <template slot-scope="scope" >
                                        <el-avatar shape="square" :size="30" fit="fill" :src="scope.row.userAvatar"></el-avatar>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" prop="gender" label="性别" min-width="100">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.gender === 1" class="states"> 男 </span>
                                        <span v-else-if="scope.row.gender === 2" class="states"> 女 </span>
                                        <span v-else class="states"> 保密 </span>
                                    </template>
                                </el-table-column>

                                <el-table-column align="center" prop="updateTime" label="更新时间" min-width="130"
                                                 :formatter="$dateFormat1"></el-table-column>

                                <el-table-column align="center" label="操作" min-width="160">
                                    <template slot-scope="scope">
                                        <el-button @click="handleDetail(scope.row)" type="text" v-hasCode="['miniapp:customer:detail']"
                                        class="btn-color">
                                            详情
                                        </el-button>
                                        <el-button @click="allocate(scope.row)" type="text" v-hasCode="['miniapp:dataScope:bind']" class="btn-color">
                                            数据权限
                                        </el-button>

                                    </template>
                                </el-table-column>

                            </el-table>

                        </el-row>
                    </el-col>

                    <el-col :span="24">
                        <el-row class="pagin">
                        <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="query.pageNum"
                            :page-sizes="[10,30, 50, 100]"
                            :page-size="query.pageSize"
                            layout="total, sizes, prev, pager, next"
                            :total="totalPages"
                        ></el-pagination>
                    </el-row>
                    </el-col>
                </div>
            </el-col>
        </el-row>



        <el-dialog @close="close" :visible.sync="isDetail" width="60%">
            <el-form size="middle" ref="detailForm" :model="detailForm" label-width="120px">
                <el-row>
                    <el-col :span="11">

                        <el-form-item label="用户姓名:" prop="userName">
                            <span> {{ detailForm.userName }} </span>
                        </el-form-item>
                        <el-form-item label="微信昵称:" prop="nickName">
                            <span> {{ detailForm.nickName }} </span>
                        </el-form-item>

                        <el-form-item label="手机号:" prop="iphoneNo">
                            <span> {{ detailForm.iphoneNo }} </span>
                        </el-form-item>

                        <el-form-item class="Form-item" label="头像:" prop="userAvatar">
                            <img class="detail-item-pic" :src="detailForm.userAvatar" alt="图片加载失败"
                                 style=" display: flex;justify-content: space-around;border: 1px rgb(245, 245, 245) ridge; width: 150px;height: 150px;" />
                        </el-form-item>
                    </el-col>

                    <el-col :span="11">
                        <el-form-item label="openId:" prop="openId">
                            <span> {{ detailForm.openId }} </span>
                        </el-form-item>
                        <el-form-item label="性别:" prop="gender">
                            <span v-if="detailForm.gender=== 1"> 男 </span>
                            <span v-else-if="detailForm.gender === 2">女</span>
                            <span v-else class="states"><i style="background-color:#ffeb3b; font-weight: bold"></i> 保密</span>
                        </el-form-item>
                        <el-form-item label="出生日期:" prop="birthday">
                            <span> {{ detailForm.birthday }} </span>
                        </el-form-item>
                        <el-form-item label="创建日期:" prop="createTime">
                            <span> {{ detailForm.createTime }} </span>
                        </el-form-item>
                        <el-form-item label="更新日期:" prop="updateTime">
                            <span> {{ detailForm.updateTime }} </span>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

        </el-dialog>

        <!-- 添加或修改角色配置对话框 -->
        <el-dialog :title="true ?'数据权限':'数据权限'" :visible.sync="isAllocate" width="600px"
                   append-to-body>
            <el-form ref="scopeform" :model="scopeForm" :rules="allocateRules" label-width="100px"
                     style="overflow:auto">
                <el-form-item label="用户昵称" prop="nickName">
                    <el-input v-model="scopeForm.nickName" disabled/>
                </el-form-item>
                <el-form-item label="用户手机号" prop="iphoneNo">
                    <el-input v-model="scopeForm.iphoneNo" disabled/>
                </el-form-item>
                <el-form-item label="数据权限">

                    <!--
                                        <el-checkbox v-model="menuExpand" @change="handleCheckedTreeExpand($event, 'menu')">展开/折叠
                                        </el-checkbox>
                                        <el-checkbox v-model="menuNodeAll" @change="handleCheckedTreeNodeAll($event, 'menu')">全选/全不选
                                        </el-checkbox>
                                        <el-checkbox v-model="scopeForm.menuCheckStrictly"
                                                     @change="handleCheckedTreeConnect($event, 'menu')">
                                            父子联动
                                        </el-checkbox>

                                        -->


                    <el-tree
                        class="tree-border"
                        show-checkbox
                        ref="opeTree"
                        node-key="id"
                        empty-text="加载中，请稍候"
                        :data="treeData.operatorTeamOptions"
                        :props="treeData.defaultProps"
                        :default-expand-all="treeData.defaultExpandAll"
                    ></el-tree>

                    <!--
                     @check-change="handleCheckClick"
                                  :filter-node-method="filterNode"
                           @node-click="handleNodeClick"
                           :render-content="renderContent"
                                -->

                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import {
    customerPage
} from "@/utils/customer";
import {bindScope,operatorTeamTree,customerScope} from "@/utils/operatorApi";
export default {
    name: "customer",
    components: {},
    data() {
        //定义数据
        return {
            query: {
                pageNum: 1,
                pageSize: 10,
                resPageNum: 1,
                userName:"",
                iphoneNo:""
            },
            tableData: [],
            totalPages: 0,
            isPage: true,
            selectList: [],
            detailForm: {},
            isDetail: false,

            isAllocate: false,
            scopeForm: {},
            allocateRules: {},
            treeData: {
                operatorTeamOptions: [],  //运营商-车队树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
                operatorTeamNameTree: "",
                operatorTeamIdTree: null,
                defaultExpandAll: false,
            }
        };

    },
    computed: {},
    created() {
        this.getPage();
    },
    methods: {
        headerStyleEvent({row, column, rowIndex, columnIndex}) {
            if (columnIndex == 1) {
                return 'padding:0px;'
            }
        },
        goodsInfo(row) {
            if (row.columnIndex == 1) {
                return 'goodsInfo'
            }
        },
        //分页查询
        async getPage() {
            let page = {
                size: this.query.pageSize,
                current: this.query.pageNum
            };

            try {
                let data = {
                    page: page,
                    iphoneNo: this.query.iphoneNo,
                    userName: this.query.userName
                };
                let res = await customerPage(data);
                if (res.status !== 200) {
                    return this.$msg.error("获取人员信息失败");
                }
                this.totalPages = res.data.data.total;
                this.tableData = res.data.data.records;
            } catch (error) {
            }
        },

        renIndex(index) {
            return (this.query.pageNum - 1) * this.query.pageSize + index + 1;
        },

        //重置
        restFunction() {
            this.iphoneNoValue = "";
            this.isPage = true;
            this.query = {
                pageNum: 1,
                pageSize: 10,
                resPageNum: 1,
                userName:"",
                iphoneNo:""
            };
            this.getPage();
            this.restScope();
        },

        restScope(){
            this.treeData = {
                operatorTeamOptions: [],  //运营商-车队树选项
                    defaultProps: {
                    children: "children",
                        label: "label"
                },
                operatorTeamNameTree: "",
                    operatorTeamIdTree: null,
                    defaultExpandAll: false,
            }
        },

        close() {
            this.detailForm = {};
            this.isDetail = false;
        },

        //详情
        handleDetail(row) {
            this.detailForm = row;
            this.isDetail = true;
        },


        // 分页事件
        handleSizeChange(val) {
            this.query.pageSize = val;
            if (this.isPage) {
                this.getPage();
            } else {
                this.queryFunction();
            }
        },

        async queryFunction() {
            try {

                await this.getPage();
                this.isPage = false;
            } catch (error) {
            }
        },

        selectionChange(rows) {
            this.selectList = rows;
        },

        handleCurrentChange(val) {
            this.query.pageNum = val;
            if (this.isPage) {
                this.getPage();
            } else {
                this.query.resPageNum = val;
                this.queryFunction();
            }
        },


        async allocate(row) {
            try {
                this.restScope();
                let res = await operatorTeamTree();
                this.scopeForm = row;
                if (res.data.code !== 200) return this.$msg.error("获取运营商-车队信息失败");
                this.treeData.operatorTeamOptions.push(res.data.data)
                //查询已经绑定权限
                const authOwnedRes = await customerScope(row.id);
                if (authOwnedRes.data.code !== 200) return this.$msg.error('获取已有权限列表失败!' + authOwnedRes.data.code)
                let authOwnedData = authOwnedRes.data.data
                this.$nextTick(() => {
                    let checkedKeys = authOwnedData
                    checkedKeys.forEach((v) => {
                        this.$nextTick(() => {
                            this.$refs.opeTree.setChecked(v.scope, true, false);
                        })
                    });
                });
                this.isAllocate = true;
            } catch (error) {
            }
        },

        async submitForm() {
            var form = {};
            form.customerId = this.scopeForm.id;
            form.scopeList = this.getAllCheckedKeys();
            var cks = this.getAllCheckedKeys();
            var res = await bindScope(form)
            if (res.status !== 200) {
                this.$msg.error('权限赋予失败' + res.data.msg)
            }else{
                this.$msg.success('权限赋予成功')
                this.isAllocate = false;
            }
        },

        // 所有菜单节点数据
        getAllCheckedKeys() {
            // 目前被选中的菜单节点
            let checkedKeys = this.$refs.opeTree.getCheckedKeys();
            // 半选中的菜单节点
            let halfCheckedKeys = this.$refs.opeTree.getHalfCheckedKeys();
            checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys);
            return checkedKeys;
        },


        async cancel() {
            this.isAllocate = false;
        },

        // 筛选节点
        filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },

        async handleCheckClick(data) {
            console.log("------handleCheckClick.data-----------[[", data, "]]-----------------")
        },


        // 节点单击事件
        async handleNodeClick(data) {
            this.treeData.operatorTeamIdTree = data.id;
            this.treeData.operatorTeamNameTree = data.label;
            this.toTreeDataRes();
        },


        // tree添加元素
        renderContent(h, {node, data, store}) {
            return (
                <span style="margin-top:8px;margin-bottom:5px;">
              <span>
                <span>{node.label}</span>
              </span>
              </span>
            );
        },


        // 树权限（展开/折叠）
        handleCheckedTreeExpand(value, type) {
            if (type == 'menu') {
                let treeList = this.menuOptions;
                for (let i = 0; i < treeList.length; i++) {
                    this.$refs.menu.store.nodesMap[treeList[i].id].expanded = value;
                }
            }
        },
        // 树权限（全选/全不选）
        handleCheckedTreeNodeAll(value, type) {
            if (type == 'menu') {
                this.$refs.menu.setCheckedNodes(value ? this.menuOptions : []);
            } //
        },
        // 树权限（父子联动）
        handleCheckedTreeConnect(value, type) {
            try {
                if (type == 'menu') {
                    this.form.menuCheckStrictly = value ? true : false;
                }
            } catch (e) {

            }
        },


    }
};
</script>

<style lang="less" scoped>
.app-container {
    background: rgba(255, 255, 255, 0);
    padding-left: 0px;
}

.el-select {
    width: 100%;
}

.vehicle-item {
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-right: 5px;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}

.avatar-box {
    width: 100%;
    display: flex;
    align-items: flex-start;

    .avatar-item {
        width: 100px;
        height: 100px;
        border: 1px dashed #666;
        position: relative;
        border-radius: 10px;
        margin-right: 10px;

        > img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
        }

        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 30px;
            color: #666;
        }
    }

    .avatar-item:hover {
        border: 1px dashed #409eff;

        .icon {
            color: #409eff;
        }
    }
}
//重构新增
.side-search {
    width: 200px;
    background: #FFFFFF;
    border-right: #d3dce6 1px solid;
}

.small-side {
    width: 36px;
    height: 36px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.14);
    border-right: 1px solid #EEE;
}

.mian-content {
    flex: 1;
    background: #F5F7FA;
}

.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.head-section {
    padding: 16px;
    background: #FFFFFF;
    overflow: hidden;
}

.btn-end {
    display: flex;
    justify-content: flex-end;
}

.section-main {
    width: 100%;
    //height: calc(100vh - 24px);
    background: #FFFFFF;
    margin-top: 16px;
    padding: 24px 16px 16px 16px;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.choose-mian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
        display: inline-block;
        margin-left: 16px;
    }
}

.alert-box {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
}

.pagin {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.main-flex {
    display: flex;

}

.left-style {
    padding-left: 43px;
}

.edit-style {
    color: #0D6CE4;
    font-size: 14px;
    cursor: pointer;
}

.more-search {
    display: inline-block;
    color: #0D6CE4;
    font-size: 14px;
    font-weight: 400;
    line-height: 40px;
    cursor: pointer;
    position: relative;
    left: -15px;
}
.btn-color{
    color: #0D6CE4;
}
/deep/.el-avatar>img{//头像宽100%
    width: 100%;
}
/deep/.el-avatar{
    margin-top: 6px;
}
</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';

</style>
