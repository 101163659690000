 <template>
    <div>
        <div class="app-container">
            <el-row class="main-flex">
                <el-col :span="3" class="side-search" v-if="isopenShow">
                    <monitoring @emitToParent='getTreeData' @isShow="isHidden"></monitoring>
                </el-col>
                <el-col v-if="!isopenShow" class="small-side">
                    <div class="change-in" @click="isopenShow = !  isopenShow "></div>
                </el-col>
                <el-col :span="21" class="mian-content">
                    <div class="head-section head-bottom">
                        <el-col :span="20">
                            <el-form :inline="true">
                                <el-form-item label="限制枪充阈值（%）：" class="battery-num">
                                    <el-input placeholder="请输入枪充阈值" v-model.trim="query.versions"
                                              clearable></el-input>
                                </el-form-item>
                            </el-form>

                        </el-col>
                        <el-col :span="4">
                            <div class="btn-end">
                            <span class="btn-rest">
                        <el-button plain @click="resetSearch">重置</el-button>
                            </span>
                                <span class="btn-submit">
                      <el-button type="primary" plain @click="searchPos">保存</el-button>
                            </span>
                            </div>
                        </el-col>
                    </div>
                    <div class="head-section">
                        <el-col :span="7">
                            <el-form :inline="true">
                                <el-form-item label="车牌号：" class="battery-num" label-width="82px">
                                    <el-input placeholder="请输入车牌号" v-model.trim="query.versions"
                                              clearable></el-input>
                                </el-form-item>
                            </el-form>

                        </el-col>
                        <el-col :span="7">
                            <el-form :inline="true">
                                <el-form-item label="车队：" class="battery-num">
                                    <el-input placeholder="请输入车队名称" v-model.trim="query.versions"
                                              clearable></el-input>
                                </el-form-item>
                            </el-form>

                        </el-col>
                        <el-col :span="7">
                            <el-form :inline="true">
                                <el-form-item label="运营商：" class="battery-num">
                                    <el-select v-model="query.versions" placeholder="请选择名单类型" clearable>
                                        <el-option v-for="item in typeList" :key="item.value" :label="item.label"
                                                   :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-form>
                        </el-col>
                        <el-col :span="3" class="add-bottom">
                            <div class="btn-end">
                            <span class="btn-rest">
                        <el-button plain @click="resetSearch">重置</el-button>
                            </span>
                                <span class="btn-submit">
                      <el-button type="primary" plain @click="searchPos">查询</el-button>
                            </span>
                            </div>
                        </el-col>
                        <el-col :span="24">
                            <el-form :inline="true">
                                <el-form-item label="更新时间：" class="battery-num" label-width="82px">
                                    <el-input placeholder="请选择运营商" v-model.trim="query.versions"
                                              clearable></el-input>
                                </el-form-item>
                            </el-form>
                        </el-col>
                    </div>
                    <div class="section-main">
                        <el-col class="choose-mian">
                            <div class="section-title">
                                车辆远程设置列表
                            </div>
                            <div>
                                <!--选择项栏-->
                                <span class="alert-box">
<!--                          已选择{{ selectList.length }}项-->
                          已选择0项
                            <span @click="clean" style="margin-left:8px;color:#0D6CE4;cursor:pointer;">清空</span>
                            </span>
                                <span class="pain-btn">
                                <el-button @click="removeAll" plain
                                           v-hasCode="['vehicle:auth:remove']">删除
                                </el-button>
                            </span>
                                <span class="add-btn">
                                <el-button type="primary" @click="addDialogFunction" plain
                                           v-hasCode="['vehicle:auth:add']">新增
                                </el-button>
                            </span>
                            </div>
                        </el-col>
                        <el-col :span="24">
                            <div class="table-box">
                                <el-table
                                    :data="tableData"
                                    ref="multipleTable"
                                    @selection-change="selectionChange"
                                    :header-cell-style="headerStyleEvent" :cell-class-name="goodsInfo"
                                >
                                    <el-table-column align="center" type="selection" min-width="15"></el-table-column>
                                    <el-table-column align="center" type="index" label="编号"
                                                     min-width="20"></el-table-column>
                                    <el-table-column align="center" prop="teamVO.teamName" label="车牌号"
                                                     min-width="110"></el-table-column>
                                    <el-table-column align="center" prop="phone" label="车队"
                                                     min-width="110"></el-table-column>
                                    <el-table-column align="center" prop="carNo" label="运营商"
                                                     min-width="80"></el-table-column>
                                    <el-table-column align="center" prop="vin" label="限制枪充阈值（%）"
                                                     min-width="120"></el-table-column>
                                    <el-table-column align="center" prop="mac" label="更新时间"
                                                     min-width="120"></el-table-column>

                                    <el-table-column align="center" label="操作" min-width="80">
                                        <template slot-scope="scope">

                                            <el-button @click="handleDetail(scope.row)" type="text" class="btn-color"
                                                       v-hasCode="['miniapp:customervehicle:detail']">详情
                                            </el-button>

                                        <!-- <el-button @click="handleAudit(scope.$index, scope.row)" type="text"
                                                       v-if="scope.row.auditStatus == 0" class="btn-color"
                                                       v-hasCode="['miniapp:customervehicle:audit']">审核
                                            </el-button>

                                            <el-button @click="handleReason(scope.$index, scope.row)" type="text" class="btn-color"
                                                       v-if="scope.row.auditStatus == 2">驳回原因
                                            </el-button>-->
                                        </template>
                                    </el-table-column>
                                </el-table>

                            </div>
                        </el-col>
                        <el-col :span="24">
                            <el-row class="pagin">
                                <el-pagination
                                    @size-change="handleSizeChange"
                                    @current-change="handleCurrentChange"
                                    :current-page="query.pageNum"
                                    :page-sizes="[10,30, 50, 100]"
                                    :page-size="query.pageSize"
                                    layout="total, sizes, prev, pager, next"
                                    :total="totalPages"
                                ></el-pagination>
                            </el-row>
                        </el-col>
                    </div>
                </el-col>
            </el-row>
            <!-- 搜索区域 -->

        </div>
    </div>
</template>

<script>
import monitoring from '@/components/tree/monitoringCarTree.vue';
// import {customerVehiclePage} from "@/utils/customer";
export default {
    name: "vehicleRemoteSetting",
    components: {
        monitoring
    },
    data() {
        return {
            showMore: false,
            isopenShow: true,
            query: {
                versions: '',
                pageNum: 1,
                pageSize: 10,
                resPageNum: 1,
                operatorId: null,
                teamId: null,
                auditStatus: null,
                phone: null,
                vin: null,
                mac: null,
                carNo: null
            },
            treeData: {
                operatorTeamOptions: [],  //运营商-车队树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
                operatorTeamNameTree: "",
                operatorTeamIdTree: null,
                defaultExpandAll: false,
            },
            selectList:[],
            tableData: [],
            totalPages: 0,
            typeList:[]
        }
    },
    mounted() {
        // let res =  customerVehiclePage(data);
        // if (res.data.code !== 200) {
        //     return this.$msg.error("获取车辆认证信息失败");
        // }
        // // let datas = res.data.data.content;
        // // this.totalPages = res.data.data.totalElements;
        // // this.tableData = datas;
        //
        // if (res.data.data.total === 0) {
        //     this.tableData = []
        //     this.totalPages = 0
        //     return this.$msg.info("未查询到记录");
        // }
        //
        // this.tableData = res.data.data.list;
        // this.totalPages = res.data.data.total;
    },
    methods: {
        goodsInfo(row) {
            if (row.columnIndex == 1) {
                return 'goodsInfo'
            }
        },
        handleCurrentChange(val) {
            this.query.pageNum = val;
            if (this.isPage) {
                this.getPage();
            } else {
                this.query.resPageNum = val
                this.queryFunction();
            }
        },
        // 分页事件
        handleSizeChange(val) {
            this.query.pageSize = val;
            if (this.isPage) {
                this.getPage();
            } else {
                this.queryFunction();
            }
        },
        headerStyleEvent({row, column, rowIndex, columnIndex}) {
            if (columnIndex == 1) {
                return 'padding:0px;'
            }
        },
        selectionChange(rows) {
            this.selectList = rows;
        },
        addDialogFunction() {

        },
        removeAll() {

        },
        clean() {

        },
        resetSearch() {

        },
        searchPos() {

        },
        getTreeData(data) {
            console.log(data, 'data')
            // const index=data.operatorTeamIdTree.indexOf('_')
            this.treeData = data;
            console.log(this.treeData, 'bbbbbbbbbb')
            this.query.teamId = data.operatorTeamNameTree
            // this.operatorSider()//触发子组件方法更新点位
            // this.getCarStatusTotal()//变化车辆总数
            // this.getalarmsPage()//变化车辆总数
            console.log(this.treeData, '运营商/车队名称')
        },
        isHidden(message) {
            this.isopenShow = message
        },
    },

}
</script>

<style scoped lang="less">
.app-container {
    background: rgba(255, 255, 255, 0);
    padding-left: 0px;
    padding-bottom: 24px;
}

.side-search {
    width: 200px;
    background: #FFFFFF;
    border-right: #d3dce6 1px solid;
}

.small-side {
    width: 36px;
    height: 36px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.14);
    border-right: 1px solid #EEE;
}

.mian-content {
    flex: 1;
    background: #F5F7FA;
}

.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.head-section {
    padding: 16px;
    background: #FFFFFF;
}
.head-section::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}
.btn-end {
    display: flex;
    justify-content: flex-end;
}

.section-main {
    width: 100%;
    //height: calc(100vh - 24px);
    background: #FFFFFF;
    margin-top: 16px;
    padding: 24px 16px 16px 16px;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.choose-mian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
        display: inline-block;
        margin-left: 16px;
    }
}

.alert-box {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
}

.pagin {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.main-flex {
    display: flex;

}

.left-style {
    padding-left: 43px;
}

.edit-style {
    color: #0D6CE4;
    font-size: 14px;
    cursor: pointer;
}

.more-search {
    display: flex;
    color: #0D6CE4;
    font-size: 14px;
    font-weight: 400;
    line-height: 40px;
    cursor: pointer;
    position: relative;
    left: -15px;

    i {
        line-height: 40px;
    }
}

.btn-color {
    color: #0D6CE4;
}
.head-bottom{
    margin-bottom: 16px;
}
.add-bottom{
    margin-bottom: 10px;
}
</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';
</style>
