<template>
    <div>
        <div class="app-container">
            <el-row class="main-flex">
                <el-col :span="3" class="side-search" v-if="isopenShow">
                    <monitoring @emitToParent='getTreeData' @isShow="isHidden"></monitoring>
                </el-col>
                <el-col v-if="!isopenShow" class="small-side">
                    <div class="change-in" @click="isopenShow = !  isopenShow "></div>
                </el-col>
                <!-- 搜索区域 -->
                <el-col :span="21" class="mian-content">
                    <div class="head-section">
                        <el-col :span="7">
                            <el-form :inline="true">
                                <el-form-item label="运营商/车队名称：" class="battery-num">
                                    <el-input placeholder="请选择运营商/车队" v-model.trim="query.teamId"
                                              clearable disabled></el-input>
                                </el-form-item>
                            </el-form>

                        </el-col>
                        <el-col :span="6">
                            <el-form :inline="true">
                                <el-form-item label="车牌号：" style="margin-left: 10px;">
                                    <el-input placeholder="请输入车牌号" v-model.trim="query.carNo"
                                              clearable></el-input>
                                </el-form-item>
                            </el-form>
                        </el-col>

                        <el-col :span="7">
                            <el-form :inline="true">
                                <el-form-item label="T-BOX编码：" class="">
                                    <el-input placeholder="请输入T-BOX编码" v-model.trim="query.tBox"
                                              clearable></el-input>
                                </el-form-item>
                            </el-form>
                        </el-col>
                        <el-col :span="4">
                            <div class="btn-end">
                                <span class="more-search" @click="showMore=!showMore"><div>展开</div>
                            <i :class="showMore==true?'el-icon-arrow-down':'el-icon-arrow-up'"></i> </span>
                                <span class="btn-rest">
                        <el-button plain @click="resetSearch">重置</el-button>
                            </span>
                                <span class="btn-submit">
                      <el-button type="primary" plain @click="searchPos">查询</el-button>
                            </span>
                            </div>
                        </el-col>
                    </div>
                    <transition name="el-zoom-in-top">
                        <div v-show="showMore">
                            <div class="head-section head-section2">
                                <el-col :span="7">
                                    <el-form :inline="true">
                                        <el-form-item label="电池编码：" class="battery-num">
                                            <el-input placeholder="请输入电池编码" v-model.trim="query.batteryCode"
                                                      clearable ></el-input>
                                        </el-form-item>
                                    </el-form>
                                </el-col>
                            </div>
                        </div>
                    </transition>
                    <div class="section-main">
                        <el-col class="choose-mian">
                            <div class="section-title">
                                车辆监控
                            </div>
                        </el-col>
                        <el-col :span="24" class="map-plant">
                            <div class="plant-son">
                                <div>车辆总数（辆）：<span>{{ this.statusTotal ? this.statusTotal.total : '0' }}</span>
                                </div>
                            </div>
                            <div class="plant-son">
                                <div>
                                    今日报警（辆）：<span>{{
                                        this.statusTotal ? this.statusTotal.alarmTodayTotal : '0'
                                    }}</span>
                                </div>
                            </div>
                            <div class="plant-son">
                                <div>
                                    当前报警（辆）：<span>{{
                                        this.statusTotal ? this.statusTotal.alarmCurrentTotal : '0'
                                    }}</span>
                                </div>
                            </div>
                            <div class="plant-son">
                                <div class="state-img"><span class="car-state onlinecar"></span>
                                    运行车辆（辆）：<span>{{
                                            this.statusTotal ? this.statusTotal.onlineTotal : '0'
                                        }}</span>
                                </div>
                            </div>
                            <div class="plant-son">
                                <div class="state-img"><span class="car-state offlinecar"></span>
                                    离线车辆（辆）：<span>{{
                                            this.statusTotal ? this.statusTotal.offlineTotal : '0'
                                        }}</span>
                                </div>
                            </div>
                            <div class="plant-son">
                                <div class="state-img"><span class="car-state breakdowncar"></span> 故障车辆（辆）：
                                    <span>{{ this.statusTotal ? this.statusTotal.alarmTotal : '0' }}</span>
                                </div>
                            </div>
                            <div class="plant-son">
                                <div class="state-img"><span class="car-state lowbatterycar"></span>
                                    低电量车辆（辆）：<span>{{
                                            this.statusTotal ? this.statusTotal.lowerPowerTotal : '0'
                                        }}</span>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="24" class="map-main">
                            <!--监控地图-->
                            <EFMAP ref="child" :amapid="mapid" @totalData="getTotalData"
                                   @setdialogTable="setdialogTable" ></EFMAP>
                            <div class="show-card" v-show="this.treeData">
                                <div>
                                    <div class="card-head">
                                        <div class="show-img">
                                            <div :class="{'change-side':cardShow==false,'change-card':cardShow==true}"
                                                 @click="cardShow = ! cardShow "></div>
                                        </div>
                                        <transition name="nested">
                                            <div class="card-title" v-if="cardShow">
                                                <div class="leve">等级</div>
                                                <div class="numno">VIN码</div>
                                                <div class="happen">发生时间</div>
                                                <!--<div class="safe">解除时间</div>-->
                                            </div>
                                        </transition>
                                    </div>
                                </div>
                                <transition name="nested">
                                    <div class="card-Box" v-if="cardShow && this.alarmsList.length!=0">
                                        <div class="card-title" v-for="(item,index) in this.alarmsList" :key="index" @click="cardChoose(item.vin)">
                                            <div class="show-warn">未解除</div>
                                            <div class="">
                                                <p class="show-inf">
                                                    <span
                                                        class="leve">{{
                                                            item.alarmLevel == 0 ? '无故障' : item.alarmLevel == -1 ? '异常' :
                                                                item.alarmLevel == null ? '--' : item.alarmLevel + '级'
                                                        }}</span>
                                                    <span class="numno">{{ item.vin }}</span>
                                                    <span class="happen">{{ item.createTime }}</span>
                                                    <!--                                                <span class="safe">2023-04-09 15:06:08</span> -->
                                                </p>
                                                <p class="show-inf">
                                                    {{
                                                        item.alarmType == 1 ? '电芯温差异常' :
                                                            item.alarmType == 2 ? '电芯温度过高' :
                                                                item.alarmType == 3 ? 'Pack过压' :
                                                                    item.alarmType == 4 ? 'Pack欠压' :
                                                                        item.alarmType == 5 ? 'Soc过低' :
                                                                            item.alarmType == 6 ? 'Soc过高' :
                                                                                item.alarmType == 7 ? 'Soc跳变' :
                                                                                    item.alarmType == 8 ? '单体电压过高' :
                                                                                        item.alarmType == 9 ? '单体电压欠压' :
                                                                                            item.alarmType == 10 ? '绝缘报警' :
                                                                                                item.alarmType == 11 ? '单体压差过大' :
                                                                                                    item.alarmType == 12 ? '充电电流过大' :
                                                                                                        item.alarmType == 13 ? '放电电流过大' : '电芯温度过底'
                                                    }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="warn-inf" v-if="cardShow && this.alarmsList.length==0">
                                        <div>暂无报警信息</div>
                                    </div>
                                </transition>
                            </div>
                        </el-col>
                    </div>
                </el-col>
            </el-row>
            <!-- 地图-->
            <el-dialog class="check-track"
                       title="查看轨迹"
                       :visible.sync="dialogVisible"
                       @close="closeTrack"
                       width="80%">
                <div class="dialog-card">
                    <div>
                        <span class="time-inf">时间：</span>
                        <el-date-picker
                            v-model="timeValue"
                            type="datetimerange"
                            format="yyyy-MM-dd HH:mm:ss"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            :picker-options="pickerOptionsStart"
                            :clearable="true"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </div>
                    <div>
                        <div class="btn-end">
                                <span class="btn-rest">
                                    <el-button plain @click="resetTime">
                                    重置
                                </el-button>
                                </span>
                            <span class="btn-submit">
                                    <el-button type="primary" plain @click="changePicker"
                                    >查询
                                </el-button>
                                </span>

                        </div>
                    </div>
                </div>
                <div class="map-box">
                    <EFMAP ref="child" :amapid="mapCard" @disabledChange="disabledChange" @passedPath="passedPath"></EFMAP>
                </div>
                <div class="map-operation">
                    <el-button v-if="!pauseBtn" @click="start('start')" icon="el-icon-video-play" type="primary">
                    </el-button>
                    <el-button v-if="pauseBtn" @click="stop('pause')" icon="el-icon-video-pause" type="primary">
                    </el-button>
                    <el-select v-model="speed" class="select-style" placeholder="选择倍速"
                               @change="handleSelect($event)">
                        <el-option :value="'1'" label="1倍速">1倍速</el-option>
                        <el-option :value="'2'" label="2倍速">2倍速</el-option>
                        <el-option :value="'4'" label="4倍速">4倍速</el-option>
                        <el-option :value="'8'" label="8倍速">8倍速</el-option>
                        <el-option :value="'16'" label="16倍速">16倍速</el-option>
                    </el-select>
                </div>
                <div class="slider-div">
                    <el-slider v-model="sliderVal" :step="1" @input="sliderChange"
                               :show-tooltip="false" :disabled="disabledValue"></el-slider>
                    <div class="progress-time">
                        <div>
                            {{ this.progressTime }} / {{this.timeValue[0]!=null?this.timeValue[0]:'--'}}
                        </div>
                        <div>
                            {{this.timeValue[1]!=null?this.timeValue[1]:'--'}}
                        </div>
                    </div>


                </div>
            </el-dialog>
            <!-- 表格-->
            <el-dialog class="tableDialog"
                       :visible.sync="setdialog"
                       width="60%"
            >
                <el-row>
                    <p class="table-dia">车辆详情</p>
                    <p class="table-dia table-title">整车信息</p>
                    <div class="car-inf-table">
                        <el-col :span="8">
                            <div class="col-inf">车牌号：</div>
                            <div>{{ '--' }}</div>
                        </el-col>
                        <el-col :span="8">
                            <div class="col-inf">VIN码：</div>
                            <div>{{ this.detailsCar ? this.detailsCar.vin : '--' }}</div>
                        </el-col>
                        <el-col :span="8">
                            <div class="col-inf">T-BOX编码：</div>
                            <div>{{ this.detailsCar ? this.detailsCar.tboxCode : '--' }}</div>
                        </el-col>
                    </div>
                    <div class="car-inf-table">
                        <el-col :span="8">
                            <div class="col-inf">电池编码：</div>
                            <div>{{ this.detailsCar ? this.detailsCar.batteryCode : '--' }}</div>
                        </el-col>
                        <el-col :span="8">
                            <div class="col-inf">车速(km/h)：</div>
                            <div>{{ this.detailsCar ? this.detailsCar.speed : '--' }}</div>
                        </el-col>
                        <el-col :span="8">
                            <div class="col-inf">累计里程(km):</div>
                            <div>{{ this.detailsCar ? this.detailsCar.mileage : '--' }}</div>
                        </el-col>
                    </div>
                    <div class="car-inf-table">
                        <el-col :span="8">
                            <div class="col-inf">车辆状态：</div>
                            <div>
                                {{
                                    this.detailsCar ? this.detailsCar.carStatus == 1 ? '上电模式' : this.detailsCar.carStatus == 2 ? '驱动模式' : this.detailsCar.carStatus == 3 ? '跛行模式' : this.detailsCar.carStatus == 4 ? '准备就绪' : this.detailsCar.carStatus == 5 ? '跛行准备' : '无效' : '--'
                                }}
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="col-inf">充电状态：</div>
                            <div>
                                {{
                                    this.detailsCar ? this.detailsCar.chargeSwapLink == 0 ? '未连接' : this.detailsCar.chargeSwapLink == 1 ? '连接' : '无效' : '--'
                                }}
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="col-inf">运行模式：</div>
                            <div>{{
                                    this.detailsCar ?
                                        this.detailsCar.runStatus == 1 ? '纯电' : this.detailsCar.runStatus == 2 ?
                                            '混动' : this.detailsCar.runStatus == 3 ? '燃油' : '异常' : '--'
                                }}
                            </div>
                        </el-col>
                    </div>
                    <div class="car-inf-table">
                        <el-col :span="8">
                            <div class="col-inf">SOC(%):</div>
                            <div>{{ this.detailsCar ? this.detailsCar.soc : '--' }}</div>
                        </el-col>
                        <el-col :span="8">
                            <div class="col-inf">总电流(A):</div>
                            <div>{{ this.detailsCar ? this.detailsCar.batCurrent : '--' }}</div>
                        </el-col>
                        <el-col :span="8">
                            <div class="col-inf">挡位：</div>
                            <div>{{
                                    this.detailsCar ?
                                        this.detailsCar.gearPosition == 1 ? '倒挡' : this.detailsCar.gearPosition == 2 ?
                                            '空挡' : this.detailsCar.gearPosition == 3 ? '前进挡' : '无效' : '--'
                                }}
                            </div>
                        </el-col>
                    </div>
                    <div class="car-inf-table">
                        <el-col :span="8">
                            <div class="col-inf">换电连接器状态:</div>
                            <div>{{
                                    this.detailsCar ?
                                        this.detailsCar.swapLink == 1 ? '工作' : this.detailsCar.swapLink == 2 ?
                                            '断开' : '异常' : '--'
                                }}
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="col-inf">充电回路连接器状态:</div>
                            <div>{{
                                    this.detailsCar ?
                                        this.detailsCar.chargeSwapLink == 0 ? '未连接' : this.chargeSwapLink.swapLink == 1 ?
                                            '连接' : '无效' : '--'
                                }}
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="col-inf">放电回路连接器状态:</div>
                            <div>{{
                                    this.detailsCar ?
                                        this.detailsCar.dischargeSwapLink == 0 ? '未连接' : this.chargeSwapLink.dischargeSwapLink == 1 ?
                                            '连接' : '无效' : '--'
                                }}
                            </div>
                        </el-col>
                    </div>
                    <div class="car-inf-table">
                        <el-col :span="8">
                            <div class="col-inf">锁止状态：</div>
                            <div>{{ '--' }}</div>
                        </el-col>
                    </div>
                    <p class="table-dia table-title extremum-inf">极值信息</p>
                    <table id="tab-extremum">
                        <tr class="head-extremum">
                            <th>电芯电压最大值所在csc编号</th>
                            <th>电芯电压最大值所在csc编号内单体编号</th>
                            <th>电芯电压最大值</th>
                        </tr>
                        <tr class="head-extremum">
                            <td>{{ this.detailsCar ? this.detailsCar.cellVolMaxCsc : '--' }}</td>
                            <td>{{ this.detailsCar ? this.detailsCar.cellVolMaxCscUnit : '--' }}</td>
                            <td>{{ this.detailsCar ? this.detailsCar.cellVolMax : '--' }}</td>
                        </tr>
                        <tr class="head-extremum">
                            <th>电芯电压最小值所在csc编号</th>
                            <th>电芯电压最小值所在csc编号内单体编号</th>
                            <th>电芯电压最小值</th>
                        </tr>
                        <tr class="head-extremum">
                            <td>{{ this.detailsCar ? this.detailsCar.cellVolMinCsc : '--' }}</td>
                            <td>{{ this.detailsCar ? this.detailsCar.cellVolMinCscUnit : '--' }}</td>
                            <td>{{ this.detailsCar ? this.detailsCar.cellVolMin : '--' }}</td>
                        </tr>
                        <tr class="head-extremum">
                            <th>电芯温度最大值所在csc编号</th>
                            <th>电芯电压最小值所在csc编号内单体编号</th>
                            <th>电芯电压最小值</th>
                        </tr>
                        <tr class="head-extremum">
                            <td>{{ this.detailsCar ? this.detailsCar.cellTemMaxCsc : '--' }}</td>
                            <td>{{ this.detailsCar ? this.detailsCar.cellTemMaxCscProbe : '--' }}</td>
                            <td>{{ this.detailsCar ? this.detailsCar.cellTemMax : '--' }}</td>
                        </tr>
                        <tr class="head-extremum">
                            <th>电芯温度最小值所在csc编号</th>
                            <th>电芯温度最小值所在csc内温度探针编号</th>
                            <th>电芯温度最小值</th>
                        </tr>
                        <tr class="head-extremum">
                            <td>{{ this.detailsCar ? this.detailsCar.cellTemMinCsc : '--' }}</td>
                            <td>{{ this.detailsCar ? this.detailsCar.cellTemMinCscProbe : '--' }}</td>
                            <td>{{ this.detailsCar ? this.detailsCar.cellTemMin : '--' }}</td>
                        </tr>
                    </table>
                    <p class="table-dia table-title today-warn">报警信息（当日）</p>
                    <table id="warn-table">
                        <tr>
                            <th class="index-warn">序号</th>
                            <th class="warn-lev">报警等级</th>
                            <th class="warn-inf">报警内容</th>
                            <th class="warn-time">报警时间</th>
                        </tr>
                        <tr v-for="(item,index) in this.warnList" :key="index">
                            <td>{{ index + 1 }}</td>
                            <td>
                                {{
                                    item.alarmLevel == 0 ? '无故障' : item.alarmLevel == -1 ? '异常' : item.alarmLevel + '级'
                                }}
                            </td>
                            <td>{{
                                    item.alarmType == 1 ? '电芯温差异常' :
                                        item.alarmType == 2 ? '电芯温度过高' :
                                            item.alarmType == 3 ? 'Pack过压' :
                                                item.alarmType == 4 ? 'Pack欠压' :
                                                    item.alarmType == 5 ? 'Soc过低' :
                                                        item.alarmType == 6 ? 'Soc过高' :
                                                            item.alarmType == 7 ? 'Soc跳变' :
                                                                item.alarmType == 8 ? '单体电压过高' :
                                                                    item.alarmType == 9 ? '单体电压欠压' :
                                                                        item.alarmType == 10 ? '绝缘报警' :
                                                                            item.alarmType == 11 ? '单体压差过大' :
                                                                                item.alarmType == 12 ? '充电电流过大' :
                                                                                    item.alarmType == 13 ? '放电电流过大' : '电芯温度过底'
                                }}
                            </td>
                            <td>{{ item.createTime }}</td>
                        </tr>
                    </table>
                </el-row>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import '@/assets/font/font.css';
import monitoring from '@/components/tree/monitoringCarTree.vue';
import EFMAP from "./mapLoader.vue"
import {
    carPosition, carTrack, alarmsPage, selectCarStatusTotal
} from "@/utils/vehiclApi";
import operator from "@/views/system/operator/index.vue";

export default {
    name: "registrationVehicles",
    computed: {
        operator() {
            return operator
        }
    },
    components: {
        monitoring, EFMAP
    },
    data() {
        return {
            showMore: false,
            isopenShow: true,
            query: {
                carNo: '',
                batteryCode: "",
                tBox: "",
                teamId:''
            },
            mapData: null,
            treeData: {
                operatorTeamOptions: [],  //运营商-车队树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
                operatorTeamNameTree: "",
                operatorTeamIdTree: null,
                defaultExpandAll: false,
            },
            cardShow: true,
            clusterOpen: null,//维护中聚合点实例
            dialogVisible: false,
            setdialog: false,
            mapid: 'container_amap',
            timeValue: [null,null],
            mapCard: 'card_amap',
            alarmsList: [],
            sliderVal: 0,
            speed: '1',
            getTime: '',
            pauseBtn: false,
            detailsCar: null,
            warnList: [],
            vinSearch: '',
            statusTotal: null,
            ptm: 0,
            progressTime:'00:00',
            pickerOptionsStart: {
                // 时间不能大于当前时间
                disabledDate: (time) => {
                    return time.getTime() > Date.now()
                }
            },
            trackLength:null,
            totalData:[],
            disabledValue:false
        }
    },
    watch: {
        timeValue(newVal) {
            if (newVal == null) {
                this.timeValue = [];
            }
        },
    },
    created() {
        this.getalarmsPage();// 报警信息
        this.getCarStatusTotal();//车辆总数数据
        window.openTrack = (data) => {
            this.dialogVisible = true
            this.pauseBtn = false
            this.vinSearch = data
            setTimeout(() => {
                this.$refs.child.getcarTrack(data, this.timeValue)
            }, 200)

            this.resetTime()
        }

    },
    mounted() {
    },
    destroyed() {

    },
    methods: {
        cardChoose(data){
            // console.log(data,'xxxxx',this.totalData)
            this.searchPos(data)
        },
        getTotalData(data){
            this.totalData=data
        },
        passedPath(data,length,passedTime){
            this.sliderVal=data
            this.trackLength=length
            if (passedTime){
                this.progressTime=passedTime
            }
        },
        // 重置输入
        resetSearch() {
            this.query.tBox = ''
            this.query.batteryCode = ''
            this.query.carNo = ''
        },
        operatorSider(){
            this.$refs.child.clusterChange(this.treeData.operatorTeamIdTree)
        },
        // 搜索信息展示点位
        searchPos(data) {
            //查找
            const searchData = this.totalData.find((item) =>
                item.carNo == this.query.carNo || item.batteryCode == this.query.batteryCode
                || item.tboxCode == this.query.tBox || item.vin == data); //返回对象
            if (searchData) {
                this.$refs.child.searchMoveTo(searchData.longitude, searchData.latitude)
            } else {
                this.$message.success('未查询到车辆记录！');
            }
        },
        resetTime() {
            // 设置默认时间
            var currentTime1 = new Date().getTime() // 获取当前时间
            var currentTime2 = new Date().setHours(new Date().getHours() - 1) // 1小时前
            this.timeValue = [this.timestampToTime(currentTime2), this.timestampToTime(currentTime1)]
        },
        changePicker() {
            setTimeout(() => {
                this.$refs.child.getcarTrack(this.vinSearch, this.timeValue)
            }, 200)
        },
        // 倍速功能
        handleSelect(e) {
            if (this.trackLength==0){
                return
            }
            let duration = parseInt(e)
            this.$store.commit('getduration', duration)
        },
        // 拖动进度条
        sliderChange(val) {
            if(val==0){
                return
            }
            let newVal = typeof(val)==='number' ? val : this.sliderVal
            let num = parseInt((newVal / 100) * this.trackLength);
            let decimal = String((newVal / 100) * this.trackLength).split('.')[1]||0
            this.$refs.child.moveToPoint(num,decimal)
        },
        setdialogTable(data) {
            this.detailsCar = data.data[0].extData
            this.setdialog = true
            this.getalarmsPage(1)
        },
        // 警告数据
        async getalarmsPage(vin) {
            let data={}
            if (vin==1){
                data={
                    vin:this.detailsCar.vin
                }
            }else {
                var id = this.treeData.operatorTeamIdTree;
                if (id != "" && id != null) {
                    if (id.indexOf("operator") != -1) {
                        id = id.slice(9);
                        data.operatorId = Number(id);
                    } else {
                        id = id.slice(5);
                        data.teamId = Number(id);
                    }
                }
            }
            await alarmsPage(data).then((res) => {
                if (vin==1){
                    this.warnList = JSON.parse(JSON.stringify(res.data.data.data))

                }else {
                    this.alarmsList = res.data.data.data
                }


            })
        },
        disabledChange(e){
            if (e==1){
                this.disabledValue=false
            }else {
                this.disabledValue=true
                this.$message.success('此时暂无轨迹数据');
            }

        },
        start(data) {//开启播放
            // console.log(this.trackLength,'this.trackLength')
            if (this.disabledValue==true){
                this.$message.success('暂无轨迹数据');
                return
            }else if (this.sliderVal!=0){
                data='resume'
                this.pauseBtn = !this.pauseBtn
                this.$refs.child.callMethod(data)
                return
            }else {
                this.pauseBtn = !this.pauseBtn
                this.$refs.child.callMethod(data)
            }
        },
        stop(data) {
            this.pauseBtn = !this.pauseBtn
            this.$refs.child.callstop(data)
        },
        timestampToTime(timestamp) {
            var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var Y = date.getFullYear() + '-';
            var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
            var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
            var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
            var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            return Y + M + D + h + m + s;
        },
        closeTrack() {
            this.$refs.child.destroyCard()
            this.dialogVisible = false
            this.speed = '1'
            this.progressTime='00:00'
            this.sliderVal=0
        },
        async getCarStatusTotal() {
            let data={}
            var id = this.treeData.operatorTeamIdTree;

            if (id != "" && id != null) {
                if (id.indexOf("operator") != -1) {
                    id = id.slice(9);
                    data.operatorId = Number(id);
                } else {
                    id = id.slice(5);
                    data.teamId = Number(id);
                }
            }
            // 车辆总数数据
            await selectCarStatusTotal(data).then(res => {
                this.statusTotal = res.data.data
            })
        },
        getTreeData(data) {
            // const index=data.operatorTeamIdTree.indexOf('_')
            this.treeData = data;
            this.query.teamId=data.operatorTeamNameTree
            this.operatorSider()//触发子组件方法更新点位
            this.getCarStatusTotal()//变化车辆总数
            this.getalarmsPage()//变化警告数据
        },
        isHidden(message) {
            this.isopenShow = message
        },
    }
}
</script>

<style scoped lang="less">
.app-container {
    background: rgba(255, 255, 255, 0);
    padding-left: 0px;
}

.side-search {
    width: 200px;
    background: #FFFFFF;
    border-right: #d3dce6 1px solid;
}

.small-side {
    width: 36px;
    height: 36px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.14);
    border-right: 1px solid #EEE;
}

.mian-content {
    flex: 1;
    background: #F5F7FA;
}

.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.head-section {
    padding: 16px;
    background: #FFFFFF;
    overflow: hidden;
}

.btn-end {
    display: flex;
    justify-content: flex-end;
}

.section-main {
    width: 100%;
    //height: calc(100vh - 24px);
    background: #FFFFFF;
    margin-top: 16px;
    padding: 24px 16px 16px 16px;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.map-plant {
    display: flex;
    //styleName: 平方14;
    font-size: 14px;
    font-weight: 400;
    color: #2F3133;
    justify-content: space-between;
    margin-bottom: 16px;
}

.choose-mian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
        display: inline-block;
        margin-left: 16px;
    }
}

.map-main {
    height: 600px;
    position: relative;
}

#container_amap {
    width: 100%;
    height: 600px;
}

.main-flex {
    display: flex;
}

.car-state {
    width: 54px;
    height: 20px;
    display: inline-block;
    margin-right: 8px;
}

.plant-son, .state-img {
    display: flex;
    align-items: center;
}

.onlinecar {
    background: url("~@/assets/image/onlinecar.svg") no-repeat;
    background-size: 100% 100%;
}

.offlinecar {
    background: url("~@/assets/image/offlinecar.svg") no-repeat;
    background-size: 100% 100%;
}

.breakdowncar {
    background: url("~@/assets/image/breakdowncar.svg") no-repeat;
    background-size: 100% 100%;
}

.lowbatterycar {
    background: url("~@/assets/image/lowbatterycar.svg") no-repeat;
    background-size: 100% 100%;
}

.show-card {
    position: absolute;
    right: 1%;
    top: 2%;
    background: #FFFFFF;
}

.change-card {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.change-side {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/iconinSide.svg") no-repeat;
    background-size: 100% 100%;
}

.card-head, .show-inf {
    height: 32px;
    line-height: 32px;
}

.card-head, .card-title {
    display: flex;
    font-size: 12px;
    font-weight: 400;
    color: #2F3133;

}
.card-title{
    border-bottom: 1px solid #EEEEEE;
}
.show-img {
    width: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.show-warn {
    width: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FF6165;
}

.show-inf {
    span {
        display: inline-block;
    }
}

.leve {
    width: 40px;
    text-align: center;
    //padding: 8px;
}

//.numno {
//    width: 140px;
//    text-align: center;
//}

.happen, .safe, .numno {
    width: 160px;
    text-align: center;
}

.nested-enter-active
.nested-leave-active {
    transition: all 0.2s ease;
}

.nested-enter-from,
.nested-leave-to {
    transform: translateX(0px);
    opacity: 0;
}

.nested-enter-active {
    transition-delay: 0.1s;
}

/deep/ .amap-info-content {
    padding: 0px;
}

///deep/ .amap-info-close {//弹窗的叉
//    display: none;
//}
.dialog-card {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    .time-inf {
        font-size: 14px;
        font-weight: 400;
        color: #585858;
    }
}

.map-box {
    position: relative;
}

.map-operation {
    position: absolute;
    left: 20px;
    bottom: 87px;
    padding: 8px 16px;
    background: #00000033;

    /deep/ .el-button { //btn大小
        padding: 4px 4px;
        //line-height: 20px;
        border: 1px solid #0D6CE4;
        color: #fff;
        font-size: 16px;
        background: #0D6CE4;
    }
}

.tableDialog {
    /deep/ .el-dialog__header {
        padding: 0;
    }

    /deep/ .el-dialog__body {
        padding: 24px;
    }

    .table-dia {
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        margin-bottom: 16px;
    }

    .table-title {
        font-size: 14px;
    }

    .car-inf-table {
        margin-bottom: 16px;
        color: #2F3133;

    }

    .car-inf-table::after {
        height: 0;
        content: '';
        clear: both;
        display: block;
        visibility: hidden;
    }

    .col-inf {
        margin-bottom: 8px;
        color: #585858;

    }
}

#tab-extremum, #tab-extremum tr th, #tab-extremum tr td {
    border: 1px solid #EEEEEE;
}

#tab-extremum {
    border-collapse: collapse;
}

.head-extremum {
    th, td {
        width: 384px;
        height: 40px;
        font-size: 14px;
        font-weight: 400;
        line-height: 40px;
        text-align: center;
        color: #2F3133;

    }
}

#warn-table, #warn-table tr th, #warn-table tr td {
    border: 1px solid #EEEEEE;
}

#warn-table {
    border-collapse: collapse;

    th, td {
        height: 40px;
        font-size: 14px;
        font-weight: 400;
        line-height: 40px;
        text-align: center;
        color: #2F3133;
    }

    .index-warn {
        width: 64px;
    }

    .warn-lev {
        width: 240px;
    }

    .warn-inf {
        width: 368px;
    }

    .warn-time {
        width: 240px;
    }
}

.today-warn {
    margin-top: 24px;
}

.extremum-inf {
    padding-top: 8px;
}

.select-style {
    margin-left: 10px;

    /deep/ .el-input__inner {
        width: 90px;
        height: 26px;
        line-height: 26px;
        background: rgba(255, 255, 255, 0);
        color: #FFFFFF;
        border: 0px solid #0D6CE4;
    }

}

/deep/ .el-select .el-input .el-select__caret {
    color: #FFFFFF;
    display: flex;
    align-items: center;
}

.more-search {
    display: flex;
    color: #0D6CE4;
    font-size: 14px;
    font-weight: 400;
    line-height: 36px;
    cursor: pointer;
    position: relative;
    left: -15px;

    i {
        line-height: 36px;
    }
}
/deep/.battery-num .el-form-item__label
{
    width: 155px;
}
.check-track{
    /deep/.el-dialog__title{
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
    }
}
.slider-div {

    .progress-time {
        display: flex;
        justify-content: space-between;
    }
}
.card-Box{
    height: 130px;
    overflow: hidden;
    overflow-y: auto;
}
.warn-inf{
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #CDD7D5;
}
/deep/.el-time-spinner__item{
    height: 18PX;
}
/deep/.el-dialog__headerbtn .el-dialog__close{
    position: relative;
    z-index: 5;
}
.head-section2 {
    overflow: hidden;
    padding: 0 16px 16px 16px;
    height: 100%;
}
</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';
/* 隐藏高德logo  */
.amap-logo {
    display: none !important;
}

/* 隐藏高德版权  */
.amap-copyright {
    display: none !important;
}

</style>

