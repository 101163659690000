export const getItem = (str) => {
    try {
        let res = window.localStorage.getItem(str)
        return res
    }catch (e) {
        this.$msg.error('getItem时发生异常')
    }
}

export const setItem = (str, data) => {
    try {
        window.localStorage.setItem(str, data)
    }catch (e) {
        this.$msg.error('setItem时发生异常')
    }
}

export const removeItem = (str) => {
    try {
        window.localStorage.removeItem(str)
    }catch (e) {
        this.$msg.error('removeItem时发生异常')
    }
}

export const sessionGetItem = (str) => {
    try {
        var res = window.sessionStorage.getItem(str)
        return JSON.parse(res)
    } catch (e) {
        this.$msg.error('sessionGetItem时发生异常')
    }
}

export const sessionSetItem = (str, data) => {
    try {
        window.sessionStorage.setItem(str, data)
    } catch (e) {
        this.$msg.error('sessionSetItem时发生异常')
    }
}

export const sessionRemoveItem = (str) => {
    try {
        window.sessionStorage.removeItem(str)
    } catch (e) {
        this.$msg.error('sessionRemoveItem时发生异常')
    }
}
