<template>
    <div class="app-container">
        <el-row class="main-flex">
            <el-col :span="3" class="side-search" v-if="isopenShow">
                <operatorTreeVue @emitToParent='getTreeData' @isShow="isHidden"></operatorTreeVue>
            </el-col>

            <el-col v-if="!isopenShow" class="small-side">
                <div class="change-in" @click="isopenShow = !  isopenShow "></div>
            </el-col>

            <!-- 搜索区域 -->
            <el-col :span="21" class="mian-content">

                <!--        <el-col :span="5">-->
                <!--          <el-input placeholder="请从左侧选择运营商" v-model.trim="treeData.operatorNameTree" disabled></el-input>-->
                <!--        </el-col>-->

                <!--        <el-col :span="5">-->
                <!--          <el-button @click="getSearch" type="primary" icon="el-icon-search" plain>查询</el-button>-->
                <!--          <el-button @click="resFuntion" plain icon="el-icon-refresh-right">重置</el-button>-->
                <!--        </el-col>-->
                <div class="head-section">
                    <el-col :span="7">
                        <el-form :inline="true">
                            <el-form-item label="换电站类型：">
                                <el-select v-model="nodeId" placeholder="请选择换电站类型" clearable>
                                    <el-option
                                        clearable
                                        v-for="item in nodeList"
                                        :key="item.id"
                                        :label="item.node"
                                        :value="item.id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="17">
                        <div class="btn-end">
                                <span class="btn-rest">
                                <el-button @click="resFuntion" plain>重置</el-button>
                                </span>
                            <span class="btn-submit">
                                    <el-button @click="getSearch" type="primary" plain>查询</el-button>
                                </span>
                        </div>
                    </el-col>
                </div>
                <!--                表格部分-->
                <div class="section-main">
                    <el-col class="choose-mian">
                        <div class="section-title">
                            场站类型列表
                        </div>
                        <div>
                            <!--选择项栏-->
                            <span class="alert-box">
                           已选择{{ batchNum.length }}项
                            <span @click="cleanList" style="margin-left:8px;color:#0D6CE4;cursor:pointer;">清空</span>
                        </span>
                            <span class="pain-btn">
                      <el-button @click="removeAll"
                                 plain
                                 v-hasCode="['base:stationtype:delete']">删除
                         </el-button>
                            </span>
                            <span class="add-btn">
                      <el-button @click="addOrUpdate =true"
                                 type="primary" plain
                                 v-hasCode="['base:stationtype:add']"> 新增
                  </el-button>
                            </span>
                            <!--      <el-button size="small" icon="el-icon-upload2" type="primary">批量导入</el-button>-->
                            <!--      <el-button size="small" icon="el-icon-download" @click="excelAll" type="primary">批量导出</el-button>-->
                        </div>
                    </el-col>
                    <el-col :span="24">
                        <el-row class="table-box">
                            <el-table ref="equipTable" id="equipment" @selection-change="batchList" :data="tableData"
                                       :header-cell-style="headerStyleEvent"
                                      :cell-class-name="goodsInfo" height="100%">
                                <el-table-column align="center" type="selection"></el-table-column>
                                <!--        <el-table-column label="序号" prop="fid"></el-table-column>-->
                                <el-table-column align="center" label="序号" type="index" width="80"
                                                 :index="renIndex"></el-table-column>
                                <el-table-column align="center" label="归属运营商" prop="operatorName"></el-table-column>
                                <el-table-column align="center" label="换电站类型编码" prop="stationTypeId"></el-table-column>
                                <el-table-column align="center" label="换电站类型名称" prop="stationTypeName"></el-table-column>
                                <el-table-column align="center" label="操作" fixed="right" width="150">
                                    <template slot-scope="scope">
                                        <span class="edit-style" @click="compileEqu(scope.row)"
                                        v-hasCode="['base:stationtype:update']">编辑
                                        </span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-row>
                    </el-col>
                    <el-col :span="24">
                        <el-row class="pagin">
                            <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="query.pageNum"
                                :page-sizes="[10, 30, 50,100]"
                                :page-size="query.pageSize"
                                layout="total, sizes, prev, pager, next"
                                :total="total">
                            </el-pagination>
                        </el-row>
                    </el-col>
                </div>
            </el-col>
            <el-dialog
                :title="isequipemt?'新增信息':'修改信息'"
                :visible.sync="addOrUpdate"
                width="40%"
                @close="close"
            >

                <el-form size="middle" ref="equipform" :rules="equipmentRules" :model="informationForm"
                         label-width="120px">
                    <el-row>
                        <el-col>
                            <el-form-item label="归属运营商" prop="operatorId">
                                <TreeSelect v-model="informationForm.operatorId" :options="treeData.operatorOptions"
                                            placeholder="请选择运营商"/>
                                <!-- <TreeSelect v-model="addForm.operatorId" :options="operatorOptions" placeholder="选择上级目录" @select="change(node)"/>-->
                            </el-form-item>
                            <el-form-item label="换电站类型编号:" prop="stationTypeId">
                                <el-input v-model="informationForm.stationTypeId"></el-input>
                            </el-form-item>
                            <el-form-item label="换电站类型名称:" prop="stationTypeName">
                                <el-input v-model="informationForm.stationTypeName"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <span slot="footer" class="dialog-footer">
        <el-button @click="addOrUpdate = false">取 消</el-button>
        <el-button type="primary" @click="newequipemt">确 定</el-button>
      </span>
            </el-dialog>
        </el-row>
    </div>
</template>

<script>
import '@/assets/font/font.css';
import {
    getStationTypePage,
    addStationType,
    updateStationType,
    deleteStationType
} from '@/utils/station2'
import {getForceList} from '@/utils/station'
import moment from 'moment'
import {mapState} from "vuex";

import operatorTreeVue from "@/components/tree/operatorTree"
import TreeSelect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {operatorTree} from "@/utils/operatorApi";

export default {
    name: 'stationTypeName',
    components: {TreeSelect, operatorTreeVue},
    data() {
        return {
            isopenShow: true,
            selectOptions: [
                {
                    value: 1,
                    label: "启用",
                },
                {
                    value: 0,
                    label: "禁用",
                },
            ],

            informationVo: {},
            isPage: true,//查询
            query: {
                pageNum: 1,
                pageSize: 10
            },
            total: 0,
            addOrUpdate: false,
            isequipemt: true,
            batchNum: [],
            tableData: [],
            allList: [],
            stateList: [
                {
                    value: 1,
                    label: '在役'
                },
                {
                    value: 0,
                    label: '退役'
                }
            ],
            informationForm: {},
            equipmentRules: {
                operatorId: [
                    {required: true, message: '请选择归属运营商', trigger: 'blur'}
                ],
                stationTypeId: [
                    {required: true, message: '请输入换电站类型编号', trigger: 'blur'}
                ],
                stationTypeName: [
                    {required: true, message: '请输入换电站类型名称', trigger: 'blur'}
                ]
            },
            brandList: [],
            unitTypeList: [],

            treeData: {
                operatorNameTree: "",
                operatorIdTree: null,
                operatorOptions: undefined,  //运营商树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
            },

        }
    },
    computed: {
        ...mapState(['user']),
        username() {
            return JSON.parse(this.user).name
        }

    },
    created() {
        this.getOperatorTree();
        this.getAllPage()
        // this. getAllList()
    },
    methods: {
        headerStyleEvent({row, column, rowIndex, columnIndex}) {
            if (columnIndex == 1) {
                return 'padding:0px;'
            }
        },
        goodsInfo(row) {
            if (row.columnIndex == 1) {
                return 'goodsInfo'
            }
        },
        isHidden(message) {
            this.isopenShow = message
        },
        renIndex(index) {
            return (this.query.pageNum - 1) * this.query.pageSize + index + 1
        },

        async getAllPage() {
            var data = this.informationVo;
            data.page = this.query.pageNum
            data.size = this.query.pageSize
            var url = "?page=" + this.query.pageNum + "&size=" + this.query.pageSize;
            if (this.treeData.operatorNameTree != "" && this.treeData.operatorNameTree != null) {
                url += "&operatorId=" + this.treeData.operatorIdTree
            } else if (this.treeData.operatorOptions.length > 0) {
                url += "&operatorId=" + this.treeData.operatorOptions[0].id
            }
            data.url = url;

            const result = await getStationTypePage(data)
            // console.log(result)
            if (result.data.code !== 200)
                return this.$msg.error('获取信息失败!' + res.data.code)
            this.tableData = result.data.data.content;
            this.total = result.data.data.totalElements
        },

        //搜索项
        getSearch() {
            this.query.pageNum = 1;
            this.getAllPage();
        },

        //重置
        resFuntion() {
            this.informationVo = {}
            this.treeData.operatorNameTree = ""
            this.treeData.operatorIdTree = null
            this.getAllPage()
        },
        batchList(list) {
            this.batchNum = list
        },
        close() {
            this.$refs.equipform.resetFields()
            this.informationForm = {}
            this.isequipemt = true
        },

        //新增与修改
        newequipemt() {
            this.$refs.equipform.validate(async valid => {
                if (!valid) return this.$msg.info('信息不能为空!')
                const data = JSON.parse(JSON.stringify(this.informationForm))
                try {
                    if (this.isequipemt) {
                        const res = await addStationType(data)
                        if (res.data.code !== 200) return this.$msg.error('新增信息失败!')
                        this.$msg.success('新增信息成功!')
                        this.addOrUpdate = false
                        this.getAllPage()
                    } else {
                        const res = await updateStationType(data)
                        if (res.data.code !== 200) return this.$msg.error('修改信息失败!')
                        this.$msg.success('修改换信息成功!')
                        this.addOrUpdate = false
                        this.getAllPage()
                    }
                } catch (error) {
                }
            })

        },
        //修改信息填充
        compileEqu(list) {
            this.addOrUpdate = true
            this.isequipemt = false
            var data = JSON.parse(JSON.stringify(list))
            this.informationForm = data
        },

        handleSizeChange(val) {
            this.query.pageSize = val
            this.getAllPage()
        },
        handleCurrentChange(val) {
            this.query.pageNum = val
            this.getAllPage()
        },

        cleanList() {
            this.$refs.equipTable.clearSelection()
        },
        //批量删除
        async removeAll() {
            if (this.batchNum.length === 0) return this.$msg.info('请选择需要删除的项')
            const config = await this.$MessageBox.confirm(
                "此操作将永久删除选中项, 是否继续?",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }
            ).catch(e => e);
            if (config !== "confirm") return;
            try {
                const list = this.batchNum.map(item => {
                    return item.id
                }).join(",")
                const res = await deleteStationType(list)
                if (res.data.code !== 200) return this.$msg.error('删除失败')
                this.tableData = this.tableData.filter((item, i) => {
                    return item.id !== list[i]
                })
                this.$refs.equipTable.clearSelection()
                if (this.query.pageNum > 1 && this.tableData.length === 0) {
                    this.query.pageNum = this.query.pageNum - 1
                }
                this.$msg.success('删除成功')
                this.getAllPage()
            } catch (e) {
                console.log(e);
            }
        },

        formatting(time) {
            return moment(time).format('YYYY-MM-DD HH:mm:ss')
        },

        /** 查询运营商下拉树结构 */
        async getOperatorTree() {
            try {
                this.treeData.operatorOptions = [];
                let res = await operatorTree();
                if (res.status !== 200) return this.$msg.error("获取运营商信息失败");
                this.treeData.operatorOptions.push(res.data.data)
                this.treeData.operatorIdTree = res.data.data.id
                this.treeData.operatorNameTree = res.data.data.label
            } catch (error) {
            }
        },

        getTreeData(data) {
            this.treeData = data
        },

    },
}
</script>

<style lang='less' scoped>
.app-container {
    background: rgba(255, 255, 255, 0);
    padding-left: 0px;
}


.inner-container {
    //min-width: 1300px;
    //height: var(--tableHeight);;
    overflow: hidden;
}

//重构新增
.side-search {
    width: 200px;
    background: #FFFFFF;
    border-right: #d3dce6 1px solid;
}

.small-side {
    width: 36px;
    height: 36px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.14);
    border-right: 1px solid #EEE;
}

.mian-content {
    flex: 1;
    background: #F5F7FA;
}

.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.head-section {
    padding: 16px;
    background: #FFFFFF;
    overflow: hidden;
}

.btn-end {
    display: flex;
    justify-content: flex-end;
}

.section-main {
    width: 100%;
    //height: calc(100vh - 24px);
    background: #FFFFFF;
    margin-top: 16px;
    padding: 24px 16px 16px 16px;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.choose-mian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
        display: inline-block;
        margin-left: 16px;
    }
}

.alert-box {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
}

.pagin {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.main-flex {
    display: flex;

}

.left-style {
    padding-left: 43px;
}

.edit-style {
    color: #0D6CE4;
    font-size: 14px;
    cursor:pointer;
}
</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';
</style>
