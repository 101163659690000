<template>
    <div class="app-container">
        <el-row class="main-flex">
            <!--运营商场站树-->
            <el-col :span="3" class="side-search" v-if="isopenShow">
                <operatorStationTreeVue @emitToParent='getTreeData' @isShow="isHidden"></operatorStationTreeVue>
            </el-col>

            <el-col v-if="!isopenShow" class="small-side">
                <div class="change-in" @click="isopenShow = !  isopenShow "></div>
            </el-col>

            <!-- 搜索区域 -->
            <!--                    <el-input placeholder="请从左侧选择运营商/场站" v-model.trim="treeData.operatorStationNameTree" disabled>-->
            <!--                    </el-input>-->
            <el-col :span="21" class="mian-content">
                <div class="head-section">
                    <el-col :span="6">
                        <el-form :inline="true">
                            <el-form-item label="车牌号：" label-width="82px">
                                <el-input placeholder="请输入车牌号" v-model.trim="query.carNo" clearable></el-input>
                            </el-form-item>

                        </el-form>
                    </el-col>
                    <el-col :span="7">
                        <el-form :inline="true">
                            <el-form-item label="换电订单号：" class="left-style">
                                <el-input placeholder="请输入换电订单号" v-model.trim="query.son" clearable></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="7">
                        <el-form :inline="true">
                            <el-form-item label="充电订单号：" label-width="96px">
                                <el-input placeholder="请输入充电订单号" v-model.trim="query.chrsn" clearable></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="4">
                        <div class="btn-end">
                            <span class="more-search" @click="showMore=!showMore"> <div>展开</div>
                            <i :class="showMore==true?'el-icon-arrow-down':'el-icon-arrow-up'"></i> </span>
                            <span class="btn-rest">
                         <el-button @click="resFuntion" plain>
                            重置
                        </el-button>
                            </span>
                            <span class="btn-submit">
                       <el-button @click="getChargeOrderList()" type="primary" plain>
                            查询
                        </el-button>
                            </span>
                        </div>
                    </el-col>
                </div>
                <transition name="el-zoom-in-top">
                    <div v-show="showMore">
                        <div class="head-section head-section2">
                            <el-col :span="6">
                                <el-form :inline="true">
                                    <el-form-item label="车辆VIN：" label-width="82px">
                                        <el-input placeholder="请输入vin" v-model.trim="query.carVin" clearable></el-input>
                                    </el-form-item>
                                </el-form>
                            </el-col>
                            <el-col :span="7">
                                <el-form :inline="true">
                                    <el-form-item label="电池序列号：" class="left-style">
                                        <el-input placeholder="请输入电池序列号" v-model.trim="query.bid" clearable></el-input>
                                    </el-form-item>
                                </el-form>
                            </el-col>
                            <el-col :span="7">
                                <el-form :inline="true">
                                    <el-form-item label="充电方式："  label-width="96px">
                                        <el-select v-model="query.sfoc" placeholder="选择充电方式" clearable>
                                            <el-option clearable v-for="item in chargeWay" :key="item.value" :label="item.label"
                                                       :value="item.value"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-form>
                            </el-col>
                        </div>
                        <div class="head-section head-section2">
                            <el-col :span="6">
                                <el-form :inline="true">
                                    <el-form-item label="选择月：" label-width="82px">
                                        <el-date-picker value-format="yyyy-MM" v-model="query.recordTime" type="month" placeholder="选择月(默认当前月)" clearable></el-date-picker>
                                    </el-form-item>
                                </el-form>
                            </el-col>
                        </div>
                    </div>
                </transition>

                <!--  表格部分-->
                <div class="section-main">
                    <el-col class="choose-mian">
                        <div class="section-title">
                            充电订单列表
                        </div>
                        <div>
                            <span class="pain-btn">
                             <el-button @click="batchExport()" plain
                                        v-hasCode="['charge:order:export']">
                                导出
                            </el-button>
                            </span>
                        </div>
                    </el-col>
                    <!-- table -->
                    <el-col :span="24">
                            <el-row class="table-box">

                                <el-table ref="orderTable" id="excel-tabel" :header-cell-style="headerStyleEvent"
                                          :cell-class-name="goodsInfo"
                                          :data="tableData" height="100%">
                                    <el-table-column align="center" label="编号" :index="renIndex" type="index"></el-table-column>

                                    <el-table-column align="center" prop="stationNo" label="场站编码"
                                                     :show-overflow-tooltip="true" min-width="100"></el-table-column>

                                    <el-table-column align="center" prop="stationName" label="场站名称"
                                                     :show-overflow-tooltip="true" min-width="120"></el-table-column>

                                    <el-table-column prop="carNo" label="车牌号" min-width="100"></el-table-column>

                                    <!--
                                                                <el-table-column min-width="120" prop="stationName" label="场站名称"
                                                                                 :show-overflow-tooltip="true">
                                                                </el-table-column>
                                    -->

                                    <el-table-column align="center" prop="chrsn" label="充电订单号" min-width="250"></el-table-column>
                                    <el-table-column align="center" prop="son" label="换电订单号" min-width="270"></el-table-column>

                                    <el-table-column align="center" prop="bid" label="电池序列" min-width="100"
                                                     :show-overflow-tooltip="true"></el-table-column>

                                    <el-table-column align="center" prop="ct" label="充电时长(min)" min-width="120"></el-table-column>


                                    <el-table-column prop="dcce" label="直流电量(kWh)" min-width="120"
                                                                                 :show-overflow-tooltip="true"></el-table-column>

                                    <el-table-column prop="acce" label="交流电量(kWh)" min-width="120"
                                                                                 :show-overflow-tooltip="true"></el-table-column>

                                    <el-table-column prop="cn" label="充电次数"
                                                                                 :show-overflow-tooltip="true"></el-table-column>

                                    <el-table-column align="center" prop="sfs" label="充电方式"  min-width="80">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.sfoc === 0" class="states" style="color: #0D6CE4"> 站内充电 </span>
                                            <span v-else-if="scope.row.sfoc === 1" class="states" style="color: #19B21E;">站外充电</span>
                                            <span v-else class="states" style="color:#FFCD29">未知</span>
                                        </template>
                                    </el-table-column>

                                    <el-table-column align="center" prop="sfs" label="上报方式"  min-width="80">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.sfs === 1" class="states" style="color: #0D6CE4"> 自动 </span>
                                            <span v-else-if="scope.row.sfs === 2" class="states" style="color: #19B21E;">人工手动</span>
                                            <span v-else class="states" style="color:#FFCD29">未知</span>
                                        </template>
                                    </el-table-column>

                                    <el-table-column align="center" prop="createTime" label="创建时间"  min-width="180">
                                    </el-table-column>
                                    <!--
                                                                <el-table-column prop="remark" label="备注" :show-overflow-tooltip="true">
                                                                </el-table-column>
                                                                -->

                                    <el-table-column align="center" label="操作" fixed="right" width="80px">
                                        <template slot-scope="scope">
                                            <span class="edit-style" @click="handleDetail(scope.row)" >详情
                                            </span>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-row>
                    </el-col>

                    <!--分页栏-->
                    <el-col :span="24">
                        <el-row class="pagin">
                            <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="query.pageNum"
                                :page-sizes="[10, 30, 50, 100]"
                                :page-size="query.pageSize"
                                layout="total, sizes, prev, pager, next"
                                :total="total">
                            </el-pagination>
                        </el-row>
                    </el-col>
                </div>


            </el-col>

        </el-row>

        <!-- 详情弹出框 -->
        <el-dialog @close="chargeOrderClose"
                   :title="true ? '充电订单详情' : '充电订单详情'"
                   :visible.sync="isChargeOrderDetail" width="50%">
            <div class="dialogCenter" style="width: 98%; margin: 0 auto">
                <div class="block_1">
                    <div class="block_1_order">
                        <span>场站编码:</span>
                        <span style="margin-left: 10px; font-weight: bolder">{{chargeOrder.stationNo}}</span>
                    </div>
                </div>

                <div class="block_2 circular">
                    <div class="block_2_box4" style="width: 40%">
                        <span>充电订单号:</span>
                        <span class="contentbox">{{chargeOrder.chrsn }}</span>
                    </div>
                    <div class="block_2_box5" style="width: 50%">
                        <span>换电订单号:</span>
                        <span class="contentbox">{{chargeOrder.son}}</span>
                    </div>

                    <div class="block_2_box5" style="width: 40%">
                        <span>车牌号:</span>
                        <span class="contentbox">{{chargeOrder.carNo}}</span>
                    </div>

                    <div class="block_2_box5" style="width: 50%">
                        <span>VIN:</span>
                        <span class="contentbox">{{chargeOrder.vin}}</span>
                    </div>
                </div>

                <div class="block_3 circular">
                    <div class="block_3_box2">
                        <span>电池序列号:</span>
                        <span class="contentbox">{{chargeOrder.bid}}</span>
                    </div>
                    <div class="block_3_box3">
                        <span>开始时间:</span>
                        <span class="contentbox">{{chargeOrder.st}}</span>
                    </div>

                    <div class="block_3_box3">
                        <span>结束时间:</span>
                        <span class="contentbox">{{chargeOrder.et}}</span>
                    </div>

                    <div class="block_3_box5">
                        <span>开始 SOC:</span>
                        <span class="contentbox">{{chargeOrder.ssoc}}</span>
                    </div>

                    <div class="block_3_box5">
                        <span>结束 SOC:</span>
                        <span class="contentbox">{{chargeOrder.esoc}}</span>
                    </div>

                    <!--
                     <div class="block_3_box5">
                         <span>开始 SOE(单位:kWh:</span>
                         <span class="contentbox">{{chargeOrder.ssoe}}</span>
                     </div>

                     <div class="block_3_box5">
                         <span>结束SOE(单位:kWh:</span>
                         <span class="contentbox">{{chargeOrder.esoe}}</span>
                     </div>
                     -->

                    <div class="block_3_box5">
                        <span>直流电表充电电量(kWh):</span>
                        <span class="contentbox">{{chargeOrder.dcce}}</span>
                    </div>

                    <div class="block_3_box5">
                        <span>交流电表充电电量(kWh):</span>
                        <span class="contentbox">{{chargeOrder.acce}}</span>
                    </div>

                    <div class="block_3_box5">
                        <span>尖时段电量(kWh):</span>
                        <span class="contentbox">{{chargeOrder.tp}}</span>
                    </div>

                    <div class="block_3_box5">
                        <span>峰时段电量(kWh):</span>
                        <span class="contentbox">{{chargeOrder.pp}}</span>
                    </div>

                    <div class="block_3_box5">
                        <span>平时段电量(kWh):</span>
                        <span class="contentbox">{{chargeOrder.fp}}</span>
                    </div>

                    <div class="block_3_box5">
                        <span>谷时段电量(kWh):</span>
                        <span class="contentbox">{{chargeOrder.vp }}</span>
                    </div>

                    <div class="block_3_box5">
                        <span>修正电量(kWh):</span>
                        <span class="contentbox">{{chargeOrder.ep }}</span>
                    </div>

                    <div class="block_3_box5">
                        <span>充电时长(分钟):</span>
                        <span class="contentbox">{{chargeOrder.ct}}</span>
                    </div>

                    <div class="block_3_box5">
                        <span>充电次数:</span>
                        <span class="contentbox">{{chargeOrder.cn }}</span>
                    </div>

                    <div class="block_3_box5">
                        <span>上报方式</span>
                        <span v-if="chargeOrder.sfs === 1" class="contentbox" style="color: #1890ff">自动</span>
                        <span v-else-if="chargeOrder.sfs === 2" class="contentbox" style="color: #02a6b5">人工手动</span>
                        <span v-else class="contentbox" style="color: orange">未知</span>
                    </div>

                    <div class="block_3_box5">
                        <span>车架号:</span>
                        <span class="contentbox">{{chargeOrder.vin}}</span>
                    </div>

                    <div class="block_3_box5">
                        <span>是否站外插枪充电</span>
                        <span v-if="chargeOrder.sfoc === 0" class="contentbox" style="color: #1890ff">站内充电</span>
                        <span v-else-if="chargeOrder.sfoc === 1" class="contentbox" style="color: orange">站外充电</span>
                    </div>

                    <div class="block_3_box5">
                        <span>创建时间:</span>
                        <span class="contentbox">{{chargeOrder.createTime}}</span>
                    </div>

                    <div class="block_3_box5">
                        <span>备注:</span>
                        <span class="contentbox">{{chargeOrder.remark}}</span>
                    </div>

                </div>
            </div>
            <div class="closeDialog" @click="chargeOrderClose">关闭</div>
        </el-dialog>
    </div>
</template>

<script>
import '@/assets/font/font.css';
import {chargeOrderPage, chargeOrderExport,} from "@/utils/order";
import "@/utils/dateUtil";
import operatorStationTreeVue from "@/components/tree/operatorStationTree";
import {myconfig} from "@/utils/config";
import * as myStore from "@/utils/stroage";
import axios from "axios";
import moment from "moment";

export default {
    name: "chargeOrder",
    components: {operatorStationTreeVue},
    data() {
        return {
            showMore: false,
            isopenShow: true,
            query: {
                pageNum: 1,
                pageSize: 10,
                resPageNum: 1,
                operatorId: "",
                stationId: null,
                chrsn: "",//充电订单号
                son: "", //换电订单号
                bid:"",//电池序列
                sfoc:null,
                carNo:"",
                carVin:"",
                recordTime:null//订单日期
            },
            pickerOptions: {
                disabledDate(time) {
                    //return time.getTime() < Date.now();
                },
            },
            clientHeight: 0,
            tableData: [],
            total: 0, //数据总数
            isChargeOrderDetail: false,
            chargeOrder: {
                originalOrder: {},
                swapBillingModelVO: {},
                staSwapRecord: {},
            }, //消费者订单详情

            //左侧 运营商-场站树
            treeData: {
                operatorStationOptions: undefined,  //运营商-场站树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
                operatorStationNameTree: "",
                operatorStationIdTree: null,
                defaultExpandAll: true,
            },

            chargeWay: [
                {
                    value: 0,
                    label: "站内充电",
                },
                {
                    value: 1,
                    label: "站外充电",
                }
            ],

        };
    },
    computed: {},
    created() {
        this.getChargeOrderList();
        this.clientHeight = document.body.clientHeight - 240;
    },
    methods: {
        headerStyleEvent({row, column, rowIndex, columnIndex}) {
            if (columnIndex == 1) {
                return 'padding:0px;'
            }
        },
        goodsInfo(row) {
            if (row.columnIndex == 1) {
                return 'goodsInfo'
            }
        },
        isHidden(message) {
            this.isopenShow = message
        },
        async getChargeOrderList() {

            var url = "?page=" + this.query.pageNum + "&size=" + this.query.pageSize;
            var id = this.treeData.operatorStationIdTree;
            if (id !== "" && id !== null) {
                if (id.indexOf("operator") !== -1) {
                    id = id.slice(9);
                    url += "&operatorId=" + Number(id)
                } else {
                    id = id.slice(8);
                    url += "&stationId=" + Number(id)
                }
            }

            if(this.query.chrsn !== "" && this.query.chrsn !== null){
                url += "&chrsn=" + this.query.chrsn;
            }
            if(this.query.son !== "" && this.query.son !== null){
                url += "&son=" + this.query.son;
            }
            if(this.query.sfoc !== "" && this.query.sfoc !== null){
                url += "&sfoc=" + this.query.sfoc;
            }

            if(this.query.carNo !== "" && this.query.carNo !== null){
                url += "&carNo=" + this.query.carNo;
            }
            if(this.query.carVin !== "" && this.query.carVin !== null){
                url += "&carVin=" + this.query.carVin;
            }

            if(this.query.bid !== "" && this.query.bid !== null){
                url += "&bid=" + this.query.bid;
            }
            if(this.query.recordTime !== "" && this.query.recordTime !== null){
                url += "&recordTime=" + this.query.recordTime;
            }

            const res = await chargeOrderPage(url);
            if (res.data.code !== 200) {
                return this.$msg.error("获取充电订单信息失败");
            }
            this.tableData = res.data.data.data;
            this.total = res.data.data.total;
        },

        //查看详情
        async handleDetail(row) {
            this.chargeOrder = row;
            this.isChargeOrderDetail = true;
        },

        chargeOrderClose() {
            this.chargeOrder = {
                originalOrder: {},
                swapBillingModelVO: {},
                staSwapRecord: {},
            };
            this.isChargeOrderDetail = false;
        },

        renIndex(index) {
            return (this.query.pageNum - 1) * this.query.pageSize + index + 1;
        },

        cleanList() {
            this.$refs.orderTable.clearSelection();
        },
        async changeSelect() {
        },
        resFuntion() {
            this.treeData.operatorStationNameTree = ""
            this.treeData.operatorStationIdTree = null
            this.query= {
                pageNum: 1,
                pageSize: 10,
                resPageNum: 1,
                operatorId: "",
                stationId: null,
                chrsn: "",//充电订单号
                son: "", //换电订单号
                bid:"",//电池序列
                sfoc:null,//是否站外充电 0:站内充电 1：站外充电
                carNo:"",
                carVin:"",
                recordTime:""//订单日期
            };
            this.getChargeOrderList();
        },
        handleSizeChange(val) {
            //每页数量改变
            this.query.pageSize = val;
            this.getChargeOrderList();
        },
        handleCurrentChange(val) {
            //当前页数改变
            this.query.pageNum = val;
            this.getChargeOrderList();
        },

        getTreeData(data) {
            this.treeData = data;
        },


        async batchExport(){
            var requestVo = {};
            var id = this.treeData.operatorStationIdTree;
            if (id !== "" && id !== null) {
                if (id.indexOf("operator") !== -1) {
                    id = id.slice(9);
                    requestVo.operatorId=Number(id);
                } else {
                    id = id.slice(8);
                    requestVo.stationId=Number(id);
                }
            }

            if (this.query.recordTime !== "" && this.query.recordTime !== null) {
                requestVo.recordTime = this.query.recordTime;
            }
            if(this.query.chrsn !== "" && this.query.chrsn !== null){
                requestVo.chrsn = this.query.chrsn;
            }
            if(this.query.son !== "" && this.query.son !== null){
                requestVo.son = this.query.son;
            }
            if(this.query.sfoc !== "" && this.query.sfoc !== null){
                requestVo.sfoc = this.query.sfoc;
            }
            if(this.query.carNo !== "" && this.query.carNo !== null){
                requestVo.carNo = this.query.carNo;
            }
            if(this.query.carVin !== "" && this.query.carVin !== null){
                requestVo.carVin = this.query.carVin;
            }
            if(this.query.bid !== "" && this.query.bid !== null){
                requestVo.bid = this.query.bid;
            }

            //await chargeOrderExport(url);
            var url = myconfig.url + "/service-baseinfo/swapOrder/chargeOrder/export";
            const user = myStore.sessionGetItem("electric-token");
            var token = 'Bearer ' + user.data;
            await axios.get(url, {params: requestVo,responseType: "blob",headers: {'Authorization': token}})
                .then(response => {
                    console.log("===============获取充电订单信息成功============");
                    const content = response.data;
                    const blob = new Blob([content]);
                    const fileName = "充电订单信息.xls";
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                    URL.revokeObjectURL(link.href);
                    //document.body.removeChild(link);
                }).catch(console.error);
        },



    },
};
</script>

<style lang="less" scoped>
.app-container {
    background: rgba(255, 255, 255, 0);
    padding-left: 0px;
}

// .inner-container .el-table__fixed-right{
//     height:100% !important;
// }
// .el-table__fixed,.el-table__fixed-right{
//     height:100% !important;
// }

::v-deep .el-dialog__header {
    background-color: #e4ebf1;

    .el-dialog__title {
        font-weight: bolder;

        .dialogCenter1 .block_5 {
            background-color: #e4ebf1;
            display: flex;
            flex-direction: row;
            margin: 10px 0;
            flex-wrap: wrap;

            [class^="block_5_box"] {
                text-align: left;
                box-sizing: border-box;
                padding: 10px 10px;
                display: flex;
                width: 25%;
                flex-direction: column;

                .contentbox {
                    font-weight: bold;
                }
            }
        }
    }
}

.block_5 {
    background-color: #e4ebf1;
    display: flex;
    flex-direction: row;
    margin: 20px 0;
    padding: 20px 20px;
    flex-wrap: wrap;

    [class^="block_5_box"] {
        text-align: center;

        box-sizing: border-box;
        padding: 15px 15px;
        padding-left: 30px;
        display: flex;
        width: 30%;
        // flex-direction: column;
        .contentbox {
            font-weight: bold;
            padding-left: 8px;
        }
    }
}

.block_2 {
    background-color: #e4ebf1;
    display: flex;
    flex-direction: row;
    margin: 10px 0;
    flex-wrap: wrap;

    [class^="block_2_box"] {
        text-align: left;
        box-sizing: border-box;
        padding: 10px 10px;
        display: flex;
        width: 20%;
        flex-direction: column;

        .contentbox {
            font-weight: bold;
        }
    }
}

.block_3 {
    background-color: #e4ebf1;
    display: flex;
    flex-direction: row;
    margin: 10px 0;
    flex-wrap: wrap;

    [class^="block_3_box"] {
        text-align: left;
        box-sizing: border-box;
        padding: 10px 10px;
        display: flex;
        width: 20%;
        flex-direction: column;

        .contentbox {
            font-weight: bold;
        }
    }
}

.block_4 {
    background-color: #e4ebf1;
    display: flex;
    flex-direction: row;
    margin: 10px 0;
    flex-wrap: wrap;

    [class^="block_4_box"] {
        text-align: left;
        box-sizing: border-box;
        padding: 10px 10px;
        display: flex;
        width: 20%;
        flex-direction: column;

        .contentbox {
            font-weight: bold;
        }
    }
}

.box1_1 {
    text-align: left;
    box-sizing: border-box;
    padding: 10px 10px 10px 0px;
    display: flex;
    width: 50%;
    flex-direction: column;
}

.box1_2 {
    text-align: left;
    box-sizing: border-box;
    padding: 10px 10px;
    display: flex;
    width: 50%;
    flex-direction: column;
}

.circular {
    border-radius: 5px;
}

.closeDialog {
    width: 80px;
    height: 30px;
    line-height: 30px;
    margin: 0 auto;
    margin-top: 20px;
    text-align: center;
    color: #ffffff;
    background-color: #3981e2;
}

.el-table {
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
        width: 10px; /*滚动条宽度*/
        height: 10px; /*滚动条高度*/
    }

    /*定义滚动条轨道 内阴影+圆角*/

    /deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
        //   box-shadow: 0px 1px 3px #fff inset; /*滚动条的背景区域的内阴影*/
        border-radius: 10px; /*滚动条的背景区域的圆角*/
        background-color: #fff; /*滚动条的背景颜色*/
    }

    /*定义滑块 内阴影+圆角*/

    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
        //   box-shadow: 0px 1px 3px #dddee0 inset; /*滚动条的内阴影*/
        border-radius: 10px; /*滚动条的圆角*/
        background-color: #dddee0; /*滚动条的背景颜色*/
    }
}

.out-container {
    width: 100%;
    /* height: var(--tableHeight);*/
    height: 660px;
    overflow: auto;
}

.inner-container {
    // min-width: 2600px;
    height: var(--tableHeight);
    overflow: hidden;
    scroll-behavior: smooth;
}
//重构新增
.side-search {
    width: 200px;
    background: #FFFFFF;
    border-right: #d3dce6 1px solid;
}

.small-side {
    width: 36px;
    height: 36px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.14);
    border-right: 1px solid #EEE;
}

.mian-content {
    flex: 1;
    background: #F5F7FA;
}

.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.head-section {
    padding: 16px;
    background: #FFFFFF;
    overflow: hidden;
}

.btn-end {
    display: flex;
    justify-content: flex-end;
}

.section-main {
    width: 100%;
    //height: calc(100vh - 24px);
    background: #FFFFFF;
    margin-top: 16px;
    padding: 24px 16px 16px 16px;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.choose-mian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
        display: inline-block;
        margin-left: 16px;
    }
}

.alert-box {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
}

.pagin {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.main-flex {
    display: flex;

}

.left-style {
    padding-left: 40px;
}

.edit-style {
    color: #0D6CE4;
    font-size: 14px;
    cursor: pointer;
}
.more-search {
    display: flex;
    color: #0D6CE4;
    font-size: 14px;
    font-weight: 400;
    line-height: 36px;
    cursor: pointer;
    position: relative;
    left: -15px;
    i{
        line-height: 36px;
    }
}

.head-section{
    /deep/.el-input__inner { //更改input大小
        width: 250px !important;
        height: 36px;
        line-height: 36px;
    }
}
.head-section2 {
    overflow: hidden;
    padding: 0 16px 16px 16px;
    height: 100%;
}
</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';
</style>
