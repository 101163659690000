<template>
    <div class="app-container">
        <el-row class="main-flex">
            <el-col :span="3" class="side-search" v-if="isopenShow">
                <operatorTreeVue @emitToParent='getTreeData' @isShow="isHidden"></operatorTreeVue>
            </el-col>

            <el-col v-if="!isopenShow" class="small-side">
                <div class="change-in" @click="isopenShow = !  isopenShow "></div>
            </el-col>
            <!-- 搜索区域 -->
            <el-col :span="21" class="mian-content">
                <!--                    <el-input placeholder="请从左侧选择运营商" v-model.trim="treeData.operatorNameTree"
                              disabled></el-input>-->
                <div class="head-section">
                    <el-col :span="6">
                        <el-form :inline="true">
                            <el-form-item label="版本名称：">
                                <el-input placeholder="请输入版本名称" v-model.trim="query.versionName"
                                          clearable></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="6">
                        <el-form :inline="true">
                            <el-form-item label="版本号：">
                                <el-input placeholder="请输入版本号" v-model.trim="query.versionNo"
                                          clearable></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="12">
                        <div class="btn-end">
                            <span class="btn-rest">
                            <el-button @click="restFunction" plain v-hasCode="['base:version:query']">重置</el-button>
                                </span>
                            <span class="btn-submit">
                            <el-button @click="submitCheck" type="primary" plain v-hasCode="['base:version:query']">查询
                            </el-button>
                            </span>
                        </div>
                    </el-col>
                </div>
                <!--  表格部分-->
                <div class="section-main">
                    <el-col class="choose-mian">
                        <div class="section-title">
                            版本列表
                        </div>
                        <div>
                            <span class="pain-btn">
                            <el-button plain @click="removeAll"
                                       v-hasCode="['base:version:delete']">删除
                            </el-button>
                            </span>
                            <span class="add-btn">
                               <el-button plain @click="addVersion"
                                          type="primary" v-hasCode="['base:version:add']"> 新增
                               </el-button>
                            </span>
                        </div>
                    </el-col>
                    <!--table-->
                    <el-col :span="24">
                        <el-row class="table-box">
                            <el-table ref="forceTable" id="statiNo" @selection-change="batchList" :data="tableData"
                                      :header-cell-style="headerStyleEvent" :cell-class-name="goodsInfo">
                                <el-table-column align="center" type="selection" width="46" fixed></el-table-column>
                                <el-table-column align="center" label="编号" :index="renIndex" type="index"
                                                 width="50"></el-table-column>
                                <el-table-column align="center" prop="operatorName" label="归属运营商"
                                                 :show-overflow-tooltip="true"
                                                 min-width="110"></el-table-column>

                                <el-table-column align="center" prop="versionNo" label="当前版本号"
                                                 min-width="110"></el-table-column>
                                <el-table-column align="center" prop="versionName" label="版本名称"
                                                 min-width="110"></el-table-column>
                                <!--
                                                            <el-table-column prop="uploadWay" label="升级方式" :formatter="uploadWayFormat" min-width="110">
                                                            </el-table-column>
                                                            -->
                                <el-table-column align="center" prop="fileName" label="文件名称"
                                                 min-width="110"></el-table-column>
                                <el-table-column align="center" prop="size" label="文件大小(KB)"
                                                 min-width="110"></el-table-column>
                                <el-table-column align="center" prop="type" label="文件类型"
                                                 min-width="110"></el-table-column>

                                <el-table-column align="center" prop="path" label="文件地址" min-width="120"
                                                 :show-overflow-tooltip="true"></el-table-column>

                                <el-table-column align="center" label="操作" width="250">
                                    <template slot-scope="scope">
                                        <span @click="compileForce(scope.row)" class="edit-style"
                                              v-hasCode="['base:version:edit']">
                                            编辑
                                        </span>

                                        <!--
                                         <el-button @click="historyQuery(scope.row)" size=small type="success" plain
                                                    icon="el-icon-search">
                                             升级记录
                                         </el-button>
                                         -->

                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-row>
                    </el-col>

                    <!--pagination-->
                    <el-col :span="24">
                        <el-row class="pagin">
                            <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="query.pageNum"
                                :page-sizes="[10, 30, 50,100]"
                                :page-size="query.pageSize"
                                layout="total, sizes, prev, pager, next"
                                :total="total">
                            </el-pagination>
                        </el-row>
                    </el-col>
                </div>
            </el-col>
        </el-row>

        <el-dialog
            :title="isAdd?'新增版本升级':'编辑版本升级'"
            :visible.sync="isforce"
            width="50%"
            :before-close="cancelDialog">
            <el-form size="middle" ref="versionForm" :model="versionForm" label-width="150px" :rules="versionFormRules">
                <el-row>
                    <el-col :span="11">

                        <el-form-item label="归属运营商" prop="operatorId">
                            <TreeSelect v-model="versionForm.operatorId" :options="treeData.operatorOptions"
                                        placeholder="请选择运营商" :disabled="!isAdd"/>
                        </el-form-item>

                        <el-form-item label="版本号: " prop="versionNo">
                            <el-input v-model="versionForm.versionNo" :disabled="!isAdd"></el-input>
                        </el-form-item>

                        <el-form-item label="版本名称: " prop="versionName">
                            <el-input v-model="versionForm.versionName"></el-input>
                        </el-form-item>

                        <el-form-item label="升级对象" prop="target">
                            <el-select v-model="versionForm.target" placeholder="请选择升级对象" clearable>
                                <el-option
                                    v-for="dict in dict.type.version_type"
                                    :key="dict.value"
                                    :label="dict.label"
                                    :value="dict.value"
                                ></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="是否强制更新" prop="forceUpdata">
                            <el-radio-group v-model="versionForm.forceUpdate" @change="uploadSelectChange">
                                <el-radio
                                    v-for="item in forceUpdateList"
                                    :key="item.value"
                                    :label="item.value"
                                >{{ item.label }}
                                </el-radio>
                            </el-radio-group>
                        </el-form-item>

                    </el-col>

                    <el-col :span="11">

                        <el-form-item label="更新方式" prop="updateWay">
                            <el-radio-group v-model="versionForm.updateWay" @change="uploadSelectChange">
                                <el-radio
                                    v-for="item in updateWayList"
                                    :key="item.value"
                                    :label="item.value"
                                >{{ item.label }}
                                </el-radio>
                            </el-radio-group>
                        </el-form-item>


                        <el-form-item label="发布时间:" prop="releData">
                            <el-date-picker
                                v-model="versionForm.releData"
                                type="date"
                                style="width:100%;"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期时间">
                            </el-date-picker>
                        </el-form-item>

                        <!--
                        //2种升级方式选择
                        <el-form-item label="升级方式" prop="uploadWay" v-if="isAdd !==true">
                            <el-radio-group v-model="versionForm.uploadWay" @change="uploadSelectChange">
                                <el-radio
                                    v-for="item in uploadWayList"
                                    :key="item.value"
                                    :label="item.value"
                                >{{ item.label }}
                                </el-radio>

                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="选择要升级的版本:" prop="stationSftVer" v-if="isAdd !==true && versionForm.uploadWay == 0">
                            <el-select v-model="versionForm.versionNoHistory" placeholder="选择要升级的版本">
                                <el-option
                                    clearable
                                    v-for="item in versionHistoryList"
                                    :key="item.id"
                                    :label="`${item.versionName} - ${item.versionNo}`"
                                    :value="item.versionNo"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                         <el-form-item label="" prop="versionId" v-if="versionForm.uploadWay == 1">
                            <el-button size="small" plain @click="upload" type="primary" icon="el-icon-uploade">上传文件
                            </el-button>
                        </el-form-item>
                        -->

                        <el-form-item label="文件地址:" prop="path">
                            <el-input type="textarea" v-model="versionForm.path" :disabled="true"></el-input>
                            <el-button size="small" plain @click="upload" type="primary" icon="el-icon-uploade"
                                       :disabled="!isAdd" v-hasCode="['base:version:upload']">上传文件
                            </el-button>
                        </el-form-item>


                        <el-form-item label="更新内容:">
                            <el-input type="textarea" v-model="versionForm.content"></el-input>
                        </el-form-item>

                    </el-col>
                </el-row>
            </el-form>


            <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDialog">取 消</el-button>
        <el-button type="primary" @click="newAddOrset">确 定</el-button>
      </span>
        </el-dialog>


    </div>
</template>

<script>
import '@/assets/font/font.css';
import operatorTreeVue from "@/components/tree/operatorTree"
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {createAPI} from "@/utils/request";
import {
    addVersionManage, batchDelVersionManage, updateVersionManage,
    versionHistoryPage, versionManageList
} from "@/utils/versionManege";
import {operatorTree} from "@/utils/operatorApi";
import TreeSelect from "@riophae/vue-treeselect";
import Bus from '../../../assets/js/bus';
import * as myStore from "@/utils/stroage";

export default {
    name: 'verisonManage',
    dicts: ['version_type'],
    components: {operatorTreeVue, TreeSelect},
    data() {
        return {
            isopenShow: true,
            token: null,
            stationValue: '',
            stationNo: "",
            isforce: false,
            batchNum: [],
            tableData: [],
            total: 0,
            isPage: true,
            isdisabled: false,

            treeData: {
                operatorNameTree: "",
                operatorIdTree: null,
                operatorOptions: undefined,  //运营商树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
            },
            versionForm: {
                operatorId: null,
                versionNo: "",
                versionName: null,
                uploadWay: 1, //上传新版本
                updateWay: null, //更新方式：0增量更新；1：全量更新
                path: null,  //文件地址
                size: null,
                unionCode: null,
                forceUpdate: null, //是否强制更新：0否；1是
                releData: null, //发布日期
                content: "",
                filename: null,
                target: "",
                versionNoHistory: null //历史版本号
            },

            versionFormRules: {
                operatorId: [
                    {required: true, message: '请选择运营商', trigger: 'blur'}
                ],
                versionNo: [
                    {required: true, message: '请输入版本号', trigger: 'blur'}
                ],
                versionName: [
                    {required: true, message: '请输入版本名称', trigger: 'blur'}
                ],
                content: [
                    {required: true, message: '请输入更新内容', trigger: 'blur'}
                ],
                target: [
                    {required: true, message: '请选择升级对象', trigger: 'blur'}
                ],
                path: [
                    {required: true, message: '请选择上传文件', trigger: 'blur'}
                ]
            },

            isAdd: false,
            query: {
                operatorId: null,
                pageNum: 1,
                pageSize: 10,
                versionNo:"",
                versionName:""
            },

            hisQuery: {
                operatorId: null,
                pageNum: 1,
                pageSize: 10,
                versionNo:"",
                versionName:""
            },
            historyForm: {},
            isHistory: false,
            historyTableData: [],
            historyPageTotal: null,
            uploadData: null,

            uploadWayList: [
                {value: 0, label: "历史版本"},
                {value: 1, label: "上传新版本"},
            ],
            forceUpdateList: [
                {value: 0, label: "否"},
                {value: 1, label: "是"},
            ],
            updateWayList: [
                {value: 0, label: "增量更新"},
                {value: 1, label: "全量更新"},
            ],
            uploadDisabled: true,
            fileList: [],//上传的文件列表
            versionNameValue: ''
        };
    },
    created() {
        this.getOperatorTree()
        this.getVersionManagePage();
        this.getToken();

    },
    computed: {},
    methods: {
        cancelDialog() {
            this.resetVersionForm();
            this.query = {
                stationNo: "",
                pageNum: 1,
                pageSize: 10,
            };
            this.$refs['versionForm'].resetFields();
            this.isforce = false
        },
        headerStyleEvent({row, column, rowIndex, columnIndex}) {
            if (columnIndex == 1) {
                return 'padding:0px;'
            }
        },
        goodsInfo(row) {
            if (row.columnIndex == 1) {
                return 'goodsInfo'
            }
        },
        isHidden(message) {
            this.isopenShow = message
        },
        getToken() {
            this.token = myStore.sessionGetItem('electric-token').data;
        },

        upload() {
            // 打开文件选择框
            Bus.$emit('openUploader', {
                token: this.token
            })
        },

        uploadSelectChange() {
            this.$forceUpdate()
        },

        uploadWayFormat(row) {
            if (row.uploadWay === 0) {
                return "历史版本";
            }
            if (row.uploadWay === 1) {
                return "上传新版本";
            }
            return "未知";
        },

        updateWayFormat(row) {
            console.log(row.updateWay)
            if (row.uploadWay === 0) {
                return "增量更新";
            }
            if (row.updateWay === 1) {
                return "全量更新";
            }
            return "未知";
        },


        //上传文件改变
        handleChange(file, fileList) {
            if (fileList.length > 0) {
                this.uploadDisabled = false
                this.fileList = fileList
            }
        },

        //上传文件移除
        handleRemove(file, fileList) {
            this.fileList = fileList
            if (fileList.length < 1) {
                this.uploadDisabled = true
            }
        },
        //上传文件之前
        beforeUpload(file) {
            let fileTemp = file.raw
        },

        //删除文件之前的钩子
        async beforeRemove(file) {
            return this.$MessageBox.confirm(`确定移除 ${file.name}？`)
        },

        restFunction() {
            this.treeData.operatorNameTree = "";
            this.treeData.operatorIdTree = null;
            this.stationValue = ''
            this.stationNo = ''
            this.isPage = true
            this.query = {
                operatorId: null,
                pageNum: 1,
                pageSize: 10,
                versionNo:"",
                versionName:""
            }
            this.getVersionManagePage();
        },

        batchList(list) {
            this.batchNum = list
        },
        handleSizeChange(val) {
            this.query.pageSize = val
            this.getVersionManagePage();
        },
        handleCurrentChange(val) {
            this.query.pageNum = val
            this.getVersionManagePage();
        },

        renIndex(index) {
            return (this.query.pageNum - 1) * this.query.pageSize + index + 1
        },

        async getVersionManagePage() {
            try {
                const data = {
                    page: this.query.pageNum,
                    size: this.query.pageSize
                }

                if (this.treeData.operatorNameTree != "" && this.treeData.operatorNameTree != null) {
                    data.operatorId = this.treeData.operatorIdTree;
                }
                data.versionNo = this.query.versionNo;
                data.versionName = this.query.versionName;

                const res = await versionManageList(data)
                if (res.data.code !== 200) return this.$msg.error('获取运营商版本信息失败!')
                let newList = JSON.parse(JSON.stringify(res.data.data.data.list));
                this.tableData = newList;
                this.total = res.data.data.data.total;
            } catch (e) {
            }
        },

        addVersion() {
            this.isAdd = true;
            this.isforce = true;
            this.resetVersionForm();
            this.uploadData = null;
        },

        async removeAll() {
            if (this.batchNum.length === 0) return this.$msg.info('请选择需要删除的项')
            const config = await this.$MessageBox.confirm(
                "此操作将永久删除选中项, 是否继续?",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }
            ).catch(e => e);
            if (config !== "confirm") return;
            try {
                const ids = this.batchNum.map(item => {
                    return item.id
                }).join(",")

                const res = await batchDelVersionManage(ids)
                if (res.data.code !== 200) return this.$msg.error('删除失败')
                this.tableData = this.tableData.filter((item, i) => {
                    return item.id !== ids[i]
                })
                this.$refs.forceTable.clearSelection()
                if (this.query.pageNum > 1 && this.tableData.length === 0) {
                    this.query.pageNum = this.query.pageNum - 1
                }
                this.$msg.success('删除成功')
                this.getVersionManagePage();

            } catch (e) {
                console.log(e);
            }

        },


        async submitCheck() {
            try {
                this.query.pageNum = 1;
                this.getVersionManagePage();
            } catch (e) {
                console.log(e);
            }
        },

        getTreeData(data) {
            this.treeData = data
        },

        /** 查询运营商下拉树结构 */
        async getOperatorTree() {
            try {
                this.treeData.operatorOptions = [];
                let res = await operatorTree();
                if (res.status !== 200) return this.$msg.error("获取运营商信息失败");
                this.treeData.operatorOptions.push(res.data.data)
                this.treeData.operatorIdTree = res.data.data.id
                this.treeData.operatorNameTree = res.data.data.label
            } catch (error) {
            }
        },

        async newAddOrset() {
            this.$refs.versionForm.validate(async valid => {
                if (!valid) return
                try {
                    const data = JSON.parse(JSON.stringify(this.versionForm));
                    if (this.isAdd) {
                        data.uploadWay = 1;  //升级方式：0历史版本；1上传新版本
                        if (this.versionForm.path == null || this.versionForm.path == "") {
                            return this.$msg.error('请选择上传文件,并点击开始上传，然后等待文件上传完成')
                        }
                        const result = await addVersionManage(data);
                        if (result.data.code != 200) {
                            return this.$msg.error('新增版本信息失败!' + result.data.msg)
                        }
                        this.$msg.success('新增版本信息成功!');
                        this.isforce = false;
                        this.getVersionManagePage();
                    } else {
                        const result = await updateVersionManage(data);
                        if (result.data.code != 200) {
                            return this.$msg.error('修改版本信息失败!' + result.data.msg)
                        }
                        this.$msg.success('修改版本信息成功!');
                        this.isforce = false;
                        this.getVersionManagePage();
                    }
                } catch (e) {
                }
            })
        },


        reset() {
            this.query = {
                operatorId: null,
                pageNum: 1,
                pageSize: 10,
            };
            this.resetVersionForm();
        },

        async compileForce(data) {
            this.isforce = true;
            this.versionForm = {
                operatorId: data.operatorId,
                versionNo: data.versionNo,
                versionName: data.versionName,
                uploadWay: data.updateWay, //上传新版本
                updateWay: data.updateWay, //更新方式：0增量更新；1：全量更新
                path: data.path,  //文件地址
                size: data.size,
                unionCode: data.unionCode,
                forceUpdate: data.forceUpdate, //是否强制更新：0否；1是
                releData: new Date(data.releData), //发布日期
                content: data.content,
                filename: data.filename,
                target: data.target,
                versionNoHistory: data.versionNoHistory //历史版本号
            }
            this.isAdd = false;
            this.versionForm.uploadWay = null;
            if (data.releData == 0 || data.releData == null) {
                this.versionForm.releData = null
            }

        },

        async historyQuery(row) {
            this.isHistory = true;
            this.historyForm = row;
            this.hisQuery.operatorId = row.operatorId;
            this.getVersionHistoryPage();
        },

        async getVersionHistoryPage() {
            const operatorId = this.hisQuery.operatorId;
            if (operatorId == null || operatorId == "") {
                return this.$msg.error('获取当前运营商信息失败!')
            }
            var url = "?page=" + this.hisQuery.pageNum + "&size=" + this.hisQuery.pageSize + "&operatorId=" + operatorId;
            const result = await versionHistoryPage(url);
            if (result.data.data.code == 200) {
                this.historyTableData = result.data.data.data.data.list;
                this.historyPageTotal = result.data.data.data.total;

            } else {
                return this.$msg.error('获取运营商历史版本失败!')
            }

        },

        diaHandleSizeChange(val) {
            this.hisQuery.pageSize = val;
            this.getVersionHistoryPage();
        },

        diaHandleCurrentChange(val) {
            this.hisQuery.pageNum = val
            this.getVersionHistoryPage()
        },

        resetVersionForm() {
            this.versionForm = {
                operatorId: null,
                versionNo: "",
                versionName: null,
                uploadWay: 1, //上传新版本
                updateWay: null, //更新方式：0增量更新；1：全量更新
                path: null,  //文件地址
                size: null,
                unionCode: null,
                forceUpdate: null, //是否强制更新：0否；1是
                releData: null, //发布日期
                content: "",
                filename: null,
                target: "",
                versionNoHistory: null //历史版本号
            };
        },

        historyClose() {
            this.isHistory = false;
            this.hisQuery = {
                operatorId: null,
                pageNum: 1,
                pageSize: 10,
            };
            this.historyForm = {};
            this.isHistory = false;
            this.historyTableData = [];
            this.historyPageTotal = null;
        },
        historyConfirm() {
            this.isHistory = false;
            this.hisQuery = {
                operatorId: null,
                pageNum: 1,
                pageSize: 10,
            };
            this.historyForm = {};
            this.isHistory = false;
            this.historyTableData = [];
            this.historyPageTotal = null;
        },

    },

    mounted() {
        // 文件选择后的回调
        Bus.$on('fileAdded', () => {
            console.log('文件已选择')
        });

        // 文件上传成功的回调
        Bus.$on('fileSuccess', (data) => {
            console.log("------文件上传成功!--接受子类参数--------", data);
            if (data != null && data.url != null && data.url != "") {
                this.uploadData = data;
                this.versionForm.path = data.url;
                this.versionForm.size = data.totalSize;

                console.log("====================data=======================",data)

                this.versionForm.unionCode = data.identifier;
                this.versionForm.fileName = data.filename;
            }
            console.log('文件上传成功')
        });
    }
}
;
</script>

<style lang='less' scoped>
.app-container {
    background: rgba(255, 255, 255, 0);
    padding-left: 0px;
}

.inner-container {
    width: 100%;
    height: var(--tableHeight);
    // height: 900px;
    overflow: hidden;
}

//重构新增
.side-search {
    width: 200px;
    background: #FFFFFF;
    border-right: #d3dce6 1px solid;
}

.small-side {
    width: 36px;
    height: 36px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.14);
    border-right: 1px solid #EEE;
}

.mian-content {
    flex: 1;
    background: #F5F7FA;
}

.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.head-section {
    padding: 16px;
    background: #FFFFFF;
    overflow: hidden;
}

.btn-end {
    display: flex;
    justify-content: flex-end;
}

.section-main {
    width: 100%;
    //height: calc(100vh - 24px);
    background: #FFFFFF;
    margin-top: 16px;
    padding: 24px 16px 16px 16px;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.choose-mian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
        display: inline-block;
        margin-left: 16px;
    }
}

.alert-box {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
}

.pagin {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.main-flex {
    display: flex;

}

.left-style {
    padding-left: 43px;
}

.edit-style {
    color: #0D6CE4;
    font-size: 14px;
    cursor: pointer;
}
</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';
</style>
