<template>
    <div class="app-container">
        <el-row class="main-flex">
            <el-col :span="3" class="side-search" v-if="isopenShow">
                <operatorTeamTreeVue @emitToParent='getTreeData' @isShow="isHidden"></operatorTeamTreeVue>
            </el-col>

            <el-col v-if="!isopenShow" class="small-side">
                <div class="change-in" @click="isopenShow = !  isopenShow "></div>
            </el-col>

            <!--            <el-input placeholder="左侧选择运营商/车队" v-model.trim="treeData.operatorTeamNameTree"-->
            <!--                      disabled></el-input>-->
            <!-- 搜索区域 -->
            <el-col :span="21" class="mian-content">
                <div class="head-section">
                    <el-col :span="6">
                        <el-form :inline="true">
                            <el-form-item label="车牌号：" label-width="82px">
                                <el-input placeholder="请输入车牌号" v-model.trim="carValue" clearable></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="7">
                        <el-form :inline="true" class="left-style">
                            <el-form-item label="车辆VIN码：" label-width="95px">
                                <el-input placeholder="请输入车辆VIN码" v-model.trim="carVinValue" clearable></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="7">
                        <el-form :inline="true" class="left-style">
                            <el-form-item label="MAC地址：" label-width="90px">
                                <el-input placeholder="请输入MAC地址" v-model.trim="macValue" clearable></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="4">
                        <div class="btn-end">
                            <span class="more-search" @click="showMore=!showMore"><div>展开</div>
                            <i :class="showMore==true?'el-icon-arrow-down':'el-icon-arrow-up'"></i> </span>
                            <span class="btn-rest">
                             <el-button @click="restFunction" plain>重置</el-button>
                            </span>
                            <span class="btn-submit">
                           <el-button type="primary" @click="queryFunction" plain>查询</el-button>
                            </span>
                        </div>
                    </el-col>
                </div>
                <transition name="el-zoom-in-top">
                    <div v-show="showMore">
                        <div class="head-section head-section2">
                            <el-col :span="6">
                                <el-form :inline="true">
                                    <el-form-item label="车辆型号：" label-width="82px">
                                        <el-select v-model="carTypeValue" placeholder="请选择车辆型号" clearable>
                                            <el-option
                                                v-for="item in carModelList"
                                                :key="item.id"
                                                :label="item.carTypeName"
                                                :value="item.id"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-form>
                            </el-col>
                            <el-col :span="7">
                                <el-form :inline="true">
                                    <el-form-item label="所有人：" class="left-style" label-width="95px">
                                        <el-input placeholder="请输入所有人" v-model.trim="posserValue"
                                                  clearable></el-input>
                                    </el-form-item>
                                </el-form>
                            </el-col>
                            <el-col :span="7">
                                <el-form :inline="true">
                                    <el-form-item label="审核进度：" class="left-style" label-width="90px">
                                        <el-select placeholder="请选择审核进度状态" v-model="stateValue" clearable>
                                            <el-option
                                                v-for="item in options"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-form>
                            </el-col>
                        </div>
                    </div>
                </transition>
                <!--  表格部分-->
                <div class="section-main">
                    <el-col class="choose-mian">
                        <div class="section-title">
                            车辆列表
                        </div>
                        <div>
                            <!--选择项栏-->
                            <span class="alert-box">
                           已选择{{ selectList.length }}项
                            <span @click="clean" style="margin-left:8px;color:#0D6CE4;cursor:pointer;">清空</span>
                            </span>
                            <span class="pain-btn">
                                <el-button @click="download" plain
                                           v-hasCode="['base:car:export']">导出
                                </el-button>

                            </span>
                            <span class="pain-btn">
                            <el-button @click="dialogImport = true" plain
                                       v-hasCode="['base:car:import']">导入
                            </el-button>
                            </span>
                            <span class="pain-btn">
                                <el-button @click="removeAll"
                                           plain
                                           v-hasCode="['base:car:delete']">删除
                                </el-button>
                            </span>
                            <span class="add-btn">
                                <el-button type="primary" plain
                                           @click="addvehicle"
                                           v-hasCode="['base:car:add']">
                                            新增
                                </el-button>
                            </span>
                        </div>
                    </el-col>
                    <el-col :span="24">
                        <el-row class="table-box">
                            <el-table :data="tableData" ref="multipleTable" @selection-change="selectionChange"
                                       :header-cell-style="headerStyleEvent" :cell-class-name="goodsInfo" height="100%">
                                <el-table-column align="center" type="selection" fixed></el-table-column>
                                <el-table-column align="center" type="index" :index="renIndex" label="编号"
                                                 min-width="50"></el-table-column>

                                <el-table-column align="center" prop="operatorName" label="归属运营商" :show-overflow-tooltip="true"
                                                 min-width="100"></el-table-column>
                                <el-table-column align="center" prop="teamName" label="归属车队" :show-overflow-tooltip="true"
                                                 min-width="100"></el-table-column>
                                <el-table-column align="center" prop="carNo" label="车牌号" min-width="110"></el-table-column>
                                <el-table-column align="center" prop="carVin" label="车辆VIN码" min-width="190"></el-table-column>
                                <el-table-column align="center" prop="mac" label="mac地址" min-width="190"></el-table-column>
                                <el-table-column align="center" prop="brand" label="品牌" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column align="center" prop="carProperty" label="车辆性质">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.carProperty === 1">私人车辆</span>
                                        <span v-else-if="scope.row.carProperty === 2">运营车辆</span>
                                        <span v-else-if="scope.row.carProperty === 3">平台自有车辆</span>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" prop="carType" label="车辆型号" min-width="100"
                                                 :formatter="carTypeFormat"></el-table-column>
                                <!--
                                <el-table-column prop="rfid" label="rfid" min-width="100" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column prop="carType" label="车辆型号" min-width="100"
                                                 :formatter="carTypeFormat"></el-table-column>
                                <el-table-column prop="chassisNumber" label="底盘号码" min-width="100" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column prop="engineNumber" label="电动机号码" min-width="100" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column prop="purchaseDate" label="购车日期" min-width="110"
                                                 :formatter="$dateFormat"></el-table-column>
                                <el-table-column prop="producer" label="车辆厂家" min-width="110"></el-table-column>
                                <el-table-column prop="possessor" label="所有人"></el-table-column>
                                <el-table-column prop="carProperty" label="车辆性质">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.carProperty === 1">私人车辆</span>
                                        <span v-else-if="scope.row.carProperty === 2">运营车辆</span>
                                        <span v-else-if="scope.row.carProperty === 3">平台自有车辆</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="carApc" label="车辆外观" min-width="130">
                                    <template slot-scope="scope">
                                        <el-avatar shape="square" :size="100" fit="fill"
                                                   :src="scope.row.carApc"></el-avatar>
                                    </template>
                                </el-table-column>
                                  -->
                                <el-table-column align="center" prop="auditState" label="审核进度" min-width="100">
                                    <template slot-scope="scope">
                                    <span v-if="scope.row.auditState === 0">
                                        <i class="vehicle-item" style="background-color:#FA9600;"></i> 未注册
                                    </span>
                                        <span v-else-if="scope.row.auditState === 1">
                                        <i class="vehicle-item" style="background-color:#FFCD29;"></i>审核中
                                    </span>
                                        <span v-else-if="scope.row.auditState === 2">
                                        <i class="vehicle-item" style="background-color:#0D6CE4;"></i>审核通过
                                    </span>
                                        <span v-else-if="scope.row.auditState === 3">
                                        <i class="vehicle-item" style="background-color:#FF6165;"></i>审核不通过
                                    </span>
                                    </template>
                                </el-table-column>

                                <el-table-column align="center" prop="createTime" label="创建时间" min-width="100"
                                                 :formatter="$dateFormat">
                                </el-table-column>

                                <el-table-column align="center" label="操作" fixed="right" width="100">
                                    <template slot-scope="scope">
                                        <span class="btn-color"
                                                   @click="handleEdit(scope.row)" type="primary"
                                                   v-hasCode="['base:car:update']">
                                            编辑
                                        </span>
                                        <!--
                                                                            <el-button
                                                                                size="small"
                                                                                round
                                                                                type="success"
                                                                                plain
                                                                                icon="el-icon-search"
                                                                                @click="$router.push({path:'/vehicle-record',query:{data:JSON.stringify(scope.row)}})"
                                                                            >查看
                                                                            </el-button>
                                                                            -->
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-row>
                    </el-col>

                    <el-col :span="24">
                        <el-row class="pagin">
                            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                                           :current-page="query.pageNum"
                                           :page-sizes="[10,30, 50, 100]"
                                           :page-size="query.pageSize"
                                           layout="total, sizes, prev, pager, next"
                                           :total="total"
                            ></el-pagination>
                        </el-row>
                    </el-col>
                </div>

            </el-col>
            <!--                    <el-select placeholder="车辆性质" v-model="carPropertyValue" clearable>-->
            <!--                        <el-option-->
            <!--                            v-for="item in vehicleNature"-->
            <!--                            :key="item.value"-->
            <!--                            :label="item.label"-->
            <!--                            :value="item.value"-->
            <!--                        ></el-option>-->
            <!--                    </el-select>-->


            <el-dialog :title="isVehicle?'新增车辆信息':'修改车辆信息'" :visible.sync="addDialog" @close="close"
                       width="64%">
                <el-form size="middle" ref="addForm" :model="addForm" :rules="rules" class="popup_marker" label-position="top">
                    <el-row :span="24" :gutter="16">
                        <el-col :span="8">
                            <el-form-item label="归属运营商"  prop="operatorId" >
                                <TreeSelect v-model="addForm.operatorId" :options="operatorTreeData.operatorOptions"
                                                 placeholder="请选择运营商"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" >
                            <el-form-item label="归属车队:" prop="teamId">
                                <el-select v-model="addForm.teamId">
                                    <el-option v-for="item in teamList" :key="item.id" :label="item.teamName"
                                               :value="item.id"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" >
                            <el-form-item label="车牌号:" prop="carNo" >
                                <el-input @change="changeCarNo(addForm.carNo)" v-model="addForm.carNo"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :span="24" :gutter="16">
                        <el-col :span="8">
                            <el-form-item label="车辆VIN码:" prop="carVin">
                                <el-input v-model="addForm.carVin" maxlength="17" show-word-limit></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="RFID:" prop="rfid">
                                <el-input v-model="addForm.rfid" maxlength="17" show-word-limit></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="mac:" prop="mac">
                                <el-input v-model="addForm.mac" maxlength="17" show-word-limit></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :span="24" :gutter="16">
                        <el-col :span="8">
                            <el-form-item label="车辆性质:" prop="carProperty">
                                <el-select v-model="addForm.carProperty">
                                    <el-option
                                        v-for="item in vehicleNature"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="车辆型号:" prop="carType">
                                <el-select v-model="addForm.carType">
                                    <el-option
                                        v-for="item in carModelList"
                                        :key="item.id"
                                        :label="item.carTypeName"
                                        :value="item.id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="车辆厂家:" prop="producer">
                                <el-input v-model="addForm.producer"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :span="24" :gutter="16">
                        <el-col :span="8">
                            <el-form-item label="购车日期:"   prop="purchaseDate" >
                                <template>
                                    <div class="block">
                                        <el-date-picker
                                            type="date"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            v-model="addForm.purchaseDate"
                                            placeholder="选择日期时间"
                                        ></el-date-picker>
                                    </div>
                                </template>
                            </el-form-item></el-col>
                        <el-col :span="8">
                            <el-form-item label="品牌:" prop="brand">
                                <el-input v-model="addForm.brand"></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col :span="8">
                            <el-form-item label="底盘号码:" prop="chassisNumber">
                                <el-input v-model="addForm.chassisNumber"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :span="24" :gutter="16">
                        <el-col :span="8">
                            <el-form-item label="电动机号码:" prop="engineNumber">
                                <el-input v-model="addForm.engineNumber"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="所有人:" prop="possessor">
                                <el-input v-model="addForm.possessor"></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col :span="8">
                            <el-form-item label="审核进度:" prop="auditState">
                                <el-select :disabled="auditStatus" v-model="addForm.auditState">
                                    <el-option
                                        v-for="item in reviewProgress"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :span="24" :gutter="16">
                        <el-col :span="8">
                            <el-form-item label="允许离线换电" prop="canOffline">
                                <el-radio-group v-model="addForm.canOffline">
                                    <el-radio
                                        v-for="item in offLineList"
                                        :key="item.value"
                                        :label="item.value"
                                    >{{ item.label }}
                                    </el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="生效时间:" prop="startTime" v-if="addForm.canOffline === 1" >
                                <template>
                                    <div class="block">
                                        <!--
                                        <el-date-picker
                                            type="date"
                                            value-format="HH:mm:ss"
                                            v-model="addForm.endTime"
                                            placeholder="选择日期时间"
                                        ></el-date-picker>
                                        -->
                                        <el-time-picker v-model="addForm.startTime"
                                                        format="HH:mm:ss"
                                                        value-format="HH:mm:ss"
                                                        placeholder="选择生效时间"
                                        />
                                    </div>
                                </template>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                        <el-form-item label="失效时间:" prop="endTime" v-if="addForm.canOffline === 1">
                            <template>
                                <div class="block">
                                    <el-time-picker v-model="addForm.endTime"
                                                    format="HH:mm:ss"
                                                    value-format="HH:mm:ss"
                                                    placeholder="选择失效时间"/>
                                </div>
                            </template>
                        </el-form-item>
                        </el-col>
                        <el-col :span="8" >
                            <el-form-item label="车辆外观:" class="el-form-item_bottom">
                                <template>
                                    <Avater @on-avater="onAvater" @on-imgchange="imgchange" :uploadImg="uploadImg"
                                            :isAvater="isAvater"/>
                                </template>
                            </el-form-item></el-col>

                    </el-row>
                </el-form>
                <span slot="footer" class="dialog-footer">
                <el-button @click="addDialog = false">取 消</el-button>
                <el-button type="primary" @click="addFunction">确 定</el-button>
                </span>
            </el-dialog>

            <!-- 批量导入 -->
            <el-dialog title="请选择导入文件" :visible.sync="dialogImport" width="30%" @close="closeLoad">
                <el-upload class="upload-demo" ref="upload" action :on-change="handleChange" :on-remove="handleRemove"
                           :before-remove="beforeRemove" :before-upload="beforeUpload" :file-list="fileList" :limit="1"
                           accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                           :auto-upload="false">
                    <el-button size="small" type="primary">请选择 xlsx / xls 文件</el-button>
                </el-upload>
                <span slot="footer" class="dowload dialog-footer">
                    <div>
                        <el-button @click="dowtemplate">下载模版</el-button>
                        <span>请不要擅自修改或删除模版</span>
                    </div>
                    <div>
                        <el-button @click="dialogImport = false">取消</el-button>
                        <el-button type="primary" :disabled="uploadDisabled" style="margin-top: 16px"
                                   @click="handleUpload">开始上传</el-button>
                    </div>
                </span>
            </el-dialog>


        </el-row>
    </div>
</template>

<script>
import '@/assets/font/font.css';
import {
    getCarBaseAll,
    addCarBaseInfo,
    upCarBaseInfo,
    getCarBaseInfoOfState, getTeamList, vehicleLeadFile,
    batchDeleteCar
} from "@/utils/vehiclApi";
import * as myStore from "@/utils/stroage";
import Avater from '@/components/avater'
import {getCarAll} from "@/utils/vehicleModelManagementApi";
import axios from "axios";
import TreeSelect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {operatorTeamTree, operatorTree} from "@/utils/operatorApi";
import operatorTeamTreeVue from "@/components/tree/operatorTeamTree";

export default {
    name: "vehicleMsg",
    components: {
        Avater, TreeSelect, operatorTeamTreeVue
    },
    data() {

        /*
        var licence = (rule, value, cb) => {
            const Reg = /^([京津晋冀蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼渝川贵云藏陕甘青宁新][ABCDEFGHJKLMNPQRSTUVWXY][1-9DF][1-9ABCDEFGHJKLMNPQRSTUVWXYZ]\d{3}[1-9DF]|[京津晋冀蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼渝川贵云藏陕甘青宁新][ABCDEFGHJKLMNPQRSTUVWXY][\dABCDEFGHJKLNMxPQRSTUVWXYZ]{6})$/;
            if (value !== "") {
                Reg.test(value) ? cb() : cb(new Error("车牌号格式错误"));
            } else {
                cb("请输入车牌号");
            }
        };
        */

        var licence = (rule, value, cb) => {
            //归属地2位+6位车牌号
            const Reg = /^([京津晋冀蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼渝川贵云藏陕甘青宁新][ABCDEFGHJKLMNPQRSTUVWXY][1-9DF][1-9ABCDEFGHJKLMNPQRSTUVWXYZ]\d{3}[1-9DF]|[京津晋冀蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼渝川贵云藏陕甘青宁新][ABCDEFGHJKLMNPQRSTUVWXY][\dABCDEFGHJKLNMxPQRSTUVWXYZ]{6})$/;
            if (value !== "" && value != null) {
                Reg.test(value) ? cb() : cb(new Error("车牌号格式错误"));
            } else {
                cb()
            }
        };


        //定义数据
        return {
            showMore: false,
            isopenShow: true,
            carValue: "",
            carVinValue: "",
            macValue: "",
            clientHeight: 0,

            rfid: "",
            posserValue: "",
            stateValue: "",
            carTypeValue: null,
            carPropertyValue: null,
            query: {
                pageNum: 1,
                pageSize: 10
            },

            teamList: [],//车队数组

            carModelList: [],//车辆类型数组1，用于页面格式映射
            carTypeList: [], //车辆类型数组2，新增修改时运营商发生变化动态改变车辆类型数组

            vehicleNature: [
                {value: 1, label: "私人车辆"},
                {value: 2, label: "运营车辆"},
                {value: 3, label: "平台自有车辆"},
            ],
            reviewProgress: [
                {value: 0, label: "未注册"},
                {value: 1, label: "审核中"},
                {value: 2, label: "审核通过"},
                {value: 3, label: "审核不通过"},
            ],

            offLineList: [
                {value: 0, label: "否"},
                {value: 1, label: "是"},
            ],

            options: [
                // 0：未注册；1：审核中；2：审核通过；3：审核不通过
                {
                    value: "0",
                    label: "未注册",
                },
                {
                    value: "1",
                    label: "审核中",
                },
                {
                    value: "2",
                    label: "审核通过",
                },
                {
                    value: "3",
                    label: "审核不通过",
                },
            ],
            imgAvatar: '',
            addDialog: false,
            isVehicle: true,
            isEdit: false, //是否编辑标志

            selectList: [],
            tableData: [],
            msgListAll: [],
            stationNoList: [],
            total: 0, //总数
            auditStatus: false,
            addForm: {
                operatorId: null,
                teamId: null,
                carType: null,
                rfid: "",
                auditState: 0,
                brand: "",
                carApc: "",
                carNo: "",
                carProperty: null,
                carVin: "",
                mac: "",
                chassisNumber: "",
                engineNumber: "",
                possessor: "",
                producer: "",
                purchaseDate: null,
                canOffline: 0,
                startTime: null,//有效时间
                endTime: null //失效时间
            },
            rules: {
                operatorId: [
                    {required: true, message: "请选择归属运营商", trigger: "blur"},
                ],
                auditState: [
                    {required: true, message: "请选择审核进度", trigger: "change"},
                ],
                /*
                carType: [
                    {required: true, message: "请输入车辆型号", trigger: "blur"},
                ],
                carProperty: [
                    {required: true, message: "请选择车辆性质", trigger: "change"},
                ],
                */
                carVin: [
                    {required: true, message: "请输入车辆VIN码", trigger: "blur"},
                    {min: 17, message: '请正确输入17位vin', trigger: 'blur'},
                    {max: 17, message: '请正确输入17位vin', trigger: 'blur'}
                ],
                mac: [
                    {required: false, message: "请正确输入17位mac", trigger: "blur"},
                    {min: 17, message: '请正确输入17位mac', trigger: 'blur'},
                    {max: 17, message: '请正确输入17位mac', trigger: 'blur'}
                ],
                carNo: [{required: false, validator: licence, trigger: "blur"}],
                producer: [
                    {required: false, message: "请输入车辆厂家", trigger: "blur"},
                ],
                purchaseDate: [
                    {required: true, message: "请选择购车日期", trigger: "change"},
                ],
                teamId: [
                    {required: true, message: "请选择所属车队", trigger: "change"},
                ],
                canOffline: [
                    {required: true, message: "请选择是否允许离线换电", trigger: "change"},
                ]
            },

            fileAvater: null,
            isAvater: true,
            uploadImg: null,
            dialogImport: false,
            fileList: [],//上传的文件列表
            uploadDisabled: true,
            vehicleDownList: [],

            //左侧运营商--车队树图
            treeData: {
                operatorTeamOptions: [],  //运营商-车队树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
                operatorTeamNameTree: "",
                operatorTeamIdTree: null,
                defaultExpandAll: false,
            },

            //新增、修改时运营商树选择
            operatorTreeData: {
                operatorNameTree: "",
                operatorIdTree: null,
                operatorOptions: undefined,  //运营商树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
            },

        };
    },
    computed: {},
    created() {
        this.clientHeight = document.body.clientHeight - 299;
        //this.getOperatorTeamTree();
        this.getOperatorTree();
        this.findAllCarTypeInfo() //获取车辆类型
        //this.findAllTeamInfo()  //获取车队列表
        this.getPage();  //车辆列表查询
    },
    methods: {
        headerStyleEvent({row, column, rowIndex, columnIndex}) {
            if (columnIndex == 1) {
                return 'padding:0px;'
            }
        },
        goodsInfo(row) {
            if (row.columnIndex == 1) {
                return 'goodsInfo'
            }
        },
        isHidden(message) {
            this.isopenShow = message
        },
        //获取所有车辆型号
        async findAllCarTypeInfo() {
            var data = {};
            data.url = "";
            const res = await getCarAll(data)
            if(res.data.code == 200){
                this.carModelList = res.data.data
            }else{
                this.carModelList = []
            }
        },

        //获取所有车队信息
        async findAllTeamInfo() {
            let data = {};
            data.url = "";
            const res = await getTeamList(data)
            this.teamList = res.data.data
        },


        async getMsgListAll() {
            const res = await getCarBaseAll();
            this.msgListAll = res.data.data;
        },
        selectionChange(rows) {
            this.selectList = rows;
        },
        onAvater(file) {
            this.fileAvater = file
        },
        imgchange(val) {
            this.isAvater = val
        },

        addvehicle() {
            this.resetForm();
            this.isEdit = false;
            this.teamList = [];
            this.carTypeList = [];
            this.addDialog = true;
            this.auditStatus = true;
        },

        resetForm() {
            this.addForm = {
                operatorId: null,
                teamId: null,
                carType: null,
                rfid: "",
                auditState: 0,
                brand: "",
                carApc: "",
                carNo: "",
                carProperty: null,
                carVin: "",
                mac: "",
                chassisNumber: "",
                engineNumber: "",
                possessor: "",
                producer: "",
                purchaseDate: null,
                startTime: null,//有效时间
                endTime: null //失效时间
            }
        },

        handleSizeChange(val) {
            this.query.pageSize = val;
            this.queryFunction();
        },
        handleCurrentChange(val) {
            this.query.pageNum = val;
            this.getPage();
        },
        close() {
            this.$refs.addForm.resetFields();
            this.imgAvatar = ''
            this.addForm = {};
            this.isVehicle = true;
            this.isAvater = false
            this.uploadImg = null
            this.fileAvater = null
            this.addDialog = false
        },
        clean() {
            this.$refs.multipleTable.clearSelection();
        },
        //新增与修改
        addFunction() {
            this.isEdit == false;

            this.$refs.addForm.validate(async (valid) => {
                if (!valid) return this.$msg.info("车辆信息不能为空");
                try {
                    let data = JSON.parse(JSON.stringify(this.addForm));


                    if (data.canOffline === 1 && (data.startTime == null || data.endTime == null)) {
                        return this.$msg.info("离线时间不能为空");
                    }

                    if (data.startTime > data.endTime) {
                        return this.$msg.info("结束时间不能大于开始时间");
                    }

                    this.fileAvater && (data.carApc = this.fileAvater.url)
                    if (this.isVehicle) {
                        let res = await addCarBaseInfo(data);
                        if (res.data.code != 200) {
                            this.$msg.error("车辆添加失败!" + res.data.msg);
                        } else {
                            this.$msg.success("车辆基础信息添加成功");
                            this.addDialog = false;
                            this.getPage();
                        }
                    } else {
                        let res = await upCarBaseInfo(data);
                        if (res.data.code != 200) {
                            this.$msg.error("车辆基础信息修改失败" + res.data.msg);
                        } else {
                            this.$msg.success("车辆基础信息修改成功");
                            this.addDialog = false;
                            this.getPage();
                        }
                    }
                } catch (e) {
                }
            });
        },
        renIndex(index) {
            return (this.query.pageNum - 1) * this.query.pageSize + index + 1
        },


        async getPage() {
            try {
                var data = {};
                data.page = this.query.pageNum;
                data.size = this.query.pageSize;
                var url = "?page=" + this.query.pageNum + "&size=" + this.query.pageSize;
                data.url = url;
                var id = this.treeData.operatorTeamIdTree;

                if (id != "" && id != null) {
                    if (id.indexOf("operator") != -1) {
                        id = id.slice(9);
                        data.operatorId = Number(id);
                    } else {
                        id = id.slice(5);
                        data.teamId = Number(id);
                    }
                }

                if (this.carValue != "" && this.carValue != null) {
                    data.carNo = this.carValue;
                }
                if (this.carVinValue != "" && this.carVinValue != null) {
                    data.carVin = this.carVinValue;
                }
                if (this.macValue != "" && this.macValue != null) {
                    data.mac = this.macValue;
                }
                if (this.carTypeValue != "" && this.carTypeValue != null) {
                    data.carType = this.carTypeValue;
                }
                if (this.posserValue != "" && this.posserValue != null) {
                    data.possessor = this.posserValue;
                }
                if (this.stateValue != "" && this.stateValue != null) {
                    data.auditState = this.stateValue;
                }
                if (this.carPropertyValue != "" && this.carPropertyValue != null) {
                    data.carProperty = this.carPropertyValue;
                }
                let res = await getCarBaseInfoOfState(data);
                if (res.data.code !== 200) return this.$msg.error("获取车辆信息失败");
                if (res.data.data == null || res.data.data.total == 0) {
                    this.tableData = [];
                    this.total = 0;
                    return this.$msg.success("未查询到相关记录！");
                } else {
                    this.tableData = res.data.data.data.list;
                    this.total = res.data.data.total;
                }
            } catch (error) {
            }
        },

        async queryFunction() {
            this.query.pageNum = 1;
            this.getPage();
        },

        //修改信息填充
        async handleEdit(row) {
            this.isVehicle = false;
            this.auditStatus = false;
            let data = JSON.parse(JSON.stringify(row));
            this.uploadImg = data.carApc;
            this.addForm = data;

            let data2 = {};
            data2.url = "?operatorId=" + row.operatorId;
            const res = await getTeamList(data2)
            const res2 = await getCarAll(data2);

            if (res.data.code != 200 || res2.data.code != 200) {
                return this.$msg.info("获取车队或车辆类型信息失败");
            } else {
                this.teamList = res.data.data
                this.carTypeList = res2.data.data
                this.addDialog = true;
                this.$set(this.addForm,'teamId',row.teamId)
            }
            this.isEdit = true;

        },
        //重置
        restFunction() {
            this.resetForm();
            this.carValue = "";
            this.carVinValue = "";
            this.macValue = "";
            this.stateValue = "";
            this.posserValue = "";
            this.carTypeValue = null;
            this.rfid = null;
            this.carPropertyValue = null;
            this.treeData.operatorTeamNameTree = "";
            this.treeData.operatorTeamIdTree = null;
            this.query = {
                pageNum: 1,
                pageSize: 10
            }
            this.getPage();
        },
        changeCarNo(val) {
            this.addForm.carNo = val.toUpperCase();
        },
        async removeAll() {
            if (this.selectList.length === 0)
                return this.$msg.info("请选择需要删除的车辆");
            const confirm = await this.$MessageBox.confirm(
                "此操作将删除选中的车辆?",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            );
            if (confirm !== "confirm") return;
            const list = this.selectList;

            const res = await batchDeleteCar(list);
            if (res.status !== 200) return this.$msg.error("删除失败");
            this.tableData = this.tableData.filter((item, i) => {
                return item.carNo !== list[i];
            });
            this.$refs.multipleTable.clearSelection();
            if (this.query.pageNum > 1 && this.tableData.length === 0) {
                this.query.pageNum = this.query.pageNum - 1;
            }
            this.$msg.success("删除成功");
            this.getPage();
        },

        carTypeFormat(row) {
            var carTypeName = "未知";
            this.carModelList.filter(item => {
                if (item.id === row.carType) carTypeName = item.carTypeName
            })
            return carTypeName;
        },

        //下载模版
        dowtemplate() {

            const a = document.createElement("a");
            a.href = "/template/carInfo.xlsx";
            a.download = "车辆信息导入模板.xlsx";
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            a.remove();

        },

        //清空上传的文件
        closeLoad() {
            this.uploadDisabled = true
            this.$refs.upload.clearFiles();
        },
        //上传文件改变
        handleChange(file, fileList) {
            if (fileList.length > 0) {
                this.uploadDisabled = false
                this.fileList = fileList
            }
        },
        //上传文件之前
        beforeUpload(file) {
            let fileTemp = file.raw
            if (fileTemp) {
                if (fileTemp.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                    fileTemp.type === "application/vnd.ms-excel") {
                } else {
                    this.$msg.warning('附件格式错误，请删除后重新上传!')
                }
            }
        },
        //上传文件移除
        handleRemove(file, fileList) {
            this.fileList = fileList
            if (fileList.length < 1) {
                this.uploadDisabled = true
            }
        },
        //开始上传
        async handleUpload() {
            if (!this.fileList.length === 0) return this.$msg.info('请选择要上传的文件')
            let formData = new FormData()
            formData.append('file', this.fileList[0].raw)
            const res = await vehicleLeadFile(formData)
            if (res.data.code !== 200) return this.$msg.error('文件上传失败' + res.data.msg)
            //if (res.data && res.data.slice(0, 4) === '导入失败') return this.$msg.error('文件上传失败')
            this.$msg.success('文件上传成功')
            this.dialogImport = false
            this.getPage();
        },

        async beforeRemove(file) {
            return this.$MessageBox.confirm(`确定移除 ${file.name}？`)
        },

        //excel导出
        async download() {
            const confirm = await this.$MessageBox.confirm('是否导出文件', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(e => e)
            if (confirm !== 'confirm') return
            const columnList = [
                {
                    label: '归属运营商',
                    prop: 'operatorName'
                },
                {
                    label: '归属车队',
                    prop: 'teamName'
                },
                {
                    label: '车牌号',
                    prop: 'carNo'
                },
                {
                    label: '车辆VIN码',
                    prop: 'carVin'
                },
                {
                    label: 'rfid',
                    prop: 'rfid'
                },
                {
                    label: 'mac',
                    prop: 'mac'
                },
                {
                    label: '车辆性质',
                    prop: 'carProperty'
                },
                {
                    label: '车辆型号',
                    prop: 'carType'
                },
                {
                    label: '车辆厂家',
                    prop: 'producer'
                },
                {
                    label: '购车日期',
                    prop: 'purchaseDate'
                },
                {
                    label: '品牌',
                    prop: 'brand'
                },
                {
                    label: '底盘号码',
                    prop: 'chassisNumber'
                },
                {
                    label: '电动机号码',
                    prop: 'engineNumber'
                },
                {
                    label: '所有人',
                    prop: 'possessor'
                },
                {
                    label: '审核进度',
                    prop: 'auditState'
                }
            ]

            //获取车队信息列表，映射
            let data = {};
            data.url = "";
            const result = await getTeamList(data);
            const res = await getCarBaseAll();
            if (result.data.code == 200 && res.data.code == 200) {
                this.teamList = result.data.data;
                this.vehicleDownList = res.data.data;
                this.$download(this.downloadList(), columnList, '车辆基础信息');
            } else {
                return this.$msg.error("获取信息列表失败");
            }
        },

        downloadList() {
            if (this.selectList.length === 0) {
                return this.tableData.map(item => {
                    this.carModelList.some(item2 => {
                        if (item2.id === item.carType) {
                            item.carType = item2.carTypeName;
                            return true;
                        }
                    })


                    this.teamList.some(item3 => {
                        if (item3.id === item.teamId) {
                            item.teamName = item3.teamName;
                            return true;
                        }
                    })

                    switch (item.carProperty) {
                        case 1:
                            item.carProperty = '私人车辆'
                            break;
                        case 2:
                            item.carProperty = '运营车辆'
                            break
                        case 3:
                            item.carProperty = '平台自有车辆'
                            break
                        default:
                            item.carProperty = '未知'
                            break;
                    }


                    switch (item.auditState) {
                        case 0:
                            item.auditState = '未注册'
                            break;
                        case 1:
                            item.auditState = '审核中'
                            break
                        case 2:
                            item.auditState = '审核通过'
                            break
                        case 3:
                            item.auditState = '审核不通过'
                            break
                        default:
                            item.auditState = '未知'
                            break;
                    }
                    return item
                })
            } else {
                return this.selectList.map(item => {
                    this.tableData.some(item2 => {
                        if (item2.value === item.stationType) {
                            item.stationType = item2.label;
                            return true;
                        }
                    })

                    switch (item.status) {
                        case 1:
                            item.status = '营业状态'
                            break;
                        case 2:
                            item.status = '暂停营业'
                            break
                        case 3:
                            item.status = '设备维护'
                            break
                        case 4:
                            item.status = '歇业状态'
                            break
                        default:
                            item.status = '未知'
                            break;
                    }
                    switch (item.carProperty) {
                        case 1:
                            item.carProperty = '私人车辆'
                            break;
                        case 2:
                            item.carProperty = '运营车辆'
                            break
                        case 3:
                            item.carProperty = '平台自有车辆'
                            break
                        default:
                            item.carProperty = '未知'
                            break;
                    }


                    switch (item.auditState) {
                        case 0:
                            item.auditState = '未注册'
                            break;
                        case 1:
                            item.auditState = '审核中'
                            break
                        case 2:
                            item.auditState = '审核通过'
                            break
                        case 3:
                            item.auditState = '审核不通过'
                            break
                        default:
                            item.auditState = '未知'
                            break;
                    }
                    return item
                })
            }

        },

        getTreeData(data) {
            this.treeData = data
        },

        /** 查询运营商--车队下拉树结构 */
        async getOperatorTeamTree() {
            try {
                this.treeData.operatorTeamOptions = [];
                let res = await operatorTeamTree();
                //let res = await operatorTeamList()

                if (res.data.code !== 200) return this.$msg.error("获取运营商信息失败");
                this.treeData.operatorTeamOptions.push(res.data.data)
            } catch (error) {
            }
        },

        /** 查询运营商下拉树结构 */
        async getOperatorTree() {
            try {
                this.operatorTreeData.operatorOptions = [];
                let res = await operatorTree();
                if (res.data.code !== 200) return this.$msg.error("获取运营商信息失败");
                this.operatorTreeData.operatorOptions.push(res.data.data)
                this.operatorTreeData.operatorIdTree = res.data.data.id
                this.operatorTreeData.operatorNameTree = res.data.data.label
            } catch (error) {
            }
        },
    },

    watch: {
        'addForm.operatorId': {
            async handler(newName, oldName) {
                //修改
                if (this.isEdit == true && !newName ) {
                    return;
                }
                //新增、修改时改动过运营商
                if (newName !== null && newName != undefined && newName != oldName) {
                    this.addForm.teamId = null;
                    this.addForm.carType = null;
                    let data = {}
                    data.url = "?operatorId=" + newName;
                    const res = await getTeamList(data)
                    this.teamList = res.data.data
                    const res2 = await getCarAll(data);
                    this.carTypeList = res2.data.data;
                    return;
                }
            },
            deep: true,
            immediate: false
        },


    },

};
</script>

<style lang='less' scoped>
.app-container {
    background: rgba(255, 255, 255, 0);
    padding-left: 0px;
}

//.outer-container {
//    width: 100%;
//    /* height: var(--tableHeight);*/
//    height: 580px;
//    overflow: auto;
//}

.inner-container {
    // min-width: 3500px;
    height: var(--tableHeight);
    overflow: hidden;
}

.el-table {
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
        width: 10px; /*滚动条宽度*/
        height: 10px; /*滚动条高度*/
    }

    /*定义滚动条轨道 内阴影+圆角*/

    /deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
        box-shadow: 0px 1px 3px #fff inset; /*滚动条的背景区域的内阴影*/
        border-radius: 10px; /*滚动条的背景区域的圆角*/
        background-color: #fff; /*滚动条的背景颜色*/
    }

    /*定义滑块 内阴影+圆角*/

    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
        box-shadow: 0px 1px 3px #eee inset; /*滚动条的内阴影*/
        border-radius: 10px; /*滚动条的圆角*/
        background-color: #eee; /*滚动条的背景颜色*/
    }
}

.el-select {
    width: 100%;
}

.vehicle-item {
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-right: 5px;
}

.clean {
    color: #409eff;
    margin-left: 10px;
    cursor: pointer;
}

//重构新增
.side-search {
    width: 200px;
    background: #FFFFFF;
    border-right: #d3dce6 1px solid;
}

.small-side {
    width: 36px;
    height: 36px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.14);
    border-right: 1px solid #EEE;
}

.mian-content {
    flex: 1;
    background: #F5F7FA;
}

.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.head-section {
    padding: 16px;
    background: #FFFFFF;
    overflow: hidden;
}

.btn-end {
    display: flex;
    justify-content: flex-end;
}

.section-main {
    width: 100%;
    //height: calc(100vh - 24px);
    background: #FFFFFF;
    margin-top: 16px;
    padding: 24px 16px 16px 16px;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.choose-mian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
        display: inline-block;
        margin-left: 16px;
    }
}

.alert-box {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
}

.pagin {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.main-flex {
    display: flex;

}

.left-style {
    padding-left: 20px;
}

.edit-style {
    color: #0D6CE4;
    font-size: 14px;
    cursor: pointer;
}

.more-search {
    display: flex;
    color: #0D6CE4;
    font-size: 14px;
    font-weight: 400;
    line-height: 36px;
    cursor: pointer;
    position: relative;
    left: -15px;
    i{
        line-height: 36px;
    }
}
.btn-color {
    color: #0D6CE4;
    cursor: pointer;
}
.head-section{
    /deep/.el-input__inner { //更改input大小
        width: 250px !important;
        height: 36px;
        line-height: 36px;
    }
}
.head-section2 {
    overflow: hidden;
    padding: 0 16px 16px 16px;
    height: 100%;
}
/*popup_marker弹窗标记*/
.popup_marker{

    /*   弹窗左右间距*/
    /deep/.el-dialog__header{
        padding: 24px 24px 0 24px;
    }
    /deep/ .el-dialog__body{
        padding: 24px 24px;
    }
    /deep/.el-dialog__footer{
        padding: 0 24px 24px 24px;
    }
    /deep/.el-form-item{
        margin-bottom: 16px;
    }

    /*    弹窗文本框标题*/
    /deep/.el-form-item__label{
        line-height: 20px;
        margin-bottom: 8px;
        padding-bottom: 0;
    }
    /*    消除最后一排底部边框*/
    .el-form-item_bottom{
        margin-bottom: 0;
    }

/*    日期时间选择框宽*/
    .block{
        /deep/.el-input__inner{
            width: 372.48PX;
        }
        /deep/.el-date-editor--time{
            width: 372.48PX;
        }
        /deep/.el-date-editor--date{
            width: 372.48PX;
        }

    }
}
</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';

</style>
