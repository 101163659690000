<template>
  <el-card>
    <el-row>
<!--      <el-col :span="4">-->
<!--        <el-cascader-->
<!--          v-model="cascaderValue"-->
<!--          :options="options"-->
<!--           :props="{checkStrictly: true}"-->
<!--          @change="changeCascader"-->
<!--           placeholder="请选择省市区"-->
<!--         ></el-cascader>-->
<!--      </el-col>-->
<!--      <el-col :span="4">-->
<!--        <el-select @change="changeSelect" v-model="stationType" placeholder="请选择换电站类型">-->
<!--          <el-option-->
<!--            v-for="item in stationTypeList"-->
<!--            :key="item.value"-->
<!--            :label="item.label"-->
<!--            :value="item.value"-->
<!--          ></el-option>-->
<!--        </el-select>-->
<!--      </el-col>-->
      <el-col :span="4">
        <el-select v-model="stationValue" placeholder="请选择换电站">
          <el-option
            v-for="item in stationList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
       <el-col :span="4">
        <el-select v-model="versionsValue" placeholder="请选择版本类型">
          <el-option
            v-for="item in versionsList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="5">
        <el-button @click="submitCheck" type="primary">查询</el-button>
        <el-button @click="restFunction">重置</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-button type="primary" @click="dialogGrade = true" class="el-icon-plus" size="small"> 新增</el-button>
    </el-row>
    <el-row>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column type="index"  :index="renIndex" label="编号" ></el-table-column>
        <el-table-column prop="stationNo" label="换电站编码" min-width="160"></el-table-column>
        <el-table-column prop="stationName" label="换电站名称" min-width="100"></el-table-column>
        <el-table-column prop="sftVersion" label="换电站软件版本" min-width="120"></el-table-column>
        <el-table-column prop="versionType" label="版本类型" min-width="120">
          <template slot-scope="scope">
            {{scope.row.versionType === 1?'成熟版本':'测试版本'}}
          </template>
        </el-table-column>
        <el-table-column prop="sftStorePath" label="文件路径" min-width="170"></el-table-column>
        <el-table-column prop="nowUpdateNum" label="软件文件更新次序" min-width="140"></el-table-column>
        <el-table-column prop="totalUpdateNum" label="总更新次数" min-width="120"></el-table-column>
        <el-table-column prop="updateTime" label="更新时刻" min-width="160"></el-table-column>
        <el-table-column  label="操作" fixed="right">
            <template slot-scope="scope">
              <el-button @click="UpdateGrade(scope.row)" size=small type="primary" icon="el-icon-edit" >编辑</el-button>
            </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row>
       <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNum"
      :page-sizes="[20, 25, 30]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalPage">
    </el-pagination>
    </el-row>
    <!-- 新增和更新 -->
    <el-dialog
      :title="isGrade?'文件新增':'文件更新'"
      :visible.sync="dialogGrade"
      width="30%"
      @close="close">
      <el-row>
        <el-form size="small" :model="gradeFrom" :rules="graderules" ref="graderuleForm" label-width="100px" >
          <el-form-item label="区域信息:" >
            <el-cascader
                v-model="cascaderfromValue"
                :options="options"
                :props="{checkStrictly: true}"
                @change="changeCascaderFrom"
                placeholder="请选择省市区"
                ></el-cascader>
          </el-form-item>
          <el-form-item label="换电站类型:" >
             <el-select @change="changeCascaderFrom" v-model="stationTypeValue" placeholder="请选择换电站类型">
            <el-option
              v-for="item in stationTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          </el-form-item>
          <el-form-item label="换电站名称:" prop="stationNo">
             <el-select @change="changeStation" v-model="gradeFrom.stationNo" placeholder="请选择换电站名称">
            <el-option
              v-for="item in statinoList1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          </el-form-item>
          <el-form-item label="软件版本号:" prop="sftVersion">
            <el-input v-model="gradeFrom.sftVersion" ></el-input>
          </el-form-item>
          <el-form-item label="版本类型:" prop="versionType">
             <el-select  v-model="gradeFrom.versionType" placeholder="请选择版本类型">
            <el-option
              v-for="item in versionsList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          </el-form-item>
           <el-form-item label="文件选择:" prop="sftStorePath">
                <el-input v-model="gradeFrom.sftStorePath" ></el-input>
           </el-form-item>
        </el-form>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogGrade = false">取 消</el-button>
        <el-button type="primary" @click="updated">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import { regionData } from "element-china-area-data";
import { getForceList,regionCodeSation } from "@/utils/station";
import { UpdateInfoPage,UpdateAdd,Updatecompil } from '@/utils/updateInfo'
import * as myStore from "@/utils/stroage";
import moment from 'moment'
export default {
  name: "upgrade",
  data() {
    return {
      pageNum:1,
      pageSize:20,
      resPageNum:1,
      totalPage:0,
      cascaderValue: "",
      options: regionData,
      stationValue: "",
      stationList: [],
      statinoList1:[],
      stationType: "",
      stationTypeList: [
        {
          value: '0',
          label: "A类型",
        },
        {
          value: '1',
          label: "B类型",
        },
      ],
      versionsValue: "",
      versionsList: [
        {
          value: 1,
          label: "成熟版本",
        },
        {
          value: 2,
          label: "测试版本",
        },
      ],
      tableData:[],
      dialogGrade:false,
      isGrade:true,
      gradeFrom:{
         stationNo:'',
         stationName:'',
         sftVersion:'',
         versionType:'',
         sftStorePath:''
      },
      graderules:{
        stationNo:[
           { required: true, message: '请选择换电站名称', trigger: 'change' }
        ],
        sftVersion:[
           { required: true, message: '请输入软件版本号', trigger: 'blur' }
        ],
        versionType:[
            { required: true, message: '请选择版本类型', trigger: 'change' }
        ],
        sftStorePath:[
            { required: true, message: '请选择文件路径', trigger: 'blur' }
        ]
      },
      cascaderfromValue:[],
      stationTypeValue:'',
     isPage:true
    };
  },
  computed:{
     cascader(){
        //  if(this.cascaderValue.length === 1){
        //   return this.cascaderValue[0]
        // }else if(this.cascaderValue.length === 2){
        //  return this.cascaderValue[1]
        // }else if(this.cascaderValue.length === 3){
        //   return this.cascaderValue[2]
        // }else {
        //   return ''
        // }
       return this.cascaderValue.join(',')
     },
     cascaderFrom(){
        // if(this.cascaderfromValue.length === 1){
        //   return this.cascaderfromValue[0]
        // }else if(this.cascaderfromValue.length === 2){
        //  return this.cascaderfromValue[1]
        // }else if(this.cascaderfromValue.length === 3){
        //   return this.cascaderfromValue[2]
        // }else {
        //   return ''
        // }
       return this.cascaderValue.join(',')
     }
  },
  created() {
    this.getStationAll()
    this.getUpgrade()
    this.getStationAll()
  },
  methods: {
    //获取换电站编码
    async getStationAll() {
      const res = await getForceList();
      this.stationList = res.data.data.map((item) => {
        return {
          value: item.stationNo,
          label: item.stationName,
        };
      });
      this.statinoList1 = this.stationList
    },
   async getUpgrade(){
      try {
        const data = {
          page:this.pageNum,
          size:this.pageSize
        }
        const res =await UpdateInfoPage(data)
        if(res.status !== 200)return this.$msg.error('获取文件更新信息失败')
        let newList = res.data.data
        this.tableData = newList.content
        this.totalPage = newList.totalElements
      } catch (error) {

      }
    },
     renIndex(index){
      return (this.pageNum -1 ) * this.pageSize + index +1
    },
    //查询
   async submitCheck() {
      try {
         const data ={
            regionCode:this.cascader,
            stationType:this.stationType,
            stationNo:this.stationValue,
            versionType:this.versionsValue
         }
         this.storage('upgrade',data)
          data.page = this.resPageNum
          data.size = this.pageSize
          const res =await UpdateInfoPage(data)
          if(res.status !== 200)return this.$msg.error('查询文件更新信息失败')
            let newList = res.data.data
            this.tableData = newList.content
            this.totalPage = newList.totalElements
            this.isPage = false
      } catch (error) {

      }
    },
    //重置
    restFunction() {
       this.cascaderValue = []
       this.stationType = ''
       this.stationValue =''
       this.versionsValue = ''
       this.isPage = true
       this.pageNum =1
       this.pageSize = 20
       this.resPageNum = 1
       this.getUpgrade()
    },
    handleSizeChange(){
      this.pageSize = val
           if(this.isPage){
            this.getUpgrade()
           }else{
            this.submitCheck()
           }
    },
    handleCurrentChange(){
       this.pageNum = val
           if(this.isPage){
            this.getUpgrade()
           }else{
             this.resPageNum = val
            this.submitCheck()
           }
    },
    close(){
        this.$refs.graderuleForm.resetFields()
        this.cascaderfromValue = []
        this.stationTypeValue = ''
        this.gradeFrom = {}
        this.isGrade = true
    },
    updated(){
       this.$refs.graderuleForm.validate(async valid=>{
         if(!valid)return this.$msg.info('站控主机信息不能为空')
        try {
           let data = JSON.parse(JSON.stringify(this.gradeFrom))
           data.updateTime = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
          if(this.isGrade){//新增
           const res =await UpdateAdd(data)
           if(res.status !== 200)return this.$msg.error('新增站控文件失败')
           this.dialogGrade = false
           this.getUpgrade()
          }else{//修改
           const res =await Updatecompil(data)
           if(res.status !== 200)return this.$msg.error('修改站控文件失败')
           this.dialogGrade = false
           this.getUpgrade()
          }

        } catch (e) {
          console.log(e);
        }
       })

    },
    changeStation(val){
     const data = this.stationList.filter(e=>e.value === val)
     this.gradeFrom.stationName = data[0].label
    },
     async changeCascader(){
       this.stationValue = ''
      const data ={
        regionCode:this.cascader,
        stationType:this.stationType
        }
      const res =await regionCodeSation(data)

       if(res.status !== 200)return this.$msg.error('换电站查询失败!')

      if(res.data.data.length !== 0){
          this.stationList = res.data.data.map(item=>{
         return {
           value:item.stationNo,
           label:item.stationName
         }
       })
      }else{
        this.stationList = []
      }
      if(this.stationList.length !== 0 && this.cascader !== ''){
         const newList = JSON.parse(getItem('upgrade'))
        if(newList && newList[1].regionCode === this.cascader && newList[0].index > 3){
          this.cascaderValue = this.changeDetSelect(newList[1].regionCode,this.options)
          this.stationValue =  newList[1].stationNo
          this.stationType =  newList[1].stationType
          this.versionsValue =  newList[1].versionType
        }
      }
    },
   async changeSelect(){
         this.stationValue = ''
      const data ={
        regionCode:this.cascader,
        stationType:this.stationType
        }
      const res =await regionCodeSation(data)

       if(res.status !== 200)return this.$msg.error('换电站查询失败!')
      if(res.data.data.length !== 0){
          this.stationList = res.data.data.map(item=>{
            return {
              value:item.stationNo,
              label:item.stationName
            }
          })
      }else{
        this.stationList = []
      }
    },
    async changeCascaderFrom(){
       this.gradeFrom.stationNo = ''
        const data ={
        regionCode:this.cascaderFrom,
        stationType:this.stationTypeValue
        }
      const res =await regionCodeSation(data)
       if(res.status !== 200)return this.$msg.error('换电站查询失败!')
      if(res.data.data.length !== 0){
          this.statinoList1 = res.data.data.map(item=>{
         return {
           value:item.stationNo,
           label:item.stationName
         }
       })
      }else{
        this.statinoList1 = []
      }
    },
    UpdateGrade(data){
       this.dialogGrade = true
       this.isGrade = false
       let list = JSON.parse(JSON.stringify(data))
       this.gradeFrom = list
    }
  },
};
</script>

<style lang='less' scoped>
.el-row {
  margin: 15px 0;
  .el-col {
    margin-right: 20px;
  }
}
.el-cascader,
.el-select,.el-input {
  width: 100%;
}

</style>
