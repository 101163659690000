<template>
  <!-- 电池投入情况展示 -->
  <el-card>
    <el-row>
      <el-col :span="4">
        <el-cascader
          filterable
          @change="changeCascader"
          v-model="cascaderValue"
          :options="options"
          :props="{checkStrictly: true}"
          placeholder="请选择省市区"
        ></el-cascader>
      </el-col>
      <el-col :span="4">
        <el-select @change="changeSelect" v-model="stationType" placeholder="请选择换电站类型">
          <el-option
            v-for="item in stationTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select v-model="stationValue" filterable placeholder="请选择换电站">
          <el-option
            v-for="item in stationList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4">
        <el-select v-model="cellCityValue" filterable placeholder="请选择电池所在位置">
          <el-option
            v-for="item in cellCityList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select placeholder="请选择电池状态" v-model="stateValue">
          <el-option
            v-for="item in cellState"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <el-button type="primary" @click="queryFunction">查询</el-button>
        <el-button @click="restFunction">重置</el-button>
      </el-col>
    </el-row>
    <el-row>
      <!-- <el-button type="primary" class="el-icon-plus" size="small" @click="addDialog = true">新增</el-button> -->
      <el-button type="primary" class="el-icon-download" size="small" @click="download">批量导出</el-button>
    </el-row>
    <el-table
      id="cellPutTable"
      :data="tableData"
      style="width: 100%"
      @selection-change="selectionChange"
      ref="cellPutTable"
      height="300"
    >
      <el-table-column type="selection" width="40"></el-table-column>
      <el-table-column type="index" :index="renIndex" fixed="left" label="编号" width="50"></el-table-column>
      <el-table-column prop="btyNo" label="电池序列号" width="100"></el-table-column>
      <el-table-column prop="chargeCount" label="充电次数"></el-table-column>
      <el-table-column prop="swapCount" label="换电次数"></el-table-column>
      <el-table-column prop="extChargeCount" label="外接充电次数" width="110"></el-table-column>
      <el-table-column prop="extChargeEnergy" label="外接充电能量" width="110"></el-table-column>
      <el-table-column prop="btyLocation" label="电池所在位置" width="110">
        <template slot-scope="scope">
          <span v-if="scope.row.btyLocation === 1">在车上</span>
          <span v-else-if="scope.row.btyLocation === 2">在站内</span>
          <span v-else>其它</span>
        </template>
      </el-table-column>
      <el-table-column prop="stationNo" label="换电站编码" width="170"></el-table-column>
      <el-table-column prop="binNo" label="仓位号(序号)" width="110"></el-table-column>
      <el-table-column prop="carNo" label="车牌号" width="100"></el-table-column>
      <el-table-column prop="binNo" label="仓位号(序号)" width="110"></el-table-column>
      <el-table-column prop="batterySoc" label="电池SOC" width="100"></el-table-column>
      <el-table-column prop="batterySoh" label="电池SOH" width="100"></el-table-column>
      <el-table-column prop="batteryState" label="电池状态">
        <template slot-scope="scope">
          <span v-if="scope.row.batteryState === 1">优秀</span>
          <span v-else-if="scope.row.batteryState === 2">良好</span>
          <span v-else-if="scope.row.batteryState === 3">普通</span>
          <span v-else-if="scope.row.batteryState === 4">需维护</span>
          <span v-else-if="scope.row.batteryState === 5">需回收</span>
          <span v-else>其它</span>
        </template>
      </el-table-column>
      <el-table-column prop="installCount" label="安装次数"></el-table-column>
      <el-table-column prop="tearCount" label="拆卸次数"></el-table-column>
      <el-table-column prop="lastSwapTime" :formatter="$dateFormat" label="上次换电完成时刻" width="160"></el-table-column>
      <el-table-column prop="lastSwapTimeCount" label="上次换电时间" width="110"></el-table-column>
      <el-table-column prop="lastChargeTime" :formatter="$dateFormat" label="上次充电完成时刻" width="140"></el-table-column>
      <el-table-column prop="lastChargeTimeCount" label="上次充电时间" width="110"></el-table-column>
      <el-table-column prop="updateTime" :formatter="$dateFormat" label="更新时间" width="160"></el-table-column>
    </el-table>
    <el-row>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNum"
        :page-sizes="[20, 25, 30]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalPages">
      </el-pagination>
    </el-row>
    <el-dialog :title="titleNmae" :visible.sync="addDialog" width="50%">
      <el-form
        size="small"
        ref="addFrom"
        :model="addFrom"
        label-position="right"
        label-width="110px"
        :rules="rules"
      >
        <el-row>
          <el-col style="margin:0;" :span="12">
            <el-form-item label="电池序列号:" prop="btyNo">
              <el-input v-model="addFrom.btyNo"></el-input>
            </el-form-item>
            <el-form-item label="电池所在位置:" prop="btyLocation">
              <el-select v-model="addFrom.btyLocation" placeholder="请选择电池所在位置">
                <el-option
                  v-for="item in cellCityList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="充电次数:" prop="chargeCount">
              <el-input v-model="addFrom.chargeCount"></el-input>
            </el-form-item>
            <el-form-item label="换电次数:" prop="swapCount">
              <el-input v-model="addFrom.swapCount"></el-input>
            </el-form-item>
            <el-form-item label="仓位号(序号):" prop="binNo">
              <el-input v-model="addFrom.binNo"></el-input>
            </el-form-item>
            <el-form-item label="电池SOC:" prop="batterySoc">
              <el-input v-model="addFrom.batterySoc"></el-input>
            </el-form-item>
            <el-form-item label="电池SOH:" prop="batterySoh">
              <el-input v-model="addFrom.batterySoh"></el-input>
            </el-form-item>
          </el-col>
          <el-col style="margin:0;" :span="12">
            <el-form-item label="电池状态:" prop="batteryState">
              <el-select v-model="addFrom.batteryState">
                <el-option
                  v-for="item in cellState"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="安装次数:" prop="installCount">
              <el-input v-model="addFrom.installCount"></el-input>
            </el-form-item>
            <el-form-item label="拆卸次数:" prop="tearCount">
              <el-input v-model="addFrom.tearCount"></el-input>
            </el-form-item>
            <el-form-item label="外接充电次数:">
              <el-input v-model="addFrom.extChargeCount"></el-input>
            </el-form-item>
            <el-form-item label="外接充电能量:">
              <el-input v-model="addFrom.extChargeEnergy"></el-input>
            </el-form-item>
            <el-form-item label="换电站编码:">
              <el-select v-model="addFrom.stationNo" placeholder="请选择换电站编码">
                <el-option
                  v-for="item in stationList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="车牌号:">
              <el-input v-model="addFrom.carNo"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialog = false">取 消</el-button>
        <el-button type="primary" @click="addFunction">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
  import {
    getBatterAll,
    getBatteryRealResultOfPage,
    addBatteryRealResult,
    getBatteryRealResultOfstate,
  } from "@/utils/cellPutApi";
  import {getForceList, regionCodeSation} from "@/utils/station"
  import * as myStore from "@/utils/stroage";
  import {regionData} from "element-china-area-data"
  import moment from 'moment'

  export default {
    name: 'cellPut',
    data() {
      //定义数据
      return {
        cascaderValue: [],
        stationValue: "",
        stationType: '',
        cellCityValue: '',
        stateValue: null,
        stationList: [],
        options: regionData,
        stationTypeList: [
          //换电站类型
          {
            value: '0',
            label: "A类型",
          },
          {
            value: '1',
            label: "B类型",
          },
        ],
        query: {
          pageNum: 1,
          pageSize: 20,
          resPageNum: 1
        },
        addDialog: false,
        isPage: true,
        titleNmae: "新增电池实时情况",
        selectList: [],
        bartchNum: [],
        tableData: [],
        totalPages: 0,
        cellCityList: [
          {
            value: 1,
            label: '在车上'
          },
          {
            value: 2,
            label: '在站内'
          },
          {
            value: 3,
            label: '其它'
          }
        ],
        cellState: [
          {
            value: 1,
            label: "优秀",
          },
          {
            value: 2,
            label: "良好",
          },
          {
            value: 3,
            label: '普通'
          },
          {
            value: 4,
            label: '需维护'
          },
          {
            value: 5,
            label: '需回收'
          },
          {
            value: 6,
            label: '其它'
          }
        ],
        addFrom: {
          batterySoc: null,
          batterySoh: null,
          batteryState: null,
          binNo: null,
          btyLocation: null,
          btyNo: "",
          carNo: "",
          chargeCount: null,
          extChargeCount: null,
          extChargeEnergy: null,
          installCount: null,
          stationNo: "",
          swapCount: null,
          tearCount: null,
        },
        rules: {
          batterySoc: [{required: true, message: "请输入电池SOC", trigger: "blur"}],
          batterySoh: [{required: true, message: "请输入电池SOH", trigger: "blur"}],
          batteryState: [
            {required: true, message: "请选择电池状态", trigger: "change"},
          ],
          binNo: [{required: true, message: "请输入仓位号(序号)", trigger: "blur"}],
          btyLocation: [{required: true, message: "请输入电池所在位置", trigger: "blur"}],
          btyNo: [{required: true, message: "请输入电池序列号", trigger: "blur"}],
          carNo: [{required: true, message: "不能为空", trigger: "blur"}],
          chargeCount: [{required: true, message: "请输入充电次数", trigger: "blur"}],
          installCount: [
            {required: true, message: "请输入安装次数", trigger: "blur"},
          ],
          swapCount: [{required: true, message: "请输入换电次数", trigger: "blur"}],
          tearCount: [{required: true, message: "请输入拆卸次数", trigger: "blur"}],
        },
        coulm: [
          {prop: 'btyNo', label: '电池序列号'},
          {prop: 'btyLocation', label: '电池所在位置'},
          {prop: 'chargeCount', label: '充电次数'},
          {prop: 'swapCount', label: '换电次数'},
          {prop: 'binNo', label: '仓位号(序号)'},
          {prop: 'batterySoc', label: '电池SOC'},
          {prop: 'batterySoh', label: '电池SOH'},
          {prop: 'batteryState', label: '电池状态'},
          {prop: 'installCount', label: '安装次数'},
          {prop: 'tearCount', label: '拆卸次数'},
          {prop: 'lastSwapTime', label: '上次换电完成时刻'},
          {prop: 'lastSwapTimeCount', label: '上次换电时间'},
          {prop: 'lastChargeTime', label: '上次充电完成时刻'},
          {prop: 'lastChargeTimeCount', label: '上次充电时间'},
          {prop: 'updateTime', label: '更新时间'},
        ]
      };
    },
    computed: {
      cascader() {
        // if (this.cascaderValue.length === 1) {
        //   return this.cascaderValue[0]
        // } else if (this.cascaderValue.length === 2) {
        //   return this.cascaderValue[1]
        // } else if (this.cascaderValue.length === 3) {
        //   return this.cascaderValue[2]
        // } else {
        //   return ''
        // }
        return this.cascaderValue.join(',')
      },
      downloadList() {
        if (this.bartchNum.length === 0) {
          return this.selectList.map(item => {
            switch (item.batteryState) {
              case 1:
                item.batteryState = '优秀'
                break;
              case 2:
                item.batteryState = '良好'
                break;
              case 3:
                item.batteryState = '普通'
                break;
              case 4:
                item.batteryState = '需维护'
                break;
              case 5:
                item.batteryState = '需回收'
                break;
              case 6:
                item.batteryState = '其它'
                break;
              default:
                break;
            }
            switch (item.btyLocation) {
              case 1:
                item.btyLocation = '在车上'
                break;
              case 2:
                item.btyLocation = '在站内'
                break;
              case 3:
                item.btyLocation = '其它'
                break;
              default:
                break;
            }
            return item
          })
        } else {
          return this.bartchNum.map(item => {
            switch (item.batteryState) {
              case 1:
                item.batteryState = '优秀'
                break;
              case 2:
                item.batteryState = '良好'
                break;
              case 3:
                item.batteryState = '普通'
                break;
              case 4:
                item.batteryState = '需维护'
                break;
              case 5:
                item.batteryState = '需回收'
                break;
              case 6:
                item.batteryState = '其它'
                break;
              default:
                break;
            }
            switch (item.btyLocation) {
              case 1:
                item.btyLocation = '在车上'
                break;
              case 2:
                item.btyLocation = '在站内'
                break;
              case 3:
                item.btyLocation = '其它'
                break;
              default:
                break;
            }
            return item
          })
        }

      }
    },
    created() {
      //进入页面自动运行
      this.getAll();
      this.getStation()
      this.getBatterListAll()
    },
    methods: {
      //自定义方法
      async getAll() {
        try {
          let data = {page: this.query.pageNum, size: this.query.pageSize};
          let res = await getBatteryRealResultOfPage(data);
          if (res.status !== 200) return tis.$msg.error("获取电池实时信息失败");
          let datas = res.data.data.content;
          this.totalPages = res.data.data.totalElements;
          this.tableData = datas;

        } catch (error) {

        }
      },
      //获取换电站编码
      async getStation() {
        const res = await getForceList();
        let newList = res.data.data;
        this.stationList = newList.map((item) => {
          return {
            value: item.stationNo,
            label: item.stationName,
          };
        });
      },
      //获取所有电池请况
      async getBatterListAll() {
        const res = await getBatterAll()
        this.selectList = res.data.data
      },
      handleSizeChange(val) {
        this.query.pageSize = val
        if (this.isPage) {
          this.getAll()
        } else {
          this.queryFunction()
        }

      },
      selectionChange(selection) {
        this.bartchNum = selection
      },
      handleCurrentChange(val) {
        this.query.pageNum = val
        if (this.isPage) {
          this.getAll()
        } else {
          this.query.resPageNum = val
          this.queryFunction()
        }
      },
      //添加
      addFunction() {
        this.$refs.addFrom.validate(async (valid) => {
          if (!valid) return this.$msg.info('必填项不能为空')
          try {
            var dataList = this.addFrom;
            dataList.updateTime = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
            let res = await addBatteryRealResult(dataList);
            if (res.status != 200) return this.$msg.error("添加失败");
            this, this.getAll();
            this.addDialog = false;

          } catch (e) {
            console.log(e);
          }
        });
      },
      renIndex(index) {
        return (this.query.pageNum - 1) * this.query.pageSize + index + 1
      },
      //查询
      async queryFunction() {
        if (
          this.cascader === '' && this.stationValue === '' &&
          this.stationType === '' && this.cellCityValue === '' && this.stateValue === '') return
        try {
          let data = {
            regionCode: this.cascader,
            stationNo: this.stationValue,
            stationType: this.stationType,
            btyLocation: this.cellCityValue,
            state: this.stateValue
          };
          this.storage('cellPut', data)
          data.page = this.query.resPageNum
          data.size = this.query.pageSize
          let res = await getBatteryRealResultOfstate(data);
          if (res.status != 200) return this.$msg.error("查询失败");
          let datas = res.data.data.content;
          this.totalPages = res.data.data.totalElements;
          this.tableData = datas;
          this.isPage = false
        } catch (e) {
          console.log(e);
        }
      },
      cleanList() {
        this.$refs.cellPutTable.clearSelection()
      },
      async download() {
        const confirm = await this.$MessageBox.confirm('是否导出文件', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(e => e)
        if (confirm !== 'confirm') return
        this.$download(this.downloadList, this.coulm, '电池投入信息')

      },
      restFunction() {
        this.cascaderValue = [];
        this.stationValue = "";
        this.stationType = "";
        this.cellCityValue = "";
        this.stateValue = "";
        this.isPage = true
        this.query = {
          pageNum: 1,
          pageSize: 20,
          resPageNum: 1
        }
        this.getAll();
        this.getStation()
      },
      async changeCascader() {
        this.stationValue = ''
        const data = {
          regionCode: this.cascader,
          stationType: this.stationType
        }
        const res = await regionCodeSation(data)
        if (res.status !== 200) return this.$msg.error('换电站查询失败!')
        if (res.data.data.length !== 0) {
          this.stationList = res.data.data.map(item => {
            return {
              value: item.stationNo,
              label: item.stationName
            }
          })
        } else {
          this.stationList = []
        }
        if (this.stationList.length !== 0) {
          const newList = JSON.parse(getItem('cellPut'))
          if (newList && newList[1].regionCode === this.cascader && newList[0].index > 3) {
            this.cascaderValue = [newList[1].regionCode]
            this.stationValue = newList[1].stationNo
            this.stationType = newList[1].stationType
            this.cellCityValue = newList[1].btyLocation
            this.stateValue = newList[1].state
          }
        }
      },
      async changeSelect() {
        this.stationValue = ''
        const data = {
          regionCode: this.cascader,
          stationType: this.stationType
        }
        const res = await regionCodeSation(data)
        if (res.status !== 200) return this.$msg.error('换电站查询失败!')
        if (res.data.data.length !== 0) {
          this.stationList = res.data.data.map(item => {
            return {
              value: item.stationNo,
              label: item.stationName
            }
          })
        } else {
          this.stationList = []
        }
      }
    },
  };
</script>

<style lang='less' scoped>
  .el-row {
    margin: 15px 0;

    .el-col {
      margin-right: 20px;
    }
  }

  .el-select, .el-cascader {
    width: 100%;
  }
</style>
