import { createAPI } from '@/utils/request'
const urlStr2 = '/service-baseinfo'
const urlStr = '/cloud-platform'


//站控软件升级信息
export const getUpgradeInfo = data=>createAPI(urlStr2+'/sftRemoteUpgradeInfo/specificPage','get',data)

//站控主机升级获取
export const UpdateInfoPage = data=>createAPI(urlStr2+'/sftVerFileUpdateInfo/specificPage','get',data)

//站控主机新增
export const UpdateAdd = data=>createAPI(urlStr2+'/sftVerFileUpdateInfo/add','post',data)

//站控主机修改
export const Updatecompil =data=>createAPI(urlStr2+'/sftVerFileUpdateInfo/update','post',data)

//站控软件升级信息
//根据名称状态分页查询
export const apkManagePage = (current, size, data)=>createAPI(urlStr+'/cloudPlatform/apkManagePage/' + current + "/" + size,'post',data)
//获取云平台区域信息
// export const getNode = () => createAPI(urlStr2 + '/cloudPlatform/findNodeList', 'get')
//添加云平台区域信息
export const AddApkManage = data => createAPI(urlStr + '/cloudPlatform/AddApkManage', 'post',data)
//修改云平台区域信息
export const UpdateApkManage = data => createAPI(urlStr + '/cloudPlatform/UpdateApkManage', 'post',data)
//删除云平台区域信息
export const DeleteApkManage = id => createAPI(urlStr + '/cloudPlatform/DeleteApkManage/'+ id, 'delete')
