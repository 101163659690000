//订单管理
import { createAPI } from '@/utils/request'
const urlStr = '/service-pay'
const urlStr2 = '/service-baseinfo'
const urlStr3='/service-mqtt'

//订单信息管理
//订单分页查询
export const customerOrderPage = data => createAPI(urlStr + "/pay/order/pageQuery",'post',data)
// export const customerOrderPage = form=> createAPI(urlStr + "/order/specificPageWithoutPerm?page="+form.page+"&size="+form.size + form.url,'get')
//订单详情查询
export const queryOrderDetail = data => createAPI(urlStr + "/pay/order/queryDetailById?id="+data.id,'get')

export const detailByOrderNo = data => createAPI(urlStr + "/pay/order/detailByOrderNo?orderNo="+data.orderNo + "&recordTime="+data.recordTime,'get')

//订单退款
export const orderRefund = data => createAPI(urlStr + "/pay/order/refund",'post',data)


export const transStatistics = data => createAPI(urlStr + "/pay/statistics/transStatistics",'get')

export const dayTransStatistics = data => createAPI(urlStr + "/pay/statistics/dayTransStatistics",'get')

export const transStatisticsDateRange = data => createAPI(urlStr + "/pay/statistics/transStatisticsDateRange",'get')

//付款订单导出
export const payOrderExport = data => createAPI(urlStr + "/pay/order/export",'post',data)

//换电订单
export const swapOrderPage = data => createAPI(urlStr2 + "/swapOrder/pageQuery"+data,'get')
//换电订单导出
export const swapOrderExport = data => createAPI(urlStr2 + "/swapOrder/export"+data,'get')

//充电订单
export const chargeOrderPage = data => createAPI(urlStr2 + "/swapOrder/chargeOrder/pageQuery"+data,'get')
//充电订单导出
export const chargeOrderExport = data => createAPI(urlStr2 + "/swapOrder/chargeOrder/export"+data,'get')

//异常订单手动计费
export const swapOrderManualCharging = data => createAPI(urlStr3 + '/swapOrder/manualCharging?orderId='+ data.orderId + '&orderNo=' + data.orderNo, 'get')
