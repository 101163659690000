<template>
    <div class="app-container">
        <el-row class="main-flex">
            <!--运营商场站树-->
            <el-col :span="3" class="side-search" v-if="isopenShow">
                <operatorStationTreeVue @emitToParent='getTreeData' @isShow="isHidden"></operatorStationTreeVue>
            </el-col>

            <el-col v-if="!isopenShow" class="small-side">
                <div class="change-in" @click="isopenShow = !  isopenShow "></div>
            </el-col>

            <!-- 搜索区域 -->
            <el-col :span="21" class="mian-content">
<!--                    <el-input placeholder="请从左侧选择运营商/场站" v-model.trim="treeData.operatorNameTree"-->
<!--                              disabled></el-input>-->
                <div class="head-section">
                    <el-col  :span="24">
                        <div class="btn-end">
                            <span class="btn-rest">
                     <el-button  @click="restFunction" plain >重置</el-button></span>
                            <span class="btn-submit">
                       <el-button @click="getChargeDevDataPage" type="primary" plain >查询</el-button>
                        </span>
                        </div>
                    </el-col>
                </div>
                <!--  表格部分-->
                <div class="section-main">
                    <el-col class="choose-mian">
                        <div class="section-title">
                            充电机列表
                        </div>
                    </el-col>
                    <!--table-->
                    <el-col :span="24">
                            <el-row class="table-box">
                                <el-table ref="chargeDevTable" id="statiNo" :data="tableData"
                                           :header-cell-style="headerStyleEvent" :cell-class-name="goodsInfo">
                                    <el-table-column align="center" type="selection" width="46" fixed></el-table-column>
                                    <el-table-column align="center" label="编号" :index="renIndex" type="index" width="50"></el-table-column>
                                    <el-table-column align="center" prop="operatorName" label="归属运营商" :show-overflow-tooltip="true"
                                                     width="130"></el-table-column>
                                    <el-table-column align="center" prop="stationName" label="场站名称" :show-overflow-tooltip="true" width="130"></el-table-column>
                                    <el-table-column align="center" prop="sn" label="场站编码" width="150"></el-table-column>
                                    <el-table-column align="center" prop="sd" label="充电架ID" ></el-table-column>
                                    <el-table-column align="center" prop="cno" label="充电机序号" min-width="100"></el-table-column>
                                    <el-table-column align="center" prop="cs" label="工作状态" min-width="80">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.cs === 0"> 待机 </span>
                                            <span v-else-if="scope.row.cs === 1" >工作</span>
                                            <span v-else-if="scope.row.cs === 2" >工作完成</span>
                                            <span v-else-if="scope.row.cs === 3" >充电暂停</span>
                                            <span v-else>未知</span>
                                        </template>
                                    </el-table-column>

                                    <el-table-column align="center" prop="hb" label="是否有电池" min-width="100">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.hb === 1"> 有电池 </span>
                                            <span v-else-if="scope.row.hb === 2" >无电池</span>
                                            <span v-else>未知</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column align="center" prop="el" label="电接头连接状态" min-width="120">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.el === 1"> 已经连接 </span>
                                            <span v-else-if="scope.row.el === 2" >未连接</span>
                                            <span v-else>未知</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column align="center" prop="mtp" label="充电机最大允许输出功率(KW)" min-width="120"></el-table-column>
                                    <el-table-column align="center" prop="mcr" label="充电机最大允许充电速率(KW)" min-width="120"></el-table-column>


                                    <el-table-column align="center" prop="fs" label="故障状态" >
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.fs === 0"> 无故障 </span>
                                            <span v-else-if="scope.row.fs === 1" >有故障</span>
                                            <span v-else>未知</span>
                                        </template>
                                    </el-table-column>

                                    <!--
                                    <el-table-column align="center" prop="as" label="告警状态" >
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.as === 0"> 无告警 </span>
                                            <span v-else-if="scope.row.as === 1" >有告警</span>
                                            <span v-else>未知</span>
                                        </template>
                                    </el-table-column>
                                    -->

                                    <el-table-column align="center" prop="fc" label="故障码" ></el-table-column>

                                    <el-table-column align="center" prop="st" label="充电开始时间" min-width="170"></el-table-column>
                                    <el-table-column align="center" prop="ct" label="已经充电时间(分钟)" min-width="120"></el-table-column>
                                    <el-table-column align="center" prop="ssoc" label="充电开始SOC" ></el-table-column>
                                    <el-table-column align="center" prop="csoc" label="当前SOC" ></el-table-column>
                                    <!--
                                                                <el-table-column prop="ssoe" label="充电开始SOE" ></el-table-column>
                                                                <el-table-column prop="csoe" label="当前SOE" ></el-table-column>
                                                                -->
                                    <el-table-column align="center" prop="cvot" label="当前充电电压(V)" ></el-table-column>
                                    <el-table-column align="center" prop="ccur" label="当前充电电流(A)" ></el-table-column>
                                    <el-table-column align="center" prop="nvot" label="BMS需求电压(V)" ></el-table-column>
                                    <el-table-column align="center" prop="ncur" label="BMS需求电流(A)" ></el-table-column>
                                    <el-table-column align="center" prop="lsv" label="最低单体电压(V)" ></el-table-column>
                                    <el-table-column align="center" prop="hsv" label="最高单体电压(V)" ></el-table-column>
                                    <el-table-column align="center" prop="lst" label="最低单体温度(°C)" ></el-table-column>
                                    <el-table-column align="center" prop="hst" label="最高单体温度(°C)" ></el-table-column>
<!--
                                    <el-table-column align="center" prop="ws" label="水冷状态" ></el-table-column>
                                    <el-table-column align="center" prop="it" label="进水口温度(°C)" ></el-table-column>
                                    <el-table-column align="center" prop="ot" label="出水口温度(°C)" ></el-table-column>
                                    -->
                                    <el-table-column align="center" prop="bt" label="更新时间"  min-width="170"></el-table-column>
                                    <el-table-column align="center" prop="createTime" label="创建时间" min-width="170"></el-table-column>
                                    <!--
                                                                <el-table-column label="操作" min-width="250" style="align-items: center;" fixed="right">
                                                                    <template slot-scope="scope">
                                                                        <div class="operationBtn">
                                                                            <div class="leftBtn">
                                                                                <el-button plain @click="detail(scope.row)"
                                                                                           size=small type="warning" icon="el-icon-search">详情
                                                                                </el-button>
                                                                            </div>
                                                                        </div>
                                                                    </template>
                                                                </el-table-column>，
                                                                -->
                                </el-table>
                            </el-row>

                    </el-col>

                    <!--pagination-->
                    <el-col :span="24">
                        <el-row class="pagin">
                            <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="query.pageNum"
                                :page-sizes="[10, 30, 50,100]"
                                :page-size="query.pageSize"
                                layout="total, sizes, prev, pager, next"
                                :total="total">
                            </el-pagination>
                        </el-row>
                    </el-col>
                </div>

            </el-col>

        </el-row>


    </div>
</template>

<script>
import '@/assets/font/font.css';
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import operatorStationTreeVue from "@/components/tree/operatorStationTree";
import {getChargeDevPage} from "@/utils/station";
export default {
    name: 'chargeDev',
    components: {operatorStationTreeVue},
    data() {
        return {
            isopenShow: true,
            query: {
                pageNum: 1,
                pageSize: 10,
                stationNo: "",
                stationName: ""
            },

            isUpdate:false,
            isDataShow:false,
            dataShow:{},

            tableData: [],
            total: 0,


            //左侧 运营商-场站树
            treeData: {
                operatorStationOptions: undefined,  //运营商-场站树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
                operatorStationNameTree: "",
                operatorStationIdTree: null,
                defaultExpandAll: true,
            },


        };

    },
    created() {

        this.getChargeDevDataPage();

    },
    computed: {},
    methods: {
        headerStyleEvent({ row, column, rowIndex, columnIndex }) {
            if (columnIndex == 1) {
                return 'padding:0px;'
            }
        },
        goodsInfo(row){
            if (row.columnIndex == 1) {
                return 'goodsInfo'
            }
        },
        isHidden(message) {
            this.isopenShow = message
        },
        async getChargeDevDataPage() {
            try {
                var data = {
                    page: this.query.pageNum,
                    size: this.query.pageSize
                };
                var id = this.treeData.operatorStationIdTree;
                if (id !=="" && id !== null) {
                    if (id.indexOf("operator") !== -1) {
                        id = id.slice(9);
                        data.operatorId = Number(id);
                    } else {
                        id = id.slice(8);
                        data.stationId =  Number(id)
                    }
                }
                const res = await getChargeDevPage(data)
                if (res.data.code !== 200) return this.$msg.error('获取场站基础信息失败!')
                let newList = res.data.data.data
                this.tableData = newList
                this.total = res.data.data.total
            } catch (e) {
            }
        },
        detail(row){
            this.isDataShow = true;
            this.showData = row;
        },


        restFunction() {
            //左侧 运营商-场站树
            this.treeData = {
                operatorStationOptions: undefined,  //运营商-场站树选项
                    defaultProps: {
                    children: "children",
                        label: "label"
                },
                operatorStationNameTree: "",
                    operatorStationIdTree: null,
                    defaultExpandAll: true,
            };
            this.query = {
                pageNum: 1,
                pageSize: 10,
            }
            this.getChargeDevDataPage()
        },

        handleSizeChange(val) {
            this.query.pageSize = val
            this.getChargeDevDataPage()
        },
        handleCurrentChange(val) {
            this.query.pageNum = val
            this.getChargeDevDataPage()
        },

        renIndex(index) {
            return (this.query.pageNum - 1) * this.query.pageSize + index + 1
        },

        close() {

        },

        cleanList() {
            this.$refs.chargeDevTable.clearSelection()
        },

        getTreeData(data) {
            this.treeData = data
        },

        /** 查询运营商下拉树结构 */
        async getOperatorTree() {
            try {
                this.treeData.operatorOptions = [];
                let res = await operatorTree();
                if (res.status !== 200) return this.$msg.error("获取运营商信息失败");
                this.treeData.operatorOptions.push(res.data.data)
                this.treeData.operatorIdTree = res.data.data.id
                this.treeData.operatorNameTree = res.data.data.label
            } catch (error) {
            }
        },
    },



};
</script>

<style lang='less' scoped>
.app-container {
    background: rgba(255, 255, 255, 0);
    padding-left: 0px;
}

::v-deep .fixstyle {
    padding-left: 0 !important;
}

.el-table {
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
        width: 10px; /*滚动条宽度*/
        height: 10px; /*滚动条高度*/
    }

    /*定义滚动条轨道 内阴影+圆角*/

    /deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
        //   box-shadow: 0px 1px 3px #fff inset; /*滚动条的背景区域的内阴影*/
        border-radius: 10px; /*滚动条的背景区域的圆角*/
        background-color: #fff; /*滚动条的背景颜色*/
    }

    /*定义滑块 内阴影+圆角*/

    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
        //   box-shadow: 0px 1px 3px #dddee0 inset; /*滚动条的内阴影*/
        border-radius: 10px; /*滚动条的圆角*/
        background-color: #dddee0; /*滚动条的背景颜色*/
    }
}

.inner-container {
    // min-width: 100% + 60px;
    height: var(--tableHeight);
    overflow: hidden;
}

.bm-view {
    width: 100%;
    height: 400px;
}


li {
    list-style: none;
}

.el-select, .el-cascader {
    width: 100%;
}

.el-dropdown {
    margin-left: 25px;
}

.states {
    > i {
        display: inline-block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        margin-right: 5px;
        margin-bottom: 3px;
    }
}

.box1 {
    display: flex;
    align-items: center;
    margin: 0 !important;

    span {
        position: relative;
        font-size: 18px;
        font-weight: 700;
        margin-left: 20px;
    }

    .text::before {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        background-color: #1890ff;
        border-radius: 50%;
        top: 50%;
        left: -13px;
        transform: translate(0, -50%);
    }
}

.content-hander {
    height: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-left: 3px solid #2980e1;
    padding-left: 15px;

    > h3 {
        font-size: 16px;
        font-weight: 700;
    }
}

.content-title {
    height: 200px;

    .el-col {
        height: 100%;

        > img {
            width: 100%;
            height: 100%;
            box-shadow: 0 0 25px #333;
        }
    }

    .content-title-right {
        margin-left: 50px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        > ul {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 100%;
            height: 100%;
        }
    }
}

.msg-title {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 0;
    margin: 0 !important;

    span {
        color: #333;
    }

    .text1 {
        color: #595959;
        margin-left: 15px;
    }
}

.content-map {
    width: 100%;
    height: 100%;
    border: 1px solid #eee;
}

.el-divider--horizontal {
    margin-bottom: 0;
}

.fileImg {
    display: flex;
    align-items: flex-start;

    div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 120px;
        height: 120px;
        border: 1px dashed #ccc;
        border-radius: 6px;
        margin-right: 20px;
        cursor: pointer;

        > img {
            width: 100%;
            height: 100%;
            border-radius: 6px;
        }
    }

    div:hover {
        border: 1px dashed #1b80e1;

        i {
            color: #1b80e1;
        }
    }

}

.dowload {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
        display: flex;
        align-items: center;

        &:first-child {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            > span {
                font-size: 12px;
                color: #e10505;
            }
        }

        > button {
            margin-top: 0 !important;
        }
    }
}
.out-container{
    height: 100%;
}
//重构新增
.side-search {
    width: 200px;
    background: #FFFFFF;
    border-right: #d3dce6 1px solid;
}

.small-side {
    width: 36px;
    height: 36px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.14);
    border-right: 1px solid #EEE;
}

.mian-content {
    flex: 1;
    background: #F5F7FA;
}

.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.head-section {
    padding: 16px;
    background: #FFFFFF;
    overflow: hidden;
}

.btn-end {
    display: flex;
    justify-content: flex-end;
}

.section-main {
    width: 100%;
    //height: calc(100vh - 24px);
    background: #FFFFFF;
    margin-top: 16px;
    padding: 24px 16px 16px 16px;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.choose-mian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
        display: inline-block;
        margin-left: 16px;
    }
}

.alert-box {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
}

.pagin {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.main-flex {
    display: flex;

}

.left-style {
    padding-left: 43px;
}
.edit-style{
    color: #0D6CE4;
    font-size: 14px;
    cursor:pointer;
}
</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';
</style>
