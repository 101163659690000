<template>
    <div class="app-container">
        <el-row class="main-flex">
            <el-col :span="3" class="side-search" v-if="isopenShow">
                <operatorTeamTreeVue @emitToParent='getTreeData' @isShow="isHidden"></operatorTeamTreeVue>
            </el-col>

            <el-col v-if="!isopenShow" class="small-side">
                <div class="change-in" @click="isopenShow = !  isopenShow "></div>
            </el-col>

            <!--            <el-input placeholder="左侧选择运营商/车队" v-model.trim="treeData.operatorTeamNameTree"-->
            <!--                      disabled></el-input>-->
            <!-- 搜索区域 -->
            <el-col :span="21" class="mian-content">
                <div class="head-section">
                    <el-col :span="6">
                        <el-form :inline="true">
                            <el-form-item label="审核状态：" label-width="82px">
                                <el-select v-model="query.auditStatus" filterable placeholder="请选择审核状态"
                                           clearable>
                                    <el-option
                                        v-for="item in auditStatusList"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="7">
                        <el-form :inline="true" class="left-style">
                            <el-form-item label="手机号：" label-width="90px">
                                <el-input placeholder="请输入手机号" v-model.trim="query.phone" clearable></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="7">
                        <el-form :inline="true" class="left-style">
                            <el-form-item label="车牌号：">
                                <el-input placeholder="请输入车牌号" v-model.trim="query.carNo" clearable></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="4">
                        <div class="btn-end">
                            <span class="more-search" @click="showMore=!showMore"><div>展开</div>
                            <i :class="showMore==true?'el-icon-arrow-down':'el-icon-arrow-up'"></i> </span>
                            <span class="btn-rest">
                            <el-button @click="restFunction" plain>重置</el-button>
                            </span>
                            <span class="btn-submit">
                            <el-button type="primary" @click="queryFunction" plain v-hasCode="['vehicle:auth:query']">查询
                    </el-button>
                            </span>
                        </div>
                    </el-col>
                </div>
                <transition name="el-zoom-in-top">
                    <div v-show="showMore">
                        <div class="head-section head-section2">
                            <el-col :span="6">
                                <el-form :inline="true">
                                    <el-form-item label="VIN码：" label-width="82px">
                                        <el-input placeholder="请输入VIN码" v-model.trim="query.vin" clearable></el-input>
                                    </el-form-item>
                                </el-form>
                            </el-col>
                            <el-col :span="7">
                                <el-form :inline="true">
                                    <el-form-item label="MAC地址：" class="left-style" label-width="90px">
                                        <el-input placeholder="请输入MAC地址" v-model.trim="query.mac" clearable></el-input>
                                    </el-form-item>
                                </el-form>
                            </el-col>
                        </div>
                    </div>
                </transition>
                <!--  表格部分-->
                <div class="section-main">
                    <el-col class="choose-mian">
                        <div class="section-title">
                            车辆审核列表
                        </div>
                        <div>
                            <!--选择项栏-->
                            <span class="alert-box">
                          已选择{{ selectList.length }}项
                            <span @click="clean" style="margin-left:8px;color:#0D6CE4;cursor:pointer;">清空</span>
                            </span>
                            <span class="pain-btn">
                                <el-button @click="removeAll" plain
                                           v-hasCode="['vehicle:auth:remove']">删除
                                </el-button>
                            </span>
                            <span class="add-btn">
                                <el-button type="primary" @click="addDialogFunction" plain
                                           v-hasCode="['vehicle:auth:add']">新增
                                </el-button>
                            </span>
                        </div>
                    </el-col>
                    <el-col :span="24">
                        <el-row class="table-box">
                            <el-table
                                :data="tableData"
                                ref="multipleTable"
                                @selection-change="selectionChange"
                                :header-cell-style="headerStyleEvent" :cell-class-name="goodsInfo"
                            >
                                <el-table-column align="center" type="selection" min-width="15"></el-table-column>
                                <el-table-column align="center" type="index" :index="renIndex" label="编号"
                                                 min-width="20"></el-table-column>
                                <!--                            <el-table-column prop="userName" label="用户姓名" min-width="30"></el-table-column>
                                                            <el-table-column prop="carNo" label="车牌号" min-width="50"></el-table-column>-->
                                <el-table-column align="center" prop="teamVO.teamName" label="车队名称"
                                                 min-width="110" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column align="center" prop="phone" label="手机号"
                                                 min-width="110"></el-table-column>
                                <el-table-column align="center" prop="carNo" label="车牌号"
                                                 min-width="100"></el-table-column>
                                <el-table-column align="center" prop="vin" label="vin"
                                                 min-width="170"></el-table-column>
                                <el-table-column align="center" prop="mac" label="mac地址"
                                                 min-width="168"></el-table-column>

                                <el-table-column align="center" prop="userName" label="姓名"
                                                 min-width="70" :show-overflow-tooltip="true"></el-table-column>
                                <!--
                                <el-table-column prop="nickName" label="微信昵称" min-width="100"></el-table-column>
                                 <el-table-column prop="openId" label="小程序openId" :show-overflow-tooltip="true" min-width="160"></el-table-column>
                                 <el-table-column prop="cover" label="驾驶证" min-width="130">
                                    <template slot-scope="scope">
                                        <el-avatar shape="square" :size="100" fit="fill"
                                                   :src="scope.row.driverLicense"></el-avatar>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="cover" label="行驶证" min-width="130">
                                    <template slot-scope="scope">
                                        <el-avatar shape="square" :size="100" fit="fill"
                                                   :src="scope.row.vehicleLicense"></el-avatar>
                                    </template>
                                </el-table-column>
                                -->

                                <el-table-column align="center" prop="isDefault" label="默认账户">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.isDefault === 0">否</span>
                                        <span v-else-if="scope.row.isDefault === 1">是</span>
                                    </template>
                                </el-table-column>

                                <el-table-column align="center" prop="accountBalance" label="账户余额" :formatter="balanceFormat"
                                                 min-width="120"></el-table-column>

                                <el-table-column align="center" prop="auditStatus" label="审核状态" min-width="90">
                                    <template slot-scope="scope">
                                <span v-if="scope.row.auditStatus === 1" class="states"
                                      style="color:#0D6CE4;"><i
                                    style="background-color:#0D6CE4;"></i> 审核通过 </span>
                                        <span v-else-if="scope.row.auditStatus === 2" class="states"
                                              style="color:#FF6165;"><i
                                            style="background-color:#FF6165;"></i> 审核拒绝</span>
                                        <span v-else-if="scope.row.auditStatus === 0" class="states"
                                              style="color:#FA9600;"><i
                                            style="background-color:#FA9600;"></i> 待审核</span>
                                        <span v-else class="states" style="color:#FFCD29;"><i
                                            style="background-color:#FFCD29;"></i> 未知</span>
                                    </template>
                                </el-table-column>

                                <el-table-column align="center" prop="applyTime" label="申请时间"
                                                 min-width="160"></el-table-column>

                                <el-table-column align="center" label="操作" min-width="240">
                                    <template v-slot:default="scope">

                                        <el-button @click="handleDetail(scope.row)" type="text" class="btn-color"
                                                   v-hasCode="['miniapp:customervehicle:detail']">详情
                                        </el-button>

                                        <el-button @click="handleAudit(scope.$index, scope.row)" type="text"
                                                   v-if="scope.row.auditStatus == 0" class="btn-color"
                                                   v-hasCode="['miniapp:customervehicle:audit']">审核
                                        </el-button>

                                        <el-button @click="handleReason(scope.$index, scope.row)" type="text" class="btn-color"
                                                   v-if="scope.row.auditStatus == 2">驳回原因
                                        </el-button>

                                        <el-button @click="handleBind(scope.row)" type="text" class="btn-color"
                                                   v-hasCode="['miniapp:customervehicle:accountbind']">账户绑定
                                        </el-button>

                                        <el-button @click="handleEdit(scope.row)" type="text" class="btn-color"   @change="$forceUpdate()"
                                                   v-hasCode="['vehicle:auth:edit']">编辑
                                        </el-button>

                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-row>
                    </el-col>

                    <el-col :span="24">
                        <el-row class="pagin">
                            <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="query.pageNum"
                                :page-sizes="[10,30, 50, 100]"
                                :page-size="query.pageSize"
                                layout="total, sizes, prev, pager, next"
                                :total="totalPages"
                            ></el-pagination>
                        </el-row>
                    </el-col>
                </div>
            </el-col>


        </el-row>

        <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="picArr">
        </el-image-viewer>

        <el-dialog
            class="popup_marker"
            :title="isAddCheck? '审核详情':'车辆审核'"
            :visible.sync="auditShow"
            width="38%"
            style="z-index: 9999">
            <el-row :span="24" :gutter="16" >
                <el-col :span="12" class="inline_element">
                    <div class="detail-item-left">车牌号：</div>
                    <div class="detail-item">{{ form.carNo }}&nbsp;</div>
                </el-col>
                <el-col :span="12" class="inline_element">
                    <div class="detail-item-left">VIN码：</div>
                    <div class="detail-item">{{ form.vin }}&nbsp;</div>
                    <!--                    <div class="detail-item-left">发动机号：</div>-->
                    <!--                    <div class="detail-item">{{ form.engineNo }}&nbsp;</div>-->
                </el-col>
                <el-col :span="12" class="inline_element">
                    <div class="detail-item-left">驾驶证姓名：</div>
                    <div class="detail-item">{{ form.userName }}&nbsp;</div>
                </el-col>
                <el-col :span="12" class="inline_element">
                    <div class="detail-item-left">手机号码：</div>
                    <div class="detail-item">{{ form.phone }}&nbsp;</div>

                </el-col>
                <el-col :span="12" class="inline_element">
                    <div class="detail-item-left">申请时间：</div>
                    <div class="detail-item">{{ form.createTime }}&nbsp;</div>

                </el-col>
            </el-row>
            <el-row :gutter="16" :span="24" >
                <el-col :span="6" class="el-form-item_bottom">
                    <div class="detail-item-left" >驾驶证：</div>
                    <div class="detail-item">
                        <img class="detail-item-pic" :src="driverLicenseUrl" alt="图片加载失败" @click="showBigPic1(0)"
                             style=" display: flex;justify-content: space-around;border: 1px rgb(245, 245, 245) ridge;
                             width: 144px;height: 82px; border-radius: 4px; border-style: solid; border-width: 1px; border-color: #EEEEEE; ">
                    </div>
                </el-col>
                <el-col :span="6" class="el-form-item_bottom">
                    <div class="detail-item-left" >行驶证：</div>
                    <div class="detail-item">
                        <img class="detail-item-pic" :src="vehicleLicenseUrl" alt="图片加载失败" @click="showBigPic1(1)"
                             style=" display: flex;justify-content: space-around;border: 1px rgb(245, 245, 245) ridge;
                             width: 144px;height: 82px; border-radius: 4px; border-style: solid; border-width: 1px; border-color: #EEEEEE;">
                    </div>
                </el-col>
                <el-col :span="6" class="el-form-item_bottom">
                    <div class="detail-item-left" >身份证（正）：</div>
                    <div class="detail-item">
                        <img class="detail-item-pic" :src="idFrontUrl" alt="图片加载失败" @click="showBigPic1(2)"
                             style=" display: flex;justify-content: space-around;border: 1px rgb(245, 245, 245) ridge;
                             width: 144px;height: 82px; border-radius: 4px; border-style: solid; border-width: 1px; border-color: #EEEEEE;">
                    </div>
                </el-col>
                <el-col :span="6" class="el-form-item_bottom">
                    <div class="detail-item-left" >身份证（反）：</div>
                    <div class="detail-item">
                        <img class="detail-item-pic" :src="idBackUrl" alt="图片加载失败" @click="showBigPic1(3)"
                             style=" display: flex;justify-content: space-around;border: 1px rgb(245, 245, 245) ridge;
                             width: 144px;height: 82px; border-radius: 4px; border-style: solid; border-width: 1px; border-color: #EEEEEE;">
                    </div>
                </el-col>
            </el-row>


            <el-row :gutter="24" v-if="isAudit" class="car_message">
                <el-col :span="24">
                    <div class="detail-item-left">是否通过：</div>
                </el-col>
                <el-col :span="8" class="detail-item">
                    <el-radio v-model="auditStatus" label="1">通过</el-radio>
                    <el-radio v-model="auditStatus" label="2">驳回</el-radio>
                    <!--                    <el-select v-model="value" placeholder="请审核" style="margin-left: 8px">-->
                    <!--                        <el-option-->
                    <!--                            v-for="item in auditStatus_car"-->
                    <!--                            :key="item.value"-->
                    <!--                            :label="item.label"-->
                    <!--                            :value="item.value">-->
                    <!--                        </el-option>-->
                    <!--                    </el-select>-->
                </el-col>



            </el-row>
            <el-row :gutter="24" v-if="isAudit && auditStatus==2" class="car_message">
                <el-col :span="4">
                    <div class="detail-item-left">驳回原因：</div>
                </el-col>
                <el-col :span="24" class="detail-item">
                    <el-input
                        type="textarea"
                        :rows="3"
                        maxlength="50"
                        placeholder="请输入驳回原因"
                        v-model="auditReason">
                    </el-input>
                </el-col>
            </el-row>
            <span slot="footer" class="dialog-footer" v-if="isAudit">
                <el-button @click="auditShow = false">取 消</el-button>
                <el-button type="primary" @click="handleSave">确 定</el-button>
            </span>
        </el-dialog>


        <el-dialog :visible.sync="auditReasonShow" width="38%">
            <el-row :gutter="24">
                <el-col :span="3">
                    <div class="detail-item-left" style="width: 78px;margin: 0;" >驳回原因：</div>
                </el-col>
                <el-col :span="21">
                    <el-input
                        type="textarea"
                        :rows="3"
                        maxlength="50"
                        placeholder="请输入内容"
                        v-model="auditReason">
                    </el-input>
                </el-col>
            </el-row>
        </el-dialog>

        <el-dialog
            class="popup_marker"
            :title="isAdd?'新增车辆审核信息':'修改车辆审核信息'"
            :visible.sync="addDialog"
            @close="addClose"
            width="38%"
        >
            <el-form size="middle" ref="addForm" :model="addForm"  :rules="rules">
                <el-row :gutter="16" :span="24">
                    <el-col :span="12">

                        <el-form-item label="归属车队:" prop="teamId">
                            <el-select v-model="addForm.teamId">
                                <el-option v-for="item in teamList" :key="item.id" :label="item.teamName"
                                           :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="姓名:" prop="name">
                            <el-input placeholder="请输入姓名" v-model.trim="addForm.userName" clearable maxlength="30" show-word-limit></el-input>
                        </el-form-item>
                    </el-col>
                        </el-row>
                <el-row :gutter="16" :span="24">
                    <el-col :span="12">
                        <el-form-item label="手机号:" prop="phone">
                            <el-select filterable v-model.trim="addForm.phone" placeholder="请选择手机号" clearable>
                                <el-option v-for="(item,index) in accounts" :key="index" :label="item.phone"
                                           :value="item.phone"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="车辆VIN码:" prop="vin">
                            <el-select filterable v-model.trim="id" placeholder="请选择车辆VIN" clearable>
                                <el-option v-for="(item,index) in vehicles" :key="index" :label="item.carVin"
                                           :value="index"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="16" :span="24">
                    <el-col :span="12">
                        <el-form-item label="车牌号:" prop="carNo">
                            <el-select filterable v-model.trim="id" placeholder="请选择车牌号" clearable>
                                <el-option v-for="(item,index) in vehicles" :key="index"
                                           :label="item.carNo===null?'暂无车牌':item.carNo"
                                           :value="index"></el-option>
                            </el-select>
                        </el-form-item>
                        </el-col>
                    <el-col :span="12">
                        <el-form-item label="mac地址:" prop="mac">
                            <el-select filterable v-model.trim="id" placeholder="请选择车辆MAC" clearable>
                                <el-option v-for="(item,index) in vehicles" :key="index"
                                           :label="item.mac===null?'暂无mac地址':item.mac"
                                           :value="index"></el-option>
                            </el-select>
                        </el-form-item>
                        </el-col>
                </el-row>
               <el-row :gutter="16" :span="24">
                   <el-col :span="12">
                       <el-form-item label="状态:" prop="state">
                           <el-select v-model="addForm.auditStatus" :disabled="true">
                               <el-option
                                   v-for="item in auditStatusList"
                                   :key="item.value"
                                   :label="item.label"
                                   :value="item.value"
                               ></el-option>
                           </el-select>
                       </el-form-item>
                   </el-col>
                   <el-col :span="12"></el-col>
               </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="addClose">取 消</el-button>
        <el-button type="primary" @click="addFunction">确 定</el-button>
      </span>
        </el-dialog>



        <el-dialog class="popup_marker"
            :title="isBind?'账户绑定':'账户绑定'"
            :visible.sync="bindDialog"
            @close="bindClose"
            width="38%">
            <el-form size="middle" ref="addForm" :model="addForm" l :rules="rules"  label-position="top">
                <el-row :span="24" :gutter="16">
                    <el-col :span="12">
                        <el-form-item label="归属车队:" prop="teamName">
                            <el-input v-model="addForm.teamName" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                        <el-col :span="12">
                        <el-form-item label="姓名:" prop="name">
                            <el-input v-model.trim="addForm.name" :disabled="true"></el-input>
                        </el-form-item>
                        </el-col>
                </el-row>
                    <el-row :span="24" :gutter="16">
                    <el-col :span="12">
                        <el-form-item label="手机号:" prop="phone">
                            <el-input v-model="addForm.phone" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="车牌号:" prop="carNo">
                            <el-input v-model="addForm.carNo" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="车辆VIN码:" prop="vin">
                            <el-input v-model="addForm.vin" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="mac地址:" prop="mac">
                            <el-input v-model="addForm.mac" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="状态:" prop="state" style="margin-bottom: 0">
                            <el-select v-model="addForm.auditStatus" :disabled="true">
                                <el-option
                                    v-for="item in auditStatusList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="默认(扣款)账户" prop="isDefault" style="margin-bottom: 0">
                            <el-radio-group v-model="addForm.isDefault">
                                <el-radio
                                    v-for="item in defaultGroup"
                                    :key="item.value"
                                    :label="item.value"
                                >{{ item.label }}
                                </el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="bindClose">取 消</el-button>
        <el-button type="primary" @click="accountBind">确 定</el-button>
      </span>
        </el-dialog>


        <el-dialog class="popup_marker"
            :title="isEdit?'账户编辑':'账户编辑'"
            :visible.sync="editDialog"
            @close="editClose"
            width="38%">

            <el-form size="middle" ref="addForm" :model="addForm"  :rules="rules">
                <el-row span:="24" :gutter="16">
                    <el-col :span="12">

                        <el-form-item label="归属车队:" prop="teamId">
                            <el-select v-model="addForm.teamId">
                                <el-option v-for="item in teamList" :key="item.id" :label="item.teamName"
                                           :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="手机号:" prop="phone">
                            <el-input v-model="addForm.phone" :disabled="true"/>
                            <!--
                            <el-select filterable v-model.trim="addForm.phone" placeholder="请选择手机号" clearable>
                                <el-option v-for="(item,index) in accounts" :key="index" :label="item.phone"
                                           :value="item.phone"></el-option>
                            </el-select>
                            -->


                        </el-form-item>
                        <el-form-item label="车牌号:" prop="carNo">
                            <el-select filterable v-model.trim="id" placeholder="请选择车牌号" clearable>
                                <el-option v-for="(item,index) in vehicles" :key="index"
                                           :label="item.carNo===null?'暂无车牌':item.carNo"
                                           :value="index"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="状态:" prop="state" style="margin-bottom: 0">
                            <el-select v-model="addForm.auditStatus">
                                <el-option
                                    v-for="item in auditStatusList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                ></el-option>
                            </el-select>
                        </el-form-item>

                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="mac地址:" prop="mac">
                            <el-select filterable v-model.trim="id" placeholder="请选择车辆MAC" clearable>
                                <el-option v-for="(item,index) in vehicles" :key="index"
                                           :label="item.mac===null?'暂无mac地址':item.mac"
                                           :value="index"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="姓名:" prop="name">
                            <el-input placeholder="请输入姓名" v-model.trim="addForm.userName" clearable maxlength="30" show-word-limit></el-input>
                        </el-form-item>
                            <el-form-item label="车辆VIN码:" prop="vin">
                                <el-select filterable v-model.trim="id" placeholder="请选择车辆VIN" clearable>
                                    <el-option v-for="(item,index) in vehicles" :key="index" :label="item.carVin"
                                               :value="index"></el-option>
                                </el-select>
                            </el-form-item>
                    </el-col>

                </el-row>
            </el-form>

            <span slot="footer" class="dialog-footer">
        <el-button @click="editClose">取 消</el-button>
        <el-button type="primary" @click="authEdit">确 定</el-button>
      </span>
        </el-dialog>

    </div>
</template>

<script>
import '@/assets/font/font.css';
import {
    customerVehiclePage,
    customerVehicleAudit, addVehicleAuth, batchDeleteCarAuth, bindVehicleAccount,updateVehicleAuth
} from "@/utils/customer";
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

import operatorTeamTreeVue from "@/components/tree/operatorTeamTree";
import {getTeamCars, getTeamList} from "@/utils/vehiclApi";
import {getTeamAccounts} from "@/utils/account";

export default {
    name: "customerVehicle",
    components: {ElImageViewer, operatorTeamTreeVue},
    data() {
        var licence = (rule, value, cb) => {
            //归属地2位+6位车牌号
            const Reg = /^([京津晋冀蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼渝川贵云藏陕甘青宁新][ABCDEFGHJKLMNPQRSTUVWXY][1-9DF][1-9ABCDEFGHJKLMNPQRSTUVWXYZ]\d{3}[1-9DF]|[京津晋冀蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼渝川贵云藏陕甘青宁新][ABCDEFGHJKLMNPQRSTUVWXY][\dABCDEFGHJKLNMxPQRSTUVWXYZ]{6})$/;
            if (value !== "" && value != null) {
                Reg.test(value) ? cb() : cb(new Error("车牌号格式错误"));
            } else {
                cb()
            }
        };
        //定义数据
        return {
            showMore: false,
            isopenShow: true,
            isAudit: false,
            auditShow: false,
            // auditStatusValue: null,
            defaultGroup: [
                {value: 0, label: "否"},
                {value: 1, label: "是"},
            ],
            auditStatusList: [
                {
                    value: 0,
                    label: "待审核",
                },
                {
                    value: 1,
                    label: "通过审核",
                },
                {
                    value: 2,
                    label: "审核拒绝",
                }
            ],
            query: {
                pageNum: 1,
                pageSize: 10,
                resPageNum: 1,
                operatorId: null,
                teamId: null,
                auditStatus: null,
                phone: null,
                vin: null,
                mac: null,
                carNo: null
            },
            vehicles:[],
            tableData: [],
            totalPages: 0,
            addForm: {
                teamId: null,
                userName:"",
                phone: null,
                carNo: null,
                vin: null,
                mac: null,
                auditStatus: 0,
                isDefault: 0
            },
            id: null,
            accounts: [],
            vehicle: [],

            rules: {
                teamId: [
                    {required: true, message: "请选择车队", trigger: "change"},
                ],
                carNo: [{required: false, message: "请选择车牌号", trigger: "blur"}],
                vin: [
                    {required: true, message: "请输入车辆VIN码", trigger: "blur"},
                    // {min: 17, message: '请正确输入17位vin', trigger: 'blur'},
                    // {max: 17, message: '请正确输入17位vin', trigger: 'blur'}
                ],
                mac: [
                    {required: false, message: "请输入mac地址", trigger: "blur"},
                    {min: 17, message: '请正确输入17位mac地址', trigger: 'blur'},
                    {max: 17, message: '请正确输入17位mac地址', trigger: 'blur'}
                ],
                phone: [
                    {required: true, message: "请选择手机号", trigger: "blur"},
                ],
                auditState: [
                    {required: true, message: "请选择审核进度", trigger: "change"},
                ],
            },

            isPage: true,
            addDialog: false,
            editDialog:false,
            bindDialog:false,
            isAdd: true,//是否新增
            isAddCheck: true,//是否新增
            isEdit:false,
            isBind:false,
            selectList: [],
            form: {},
            auditId: null,
            auditStatus: null,
            auditReason: "",
            driverLicenseUrl: "",
            vehicleLicenseUrl: "",
            idFrontUrl: "",
            idBackUrl: "",
            auditReasonShow: false,
            picList: [],
            showViewer: false,
            picArr: [],
            teamList: [],

            //左侧运营商--车队树图
            treeData: {
                operatorTeamOptions: [],  //运营商-车队树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
                operatorTeamNameTree: "",
                operatorTeamIdTree: null,
                defaultExpandAll: false,
            },

        };


    },
    computed: {},
    created() {
        //进入页面自动运行
        this.getPage();
        this.findAllTeamInfo();
    },
    methods: {
        headerStyleEvent({row, column, rowIndex, columnIndex}) {
            if (columnIndex == 1) {
                return 'padding:0px;'
            }
        },
        goodsInfo(row) {
            if (row.columnIndex == 1) {
                return 'goodsInfo'
            }
        },
        isHidden(message) {
            this.isopenShow = message
        },
        //获取所有车队信息
        async findAllTeamInfo() {
            let data = {};
            data.url = "";
            const res = await getTeamList(data)
            this.teamList = res.data.data;
        },

        //获取所有车辆信息
        async getTeamCars() {
            const res = await getTeamCars(this.addForm.teamId);
            this.vehicles = res.data.data;
            console.log("==========this.vehicles===========",this.vehicles)
        },

        async getTeamAccounts() {
            try {
                let res = await getTeamAccounts(this.addForm.teamId);
                this.accounts = res.data.data;
            } catch (error) {
            }
        },


        //分页查询
        async getPage() {
            try {
                let id = this.treeData.operatorTeamIdTree;
                if (id != "" && id != null) {
                    if (id.indexOf("operator") != -1) {
                        id = id.slice(9);
                        // url += "&operatorId=" + Number(id)
                        this.query.operatorId = Number(id);
                        this.query.teamId = "";
                    } else {
                        id = id.slice(5);
                        // url += "&teamId=" + Number(id)
                        this.query.operatorId = "";
                        this.query.teamId = Number(id);
                    }
                }
                let data = {
                    page: this.query.pageNum,
                    size: this.query.pageSize,
                    operatorId: this.query.operatorId,
                    teamId: this.query.teamId,
                    auditStatus: this.query.auditStatus,
                    phone: this.query.phone,
                    vin: this.query.vin,
                    mac: this.query.mac,
                    carNo: this.query.carNo
                };

                data.url = "?page=" + this.query.pageNum + "&size=" + this.query.pageSize;
                let res = await customerVehiclePage(data);
                if (res.data.code !== 200) {
                    return this.$msg.error("获取车辆认证信息失败");
                }
                // let datas = res.data.data.content;
                // this.totalPages = res.data.data.totalElements;
                // this.tableData = datas;

                if (res.data.data.total === 0) {
                    this.tableData = []
                    this.totalPages = 0
                    return this.$msg.info("未查询到记录");
                }

                this.tableData = res.data.data.list;
                this.totalPages = res.data.data.total;

            } catch (error) {
            }
        },

        //审核
        handleAudit(index, row) {
            this.isAddCheck = false
            this.auditShow = true,
                this.isAudit = true,
                this.form = row,
                this.vehicleLicenseUrl = row.vehicleLicense,
                this.driverLicenseUrl = row.driverLicense,
                this.idFrontUrl = row.idFront,
                this.idBackUrl = row.idBack,
                this.auditId = row.id;
            this.picList=[]
            this.picList.push(row.driverLicense),
                this.picList.push(row.vehicleLicense),
                this.picList.push(row.idFront),
                this.picList.push(row.idBack);

            this.auditStatus = null,
                this.auditReason = "";
        },

        //详情
        handleDetail(row) {
            this.picList = []
            this.auditShow = true,
                this.isAudit = false,
                this.form = row,
                this.vehicleLicenseUrl = row.vehicleLicense,
                this.driverLicenseUrl = row.driverLicense,
                this.idFrontUrl = row.idFront,
                this.idBackUrl = row.idBack,
                this.picList=[]
            this.picList.push(row.driverLicense),
                this.picList.push(row.vehicleLicense),
                this.picList.push(row.idFront),
                this.picList.push(row.idBack);

        },

        //驳回原因
        handleReason(index, row) {
            this.auditReasonShow = true,
                this.auditReason = row.auditReason
        },

        handleBind(row){
            this.isBind = true;
            this.bindDialog = true;
            this.addForm = row;
            this.addForm.teamName = row.teamVO.teamName;
        },

        async handleEdit(row){
            let data = row;
            console.log("==========data==========",data)
            const res = await getTeamCars(data.teamId);
            if(res.data.code === 200){
                console.log("==========res==========",res)
                this.vehicles = res.data.data;
                console.log("==========this.vehicles===========",this.vehicles);

                let vin = data.vin;
                let id =  this.vehicles.findIndex(item => item.carVin === vin);
                this.id = id;
                console.log("==========id==========",id)

                this.isEdit = true;
                this.editDialog = true;
                this.addForm = data;
                this.addForm.teamName = data.teamVO.teamName;
                console.log("============handleEdit.data=============",data)
            }else{
                return this.$msg.error("获取车辆信息失败!" + res.data.msg);
            }
        },


        vinChange(item){
            console.log("==========vinChange=========",item);
            let vin = this.addForm.vin;
            let id =  this.vehicles.findIndex(item => item[carVin] === vin);
        },


        balanceFormat(row) {
            var balance = "尚未绑定账户"; //stationType
            if (row.accountVO != null) {
                return row.accountVO.balance
            }
            return balance;
        },

        async accountBind() {
            let data = {
                phone: this.addForm.phone,
                isDefault: this.addForm.isDefault,
                vin: this.addForm.vin
            }
            let res = await bindVehicleAccount(data);
            if (res.data.code != 200) {
                return this.$msg.error("车辆绑定账户失败!" + res.data.msg);
            }else{
                this.isBind = false;
                this.bindDialog = false;
                this.$msg.success("车辆绑定账户成功!");
                this.getPage();
            }
        },

        bindClose() {
            this.isBind = false;
            this.bindDialog = false;
            this.resetAddForm();
        },


        editClose() {
            this.isEdit = false;
            this.editDialog = false;
            this.resetAddForm();
            this.getPage();
        },


        resetAddForm(){
            this.addForm = {
                teamId: null,
                userName:"",
                phone: null,
                carNo: null,
                vin: null,
                mac: null,
                auditStatus: 0,
                isDefault: 0
            };
        },


        async handleSave(row) {
            if (!(this.auditStatus == 1 || this.auditStatus == 2)) {
                return this.$msg.error("请选择是否通过!");
            }

            if (this.auditStatus == 2 && this.auditReason.trim() == "") {
                return this.$msg.error("请输入驳回原因!");
            }

            let data = {id: this.auditId, auditStatus: this.auditStatus, auditReason: this.auditReason};
            let res = await customerVehicleAudit(data);
            this.auditShow = false
            this.isAudit = false
            if (res.data.code != 200) return this.$msg.error("审核失败!" + res.data.msg);
            this.$msg.success("审核成功");
            this.getPage()
        },

        renIndex(index) {
            return (this.query.pageNum - 1) * this.query.pageSize + index + 1
        },

        showBigPic1(index) {
            this.auditShow = false
            this.picArr = []
            if (index < this.picList.length) {
                this.picArr.push(this.picList[index])
            }
            for (let i = 0; i < this.picList.length; i++) {
                if (i != index && this.picList[i] != null && this.picList[i] != '')
                    this.picArr.push(this.picList[i])
            }
            /*for (let i = index; i < this.picList.length; i++) {
                if (this.picList[i] != "")
                this.picArr.push(this.picList[i])
            }*/
            /*if (index > 0){
              for (let i = 0; i < index; i++) {
                if (this.picList[i] != "")
                  this.picArr.push(this.picList[i])
              }
            }*/
            this.showViewer = true
        },

        closeViewer() {
            this.auditShow = true
            this.showViewer = false
        },


        //重置
        restFunction() {
            // this.auditStatusValue = null;
            this.treeData.operatorTeamNameTree = "";
            this.treeData.operatorTeamIdTree = null;
            this.form = {};
            this.auditId = null;
            this.auditStatus = null;
            this.auditReason = "";
            this.driverLicenseUrl = "";
            this.vehicleLicenseUrl = "";
            this.idFrontUrl = "";
            this.idBackUrl = "";
            this.auditReasonShow = false;

            this.isPage = true;
            this.query = {
                pageNum: 1,
                pageSize: 10,
                resPageNum: 1,
                operatorId: "",
                teamId: "",
                customerId: "",
                auditId: "",
                auditStatus: ""
            }
            this.getPage();
        },
        addClose() {
            this.isAdd = true;
            this.addDialog = false;
            this.resetAddForm();
            this.id = null
        },


        async addFunction() {
            this.addForm.carNo = this.vehicles[this.id].carNo
            this.addForm.vin = this.vehicles[this.id].carVin
            this.addForm.mac = this.vehicles[this.id].mac
            let data = JSON.parse(JSON.stringify(this.addForm))
            this.$refs.addForm.validate(async (valid) => {
                if (!valid) return this.$msg.info("车辆审核信息不能为空");
                try {
                    if (this.isAdd) {
                        let res = await addVehicleAuth(data);
                        if (res.data.code != 200) return this.$msg.error("车辆审核添加失败!" + res.data.msg);
                        this.$msg.success("车辆审核信息添加成功");
                        this.addDialog = false;
                        this.getPage();
                    } else {
                        let res = await updateVehicleAuth(data);
                        if (res.data.code != 200) return this.$msg.error("车辆审核信息修改失败" + res.data.msg);
                        this.$msg.success("车辆审核信息修改成功");
                        this.addDialog = false;
                        this.getPage();
                    }
                } catch (e) {
                    console.log(e,'catch')
                }
            });
        },

        async authEdit() {
            this.addForm.carNo = this.vehicles[this.id].carNo
            this.addForm.vin = this.vehicles[this.id].carVin
            this.addForm.mac = this.vehicles[this.id].mac
            let data = this.addForm;
            this.$refs.addForm.validate(async (valid) => {
                if (!valid) return this.$msg.info("车辆审核信息不能为空");
                try {
                    let res = await updateVehicleAuth(data);
                    if (res.data.code != 200) return this.$msg.error("车辆审核信息修改失败" + res.data.msg);
                    this.$msg.success("车辆审核信息修改成功");
                    this.editDialog = false;
                    this.isEdit = false;
                    this.getPage();
                } catch (e) {
                    console.log(e,'catch')
                }
            });
        },



        // 分页事件
        handleSizeChange(val) {
            this.query.pageSize = val;
            if (this.isPage) {
                this.getPage();
            } else {
                this.queryFunction();
            }
        },

        async removeAll() {
            if (this.selectList.length === 0)
                return this.$msg.info("请选择需要删除的审核信息!");
            const confirm = await this.$MessageBox.confirm(
                "此操作将删除选中的审核信息?",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            );
            if (confirm !== "confirm") return;
            const list = this.selectList.map((item) => {
                return item.id;
            });
            const res = await batchDeleteCarAuth(list);
            if (res.data.code !== 200) return this.$msg.error("删除失败");
            this.$msg.success("删除成功");
            this.getPage();
        },


        async queryFunction() {
            try {
                // let data = {
                //     page: this.query.pageNum,
                //     size: this.query.pageSize
                // };

                await this.getPage();
                // if (res.status != 200) return this.$msg.error("查询失败");
                // this.tableData = res.data.data.content;
                // this.totalPages = res.data.data.totalElements;
                this.isPage = false;
            } catch (error) {
            }
        },

        selectionChange(rows) {
            this.selectList = rows;
        },

        addDialogFunction() {
            this.vehicles = [];
            this.addDialog = true;
        },

        handleCurrentChange(val) {
            this.query.pageNum = val;
            if (this.isPage) {
                this.getPage();
            } else {
                this.query.resPageNum = val
                this.queryFunction();
            }
        },

        clean() {
            this.$refs.multipleTable.clearSelection();
        },

        getTreeData(data) {
            this.treeData = data
        },


    },

    watch: {
        'addForm.teamId': {
            handler(newName, oldName) {
                if(this.isBind === true){
                    return;
                }

                //编辑
                if(this.isEdit === true){
                    if(oldName === null || oldName===newName){
                        return;
                    }
                    this.id = null;
                    this.addForm.carNo = null;
                    this.addForm.vin = null;
                    this.addForm.mac = null;
                    this.vehicles = [];
                    this.getTeamCars();
                    return;
                }
                //新增
                if (newName != null && newName != oldName) {
                    this.addForm.carNo = null;
                    this.addForm.phone = null;
                    this.addForm.vin = null;
                    this.addForm.mac = null;
                    this.id = null;

                    this.vehicles = [];
                    this.accounts = [];
                    this.getTeamAccounts();
                    this.getTeamCars();
                }
            },
            deep: true,
            immediate: false
        }

    },
};
</script>

<style lang='less' scoped>
.app-container {
    background: rgba(255, 255, 255, 0);
    padding-left: 0px;
}

//.out-container {
//    width: 100%;
//    /* height: var(--tableHeight);*/
//    height: 660px;
//    overflow: auto;
//}

.inner-container {
    height: var(--tableHeight);
    overflow: hidden;
}

.el-select {
    width: 100%;
}

.vehicle-item {
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-right: 5px;
}

.clean {
    color: #409eff;
    margin-left: 10px;
    cursor: pointer;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}

.avatar-box {
    width: 100%;
    display: flex;
    align-items: flex-start;

    .avatar-item {
        width: 100px;
        height: 100px;
        border: 1px dashed #666;
        position: relative;
        border-radius: 10px;
        margin-right: 10px;

        > img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
        }

        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 30px;
            color: #666;
        }
    }

    .avatar-item:hover {
        border: 1px dashed #409eff;

        .icon {
            color: #409eff;
        }
    }
}


.pagination {
    padding: 10px;
    float: right;
}

.form-item {
    width: 60%;
}
.car_message{
    margin-top: 16px;

    /deep/.el-input__inner{
        height: 36px;
    }
}
.detail-item-left {
    text-align: left;
    //width: 96px;
    color: #585858;
    font-size: 14PX;
}
//.detail-item-left_examine{
//    line-height: 36px;
//    text-align: right;
//    width: 96px;
//    font-size: 14px;
//}
.detail-item{
    color: #2F3133;
    font-size: 14px;
    margin-top: 8px;
}
.detail-item-pic {
    width: 100%;
    height: 100%
}

.states {
    > i {
        display: inline-block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        margin-right: 5px;
        margin-bottom: 3px;
    }
}

//重构新增
.side-search {
    width: 200px;
    background: #FFFFFF;
    border-right: #d3dce6 1px solid;
}

.small-side {
    width: 36px;
    height: 36px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.14);
    border-right: 1px solid #EEE;
}

.mian-content {
    flex: 1;
    background: #F5F7FA;
}
.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.head-section {
    padding: 16px;
    background: #FFFFFF;
    overflow: hidden;
}

.btn-end {
    display: flex;
    justify-content: flex-end;
}

.section-main {
    width: 100%;
    //height: calc(100vh - 24px);
    background: #FFFFFF;
    margin-top: 16px;
    padding: 24px 16px 16px 16px;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.choose-mian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
        display: inline-block;
        margin-left: 16px;
    }
}

.alert-box {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
}

.pagin {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.main-flex {
    display: flex;

}

.left-style {
    padding-left: 43px;
}

.edit-style {
    color: #0D6CE4;
    font-size: 14px;
    cursor: pointer;
}

.more-search {
    display: flex;
    color: #0D6CE4;
    font-size: 14px;
    font-weight: 400;
    line-height: 36px;
    cursor: pointer;
    position: relative;
    left: -15px;
    i{
        line-height: 36px;
    }
}
.btn-color{
    color: #0D6CE4;
}
.head-section{
    /deep/.el-input__inner { //更改input大小
        width: 250px !important;
        height: 36px;
        line-height: 36px;
    }
}
.head-section2 {
    overflow: hidden;
    padding: 0 16px 16px 16px;
    height: 100%;
}



/*popup_marker弹窗标记*/
.popup_marker{

    /*   弹窗左右间距*/
    /deep/.el-dialog__header{
        padding: 24px 24px 0 24px;
    }
    /deep/ .el-dialog__body{
        padding: 24px;
    }
    /deep/.el-dialog__footer{
        padding: 0 24px 24px 24px;
    }
    /deep/.inline_element{
        margin-bottom: 16px;
    }
    /deep/.el-form-item--middle{
        margin-bottom: 16px;
    }

    /*    弹窗文本框标题*/
    /deep/.el-form-item__label{
        line-height: 20px;
        margin-bottom: 8px;
        padding-bottom: 0;
    }
    /*    消除最后一排底部边框*/
    .el-form-item_bottom{
        margin-bottom: 0;
    }
}
</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';
.el-message--error {
    z-index: 100000000 !important;
}
</style>
