//运营管理
import {createAPI} from '@/utils/request'

const urlStr = '/cloud-platform'
const urlStr2 = '/service-business2'


// 轮播图
//根据名称状态分页查询
export const CyclicPicInfoPage = (current, size,data) => createAPI(urlStr + '/cloudPlatform/CyclicPicInfoPage/' + current + "/" + size, 'post',data)
//新增换电站车辆型号信息
export const addCyclicPic = data => createAPI(urlStr + '/cloudPlatform/addCyclicPic', 'post', data)
//修改换电站设备型号信息
export const updateCyclicPic = data => createAPI(urlStr + '/cloudPlatform/updateCyclicPic', 'post', data)
//批量删除换电站设备型号信息
export const deleteCyclicPic = id => createAPI(urlStr + '/cloudPlatform/deleteCyclicPic/' + id, 'delete')


//退款订单分页查询
export const ApSwapRefundPage = (current, size, data)=>createAPI(urlStr+'/cloudPlatform/ApSwapRefundPage/' + current + "/" + size,'post',data)
//退款订单修改
export const updateApSwapRefund = data => createAPI(urlStr + '/cloudPlatform/updateApSwapRefund', 'post', data)
//根据id查询退款信息
export const findApSwapRefundById = id => createAPI(urlStr + '/cloudPlatform/findApSwapRefundById/' + id, 'get')
//根据换电订单查询换电订单信息
export const StationOrderFinishedByOrderNo = id => createAPI(urlStr + '/cloudPlatform/StationOrderFinishedByOrderNo/' + id, 'get')
//根据换电订单号查询退款信息息
export const findApSwapRefundByOrderNo = id => createAPI(urlStr + '/cloudPlatform/findApSwapRefundByOrderNo/' + id, 'get')
//根据车牌号查询车辆信息
export const CarBaseByCarNo = id => createAPI(urlStr + '/cloudPlatform/CarBaseByCarNo/' + id, 'get')


//查询用户爱车信息
export const CarInfoPage = (current, size, data)=>createAPI(urlStr+'/cloudPlatform/CarInfoPage/' + current + "/" + size,'post',data)



//根据车牌号查询客户注册信息
export const getCustomerRegisterByCarNo = id => createAPI(urlStr + '/cloudPlatform/getCustomerRegisterByCarNo/' + id, 'get')
//根据车牌号查询黑白名单信息
export const BlackWhiteListByCarNo = id => createAPI(urlStr + '/cloudPlatform/BlackWhiteListByCarNo/' + id, 'get')
//修改客户审核状态
export const updateCustomerRegister = data => createAPI(urlStr + '/cloudPlatform/updateCustomerRegister', 'post', data)

//支付宝退款
export const aliRefund = data => createAPI(urlStr + '/cloudPlatform/aliRefund', 'post', data)
//微信退款
export const WXRefund = data => createAPI(urlStr + '/cloudPlatform/WXRefund', 'post', data)

//用户消费信息分页查询
export const orderPaymentPage = (current, size, data)=>createAPI(urlStr+'/cloudPlatform/orderPaymentPage/' + current + "/" + size,'post',data)



//APP注册用户分页查询
export const getCustomerRegister = (current, size, data)=>createAPI(urlStr+'/cloudPlatform/CustomerRegisterPage/' + current + "/" + size,'post',data)

//爱车管理分页查询
export const getCarInfo = (current, size, data)=>createAPI(urlStr+'/cloudPlatform/CarInfoPage/' + current + "/" + size,'post',data)



//区域电价计费信息
//根据名称状态分页查询
export const checkareaElecValPage = (current, size, data) => createAPI(urlStr + '/cloudPlatform/areaElecValInfoPage/' + current + "/" + size, 'post', data)
//新增换电站车辆型号信息
export const addareaElecVal = data => createAPI(urlStr + '/cloudPlatform/addAreaElecValInfo', 'post', data)
//修改换电站设备型号信息
export const setareaElecVal = data => createAPI(urlStr + '/cloudPlatform/updateAreaElecValInfo', 'post', data)
//批量删除换电站设备型号信息
export const deleteareaElecVal = id => createAPI(urlStr + '/cloudPlatform/deleteAreaElecValList/' + id, 'delete')

//换电站电价计费信息
//根据名称状态分页查询
export const checkStationElecValList = fAreaCode => createAPI(urlStr + '/cloudPlatform/findStationElecValInfoList/' + fAreaCode, 'get')
//新增换电站车辆型号信息
export const addStationElecVal = data => createAPI(urlStr + '/cloudPlatform/addStationElecValInfo', 'post', data)
//批量添加换电站车辆型号信息
export const addStationElecValList = data => createAPI(urlStr + '/cloudPlatform/addStationElecValList', 'post', data)
//修改换电站设备型号信息
export const setStationElecVal = data => createAPI(urlStr + '/cloudPlatform/updateAreaElecValInfo', 'post', data)
//批量删除换电站设备型号信息
export const deleteStationElecVal = id => createAPI(urlStr + '/cloudPlatform/deleteAreaElecValList/' + id, 'delete')


// 区域换电计费模型信息
// 根据名称状态分页查询
export const getStationBilling = (current, size, data) => createAPI(urlStr + '/cloudPlatform/getStationBillingPage/' + current + "/" + size, 'post', data)
//新增换电站车辆型号信息
export const addStationBilling = data => createAPI(urlStr + '/cloudPlatform/addStationBilling', 'post', data)
//修改换电站设备型号信息
export const setStationBilling = data => createAPI(urlStr + '/cloudPlatform/updateStationBilling', 'post', data)
//批量删除换电站设备型号信息
export const deleteStationBilling = id => createAPI(urlStr + '/cloudPlatform/deleteStationBilling/' + id, 'delete')



//查询充电卡信息
export const checkRecharPage =data=>createAPI(urlStr2+'/cmChargingCard/specificPage','get',data)

//获取充电卡数据
export const getrechargeAll = ()=>createAPI(urlStr2+'/cmChargingCard/findAll','get')

//新增充电卡信息
export const addRecharge = data=>createAPI(urlStr2+'/cmChargingCard/add','post',data)

//修改充电卡信息
export const changeRechar = data=>createAPI(urlStr2+'/cmChargingCard/update','post',data)

//删除充电卡信息
export const removeRechar =id=>createAPI(urlStr2+'/cmChargingCard/batch?idList='+id,'delete')

export const restCard = data =>createAPI(urlStr2+`/cmChargingCard/updDate?id=${data.id}&isAmount=${data.isAmount}&updDate=${data.updDate}`,'post')



//查询客户信息
export const checkClient = data=>createAPI(urlStr2+'/omCustomerBase/findByLoginNoAndName','get',data)

//获取客户信息数据
export const getClientPage = data=>createAPI(urlStr2+'/omCustomerBase/specificPage','get',data)

//新增客户信息
export const newAddClient = data=>createAPI(urlStr2+'/omCustomerBase/add','post',data)

//修改客户信息
export const chanegClient = data=>createAPI(urlStr2+'/omCustomerBase/update','post',data)

//删除客户信息
export const removeClientMsg = id=>createAPI(urlStr2+'/omCustomerBase/batch?idList='+id,'delete')

//查询会员信息
export const checkMember = data=>createAPI(urlStr2+'/omMemberBase/findByloginNo?loginNo='+data,'get')

//查询会员信息
export const restWord = data=>createAPI(urlStr2+'/omCustomerBase/reset?id='+data,'post')



//获取所有会员信息
export const memberAll = ()=>createAPI(urlStr2+'/omMemberBase/findAll','get')

//获取会员信息分页
export const getMemberPage = data=>createAPI(urlStr2+'/omMemberBase/specificPage','get',data)

//新增会员信息
export const addmember =data=>createAPI(urlStr2+'/omMemberBase/add','post',data)

//修改会员信息
export const changeMember = data=>createAPI(urlStr2+'/omMemberBase/update','post',data)

//会员信息删除
export const removeMember = id=>createAPI(urlStr2+'/omMemberBase/batch?idList='+id,'delete')



//查询订单信息
export const getOrderPage =data=>createAPI(urlStr2+'/cmOrderBaseInfo/specificPage','get',data)

//获取所有订单信息
export const getOrderAll =()=>createAPI(urlStr2+'/cmOrderBaseInfo/findAll','get')

//新增订单信息
export const addOrder =data=>createAPI(urlStr2+'/cmOrderBaseInfo/add','post',data)

//修改订单信息
export const changeOrder =data=>createAPI(urlStr2+'/cmOrderBaseInfo/update','post',data)

//删除订单信息
export const removeOrder =id=>createAPI(urlStr2+'/cmOrderBaseInfo/batch?idList='+id,'delete')

//换电站订单完成记录
export const getStationNoOrder = ()=>createAPI(urlStr2+'/cmStationOrderFinishedLogInfo/queryByDate','get')

//用户订单完成记录
export const getUserOrder = ()=>createAPI(urlStr2+'/cmCustomerOrderFinishedLogInfo/queryByDate','get')

//根据车牌查询黑名单
export const carCheckBlack = data=>createAPI(urlStr2+'/cmBlackWhiteList/queryByArea','get',data)

//获取所有黑名单信息
export const getBlackAll = ()=>createAPI(urlStr2+"/cmBlackWhiteList/findAll",'get')

//新增黑名单信息
export const newAddBlack =data=>createAPI(urlStr2+'/cmBlackWhiteList/add','post',data)

//修改黑名单信息
export const compileBlack =data=>createAPI(urlStr2+'/cmBlackWhiteList/update','post',data)

//删除黑名单信息
export const removeBlack =id=>createAPI(urlStr2+'/cmBlackWhiteList/delete/'+id,'delete')
