<template>
  <el-card>
    <el-row>
      <el-col :span="4">
        <el-select v-model="stationValue" filterable placeholder="请选择换电站">
          <el-option
            v-for="item in stationList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select v-model.trim="listType" placeholder="请选择名单性质">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select v-model.trim="carValue" filterable placeholder="请选择车牌号">
          <el-option
            v-for="item in carList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>

      <el-col :span="6">
        <el-button @click="checkoutBlack" type="primary">查询</el-button>
        <el-button @click="resFuntion">重置</el-button>
      </el-col>
    </el-row>

    <el-row>
      <el-button @click="isblack =true" size="small" class="el-icon-plus" type="primary"> 新增</el-button>
      <el-button @click="removeAll" size="small" class="el-icon-delete" type="danger"> 选中删除</el-button>
    </el-row>

    <el-row>
      <el-alert show-icon type="info">
        <span slot="title">
          已选择{{batchNum.length}}项
           <span @click="cleanList" style="margin-left:10px;color:#409eff;cursor:pointer;">清空</span>
        </span>
      </el-alert>
    </el-row>
    <el-row>
      <el-table ref="blackTable" @selection-change="batchList" :data="tableData" style="width: 100%" height="300">
        <el-table-column type="selection" :index="renIndex" width="40"></el-table-column>
        <el-table-column label="编号" type="index" min-width="50"></el-table-column>
        <el-table-column label="换电站编码" prop="stationNo" min-width="130"></el-table-column>
        <el-table-column label="换电站名称" prop="stationName" min-width="130"></el-table-column>
        <el-table-column label="名单编号" prop="bwNo" min-width="230"></el-table-column>
        <el-table-column label="车牌号" prop="carNo" min-width="80"></el-table-column>
<!--        <el-table-column label="车辆VIN码" prop="carVin" min-width="80"></el-table-column>-->
        <el-table-column label="名单性质" prop="listType" min-width="80">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.listType === 1" type="success">白名单</el-tag>
            <el-tag v-else-if="scope.row.listType === 2" type="danger">黑名单</el-tag>
            <el-tag v-else type="primary">其它</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="注册时间" prop="regTime" min-width="110" :formatter="$dateFormat"></el-table-column>
        <el-table-column label="创建时间" prop="createTime" min-width="110" :formatter="$dateFormat"></el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button
              @click="compile(scope.row)"
              size="small"
              type="primary"
              class="el-icon-edit"
            >编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNum"
        :page-sizes="[20, 25, 30]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalPage"
      ></el-pagination>
    </el-row>
    <!-- 新增和修改 -->
    <el-dialog
      :title="changeBlack?'新增黑白名单信息':'修改黑白名单信息'"
      :visible.sync="isblack"
      width="30%"
      @close="close"
    >
      <el-form size="small" ref="blackFrom" :rules="blackRules" :model="blackFrom" label-width="100px">
        <el-row>
          <el-col :span="18">
            <el-form-item label="换电站编码:" prop="stationNo">
              <el-select v-model="blackFrom.stationNo" filterable placeholder="请选择换电站名称">
                <el-option
                  v-for="item in stationList1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="名单性质:" prop="listType">
              <el-select v-model="blackFrom.listType" placeholder="请选择名单性质">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="车牌号:" prop="carNo">
              <el-select v-model="blackFrom.carNo" filterable placeholder="请选择车牌号">
                <el-option
                  v-for="item in carList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
<!--              <el-input @change="changeCarNo(blackFrom.carNo)" v-model="blackFrom.carNo"></el-input>-->
            </el-form-item>
<!--            <el-form-item label="车辆VIN码:" prop="carVin">-->
<!--              <el-input v-model="blackFrom.carVin"></el-input>-->
<!--            </el-form-item>-->
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isblack = false">取 消</el-button>
        <el-button type="primary" @click="addORsetblack">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
  import {
    newAddBlack,
    compileBlack,
    carCheckBlack,
    removeBlack
  } from "@/utils/operation2";
  import {getForceList, regionCodeSation} from '@/utils/station'
  import { getCarBaseAll } from '@/utils/vehiclApi'
  import {regionData} from "element-china-area-data"
  import * as myStore from "@/utils/stroage";
  import moment from 'moment'

  export default {
    name: "blackName",
    data() {
      var licence = (rule, value, cb) => {
        const Reg = /^([京津晋冀蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼渝川贵云藏陕甘青宁新][ABCDEFGHJKLMNPQRSTUVWXY][1-9DF][1-9ABCDEFGHJKLMNPQRSTUVWXYZ]\d{3}[1-9DF]|[京津晋冀蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼渝川贵云藏陕甘青宁新][ABCDEFGHJKLMNPQRSTUVWXY][\dABCDEFGHJKLNMxPQRSTUVWXYZ]{5})$/
        if (value !== '') {
          Reg.test(value) ? cb() : cb(new Error('车牌号格式错误'))
        } else {
          cb('请输入车牌号')
        }
      }

      return {
        carValue: '',
        cascaderValue: [],
        stationType: '',
        stationValue: '',
        listType: '',
        query: {
          pageNum: 1,
          pageSize: 20,
          resPageNum: 1
        },
        totalPage: 0,
        optionsList: regionData,
        stationTypeList: [
          {
            value: '0',
            label: "A类型",
          },
          {
            value: '1',
            label: "B类型",
          },
        ],
        stationList: [],
        stationList1: [],
        tableData: [],
        isblack: false,
        changeBlack: true,
        isPage: true,
        blackFrom: {
          carNo: "",
          carVin: "",
          listType: null,
          stationNo: "",
        },
        blackRules: {
          carNo: [{required: true, validator: licence, trigger: "blur"}],
          // carVin: [
          //   {required: true, message: "请输入车辆VIN码", trigger: "blur"},
          // ],
          listType: [
            {required: true, message: "请选择名单性质", trigger: "change"},
          ],
          stationNo: [
            {required: true, message: "请输入换电站编码", trigger: "blur"},
          ],
        },
        options: [
          {
            value: 1,
            label: "白名单",
          },
          {
            value: 2,
            label: "黑名单",
          },
          {
            value: 3,
            label: "其它",
          },
        ],
        batchNum: [],
        carList:[],
        stationListAll:[],
      };
    },
    computed: {
      cascader() {
        // let rengin = null
        // if (this.cascaderValue.length === 1) {
        //   rengin = this.cascaderValue[0]
        // } else if (this.cascaderValue.length === 2) {
        //   rengin = this.cascaderValue[1]
        // } else if (this.cascaderValue.length === 3) {
        //   rengin = this.cascaderValue[2]
        // } else {
        //   rengin = ''
        // }
        // return rengin
        return this.cascaderValue.join(',')
      }
    },
    created() {
      this.getBlackInit();
      this.getStation()
      this.getVar()
    },
    methods: {
      async getVar(){
        try {
          const res = await getCarBaseAll()
          if(res.status !== 200)return this.$msg.error('获取车牌信息失败')
          this.carList = res.data.data.map(item=>{
            return {
              value:item.carNo,
              label:item.carNo
            }
          })
        } catch (error) {

        }
      },
      async getBlackInit() {
        const data = {
          page: this.query.pageNum,
          size: this.query.pageSize
        }
        const res = await carCheckBlack(data);
        console.log(res)
        if (res.status !== 200) return this.$msg.error("获取黑名单失败!");
        let newList = res.data.data
        // this.tableData = newList.content
        this.tableData =  this.getRender(newList.content)
        this.totalPage = newList.totalElements
      },
      getRender(val){
        return val.map(item=>{
          let i = this.stationListAll.findIndex(c=>c.value === item.stationNo)
          i !== -1 ?item.stationName = this.stationListAll[i].label:item.stationNam = ''
          return item
        })
      },
      async getStation() {
        const res = await getForceList()
        let newList = res.data.data
        this.stationList = newList.map(item => {
          return {
            value: item.stationNo,
            label: item.stationName
          }
        })
        this.stationList1 = this.stationList
        this.stationListAll = JSON.parse(JSON.stringify(this.stationList))
        this.getBlackInit()
      },
      renIndex(index) {
        return (this.query.pageNum - 1) * this.query.pageSize + index + 1
      },
      batchList(list) {
        this.batchNum = list.map((item) => {
          return item.bwNo;
        });
      },
      //修改填充信息
      compile(list) {
        this.isblack = true;
        this.changeBlack = false;
        this.blackFrom = JSON.parse(JSON.stringify(list));
      },
      //新增与修改黑名单
      addORsetblack() {
        try {
          this.$refs.blackFrom.validate(async (valid) => {
            if (!valid) return this.$msg.error("黑名单信息不能为空!");
            const data = JSON.parse(JSON.stringify(this.blackFrom))
            data.regTime = moment().format('YYYY-MM-DD HH:mm:ss')
            if (this.changeBlack) {
              const res = await newAddBlack(data);
              if (res.status !== 200) return this.$msg.error("新增黑白名单信息失败!");
              this.$msg.success("新增黑白名单信息成功!");
              this.isblack = false;
              this.getBlackInit();
            } else {
              const res = await compileBlack(data);
              if (res.status !== 200) return this.$msg.error("修改黑白名单信息失败!");
              this.$msg.success("修改黑白名单信息成功!");
              this.isblack = false;
              this.getBlackInit();
            }
          });
        } catch (error) {
          console.log(error);
        }
      },
      close() {
        this.$refs.blackFrom.resetFields();
        this.blackFrom = {}
        this.changeBlack = true;
      },
      //根据车牌查询
      async checkoutBlack() {
        if (this.cascader === '' && this.carValue === '' && this.stationValue === '' && this.listType === '' && this.stationType === '') return
        try {
          const data = {
            regionCode: this.cascader,
            carNo: this.carValue,
            stationNo: this.stationValue,
            listType: this.listType,
            stationType: this.stationType
          }
          this.storage('blacklist', data)
          data.page = this.query.resPageNum
          data.size = this.query.pageSize
          const res = await carCheckBlack(data);
          if (res.status !== 200) return this.$msg.error("查询失败");
          let newList = res.data.data
          // this.tableData = newList.content
          this.tableData =  this.getRender(newList.content)
          this.totalPage = newList.totalElements
          this.isPage = false
        } catch (error) {

        }

      },
      async changeCascader() {
        this.carValue = ''
        this.stationValue = ''
        this.listType = ''
        const res = await regionCodeSation({regionCode: this.cascader, stationType: null})
        if (res.status !== 200) return this.$msg.error('换电站查询失败!')
        if (res.data.data.length !== 0) {
          this.stationList = res.data.data.map(item => {
            return {
              value: item.stationNo,
              label: item.stationName
            }
          })
        } else {
          this.stationList = []
        }
        if (this.stationList.length !== 0) {
          const newList = JSON.parse(getItem('blacklist'))
          if (newList && newList[1].regionCode === this.cascader && newList[0].index > 3) {
            this.cascaderValue = [newList[1].regionCode]
            this.carValue = newList[1].carNo
            this.stationValue = newList[1].stationNo
            this.listType = newList[1].listType
            this.stationType = newList[1].stationType
          }
        }
      },
      async changeSelect() {
        this.carValue = ''
        this.stationValue = ''
        this.listType = ''
        const res = await regionCodeSation({regionCode: this.cascader, stationType: null})
        if (res.status !== 200) return this.$msg.error('换电站查询失败!')
        if (res.data.data.length !== 0) {
          this.stationList = res.data.data.map(item => {
            return {
              value: item.stationNo,
              label: item.stationName
            }
          })
        } else {
          this.stationList = []
        }
      },
      cleanList() {
        this.$refs.blackTable.clearSelection()
      },
      resFuntion() {
        this.cascaderValue = ''
        this.carValue = ''
        this.stationValue = ''
        this.listType = ''
        this.stationType = ''
        this.isPage = true
        this.query = {
          pageNum: 1,
          pageSize: 20,
          resPageNum: 1
        }
        // this.getBlackInit()
        this.getStation()
      },

      // async removeAll() {
      //   if (this.batchNum.length === 0) return this.$msg.info('请选择需要删除的项')
      //   const config = await this.$MessageBox.confirm(
      //     "此操作将永久删除选中项, 是否继续?",
      //     "提示",
      //     {
      //       confirmButtonText: "确定",
      //       cancelButtonText: "取消",
      //       type: "warning"
      //     }
      //   ).catch(e => e);
      //   if (config !== "confirm") return;
      //   const list = this.batchNum;
      //   const res = await removeBlack(list);
      //   console.log(res)
      //   // this.$refs.blackTable.clearSelection()
      //   if (this.query.pageNum > 1 && this.tableData.length === 0) {
      //     this.query.pageNum = this.query.pageNum - 1
      //   }
      //
      // },
      //批量删除
      async removeAll() {
        console.log(this.batchNum)
        if (this.batchNum.length === 0)
          return this.$msg.info("请选择需要删除的项");
        const config = await this.$MessageBox
          .confirm("此操作将永久删除选中项, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .catch((e) => e);
        if (config !== "confirm") return;
        try {
          const list = this.batchNum;
          // const list = this.batchNum.join(',')

          for (let i = 0; i <list.length ; i++) {
            const id=list[i]
            console.log(id)
            const res = await removeBlack(id);
            if (res.status !== 200) return this.$msg.error("删除失败");
          }
          this.$msg.success("删除成功");
          this.getStation()
        } catch (e) {
          console.log(e);
        }
      },
      changeCarNo(val) {
        this.blackFrom.carNo = val.toUpperCase()
      },
      handleSizeChange(val) {
        this.query.pageSize = val;
        if (this.isPage) {
          this.getBlackInit();
        } else {
          this.checkoutBlack()
        }
      },
      handleCurrentChange(val) {
        this.query.pageNum = val;
        if (this.isPage) {
          this.getBlackInit();
        } else {
          this.query.resPageNum = val
          this.checkoutBlack()
        }
      }

    },
  };
</script>

<style lang='less' scoped>
  .el-row {
    margin: 15px 0;

    .el-col {
      margin-right: 20px;
    }
  }

  .el-select, .el-cascader {
    width: 100%;
  }
</style>
