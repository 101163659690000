//营收数据管理
import { createAPI } from '@/utils/request'
//const urlStr = '/springcloud-service-provider03'
const urlStr = '/service-business2'

// 换电站分时用电统计
//换电站分时用电统计查询所有
// export const searchEleAll = ()=>createAPI(urlStr+'/stationHourEnergyValue/findAll','get')
//换电站分时用电统计查询
export const searchEleAll = (data)=>createAPI(urlStr+'/stationHourEnergyValue/findAllByRegionCode','get',data)

//换电站分时用电统计根据时间段查询
// export const searchEleTime = (data)=>createAPI(urlStr+'/stationHourEnergyValue/findByStarttimeAndEndtime','get',data)
//
// //换电站分时用电统计根据station查询
// export const searchEleStation = (data)=>createAPI(urlStr+'/stationHourEnergyValue/findByStationNo','get',data)


//换电站每天用电统计
// -------------- 换电站每天用电统计根据查询所有-----------------
export const searchDayAll = ()=>createAPI(urlStr+'/rdStationDayEnergyValue/findAll','get')

// -------------------------------换电站每天用电统计根据时间段查询------------------------------------
export const getStationDayPage = (data)=>createAPI(urlStr+'/rdStationDayEnergyValue/findBystationNo?stationNo='+data,'get')

// -------------------------------换电站每天用电统计根据stationNo查询-----------------------
export const searchDayStation = (data)=>createAPI(urlStr+'/rdStationDayEnergyValue/timeZoneAndId','get',data)



//换电站每月数量统计
// -------------- 换电站每月用电统计根据查询所有-----------------
export const searchStation = (data)=>createAPI(urlStr+'/rdStationMonthEnergyValue/countCensus?stationNo='+data,'get')

//换电站每月用电统计
export const getStationMonthPage = data =>createAPI(urlStr+'/rdStationMonthEnergyValue/findByStarttimeAndEndtime','get',data)


// -------------------------------换电站每月用电统计根据stationNo查询-----------------------
export const searchMonStation = (data)=>createAPI(urlStr+'/rdStationMonthEnergyValue/timeZoneAndId','get',data)


//场站用电计费

// 换电站分时用电计费
// ---------换电站分时用电计费查询所有-------------
// export const searchTimeAmountAll = ()=>createAPI(urlStr+'/rdStationHourElecAmountResult/findAll','get')
// export const searchTimeAmountAll = ()=>createAPI(urlStr+'/rdStationHourElecAmountResult/findAllByRegionCode','get')
export const searchTimeAmountAll = (data)=>createAPI(urlStr+'/rdStationHourElecAmountResult/findAllByRegionCode','get',data)
// --------------换电站分时用电计费根据时间段查询---------------------
export const searchAmountTime = (data)=>createAPI(urlStr+'/rdStationHourElecAmountResult/findBystarttimeAndendtime','get',data)

// --------------换电站分时用电计费根据station查询-----------------------------------------
// export const searchAmountTimeStation = (data)=>createAPI(urlStr+'/rdStationHourElecAmountResult/findBystation','get',data)

//换电站每天用电计费
// -------------- 换电站每天用电计费根据查询所有-----------------
export const searchDayAmountAll = ()=>createAPI(urlStr+'/rdStationDayElecAmount/finAll','get')

// -------------------------------换电站每天用电计费根据时间段查询------------------------------------
export const searchAmountDay = (data)=>createAPI(urlStr+'/rdStationDayElecAmount/findBystarttimeAndendtime','get',data)

// -------------------------------换电站每天用电计费根据stationNo查询-----------------------
export const searchAmountDayStation = (data)=>createAPI(urlStr+'/rdStationDayElecAmount/timeZoneAndId','get',data)



//换电站每月用电计费
// -------------- 换电站每月用电计费根据查询所有-----------------
export const getMonthAmount = data=>createAPI(urlStr+'/rdStationMonthElecAmountResult/timeZoneAndId','get',data)

//换电站用电计费统计
export const getMonthStatis = data=>createAPI(urlStr+'/rdStationMonthElecAmountResult/countCensus','get',data)


//区域用电计费
//区域每天根据时间查询用电计费
export const getArerElectroDaysTime = data=>createAPI(urlStr+'/rdAreaDayElecAmountResult/findByStarttimeAndEndtime','get',data)

//区域每天根据arerCode和时间查询用电计费
export const  searchArerDay = data=>createAPI(urlStr+'/rdAreaDayElecAmountResult/timeZoneAndId','get',data)

//区域每月根据时间查询用电计费
export const getArerElectroMonthTime =data=>createAPI(urlStr+`/rdAreaMonthElecAmountResult/findBystarttimeAndendtime`,'get',data)

//根据区域查询
export const searchArerCode = data=>createAPI(urlStr+'/rdAreaMonthElecAmountResult/finByaeaCode','get',data)

//区域每月根据arerCode和时间查询用电计费
export const  searchArerMonth = data=>createAPI(urlStr+'/rdAreaMonthElecAmountResult/timeZoneAndId','get',data)

//区域用电计费统计
export const getArerCodeStatis = data=>createAPI(urlStr+'/rdAreaMonthElecAmountResult/countCensus?areaCode='+data,'get')

//换电站营收结果
//换电站每天根据时间查询营收结果
export const getStatNoDaysTime =data=>createAPI(urlStr+`/rdStationDayAmountResult/findBystarttimeAndentime`,'get',data)

//换电站每天根据station和时间查询营收结果
export const searchDayRevenue = data=>createAPI(urlStr+'/rdStationDayAmountResult/timeZoneAndId','get',data)

//换电站每月所有场站营收营收结果
export const getStatNoMonthTime =data=>createAPI(urlStr+`/rdStationMonthAmountResult/findByStarttimeAndEndtime`,'get',data)

//换电站每月根据station和时间查询营收结果
export const searchMonthRevenue = data=>createAPI(urlStr+'/rdStationMonthAmountResult/timeZoneAndId','get',data)

//根据换电站查询营收
export const searchStationRevenue = data=>createAPI(urlStr+'/rdStationMonthAmountResult/findByStationNo','get',data)

//换电站营收结果统计
export const getStationStatis = data=>createAPI(urlStr+'/rdStationMonthAmountResult/countCensus?stationNo='+data,'get')

//区域营收结果
//区域每天根据时间查询营收结果
export const getArerDaysTime = data=>createAPI(urlStr+`/rdAreadayAmountResult/findByStartTimeAndEndTime`,'get',data)

//区域每天根据arerCode和时间查询营收结果
export const searchDayArerRevenue = data =>createAPI(urlStr+'/rdAreadayAmountResult/timeZoneAndId','get',data)

//区域获每月根据时间查询营收结果
export const getArerMonthTime =data=>createAPI(urlStr+`/edAreaMonthAmountResult/findBystarttimeAndentime`,'get',data)

//区域每月根据arerCode和时间查询营收结果
export const searchMonthArerRevenue = data =>createAPI(urlStr+'/edAreaMonthAmountResult/timeZoneAndId','get',data)

//根据区域查询营收
export const searchArerCodeRevenue = data=>createAPI(urlStr+'/edAreaMonthAmountResult/findByareacode','get',data)


//区域营收结果统计
export const getArerStatis = data=>createAPI(urlStr+'/edAreaMonthAmountResult/countCensus?areaCode='+data,'get')

//营收结果分时统计
export const getAmountCount = data =>createAPI(urlStr+'/apOrderPaymentLog/findAllByRegionCode','get',data)







