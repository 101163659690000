<template>
    <div class="app-container">
        <el-row class="main-flex">
            <el-col :span="3" class="side-search" v-if="isopenShow">
                <operatorTreeVue @emitToParent='getTreeData' @isShow="isHidden"></operatorTreeVue>
            </el-col>

            <el-col v-if="!isopenShow" class="small-side">
                <div class="change-in" @click="isopenShow = !  isopenShow "></div>
            </el-col>

            <!-- 搜索区域 -->
            <el-col :span="21" class="mian-content">
                <!--                <el-col :span="5">-->
                <!--                    <el-input placeholder="请从左侧选择运营商" v-model.trim="treeData.operatorNameTree"-->
                <!--                              disabled></el-input>-->
                <!--                </el-col>-->
                <div class="head-section">
                    <el-col :span="6">
                        <el-form :inline="true">
                            <el-form-item label="车队名称：">
                                <el-input placeholder="请输入车队名称" v-model.trim="vehicleTeamNameValue"
                                          clearable></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="7">
                        <el-form :inline="true" class="left-style">
                            <el-form-item label="车队编码：">
                                <el-input placeholder="请输入车队编码" v-model.trim="vehicleTeamCodeValue"
                                          clearable></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="7">
                        <el-form :inline="true" class="left-style">
                            <el-form-item label="绑定账号：">
                                <el-input placeholder="请输入绑定账号" v-model.trim="phoneValue" clearable></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="4">
                        <div class="btn-end">
                            <span class="btn-rest">
                                <el-button @click="restFunction" plain>
                                重置
                            </el-button>
                            </span>
                            <span class="btn-submit">
                                <el-button type="primary" @click="getSearch" plain>查询
                            </el-button>
                            </span>
                        </div>
                    </el-col>
                </div>

                <!--  表格部分-->
                <div class="section-main">
                    <el-col class="choose-mian">
                        <div class="section-title">
                            车队列表
                        </div>
                        <div>
                            <!--选择项栏-->
                            <span class="alert-box">
                          已选择{{ selectList.length }}项
                            <span @click="clean" style="margin-left:8px;color:#0D6CE4;cursor:pointer;">清空</span>
                        </span>
                            <span class="pain-btn">
                                <el-button @click="removeAll"
                                           plain
                                           v-hasCode="['base:team:delete']">删除
                                </el-button>
                            </span>
                            <span class="add-btn">
                               <el-button @click="addDialogFunction" plain type="primary"
                                          v-hasCode="['base:team:add']">新增
                                </el-button>
                            </span>
                        </div>
                    </el-col>
                    <el-col :span="24">
                        <el-row class="table-box">
                            <el-table
                                :data="tableData"
                                ref="multipleTable"
                                @selection-change="selectionChange"
                                :header-cell-style="headerStyleEvent" :cell-class-name="goodsInfo">

                                <el-table-column align="center" type="selection"></el-table-column>
                                <el-table-column align="center" type="index" :index="renIndex" label="编号"
                                                 min-width="50"></el-table-column>
                                <el-table-column align="center" label="归属运营商" prop="operatorName" min-width="150"
                                                 :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column align="center" prop="teamName" label="车队名称" min-width="150"
                                                 :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column align="center" prop="teamCode" label="车队编码" min-width="150"
                                                 :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column align="center" prop="accountVO.phone" label="绑定账户" min-width="150"
                                                 :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column align="center" prop="account" label="账户余额" :formatter="balanceFormat"
                                                 min-width="120"></el-table-column>
                                <el-table-column align="center" prop="principal" label="负责人" min-width="100"></el-table-column>
                                <el-table-column align="center" prop="contactWay" label="联系方式" min-width="140"></el-table-column>
                                <el-table-column align="center" prop="createTime" label="创建时间" min-width="110"
                                                 :formatter="$dateFormat"></el-table-column>
                                <el-table-column align="center" label="操作" width="100" fixed="right">
                                    <template slot-scope="scope">
                                        <span class="edit-style"
                                            @click="handleEdit(scope.row)"
                                            v-hasCode="['base:team:update']">编辑
                                        </span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-row>
                    </el-col>
                    <el-col :span="24">
                        <el-row class="pagin">
                            <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="query.pageNum"
                                :page-sizes="[10,30, 50, 100]"
                                :page-size="query.pageSize"
                                layout="total, sizes, prev, pager, next"
                                :total="totalPages"
                            ></el-pagination>
                        </el-row>
                    </el-col>
                </div>


            </el-col>


        </el-row>

        <el-dialog class="popup_marker"
            :title="isAdd?'新增车队信息':'修改车队信息'"
            :visible.sync="addDialog"
            @close="close"
            width="38%"
        >
            <el-form size="middle" ref="addForm" :model="addForm"  :rules="rules">
                <el-row :span="24" :gutter="16">
                    <el-col :span="12">
                        <el-form-item label="归属运营商" prop="operatorId">
                            <TreeSelect v-model="addForm.operatorId" :options="treeData.operatorOptions"
                                        placeholder="请选择运营商"/>
                            <!-- <TreeSelect v-model="addForm.operatorId" :options="operatorOptions" placeholder="选择上级目录" @select="change(node)"/>-->
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="车队名称:" prop="teamName" >
                            <el-input v-model="addForm.teamName" show-word-limit maxlength="30"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                    <el-row :span="24" :gutter="16">
                    <el-col :span="12">
                        <el-form-item label="车队编码:" prop="teamCode">
                            <el-input v-model="addForm.teamCode" show-word-limit maxlength="30"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="负责人:" prop="principal">
                            <el-input v-model="addForm.principal" show-word-limit maxlength="30"></el-input>
                        </el-form-item>
                    </el-col>
                    </el-row>
                <el-row :span="24" :gutter="16">
                    <el-col :span="12">
                        <el-form-item label="联系方式:" prop="contactWay" class="el-form-item_bottom">
                            <el-input v-model="addForm.contactWay" maxlength="11" show-word-limit></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="创建日期:" prop="createTime" class="el-form-item_bottom">
                            <template>
                                <div class="block">
                                    <el-date-picker
                                        type="date"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        v-model="addForm.createTime"
                                        placeholder="选择日期时间"
                                    ></el-date-picker>
                                </div>
                            </template>
                        </el-form-item>
                    </el-col>

                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="addDialog = false">取 消</el-button>
        <el-button type="primary" @click="addFunction">确 定</el-button>
      </span>
        </el-dialog>

    </div>
</template>

<script>
import '@/assets/font/font.css';
import {addTeam, updateTeam, delTeam, teamPage} from "@/utils/vehiclApi";
import {updateAccount} from "@/utils/account";
import operatorTreeVue from "@/components/tree/operatorTree"
import TreeSelect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {operatorTree} from "@/utils/operatorApi";

export default {
    name: "vehicleTeam",
    components: {TreeSelect, operatorTreeVue},
    data() {
        //定义数据
        return {
            isopenShow: true,
            vehicleTeamNameValue: "",
            vehicleTeamCodeValue: "",
            phoneValue: null,
            query: {
                pageNum: 1,
                pageSize: 10
            },
            tableData: [],
            totalPages: 0,

            addForm: {
                operatorId: null,
                teamName: "",
                teamCode: "",
                principal: "",
                contactWay: null,
                createTime: null,
            },

            accountForm: {
                teamId: null,
                phone: null
            },

            rules: {
                operatorId: [
                    {required: true, message: "选择归属运营商", trigger: "blur"},
                ],
                teamName: [
                    {required: true, message: "请输入车队名称", trigger: "blur"},
                ],
                contactWay: [
                    {required: false, validator: mobileReg, trigger: 'blur'}
                ],
            },


            addDialog: false,
            isAdd: true,//是否新增

            accountBindDialog: false,
            isAccountBindAdd: true,//是否新增

            selectList: [],

            treeData: {
                operatorNameTree: "",
                operatorIdTree: null,
                operatorOptions: undefined,  //运营商树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
            },

        };

        var mobileReg = (rule, value, cb) => {
            const reg = /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/;
            if (!value) cb(new Error("手机号不能为空"));
            if (!reg.test(value)) {
                return cb(new Error("手机号输入错误"));
            }
            return cb();
        };

    },
    computed: {},
    created() {
        //进入页面自动运行
        //this.findAllCarTypeInfo()
        this.getOperatorTree();
        this.getPage();
    },
    methods: {
        headerStyleEvent({ row, column, rowIndex, columnIndex }) {
            if (columnIndex == 1) {
                return 'padding:0px;'
            }
        },
        goodsInfo(row){
            if (row.columnIndex == 1) {
                return 'goodsInfo'
            }
        },
        isHidden(message) {
            this.isopenShow = message
        },
        //分页查询
        async getPage() {
            try {
                let data = {page: this.query.pageNum, size: this.query.pageSize};
                var url = "";
                if (this.vehicleTeamNameValue !== "" && this.vehicleTeamNameValue !== null) {
                    url += "&teamName=" + this.vehicleTeamNameValue
                }
                if (this.vehicleTeamCodeValue !== "" && this.vehicleTeamCodeValue !== null) {
                    url += "&teamCode=" + this.vehicleTeamCodeValue
                }
                if (this.phoneValue !== "" && this.phoneValue !== null) {
                    url += "&phone=" + this.phoneValue
                }
                if (this.treeData.operatorNameTree !== "" && this.treeData.operatorNameTree !== null) {
                    url += "&operatorId=" + this.treeData.operatorIdTree
                } else if (this.treeData.operatorOptions.length > 0) {
                    url += "&operatorId=" + this.treeData.operatorOptions[0].id
                }
                data.url = url;

                let res = await teamPage(data);
                if (res.data.code !== 200) return tis.$msg.error("获取车队信息失败");
                let datas = res.data.data.list;
                this.totalPages = res.data.data.total;
                this.tableData = datas;
            } catch (error) {
            }
        },

        getSearch() {
            this.query.pageNum = 1;
            this.getPage();
        },

        //新增与修改
        addFunction() {
            this.$refs.addForm.validate(async (valid) => {
                if (!valid) return this.$msg.info("车队信息不能为空");
                try {
                    let data = JSON.parse(JSON.stringify(this.addForm))
                    if (this.isAdd) {
                        let res = await addTeam(data);
                        if (res.data.code != 200) return this.$msg.error("车队添加失败!" + res.data.msg);
                        this.$msg.success("车队信息添加成功");
                        this.addDialog = false;
                        this.getPage();
                    } else {
                        let res = await updateTeam(data);
                        if (res.data.code != 200) return this.$msg.error("车队信息修改失败");
                        this.$msg.success("车队信息修改成功");
                        this.addDialog = false;
                        this.getPage();
                    }
                } catch (e) {
                }
            });
        },

        closeAccount() {
            this.accountBindDialog = false;
            this.accountForm = {
                teamId: null,
                phone: null
            };
        },

        //新增与修改账户绑定
        async accountBind() {
            try {
                let data = JSON.parse(JSON.stringify(this.accountForm));
                let res = await updateAccount(data);
                if (res.data.code != 200) return this.$msg.error("账户绑定失败:" + res.data.msg);
                this.$msg.success("账户绑定修改成功");
                this.accountBindDialog = false;
                this.getPage();
            } catch (e) {
            }
        },

        renIndex(index) {
            return (this.query.pageNum - 1) * this.query.pageSize + index + 1
        },

        balanceFormat(row) {
            var balance = "尚未开通账户"; //stationType
            if (row.accountVO != null) {
                return row.accountVO.balance
            }
            return balance;
        },

        //修改信息填充
        handleEdit(row) {
            this.addDialog = true;
            this.isAdd = false;
            let rowData = this.copyDeep(row);
            this.addForm = rowData;
        },


        copyDeep(templateData) {
            // templateData 是要复制的数组或对象，这样的数组或者对象就是指向新的地址的
            return JSON.parse(JSON.stringify(templateData));
        },

        //重置
        restFunction() {
            this.restForm();
            this.treeData.operatorNameTree = "";
            this.treeData.operatorIdTree = null;
            this.vehicleTeamNameValue = "";
            this.vehicleTeamCodeValue = "";
            this.phoneValue = null;
            this.query = {
                pageNum: 1,
                pageSize: 10
            }
            this.getPage();
        },
        restForm() {
            this.addForm = {
                operatorId: null,
                teamName: "",
                teamCode: "",
                principal: "",
                contactWay: null,
                createTime: null,
            };
            this.accountForm = {
                teamId: null,
                phone: null
            };
        },

        close() {
            this.$refs.addForm.resetFields();
            this.restForm();
            this.isAdd = true;
            this.addDialog = false
        },

        // 分页事件
        handleSizeChange(val) {
            this.query.pageSize = val;
            this.getPage();
        },

        selectionChange(rows) {
            this.selectList = rows;
        },

        addDialogFunction() {
            this.restForm();
            this.addDialog = true;
        },

        handleCurrentChange(val) {
            this.query.pageNum = val;
            this.getPage();
        },

        clean() {
            this.$refs.multipleTable.clearSelection();
        },

        async removeAll() {
            if (this.selectList.length === 0)
                return this.$msg.info("请选择需要删除的车队");
            const confirm = await this.$MessageBox.confirm(
                "此操作将删除选中的车队?",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            );
            if (confirm !== "confirm") return;
            const list = this.selectList.map((item) => {
                return item.id;
            });

            const res = await delTeam(list);
            if (res.data.code !== 200) return this.$msg.error("删除失败!" + res.data.msg);
            this.tableData = this.tableData.filter((item, i) => {
                return item.id !== list[i];
            });
            this.$refs.multipleTable.clearSelection();
            if (this.query.pageNum > 1 && this.tableData.length === 0) {
                this.query.pageNum = this.query.pageNum - 1;
            }
            this.$msg.success("删除成功");
            this.getPage();
        },

        getTreeData(data) {
            this.treeData = data
        },

        /** 查询运营商下拉树结构 */
        async getOperatorTree() {
            try {
                this.treeData.operatorOptions = [];
                let res = await operatorTree();
                if (res.status !== 200) return this.$msg.error("获取运营商信息失败");
                this.treeData.operatorOptions.push(res.data.data)
                this.treeData.operatorIdTree = res.data.data.id
                this.treeData.operatorNameTree = res.data.data.label
            } catch (error) {
            }
        },


    },
};
</script>

<style lang='less' scoped>
.app-container {
    background: rgba(255, 255, 255, 0);
    padding-left: 0px;
}

.el-select {
    width: 100%;
}

.vehicle-item {
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-right: 5px;
}

.clean {
    color: #409eff;
    margin-left: 10px;
    cursor: pointer;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}

.avatar-box {
    width: 100%;
    display: flex;
    align-items: flex-start;

    .avatar-item {
        width: 100px;
        height: 100px;
        border: 1px dashed #666;
        position: relative;
        border-radius: 10px;
        margin-right: 10px;

        > img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
        }

        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 30px;
            color: #666;
        }
    }

    .avatar-item:hover {
        border: 1px dashed #409eff;

        .icon {
            color: #409eff;
        }
    }
}
//重构新增
.side-search {
    width: 200px;
    background: #FFFFFF;
    border-right: #d3dce6 1px solid;
}

.small-side {
    width: 36px;
    height: 36px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.14);
    border-right: 1px solid #EEE;
}

.mian-content {
    flex: 1;
    background: #F5F7FA;
}

.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.head-section {
    padding: 16px;
    background: #FFFFFF;
    overflow: hidden;
}

.btn-end {
    display: flex;
    justify-content: flex-end;
}

.section-main {
    width: 100%;
    //height: calc(100vh - 24px);
    background: #FFFFFF;
    margin-top: 16px;
    padding: 24px 16px 16px 16px;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.choose-mian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
        display: inline-block;
        margin-left: 16px;
    }
}

.alert-box {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
}

.pagin {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.main-flex {
    display: flex;

}

.left-style {
    padding-left: 43px;
}
.edit-style{
    color: #0D6CE4;
    font-size: 14px;
    cursor:pointer;
}

/*popup_marker弹窗标记*/
.popup_marker{

 /*   弹窗左右间距*/
    /deep/.el-dialog__header{
        padding: 24px 24px 0 24px;
    }
    /deep/ .el-dialog__body{
        padding: 24px 24px;
    }
    /deep/.el-dialog__footer{
        padding: 0 24px 24px 24px;
    }
    /deep/.el-form-item{
        margin-bottom: 16px;
    }

/*    弹窗文本框标题*/
    /deep/.el-form-item__label{
        line-height: 20px;
        margin-bottom: 8px;
    }
/*    消除最后一排底部边框*/
    .el-form-item_bottom{
        margin-bottom: 0;
    }
}
</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';
</style>
