import {createAPI} from '@/utils/request'


const urlStr = '/service-userinfo'
const urlStr2 = '/service-oauth2'

//登录
export const login = (data) => createAPI(urlStr2 + '/oauth/token', 'post', data)
export const checkToken = (data) => createAPI('springcloud-oauth2-service/oauth/check_token?token=' + data, 'post')


//用户信息
//根据条件分页查询用户信息
//export const getUsersPage = data => createAPI(urlStr + `/user/specificPage?` + data.url, 'post')
export const getUsersPage = data => createAPI(urlStr + `/user/specificPage`+ data.url  , 'post',data)
// export const getUsersPage = data => createAPI(urlStr + `/user/specificPage`, 'post',data)

export const getUserList = data => createAPI(urlStr + '/user/getRoleReUserList', 'get', data)


//获取所有用户信息
export const userList = () => createAPI(urlStr + '/user/list', 'get')

//根据用户账号获取用户
export const getUsername = (data) => createAPI(urlStr + '/user/' + data, 'get')

//获取邮箱验证码
export const sendEmail= (data) => createAPI(  urlStr + '/user/send/' , 'post',data)

export const checkEmail = (data) => createAPI(  urlStr + '/user/check' , 'post',data)


//注册用户
export const adduserRole = (data) => createAPI(urlStr + '/user/register', 'post', data)

//修改用户信息
export const setUser = data => createAPI(urlStr + '/user/update', 'put', data)

//用户账号赋予角色
export const addGive = data => createAPI(urlStr + '/user/empower', 'post', data)

//修改用户账号状态
export const setStatus = id => createAPI(urlStr + '/user/state?userId=' + id, 'put')

//批量删除用户账号
export const batchUser = list => createAPI(urlStr + '/user/batch?idString=' + list, 'delete')

//根据用户查询用户权限
export const userIdMenuList = data => createAPI(urlStr + '/user/getMenuListByUser?userId=' + data, 'get')


//发送邮箱
export const getEmail = data => createAPI(urlStr + '/user/sendEmail', 'post', data)

//验证邮箱
export const verifyEmail = data => createAPI(urlStr + '/user/isemail', 'post', data)

//修改密码
export const modification = data => createAPI(urlStr + '/user/updatepassword', 'post', data)

//权限信息

//获取所有
export const getAuthAll = () => createAPI(urlStr + '/authority/listWithPermission', 'get')


//根据名称分页查询权限信息
export const checoutAuth = data => createAPI(urlStr + `/authority/specific?name=${data.name}&page=${data.pageNum}&size=${data.pageSize}`, 'get')

//添加权限
export const AddAuth = data => createAPI(urlStr + '/authority/add', 'post', data)

//修改权限
export const compileAuths = data => createAPI(urlStr + '/authority/update', 'post', data)

//删除权限
export const removeAuth = data => createAPI(urlStr + '/authority/delete/' + data, 'delete')

//批量删除权限
export const removeAuthAll = list => createAPI(urlStr + '/authority/batch?idString=' + list, 'delete')

//上传图片
export const imgUpload = data => createAPI('/service-file/upload/uploadFile', 'post', data)
//export const imgUpload = data => createAPI('/service-openapi/upload/uploadFile', 'post', data)

//上传文件
//export const uploadFile = data => createAPI('/service-file/upload/uploadFile', 'post', data)
export const uploadFile = data => createAPI('/service-openapi/upload/uploadFile', 'post', data)

//根据角色查询权限
export const rolesIdAuth = data => createAPI(urlStr + '/user/getMenuListByRole?roleId=' + data, 'get')

//角色信息
//分页查询
export const getRolesPage = data => createAPI(urlStr + `/role/page`, 'post',data)
// export const getRolesPage = data => createAPI(urlStr + `/role/page?` + data.url, 'get')

//获取用户角色信息
export const getRoles = data => createAPI(urlStr + `/role/page?page=${data.pageNum}&size=${data.pageSize}`, 'get')

export const getRoleByOperatorId = data => createAPI(urlStr + '/role/listWithPermission?operatorId=' + data.operatorId, 'get')

//获取所有角色信息
export const getRolesAll = () => createAPI(urlStr + '/role/listWithPermission', 'get')

//新增角色信息
export const addRoles = data => createAPI(urlStr + '/role/add', 'post', data)

//修改角色信息
export const putRoles = data => createAPI(urlStr + '/role/update', 'put', data)

//批量删除角色
export const removeAll = list => createAPI(urlStr + '/role/batch?idString=' + list, 'delete')

//密码修改
export const security = data => createAPI(urlStr + `/user/reset?id=${data.id}&password=${data.password}&newPassword=${data.newPassword}`, 'post')

//角色权限绑定
export const AuthBindin = data => createAPI(urlStr + '/role/empower', 'post', data)


//系统参数
//分页查询系统参数
export const sysConfigPage = data => createAPI(urlStr + '/sysConfig/page', 'get',data)
//添加系统参数
export const sysConfigAdd = data => createAPI(urlStr + '/sysConfig/add', 'post', data)
//修改系统参数
export const sysConfigModify = data => createAPI(urlStr + '/sysConfig/update', 'post', data)
//批量删除系统参数
export const sysConfigBatchDel = data => createAPI(urlStr + '/sysConfig/batchDelete?ids=' + data, 'get')
//修改系统参数状态
export const sysConfigStatusSet = data => createAPI(urlStr + '/sysConfig/updateStatus?id=' + data, 'get')
