<template>
    <div class="app-container">
        <el-row class="main-flex">
            <el-col :span="3" class="side-search" v-if="isopenShow">
                <operatorTreeVue @emitToParent='getTreeData' @isShow="isHidden"></operatorTreeVue>
            </el-col>

            <el-col v-if="!isopenShow" class="small-side">
                <div class="change-in" @click="isopenShow = !  isopenShow "></div>
            </el-col>

            <el-col :span="21" class="mian-content">
                <!--                搜索-->
                <div class="head-section">
                    <!--  2023-11-10 沟通先隐藏 区域编码-->
                    <!--                    <el-col :span="6">
                    <el-form :inline="true">
                        <el-form-item label="区域编码：">
                            <el-select v-model="nodeId" placeholder="请选择云平台区域编码" clearable>
                                <el-option
                                    clearable
                                    v-for="item in nodeList"
                                    :key="item.id"
                                    :label="item.node"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </el-col>-->
                    <el-col :span="7">
                        <div>
                            <el-form :inline="true">
                                <el-form-item label="换电站名称：">
                                    <el-input v-model.trim="stationValue" placeholder="请输入换电站名称"></el-input>
                                </el-form-item>
                            </el-form>
                        </div>
                    </el-col>
                    <el-col :span="13">
                        <div class="left-style">
                            <el-form :inline="true">
                                <el-form-item label="换电站编码：">
                                    <el-input v-model.trim="stationNo" placeholder="请输入换电站编码"></el-input>
                                </el-form-item>
                            </el-form>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="btn-end">
                                <span class="btn-rest">
                                    <el-button @click="restFunction" plain>
                                    重置
                                </el-button>
                                </span>
                            <span class="btn-submit">
                                    <el-button @click="submitCheck" type="primary" plain
                                    >查询
                                </el-button>
                                </span>

                        </div>
                    </el-col>
                </div>

                <!--                表格部分-->
                <div class="section-main">
                    <el-col class="choose-mian">
                        <div class="section-title">
                            场站列表
                        </div>
                        <div>
                            <!--选择项栏-->
                            <span class="alert-box">
                          已选择{{ batchNum.length }}项
                            <span @click="cleanList" style="margin-left:8px;color:#0D6CE4;cursor:pointer;">清空</span>
                        </span>
                            <span class="pain-btn">
                                 <el-button size="small" plain @click="download"
                                            v-hasCode="['base:station:export']">导出
                            </el-button>
                            </span>
                            <span class="pain-btn">
                                <el-button size="small" plain @click="dialogImport = true"
                                           v-hasCode="['base:station:import']">导入
                            </el-button>
                            </span>
                            <span class="pain-btn">
                                <el-button size="small" plain @click="removeAll"
                                           v-hasCode="['base:station:delete']">删除
                            </el-button>
                            </span>
                            <span class="add-btn">
                                <el-button size="small" plain @click="addStation"
                                           type="primary"
                                           v-hasCode="['base:station:add']"> 新增
                            </el-button>
                            </span>
                        </div>

                    </el-col>

                    <div class="table-box">
                        <!--           :max-height="clientHeight"             -->
                        <el-table ref="forceTable" id="statiNo" @selection-change="batchList" :data="tableData"
                                  :header-cell-style="headerStyleEvent" :cell-class-name="goodsInfo" height="100%">
                            <el-table-column align="center" type="selection" width="46" fixed></el-table-column>
                            <el-table-column align="center" label="编号" :index="renIndex" type="index"
                                             width="46"></el-table-column>
                            <el-table-column align="center" prop="operatorName" label="归属运营商" min-width="120"
                                             :show-overflow-tooltip="true"
                            ></el-table-column>
                            <el-table-column align="center" prop="stationNo" label="换电站编码"
                                             width="150"></el-table-column>
                            <el-table-column align="center" prop="stationName" label="换电站名称" width="200"
                                             :show-overflow-tooltip="true"
                            ></el-table-column>
                            <el-table-column align="center" prop="stationSn" label="换电站编号"
                                             width="70"></el-table-column>

                            <!--
                            <el-table-column prop="nodeId" label="云平台区域编号" width="120"
                                             :formatter="nodeIdFormat"></el-table-column>
                            -->

                            <el-table-column align="center" prop="staType" label="工位分类" width="100">
                                <template slot-scope="scope">
                                    <dict-tag :options="dict.type.sta_station_category" :value="scope.row.staType"/>
                                </template>
                            </el-table-column>


                            <el-table-column align="center" prop="runModel" label="运行模式"
                                             :formatter="stationRunModelFormat"></el-table-column>

                            <el-table-column align="center" prop="sbmType" label="计费模型"
                                             :show-overflow-tooltip="true"
                                             :formatter="sbmTypeFormat"></el-table-column>

                            <!--  <el-table-column prop="chargePower" label="功率(单位kwh)" width="100"></el-table-column>-->

                            <!--
                            <el-table-column prop="operationTime" label="营运时间" min-width="110"></el-table-column>
                            <el-table-column prop="launchTime" label="投放时间" min-width="110"></el-table-column>
                            <el-table-column prop="areaName" :show-overflow-tooltip="true" label="区域名称"
                                            min-width="100"></el-table-column>
                           <el-table-column prop="areaCode" label="区域编号" min-width="160"></el-table-column>
                           <el-table-column prop="stationLocation" :show-overflow-tooltip="true" label="地理位置"
                                              min-width="100"></el-table-column>
                           <el-table-column prop="principal" label="场站负责人" min-width="140"></el-table-column>
                           <el-table-column prop="contactWay" label="联系方式" min-width="120"></el-table-column>
                           <el-table-column prop="email" label="联系邮箱" min-width="120"></el-table-column>
                           <el-table-column prop="cover" label="场站图片" min-width="130">
                             <template slot-scope="scope">
                               <el-avatar shape="square" :size="100" fit="fill" :src="scope.row.cover"></el-avatar>
                             </template>
                           </el-table-column>
                             -->

                            <el-table-column align="center" prop="status" label="服务状态">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.status == 1">
                                        <i style="background-color:#1890ff;"></i>服务启用
                                    </span>
                                    <span v-else-if="scope.row.status == 2">
                                        <i style="background-color:#808080;"></i>服务停用
                                    </span>
                                    <span v-else class="states">
                                        <i style="background-color:#FFCD29;"></i>未知
                                    </span>
                                </template>
                            </el-table-column>


                            <el-table-column label="服务状态设置" width="110">
                                <template slot-scope="scope">
                                    <el-switch
                                        @change="clickcommand(scope.row)"
                                        v-model="scope.row.status"
                                        v-hasCode="['base:station:stateSetting']"
                                        :active-value=1
                                        :inactive-value=2
                                        active-color="#13ce66"
                                        inactive-color="#ff4949">
                                    </el-switch>
                                </template>
                            </el-table-column>


                            <el-table-column align="center" prop="operationStatus" label="运营状态" width="100">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.operationStatus === 1" class="states"><i
                                        style="background-color:#0D6CE4;"></i> 营业状态</span>
                                    <span v-else-if="scope.row.operationStatus === 2" class="states"><i
                                        style="background-color:#808080;"></i> 暂停营业</span>
                                    <span v-else-if="scope.row.operationStatus === 3" class="states"><i
                                        style="background-color:#FA9600;"></i> 设备维护</span>
                                    <span v-else-if="scope.row.operationStatus === 4" class="states"><i
                                        style="background-color:#808080;"></i> 歇业状态</span>
                                    <span v-else class="states"><i
                                        style="background-color:#FFCD29;"></i> 未知</span>
                                </template>
                            </el-table-column>

                            <el-table-column align="center" label="操作" min-width="320"
                                             fixed="right">
                                <template slot-scope="scope">
                                    <div class="operationBtn">
                                            <span @click="goForce(scope.row)">
                                                详情
                                            </span>
                                        <span @click="$router.push({path:'/equipment',query:{data:JSON.stringify(scope.row)}})">
                                                设备
                                        </span>
                                        <span @click="compileForce(scope.row)" v-hasCode="['base:station:update']">
                                                编辑
                                        </span>
                                        <span @click="compileRun(scope.row)" v-hasCode="['operation:billmodel:send']">
                                                发送计费模型
                                        </span>
                                        <span @click="elecPriceSend(scope.row)" v-hasCode="['elec:price:send']">
                                                发送电价模型
                                        </span>
                                    </div>

                                    <!--
                                                      <el-dropdown @command="clickcommand" trigger="click">
                                                  <span class="el-dropdown-link">
                                                    {{ commandList[scope.row.status] }}<i class="el-icon-arrow-down el-icon--right"></i>
                                                  </span>
                                                        <el-dropdown-menu slot="dropdown">
                                                          <el-dropdown-item v-for="(item,i) in commandList" :key="i"
                                                                            :command="{stationNo:scope.row.stationNo,node:scope.row.nodeId,status:i}">{{
                                                              item
                                                            }}
                                                          </el-dropdown-item>
                                                        </el-dropdown-menu>
                                                      </el-dropdown>
                                    -->
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-col>
                            <el-row class="pagin">
                                <el-pagination
                                    @size-change="handleSizeChange"
                                    @current-change="handleCurrentChange"
                                    :current-page="query.pageNum"
                                    :page-sizes="[20, 30, 50, 100]"
                                    :page-size="query.pageSize"
                                    layout="total, sizes, prev, pager, next"
                                    :total="total">
                                </el-pagination>
                            </el-row>
                        </el-col>
                    </div>

                </div>
                <!--table-->

                <!--pagination-->

            </el-col>

        </el-row>

        <!-- 新增与修改站场信息 -->
        <el-dialog
            :title="isAdd?'新增场站信息':'修改场站信息'"
            :visible.sync="isforce"
            width="70%"
            @close="close">
            <el-form size="middle" ref="forceform" :rules="forceRules" :model="forceForm" label-width="150px">
                <el-row>
                    <el-col :span="11">
                        <el-form-item label="归属运营商" prop="operatorId">
                            <TreeSelect v-model="forceForm.operatorId" :options="treeData.operatorOptions"
                                        placeholder="请选择运营商"/>
                        </el-form-item>
                        <el-form-item label="换电站名称:" prop="stationName">
                            <el-input v-model="forceForm.stationName" maxlength="50" show-word-limit></el-input>
                        </el-form-item>

                        <el-form-item label="换电站编号:" prop="stationSn">
                            <el-input maxlength="4" v-model="forceForm.stationSn" show-word-limit></el-input>
                        </el-form-item>

                        <el-form-item label="云平台区域编号:" prop="nodeId">
                            <el-select v-model="forceForm.nodeId" placeholder="请选择云平台区域编号">
                                <el-option
                                    clearable
                                    v-for="item in nodeListForm"
                                    :key="item.id"
                                    :label="item.node"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="区域名称:" prop="areaCode">
                            <el-cascader
                                filterable
                                clearable
                                v-model="forceForm.areaCode"
                                :options="options"
                                ref="elcascader"
                                @change="areaHandleChange"
                                :props="{checkStrictly: true}"
                                placeholder="请选择区域名称"
                            ></el-cascader>
                        </el-form-item>

                        <el-form-item label="地理位置:" prop="stationLocation">
                            <el-input v-model="forceForm.stationLocation" maxlength="120" show-word-limit></el-input>
                        </el-form-item>

                        <el-form-item label="经度:" prop="longitude">
                            <el-input v-model="forceForm.longitude" maxlength="20" show-word-limit></el-input>
                        </el-form-item>
                        <el-form-item label="纬度:" prop="latitude">
                            <el-input v-model="forceForm.latitude" maxlength="20" show-word-limit></el-input>
                        </el-form-item>


                        <el-form-item label="场站类型分类" prop="type">
                            <el-select v-model="forceForm.type" placeholder="请选择站型类型" clearable>
                                <el-option
                                    v-for="dict in dict.type.sta_type_category"
                                    :key="dict.value"
                                    :label="dict.label"
                                    :value="dict.value"
                                ></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="工位分类" prop="staType">
                            <el-select v-model="forceForm.staType" placeholder="请选择站类型" clearable>
                                <el-option
                                    v-for="dict in dict.type.sta_station_category"
                                    :key="dict.value"
                                    :label="dict.label"
                                    :value="dict.value"
                                ></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="区域分类" prop="areaType">
                            <el-select v-model="forceForm.areaType" placeholder="请选择站类型" clearable>
                                <el-option
                                    v-for="dict in dict.type.sta_area_type"
                                    :key="dict.value"
                                    :label="dict.label"
                                    :value="dict.value"
                                ></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="特殊配置" prop="specialConfig">
                            <el-select v-model="forceForm.specialConfig" placeholder="请选择站类型" clearable>
                                <el-option
                                    v-for="dict in dict.type.sta_special_config"
                                    :key="dict.value"
                                    :label="dict.label"
                                    :value="dict.value"
                                ></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="协议版本号:" prop="protocolVersion">
                            <el-input v-model="forceForm.protocolVersion" maxlength="30" show-word-limit></el-input>
                        </el-form-item>
                        <el-form-item label="设备列表版本号:" prop="deviceVersion">
                            <el-input v-model="forceForm.deviceVersion" maxlength="30" show-word-limit></el-input>
                        </el-form-item>
                        <el-form-item label="功率(单位kWh):" prop="chargePower">
                            <el-input v-model="forceForm.chargePower" type="number"></el-input>
                        </el-form-item>
                        <el-button size="medium" @click="getMap" type="text" plain>查看地图</el-button>
                    </el-col>

                    <el-col :span="11">

                        <el-form-item label="运行模式:" prop="runModel">
                            <el-select v-model="forceForm.runModel" placeholder="请选择运行模式">
                                <el-option
                                    clearable
                                    v-for="item in stationRunModelList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                ></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="计费模型:" prop="sbmId">
                            <el-select v-model="forceForm.sbmId" placeholder="请选择场站计费模型" @change="forceUpdate">
                                <el-option
                                    clearable
                                    v-for="item in sbmOptions"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="电价模型:" prop="sbmId">
                            <el-select v-model="forceForm.priceId" placeholder="请选择电价模型" @change="forceUpdate">
                                <el-option
                                    clearable
                                    v-for="item in priceOptions"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>


                        <el-form-item label="营运时间:" prop="operationTime">
                            <el-date-picker
                                v-model="forceForm.operationTime"
                                type="datetime"
                                style="width:100%;"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                placeholder="选择日期时间">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="投放时间:" prop="launchTime">
                            <el-date-picker
                                v-model="forceForm.launchTime"
                                style="width:100%;"
                                type="datetime"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                placeholder="选择日期时间">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="负责人: " prop="principal">
                            <el-input v-model="forceForm.principal" maxlength="20" show-word-limit></el-input>
                        </el-form-item>
                        <el-form-item label="联系方式:" prop="contactWay">
                            <el-input maxlength="11" v-model="forceForm.contactWay" show-word-limit
                                      oninput="value=value.replace(/[^\d]/g,'')"></el-input>
                        </el-form-item>
                        <el-form-item label="联系邮箱:" prop="email">
                            <el-input v-model="forceForm.email" maxlength="50" show-word-limit></el-input>
                        </el-form-item>
                        <el-form-item label="所属运营企业:" prop="stationCompany">
                            <el-input v-model="forceForm.stationCompany" maxlength="50" show-word-limit></el-input>
                        </el-form-item>
                        <el-form-item label="所属运营企业统一社会信用代码:" prop="socialCreditCode">
                            <el-input v-model="forceForm.socialCreditCode" maxlength="18" show-word-limit></el-input>
                        </el-form-item>
                        <el-form-item label="站控主机软件版本号:" prop="stationSftVer">
                            <el-input v-model="forceForm.stationSftVer" maxlength="30" show-word-limit></el-input>
                        </el-form-item>
                        <el-form-item label="基础信息版本号" prop="version"
                                      title="基础信息版本号(换电站编码+更新时间(yyyyMMddHHmmss)">
                            <el-input v-model="forceForm.version" :disabled="true" maxlength="30"
                                      show-word-limit></el-input>
                        </el-form-item>

                        <el-form-item label="是否在小程序中展示" prop="appAccess">
                            <el-radio-group v-model="forceForm.appAccess">
                                <el-radio
                                    v-for="item in appAccessList"
                                    :key="item.value"
                                    :label="item.value"
                                >{{ item.label }}
                                </el-radio>
                            </el-radio-group>
                        </el-form-item>


                        <el-form-item label="封面图片文件:">
                            <Avater @on-avater="onAvater" @on-imgchange="imgchange" :uploadImg="uploadImg"
                                    :isAvater="isAvater"/>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="cancelIsforce" plain>取 消</el-button>
        <el-button type="primary" @click="newAddOrset" plain>确 定</el-button>
      </span>
        </el-dialog>

        <!-- 批量导入 -->
        <el-dialog title="请选择导入文件" :visible.sync="dialogImport" width="30%" @close="closeLoad">
            <el-upload
                class="upload-demo"
                ref="upload"
                action
                :on-change="handleChange"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :before-upload="beforeUpload"
                :file-list="fileList"
                :limit="1"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                :auto-upload="false"
            >
                <el-button size="small" type="primary">请选择 xlsx / xls 文件</el-button>
            </el-upload>
            <span slot="footer" class="dowload dialog-footer">
        <div>
          <el-button @click="dowtemplate">下载模版</el-button>
          <span>请不要擅自修改或删除模版</span>
        </div>
       <div>
          <el-button @click="dialogImport = false">取消</el-button>
        <el-button type="primary" :disabled="uploadDisabled" style="margin-top: 16px"
                   @click="handleUpload">开始上传</el-button>
       </div>
      </span>
        </el-dialog>

        <el-dialog
            title="地图"
            :visible.sync="mapDialogVisible"
            width="30%"
            :append-to-body="true"
            style="height: 815px;"
            :close-on-click-modal="false" :close-on-press-escape="false">
            <div class="address-inf">
                {{ this.addressKeyword ? this.addressKeyword : '--' }}
            </div>
            <div id="change_AMAP"></div>
            <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="checkedAddress">确 定</el-button>
          <el-button @click="dismapDialog">关 闭</el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
import '@/assets/font/font.css';
import {
    addForce,
    setForce,
    deleteForce,
    checkForce,
    stationLeadFile,
    getForceList, setServicesMqtt, setStaBaseMqtt
} from '@/utils/station';
import {
    getNode
} from '@/utils/station2'
import {getArerCodeAll} from '@/utils/basicInformation'
import Avater from '@/components/avater'
import {CodeToText, TextToCode, regionData} from "element-china-area-data";
import axios from 'axios'

import operatorTreeVue from "@/components/tree/operatorTree"
import TreeSelect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {operatorTree} from "@/utils/operatorApi";
import moment from "moment";
import {getBillingModelList, selectElecPriceList, setChargePriceMqtt, setStaPriceModelMqtt} from "@/utils/operation";

import {provincepy} from '@/components/map/provincepy'

export default {
    name: 'force',
    dicts: ['sta_type_category', 'sta_area_type', 'sta_special_config', 'sta_station_category'],
    components: {
        Avater, TreeSelect, operatorTreeVue
    },
    data() {
        return {
            pickPoint: null,
            marker: null,
            mapData: null,
            isopenShow: true,
            cascaderValue: [],
            nodeId: null,
            stationValue: '',
            stationNo: "",
            query: {
                pageNum: 1,
                pageSize: 20,
            },
            batchNum: [],
            tableData: [],

            //区域填充数据
            options: regionData,
            mapDialogVisible: false,
            addressKeyword: '',
            pointLngLat: {},
            showMap: false,
            center: {lng: 109.45744048529967, lat: 36.49771311230842},
            zoom: 13,
            //options: [],
            total: 0,
            isforce: false,
            isAdd: true,
            isPage: true,
            isdisabled: false,
            command: '关闭',
            // commandList:['运营','维护','歇业','关闭'],
            commandList: ['启用', '停用'],
            forceForm: {
                stationName: '',
                stationSn: null,
                stationLocation: '',
                longitude: '',
                latitude: '',
                chargePower: null,
                areaCode: [],
                cover: null,
                areaName: '',
                operationTime: null,
                launchTime: null,
                principal: '',
                email: '',
                stationCompany: '',
                socialCreditCode: '',
                stationSftVer: '',
                stationNo: '',
                operatorId: null,
                version: null,
                nodeId: null,
                protocolVersion: null,
                deviceVersion: null,
                sbmId: null,
                priceId: null,
                runModel: 1,

                staType: null,
                areaType: null,
                specialConfig: null,
                type: null,
                appAccess: 1

            },
            avater: null,
            forceRules: {
                stationName: [
                    {required: true, message: '请输入换电站名称', trigger: 'blur'}
                ],
                stationSn: [
                    {required: true, message: '请输入换电站识别号', trigger: 'blur'}
                    //,{ min: 4, message: '密码长度不能低于4位', trigger: 'blur' }
                    , {max: 4, message: '密码长度不能高于4位', trigger: 'blur'}
                ],

                /**
                 nodeId: [
                 {required: true, message: '请选择云平台区域', trigger: 'change'}
                 ],
                 staType: [
                 {required: true, message: '请选择工位分类', trigger: 'change'}
                 ],
                 **/

                longitude: [
                    {required: true, message: '请输入经度', trigger: 'blur'}
                ],
                latitude: [
                    {required: true, message: '请输入纬度', trigger: 'blur'}
                ],
                areaCode: [
                    {required: true, message: '请输入区域编号', trigger: 'change'}
                ],
                stationLocation: [
                    {required: true, message: '请输入地理位置', trigger: 'change'}
                ],
                operatorId: [
                    {required: true, message: '请选择所属运营商', trigger: 'blur'}
                ],
                runModel: [
                    {required: true, message: '请选择运行模式', trigger: 'blur'}
                ],
                email: [
                    {required: false, message: "请填写邮箱地址", trigger: "blur"},
                    {
                        type: "email",
                        message: "请输入正确的邮箱地址",
                        trigger: ["blur"]
                    }],
                operationTime: [
                    {required: true, message: '请选择营运时间', trigger: 'blur'}
                ],

                launchTime: [
                    {required: true, message: '请选择投放时间', trigger: 'blur'}
                ],
                sbmId: [
                    {required: true, message: '请选择计费模型', trigger: 'blur'}
                ],
                stationCompany: [
                    {required: true, message: '请输入所属企业', trigger: 'blur'}
                ],
                socialCreditCode: [
                    {required: true, message: '请输入场站统一社会信用代码', trigger: 'blur'}
                ]

            },
            columns: [
                {
                    label: '区域编号',
                    prop: 'areaCode'
                },
                {
                    label: '区域名称',
                    prop: 'areaName'
                },
                {
                    label: '联系方式',
                    prop: 'contactWay'
                },
                {
                    label: '纬度',
                    prop: 'latitude'
                },
                {
                    label: '经度',
                    prop: 'longitude'
                },
                {
                    label: '场站负责人',
                    prop: 'principal'
                },
                {
                    label: '所属运营企业统一社会信用代码',
                    prop: 'socialCreditCode'
                },
                {
                    label: '所属运营企业',
                    prop: 'stationCompany'
                },
                {
                    label: '地理位置',
                    prop: 'stationLocation'
                },
                {
                    label: '换电站名称',
                    prop: 'stationName'
                },
                {
                    label: '站控主机软件版本号',
                    prop: 'stationSftVer'
                },
                {
                    label: '换电站识别号',
                    prop: 'stationSn'
                },
                {
                    label: '工位分类',
                    prop: 'staType'
                }
            ],
            dialogImport: false,
            nodeList: [],
            clientHeight: 0,
            stationDownList: [],

            nodeListForm: [],

            fileList: [],//上传的文件列表
            uploadDisabled: true,
            casvderValue: [],
            fileAvater: null,//图片地址
            isAvater: true,
            uploadImg: null,
            copyCode: [],
            isEdit: false,
            sbmList: [],
            sbmOptions: [],

            priceOptions: [],
            stationRunModelList: [
                {value: 0, label: "本地模式"},
                {value: 1, label: "云端模式"}
            ],
            tem_operatorId: null,
            pvepy: provincepy(),
            treeData: {
                operatorNameTree: "",
                operatorIdTree: null,
                operatorOptions: undefined,  //运营商树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
            },

            appAccessList: [
                {value: 1, label: "是"},
                {value: 0, label: "否"}
            ],
        };

    },
    created() {
        this.getNode();
        this.getForce();
        this.getOperatorTree();
        this.getSBMList();
        this.clientHeight = document.body.clientHeight - 310;
    },
    computed: {},
    mounted() {
    },
    watch: {
        'forceForm.operatorId': {
            async handler(newName, oldName) {

                if (newName == oldName) return;
                if (!this.isAdd && (oldName === null || oldName === "" || oldName === undefined)) {
                    return;
                }

                //新增、修改时改动过运营商
                if (newName !== null && newName !== undefined && newName !== oldName) {
                    let data = {};
                    data.operatorId = newName;
                    this.forceForm.sbmId = null;
                    this.forceForm.nodeId = null;
                    this.forceForm.priceId = null;
                    const res1 = await getNode(data);
                    const res2 = await getBillingModelList(data);
                    const res3 = await selectElecPriceList(data)
                    this.nodeListForm = res1.data.data;
                    this.sbmOptions = res2.data.data;
                    this.priceOptions = res3.data.data;
                    return;
                }
            },
            deep: true,
            immediate: false
        }
    },

    methods: {
        cancelIsforce() {
            this.isforce = false
            this.$refs['forceform'].resetFields();
        },
        dismapDialog() {
            let that = this
            that.mapDialogVisible = false
            that.mapData.destroy();
        },
        initMap() {
            new Promise((resolve, reject) => {
                this.AMap(resolve)
            }).then(() => {
                // 场站地图
                var that = this
                that.mapData = new AMap.Map("change_AMAP", {
                    viewMode: "2D", //  是否为3D地图模式
                    zoom: 5, // 初始化地图级别
                    center: [113.425981, 35.423209], //中心点坐标
                    resizeEnable: true,
                });
                // 创建一个 Marker 实例：
                if (this.forceForm.longitude && this.forceForm.latitude) {//修改的点位
                    var markerPoint = new AMap.Marker({
                        position: new AMap.LngLat(this.forceForm.longitude, this.forceForm.latitude),
                    });
                    // 将创建的点标记添加到已有的地图实例：
                    that.mapData.add(markerPoint);
                    that.mapData.setFitView(markerPoint);
                    console.log(that.mapData.setFitView(markerPoint), 'cccc', that.mapData)
                    that.mapData.setZoom(10);
                }
                // 鼠标点击获取经纬度

                that.mapData.on('click', function (e) {
                    if (that.marker) {
                        that.marker.setMap(null);
                    }
                    that.marker = new AMap.Marker({
                        position: [e.lnglat.getLng(), e.lnglat.getLat()],
                    });
                    that.marker.setMap(that.mapData);
                    that.getClickInfo(e)
                    console.log("经度", e.lnglat.getLng(), "纬度", e.lnglat.getLat())
                });

                // }
            })
            // AMapLoader.load({
            //     key: "647fcf74c39e032f88c2ac31cba64336", //key值是key值 和安全密钥不同
            //     version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
            //     plugins: ["AMap.PlaceSearch","AMap.Geocoder"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
            // })
            //     .then((AMap) => {
            //         // 初始化地图
            //         this.mapData = new AMap.Map("change_AMAPchange_AMAP", {
            //             viewMode: "2D", //  是否为3D地图模式
            //             zoom: 5, // 初始化地图级别
            //             center: [113.425981, 35.423209], //中心点坐标
            //             resizeEnable: true,
            //         });

            // let placeSearch = new AMap.PlaceSearch({//
            //     map: this.mapData,
            //     panel: "panel", // 结果列表将在此容器中进行展示。
            //     // city: "010", // 兴趣点城市
            //     autoFitView: true, // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
            //     extensions: 'base' //返回基本地址信息
            // });
            // placeSearch.search(this.addressKeyword);

            // })
            // .catch((e) => {
            //     console.log(e, 'catch');
            // });
        },
        headerStyleEvent({row, column, rowIndex, columnIndex}) {
            if (columnIndex == 1) {
                return 'padding:0px;'
            }
        },
        goodsInfo(row) {
            if (row.columnIndex == 1) {
                return 'goodsInfo'
            }
        },
        isHidden(message) {
            this.isopenShow = message
        },
        userNameBlur() {
            const newList = JSON.parse(getItem('upgrade'))
            if (newList && newList[1].username === this.stationValue && newList[0].index > 3) {
                this.stationValue = [newList[1].username]
                this.iponeValue = newList[1].mobile
                this.identValue = newList[1].identifyCode
                this.sexValue = newList[1].gender
                this.stateValue = newList[1].version
            }
        },
        async getNode() {
            var data = {}
            const result = await getNode(data);
            if (result.data.code !== 200){
                this.nodeList = result.data.data;
            }
        },
        async getSBMList() {
            var data = {};
            const result = await getBillingModelList(data);
            if (res.data.code !== 200){
                this.sbmList = result.data.data;
            }
        },

        async getArerCode() {
            const res = await getArerCodeAll()
            if (res.data.code !== 200) return this.$msg.error('获取换电站区域信息失败')
            let codeList = res.data.data.filter(c => c.areaState !== 0)
            this.renderAreacode(codeList)
        },

        restFunction() {
            this.treeData.operatorNameTree = "";
            this.treeData.operatorIdTree = null;
            this.nodeId = null;
            this.resetForm();
            this.cascaderValue = []
            this.stationValue = ''
            this.stationNo = ''
            this.isPage = true
            this.query = {
                pageNum: 1,
                pageSize: 20,
            }
            this.getForce()
        },
        batchList(list) {
            this.batchNum = list
        },
        handleSizeChange(val) {
            this.query.pageSize = val
            this.getForce()
        },
        handleCurrentChange(val) {
            this.query.pageNum = val
            this.getForce()
        },
        // async clickcommand(command){
        //   command.status = command.status +''
        //   const res =await stationNoState(command)
        //   if(res.status !== 200)return this.$msg.error('换电站状态修改失败')
        //   this.getForce()
        //
        // },

        async clickcommand(list) {
            const config = await this.$MessageBox.confirm(
                "准备向编码为: ‘" + list.stationNo + "’ 的场站下发服务状态, 是否继续?",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }
            ).catch(e => e);
            if (config !== "confirm") {
                this.getForce()
                return;
            }

            var data = JSON.parse(JSON.stringify(list))
            const info = {
                "stationCode": data.stationNo,
                "st": data.status
            }

            const result = await setServicesMqtt(JSON.parse(JSON.stringify(info)))
            if (result.data.code !== 200) {
                return this.$msg.error("指令发送失败")
            }

            //修改场站状态
            let sta = {stationNo: data.stationNo, status: data.status}
            const res = await setForce(data)
            if (res.status !== 200) {
                return this.$msg.error('修改场站基础信息失败!')
            }
            this.addOrUpdate = false
            this.$msg.success("操作成功")
            this.close()
        },

        onAvater(file) {
            this.fileAvater = file
        },
        imgchange(val) {
            this.isAvater = val
        },
        //新增和修改站场信息
        newAddOrset() {
            this.$refs.forceform.validate(async valid => {
                if (!valid) return this.$msg.info('场站信息不能为空!')
                try {
                    let data = JSON.parse(JSON.stringify(this.forceForm))
                    // data.areaCode = this.fromcascader
                    //this.fileAvater && (data.cover = this.fileAvater)
                    //判断图片是否发生过更改，更改之后data.cover做替换
                    this.fileAvater && (data.cover = this.fileAvater.url)

                    data.areaCode = data.areaCode.join(',');
                    data.stationSn = this.forceForm.stationSn;
                    if (this.isAdd) {
                        //场站编码
                        //data.stationNo = this.copyCode[this.copyCode.length - 1] + data.staType + "00" + data.stationSn;
                        var provincesName = this.forceForm.areaName.split(",")[0];
                        this.pvepy.some(item => {
                            if (item.name === provincesName) {
                                data.stationNo = item.pinyin;
                                return true;
                            }
                        })

                        const res = await addForce(data);
                        if (res.data.code !== 200) return this.$msg.error('添加场站信息失败! ' + res.data.msg);
                        this.$msg.success('新增场站基础信息成功!');
                        this.isforce = false
                        this.getForce()
                    } else {
                        const result = await setForce(data)
                        if (result.data.code !== 200) {
                            console.log(result.data.msg)
                            this.$msg.error('修改场站基础信息失败! ' + result.data.msg)
                        } else {
                            this.$msg.success('修改场站基础信息成功!')
                            //发送mqtt指令
                            this.sendMqtt(data);
                            this.isforce = false
                            this.getForce()
                        }

                    }
                } catch (e) {
                }
            })
        },

        async sendMqtt(data) {
            const result = await setStaBaseMqtt(data);
        },


        renIndex(index) {
            return (this.query.pageNum - 1) * this.query.pageSize + index + 1
        },
        //换电站信息填充1
        async compileForce(row) {
            this.sbmOptions = [];
            let data = {};
            data.operatorId = row.operatorId;
            const res1 = await getNode(data);
            const res2 = await getBillingModelList(data);
            const res3 = await selectElecPriceList(data)
            if (res1.data.code != 200 || res2.data.code != 200) {
                return this.$msg.info("获取场站信息失败");
            } else {
                this.nodeListForm = res1.data.data;
                this.sbmOptions = res2.data.data;
                this.priceOptions = res3.data.data;
                this.isforce = true;
                this.isEdit = true;
                this.tem_operatorId = row.operatorId;
                this.isAdd = false;
                var list = JSON.parse(JSON.stringify(row));
                this.uploadImg = list.cover;
                list.areaCode = row.areaCode.split(',');
                this.forceForm = list;
                this.pointLngLat = {lng: this.forceForm.longitude, lat: this.forceForm.latitude};
            }

        },


        async compileRun(list) {
            const config = await this.$MessageBox.confirm(
                // "向"+list.fstationId+"下发换电运营模型信息, 是否继续?",
                "下发换电计费模型信息, 是否继续?",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }
            ).catch(e => e);
            if (config !== "confirm") return;

            var data = JSON.parse(JSON.stringify(list))
            const info = {
                "stationCode": data.stationNo
            }

            const result = await setStaPriceModelMqtt(JSON.parse(JSON.stringify(info)))
            if (result.data.code !== 200) return this.$msg.error("操作失败!" + result.data.msg)
            this.addOrUpdate = false
            this.$msg.success("操作成功")
            this.close()
        },

        async elecPriceSend(row) {
            const config = await this.$MessageBox.confirm(
                // "向"+list.fstationId+"下发换电运营模型信息, 是否继续?",
                "下发电网电价模型信息, 是否继续?",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }
            ).catch(e => e);
            if (config !== "confirm") return;

            var data = JSON.parse(JSON.stringify(row))
            const info = {
                "stationCode": data.stationNo,
            }

            const result = await setChargePriceMqtt(JSON.parse(JSON.stringify(info)))
            if (result.data.code !== 200) return this.$msg.error("操作失败!" + result.data.msg)
            this.addOrUpdate = false
            this.$msg.success("操作成功")
            this.close()
        },

        addStation() {
            this.resetForm();
            this.nodeListForm = [];
            this.sbmOptions = [];
            this.isforce = true;
            this.isEdit = false;
        },

        async goForce(data) {
            var sbmTypeName = this.sbmTypeFormat(data);
            data.sbmTypeName = sbmTypeName;
            this.$router.push({path: '/forceMsg', query: {data: JSON.stringify(data)}})
        },

        //选中删除
        async removeAll() {
            if (this.batchNum.length === 0) return this.$msg.info('请选择需要删除的项')
            const config = await this.$MessageBox.confirm(
                "此操作将永久删除选中项, 是否继续?",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }
            ).catch(e => e);
            if (config !== "confirm") return;
            try {
                const stationIdList = this.batchNum.map(item => {
                    return item.id
                }).join(",")

                const res = await deleteForce(stationIdList)
                if (res.status !== 200) return this.$msg.error('删除失败')
                this.$msg.success('删除成功')
                this.getForce()

            } catch (e) {
                console.log(e);
            }

        },

        async getForce() {
            try {
                const data = {
                    page: this.query.pageNum,
                    size: this.query.pageSize
                }
                var url = "page=" + this.query.pageNum + "&size=" + this.query.pageSize;
                if (this.nodeId != null) {
                    url += "&nodeId=" + this.nodeId
                }
                if (this.stationNo != '' && this.stationNo != null) {
                    url += "&stationNo=" + this.stationNo
                }
                if (this.stationValue != '' && this.stationValue != null) {
                    url += "&stationName=" + this.stationValue
                }
                if (this.treeData.operatorNameTree != "" && this.treeData.operatorNameTree != null) {
                    url += "&operatorId=" + this.treeData.operatorIdTree
                }
                data.url = url;

                const res = await checkForce(data);
                if (res.data.code !== 200) return this.$msg.error('获取场站基础信息失败!');
                let newList = res.data.data.list;

                console.log("=================newList=========[[", newList, "]]=================")

                this.tableData = newList;
                this.total = res.data.data.total;
            } catch (e) {
            }
        },

        //查询
        async submitCheck() {
            try {
                this.query.pageNum = 1;
                this.getForce()
            } catch (e) {
                console.log(e);
            }
        },

        close() {
            this.resetForm();
            this.casvderValue = []
            this.isAvater = false
            this.uploadImg = null
            this.fileAvater = null
            this.isAdd = true
            this.isforce = false
            this.sbmOptions = [];
            this.priceOptions = [];
            this.forceForm.stationLocation = ''
            this.$refs['forceform'].resetFields();
        },
        //excel导出
        async download() {
            const confirm = await this.$MessageBox.confirm('是否导出文件', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(e => e)
            if (confirm !== 'confirm') return
            const list = [
                {
                    label: '归属运营商',
                    prop: 'operatorName'
                },
                {
                    label: '换电站编码',
                    prop: 'stationNo'
                },
                {
                    label: '换电站名称',
                    prop: 'stationName'
                },
                {
                    label: '换电站编号',
                    prop: 'stationSn'
                },
                {
                    label: '区域名称',
                    prop: 'areaName'
                },
                {
                    label: '地理位置',
                    prop: 'stationLocation'
                },
                {
                    label: '经度',
                    prop: 'longitude'
                },
                {
                    label: '纬度',
                    prop: 'latitude'
                },
                /*
                                {
                                    label: '场站类型分类',
                                    prop: 'type'
                                },

                                {
                                    label: '工位分类',
                                    prop: 'staType'
                                },
                                {
                                    label: '区域分类',
                                    prop: 'areaType'
                                },
                                {
                                    label: '特殊配置',
                                    prop: 'specialConfig'
                                },
                                */

                {
                    label: '协议版本号',
                    prop: 'protocolVersion'
                },
                {
                    label: '功率(单位kWh):',
                    prop: 'chargePower'
                },
                /*
                {
                    label: '运行模式:',
                    prop: 'runModel'
                },
                */
                {
                    label: '营运时间',
                    prop: 'operationTime'
                },
                {
                    label: '投放时间',
                    prop: 'launchTime'
                },
                {
                    label: '负责人',
                    prop: 'principal'
                },
                {
                    label: '联系方式',
                    prop: 'contactWay'
                },
                {
                    label: '联系邮箱',
                    prop: 'email'
                },
                {
                    label: '所属运营企业',
                    prop: 'stationCompany'
                },
                {
                    label: '所属运营企业统一社会信用代码',
                    prop: 'socialCreditCode'
                },
                {
                    label: '站控主机软件版本号',
                    prop: 'stationSftVer'
                },
                {
                    label: '基础信息版本',
                    prop: 'version'
                },
                // {
                //     label: '运营状态',
                //     prop: 'operationStatus'
                // },
                // {
                //     label: '服务状态',
                //     prop: 'status'
                // },
                {
                    label: '创建时间',
                    prop: 'createTime'
                }
            ]

            //需要异步等待后台请求
            const res = await getForceList()
            if (res.data.code == 200) {
                this.stationDownList = res.data.data
                this.$download(this.downloadList(), list, '换电站基础信息')
            } else {
                return this.$msg.error("获取信息列表失败");
            }
        },

        downloadList() {
            if (this.batchNum.length === 0) {
                return this.stationDownList;
            } else {
                return this.batchNum;
            }

        },

        //下载模版
        dowtemplate() {
            const a = document.createElement("a");
            a.href = "/template/stationInfo.xlsx";
            a.download = "场站信息导入模板.xlsx";
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            a.remove();
        },

        dowtemplate1() {
            var url = "http://10.31.3.220:8099/template/stationInfo.xlsx";
            axios.get(url, {responseType: "blob"}).then(response => {
                const blob = new Blob([response.data]);
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = "station";
                link.click();
                URL.revokeObjectURL(link.href);
            }).catch(console.error);
        },


        dowtemplate2() {
            const fileName = "station.xlsx";
            const url = "http://10.31.3.220:8099/template/stationInfo.xlsx";
            const elink = document.createElement("a");
            elink.download = fileName;
            elink.style.display = "none";
            //elink.href = URL.createObjectURL(blob);
            //elink.href = url;
            elink.href = URL.createObjectURL(url);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
        },


        /**
         dowtemplate() {
        const list = [
          {
            stationNo:'100001',
            stationName: '00000',
            stationSn: 'ST00000000',
            stationLocation: '北京市东城区',
            stationType: '单通道左:1,单通道右:2，双通道:3',
            longitude: '116.15',
            latitude: '23.76',
            areaCode: '110000',
            areaName: '北京市',
            launchTime: '1997-12-12 12:00:00',
            contactWay: '18900000000',
            principal: '000',
            stationCompany: '000',
            socialCreditCode: '000',
            stationSftVer: '1.1.1',
          }
        ]
        this.$download(list, this.columns, "换电站基础信息")
      },
         **/



        cleanList() {
            this.$refs.forceTable.clearSelection()
        },
        //清空上传的文件
        closeLoad() {
            this.uploadDisabled = true
            this.$refs.upload.clearFiles();
        },
        //上传文件改变
        handleChange(file, fileList) {
            if (fileList.length > 0) {
                this.uploadDisabled = false
                this.fileList = fileList
            }
        },
        //上传文件之前
        beforeUpload(file) {
            let fileTemp = file.raw
            if (fileTemp) {
                if (fileTemp.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                    fileTemp.type === "application/vnd.ms-excel") {
                } else {
                    this.$msg.warning('附件格式错误，请删除后重新上传!')
                }
            }
        },
        //上传文件移除
        handleRemove(file, fileList) {
            this.fileList = fileList
            if (fileList.length < 1) {
                this.uploadDisabled = true
            }
        },
        //开始上传
        async handleUpload() {
            if (!this.fileList.length === 0) return this.$msg.info('请选择要上传的文件')
            let formData = new FormData()
            formData.append('file', this.fileList[0].raw)
            const res = await stationLeadFile(formData)
            if (res.data.code !== 200) return this.$msg.error('文件上传失败' + res.data.msg)
            this.$msg.success('文件上传成功')
            this.dialogImport = false
            this.getForce()
        },
        //删除文件之前的钩子
        async beforeRemove(file) {
            return this.$MessageBox.confirm(`确定移除 ${file.name}？`)
        },


        renderAreacode(val) {
            let newList = []
            val.forEach(item => {
                if (item.areaState === 1 && item.areaCode !== '0') {
                    let splitList = item.areaName.split(',')
                    let splitcode = item.areaCode.split(',')
                    if (splitList.length === 1) {
                        let i = newList.findIndex(c => c.value === item.areaCode)
                        i === -1 && newList.push({value: splitcode[0], label: splitList[0]})
                    } else if (splitList.length === 2) {
                        let i = newList.findIndex(c => c.value === splitcode[0])
                        if (i === -1) {
                            newList.push({
                                value: splitcode[0],
                                label: splitList[0],
                                children: [{value: splitcode[1], label: splitList[1]}]
                            })
                        } else {
                            let index = newList[i].children.findIndex(c => c.value === item.areaCode)
                            index === -1 && newList[i].children.push({value: splitcode[1], label: splitList[1]})
                        }
                    } else if (splitList.length === 3) {
                        let i = newList.findIndex(c => c.value === splitcode[0])
                        if (i === -1) {
                            newList.push({
                                value: splitcode[0],
                                label: splitList[0],
                                children: [{
                                    value: splitcode[1],
                                    label: splitList[1],
                                    children: [{value: splitcode[2], label: splitList[2]}]
                                }]
                            })
                        } else {
                            if (newList[i].children) {
                                let index = newList[i].children.findIndex(c => c.value === splitcode[1])
                                if (index === -1) {
                                    newList[i].children.push({
                                        value: splitcode[1],
                                        label: splitList[1],
                                        children: [{value: splitcode[2], label: splitList[2]}]
                                    })
                                } else {
                                    if (newList[i].children[index].children) {
                                        newList[i].children[index].children.push({
                                            value: splitcode[2],
                                            label: splitList[2]
                                        })
                                    } else {
                                        newList[i].children[index].children = []
                                        newList[i].children[index].children.push({
                                            value: splitcode[2],
                                            label: splitList[2]
                                        })
                                    }

                                }
                            } else {
                                newList[i].children = []
                                newList[i].children.push({
                                    value: splitcode[0],
                                    label: splitList[1],
                                    children: [{value: splitcode[1], label: splitList[2]}]
                                })
                            }
                        }
                    }
                }
            })
            this.options = newList
        },
        areaHandleChange() {
            let arerData = this.$refs.elcascader.getCheckedNodes()[0]
            let describe = []
            if (this.forceForm.areaCode.length === 1) {
                describe[0] = arerData.label
            } else if (this.forceForm.areaCode.length === 2) {
                describe[0] = arerData.parent.label
                describe[1] = arerData.label
            } else if (this.forceForm.areaCode.length === 3) {
                describe[0] = arerData.parent.parent.label
                describe[1] = arerData.parent.label
                describe[2] = arerData.label
            }
            this.copyCode = arerData.path
            this.forceForm.areaName = describe.join(",")
        },

        nodeIdFormat(row) {
            var nodeId = "未知";
            this.nodeList.filter(item => {
                if (item.id == row.nodeId) {
                    nodeId = item.node
                    return nodeId;
                }
            })
            return nodeId;
        },

        stationRunModelFormat(row) {
            var stationRunModelName = "未知";
            if (row.runModel == 1) {
                stationRunModelName = "云端模式";
            } else if (row.runModel == 0) {
                stationRunModelName = "本地模式";
            }
            return stationRunModelName;
        },

        sbmTypeFormat(row) {
            var sbmType = "未知";
            if(this.sbmList == null || this.sbmList === undefined || this.sbmList.length === 0){
                return sbmType;
            }
            this.sbmList.filter(item => {
                if (item.id == row.sbmId) {
                    sbmType = item.name
                    return sbmType;
                }
            })
            return sbmType;
        },

        handler({BMap, map}) {
            this.mapOpen()
            this.BMap = BMap
            this.map = map
            this.loadingMap = false
            this.geoc = new BMap.Geocoder() // 地址解析对象
        },
        checkedAddress() {
            if (this.pickPoint.lng && this.pickPoint.lat) {
                this.pointLngLat = this.pickPoint
                this.forceForm.latitude = this.pointLngLat.lat
                this.forceForm.longitude = this.pointLngLat.lng
                this.pointLngLat = {}
                this.mapDialogVisible = false
            } else {
                this.$msg.warning('请选择地理位置')
            }
        },

        forceUpdate(val) {
            console.log('forceForm', this.forceForm, val)
            this.$forceUpdate();
            // this.$set(this.forceForm,'sbmId', val)
            console.log('$forceUpdate')
        },


        getMap() {
            this.mapDialogVisible = true
            this.initMap()//地图初始化
            if (this.forceForm.areaCode && this.forceForm.stationLocation) {
                this.addressKeyword = CodeToText[this.forceForm.areaCode[0]] + CodeToText[this.forceForm.areaCode[1]] + CodeToText[this.forceForm.areaCode[2]] + this.forceForm.stationLocation
            } else {
                this.addressKeyword = CodeToText[this.forceForm.areaCode[0]] + CodeToText[this.forceForm.areaCode[1]] + CodeToText[this.forceForm.areaCode[2]]
            }
        },
        // 点击地图
        getClickInfo(e) {
            this.pickPoint = {lng: e.lnglat.getLng(), lat: e.lnglat.getLat()}
            var lnglat = [e.lnglat.getLng(), e.lnglat.getLat()]
            let $this = this
            var geocoder = new AMap.Geocoder();
            geocoder.getAddress(lnglat, function (status, result) {
                if (status === 'complete' && result.info === 'OK') {
                    $this.pointAddress = result.regeocode.formattedAddress
                    // result为对应的地理位置详细信息
                }
            })
            // this.pointLngLat = {lng: e.point.lng, lat: e.point.lat}
            // 获取点位信息
            // let $this = this
            // this.geoc.getLocation(e.point, function (rs) {
            //     var addComp = rs.addressComponents
            //     $this.pointAddress = addComp.province + addComp.city + addComp.district + addComp.street + addComp.streetNumber
            //     console.log('eeeeeeeee',$this.pointAddress)
            //
            // })
        },

        // 地图相关
        mapOpen() {
            if (this.forceForm.areaCode && this.forceForm.stationLocation) {
                this.addressKeyword = CodeToText[this.forceForm.areaCode[0]] + CodeToText[this.forceForm.areaCode[1]] + CodeToText[this.forceForm.areaCode[2]] + "" + this.forceForm.stationLocation
            } else {
                this.addressKeyword = CodeToText[this.forceForm.areaCode[0]] + CodeToText[this.forceForm.areaCode[1]] + CodeToText[this.forceForm.areaCode[2]]
            }
            this.top = this.getScrollTop()
            if (this.top) {
                this.setScrollTop(0)
            }
        },

        // 关闭地图后调用
        mapClose() {
            this.setScrollTop(this.top)
            this.top = 0
            this.showMap = false
        },

        getScrollTop() {
            let scrollTop = 0
            if (document.documentElement && document.documentElement.scrollTop) {
                scrollTop = document.documentElement.scrollTop
            } else if (document.body) {
                scrollTop = document.body.scrollTop
            }
            return scrollTop
        },
        setScrollTop(top) {
            if (!isNaN(top)) {
                if (document.documentElement && document.documentElement.scrollTop !== undefined) {
                    document.documentElement.scrollTop = top
                } else if (document.body) {
                    document.body.scrollTop = top
                }
            }
        },

        resetForm() {
            this.forceForm = {
                stationName: '',
                stationSn: null,
                stationLocation: '',
                longitude: '',
                latitude: '',
                chargePower: null,
                areaCode: [],
                cover: null,
                areaName: '',
                operationTime: null,
                launchTime: null,
                principal: '',
                email: '',
                stationCompany: '',
                socialCreditCode: '',
                stationSftVer: '',
                stationNo: '',
                operatorId: null,
                version: null,
                nodeId: null,
                protocolVersion: null,
                deviceVersion: null,
                sbmId: null,
                priceId: null,
                runModel: 1,

                staType: null,
                areaType: null,
                specialConfig: null,
                type: null,
                appAccess: 1
            }
        },

        getTreeData(data) {
            this.treeData = data
        },

        /** 查询运营商下拉树结构 */
        async getOperatorTree() {
            try {
                this.treeData.operatorOptions = [];
                let res = await operatorTree();
                if (res.status !== 200) return this.$msg.error("获取运营商信息失败");
                this.treeData.operatorOptions.push(res.data.data)
                this.treeData.operatorIdTree = res.data.data.id
                this.treeData.operatorNameTree = res.data.data.label
            } catch (error) {
            }
        },
    }

};
</script>

<style lang='less' scoped>
.app-container {
    background: rgba(255, 255, 255, 0);
    padding-left: 0px;
}

::v-deep .fixstyle {
    padding-left: 0 !important;
}

.el-table {
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
        width: 10px; /*滚动条宽度*/
        height: 10px; /*滚动条高度*/
    }

    /*定义滚动条轨道 内阴影+圆角*/

    /deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
        //   box-shadow: 0px 1px 3px #fff inset; /*滚动条的背景区域的内阴影*/
        border-radius: 10px; /*滚动条的背景区域的圆角*/
        background-color: #fff; /*滚动条的背景颜色*/
    }

    /*定义滑块 内阴影+圆角*/

    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
        //   box-shadow: 0px 1px 3px #dddee0 inset; /*滚动条的内阴影*/
        border-radius: 10px; /*滚动条的圆角*/
        background-color: #dddee0; /*滚动条的背景颜色*/
    }
}

.bm-view {
    width: 100%;
    height: 200px;
}


li {
    list-style: none;
}

.el-select, .el-cascader {
    width: 100%;
}

.el-dropdown {
    //margin-left: 25px;
}

.states {
    > i {
        display: inline-block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        margin-right: 5px;
        margin-bottom: 3px;
    }
}

.box1 {
    display: flex;
    align-items: center;
    margin: 0 !important;

    span {
        position: relative;
        font-size: 18px;
        font-weight: 700;
        margin-left: 20px;
    }

    .text::before {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        background-color: #1890ff;
        border-radius: 50%;
        top: 50%;
        left: -13px;
        transform: translate(0, -50%);
    }
}

.content-hander {
    height: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-left: 3px solid #2980e1;
    padding-left: 15px;

    > h3 {
        font-size: 16px;
        font-weight: 700;
    }
}

.content-title {
    height: 200px;

    .el-col {
        height: 100%;

        > img {
            width: 100%;
            height: 100%;
            box-shadow: 0 0 25px #333;
        }
    }

    .content-title-right {
        margin-left: 50px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        > ul {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 100%;
            height: 100%;
        }
    }
}

.msg-title {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 0;
    margin: 0 !important;

    span {
        color: #333;
    }

    .text1 {
        color: #595959;
        margin-left: 15px;
    }
}

.content-map {
    width: 100%;
    height: 100%;
    border: 1px solid #eee;
}

.el-divider--horizontal {
    margin-bottom: 0;
}

.fileImg {
    display: flex;
    align-items: flex-start;

    div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 120px;
        height: 120px;
        border: 1px dashed #ccc;
        border-radius: 6px;
        margin-right: 20px;
        cursor: pointer;

        > img {
            width: 100%;
            height: 100%;
            border-radius: 6px;
        }
    }

    div:hover {
        border: 1px dashed #1b80e1;

        i {
            color: #1b80e1;
        }
    }

}

.dowload {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
        display: flex;
        align-items: center;

        &:first-child {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            > span {
                font-size: 12px;
                color: #e10505;
            }
        }

        > button {
            margin-top: 0 !important;
        }
    }
}

.main-flex {
    display: flex;

}

.mian-content {
    flex: 1;
    //padding:0 16px 0 0;
    background: #F5F7FA;


}

.side-search {
    width: 200px;
    background: #FFFFFF;
    border-right: #d3dce6 1px solid;
}

.btn-end {
    display: flex;
    justify-content: flex-end;
}

.left-style {
    padding-left: 43px;
}

.head-section {
    padding: 16px;
    background: #FFFFFF;
    overflow: hidden;

    /deep/ .el-input__inner {
        width: 260px;
        height: 36px;
        line-height: 36px;
    }
}

.choose-mian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
        display: inline-block;
        margin-left: 16px;
    }
}

.alert-box {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
}

.section-main {
    width: 100%;
    //height: calc(100vh - 24px);
    background: #FFFFFF;
    margin-top: 16px;
    padding: 24px 16px 16px 16px;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.table-box {
    width: 100%;
}

.pagin {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.small-side {
    width: 36px;
    height: 36px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.14);
    border-right: 1px solid #EEE;
}

#change_AMAP {
    //overflow: hidden;
    width: 100%;
    height: 400px;
    margin: 32px 0 0 0;
}

.address-inf {
    border: 1px solid #EEEEEE;
    padding: 8px 16px;
    border-radius: 4px;
}

</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';

.operationBtn {
    color: #0D6CE4;
    display: flex;
    justify-content: space-around;

    span {
        display: inline-block;
        margin-left: 8px;
        cursor: pointer;
    }

    width: 100%;
    display: flex;

}

/* 隐藏高德logo  */
.amap-logo {
    display: none !important;
}

/* 隐藏高德版权  */
.amap-copyright {
    display: none !important;
}

.el-cascader-menu__wrap {
    height: 100%;
}
</style>
