<template>
  <el-card>
    <el-row>
      <el-form :inline="true">
        <el-col :span="3">
          <el-input placeholder="请输版本名" v-model.trim=" informationVo.fversionsName"></el-input>
        </el-col>
      </el-form>
      <el-col :span="3">
        <el-input placeholder="请输版本号" v-model.trim=" informationVo.fversionsNo"></el-input>
      </el-col>
      <el-col :span="4">
        <el-button @click="getAllPage()" type="primary" icon="el-icon-search">查询</el-button>
        <el-button @click="resFuntion">重置</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-button size="small" @click="addOrUpdate =true" class="el-icon-plus" type="primary"> 新增</el-button>
      <el-button size="small" @click="removeAll" type="danger" icon="el-icon-delete">选中删除</el-button>
    </el-row>
    <el-row>
      <el-alert show-icon type="info">
        <span slot="title">
          已选择{{batchNum.length}}项
           <span @click="cleanList" style="margin-left:10px;color:#409eff;cursor:pointer;">清空</span>
        </span>
      </el-alert>
    </el-row>
    <el-row>
      <el-table ref="equipTable" id="equipment" @selection-change="batchList" :data="tableData" style="width: 100%"
                height="500">
        <el-table-column type="selection"></el-table-column>
        <el-table-column label="序号" type="index" width="80" :index="renIndex"></el-table-column>
        <el-table-column label="版本名" prop="fversionsName"></el-table-column>
        <el-table-column label="版本号" prop="fversionsNo"></el-table-column>
        <el-table-column label="应用大小（M）" prop="fsize" min-width="150"></el-table-column>
        <el-table-column label="是否强制更新" prop="fforceUpdata" min-width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.fforceUpdata===0">不更新</span>
            <span v-if="scope.row.fforceUpdata===1">可以更新</span>
            <span v-if="scope.row.fforceUpdata===2">强制更新</span>
          </template>
        </el-table-column>
        <el-table-column label="发布日期" prop="freleData" min-width="200"></el-table-column>
        <el-table-column label="保存地址" prop="fpath" min-width="300"></el-table-column>
        <!--        <el-table-column label="保存地址" prop="fpath" min-width="150">-->
        <!--          <template slot-scope="scope">-->
        <!--            <el-image-->
        <!--              :src="scope.row.fpath"-->
        <!--              lazy-->
        <!--            ></el-image>-->
        <!--          </template>-->
        <!--        </el-table-column>-->

        <el-table-column label="MD5值" prop="fapkMd" min-width="300"></el-table-column>
        <el-table-column label="更新内容" prop="fcontent" min-width="100"></el-table-column>
        <el-table-column label="创建时间" prop="fcreateTime" min-width="170"></el-table-column>
        <el-table-column label="修改时间" prop="fmodifyTime" min-width="170"></el-table-column>
        <el-table-column label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <el-button @click="compileEqu(scope.row)" size="small" type="primary" icon="el-icon-edit">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>

    <el-row>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNum"
        :page-sizes="[5, 10, 15]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </el-row>

    <el-dialog :title="isequipemt?'新增信息':'修改信息'" :visible.sync="addOrUpdate" width="60%" @close="close">
      <el-form size="small" ref="equipform" :rules="equipmentRules" :model="informationForm" label-width="120px">

        <el-row>
          <el-col :span="12">
            <el-form-item label="版本名:" prop="fversionsName">
              <el-input v-model="informationForm.fversionsName"></el-input>
            </el-form-item>

            <el-form-item label="版本号:" prop="fversionsNo">
              <el-input v-model="informationForm.fversionsNo"></el-input>
            </el-form-item>

            <!--            <el-form-item label="应用大小（M）:" prop="fsize"  v-if="isequipemt">-->
            <!--              <el-input v-model="informationForm.fsize"></el-input>-->
            <!--            </el-form-item>-->
            <!--            <el-form-item label="MD5基础值:" prop="fapkMd" v-if="isequipemt">-->
            <!--              <el-input v-model="informationForm.fapkMd"></el-input>-->
            <!--            </el-form-item>-->
            <!--            action="http://114.96.101.240:9107/upload/uploadFile"-->
            <el-form-item label="APK文件:" prop="fpath" v-if="isequipemt">
              <el-upload
                class="upload-demo"
                ref="upload"
                action="http://114.96.101.240:9107/upload/uploadFile"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :on-success="handleSuccess"
                :on-error="handleError"
                :file-list="fileList"
                :auto-upload="false"
              >
                <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器
                </el-button>
                <!--                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>-->
              </el-upload>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <!--            <el-form-item label="发布日期:" prop="freleData">-->
            <!--              <el-input v-model="informationForm.freleData"></el-input>-->
            <!--            </el-form-item>-->
            <el-form-item label="是否强制更新:" prop="fforceUpdata">
              <el-select v-model="informationForm.fforceUpdata">
                <el-option
                  v-for="item in selectOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <!--              <el-input v-model="informationForm.fstatus"></el-input>-->
            </el-form-item>
            <el-form-item label="更新内容:" prop="fcontent">
              <el-input v-model="informationForm.fcontent" type="textarea"></el-input>
            </el-form-item>

          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addOrUpdate = false">取 消</el-button>
        <el-button type="primary" @click="newequipemt">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
  import {
    apkManagePage,
    AddApkManage,
    UpdateApkManage,
    DeleteApkManage
  } from '@/utils/updateInfo'
  import {mapState} from "vuex";
  import {uploadFile1} from "@/utils/user";
  import Avater from '@/components/avater/index'

  export default {
    // components: {
    //   Avater
    // },
    // name: 'recharge',
    data() {
      return {
        uploadImg: null,
        isAvater: true,
        fileAvater: null,
        fileList: [],
        selectOptions: [
          {
            value: 0,
            label: "不更新",
          },
          {
            value: 1,
            label: "可以更新",
          },
          {
            value: 2,
            label: "强制更新",
          },
        ],
        coulm: [
          // {prop:'fid',label:'序号'},
          {prop: 'fthValNo', label: '报警阀值编号'},
          {prop: 'fthValName', label: '报警阀值名称'},
          {prop: 'feqmNo', label: '设备编号'},
          {prop: 'feqmNo', label: '设备编号'},
          {prop: 'feqmName', label: '设备名称'},
          {prop: 'feqmModeNo', label: '设备类型编号'},
          {prop: 'feqmModeName', label: '设备类型名称'},
          {prop: 'fthValue', label: '报警值'},
          {prop: 'fthValType', label: '报警值类别'},
          {prop: 'fthValType', label: '报警阈值类别'},
          {prop: 'fremark', label: '备注'},
          {prop: 'fcreateTime', label: '创建时间'},
          {prop: 'fmodifyTime', label: '修改时间'}
        ],
        informationVo: {},
        isPage: true,//查询
        query: {
          pageNum: 1,
          pageSize: 5
        },
        total: 0,
        addOrUpdate: false,
        isequipemt: true,
        batchNum: [],
        tableData: [],
        srcList: [],
        allList: [],
        stateList: [
          {
            value: 1,
            label: '在役'
          },
          {
            value: 0,
            label: '退役'
          }
        ],
        informationForm: {},
        equipmentRules: {

          fversionsName: [
            {required: true, message: '请输入版本名', trigger: 'blur'}
          ],
          fversionsNo: [
            {required: true, message: '请输入版本号', trigger: 'blur'}
          ],
          // fpath: [
          //   {required: true, message: '请上传文件', trigger: 'blur'}
          // ],
          fforceUpdata: [
            {required: true, message: '请选择文件更新类型', trigger: 'blur'}
          ],
          fcontent: [
            {required: true, message: '请输入更新内容', trigger: 'blur'}
          ],

        },
        url: '',
        brandList: [],
        unitTypeList: [],
      }
    },
    computed: {
      ...mapState(['user']),
      username() {
        return JSON.parse(this.user).name
      }
    },
    created() {
      this.informationForm.fcreator = this.username
      this.getAllPage()
      this.getCurrentTime()
      // this. getAllList()
    },
    methods: {
      getCurrentTime() {
        //获取当前时间并打印
        var _this = this;
        let yy = new Date().getFullYear();
        let mm = new Date().getMonth() + 1;
        let dd = new Date().getDate();
        let hh = new Date().getHours();
        let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
        let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
        _this.gettime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss;
        console.log(_this.gettime)
        this.newTime = _this.gettime
      },
      async submitUpload() {
        this.$refs.upload.submit()
      },
      //上传文件改变
      // handleChange(file, fileList) {
      //   this.url = file.response.data.ipAddress + file.response.data.filePath
      // },
      handleError(err, file, fileList) {
        this.$msg.error("文件上传失败，请重新上传")
      },
      handleSuccess(response, file, fileList) {
        this.url = file.response.data.ipAddress + file.response.data.filePath
        this.fileMd5 = file.response.data.fileMd5
        this.fileSize = file.response.data.fileSize
      },
      handleRemove(file, fileList) {
        this.url = file.response.data.ipAddress + file.response.data.filePath
        console.log(file, fileList)
      },

      handlePreview(file) {
        console.log(file)
        this.url = file.response.data.ipAddress + file.response.data.filePath
        console.log(this.url)
      },
      renIndex(index) {
        return (this.query.pageNum - 1) * this.query.pageSize + index + 1
      },

      //清空上传的文件
      closeLoad() {
        this.uploadDisabled = true
        this.$refs.upload.clearFiles()
      },
      //上传文件改变
      // handleChange(file, fileList) {
      //   if (fileList.length > 0) {
      //     this.uploadDisabled = false
      //     this.fileList = fileList
      //   }
      // },
      //上传文件之前
      beforeUpload(file) {
        let fileTemp = file.raw
        if (fileTemp) {
          if (fileTemp.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            fileTemp.type === "application/vnd.ms-excel") {
          } else {
            this.$msg.warning('附件格式错误，请删除后重新上传!')
          }
        }
      },
      //上传文件移除
      // handleRemove(file,fileList){
      //   this.fileList = fileList
      //   if(fileList.length < 1){
      //     this.uploadDisabled = true
      //   }
      // },
      //开始上传
      async handleUpload() {
        this.$refs.upload.submit();
      },
      //删除文件之前的钩子
      async beforeRemove(file) {
        return this.$MessageBox.confirm(`确定移除 ${file.name}？`)
      },
      async getAllPage() {
        const result = await apkManagePage(this.query.pageNum, this.query.pageSize, this.informationVo)
        console.log(result)
        if (result.data.code !== 20000)
          return this.$msg.error('获取能耗设备型号信息失败!')
        this.tableData = result.data.data.rows.records
        this.total = result.data.data.rows.total
        this.srcList = this.tableData.ffilePath

      },
      //重置
      resFuntion() {
        this.informationVo = {}
        this.getAllPage()
      },
      batchList(list) {
        this.batchNum = list
      },
      close() {
        const data = {
          fcreator: this.username,
        }
        this.$refs.equipform.resetFields()
        this.isAvater = false
        this.uploadImg = null
        this.fileAvater = null
        this.informationForm = data
        this.isequipemt = true
      },

      //新增与修改
      newequipemt() {

        this.$refs.equipform.validate(async valid => {
          if (!valid) return this.$msg.info('信息不能为空!')
          const data = JSON.parse(JSON.stringify(this.informationForm))
          // data.releaseDate = moment(data.releaseDate).format('x')
          console.log(this.url)
          data.fpath = this.url
          data.fsize = this.fileSize
          data.fapkMd = this.fileMd5
          data.freleData = this.newTime
          console.log(data)
          try {
            if (this.isequipemt) {
              const res = await AddApkManage(data)
              // console.log(res)
              if (res.data.code !== 20000) return this.$msg.error('新增信息失败!')
              this.$msg.success('新增信息成功!')
              this.addOrUpdate = false
              this.getAllPage()
            } else {
              console.log(data)
              const res = await UpdateApkManage(data)
              console.log(res)
              if (res.data.code !== 20000) return this.$msg.error(res.data.message)
              this.$msg.success('修改换信息成功!')
              this.addOrUpdate = false
              this.getAllPage()
            }
          } catch (error) {
          }
        })

      },
      //修改信息填充
      compileEqu(list) {
        this.addOrUpdate = true
        this.isequipemt = false
        var data = JSON.parse(JSON.stringify(list))
        this.informationForm = data
        this.uploadImg = data.ffilePath
      },

      handleSizeChange(val) {
        this.query.pageSize = val
        if (this.isPage) {
          this.getAllPage()
        } else {
          this.getAllPage()
        }
      },
      handleCurrentChange(val) {
        this.query.pageNum = val
        if (this.isPage) {
          this.getAllPage()
        } else {
          this.getAllPage()
        }
      },
      // //获取所有电池型号信息
      // async getAllList(){
      //   const res =await getVehicleModelsAll()
      //   this.allList = res.data.data
      //   console.log(res)
      // },
      async excelAll() {
        const confirm = await this.$MessageBox.confirm('是否导出文件', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(e => e)
        if (confirm !== 'confirm') return
        if (this.batchNum.length === 0) {
          this.$download(this.allList, this.coulm, '报警阈值管理')
        } else {
          this.$download(this.batchNum, this.coulm, '报警阈值管理')
        }
      },
      cleanList() {
        this.$refs.equipTable.clearSelection()
      },
      //批量删除
      async removeAll() {
        if (this.batchNum.length === 0) return this.$msg.info('请选择需要删除的项')
        const config = await this.$MessageBox.confirm(
          "此操作将永久删除选中项, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }
        ).catch(e => e);
        if (config !== "confirm") return;
        try {
          const list = this.batchNum.map(item => {
            return item.fid
          })
          const res = await DeleteApkManage(list)
          if (res.data.code !== 20000) return this.$msg.error('删除失败')
          this.tableData = this.tableData.filter((item, i) => {
            return item.id !== list[i]
          })
          this.$refs.equipTable.clearSelection()
          if (this.query.pageNum > 1 && this.tableData.length === 0) {
            this.query.pageNum = this.query.pageNum - 1
          }
          this.$msg.success('删除成功')
          this.getAllPage()
        } catch (e) {
          console.log(e);
        }
      }
    },
  }
</script>

<style lang='less' scoped>
  .el-row {
    margin: 15px 0;

    .el-col {
      margin-right: 20px;
    }
  }

  .el-select {
    width: 100%;
  }

  .el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 100%;
  }

</style>
