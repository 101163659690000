<template>
    <el-card>
        <el-row>
            <!--      <el-col :span="4">-->
            <!--        <el-cascader-->
            <!--          @change="changeCascader"-->
            <!--          filterable-->
            <!--          v-model="cascaderValue"-->
            <!--          :options="options"-->
            <!--          :props="{checkStrictly: true}"-->
            <!--          placeholder="请选择区域名称"-->
            <!--        ></el-cascader>-->
            <!--      </el-col>-->
            <!--      <el-col :span="4">-->
            <!--        <el-select @change="changeSelect" v-model="stationType" placeholder="请选择换电站类型">-->
            <!--          <el-option-->
            <!--            v-for="item in stationTypeList"-->
            <!--            :key="item.value"-->
            <!--            :label="item.label"-->
            <!--            :value="item.value"-->
            <!--          ></el-option>-->
            <!--        </el-select>-->
            <!--      </el-col>-->
            <el-col :span="4">
                <el-select @change="changeStation" v-model="stationValue" filterable placeholder="请选择换电站">
                    <el-option
                        v-for="item in stationList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </el-col>
            <el-col :span="4">
                <el-select v-model="equimentValue" filterable placeholder="请选择设备名称">
                    <el-option

                        v-for="item in equimentList"
                        :key="item.id"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </el-col>
            <el-col :span="4">
                <el-select v-model="levelValue" placeholder="请选择故障等级">
                    <el-option
                        v-for="(item,i) in levelList"
                        :key="i"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </el-col>
            <el-col :span="4">
                <el-date-picker
                    v-model="timeValue"
                    type="daterange"
                    range-separator="~"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
            </el-col>
            <el-col :span="4">
                <el-button @click="searchLevel" type="primary">查询</el-button>
                <el-button @click="resFuntion">重置</el-button>
            </el-col>
        </el-row>
        <el-row>
            <!-- <el-button size="small" @click="processed=true" class="el-icon-plus" type="primary">新增</el-button> -->
            <el-button size="small" @click="excelAll" class="el-icon-download" type="primary"> 批量导出</el-button>
        </el-row>
        <el-row>
            <el-table @selection-change="selectionChange" :data="tableData" style="width: 100%" height="300">
                <el-table-column type="selection" width="40"></el-table-column>
                <el-table-column label="编号" :index="renIndex" type="index" width="50"></el-table-column>
                <el-table-column prop="stationNo" label="换电站编码" min-width="120"></el-table-column>

                <el-table-column prop="stationName" label="换电站名称" min-width="130"></el-table-column>
                <el-table-column prop="eqmNo" label="设备编号"></el-table-column>
                <el-table-column prop="faultCode" label="故障码"></el-table-column>
                <el-table-column prop="faultLevel" label="故障等级">
                    <template slot-scope="scope">
                        <span>{{ levelChange(scope.row.faultLevel) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="faultContent" label="故障内容" min-width="150"></el-table-column>
                <el-table-column prop="repairMethod" label="处理方法"></el-table-column>
                <el-table-column prop="colMode" label="采集模式">
                    <template slot-scope="scope">
                        <span v-if="scope.row.colMode === 1">自动</span>
                        <span v-else>人工录入</span>
                    </template>
                </el-table-column>
                <el-table-column prop="begainTime" label="开始时间" :formatter="$dateFormat"></el-table-column>
                <el-table-column prop="endTime" label="结束时间" :formatter="$dateFormat"></el-table-column>
                <!-- <el-table-column label="操作" fixed="right">
                  <template slot-scope="scope">
                    <el-button
                      @click="compileUndis(scope.row)"
                      size="small"
                      type="primary"
                      icon="el-icon-edit"
                    >编辑</el-button>
                  </template>
                </el-table-column> -->
            </el-table>
        </el-row>
        <el-row>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="query.pageNum"
                :page-sizes="[20, 25, 30]"
                :page-size="query.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </el-row>
        <el-dialog
            :title="isprocessed?'新增已处理故障信息':'修改已处理故障信息'"
            :visible.sync="processed"
            width="50%"
            @close="close"
        >
            <el-form
                ref="processedform"
                size="small"
                :rules="processedRules"
                :model="processedForm"
                label-width="120px"
            >
                <el-row>
                    <el-col :span="11">
                        <el-form-item label="换电站编码:">
                            <el-select v-model="processedForm.stationNo" placeholder="请选择换电站编码">
                                <el-option
                                    v-for="item in stationList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="设备编号:" prop="eqmNo">
                            <el-select v-model="processedForm.eqmNo" placeholder="请选择设备名称">
                                <el-option
                                    v-for="item in equimentList"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.value"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="故障等级:">
                            <el-select v-model="processedForm.faultLevel" placeholder="请选择故障等级">
                                <el-option
                                    v-for="(item,i) in levelList"
                                    :key="i"
                                    :label="item.label"
                                    :value="item.value"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="故障码:">
                            <el-input v-model="processedForm.faultCode"></el-input>
                        </el-form-item>
                        <el-form-item label="采集模式:" prop="colMode">
                            <el-radio-group v-model="processedForm.colMode">
                                <el-radio-button label="1">自动</el-radio-button>
                                <el-radio-button label="2">人工录入</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item label="故障内容:" prop="faultContent">
                            <el-input
                                type="textarea"
                                :rows="2"
                                placeholder="请输入内容"
                                v-model="processedForm.faultContent"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="处理方法:">
                            <el-input type="textarea" :rows="2" v-model="processedForm.repairMethod"></el-input>
                        </el-form-item>
                        <el-form-item label="开始时间:" prop="begainTime">
                            <el-date-picker
                                v-model="processedForm.begainTime"
                                type="datetime"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                placeholder="选择日期时间"
                            ></el-date-picker>
                        </el-form-item>
                        <el-form-item label="结束时间:" prop="endTime">
                            <el-date-picker
                                v-model="processedForm.endTime"
                                type="datetime"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                placeholder="选择日期时间"
                            ></el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="processed = false">取 消</el-button>
                <el-button type="primary" @click="newprocessed">确 定</el-button>
            </span>
        </el-dialog>
    </el-card>
</template>

<script>
import {
    getProcessedPage,
    getProcessed,
    addProcessed,
    setProcessed,
    getLevel
} from "@/utils/faults";
import {getForceList, regionCodeSation, getCodingListAll, getCodingAll} from "@/utils/station"
import {regionData} from "element-china-area-data"
import * as myStore from "@/utils/stroage";
import moment from 'moment'

export default {
    name: "processed",
    data() {
        return {
            stationListAll: [],
            cascaderValue: [],
            stationType: "",
            stationValue: '',
            equimentValue: '',
            levelValue: '',
            timeValue: [],
            query: {
                pageNum: 1,
                pageSize: 20,
                resPageNum: 1
            },
            isPage: true,
            tableData: [],
            options: regionData,
            batchNum: [],
            processed: false,
            isprocessed: true,
            stationList: [],
            bartchNum: [],
            stationTypeList: [//换电站类型
                {
                    value: '0',
                    label: "A类型",
                },
                {
                    value: '1',
                    label: "B类型",
                },
            ],
            levelList: [],
            equimentList: [],//设备名称
            processedForm: {
                begainTime: "",
                endTime: "",
                colMode: 1,
                eqmNo: "",
                faultCode: "",
                faultContent: "",
                faultLevel: null,
                repairMethod: "",
                stationNo: "",
            },
            processedRules: {
                begainTime: [
                    {required: true, message: "请输入开始时间", trigger: "blur"},
                ],
                endTime: [
                    {required: true, message: "请输入结束时间", trigger: "blur"},
                ],
                colMode: [
                    {required: true, message: "请输入采集模式", trigger: "blur"},
                ],
                eqmNo: [{required: true, message: "请输入设备编号", trigger: "blur"}],
                faultContent: [
                    {required: true, message: "请输入故障内容", trigger: "blur"},
                ]
            },
            selectList: [],
            coulm: [
                {prop: 'stationNo', label: '换电站编码'},
                {prop: 'eqmNo', label: '设备编号'},
                {prop: 'faultCode', label: '故障码'},
                {prop: 'faultLevel', label: '故障等级'},
                {prop: 'faultContent', label: '故障内容'},
                {prop: 'repairMethod', label: '处理方法'},
                {prop: 'colMode', label: '采集模式'},
                {prop: 'begainTime', label: '开始时间'},
                {prop: 'endTime', label: '结束时间'},
            ]
        };
    },
    computed: {
        cascader() {
            // let regin = null
            // if (this.cascaderValue.length === 1) {
            //   regin = this.cascaderValue[0]
            // } else if (this.cascaderValue.length === 2) {
            //   regin = this.cascaderValue[1]
            // } else if (this.cascaderValue.length === 3) {
            //   regin = this.cascaderValue[2]
            // } else {
            //   regin
            // }
            // return regin
            return this.cascaderValue.join(',')
        },
        timeList() {
            let startTime = ''
            let endTime = ''
            if (this.timeValue.length === 0) {
                startTime = ''
                endTime = ''
            } else {
                startTime = moment(this.timeValue[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss')
                endTime = moment(this.timeValue[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss')
            }
            return {
                startTime,
                endTime
            }
        },
        selectBatch() {
            if (this.bartchNum.length === 0) {
                return this.selectList.map(item => {
                    let val = this.levelList.find(c => c.value === item.faultLevel)
                    item.faultLevel = val.label
                    item.colMode = item.colMode === 1 ? '自动' : '人工录入'
                    return item
                })
            } else {
                return this.bartchNum.map(item => {
                    let val = this.levelList.find(c => c.value === item.faultLevel)
                    item.faultLevel = val.label
                    item.colMode = item.colMode === 1 ? '自动' : '人工录入'
                    return item
                })
            }
        }
    },
    created() {
        // this.getFaultUn()
        this.getStation()
        this.getLevelAll()
        this.getEqimAll()
        this.getFaultAll()
    },
    methods: {
        async getFaultUn() {
            try {
                const data = {
                    page: this.query.pageNum,
                    size: this.query.pageSize,
                };
                const res = await getProcessedPage(data);
                if (res.status !== 200)
                    return this.$msg.error("获取已处理故障信息失败!");
                // this.tableData = res.data.data.content;
                this.tableData = this.getRender(res.data.data.content)
                this.total = res.data.data.totalElements;
            } catch (error) {
            }
        },
        getRender(val) {
            return val.map(item => {
                let i = this.stationListAll.findIndex(c => c.value === item.stationNo)
                i !== -1 ? item.stationName = this.stationListAll[i].label : item.stationNam = ''
                return item
            })
        },
        stationRender(val) {
            return val.map(item => {
                let obj = this.stationList.find(c => c.value === item.stationNo)
                item.stationName = obj.label
                return item
            })
        },
        //获取故障等级
        async getLevelAll() {
            const result = await getLevel()
            this.levelList = result.data.data.map(item => {
                return {
                    value: item.level,
                    label: item.levelName
                }
            })
        },
        //获取换电站编码
        async getStation() {
            const res = await getForceList();
            let newList = res.data.data;
            this.stationList = newList.map((item) => {
                return {
                    value: item.stationNo,
                    label: item.stationName,
                };
            });
            this.stationListAll = JSON.parse(JSON.stringify(this.stationList))
            this.getFaultUn()
        },
        //获取所有设备名称
        async getEqimAll() {
            const res = await getCodingAll()
            let newList = res.data.data
            this.equimentList = newList.map((item, i) => {
                return {
                    value: item.eqmNo,
                    label: item.eqmmodeNo,
                    id: i
                }
            })
        },
        selectionChange(selection) {
            this.bartchNum = selection
        },
        async getFaultAll() {
            const res = await getProcessed()
            console.log(res)
            this.selectList = res.data.data
        },
        async searchLevel() {
            try {

                if (
                    this.cascader === "" && this.stationValue === "" &&
                    this.stationType === '' && this.equimentValue === '' &&
                    this.levelValue === '' && this.timeValue.length === 0
                ) return;

                const data = {
                    stationNo: this.stationValue,
                    stationType: this.stationType,
                    regionCode: this.cascader,
                    faultLevel: this.levelValue,
                    startTime: this.timeList.startTime,
                    endTime: this.timeList.endTime,
                    eqmNo: this.equimentValue
                };
                this.storage('faultLogging', data)
                data.page = this.query.resPageNum
                data.size = this.query.pageSize
                const res = await getProcessedPage(data);
                if (res.status !== 200) return this.$msg.error("查询已处理故障信息失败!");
                this.tableData = this.getRender(res.data.data.content)
                // this.tableData = res.data.data.content;
                this.total = res.data.data.totalElements;
                this.isPage = false
            } catch (error) {
            }
        },
        renIndex(index) {
            return (this.query.pageNum - 1) * this.query.pageSize + index + 1
        },
        //新增和修改
        newprocessed() {
            try {
                this.$refs.processedform.validate(async (valid) => {
                    if (!valid) return this.$msg.info("已处理故障记录不能为空!");
                    var data = JSON.parse(JSON.stringify(this.processedForm));
                    if (this.isprocessed) {
                        const res = await addProcessed(data);
                        if (res.status !== 200)
                            return this.$msg.error("新增已处理故障记录失败!");
                        this.$msg.success("新增已处理故障记录成功!");
                        this.processed = false;
                        this.getFaultUn();
                    } else {
                        const res = await setProcessed(data);
                        if (res.status !== 200)
                            return this.$msg.error("修改已处理故障记录失败!");
                        this.$msg.success("修改已处理故障记录成功!");
                        this.processed = false;

                        this.getFaultUn();
                    }
                });
            } catch (error) {
            }
        },
        //修改填充信息
        compileUndis(data) {
            this.processed = true;
            this.isprocessed = false;
            this.processedForm = JSON.parse(JSON.stringify(data));
        },
        async changeCascader() {
            this.stationValue = ''
            this.equimentValue = ''
            const data = {
                regionCode: this.cascader,
                stationType: this.stationType
            }
            const res = await regionCodeSation(data)
            if (res.status !== 200) return this.$msg.error('换电站查询失败!')
            if (res.data.data.length !== 0) {
                this.stationList = res.data.data.map(item => {
                    return {
                        value: item.stationNo,
                        label: item.stationName
                    }
                })
            } else {
                this.stationList = []
            }
            if (this.stationList.length !== 0) {
                const newList = JSON.parse(getItem('faultLogging'))
                if (newList && newList[1].regionCode === this.cascader && newList[0].index > 3) {
                    this.stationValue = newList[1].stationNo
                    this.stationType = newList[1].stationType
                    this.cascaderValue = [newList[1].regionCode]
                    this.levelValue = newList[1].faultLevel
                    this.timeValue = [newList[1].startTime, newList[1].endTime]
                    this.equimentValue = newList[1].eqmNo
                }
            }
        },
        async changeSelect() {
            this.stationValue = ''
            this.equimentValue = ''
            const data = {
                regionCode: this.cascader,
                stationType: this.stationType
            }
            const res = await regionCodeSation(data)
            if (res.status !== 200) return this.$msg.error('换电站查询失败!')
            if (res.data.data.length !== 0) {
                this.stationList = res.data.data.map(item => {
                    return {
                        value: item.stationNo,
                        label: item.stationName
                    }
                })
            } else {
                this.stationList = []
            }
        },
        async changeStation() {
            this.equimentValue = ''
            const res = await getCodingListAll({stationNo: this.stationValue})
            if (res.status !== 200) return this.$msg.error('设备查询失败!')
            this.equimentList = res.data.data.map((item, i) => {
                return {
                    value: item.eqmNo,
                    label: item.eqmmodeNo,
                    id: i
                }
            })
        },
        handleSizeChange(val) {
            this.query.pageSize = val;
            if (this.isPage) {
                this.getFaultUn();
            } else {
                this.searchLevel();
            }
        },
        handleCurrentChange(val) {
            this.query.pageNum = val;
            if (this.isPage) {
                this.getFaultUn();
            } else {
                this.query.resPageNum = val
                this.searchLevel();
            }
        },
        close() {
            const data = {
                colMode: 1,
            };
            this.$refs.processedform.resetFields();
            this.processedForm = data;
            this.isprocessed = true;
        },
        resFuntion() {
            this.cascaderValue = ''
            this.stationValue = ''
            this.stationType = ''
            this.equimentValue = ''
            this.levelValue = ''
            this.timeValue = []
            this.isPage = true;
            this.query = {
                pageNum: 1,
                pageSize: 20,
                resPageNum: 1
            }
            this.getFaultUn();
            this.getStation()
            this.getEqimAll()
        },
        async excelAll() {
            const confirm = await this.$MessageBox.confirm('是否导出文件', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(e => e)
            if (confirm !== 'confirm') return
            this.$download(this.selectBatch, this.coulm, '故障记录')
        },
        levelChange(i) {
            if (this.levelList.length !== 0) {
                let list = this.levelList.filter(c => c.value === i)
                // return list[0].label
                return list.length !== 0 ? list[0].label : ''
            }

        }
    },
};
</script>

<style lang='less' scoped>
.el-row {
    margin: 15px 0;

    .el-col {
        margin-right: 20px;
    }
}

.el-select, .el-cascader, .el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 100%;
}

/deep/ .el-date-editor .el-range-separator {
    width: 20Px;
}

</style>
