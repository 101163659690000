//站场管理
import { createAPI } from '@/utils/request'
const urlStr = '/service-baseinfo'
const urlStr2 = '/service-mqtt/sendMessage'
//const urlStr2 = 'http://127.0.0.1:8050/sendMessage'

//站场信息
//新增场站信息
export const addForce = data=>createAPI(urlStr+'/stationInfo/add','post',data)

//获取场站信息列表
export const getForceList=()=>createAPI(urlStr+'/stationInfo/list','get')
export const getStationInfoList= data =>createAPI(urlStr+'/stationInfo/list' + data.url,'get')

//修改场站信息
export const setForce =data=>createAPI(urlStr+'/stationInfo/update','post',data)

//场站基础信息下发
export const setStaBaseMqtt = data => createAPI(urlStr2 + '/setStaBaseMqtt', 'post', data)

//删除场站信息
export const deleteForce =data=>createAPI(urlStr+'/stationInfo/batchDelete?ids='+data,'get')

//查询场站基础信息
export const checkForce = data=>createAPI(urlStr+`/stationInfo/stationPage?`+data.url,'get')
//场站设备查询
export const eqmPage =data=>createAPI(urlStr+'/stationInfo/eqmPage','get',data)

//电网电价设置--下达指令
export const setDevListMqtt = data => createAPI(urlStr2 + '/setDevListUpdate', 'get', data)

//查询场站基础信息
export const setServicesMqtt = data=>createAPI(urlStr2+`/setServices`,'post',data)

//查询充电机实时数据
export const getChargeDevPage = data=>createAPI(urlStr+`/chargeData/pageQuery`,'get',data)

//查询电池包实时数据
export const getBatDataPage = data=>createAPI(urlStr+`/batData/pageQuery?`+data.url,'get')
//换电预约审核
export const getReservation = data=>createAPI(urlStr+`/stationReservation/page`,'post',data)//换电预约审核
export const toAudit = data=>createAPI(urlStr+`/stationReservation/audit`,'post',data)

//编辑图片
export const upLoading = data =>createAPI(urlStr+'/stationInfo/editPic','post',data)

//换电站状态
export const stationNoState = data =>createAPI(urlStr+'/stationInfo/status','post',data)

//根据区域查询换电站
export const regionCodeSation = data =>createAPI(urlStr+'/stationInfo/stationPage','get',data)

//根据换电站查设备
export const stationFacility = data =>createAPI(urlStr+'/batterySwapEqipment/queryByStationNo','get',data)

//换电站批量导入
export const stationLeadFile = data =>createAPI(urlStr+'/stationInfo/excelImport','post',data)

//场站树列表
export const getOperatorStationTree = data =>createAPI(urlStr+'/stationInfo/listOperatorStations','get')

//电池仓位信息
//获取所有电池仓位信息
export const getSpaceAll = ()=>createAPI(urlStr+'/chargingBinInfo/list','get')

//新增电池仓位信息
export const addSpace =data=>createAPI(urlStr+'/chargingBinInfo/add','post',data)

//分页查询电池仓位信息
export const getSpacePage = data=>createAPI(urlStr+'/chargingBinInfo/specificPage','get',data)

//修改电池仓位信息
export const setSpace = data=>createAPI(urlStr+'/chargingBinInfo/update','post',data)

//删除电池仓位信息
export const deleteSpace =id=>createAPI(urlStr+'/chargingBinInfo/batch?idList='+id,'delete')

//电池仓位批量导入
export const sapaceLeadFile = data =>createAPI(urlStr+'/chargingBinInfo/excelImport/chargingBinInfo','post',data)


//换电站电池型号信息
//获取所有电池型号信息
export const  getCellInfoAll = ()=>createAPI(urlStr+'/batteryModeInStnInfo/list','get')

//新增电池信息
export const addCellInfo = data=>createAPI(urlStr+'/batteryModeInStnInfo/add','post',data)

//获取电池型号信息
export const getCellInfoPage =data=>createAPI(urlStr+`/batteryModeInStnInfo/queryByArea`,'get',data)

//修改电池型号信息
export const setCellInfo = data=>createAPI(urlStr+'/batteryModeInStnInfo/update','post',data)

//删除电池型号信息
export const deleteCellInfo =data=>createAPI(urlStr+'/batteryModeInStnInfo/batch?idList='+data,'delete')

//电池型号信息导入
export const InfoleadFile = data =>createAPI(urlStr+'/batteryModeInStnInfo/excelImport/batteryModeInStn','post',data)


//维护基础信息
//新增维护信息
export const addmain =data=>createAPI(urlStr+'/eqmMtnBaseInfo/add','post',data)

//获取维护基础信息
export const getMainTainPage = data=>createAPI(urlStr+'/eqmMtnBaseInfo/specificPage','get',data)

//修改维护基础信息
export const compileMain =data=>createAPI(urlStr+'/eqmMtnBaseInfo/update','post',data)

//删除维护基础信息
export const deleteMain = id=>createAPI(urlStr+'/eqmMtnBaseInfo/batch?idList='+id,'delete')


//报警基础信息
//获取换电故障信息
export const getFault = data=>createAPI(urlStr+'/eqmFaultBaseInfo/specificPage','get',data)

//新增换电站故障信息
export const addFaulting = data=>createAPI(urlStr+'/eqmFaultBaseInfo/add','post',data)

//更换换电站故障信息
export const compileMsg = data =>createAPI(urlStr+'/eqmFaultBaseInfo/update','post',data)

//删除换电站故障信息
export const removeFault =id=>createAPI(urlStr+'/eqmFaultBaseInfo/batch?idList='+id,'delete')


//编码信息管理
//新增编码信息管理
export const addCodingRule = data=>createAPI(urlStr+'/eqmEncodingInfo/add','post',data)

//获取编码信息管理
export const getCodingRule =data=>createAPI(urlStr+'/eqmEncodingInfo/specificPage','get',data)

//获取所有编码信息
export const getCodingAll =()=>createAPI(urlStr+'/eqmEncodingInfo/list','get')

//修改编码信息管理
export const setCodingRule = data=>createAPI(urlStr+'/eqmEncodingInfo/update','post',data)

//删除编码信息管理
export const deleteCodingRule = id=>createAPI(urlStr+'/eqmEncodingInfo/batch?idList='+id,'delete')

//编码信息管理息导入
export const CodingRuleFile = data =>createAPI(urlStr+'/eqmEncodingInfo/excelImport/eqmEncodingInfo','post',data)

export const getCodingListAll = data =>createAPI(urlStr+'/eqmEncodingInfo/queryByStationNo','get',data)

//换电设备管理
//新增换电设备管理
export const addEquiment =data=>createAPI(urlStr+'/batterySwapEqipment/add','post',data)

//获取换电设备信息
export const getEquipment = ()=>createAPI(urlStr+'/batterySwapEqipment/list','get')

//修改换电设备管理
export const setEquipment = data=>createAPI(urlStr+'/batterySwapEqipment/update','post',data)

//删除换电设备信息
export const deleteEqipment =id=>createAPI(urlStr+'/batterySwapEqipment/batch?btySwapEqmNoList='+id,'delete')

//根据名称状态分页查询
export const checkEquipment =data=>createAPI(urlStr+'/batterySwapEqipment/specificPage','get',data)

//获取所有换电设备
export const getEqimentAll = data=>createAPI(urlStr+'/batterySwapEqipment/list?stationNo='+data,'get')

//换电设备息导入
export const EqimentFile = data =>createAPI(urlStr+'/batterySwapEqipment/excelImport/batterySwapEqipment','post',data)
