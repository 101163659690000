<template>
    <div class="main">
        <div>
            <div class="content-hander">车辆监控
                <i @click="$router.go(-1)" class="iconfont icon-fanhui"><span class="text_33">返回</span></i>
            </div>
        </div>

        <div class="page flex-col">
            <div class="group_1 flex-col">
                <div class="section_1 flex-col">

                    <div class="box_14 flex-row justify-between">
                        <div class="box_4 flex-row" style="align-items:center;">
                            <img
                               style="height:0.11rem; margin-left: 0.5125rem;"
                                referrerpolicy="no-referrer"
                                src="~@/assets/image/allCars.png"
                            />
                            <span  style="height:16px;margin: 0 0 0 10px; font-size: 12px;">全部</span>
                            <span class="text_6">{{carCounting.total}}</span>
                        </div>
                        <div class="box_5 flex-row"  style="align-items:center;">
                            <img
                            style="height:0.11rem; margin-left: 0.5125rem;"
                                referrerpolicy="no-referrer"
                                src="~@/assets/image/onlineCars.png"
                            />
                            <span  style="height:16px;margin: 0 0 0 10px; font-size: 12px;">行驶中</span>
                            <span class="text_8">{{carCounting.driving}}</span>
                        </div>
                        <div class="box_6 flex-row"  style="align-items:center;">
                            <img
                            style="height:0.11rem; margin-left: 0.5125rem;"
                                referrerpolicy="no-referrer"
                                src="~@/assets/image/offlineCars.png"
                            />
                            <span  style="height:16px;margin: 0 0 0 10px; font-size: 12px;">离线</span>
                            <span class="text_10">{{carCounting.offLine}}</span>
                        </div>
                        <div class="box_7 flex-row"  style="align-items:center;">
                            <img
                            style="height:0.11rem; margin-left: 0.5125rem;"
                                referrerpolicy="no-referrer"
                                src="~@/assets/image/errorCars.png"
                            />
                            <span  style="height:16px;margin: 0 0 0 10px; font-size: 12px;">故障</span>
                            <span class="text_12">{{carCounting.error}}</span>
                        </div>
                    </div>

                    <div class="box_2 flex-row justify-between">

                        <div class="operator_team_car" v-if="isopenShow">
                            <operatorTeamTreeVue @emitToParent='getTreeData' @isShow="isHidden"></operatorTeamTreeVue>
                        </div>
                        <div class="operator_team_car" v-if="!isopenShow">
                            <div class="change-in" @click="isopenShow = !  isopenShow "></div>
                        </div>

                        <div class="group_7 flex-col">

                            <div class="map-show">
                                <baidu-map :center="center" :zoom="zoom" @ready="handler" style="height:100%;"
                                           @click="getClickInfo" :scroll-wheel-zoom='true'>
                                </baidu-map>
                            </div>
                            <div class="box_11 flex-row">

                                <div class="group_9 flex-col">

                                    <div class="group_10 flex-row justify-between">
                                        <img
                                            class="label_1"
                                            referrerpolicy="no-referrer"
                                            src="~@/assets/image/onlineCars.png"
                                        />
                                        <span class="text_24">在线</span>
                                    </div>

                                    <div class="group_11 flex-row justify-between">
                                        <img
                                            class="label_2"
                                            referrerpolicy="no-referrer"
                                            src="~@/assets/image/offlineCars.png"
                                        />
                                        <span class="text_25">离线</span>
                                    </div>

                                    <div class="group_12 flex-row justify-between">
                                        <img
                                            class="label_3"
                                            referrerpolicy="no-referrer"
                                            src="~@/assets/image/errorCars.png"
                                        />
                                        <span class="text_26">故障</span>
                                    </div>

                                </div>


                            </div>
                        </div>

                    </div>
                </div>


            </div>
        </div>
    </div>

</template>

<script>
import moment from 'moment'
import {getCarStatistics, teamCarTree} from "@/utils/vehiclApi";
import {handleTree} from "@/utils/tree";
import  {teamCarTreeVue} from "@/components/tree/teamCarTree"
import operatorTeamTreeVue from "@/components/tree/operatorTeamTree";
import {operatorTree} from "@/utils/operatorApi";
import Avater from "@/components/avater";
import TreeSelect from "@riophae/vue-treeselect";


export default {
    name: 'vehicleMonitor',
    components: {operatorTeamTreeVue},
    data() {
        return {
            isopenShow: true,
            carList: {},
            carCounting:{
                total:0,
                driving:0,
                offLine:0,
                error:0
            },
            center: {
                lng: 120.63212,
                lat: 31.26249
            },
            zoom: 15,
            operatorId:null,

          treeData2: {
                teamCarOptions: undefined,
                defaultProps: {
                    children: "children",
                    label: "label"
                },
                teamCarNameTree: "",
                teamCarIdTree: null,
                defaultExpandAll: true,
            },


            treeData: {
                operatorTeamOptions: [],  //运营商-车队树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
                operatorTeamNameTree: "",
                operatorTeamIdTree: null,
                defaultExpandAll: false,
            },


        }
    },
    computed: {
        },
    created() {
        var data = JSON.parse(this.$route.query.data);
        this.operatorId = data.id;
        //this.getTeamCarTree();
        this.getCarCounting();
        this.getOperatorTree();
    },
    mounted() {

    },
    methods: {
        isHidden(message) {
            this.isopenShow = message
        },
        handler({BMap, map}) {
            var point = new BMap.Point(this.center.lng, this.center.lat)
            map.centerAndZoom(point, 16)
            var marker = new BMap.Marker(point) // 创建标注
            map.addOverlay(marker) // 将标注添加到地图中
            var circle = new BMap.Circle(point, 6, {
                strokeColor: 'red',
                strokeWeight: 6,
                strokeOpacity: 1,
                Color: 'red',
                fillColor: '#f03'
            })
            map.addOverlay(circle)

        },
        getClickInfo(e) {
            this.center.lng = e.point.lng
            this.center.lat = e.point.lat
        },
        checkLong(val, val1) {
            var longrg = /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{0,15})|(\d|[1-9]\d|1[0-7]\d|0{1,3})|180\.0{0,15}|180)$/
            var latreg = /^(\-|\+)?([0-8]?\d{1}\.\d{0,15}|90\.0{0,15}|[0-8]?\d{1}|90)$/
            let lng = 116.404165
            let lat = 39.914013
            longrg.test(val) && (lng = +val)
            latreg.test(val1) && (lat = +val1)
            return {
                lng,
                lat
            }
        },

        getTreeData(data) {
            this.treeData = data
        },

        async getTeamCarTree() {
            try {
                this.treeData.teamCarOptions = [];
                let data = {operatorId: this.operatorId};
                let res = await teamCarTree(data);
                if (res.data.code !== 200) return this.$msg.error("获取信息失败");
                this.treeData.teamCarOptions.push(res.data.data)
                console.log("--------getTeamCarTree-2222----------[[",res.data.data,"]]-----------------")
            } catch (error) {
            }
        },

        async getCarCounting(){
            try {
                let data = {operatorId: this.operatorId};
                let res = await getCarStatistics(data);
                if (res.data.code !== 200) return this.$msg.error("获取车辆信息失败");
                this.carList = res.data.data;
                this.carCounting.total = res.data.data.length;
                this.carCounting.offLine = res.data.data.length;
            } catch (error) {
            }
        },


        async getOperatorTree() {
            try {
                this.operatorTreeData.operatorOptions = [];
                let res = await operatorTree();
                if (res.data.code !== 200) return this.$msg.error("获取运营商信息失败");
                this.operatorTreeData.operatorOptions.push(res.data.data)
                this.operatorTreeData.operatorIdTree = res.data.data.id
                this.operatorTreeData.operatorNameTree = res.data.data.label
            } catch (error) {
            }
        },


    },
}
</script>

<style scoped lang="less" src="@/assets/common.less"/>

<style lang='less' scoped>
.content-hander {
    margin-top: 10px;
    height: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-left: 3px solid #2980e1;
    padding-left: 15px;

    overflow-wrap: break-word;
    color: rgba(0, 67, 170, 1);
    font-size: 18px;
    font-family: MicrosoftYaHei;
    font-weight: normal;
    text-align: left;
    white-space: nowrap;
    line-height: 20px;

    .icon-fanhui {
        color: #2980e1;
        cursor: pointer;
        margin-left: 15px;
    }
}

.main {
    width: 100%;
    min-height: 600px;
    overflow: scroll;
}
// 去除百度地图的图标 根据实际情况看是否要加样式穿透(::v-deep)
::v-deep .anchorBL {
      display: none !important;
    }
.page {
    background-color: rgba(255, 255, 255, 1);
    position: relative;
    width: 100%;

    .group_1 {
        background-color: rgba(246, 247, 249, 1);
        height: 100%;
        position: relative;

        .section_1 {
            background-color: rgba(255, 255, 255, 1);
            height: 100%;
            width: 100%;
            margin: 10px 0 0 10px;

            .box_14 {
                width: 95%;
                height: 60px;
                margin: 12px 0 0 12px;
                .box_4 {
                    background-color: rgba(255, 255, 255, 1);
                    border-radius: 10px;
                    width: 357px;
                    height: 60px;
                    border: 1px dashed rgba(85, 100, 241, 1);
                    .label_2 {
                        width: 21px;
                        height: 21px;
                        margin: 19px 0 0 66px;
                    }
                    .text_5 {
                        width: 32px;
                        height: 16px;
                        overflow-wrap: break-word;
                        color: rgba(9, 17, 36, 1);
                        font-size: 16px;
                        font-family: MicrosoftYaHei;
                        font-weight: normal;
                        text-align: left;
                        white-space: nowrap;
                        line-height: 8px;
                        margin: 22px 0 0 15px;
                    }
                    .text_6 {
                        width: 167px;
                        height: 24px;
                        overflow-wrap: break-word;
                        color: rgba(62, 204, 219, 1);
                        font-size: 32px;
                        font-family: DIN-Medium;
                        font-weight: 500;
                        text-align: left;
                        line-height: 8px;
                        margin: 18px 173px 0 13px;
                    }
                }
                .box_5 {
                    background-color: rgba(255, 255, 255, 1);
                    border-radius: 10px;
                    width: 357px;
                    height: 60px;
                    border: 1px dashed rgba(25, 186, 203, 1);
                    margin-left: 13px;
                    .label_3 {
                        width: 21px;
                        height: 21px;
                        margin: 19px 0 0 66px;
                    }
                    .text_7 {
                        width: 47px;
                        height: 16px;
                        overflow-wrap: break-word;
                        color: rgba(9, 17, 36, 1);
                        font-size: 16px;
                        font-family: MicrosoftYaHei;
                        font-weight: normal;
                        text-align: left;
                        white-space: nowrap;
                        line-height: 8px;
                        margin: 22px 0 0 16px;
                    }
                    .text_8 {
                        width: 167px;
                        height: 24px;
                        overflow-wrap: break-word;
                        color: rgba(85, 100, 241, 1);
                        font-size: 32px;
                        font-family: DIN-Medium;
                        font-weight: 500;
                        text-align: left;
                        line-height: 8px;
                        margin: 18px 180px 0 11px;
                    }
                }
                .box_6 {
                    background-color: rgba(255, 255, 255, 1);
                    border-radius: 10px;
                    width: 357px;
                    height: 60px;
                    border: 1px dashed rgba(135, 123, 159, 1);
                    margin-left: 13px;
                    .label_4 {
                        width: 21px;
                        height: 21px;
                        margin: 19px 0 0 66px;
                    }
                    .text_9 {
                        width: 32px;
                        height: 17px;
                        overflow-wrap: break-word;
                        color: rgba(9, 17, 36, 1);
                        font-size: 16px;
                        font-family: MicrosoftYaHei;
                        font-weight: normal;
                        text-align: left;
                        white-space: nowrap;
                        line-height: 8px;
                        margin: 21px 0 0 14px;
                    }
                    .text_10 {
                        width: 167px;
                        height: 24px;
                        overflow-wrap: break-word;
                        color: rgba(135, 123, 159, 1);
                        font-size: 32px;
                        font-family: DIN-Medium;
                        font-weight: 500;
                        text-align: left;
                        line-height: 8px;
                        margin: 18px 210px 0 13px;
                    }
                }
                .box_7 {
                    background-color: rgba(255, 255, 255, 1);
                    border-radius: 10px;
                    width: 357px;
                    height: 60px;
                    border: 1px dashed rgba(217, 81, 81, 1);
                    margin-left: 13px;
                    .label_5 {
                        width: 21px;
                        height: 21px;
                        margin: 19px 0 0 66px;
                    }
                    .text_11 {
                        width: 32px;
                        height: 17px;
                        overflow-wrap: break-word;
                        color: rgba(9, 17, 36, 1);
                        font-size: 16px;
                        font-family: MicrosoftYaHei;
                        font-weight: normal;
                        text-align: left;
                        white-space: nowrap;
                        line-height: 8px;
                        margin: 21px 0 0 14px;
                    }
                    .text_12 {
                        width: 167px;
                        height: 24px;
                        overflow-wrap: break-word;
                        color: rgba(217, 81, 81, 1);
                        font-size: 32px;
                        font-family: DIN-Medium;
                        font-weight: 500;
                        text-align: left;
                        line-height: 8px;
                        margin: 18px 232px 0 13px;
                    }
                }
            }

            .box_2 {
                width: 100%;
                height: 90%;
                margin: 10px 0 23px 10px;
                display: flex;

                .operator_team_car {
                    width: 16%;
                    height: var(--tableHeight);
                    margin: 2px 5px 0 2px;
                }

                .group_7 {
                    width: 85%;
                    position: relative;
                    z-index: 1;
                    display: flex;

                    .map-show {
                        width: 100%;
                        height: 795px;
                        z-index: 2;
                    }

                    .box_11 {
                        z-index: 3;
                        position: absolute;
                        width: 130px;
                        height: 180px;
                        margin: 270px 0 19px 24px;

                        .group_9 {
                            background-color: rgba(255, 255, 255, 1);
                            border-radius: 6px;
                            height: 173px;
                            width: 125px;

                            .group_10 {
                                width: 75px;
                                height: 40px;
                                margin: 22px 0 0 16px;

                                .label_1 {
                                    width: 40px;
                                    height: 40px;
                                }

                                .text_24 {
                                    width: 32px;
                                    height: 16px;
                                    overflow-wrap: break-word;
                                    color: rgba(51, 51, 51, 1);
                                    font-size: 16px;
                                    font-family: MicrosoftYaHei;
                                    font-weight: normal;
                                    text-align: left;
                                    white-space: nowrap;
                                    line-height: 13px;
                                    margin-top: 11px;
                                }
                            }

                            .group_11 {
                                width: 75px;
                                height: 40px;
                                margin: 5px 0 0 16px;

                                .label_2 {
                                    width: 40px;
                                    height: 40px;
                                }

                                .text_25 {
                                    width: 32px;
                                    height: 16px;
                                    overflow-wrap: break-word;
                                    color: rgba(51, 51, 51, 1);
                                    font-size: 16px;
                                    font-family: MicrosoftYaHei;
                                    font-weight: normal;
                                    text-align: left;
                                    white-space: nowrap;
                                    line-height: 13px;
                                    margin-top: 11px;
                                }
                            }

                            .group_12 {
                                width: 75px;
                                height: 40px;
                                margin: 5px 0 21px 16px;

                                .label_3 {
                                    width: 40px;
                                    height: 40px;
                                }

                                .text_26 {
                                    width: 32px;
                                    height: 16px;
                                    overflow-wrap: break-word;
                                    color: rgba(51, 51, 51, 1);
                                    font-size: 16px;
                                    font-family: MicrosoftYaHei;
                                    font-weight: normal;
                                    text-align: left;
                                    white-space: nowrap;
                                    line-height: 13px;
                                    margin-top: 11px;
                                }
                            }
                        }

                    }

                }


            }
        }

    }
}

.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}
</style>

