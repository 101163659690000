<template>
  <div>
    <div>
      <div class="content-hander">
        场站详情
        <i @click="$router.go(-1)" class="iconfont icon-fanhui"
          ><span class="text_33">返回</span></i
        >
      </div>
    </div>

    <div class="page flex-col">
      <div class="box_1 flex-col">
        <div class="box_2 flex-col">
          <div class="group_3 flex-row justify-between">
            <div class="left-content">
              <div class="group_4 flex-col">
                <span class="text_2">基本信息</span>

                <div class="text-wrapper flex-row">
                  <span class="object_label">归属运营商:</span>
                  <span class="object_content">{{ station.operatorName }}</span>
                </div>

                <div class="text-wrapper flex-row">
                  <span class="object_label">场站编码:</span>
                  <span class="object_content">{{ station.stationNo }}</span>
                </div>

                <div class="text-wrapper flex-row">
                  <span class="object_label">服务状态:</span>
                  <span
                    class="object_content"
                    v-if="station.status === 1"
                    style="color: #1890ff"
                    >正在服务</span
                  >
                  <span
                    class="object_content"
                    v-else-if="station.status === 2"
                    style="color: #ff8d1a"
                    >停止服务</span
                  >
                  <span class="object_content" v-else style="color: #ffeb3b"
                    >未知</span
                  >
                </div>

                <div class="text-wrapper flex-row">
                  <span class="object_label">运营状态:</span>
                  <span
                    class="object_content"
                    v-if="station.operationStatus === 1"
                    style="color: #1890ff"
                    >正在营业</span
                  >
                  <span
                    class="object_content"
                    v-else-if="station.operationStatus === 2"
                    style="color: #808080"
                    >暂停营业</span
                  >
                  <span
                    class="object_content"
                    v-else-if="station.operationStatus === 3"
                    style="color: #ff8d1a"
                    >设备维护</span
                  >
                  <span
                    class="object_content"
                    v-else-if="station.operationStatus === 4"
                    style="color: #ffeb3b"
                    >歇业状态</span
                  >
                  <span class="object_content" v-else style="color: #ffeb3b"
                    >未知</span
                  >
                </div>

                <!--
                                <div class="text-wrapper flex-row">
                                    <span class="object_label">场站类型:</span>
                                    <span class="object_content">{{station.typeName}}</span>
                                </div>
                               <div class="text-wrapper flex-row">
                                    <span class="object_label">场站名称:</span>
                                    <span class="object_content">{{station.stationName}}</span>
                                </div>
                                <div class="text-wrapper flex-row">
                                    <span class="object_label">区域名称:</span>
                                    <span class="object_content">{{station.areaName}}</span>
                                </div>
                                -->

                <div class="text-wrapper flex-row">
                  <span class="object_label">区域编码:</span>
                  <span class="object_content">{{ station.areaCode }}</span>
                </div>

                <div class="text-wrapper flex-row">
                  <span class="object_label">场站编号:</span>
                  <span class="object_content">{{ station.stationSn }}</span>
                </div>

                <div class="text-wrapper flex-row">
                  <span class="object_label">地理位置:</span>
                  <span class="object_content">{{
                    station.stationLocation
                  }}</span>
                </div>

                <div class="text-wrapper flex-row">
                  <span class="object_label">场站功率:</span>
                  <span class="object_content">{{ station.chargePower }}</span>
                </div>

                <div class="text-wrapper flex-row">
                  <span class="object_label">计费模型:</span>
                  <span class="object_content">{{ station.sbmTypeName }}</span>
                </div>

                <div class="text-wrapper flex-row">
                  <span class="object_label">营运时间:</span>
                  <span class="object_content">{{
                    station.operationTime
                  }}</span>
                </div>

                <div class="text-wrapper flex-row">
                  <span class="object_label">投放时间:</span>
                  <span class="object_content">{{ station.launchTime }}</span>
                </div>

                <div class="text-wrapper flex-row">
                  <span class="object_label">负责人:</span>
                  <span class="object_content">{{ station.principal }}</span>
                </div>

                <div class="text-wrapper flex-row">
                  <span class="object_label">联系方式:</span>
                  <span class="object_content">{{ station.contactWay }}</span>
                </div>

                <div class="text-wrapper flex-row">
                  <span class="object_label">邮箱地址:</span>
                  <span class="object_content">{{ station.email }}</span>
                </div>

                <div class="text-wrapper flex-row">
                  <span class="object_label">设备版本:</span>
                  <span class="object_content">{{
                    station.deviceVersion
                  }}</span>
                </div>

                <div class="text-wrapper flex-row">
                  <span class="object_label">软件版本:</span>
                  <span class="object_content">{{
                    station.stationSftVer
                  }}</span>
                </div>

                <div class="text-wrapper flex-row">
                  <span class="object_label">所属运营企业:</span>
                  <span class="object_content">{{
                    station.stationCompany
                  }}</span>
                </div>

                <div class="text-wrapper flex-row">
                  <span class="object_label2">企业统一社会信用代码:</span>
                  <span class="object_content2">{{
                    station.socialCreditCode
                  }}</span>
                </div>

                <div class="group_5 flex-col">
                  <span class="text_25">换电站图片:</span>
                  <div v-if="station.cover != null && station.cover != ''">
                    <img
                      class="image_1"
                      referrerpolicy="no-referrer"
                      :src="station.cover"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="right-content">
              <div class="group_6 flex-col">
                <span class="text_26">换电站地图位置</span>
                <div class="box_3 flex-row">
                  <div class="text-wrapper_12 flex-col">
                    <span class="text_27">换电站名称</span>
                    <span class="text_28">{{ station.stationName }}</span>
                  </div>
                  <div class="text-wrapper_13 flex-col area-name">
                    <span class="text_29">换电站区域</span>
                    <span class="text_30">{{ station.areaName }}</span>
                  </div>
                  <div class="text-wrapper_13 flex-col stationSel">
                    <span class="text_29">工位分类</span>
                    <!--                                        <span class="text_32">{{ station.typeName }}</span>-->
                    <el-select
                      v-model="station.staType"
                      placeholder="暂未分类"
                      @focus="closeIt"
                      ref="selectIt"

                    >
                      <el-option
                        v-for="dict in dict.type.sta_station_category"
                        :key="dict.value"
                        :label="dict.label"
                        :value="dict.value"
                      />
                    </el-select>

                  </div>
                </div>
              </div>

              <div v-if="showStatus">
                <div class="block_4 flex-row justify-between">
                  <div class="device-show-1 flex-col" @click="changeShowStatus">
                    <span class="device-show-text-1">设备列表</span>
                  </div>
                  <div class="map-show-1 flex-col" @click="changeShowStatus">
                    <span class="map-show-text-1">地图位置</span>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="block_42 flex-row justify-between">
                  <div class="device-show-2 flex-col" @click="changeShowStatus">
                    <span class="device-show-text-2">设备列表</span>
                  </div>
                  <div class="map-show-2 flex-col" @click="changeShowStatus">
                    <span class="map-show-text-2">地图位置</span>
                  </div>
                </div>
              </div>
              <!-- <el-table
                              ref="equipTable"
                              id="equipment"
                              :data="deviceListData"
                              style="width: 100%; height: 100%"
                            >
                              <el-table-column
                                type="selection"
                                width="40"
                              ></el-table-column>
                              <el-table-column
                                label="设备编号"
                                prop="sn"
                              ></el-table-column>

                              <el-table-column
                                label="设备名称"
                                prop="dn"
                              ></el-table-column>
                              <el-table-column
                                label="设备状态"
                                prop="dc"
                              ></el-table-column>
                              <el-table-column
                                label="类型代码"
                                prop="tc"
                              ></el-table-column>
                            </el-table> -->

              <div class="station-device">
                <div v-if="showStatus">
                  <div class="device-list">
                    <div class="device-content">
                      <div>
                        <div class="device-table">
                          <!-- <div class="box_14 flex-row"> -->

                            <el-table
                              ref="equipTable"
                              id="equipment"
                              :data="deviceListData"
                              height="550"
                              style="width: 100%;"
                            >
                              <el-table-column
                                label="设备编号"
                                prop="sn"
                              ></el-table-column>

                              <el-table-column
                                label="设备名称"
                                prop="dn"
                              ></el-table-column>
                              <el-table-column
                                label="设备状态"
                                prop="dc"
                              ></el-table-column>
                              <el-table-column
                                label="类型代码"
                                prop="tc"
                              ></el-table-column>
                            </el-table>
                          <!-- </div> -->
                            <el-row class="pageY">
                                <el-pagination
                                    @size-change="handleSizeChange"
                                    @current-change="handleCurrentChange"
                                    :current-page="query.pageNum"
                                    :page-sizes="[10, 30, 50, 100]"
                                    :page-size="query.pageSize"
                                    layout="total, sizes, prev, pager, next, jumper"
                                    :total="total">
                                </el-pagination>
                            </el-row>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="map-style flex-col">
                    <baidu-map
                      :center="center"
                      :zoom="zoom"
                      @ready="handler"
                      style="height: 100%"
                      @click="getClickInfo"
                      :scroll-wheel-zoom="true"
                    >
                    </baidu-map>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { eqmPage } from "@/utils/station";

export default {
  name: "forceMap",
  dicts: ["sta_station_category"],
  data() {
    return {
        query: {
            pageNum: 1,
            pageSize: 10,
        },
      station: {},
      center: {
        lng: 120.63212,
        lat: 31.26249,
      },
      deviceListData: [],
      total: 0,
      zoom: 15,
      stationTypeList: [],
      stationTypeName: "",
      showStatus: true,

    };
  },
  computed: {
    time() {
      return moment(this.station.operationTime).format("YYYY-MM-DD");
    },
    createTime() {
      return moment(this.station.createTime).format("YYYY-MM-DD");
    },
  },
  created() {
    this.station = JSON.parse(this.$route.query.data);
      console.log(this.station ,'this.station ')
    this.center = this.checkLong(this.station.longitude, this.station.latitude);
    this.getDeviceList();
    console.log(
      "------------------------[[",
      this.deviceListData,
      "]]--------------"
    );
      console.log(sta_station_category,'sta_station_category')
  },
  mounted() {},
  methods: {
      handleSizeChange(val) {
          this.query.pageSize = val
          this.getDeviceList()
      },
      handleCurrentChange(val) {
          this.query.pageNum = val
          this.getDeviceList()
      },
      closeIt() {
          this.$refs.selectIt.blur();
      },
      handler({ BMap, map }) {
      var point = new BMap.Point(this.center.lng, this.center.lat);
      map.centerAndZoom(point, 16);
      var marker = new BMap.Marker(point); // 创建标注
      map.addOverlay(marker); // 将标注添加到地图中
      var circle = new BMap.Circle(point, 6, {
        strokeColor: "Red",
        strokeWeight: 6,
        strokeOpacity: 1,
        Color: "Red",
        fillColor: "#f03",
      });
      map.addOverlay(circle);
    },
    getClickInfo(e) {
      this.center.lng = e.point.lng;
      this.center.lat = e.point.lat;
    },
    checkLong(val, val1) {
      var longrg =
        /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{0,15})|(\d|[1-9]\d|1[0-7]\d|0{1,3})|180\.0{0,15}|180)$/;
      var latreg =
        /^(\-|\+)?([0-8]?\d{1}\.\d{0,15}|90\.0{0,15}|[0-8]?\d{1}|90)$/;
      let lng = 116.404165;
      let lat = 39.914013;
      longrg.test(val) && (lng = +val);
      latreg.test(val1) && (lat = +val1);
      return {
        lng,
        lat,
      };
    },

    changeShowStatus() {
      var flag = this.showStatus;
      this.showStatus = !flag;
    },

    async getDeviceList() {
      const data = {
        stationNo: this.station.stationNo,
          page:this.query.pageNum,
          size:this.query.pageSize
      };

      const result = await eqmPage(data);
      if (result.data.code !== 200)
        return this.$msg.error("获取设备列表信息失败!");
      console.log("==========", result.data.data);

      this.deviceListData =
          result.data.data.data
      this.total =
          result.data.data.total

      console.log(
        "-----------deviceListData-------------[[",
        this.deviceListData,
        "]]--------------"
      );
    },
  },
};
</script>

<style scoped lang="less" src="@/assets/common.less"/>
<style scoped lang="less">
.content-hander {
  margin-top: 10px;
  height: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-left: 3px solid #2980e1;
  padding-left: 15px;

  overflow-wrap: break-word;
  color: rgba(0, 67, 170, 1);
  font-size: 18px;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;

  .icon-fanhui {
    color: #2980e1;
    cursor: pointer;
    margin-left: 15px;
  }
}

.page {
  background-color: rgba(255, 255, 255, 1);
  position: relative;
  width: 100%;
  height: auto;

  .box_1 {
    background-color: rgba(246, 247, 249, 1);
    width: 100%;
    position: relative;

    .box_2 {
      background-color: rgba(255, 255, 255, 1);
      width: 100%;
      margin: 6px 500px 0 5px;
      overflow-x: scroll;

      .group_3 {
        width: 100%;
        margin: 10px 0 20px 10px;

        .left-content {
          width: 635px;

          .group_4 {
            background-color: rgba(246, 247, 249, 1);
            border-radius: 6px;
            width: 629px;
            justify-content: center;
            margin-top: 10px;

            .text_2 {
              width: 66px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(100, 108, 154, 1);
              font-size: 16px;
              font-family: MicrosoftYaHei;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 13px;
              margin: 19px 0 0 23px;
            }

            .text-wrapper {
              background-color: rgba(255, 255, 255, 1);
              border-radius: 6px;
              width: 581px;
              height: 38px;
              border: 1px solid rgba(204, 204, 204, 1);
              margin-left: 24px;
              margin-top: 23px;

              .object_label {
                width: 55px;
                height: 17px;
                overflow-wrap: break-word;
                color: rgba(106, 111, 117, 1);
                font-size: 16px;
                font-family: MicrosoftYaHei;
                font-weight: normal;
                text-align: left;
                white-space: nowrap;
                line-height: 13px;
                margin: 11px 0 0 23px;
              }

              .object_content {
                width: 430px;
                height: 17px;
                overflow-wrap: break-word;
                color: rgba(51, 51, 51, 1);
                font-size: 16px;
                font-family: MicrosoftYaHei;
                font-weight: normal;
                text-align: left;
                white-space: nowrap;
                line-height: 13px;
                margin-left: 60px;
                margin-top: 11px;
              }

              .object_label2 {
                width: 190px;
                height: 17px;
                overflow-wrap: break-word;
                color: rgba(106, 111, 117, 1);
                font-size: 16px;
                font-family: MicrosoftYaHei;
                font-weight: normal;
                text-align: left;
                white-space: nowrap;
                line-height: 13px;
                margin: 11px 0 0 23px;
              }

              .object_content2 {
                width: 350px;
                height: 17px;
                overflow-wrap: break-word;
                color: rgba(51, 51, 51, 1);
                font-size: 16px;
                font-family: MicrosoftYaHei;
                font-weight: normal;
                text-align: left;
                white-space: nowrap;
                line-height: 13px;
                margin-left: 10px;
                margin-top: 11px;
              }
            }

            .group_5 {
              background-color: rgba(255, 255, 255, 1);
              border-radius: 6px;
              width: 581px;
              height: 275px;
              border: 1px solid rgba(204, 204, 204, 1);
              margin: 15px 0 19px 24px;

              .text_25 {
                width: 85px;
                height: 17px;
                overflow-wrap: break-word;
                color: rgba(106, 111, 117, 1);
                font-size: 16px;
                font-family: MicrosoftYaHei;
                font-weight: normal;
                text-align: left;
                white-space: nowrap;
                line-height: 13px;
                margin: 11px 0 0 23px;
              }

              .image_1 {
                width: 538px;
                height: 216px;
                margin: 11px 0 20px 22px;
              }
            }
          }
        }

        .right-content {
          width: 1100px;
          margin-top: 10px;
          background-color: white;

          .group_6 {
            background-color: rgba(246, 247, 249, 1);
            border-radius: 6px;
            width: 1050px;
            height: 150px;
            margin-left: 10px;

            .text_26 {
              width: 115px;
              height: 17px;
              overflow-wrap: break-word;
              color: rgba(100, 108, 154, 1);
              font-size: 16px;
              font-family: MicrosoftYaHei;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 13px;
              margin: 19px 0 0 23px;
            }

            .box_3 {
              width: 953px;
              height: 65px;
              margin: 23px 0 0 24px;

              .text-wrapper_12 {
                background-color: rgba(255, 255, 255, 1);
                border-radius: 6px;
                width: 308px;
                height: 65px;
                border: 1px solid rgba(204, 204, 204, 1);
                justify-content: center;

                .text_27 {
                  width: 80px;
                  height: 17px;
                  overflow-wrap: break-word;
                  color: rgba(106, 111, 117, 1);
                  font-size: 16px;
                  font-family: MicrosoftYaHei;
                  font-weight: normal;
                  text-align: left;
                  white-space: nowrap;
                  line-height: 13px;
                  margin: 10px 0 0 22px;
                }

                .text_28 {
                  width: 109px;
                  height: 18px;
                  overflow-wrap: break-word;
                  color: rgba(51, 51, 51, 1);
                  font-size: 18px;
                  font-family: MicrosoftYaHei-Bold;
                  font-weight: 700;
                  text-align: left;
                  white-space: nowrap;
                  line-height: 13px;
                  margin: 10px 0 10px 21px;
                }
              }

              .text-wrapper_13 {
                background-color: rgba(255, 255, 255, 1);
                border-radius: 6px;
                width: 308px;
                height: 65px;
                border: 1px solid rgba(204, 204, 204, 1);
                margin-left: 15px;

                .text_29 {
                  width: 80px;
                  height: 17px;
                  overflow-wrap: break-word;
                  color: rgba(106, 111, 117, 1);
                  font-size: 16px;
                  font-family: MicrosoftYaHei;
                  font-weight: normal;
                  text-align: left;
                  white-space: nowrap;
                  line-height: 13px;
                  margin: 10px 0 0 22px;
                }

                .text_30 {
                  width: 54px;
                  height: 18px;
                  overflow-wrap: break-word;
                  color: rgba(51, 51, 51, 1);
                  font-size: 18px;
                  font-family: MicrosoftYaHei-Bold;
                  font-weight: 700;
                  text-align: left;
                  white-space: nowrap;
                  line-height: 13px;
                  margin: 10px 0 10px 21px;
                }
              }

              .text-wrapper_14 {
                background-color: rgba(255, 255, 255, 1);
                border-radius: 6px;
                width: 308px;
                height: 65px;
                border: 1px solid rgba(204, 204, 204, 1);
                margin-left: 14px;
                justify-content: center;

                .text_31 {
                  width: 80px;
                  height: 17px;
                  overflow-wrap: break-word;
                  color: rgba(106, 111, 117, 1);
                  font-size: 16px;
                  font-family: MicrosoftYaHei;
                  font-weight: normal;
                  text-align: left;
                  white-space: nowrap;
                  line-height: 13px;
                  margin: 10px 0 0 22px;
                }

                .text_32 {
                  width: 92px;
                  height: 18px;
                  overflow-wrap: break-word;
                  color: rgba(51, 51, 51, 1);
                  font-size: 18px;
                  font-family: MicrosoftYaHei-Bold;
                  font-weight: 700;
                  text-align: left;
                  white-space: nowrap;
                  line-height: 13px;
                  margin: 10px 0 10px 21px;
                }
              }
            }
          }

          .block_4 {
            width: 272px;
            height: 38px;
            margin: 2px 0 0 400px;
            display: flex;

            .device-show-1 {
              background: url("~@/assets/image/switch.png") 100% no-repeat;
              background-size: 100% 100%;
              border-radius: 17px 17px 0px 0px;
              height: 34px;
              width: 136px;
              .device-show-text-1 {
                width: 64px;
                height: 17px;
                overflow-wrap: break-word;
                color: #ffffff;
                font-size: 16px;
                font-family: MicrosoftYaHei;
                font-weight: normal;
                text-align: left;
                white-space: nowrap;
                line-height: 13px;
                margin: 9px 0 0 35px;
              }
            }

            .map-show-1 {
              height: 34px;
              background-color: rgba(229, 236, 245, 1);
              width: 136px;
              .map-show-text-1 {
                width: 64px;
                height: 17px;
                overflow-wrap: break-word;
                color: rgba(255, 255, 255, 1);
                font-size: 16px;
                font-family: MicrosoftYaHei;
                font-weight: normal;
                text-align: left;
                white-space: nowrap;
                line-height: 13px;
                margin: 9px 0 0 36px;
              }
            }
          }

          .block_42 {
            width: 272px;
            height: 30px;
            margin: 2px 0 0 400px;
            display: flex;

            .device-show-2 {
              background-color: rgba(229, 236, 245, 1);
              border-radius: 17px 17px 0px 0px;
              height: 34px;
              width: 136px;
              .device-show-text-2 {
                width: 64px;
                height: 17px;
                overflow-wrap: break-word;
                color: rgba(51, 51, 51, 1);
                font-size: 16px;
                font-family: MicrosoftYaHei;
                font-weight: normal;
                text-align: left;
                white-space: nowrap;
                line-height: 13px;
                margin: 9px 0 0 35px;
              }
            }

            .map-show-2 {
              height: 34px;
              width: 136px;
              background: url("~@/assets/image/switch.png") 100% no-repeat;
              background-size: 100% 100%;

              .map-show-text-2 {
                width: 64px;
                height: 17px;
                overflow-wrap: break-word;
                color: rgba(255, 255, 255, 1);
                font-size: 16px;
                font-family: MicrosoftYaHei;
                font-weight: normal;
                text-align: left;
                white-space: nowrap;
                line-height: 13px;
                margin: 9px 0 0 36px;
              }
            }
          }

          .station-device {
            width: 1000px;
            margin-top: 10px;

            .map-style {
              height: 686px;
              background-size: 100% 100%;
              width: 990px;
              margin: 16px 0 309px 10px;

              .image_2 {
                width: 60px;
                height: 68px;
                margin: 101px 0 0 606px;
              }
            }

            .device-list {
              background-color: rgba(246, 247, 249, 1);
              border-radius: 6px;
              width: 1060px;
              height: 480px;
              margin-top: 10px;
              margin-left: 10px;

              .device-title {
                width: 115px;
                height: 17px;
                overflow-wrap: break-word;
                color: rgba(100, 108, 154, 1);
                font-size: 16px;
                font-family: MicrosoftYaHei;
                font-weight: normal;
                text-align: left;
                white-space: nowrap;
                line-height: 13px;
                margin: 19px 0 0 23px;
              }

              .device-content {
                margin-top: 2px;
                .device-table {
                  width: 1050px;
                  height: 530px;

                  .box_14 {
                    width: 1020px;
                    height: 40px;
                    .text-wrapper_18 {
                      background-color: rgba(236, 239, 252, 1);
                      height: 40px;
                      border: 1px solid rgba(180, 196, 209, 1);
                      width: 239px;
                      .text_48 {
                        width: 33px;
                        height: 16px;
                        overflow-wrap: break-word;
                        color: rgba(51, 51, 51, 1);
                        font-size: 16px;
                        font-family: MicrosoftYaHei;
                        font-weight: normal;
                        text-align: center;
                        white-space: nowrap;
                        line-height: 16px;
                        margin: 5px 0 0 103px;
                      }
                    }
                    .text-wrapper_19 {
                      background-color: rgba(236, 239, 252, 1);
                      height: 40px;
                      border: 1px solid rgba(180, 196, 209, 1);
                      margin-left: -2px;
                      width: 305px;
                      .text_49 {
                        width: 65px;
                        height: 16px;
                        overflow-wrap: break-word;
                        color: rgba(51, 51, 51, 1);
                        font-size: 16px;
                        font-family: MicrosoftYaHei;
                        font-weight: normal;
                        text-align: center;
                        white-space: nowrap;
                        line-height: 16px;
                        margin: 5px 0 0 88px;
                      }
                    }
                    .text-wrapper_20 {
                      background-color: rgba(236, 239, 252, 1);
                      height: 40px;
                      border: 1px solid rgba(180, 196, 209, 1);
                      margin-left: -1px;
                      width: 239px;
                      .text_50 {
                        width: 65px;
                        height: 16px;
                        overflow-wrap: break-word;
                        color: rgba(51, 51, 51, 1);
                        font-size: 16px;
                        font-family: MicrosoftYaHei;
                        font-weight: normal;
                        text-align: center;
                        white-space: nowrap;
                        line-height: 16px;
                        margin: 5px 0 0 87px;
                      }
                    }
                    .text-wrapper_21 {
                      background-color: rgba(236, 239, 252, 1);
                      height: 40px;
                      border: 1px solid rgba(180, 196, 209, 1);
                      margin-left: -1px;
                      width: 239px;
                      .text_51 {
                        width: 65px;
                        height: 16px;
                        overflow-wrap: break-word;
                        color: rgba(51, 51, 51, 1);
                        font-size: 16px;
                        font-family: MicrosoftYaHei;
                        font-weight: normal;
                        text-align: center;
                        white-space: nowrap;
                        line-height: 16px;
                        margin: 5px 0 0 87px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="less" scoped>
.stationSel{
    /deep/.el-input{
        padding: 0px;
    }
    /deep/.el-input__inner{
        border: 0px ;
        padding: 0px;
        height: 20px;
        line-height: 20px;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 18px;
        font-family: MicrosoftYaHei-Bold;
        font-weight: 700;
        text-align: left;
        padding-left: 20px;
        margin-top: 6px;
    }
    /deep/.el-input__suffix{
        display: none;
    }
}
.el-table {
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
        width: 8px; /*滚动条宽度*/
        height: 10px; /*滚动条高度*/
    }
    /*定义滚动条轨道 内阴影+圆角*/
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
        border-radius: 10px; /*滚动条的背景区域的圆角*/
        background-color: #fff; /*滚动条的背景颜色*/
    }
    /*定义滑块 内阴影+圆角*/
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
        border-radius: 10px; /*滚动条的圆角*/
        background-color: #DDDEE0; /*滚动条的背景颜色*/
    }

}

.pageY{
    margin-top: 15px;
}
.area-name{
    width: 350px !important;
}
</style>
<style lang="less">
.device-table{
    height: 648px !important;
    .el-table{
        height: 648px !important;
    }
}
</style>
