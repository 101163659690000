<template>
  <el-card>
    <el-row>
      <el-col :span="4">
        <el-input placeholder="请输入电池序列号" v-model="batteryInfo.fbatteryNo"></el-input>
      </el-col>
      <el-col :span="6">
        <el-button type="primary" @click="getAll()">查询</el-button>
        <el-button  @click="restFunction">重置</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-button type="primary" size="small" @click="importExcelData" class="el-icon-download">批量导出</el-button>
    </el-row>
    <el-row>
      <el-table
      id="retrosTable"
      :data="tableData"
      style="width: 100%"
      ref="multipleTable"
      @selection-change="selectionChange"
      height="300"
    >
    <el-table-column  type="selection" width="40"></el-table-column>
      <el-table-column type="index" :index="renIndex" label="编号" width="50"></el-table-column>
      <el-table-column prop="fbatteryNo" label="电池序列号"></el-table-column>
      <el-table-column prop="fcarNo" label="车牌号"></el-table-column>
        <el-table-column prop="fstationNo" label="换电站编号"></el-table-column>
      <el-table-column prop="feqmType" label="所在设备类别">
        <template slot-scope="scope">
          <span v-if="scope.row.feqmType === 1">汽车</span>
          <span v-else>换电站</span>
        </template>
      </el-table-column>
      <el-table-column prop="fcreateTime" label="添加时间"></el-table-column>
      <el-table-column prop="fmodifyTime" label="修改时间"></el-table-column>
    </el-table>
    </el-row>
    <el-row>
       <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNum"
        :page-sizes="[20, 25, 30]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalPages">
      </el-pagination>
    </el-row>
  </el-card>
</template>

<script>
import {
  getChargingAll,
  getChargingTraceLogs,
  getBatteryDistributingInfoOfbtyNo,
  ChargingTraceInfoPage
} from "@/utils/cellPutApi";
export default {
  name:'retrospect',
  data() {
    //定义数据
    return {
      query:{
        pageNum:1,
        pageSize:20,
        resPageNum:1
      },
      batteryInfo:{},
      isPage:true,
      selectValue: "",
      inputValue: "",
      selectList: [],
      bartchNum:[],
      tableData: [],
      totalPages: 0,
      options: [
        {
          value: 1,
          label: "汽车",
        },
        {
          value: 2,
          label: "换电站",
        },
      ],
      coulm:[
        {prop:'batteryNo',label:'电池序列号'},
        {prop:'eqmNo',label:'所在设备编号'},
        {prop:'eqmType',label:'所在设备类别'},
        {prop:'startTime',label:'装入设备时间'},
        {prop:'endTime',label:'移出设备时间'},
      ]
    };
  },
  computed:{
     selectBatch(){
       if(this.bartchNum.length === 0){
         return this.selectList.map(item=>{
            return item
          })
       }else{
        return this.bartchNum.map(item=>{
           return item
         })
       }
     }
  },
  created() {
    //进入页面自动运行
    this.getAll();
  },
  methods: {
    //自定义方法
    async getAll() {
     try {
      let res = await ChargingTraceInfoPage(this.query.pageNum,this.query.pageSize,this.batteryInfo);
      console.log(res)
      if (res.data.code !== 20000) return this.$msg.error("获取日志列表失败");
       this.tableData = res.data.data.rows.records
       this.totalPages = res.data.data.rows.total
     } catch (error) {

     }
    },
   async getChargingListAll(){
       const res =await getChargingAll()
        this.selectList = res.data.data
    },
    handleSizeChange(val){
       this.query.pageSize = val
       if(this.isPage){
           this.getAll();
       }else{
          this.getAll()
       }

    },
    handleCurrentChange(val) {
      this.query.pageNum = val
       if(this.isPage){
           this.getAll();
       }else{
         this.query.resPageNum = val
          this.getAll()
       }
    },
    async queryFunction() {
      try {
        if(this.selectValue === '' && this.inputValue === '')return
      let data = {
        page: this.query.resPageNum,
        size: this.query.pageSize,
        batteryNo: this.inputValue,
        eqmType: this.selectValue,
      };
      let res = await getBatteryDistributingInfoOfbtyNo(data);
      if (res.status != 200)  return this.$msg.error("查询失败");
      let datas = res.data.data.content;
      this.totalPages = res.data.data.totalElements;
      this.tableData = datas;
      this.isPage = false

      } catch (error) {

      }
    },
    renIndex(index){
      return (this.query.pageNum -1 ) * this.query.pageSize + index +1
    },
     cleanList(){
      this.$refs.multipleTable.clearSelection()
    },
    selectionChange(selection){
      this.bartchNum = selection
    },
    restFunction() {
      this.batteryInfo = {};
       this.isPage = true
       this.query ={
         pageNum:1,
         pageSize:20,
         resPageNum:1
       }
      this.getAll()
    },
   async importExcelData(){
       const confirm =await this.$MessageBox.confirm('是否导出文件', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(e=>e)
        if(confirm !== 'confirm')return
        this.$download(this.selectBatch,this.coulm,'电池包追溯')

    },
  },
};
</script>

<style lang='less' scoped>
.el-row{
  margin: 15px 0;
  .el-col{
    margin-right: 20px;
  }
}
.el-select{
  width: 100%;
}
</style>
