import { createAPI } from '@/utils/request'
const urlStr = '/service-baseinfo'
const urlStr2 = '/service-userinfo'
const urlStr3 = '/service-pay'

//运营商基础信息
//新增运营商
export const addOperator = data=>createAPI(urlStr2+'/operator/addOperator','POST',data)
//修改运营商信息
export const updateOperator = data=>createAPI(urlStr2+'/operator/updateOperator','POST',data)
//删除运营商信息
export const delOperator = id=>createAPI(urlStr2+'/operator/batch/?ids='+id,'delete')
//查询运营商信息
export const operatorList= data=>createAPI(urlStr2+'/operator/list/' + data,'GET')
//查询运营商信息
export const operatorAccountList= data=>createAPI(urlStr2+'/operator/operatorAccounList','GET')
//分页查询运营商信息  page="+data.page+"&size="+data.size+"&name="+data.name
export const operatorPage = data=>createAPI(urlStr2+"/operator/operatorAccountPage?" + data.url,'get')


//查询运营商树状信息
export const operatorTree= data=>createAPI(urlStr2+'/operator/tree','GET')
//查询运营商--车队树状信息
export const operatorTeamTree = data=>createAPI(urlStr+'/team/teamTree','GET')
//查询运营商--车队列表信息
export const operatorTeamList = data=>createAPI(urlStr+'/team/listOperatorTeams','GET')
//查询customer已获得权限
export const customerScope = data=>createAPI(urlStr3+'/pay/customerScope/getCustomerScope?customerId='+data,'GET')
export const bindScope = data => createAPI(urlStr3 + '/pay/customerScope/bindScope', 'post', data)

//运营商绑定车队
export const bindOperatorTeams = data=>createAPI(urlStr+'/team/bindTeams','POST',data)
export const unBindOperatorTeams = data=>createAPI(urlStr+'/team/unBindTeams','POST',data)
//查询运营商绑定车队
export const operatorTeamBindsList = data=>createAPI(urlStr+'/team/listBinds?operatorId='+data.operatorId,'get')
//分页查询运营商绑定车队
export  const operatorTeamBindsPage  = data=>createAPI(urlStr+"/team/pageBinds?page="+data.page+"&size="+data.size+"&operatorId="+data.operatorId,'get')

//用户绑定运营商
export const bindUserOperator = data=>createAPI(urlStr2+'/operator/bind','POST',data)
//用户解绑运营商
export const unBindUserOperator = data=>createAPI(urlStr2+'/operator/unBind','POST',data)
//查询用户绑定运营商
export const userOperatorBindsList = data=>createAPI(urlStr2+"/operator/listBinds?userId="+data.userId,'get')
//分页查询用户绑定运营商
export const userOperatorBindsPage  = data=>createAPI(urlStr2+"/operator/pageBinds?page="+data.page+"&size="+data.size+"&userId="+data.userId,'get')

// 发票管理页列表
export const getInvoiceList = data => createAPI(urlStr3 + '/pay/invoice/listInvoice', 'POST',data)
export const getaudit = data => createAPI(urlStr3 + '/pay/invoice/audit', 'PUT',data)
export const getOrderDetail = data => createAPI(urlStr3 + '/pay/invoice/orderDetail?invoiceId='+data.id+'&pageNo='+data.page+'&pageSize='+data.size, 'get')

