<template>
    <div class="app-container">
        <el-row class="main-flex">
            <el-col :span="24" class="mian-content">
                <div class="section-main">
                    <el-col class="choose-mian">
                        <div class="section-title">
                            车辆实时数据列表
                        </div>
                    </el-col>
                    <el-col :span="24">
                        <el-row class="table-box">
                            <el-table :data="tableData" ref="multipleTable"
                                      :header-cell-style="headerStyleEvent" :cell-class-name="goodsInfo" height="100%">
                                <el-table-column align="center" type="index" label="编号"
                                                 min-width="50"></el-table-column>

                                <el-table-column align="center" prop="vin" label="VIN码"
                                                 :show-overflow-tooltip="true"
                                                 min-width="100"></el-table-column>
                                <el-table-column align="center" prop="tboxCode" label="tbox编码" :show-overflow-tooltip="true"
                                                 min-width="100"></el-table-column>
                                <el-table-column align="center" prop="batteryCode" label="电池编码" min-width="110"></el-table-column>

                                <el-table-column align="center" label="车辆状态" min-width="100"
                                >
                                    <template>
                                        <span>
                                           {{ this.secondsoldShow }}
                                        </span>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" label="操作" fixed="right" width="160">
                                    <template slot-scope="scope">
                                        <span class="btn-color"
                                              @click="handleEdit(scope.row)" type="primary">
                                            发送
                                        </span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-row>
                    </el-col>

                    <el-col :span="24">
                        <el-row class="pagin">
                            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                                           :current-page="query.pageNum"
                                           :page-sizes="[10,20, 50, 100]"
                                           :page-size="query.pageSize"
                                           layout="total, sizes, prev, pager, next"
                                           :total="total"
                            ></el-pagination>
                        </el-row>
                    </el-col>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import {selectCompleteCarPage} from "@/utils/vehiclApi";
import '@/assets/font/font.css';
export default {
    name: "vehicleRealTimeData",
    data() {
        return {
            tableData: [],
            total:0,
            query: {
                pageNum: 1,
                pageSize: 10
            },
            secondsoldShow:'--'
        }
    },
    created() {
        this.getPage()
    },
    mounted() {
    },
    methods: {
        getPage() {
            let data={
                pageNum:this.query.pageNum,
                pageSize:this.query.pageSize,
            }
            selectCompleteCarPage(data).then((res) => {
                this.tableData = res.data.data.data
                this.share_data_time(this.tableData.createTime)
                this.total=res.data.data.total
                console.log(this.tableData,'this.tableData')
            })
        },
        handleEdit(){

        },
        headerStyleEvent({row, column, rowIndex, columnIndex}) {
            if (columnIndex == 1) {
                return 'padding:0px;'
            }
        },
        goodsInfo(row) {
            if (row.columnIndex == 1) {
                return 'goodsInfo'
            }
        },
        handleSizeChange(val){
            this.query.pageSize = val
            this.getPage()
        },
        handleCurrentChange(val){
            this.query.pageNum = val
            this.getPage()
        },
        share_data_time(data) {
            //时间
            let BirthDay = new Date(data);
            //获取当前时间
            let today = new Date();
            let timeold = (today.getTime() - BirthDay.getTime()); //总豪秒数
            let secondsold = Math.floor(timeold / 1000);          //总秒数
            if (secondsold>60){
                this.secondsoldShow='GPS掉线'
            }else {
                this.secondsoldShow='GPS已连接'
            }

        }
    }
}
</script>

<style scoped lang="less">
.section-main {
    width: 100%;
    background: #FFFFFF;
    padding: 24px 16px 16px 0px;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.choose-mian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
        display: inline-block;
        margin-left: 16px;
    }
}

.alert-box {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
}

.pagin {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.main-flex {

}

.left-style {
    padding-left: 20px;
}

.edit-style {
    color: #0D6CE4;
    font-size: 14px;
    cursor: pointer;
}

.more-search {
    display: flex;
    color: #0D6CE4;
    font-size: 14px;
    font-weight: 400;
    line-height: 40px;
    cursor: pointer;
    position: relative;
    left: -15px;
    i{
        line-height: 40px;
    }
}
.btn-color {
    color: #0D6CE4;
    cursor: pointer;
}
.table-box {
    /deep/ .el-table {
        height: 650px !important;
        max-height: 655px !important;
    }
}
</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';

</style>
