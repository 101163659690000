//运营管理
import { createAPI } from '@/utils/request'
const urlStr = '/service-operation'
const urlStr2 = '/service-mqtt/sendMessage'


//电网电价设置
//电网电价设置--列表查询
export const selectElecPriceList = data => createAPI(urlStr + "/elecPrice/selectElecPriceList" ,'get',data)
//电网电价设置--分页查询
export const selectElecPricePage = data => createAPI(urlStr + "/elecPrice/page" ,'get',data)
//电网电价设置--修改信息
export const updateElecPrice = data => createAPI(urlStr + '/elecPrice/update', 'post', data)
//电网电价设置--新增电价信息
export const addElecPrice = data => createAPI(urlStr + '/elecPrice/add', 'post', data)
//电网电价设置--批量删除
export const batchDelete = id => createAPI(urlStr + '/elecPrice/batchDelete?ids='+id,'delete')

//电网电价(月日)分时模型设置--分页查询
export const selectElecDatePage = data => createAPI(urlStr + "/elecDate/page" ,'get',data)
//电网电价(月日)分时模型设置--修改信息
export const updateElecDate = data => createAPI(urlStr + '/elecDate/update', 'post', data)
//电网电价(月日)分时模型设置--新增电价信息
export const addElecDate = data => createAPI(urlStr + '/elecDate/add', 'post', data)
//电网电价(月日)分时模型设置--批量删除
export const elecDateBatchDelete = id => createAPI(urlStr + '/elecDate/batchDelete?ids='+id,'delete')

//电网电价(时分秒)分时模型设置--分页查询
export const selectElecTimePage = data => createAPI(urlStr + "/elecTime/page" ,'get',data)
//电网电价分时模型设置--修改信息
export const updateElecTime = data => createAPI(urlStr + '/elecTime/update', 'post', data)
//电网电价分时模型设置--新增电价信息
export const addElecTime = data => createAPI(urlStr + '/elecTime/add', 'post', data)
//电网电价分时模型设置--批量删除
export const elecTimeBatchDelete = id => createAPI(urlStr + '/elecTime/batchDelete?ids='+id,'delete')

//电网电价设置--下达指令
export const setChargePriceMqtt = data => createAPI(urlStr2 + '/setChargePriceModel', 'get', data)

//场站运营模型信息
//场站运营模型--分页查询
export const checkStationRunList = data=> createAPI(urlStr + "/stationRunModel/specificPage"+data.url,'get')
//场站运营模型--新增信息
export const addStationRun = data => createAPI(urlStr + '/stationRunModel/add', 'post', data)
//场站运营模型--修改信息
export const setStationRun = data => createAPI(urlStr + '/stationRunModel/update', 'post', data)
//场站运营模型--批量删
export const deleteStationRun = id => createAPI(urlStr + '/stationRunModel/batch?ids='+id,'delete')
//场站运营模型--下达指令
export const setStationRunMqtt = data => createAPI(urlStr2 + '/setStationRunModel', 'post', data)


//换电计费模型信息
//换电计费模型--分页查询
export const selectBillingModelPage = data => createAPI(urlStr + "/billingModel/page",'get',data)
//换电计费模型--新增信息
export const addBillingModel = data => createAPI(urlStr + '/billingModel/add', 'post', data)
//换电计费模型--修改信息
export const updateBillingModel= data => createAPI(urlStr + '/billingModel/update', 'post', data)
//换电计费模型--批量删除
export const batchDeleteBillingModel = id => createAPI(urlStr + '/billingModel/batchDelete?ids='+id,'get')
//换电计费模型--列表查询
export const getBillingModelList = data => createAPI(urlStr + "/billingModel/selectBillingModelList" ,'get',data)


//分时换电计费模型信息
//分时换电计费模型--分页查询
export const selectTimeBillingPage = data => createAPI(urlStr + "/timeBilling/page",'get',data)
//分时换电计费模型--新增信息
export const addTimeBilling = data => createAPI(urlStr + '/timeBilling/add', 'post', data)
//分时换电计费模型--修改信息
export const updateTimeBilling= data => createAPI(urlStr + '/timeBilling/update', 'post', data)
//分时换电计费模型--批量删除
export const batchDeleteTimeBilling = id => createAPI(urlStr + '/timeBilling/batchDelete?ids='+id,'get')
//分时换电计费模型--列表查询
export const getTimeBillingList = data => createAPI(urlStr + "/timeBilling/selectBillingModelList"  + data.url,'get')


//区域换电计费模型--下达指令
export const setStaPriceModelMqtt = data => createAPI(urlStr2 + '/setStaPriceModel', 'get', data)


//场站的电池运营模型
//场站的电池运营模型--分页查询
export const getBatteryModelPage = data=> createAPI(urlStr + "/batteryOpModel/specificPage"+data.url,'get')
//场站的电池运营模型--新增信息
export const addBatteryModel = data => createAPI(urlStr + '/batteryOpModel/add', 'post', data)
//场站的电池运营模型--修改信息
export const updateBatteryModel = data => createAPI(urlStr + '/batteryOpModel/update', 'post', data)
//场站的电池运营模型--批量删除
export const deleteSetOpModel = id => createAPI(urlStr + '/batteryOpModel/batch?ids='+id,'delete')
//场站的电池运营模型--数据拆线呢
export const findSetOpModeById = id => createAPI(urlStr + '/batteryOpModel/findSetOpModeById/' + id, 'get')
//场站运营模型--下达指令
export const setBatteryOpModelMqtt = data => createAPI(urlStr2 + '/setBatteryOpModel', 'post', data)
//分页查询故障列表
export const faultReport = data=> createAPI(urlStr + "/faultReport/specificPage"+data.url,'post',data)


