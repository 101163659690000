<template>
  <el-card>
    <el-row>
      <el-col :span="4">
        <el-input placeholder="请输入电池序列号" v-model.trim="inputValue"></el-input>
      </el-col>
      <el-col :span="6">
        <el-button type="primary" @click="queryFunction">查询</el-button>
        <el-button @click="restFunction">重置</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-button type="primary" size="small" @click="importExcelData" class="el-icon-download">批量导出</el-button>
    </el-row>
    <el-row>
      <el-table
        id="safeGuard"
        :data="tableData"
        style="width: 100%"
        @selection-change="selectionChange"
        height="300"
        ref="safeguTable">
        <el-table-column type="selection" width="40"></el-table-column>
        <el-table-column type="index" :index="renIndex" label="编号" width="50"></el-table-column>
        <el-table-column prop="btyNo" label="电池序列号"></el-table-column>
        <el-table-column prop="binNo" label="仓位号"></el-table-column>
        <el-table-column prop="startSoh" label="开始SOH"></el-table-column>
        <el-table-column prop="endSoh" label="结束SOH"></el-table-column>
        <el-table-column prop="soh" label="当前SOH"></el-table-column>
        <el-table-column prop="startTime" :formatter="$dateFormat" label="维护开始时间"></el-table-column>
        <el-table-column prop="endTime" :formatter="$dateFormat" label="维护结束时间"></el-table-column>
        <el-table-column prop="result" label="维护结果">
          <template slot-scope="scope">
            <span v-if="scope.row.result === 1">正常</span>
            <span v-else-if="scope.row.result === 2">中断</span>
            <span v-else-if="scope.row.result === 3">其它</span>
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" :formatter="$dateFormat" label="更新时间"></el-table-column>
      </el-table>
    </el-row>
    <el-row>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNum"
        :page-sizes="[20, 25, 30]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalPages">
      </el-pagination>
    </el-row>
  </el-card>
</template>

<script>
  import {
    getMainAll,
    getBatteryMainTainLogOfpage,
    getBatteryMainTainLogOfbtyNo,
  } from "@/utils/cellPutApi";

  export default {
    name: 'safeguard',
    data() {
      //定义数据
      return {
        query: {
          pageNum: 1,
          pageSize: 20,
          resPageNum: 1
        },
        isPage: true,
        selectList: [],
        tableData: [],
        bartchNum: [],
        totalPages: 0,
        inputValue: "",
        selectValue: null,
        options: [
          {value: 1, label: "正常"},
          {value: 2, label: "中断"},
          {value: 3, label: "其它"},
        ],
        coulm: [
          {prop: 'btyNo', label: '电池序列号'},
          {prop: 'binNo', label: '仓位号'},
          {prop: 'startSoh', label: '开始SOH'},
          {prop: 'endSoh', label: '结束SOH'},
          {prop: 'soh', label: '当前SOH'},
          {prop: 'startTime', label: '维护开始时间'},
          {prop: 'endTime', label: '维护结束时间'},
          {prop: 'result', label: '维护结果'},
          {prop: 'updateTime', label: '更新时间'},
        ]
      };
    },
    computed: {
      selectBatch() {
        if (this.bartchNum.length === 0) {
          return this.selectList.map(item => {
            return item
          })
        } else {
          return this.bartchNum.map(item => {
            return item
          })
        }
      }
    },
    created() {
      //进入页面自动运行
      this.getAll();
      this.getMainListAll()
    },
    methods: {
      //自定义方法
      async getAll() {
        try {
          let data = {page: this.query.pageNum, size: this.query.pageSize};
          let res = await getBatteryMainTainLogOfpage(data);
          if (res.status !== 200) return this.$msg.error("获取电池维护日志失败");
          let datas = res.data.data.content;
          this.totalPages = res.data.data.totalElements;
          this.tableData = datas;

        } catch (error) {

        }
      },
      async getMainListAll() {
        const res = await getMainAll()
        this.selectList = res.data.data
      },
      handleSizeChange(val) {
        this.query.pageSize = val
        if (this.isPage) {
          this.getAll()
        } else {
          this.queryFunction()
        }

      },
      handleCurrentChange(val) {
        this.query.pageNum = val
        if (this.isPage) {
          this.getAll()
        } else {
          this.query.resPageNum = val
          this.queryFunction()
        }
      },
      selectionChange(selection) {
        this.bartchNum = selection
      },
      cleanList() {
        this.$refs.safeguTable.clearSelection()
      },
      async importExcelData() {
        const confirm = await this.$MessageBox.confirm('是否导出文件', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(e => e)
        if (confirm !== 'confirm') return
        this.$download(this.selectBatch, this.coulm, '电池包维护信息')
      },
      async queryFunction() {
        try {
          if (this.inputValue === '' && this.selectValue === '') return
          let data = {
            page: this.query.resPageNum,
            size: this.query.pageSize,
            btyNo: this.inputValue,
            result: this.selectValue,
          };
          let res = await getBatteryMainTainLogOfbtyNo(data);
          if (res.status != 200) {
            return this.$msg.error("查询失败");
          }
          let datas = res.data.data.content;
          this.totalPages = res.data.data.totalElements;
          this.tableData = datas;
          this.isPage = false

        } catch (error) {

        }
      },
      renIndex(index) {
        return (this.query.pageNum - 1) * this.query.pageSize + index + 1
      },
      restFunction() {
        this.inputValue = ''
        this.selectValue = ''
        this.isPage = true
        this.query = {
          pageNum: 1,
          pageSize: 20,
          resPageNum: 1
        }
        this.getAll()
      },

    },
  };
</script>

<style lang='less' scoped>
  .el-row {
    margin: 15px 0;

    .el-col {
      margin-right: 20px;
    }
  }

  .el-select {
    width: 100%;
  }
</style>
