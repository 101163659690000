//换电故障信息
import { createAPI } from '@/utils/request'
const urlStr3 = '/springcloud-service-provider03'
const urlStr = '/cloud-platform'

//故障等级信息
//查询故障等级信息
export const queryLevel =data=>createAPI(urlStr3+'/faultLevel/findByLevelName/'+data,'get')

//获取故障等级信息
export const getLevel =()=>createAPI(urlStr3+'/faultLevel/findAll','get')

//获取故障等级信息分页
export const getLevelPage =data=>createAPI(urlStr3+'/faultLevel/specificPage','get',data)

//新增故障等级信息
export const addLevelMsg = data=>createAPI(urlStr3+"/faultLevel/add",'post',data)

//修改故障等级信息
export const levelChange = data=>createAPI(urlStr3+'/faultLevel/update','post',data)

//删除故障等级信息
export const removeLevelMsg = id=>createAPI(urlStr3+'/faultLevel/delete/'+id,'delete')

//设备故障基础信息
//查询故障基础信息
export const faultBasePage = (current, size, data)=>createAPI(urlStr+'/cloudPlatform/faultBasePage/' + current + "/" + size,'post',data)
//新增
export const addFaultBase = data => createAPI(urlStr + '/cloudPlatform/addFaultBase', 'post', data)
//修改
export const updateFaultBase = data => createAPI(urlStr + '/cloudPlatform/updateFaultBase', 'post', data)
//批量删除
export const deleteFaultBase = id => createAPI(urlStr + '/cloudPlatform/deleteFaultBase/' + id, 'delete')


// //故障基础信息
// //查询故障基础信息
// export const checkFultMsg = data=>createAPI(urlStr3+'/eqmFaultBaseInfo/qureyTBdEqmFaultBaseInfo/'+data,'get')
//
// //获取故障基础信息
// export const getFultMsg = ()=>createAPI(urlStr3+'/eqmFaultBaseInfo/findAll','get')
//
// //新增故障基础信息
// export const addFultMsg = data=>createAPI(urlStr3+'/eqmFaultBaseInfo/add','post',data)
//
// //修改故障基础信息
// export const setFultMsg = data=>createAPI(urlStr3+'/eqmFaultBaseInfo/update','post',data)


//未处理故障信息
//查询未处理故障信息
export const checkUndisposed =data=>createAPI(urlStr3+'/rsStationUnRepairFaultLog/specificPage','get',data)
//获取未处理故障信息
export const getUndisposed = ()=>createAPI(urlStr3+'/rsStationUnRepairFaultLog/findAll','get')

//获取未处理故障信息分页
export const getUndisposedPage = (data)=>createAPI(urlStr3+'/rsStationUnRepairFaultLog/specificPage','get',data)

//新增未处理故障信息
export const addUndisposed =data=>createAPI(urlStr3+'/rsStationUnRepairFaultLog/add','post',data)

//修改未处理故障信息
export const setUndisposed = data=>createAPI(urlStr3+'/rsStationUnRepairFaultLog/update','post',data)

//删除未处理故障信息
export const deleteUndisposed = id=>createAPI(urlStr3+'/rsStationUnRepairFaultLog/delete/'+id,'delete')


//已处理故障信息
//查询已处理故障信息
export const checkProcessed =data=>createAPI(urlStr3+'/rsStationRepairedFaultLog/specificPage','get',data)

//获取已处理故障信息
export const getProcessed = ()=>createAPI(urlStr3+'/rsStationRepairedFaultLog/findAll','get')

//获取已处理故障信息分页
export const getProcessedPage = (data)=>createAPI(urlStr3+'/rsStationRepairedFaultLog/specificPage','get',data)

//新增已处理故障信息
export const addProcessed =data=>createAPI(urlStr3+'/rsStationRepairedFaultLog/add','post',data)

//修改已处理故障信息
export const setProcessed = data=>createAPI(urlStr3+'/rsStationRepairedFaultLog/update','post',data)

//删除已处理故障信息
export const deleteProcessed = id=>createAPI(urlStr3+'/rsStationRepairedFaultLog/delete/'+id,'delete')


//通讯开始状态日志
//查询通讯开始状态日志
export const checkreportStart = data=>createAPI(urlStr3 +'/rsComStateStartLog/specificPage','get',data)

//获取通讯开始状态日志
export const getreportStart =()=>createAPI(urlStr3+'/rsComStateStartLog/findAll')

//新增通讯开始状态日志
export const addreportStart = data=>createAPI(urlStr3+'/rsComStateStartLog/add','post',data)

//修改通讯开始状态日志
export const setreportStart = data=>createAPI(urlStr3+'/rsComStateStartLog/update','post',data)

//删除通讯开始状态日志
export const removereportStart =id=>createAPI(urlStr3+'/rsComStateStartLog/delete?id='+id,'delete')


//通讯结束状态日志
//查询通讯结束状态日志
export const checkreportEnd = data=>createAPI(urlStr3 +'/rsComStateEndLog/specificPage','get',data)


//新增通讯结束状态日志
export const addreportEnd = data=>createAPI(urlStr3+'/rsComStateEndLog/add','post',data)

//修改通讯结束状态日志
export const setreportEnd = data=>createAPI(urlStr3+'/rsComStateEndLog/update','post',data)

//删除通讯结束状态日志
export const removereportEnd =id=>createAPI(urlStr3+'/rsComStateEndLog/delete/'+id,'delete')
