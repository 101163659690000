<template>
    <div class="app-container">
        <el-row class="main-flex">
            <el-col :span="3" class="side-search" v-if="isopenShow">
                <operatorTeamTreeVue @emitToParent='getTreeData' @isShow="isHidden"></operatorTeamTreeVue>
            </el-col>
            <el-col v-if="!isopenShow" class="small-side">
                <div class="change-in" @click="isopenShow = !  isopenShow "></div>
            </el-col>

            <!-- 搜索区域 -->
            <el-col :span="21" class="mian-content">
                <div class="head-section">
                    <el-col :span="6">
                        <el-form :inline="true">
                            <el-form-item label="车队名称：">
                                <el-input placeholder="请输入车队名称" v-model.trim="vehicleTeamNameValue"
                                          clearable></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="7">
                        <el-form :inline="true" class="left-style">
                            <el-form-item label="手机号：">
                                <el-input placeholder="请输入手机号" v-model.trim="phoneValue" clearable></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="11">
                        <div class="btn-end">
                            <span class="btn-rest">
                            <el-button @click="restFunction" plain>重置</el-button>
                            </span>
                            <span class="btn-submit">
                            <el-button type="primary" @click="getSearch" plain
                                       v-hasCode="['base:account:query']">查询</el-button>
                            </span>
                        </div>
                    </el-col>
                </div>
                <!--  表格部分-->
                <div class="section-main">
                    <el-col class="choose-mian">
                        <div class="section-title">
                            账户列表
                        </div>
                        <div>
                            <!--选择项栏-->
                            <span class="alert-box">
                          已选择{{ selectList.length }}项
                            <span @click="clean" style="margin-left:8px;color:#0D6CE4;cursor:pointer;">清空</span>
                        </span>
                            <span class="pain-btn">
                                <el-button @click="removeAll" size="small" plain
                                           v-hasCode="['base:account:delete']">删除
                    </el-button>
                            </span>
                            <span class="add-btn">
                               <el-button type="primary" @click="addDialogFunction" plain
                                          v-hasCode="['base:account:add']">新增
                    </el-button>
                            </span>
                        </div>
                    </el-col>

                    <el-col :span="24">
                        <el-row class="table-box">
                            <el-table
                                :header-cell-style="headerStyleEvent" :cell-class-name="goodsInfo"
                                :data="tableData"
                                ref="multipleTable"
                                @selection-change="selectionChange"
                            >
                                <el-table-column align="center" type="selection"></el-table-column>
                                <el-table-column align="center" type="index" :index="renIndex" label="编号"
                                                 min-width="50"></el-table-column>
                                <el-table-column align="center" prop="accountName" label="账户名称"
                                                 min-width="100"></el-table-column>
                                <el-table-column align="center" prop="phone" label="手机号"
                                                 min-width="100"></el-table-column>
                                <el-table-column align="center" prop="balance" label="账户余额"
                                                 min-width="120"></el-table-column>

                                <el-table-column align="center" prop="teamName" label="绑定车队" min-width="200"
                                                 :formatter="teamNameFormat"
                                                 :show-overflow-tooltip="true">
                                </el-table-column>


                                <el-table-column align="center" prop="isDefault" label="是否默认账户">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.isDefault === 0">否</span>
                                        <span v-else-if="scope.row.isDefault === 1">是</span>
                                    </template>
                                </el-table-column>

                                <el-table-column align="center" prop="state" label="状态" width="120">
                                    <template slot-scope="scope">
                                    <span v-if="scope.row.state === 0" class="states"
                                          style="color:#FA9600;">待审核 </span>
                                        <span v-else-if="scope.row.state === 1" class="states"
                                              style="color:#0D6CE4;"> 启用 </span>
                                        <span v-else-if="scope.row.state === 2" class="states"
                                              style="color:#FF6165;">禁用</span>
                                        <span v-else-if="scope.row.auditStatus === 3" class="states"
                                              style="color:#8D9094;">锁定</span>
                                        <span v-else class="states" style="color:#FFCD29;"> 未知</span>
                                    </template>
                                </el-table-column>


                                <el-table-column align="center" prop="createTime" label="创建时间" min-width="160"
                                                 :formatter="$dateFormat"></el-table-column>

                                <el-table-column align="center" label="操作" min-width="160">
                                    <template slot-scope="scope">
                                        <span class="edit-style edit-margin"
                                              @click="handleEdit(scope.row)"
                                              v-hasCode="['base:account:update']">编辑
                                        </span>

                                        <span class="edit-style edit-margin"
                                              @click="accountCharge(scope.row)"
                                              v-hasCode="['base:account:charge']">充值
                                        </span>

                                        <span class="edit-style"
                                              @click="queryRecord(scope.row)"
                                              v-hasCode="['account:charge:log']">充值记录
                                        </span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-row>
                    </el-col>

                    <el-col :span="24">
                        <el-row class="pagin">
                            <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="query.pageNum"
                                :page-sizes="[10,30, 50, 100]"
                                :page-size="query.pageSize"
                                layout="total, sizes, prev, pager, next"
                                :total="totalPages"
                            ></el-pagination>
                        </el-row>
                    </el-col>
                </div>
            </el-col>


        </el-row>

        <el-dialog
            :title="isAdd?'新增账户信息':'修改账户信息'"
            :visible.sync="addDialog"
            @close="close"
            width="50%"
        >
            <el-form size="middle" ref="addForm" :model="addForm" label-width="100px" :rules="rules">
                <el-row>
                    <el-col :span="22">

                        <el-form-item label="归属车队:" prop="teamId">
                            <el-select v-model="addForm.teamId">
                                <el-option v-for="item in teamList" :key="item.id" :label="item.teamName"
                                           :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="账户名称:" prop="accountName">
                            <el-input v-model="addForm.accountName" show-word-limit maxlength="30"></el-input>
                        </el-form-item>
                        <el-form-item label="账户编码:" prop="code">
                            <el-input v-model="addForm.code" show-word-limit maxlength="30"></el-input>
                        </el-form-item>
                        <el-form-item label="手机号:" prop="phone">
                            <el-input v-model="addForm.phone" v-if="this.isAdd" show-word-limit
                                      maxlength="11"></el-input>
                            <el-input v-model="addForm.phone" :disabled="true" v-else></el-input>
                        </el-form-item>

                        <el-form-item label="状态:" prop="state">
                            <el-select v-model="addForm.state" :disabled="isAdd">
                                <el-option
                                    v-for="item in stateList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                ></el-option>
                            </el-select>
                        </el-form-item>


                        <el-form-item label="默认(扣款)账户" prop="isDefault">
                            <el-radio-group v-model="addForm.isDefault">
                                <el-radio
                                    v-for="item in defaultGroup"
                                    :key="item.value"
                                    :label="item.value"
                                >{{ item.label }}
                                </el-radio>
                            </el-radio-group>
                        </el-form-item>

                    </el-col>

                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="addDialog = false">取 消</el-button>
        <el-button type="primary" @click="addFunction">确 定</el-button>
      </span>
        </el-dialog>


        <el-dialog title="账户充值" :visible.sync="chargeDialogVisible" width="30%">
            <el-form size="middle" ref="chargeForm" :model="chargeForm" label-width="100px" :rules="chargeRules">
                <el-form-item label="充值金额" prop="chargeAmount">

                    <el-input v-model="chargeForm.chargeAmount"
                              onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
                              onafterpaste="this.value=this.value.match(/\d+\.?\d{0,2}/)"
                              maxlength="6"
                              show-word-limit clearable/>
                </el-form-item>

                <el-form-item label="类型" prop="chargeType">
                    <el-radio-group v-model="chargeForm.chargeType">
                        <el-radio
                            v-for="item in chargeTypeList"
                            :key="item.value"
                            :label="item.value"
                        >{{ item.label }}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>

            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="accountChargeCancel()">取 消</el-button>
                <el-button type="primary" @click="accountChargeConfirm()">确 定</el-button>
            </span>

        </el-dialog>

        <el-dialog title="账户充值记录" :visible.sync="recordDialogVisible" width="70%">

            <el-row>
                <el-table
                    :header-cell-style="{'text-align':'center'}"
                    :cell-style="{'text-align':'center'}"
                    :data="recordData"
                    ref="multipleTable"
                    style="height: 85%"
                    @selection-change="selectionChange">


                    <el-table-column type="index" :index="renRecordIndex" label="编号"
                                     min-width="50"></el-table-column>
                    <el-table-column prop="accountPhone" label="手机号" min-width="100"></el-table-column>

                    <el-table-column prop="trAmt" label="充值金额" min-width="120">
                        <template slot-scope="scope">
                            <span v-if="scope.row.type === 0" style="color:orange">-{{ scope.row.trAmt }}</span>
                            <span v-else>{{ scope.row.trAmt }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column prop="accountBalanceOrigin" label="充值前账户金额"
                                     min-width="120"></el-table-column>

                    <el-table-column prop="accountBalance" label="充值后账户金额" min-width="120"></el-table-column>

                    <el-table-column prop="type" label="充值类型">
                        <template slot-scope="scope">
                            <span v-if="scope.row.type === 0" style="color:orange">扣款</span>
                            <span v-else-if="scope.row.type === 1" style="color:#1890ff">充值</span>
                            <span v-else-if="scope.row.type === 2">换电消费</span>
                            <span v-else-if="scope.row.type === 3">充电消费</span>
                            <span v-else-if="scope.row.type === 4">其他</span>
                            <span v-else>未知</span>
                        </template>
                    </el-table-column>

                    <el-table-column prop="tradeType" label="充值渠道">
                        <template slot-scope="scope">
                            <span v-if="scope.row.tradeType === 1">微信</span>
                            <span v-else-if="scope.row.tradeType === 2">支付宝</span>
                            <span v-else-if="scope.row.tradeType === 3">云平台</span>
                        </template>
                    </el-table-column>


                    <el-table-column prop="status" label="状态" min-width="60">
                        <template slot-scope="scope">
                            <span v-if="scope.row.status === 0"
                                  style="color:orange; font-weight: bold"> 未付款 </span>
                            <span v-else-if="scope.row.status === 1 && scope.row.type !== 0"
                                  style="color:#1890ff; font-weight: bold"> 已付款 </span>
                            <span v-else-if="scope.row.status === 1 && scope.row.type === 0"
                                  style="color:#1890ff; font-weight: bold"> 已扣款</span>
                            <span v-else-if="scope.row.status === 2"
                                  style="color:red;"><i
                                style="background-color:red;"></i> 付款失败 </span>
                            <span v-else-if="scope.row.status === 3"
                                  style="font-weight: bold"> 退款中 </span>
                            <span v-else-if="scope.row.status === 4"
                                  style="font-weight: bold"> 退款成功 </span>
                            <span v-else-if="scope.row.status === 5"
                                  style="font-weight: bold"> 退款失败 </span>
                        </template>
                    </el-table-column>


                    <el-table-column prop="createTime" label="充值时间" min-width="150"></el-table-column>

                </el-table>
            </el-row>

            <el-row style="margin-top: 20px">
                <el-pagination
                    @size-change="handleRecordSizeChange"
                    @current-change="handleRecordCurrentChange"
                    :current-page="recordPageNum"
                    :page-sizes="[10,20]"
                    :page-size="recordPageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="recordTotalPages"
                ></el-pagination>
            </el-row>
        </el-dialog>


    </div>
</template>

<script>
import '@/assets/font/font.css';
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {
    addAccount,
    createAccountCharge,
    delAccounts,
    getAccountPage,
    updateAccount,
    queryChargeRecord
} from "@/utils/account";
import {getTeamList} from "@/utils/vehiclApi";
import operatorTeamTreeVue from "@/components/tree/operatorTeamTree";

export default {
    name: "account",
    components: {operatorTeamTreeVue},
    data() {
        var checkPhone = (rule, value, callback) => { // 手机号验证
            if (!value) {
                return callback(new Error('手机号不能为空'));
            } else {
                const reg = /^1[3456789]\d{9}$/
                if (reg.test(value)) {
                    callback();
                } else {
                    return callback(new Error('请输入正确的手机号'));
                }
            }
        };
        //定义数据
        return {
            isopenShow: true,
            vehicleTeamNameValue: "",
            phoneValue: null,
            query: {
                pageNum: 1,
                pageSize: 10,
            },
            tableData: [],
            totalPages: 0,

            tmpRow: null,
            recordPageNum: 1,
            recordPageSize: 10,
            recordTotalPages: 0,
            recordData: [],
            recordDialogVisible: false,

            addForm: {
                accountName:"",
                teamId: null,
                code: null,
                phone: null,
                state: 0,
                isDefault: 0
            },

            stateList: [
                {value: 0, label: "待审核"},
                {value: 1, label: "启用"},
                {value: 2, label: "禁用"},
                {value: 3, label: "锁定"},
            ],
            defaultGroup: [
                {value: 0, label: "否"},
                {value: 1, label: "是"},
            ],

            rules: {
                teamId: [
                    {required: true, message: "请选择车队", trigger: "blur"},
                ],
                phone: [
                    {required: true, message: "请输入手机号", trigger: "blur"},
                    {validator: checkPhone, trigger: 'blur'}
                ],
                state: [
                    {required: true, message: "请选择状态", trigger: "blur"},
                ],
                isDefault: [
                    {required: true, message: "请选择账户类型", trigger: "blur"},
                ]
            },

            chargeRules: {
                chargeType: [
                    {required: true, message: "请选择消费类型", trigger: "blur"},
                ],
                chargeAmount: [
                    {
                        //pattern:/^[0-9]+(.[0-9]{2})?$/,
                        required: true,
                        message: "请输入金额",
                        trigger: "blur"
                    },
                ],
            },

            addDialog: false,
            isAdd: true,//是否新增

            selectList: [],

            teamList: [],//车队数组

            //左侧运营商--车队树图
            treeData: {
                operatorTeamOptions: [],  //运营商-车队树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
                operatorTeamNameTree: "",
                operatorTeamIdTree: null,
                defaultExpandAll: false,
            },

            chargeDialogVisible: false,
            chargeForm: {
                chargeAmount: null,//充值金额
                chargeType: 1, //消费类型:0扣费,1充值
            },
            chargeTypeList: [
                {value: 1, label: "账户充值"},
                {value: 0, label: "账户扣款"},
            ],

            accountDate: null,

        };

    },
    computed: {},
    created() {
        this.getPage();
        this.findAllTeamInfo();
    },
    methods: {
        headerStyleEvent({row, column, rowIndex, columnIndex}) {
            if (columnIndex == 1) {
                return 'padding:0px;'
            }
        },
        goodsInfo(row) {
            if (row.columnIndex == 1) {
                return 'goodsInfo'
            }
        },
        isHidden(message) {
            this.isopenShow = message
        },
        //获取所有车队信息
        async findAllTeamInfo() {
            let data = {};
            data.url = "";
            const res = await getTeamList(data)
            this.teamList = res.data.data
        },


        //分页查询
        async getPage() {
            try {
                let data = {};
                var url = "?page=" + this.query.pageNum + "&size=" + this.query.pageSize;
                let id = this.treeData.operatorTeamIdTree;
                if (id != "" && id != null) {
                    if (id.indexOf("operator") != -1) {
                        id = id.slice(9);
                        // url += "&operatorId=" + Number(id)
                        this.query.operatorId = Number(id);
                        this.query.teamId = "";
                        url += "&operatorId=" + Number(id);
                    } else {
                        id = id.slice(5);
                        // url += "&teamId=" + Number(id)
                        this.query.operatorId = "";
                        this.query.teamId = Number(id);
                        data.teamId = Number(id);
                    }
                }

                data.url = url;
                if (this.vehicleTeamNameValue != "" && this.vehicleTeamNameValue != null) {
                    data.teamName = this.vehicleTeamNameValue
                }
                if (this.phoneValue != "" && this.phoneValue != null) {
                    data.phone = this.phoneValue
                }
                let res = await getAccountPage(data);
                if (res.data.code !== 200) return tis.$msg.error("获取账户信息失败");

                let datas = res.data.data.list;
                this.totalPages = res.data.data.total;
                this.tableData = datas;
            } catch (error) {
            }
        },

        getSearch() {
            this.query.pageNum = 1;
            this.getPage();
        },

        //新增与修改
        async addFunction() {
            this.$refs.addForm.validate(async (valid) => {
                if (!valid) return this.$msg.info("账户信息不能为空");
                try {
                    console.log("===========this.addForm.accountName==========",this.addForm.accountName)
                    let data = JSON.parse(JSON.stringify(this.addForm))
                    if (this.isAdd) {
                        let res = await addAccount(data);
                        if (res.data.code != 200) return this.$msg.error("账户添加失败!" + res.data.msg);
                        this.$msg.success("账户信息添加成功");
                        this.addDialog = false;
                        this.getPage();
                    } else {
                        let res = await updateAccount(data);
                        if (res.data.code != 200) return this.$msg.error("账户信息修改失败" + res.data.msg);
                        this.$msg.success("账户信息修改成功");
                        this.addDialog = false;
                        this.getPage();
                    }
                } catch (e) {
                }
            });
        },
        renIndex(index) {
            return (this.query.pageNum - 1) * this.query.pageSize + index + 1
        },

        renRecordIndex(index) {
            return (this.recordPageNum - 1) * this.recordPageSize + index + 1
        },


        // 分页事件
        handleRecordSizeChange(val) {
            this.recordPageSize = val;
            this.flushRecord();
        },

        handleRecordCurrentChange(val) {
            this.recordPageNum = val;
            this.flushRecord();
        },

        flushRecord() {
            this.queryRecord(this.tmpRow)
        },


        //修改信息填充
        handleEdit(row) {
            this.addDialog = true;
            this.isAdd = false;
            this.editTile = false
            let rowData = this.copyDeep(row);
            this.addForm = rowData;
        },

        //账户充值
        accountCharge(row) {
            let rowData = this.copyDeep(row);
            this.accountDate = rowData;
            this.chargeForm = {
                chargeAmount: null,//充值金额
                chargeType: 1, //消费类型:0扣费,1充值
            },
                this.chargeDialogVisible = true;
        },

        //账户充值记录
        async queryRecord(row) {
            this.tmpRow = row
            row.url = "?page=" + this.recordPageNum + "&size=" + this.recordPageSize;
            let res = await queryChargeRecord(row);
            // const res = queryChargeRecord(row)
            if (res.data.code === 200) {
                this.recordData = res.data.data.list
                this.recordTotalPages = res.data.data.total
                this.recordDialogVisible = true;
            } else return this.$msg.error("查询充值记录失败")

        },


        copyDeep(templateData) {
            // templateData 是要复制的数组或对象，这样的数组或者对象就是指向新的地址的
            return JSON.parse(JSON.stringify(templateData));
        },


        //重置
        restFunction() {
            this.restForm();
            this.treeData.operatorTeamNameTree = "";
            this.treeData.operatorTeamIdTree = null;
            this.vehicleTeamNameValue = "";
            this.phoneValue = null;
            this.query = {
                pageNum: 1,
                pageSize: 10
            }
            this.getPage();
        },

        restForm() {
            this.addForm = {
                accountName:"",
                teamId: null,
                code: null,
                phone: null,
                state: 0,
                isDefault: 0
            }
        },

        close() {
            this.$refs.addForm.resetFields();
            this.restForm();
            this.isAdd = true;
            this.addDialog = false
        },

        accountChargeCancel() {
            this.accountPhone = null;
            this.chargeForm = {
                chargeAmount: null,//充值金额
                chargeType: 1, //消费类型:0扣费,1充值
            },
                this.accountDate = null;
            this.chargeDialogVisible = false;
        },

        async accountChargeConfirm() {

            this.$refs.chargeForm.validate(async (valid) => {
                let data = {};
                data.teamId = this.accountDate.teamId;
                data.accountPhone = this.accountDate.phone;
                data.accountId = this.accountDate.id;
                data.trAmt = this.chargeForm.chargeAmount;
                data.type = this.chargeForm.chargeType;
                const rechargeRes = await createAccountCharge(data);
                this.chargeDialogVisible = false;
                if (rechargeRes.data.code != 200) {
                    this.$msg.error("充值失败:" + rechargeRes.data.msg);
                } else {
                    this.$msg.success(data.type == 1 ? "充值成功" : "扣款成功");
                    this.getPage();
                }
            });

        },


        // 分页事件
        handleSizeChange(val) {
            this.query.pageSize = val;
            this.getPage();
        },

        selectionChange(rows) {
            this.selectList = rows;
        },

        addDialogFunction() {
            this.restForm();
            this.addDialog = true;
        },

        handleCurrentChange(val) {
            this.query.pageNum = val;
            this.getPage();
        },

        clean() {
            this.$refs.multipleTable.clearSelection();
        },

        teamNameFormat(row) {
            var teamName = "尚未绑定"; //stationType
            if (row.teamVO != null) {
                return row.teamVO.teamName
            }
            return teamName;
        },

        async removeAll() {
            if (this.selectList.length === 0)
                return this.$msg.info("请选择需要删除的账户");
            const confirm = await this.$MessageBox.confirm(
                "此操作将删除选中的账户?",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            );
            if (confirm !== "confirm") return;
            const list = this.selectList;

            const res = await delAccounts(list);
            if (res.data.code !== 200) {
                this.$msg.error(res.data.msg);
            } else {
                this.$msg.success("删除成功");
            }

            this.getPage();
        },

        getTreeData(data) {
            this.treeData = data
        },


    },
};
</script>

<style lang='less' scoped>
.app-container {
    background: rgba(255, 255, 255, 0);
    padding-left: 0px;
}

.inner-container {
    min-width: 1300px;
    height: var(--tableHeight);
    overflow: hidden;
}


.el-select {
    width: 100%;
}

.vehicle-item {
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-right: 5px;
}

.clean {
    color: #409eff;
    margin-left: 10px;
    cursor: pointer;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}

.avatar-box {
    width: 100%;
    display: flex;
    align-items: flex-start;

    .avatar-item {
        width: 100px;
        height: 100px;
        border: 1px dashed #666;
        position: relative;
        border-radius: 10px;
        margin-right: 10px;

        > img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
        }

        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 30px;
            color: #666;
        }
    }

    .avatar-item:hover {
        border: 1px dashed #409eff;

        .icon {
            color: #409eff;
        }
    }
}

//重构新增
.side-search {
    width: 200px;
    background: #FFFFFF;
    border-right: #d3dce6 1px solid;
}

.small-side {
    width: 36px;
    height: 36px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.14);
    border-right: 1px solid #EEE;
}

.mian-content {
    flex: 1;
    background: #F5F7FA;
}

.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.head-section {
    padding: 16px;
    background: #FFFFFF;
    overflow: hidden;
}

.btn-end {
    display: flex;
    justify-content: flex-end;
}

.section-main {
    width: 100%;
    //height: calc(100vh - 24px);
    background: #FFFFFF;
    margin-top: 16px;
    padding: 24px 16px 16px 16px;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.choose-mian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
        display: inline-block;
        margin-left: 16px;
    }
}

.alert-box {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
}

.pagin {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.main-flex {
    display: flex;

}

.left-style {
    padding-left: 43px;
}

.edit-style {
    color: #0D6CE4;
    font-size: 14px;
    cursor: pointer;
}

.edit-margin {
    margin-right: 16px;
}
</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';
</style>

