//统计分析
import {createAPI} from '@/utils/request'
const urlStr = '/service-spss/statistics'

//换电站换电次数同比
export const getStaDaySwapOnYear =data=>createAPI(urlStr+`/staDaySwapTimesYearOnYear?stationNo=` + data.stationNo + "&startTime=" + data.startTime,'get')
//换电站换电次数环比
export const getStaDaySwapOnMonth =data=>createAPI(urlStr+`/staDaySwapTimesYearOnMonth?stationNo=` + data.stationNo + "&startTime=" + data.startTime,'get')
//场站用电量同比
export const getStaEnergyValOnYear = (data) => createAPI(urlStr + '/staEnergyValOnYear?stationNo=' + data.stationNo + "&startTime=" + data.startTime,'get')
//场站用电量环比
export const getStaEnergyValOnMonth = (data) => createAPI(urlStr + '/staEnergyValOnMonth?stationNo=' + data.stationNo + "&startTime=" + data.startTime,'get')
//场站用电占比
export const getStaEnergyValRatio = (data) => createAPI(urlStr +  '/staEnergyValRatio?stationNo=' + data.stationNo + "&startTime=" + data.startTime,'get')





//根据换电站编码查询换电站状态信息
// export const staElecDayStatisticsPage = (stationNo,startTime,endTime) => createAPI(urlStr2 + '/cloudPlatform/staElecDayStatisticsPage/' + stationNo + "/" + startTime+ "/" + endTime, 'get')

export const staElecDayStatisticsPage = (data) => createAPI(urlStr2 + '/cloudPlatform/staElecDayStatisticsPage' , 'post',data)

//前三年每个月用电量
export const findlastYears = (data) => createAPI(urlStr2 + '/cloudPlatform/lastYears' , 'post',data)

//根据换电站编码和时间查询相邻两个月的用电量
export const staElecDayStatisticsTwoInfo = (data) => createAPI(urlStr2 + '/cloudPlatform/staElecDayStatisticsTwoInfo' , 'post',data)

//前三年中同一个月每日用电量统计
export const theThreeTears = (data) => createAPI(urlStr2 + '/cloudPlatform/threeYearsInfo' , 'post',data)
