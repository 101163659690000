//首页统计
import { createAPI } from '@/utils/request'
const urlStr = '/service-spss/bigScreen'

export const orderStatics = () => createAPI(urlStr + "/orderShow" ,'get')
export const stationTotalStatics = () => createAPI(urlStr + "/stationTotal" ,'get')
export const staSwapRanking = () => createAPI(urlStr + "/staSwapRanking" ,'get')
export const swapTimes7Days = () => createAPI(urlStr + "/swapTimes7Days" ,'get')
export const staDistribution = () => createAPI(urlStr + "/staDistribution" ,'get')
export const energyValRatio = () => createAPI(urlStr + "/energyValRatio" ,'get')

export const swapEnergyValAndTimes = (type) => createAPI(urlStr + "/swapEnergyValAndTimes?type=" + type,'get')
