<template>
    <div class="app-container">
        <el-row class="main-flex">
            <el-col :span="3" class="side-search" v-if="isopenShow">
                <operatorTreeVue @emitToParent='getTreeData' @isShow="isHidden"></operatorTreeVue>
            </el-col>

            <el-col v-if="!isopenShow" class="small-side">
                <div class="change-in" @click="isopenShow = !  isopenShow "></div>
            </el-col>

            <!-- 搜索区域 -->
            <el-col :span="21" class="mian-content">
                <div class="head-section">
                    <el-col :span="6">
                        <el-form :inline="true">
                            <el-form-item label="电池等级：">
                                <el-select placeholder="请选择电池等级" v-model.trim="statusSelect">
                                    <el-option
                                        v-for="item in levelOptions"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="18">
                        <div class="btn-end">
                <span class="btn-rest">
                <el-button @click="restFunction" plain>重置</el-button>
                    </span>
                            <span class="btn-submit">
                                  <el-button type="primary" @click="queryFunction" plain>查询</el-button>
                            </span>
                        </div>
                    </el-col>
                </div>
                <!--        <el-col :span="5">-->
                <!--          <el-input placeholder="请从左侧选择运营商" v-model.trim="treeData.operatorNameTree" disabled></el-input>-->
                <!--        </el-col>-->
                <!--        <el-col :span="10">
                          <el-button type="primary" @click="queryFunction" plain icon="el-icon-search">查询</el-button>
                          <el-button @click="restFunction" plain icon="el-icon-refresh-right">重置</el-button>
                        </el-col>-->

                <!--  表格部分-->
                <div class="section-main">
                    <el-col class="choose-mian">
                        <div class="section-title">
                            电池型号列表
                        </div>
                        <div>
                            <!--选择项栏-->
                            <span class="alert-box">
                           已选择{{ selectList.length }}项
                            <span @click="toggleSelection" style="margin-left:8px;color:#0D6CE4;cursor:pointer;">清空</span>
                        </span>
                            <span class="pain-btn">
                                <el-button
                                           @click="delFunctionOfbtytype" plain
                                           v-hasCode="['base:batterytype:delete']">删除
                        </el-button>
                            </span>
                            <span class="add-btn">
                              <el-button @click="addDialogVisible = true" plain type="primary"
                                         v-hasCode="['base:batterytype:add']">新增
                        </el-button>
                                <!--
                        <el-button type="primary" icon="el-icon-upload2" @click="dialogImport = true" style="margin-left: 15px" size="small">批量导入</el-button>
                        <el-button type="primary" icon="el-icon-download" size="small" style="margin-left: 15px" @click="download">批量导出</el-button>
                        -->
                            </span>
                        </div>
                    </el-col>
                    <el-col :span="24">
                            <el-row class="table-box">
                                <el-table
                                    ref="multipleTable"
                                    :data="tableData"
                                    style="width: 100%"
                                    @selection-change="selectionChange"
                                    :header-cell-style="headerStyleEvent" :cell-class-name="goodsInfo"
                                    height="100%"
                                >
                                    <el-table-column align="center" type="selection"></el-table-column>
                                    <el-table-column align="center" type="index" :index="renIndex" label="编号"
                                                     width="50"></el-table-column>
                                    <el-table-column align="center" prop="operatorName" label="归属运营商"></el-table-column>
                                    <el-table-column align="center" prop="btyTypeCode" label="电池型号代码"
                                                     min-width="110"></el-table-column>
                                    <el-table-column align="center" prop="btyTypeName" label="电池型号名称"
                                                     min-width="110"></el-table-column>
                                    <el-table-column align="center" prop="btyTypeLevel" label="电池型号等级" min-width="110">
                                        <template slot-scope="scope">
                                        <span
                                            v-if="scope.row.btyTypeLevel === 1 || scope.row.btyTypeLevel === '1'">一等品</span>
                                            <span
                                                v-else-if="scope.row.btyTypeLevel === 2 || scope.row.btyTypeLevel === '2'">二等品</span>
                                            <span v-else>三等品</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column align="center" prop="btyTypeDesc" label="电池型号描述"
                                                     min-width="110"></el-table-column>
                                    <el-table-column align="center" prop="btyTypeState" label="电池型号状态" min-width="140">
                                        <template slot-scope="scope">
                                            <div :class=" scope.row.btyTypeState===1 ? 'status-item2':'status-item1' ">
                                                <span></span>
                                                <span>{{ scope.row.btyTypeState === 1 ? "启用" : "禁用" }}</span>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column align="center" prop="createTime" :formatter="$dateFormat" label="创建日期"
                                                     min-width="110"></el-table-column>
                                    <el-table-column align="center" label="操作" min-width="100" fixed="right">
                                        <template slot-scope="scope">
                                            <span class="edit-style"
                                                @click="handleEdit(scope.row)"
                                                v-hasCode="['base:batterytype:update']"
                                            >编辑
                                            </span>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-row>
                    </el-col>

                    <el-col :span="24">
                        <el-row class="pagin">
                            <!-- 分页 -->
                            <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="pageNum"
                                :page-sizes="[10, 30, 50,100]"
                                :page-size="pageSize"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="total"
                            ></el-pagination>
                        </el-row>
                    </el-col>
                </div>
            </el-col>


            <!-- 批量导入 -->
            <el-dialog title="请选择导入文件" :visible.sync="dialogImport" width="30%" @close="closeLoad">
                <el-upload
                    class="upload-demo"
                    ref="upload"
                    action
                    :on-change="handleChange"
                    :on-remove="handleRemove"
                    :before-remove="beforeRemove"
                    :before-upload="beforeUpload"
                    :file-list="fileList"
                    :limit="1"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    :auto-upload="false"
                >
                    <el-button size="small" type="primary">请选择 xlsx / xls 文件</el-button>
                </el-upload>
                <span slot="footer" class="dowload dialog-footer">
        <div>
          <el-button @click="dowtemplate">下载模版</el-button>
          <span>请不要擅自修改或删除模版</span>
        </div>
       <div>
          <el-button @click="dialogImport = false">取消</el-button>
        <el-button type="primary" :disabled="uploadDisabled" style="margin-top: 16px"
                   @click="handleUpload">开始上传</el-button>
       </div>
      </span>
            </el-dialog>
            <!-- 新增 -->
            <el-dialog :title="editTile?'新增电池信息':'修改电池信息'" :visible.sync="addDialogVisible" width="40%"
                       @close="close">
                <el-form
                    size="middle"
                    :model="btyTypeForm"
                    :rules="rules"
                    label-width="120px"
                    ref="btyTypeForm"
                >
                    <el-row>
                        <el-col>
                            <el-form-item label="归属运营商" prop="operatorId">
                                <TreeSelect v-model="btyTypeForm.operatorId" :options="treeData.operatorOptions"
                                            placeholder="请选择运营商"/>
                                <!-- <TreeSelect v-model="addForm.operatorId" :options="operatorOptions" placeholder="选择上级目录" @select="change(node)"/>-->
                            </el-form-item>
                            <el-form-item label="电池型号代码:" prop="btyTypeCode">
                                <el-input size="mini" v-model="btyTypeForm.btyTypeCode"></el-input>
                            </el-form-item>
                            <el-form-item label="电池型号名称:" prop="btyTypeName">
                                <el-input size="mini" v-model="btyTypeForm.btyTypeName"></el-input>
                            </el-form-item>
                            <el-form-item label="电池型号等级:" prop="btyTypeLevel">
                                <el-select v-model="btyTypeForm.btyTypeLevel" placeholder="请选择">
                                    <el-option
                                        v-for="item in levelOptions"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="电池型号描述:" prop="btyTypeDesc">
                                <el-input size="mini" v-model="btyTypeForm.btyTypeDesc"></el-input>
                            </el-form-item>
                            <el-form-item label="电池型号状态:" prop="btyTypeState">
                                <el-select v-model="btyTypeForm.btyTypeState" placeholder="请选择">
                                    <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="addClickFunction">确定</el-button>
      </span>
            </el-dialog>
        </el-row>
    </div>
</template>

<script>
import '@/assets/font/font.css';
import {
    addNewBtytype,
    upBtytype,
    delTyType,
    importExcelTyTypes,
    getTyTypeOfLevel,
    getBatteryTypeAll,
    getBtytypePage,
} from "@/utils/batteryModelManagementApi";
import moment from 'moment';

import operatorTreeVue from "@/components/tree/operatorTree"
import TreeSelect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {operatorTree} from "@/utils/operatorApi";

export default {
    name: 'battery',
    components: {TreeSelect, operatorTreeVue},
    data() {
        return {
            isopenShow: true,
            total: 0,
            pageSize: 10,
            pageNum: 1,
            resPageNum: 1,
            queryInput: "",
            statusSelect: null,
            uploading: false,
            tableData: [],
            isPage: true,
            addDialogVisible: false,
            btyTypeForm: {
                operatorId: null,
                btyTypeCode: "",
                btyTypeDesc: "",
                btyTypeLevel: null,
                btyTypeName: "",
                btyTypeState: "",
            },
            options: [
                {
                    value: 1,
                    label: "启用",
                },
                {
                    value: 0,
                    label: "禁用",
                },
            ],
            levelOptions: [
                {
                    value: 1,
                    label: "一等品",
                },
                {
                    value: 2,
                    label: "二等品",
                },
                {
                    value: 3,
                    label: "三等品",
                },
            ],
            rules: {
                operatorId: [
                    {required: true, message: "请选择归属运营商", trigger: "blur"},
                ],
                btyTypeCode: [
                    {required: true, message: "请输入电池型号代码", trigger: "blur"},
                ],
                btyTypeName: [
                    {required: true, message: "请输入电池型号名称", trigger: "change"},
                ],
                btyTypeDesc: [
                    {required: true, message: "请输入电池型号描述", trigger: "change"},
                ],
                btyTypeLevel: [
                    {required: true, message: "请选择电池型号等级", trigger: "change"},
                ],
                btyTypeState: [
                    {required: true, message: "请选择电池型号状态", trigger: "blur"},
                ]
            },
            editTile: true,
            selectList: [],
            batteryTypeAll: [],
            columns: [
                {label: "电池型号代码", prop: "btyTypeCode"},
                {label: "电池型号名称", prop: "btyTypeName"},
                {label: "电池型号描述", prop: "btyTypeDesc"},
                {label: "电池型号等级", prop: "btyTypeLevel"},
                {label: "电池型号状态", prop: "btyTypeState"}
            ],
            dialogImport: false,
            fileList: [],//上传的文件列表
            uploadDisabled: true,

            treeData: {
                operatorNameTree: "",
                operatorIdTree: null,
                operatorOptions: undefined,  //运营商树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
            },
        };
    },
    computed: {
        downloadList() {
            if (this.selectList.length === 0) {
                return this.batteryTypeAll.map(item => {
                    item.createTime = item.createTime !== null ? moment(item.createTime).format('YYYY-MM-DD HH:mm:ss') : null
                    item.btyTypeState = item.btyTypeState === 0 ? '禁用' : '启用'
                    switch (item.btyTypeLevel) {
                        case 1:
                            item.btyTypeLevel = '一等品'
                            break;
                        case 2:
                            item.btyTypeLevel = '二等品'
                            break
                        case 3:
                            item.btyTypeLevel = '三等品'
                            break
                        default:
                            break;
                    }
                    return item
                })
            } else {
                return this.selectList.map(item => {
                    item.btyTypeState = item.btyTypeState === 0 ? '禁用' : '启用'
                    switch (item.btyTypeLevel) {
                        case 1:
                            item.btyTypeLevel = '一等品'
                            break;
                        case 2:
                            item.btyTypeLevel = '二等品'
                            break
                        case 3:
                            item.btyTypeLevel = '三等品'
                            break
                        default:
                            break;
                    }
                    return item
                })
            }
        }
    },
    created() {
        this.getOperatorTree();
        this.getList();
    },
    mounted() {
    },
    // 组件方法
    methods: {
        headerStyleEvent({row, column, rowIndex, columnIndex}) {
            if (columnIndex == 1) {
                return 'padding:0px;'
            }
        },
        goodsInfo(row) {
            if (row.columnIndex == 1) {
                return 'goodsInfo'
            }
        },
        isHidden(message) {
            this.isopenShow = message
        },
        async getList() {
            let data = {page: this.pageNum, size: this.pageSize};
            var url = "";
            if (this.treeData.operatorNameTree != "" && this.treeData.operatorNameTree != null) {
                url += "&operatorId=" + this.treeData.operatorIdTree
            } else if (this.treeData.operatorOptions.length > 0) {
                url += "&operatorId=" + this.treeData.operatorOptions[0].id
            }
            data.url = url;
            const res = await getBtytypePage(data);
            if (res.data.code != 200) return this.$msg.error("获取失败");
            this.total = res.data.data.totalElements;
            this.tableData = res.data.data.content;
        },
        //修改信息填充
        handleEdit(row) {
            this.addDialogVisible = true;
            this.editTile = false
            let rowData = this.copyDeep(row);
            this.btyTypeForm = rowData;
        },
        //获取所有电池型号信息
        async getBtyTypeAll() {
            var data = {};
            data.url = "";
            const res = await getBatteryTypeAll(data);
            this.batteryTypeAll = res.data.data;
        },

        async delFunctionOfbtytype() {
            let rows = this.selectList;
            if (rows.length === 0) {
                this.$msg({
                    type: "info",
                    message: "请选中删除文件",
                });
            } else {
                const config = await this.$MessageBox
                    .confirm("此操作将永久删除选中项, 是否继续?", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                    .catch((e) => e);
                if (config !== "confirm") return;
                const arrayId = rows.map((item) => {
                    return item.id;
                }).join(",");
                const res = await delTyType(arrayId);
                if (res.status != 200) {
                    return this.$msg.error("删除失败");
                }
                this.tableData = this.tableData.filter((item, i) => {
                    return item.id !== arrayId[i];
                });
                this.$refs.multipleTable.clearSelection();
                if (this.pageNum > 1 && this.tableData.length === 0) {
                    this.pageNum = this.pageNum - 1;
                }
                this.$msg.success("删除成功");
                this.getList();
            }
        },
        renIndex(index) {
            return (this.pageNum - 1) * this.pageSize + index + 1
        },
        async addClickFunction() {
            this.$refs.btyTypeForm.validate(async (valid) => {
                if (!valid) return this.$msg.error("内容不能为空");
                try {
                    let postData = this.copyDeep(this.btyTypeForm);
                    if (this.editTile) {
                        const res = await addNewBtytype(postData);
                        if (res.status !== 200 && res.data.message === '该型号已存在') return this.$msg.error("该型号已存在，请重新添加!")
                        if (res.status !== 200 && res.data.message !== '该型号已存在') return this.$msg.error("添加电池信息失败!");
                        this.$msg.success("添加电池信息成功");
                        this.addDialogVisible = false;
                        this.getList();
                    } else {
                        //编辑电池信息
                        const res = await upBtytype(postData);
                        if (res.status != 200) return this.$msg.error("修改电池信息失败");
                        this.$msg.success("修改电池信息成功");
                        this.addDialogVisible = false;
                        this.getList();
                    }
                } catch (e) {
                }
            });
        },
        close() {
            this.$refs.btyTypeForm.resetFields();
            this.btyTypeForm = {};
            this.editTile = true
            this.addDialogVisible = false
        },
        // 分页事件
        async handleSizeChange(val) {
            this.pageSize = val;
            if (this.isPage) {
                this.getList();
            } else {
                this.queryFunction()
            }

        },
        async handleCurrentChange(val) {
            this.pageNum = val;
            if (this.isPage) {
                this.getList();
            } else {
                this.resPageNum = val
                this.queryFunction()
            }
        },
        copyDeep(templateData) {
            // templateData 是要复制的数组或对象，这样的数组或者对象就是指向新的地址的
            return JSON.parse(JSON.stringify(templateData));
        },
        toggleSelection() {
            this.$refs.multipleTable.clearSelection();
        },
        selectionChange(rows) {
            this.selectList = rows;
        },
        async queryFunction() {
            if (this.statusSelect != null) {
                let data = {
                    btyTypeLevel: this.statusSelect,
                    page: this.resPageNum,
                    size: this.pageSize,
                };
                var url = "page=" + data.page + "&size=" + data.size;
                if (this.statusSelect != null) {
                    url += "&btyTypeLevel=" + this.statusSelect;
                }
                if (this.treeData.operatorNameTree != "" && this.treeData.operatorNameTree != null) {
                    url += "&operatorId=" + this.treeData.operatorIdTree
                } else if (this.treeData.operatorOptions.length > 0) {
                    url += "&operatorId=" + this.treeData.operatorOptions[0].id
                }
                data.url = url;
                let res = await getTyTypeOfLevel(data);
                if (res.status != 200) return this.$msg.error("查询失败");
                this.totalElements = res.data.data.totalElements;
                let rdata = res.data.data.content;
                this.tableData = rdata;
                this.isPage = false
            } else {
                this.getList();
            }

        },
        restFunction() {
            this.treeData.operatorNameTree = ""
            this.treeData.operatorIdTree = null
            this.statusSelect = null;
            this.queryInput = "";
            this.resPageNum = 1
            this.pageNum = 1
            this.pageSize = 10
            this.isPage = true
            this.getList();
        },
        async download() {
            const confirm = await this.$MessageBox
                .confirm("是否导出文件", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                .catch((e) => e);
            if (confirm !== "confirm") return;
            const list = [
                {label: "电池型号代码", prop: "btyTypeCode"},
                {label: "电池型号名称", prop: "btyTypeName"},
                {label: "电池型号描述", prop: "btyTypeDesc"},
                {label: "电池型号等级", prop: "btyTypeLevel"},
                {label: "电池型号状态", prop: "btyTypeState"},
                {label: "创建日期", prop: "createTime"},
                {label: "修改日期", prop: "modifyTime"},
                {label: "停用日期", prop: "disableTime"}
            ]
            this.$download(this.downloadList, list, "电池型号管理");
        },
        //下载模版
        dowtemplate() {
            const list = [
                {
                    btyTypeCode: '00000',
                    btyTypeName: '00000',
                    btyTypeLevel: '一等品1二等品2三等品3',
                    btyTypeDesc: '00000',
                    btyTypeState: '启用1禁用0'
                }
            ]
            this.$download(list, this.columns, "电池型号管理")
        },
        //清空上传的文件
        closeLoad() {
            this.uploadDisabled = true
            this.$refs.upload.clearFiles();
        },
        //上传文件改变
        handleChange(file, fileList) {
            if (fileList.length > 0) {
                this.uploadDisabled = false
                this.fileList = fileList
            }
        },
        //上传文件之前
        beforeUpload(file) {
            let fileTemp = file.raw
            if (fileTemp) {
                if (fileTemp.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                    fileTemp.type === "application/vnd.ms-excel") {
                } else {
                    this.$msg.warning('附件格式错误，请删除后重新上传!')
                }
            }
        },
        //上传文件移除
        handleRemove(file, fileList) {
            this.fileList = fileList
            if (fileList.length < 1) {
                this.uploadDisabled = true
            }
        },
        //开始上传
        async handleUpload() {
            if (!this.fileList.length === 0) return this.$msg.info('请选择要上传的文件')
            let formData = new FormData()
            formData.append('file', this.fileList[0].raw)
            const res = await importExcelTyTypes(formData)
            if (res.status !== 200) return this.$msg.error('文件上传失败')
            this.$msg.success('文件上传成功')
            this.dialogImport = false
            this.getList()
        },
        //删除文件之前的钩子
        async beforeRemove(file) {
            return this.$MessageBox.confirm(`确定移除 ${file.name}？`)
        },

        getTreeData(data) {
            this.treeData = data
        },

        /** 查询运营商下拉树结构 */
        async getOperatorTree() {
            try {
                this.treeData.operatorOptions = [];
                let res = await operatorTree();
                if (res.status !== 200) return this.$msg.error("获取运营商信息失败");
                this.treeData.operatorOptions.push(res.data.data)
                this.treeData.operatorIdTree = res.data.data.id
                this.treeData.operatorNameTree = res.data.data.label
            } catch (error) {
            }
        },


    },
};
</script>

<style lang="less" scoped>
.el-table {
    scrollbar-width: thin; /* 火狐滚动条无法自定义宽度，只能通过此属性使滚动条宽度变细 */

    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
        width: 10px; /*滚动条宽度*/
        height: 10px; /*滚动条高度*/
    }

    /*定义滚动条轨道 内阴影+圆角*/

    /deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
        //   box-shadow: 0px 1px 3px #fff inset; /*滚动条的背景区域的内阴影*/
        border-radius: 10px; /*滚动条的背景区域的圆角*/
        background-color: #fff; /*滚动条的背景颜色*/
    }

    /*定义滑块 内阴影+圆角*/

    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
        //   box-shadow: 0px 1px 3px #dddee0 inset; /*滚动条的内阴影*/
        border-radius: 10px; /*滚动条的圆角*/
        background-color: #dddee0; /*滚动条的背景颜色*/
    }
}

.app-container {
    background: rgba(255, 255, 255, 0);
    padding-left: 0px;
}

.inner-container {
    min-width: 1300px;
    height: var(--tableHeight);;
    overflow: hidden;
}

.el-select {
    width: 100%;
}

.bg-purple-dark {
    background: #ffffff;
}

.bg-purple {
    background: #d3dce6;
}

.bg-purple-light {
    background: #e5e9f2;
}

.grid-content {
    border-radius: 4px;
    min-height: 36px;
}

.row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
}

.alert-text {
    color: #62b2ff;
    margin-left: 10px;
    cursor: pointer;
}

.status-item1 {
    > span {
        padding: 0 3px;
    }

    :first-child {
        width: 5px;
        height: 5px;
        display: inline-block;
        border-radius: 50%;
        background-color: #FF6165;
        transform: translateY(-2px);
    }
}

.status-item2 {
    > span {
        padding: 0 3px;
    }

    :first-child {
        width: 5px;
        height: 5px;
        display: inline-block;
        border-radius: 50%;
        background-color: #0D6CE4;
        transform: translateY(-2px);
    }
}

.dowload {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
        display: flex;
        align-items: center;

        &:first-child {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            > span {
                font-size: 12px;
                color: #e10505;
            }
        }

        > button {
            margin-top: 0 !important;
        }
    }
}

//重构新增
.side-search {
    width: 200px;
    background: #FFFFFF;
    border-right: #d3dce6 1px solid;
}

.small-side {
    width: 36px;
    height: 36px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.14);
    border-right: 1px solid #EEE;
}

.mian-content {
    flex: 1;
    background: #F5F7FA;
}

.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.head-section {
    padding: 16px;
    background: #FFFFFF;
    overflow: hidden;
}

.btn-end {
    display: flex;
    justify-content: flex-end;
}

.section-main {
    width: 100%;
    //height: calc(100vh - 24px);
    background: #FFFFFF;
    margin-top: 16px;
    padding: 24px 16px 16px 16px;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.choose-mian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
        display: inline-block;
        margin-left: 16px;
    }
}

.alert-box {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
}

.pagin {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.main-flex {
    display: flex;

}

.left-style {
    padding-left: 43px;
}

.edit-style {
    color: #0D6CE4;
    font-size: 14px;
    cursor:pointer;
}
</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';
</style>
