<template>
    <div class="app-container">
        <el-row class="main-flex">

            <el-col :span="3" class="side-search" v-if="isopenShow">
                <operatorTreeVue @emitToParent='getTreeData' @isShow="isHidden"></operatorTreeVue>
            </el-col>

            <el-col v-if="!isopenShow" class="small-side">
                <div class="change-in" @click="isopenShow = !  isopenShow "></div>
            </el-col>

            <!-- 搜索区域 -->
            <el-col :span="21" class="mian-content">
                <div class="head-section">
                    <!--        <el-col :span="5">-->
                    <!--          <el-input placeholder="请从左侧选择运营商" v-model.trim="treeData.operatorNameTree" disabled></el-input>-->
                    <!--        </el-col>-->

                    <!--        <el-col :span="5">
                              <el-button @click="getSearch" type="primary" plain icon="el-icon-search">查询</el-button>
                              <el-button @click="restFunctionn" plain icon="el-icon-refresh-right">重置</el-button>
                            </el-col>-->
                    <el-col :span="8">
                        <el-form :inline="true">
                            <el-form-item label="车辆型号名称：">
                                <el-input placeholder="请输入车辆型号名称" v-model.trim="queryCarName"
                                          clearable></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="8">
                        <el-form :inline="true">
                            <el-form-item label="车辆型号状态：">
                                <el-select placeholder="请选择车辆型号状态" v-model="statusSelect">
                                    <el-option
                                        v-for="item in selectOptions"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="8">
                        <div class="btn-end">
                            <span class="btn-rest">
                            <el-button @click="restFunctionn" plain>重置</el-button>
                            </span>
                            <span class="btn-submit">
                      <el-button @click="getSearch" type="primary" plain>查询</el-button>
                            </span>
                        </div>
                    </el-col>
                </div>
                <!--  表格部分-->
                <div class="section-main">
                    <el-col class="choose-mian">
                        <div class="section-title">
                            车辆型号列表
                        </div>
                        <div>
                            <!--选择项栏-->
                            <span class="alert-box">
                         已选择{{ selectList.length }}项
                            <span @click="toggleSelection" style="margin-left:8px;color:#0D6CE4;cursor:pointer;">清空</span>
                        </span>
                            <span class="pain-btn">
                                <el-button
                                           @click="delFunctionOfbtytype" plain
                                           v-hasCode="['base:cartype:delete']">删除
                        </el-button>
                            </span>
                            <span class="add-btn">
                               <el-button  type="primary"
                                          @click="addDialog = true" plain
                                          v-hasCode="['base:cartype:add']">新增
                        </el-button>
                            </span>
                        </div>
                    </el-col><el-col :span="24">
                    <el-row class="table-box">
                        <el-table
                            id="excel-tabel"
                            ref="multipleTable"
                            :data="tableData"
                            :header-cell-style="headerStyleEvent" :cell-class-name="goodsInfo"
                            @selection-change="selectionChange" height="100%"
                        >
                            <el-table-column align="center" type="selection"></el-table-column>
                            <el-table-column align="center" label="编号" :index="renIndex" type="index"
                                             width="50"></el-table-column>
                            <el-table-column align="center" label="归属运营商" prop="operatorName"></el-table-column>
                            <el-table-column align="center" prop="carTypeCode" label="车辆型号编码"
                                             min-width="110"></el-table-column>
                            <el-table-column align="center" prop="carTypeName" label="车辆型号名称"
                                             min-width="110"></el-table-column>
                            <el-table-column align="center"
                                             prop="carTypeDesc"
                                             :show-overflow-tooltip="true"
                                             label="车辆型号描述"
                                             min-width="110"
                            ></el-table-column>
                            <el-table-column align="center" prop="carTypeState" label="车辆型号状态" min-width="110">
                                <template slot-scope="scope">
                                    <div :class="scope.row.carTypeState===1 ? 'status-item2' : 'status-item1'">
                                        <span></span>
                                        <span>{{ scope.row.carTypeState === 1 ? "启用" : "禁用" }}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" prop="createTime" :formatter="$dateFormat" label="创建日期"
                                             min-width="110"></el-table-column>
                            <el-table-column align="center" label="操作" fixed="right">
                                <template slot-scope="scope">
                                        <span class="edit-style"
                                              @click="handleEdit(scope.row)"
                                              type="primary"
                                              v-hasCode="['base:cartype:update']"
                                        >编辑
                                        </span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-row>
                </el-col>

                    <el-col :span="24">
                        <!-- 分页 -->
                        <el-row class="pagin">
                            <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="pageNum"
                                :page-sizes="[10, 30, 50,100]"
                                :page-size="pageSize"
                                layout="total, sizes, prev, pager, next"
                                :total="totalPages"
                            ></el-pagination>
                        </el-row>
                    </el-col>

                </div>
                        <!--
                        <el-button
                          type="primary"
                          icon="el-icon-upload2"
                          size="small"
                          @click="dialogImport = true"
                          style="margin-left: 15px"
                        >批量导入</el-button>
                        <el-button
                          type="primary"
                          icon="el-icon-download"
                          size="small"
                          style="margin-left: 15px"
                          @click="download"
                        >批量导出</el-button>
                        -->



            </el-col>

            <!-- 新增 -->
            <el-dialog :title="titleName?'新增车辆型号信息':'修改车辆型号信息'" width="50%" :visible.sync="addDialog"
                       @close="closeNew">
                <el-form size="middle" ref="addFrom" :model="addFrom" label-width="110px" :rules="rules">
                    <el-row>
                        <el-col :span="22">
                            <el-form-item label="归属运营商" prop="operatorId">
                                <TreeSelect v-model="addFrom.operatorId" :options="treeData.operatorOptions"
                                            placeholder="请选择运营商"/>
                                <!-- <TreeSelect v-model="addForm.operatorId" :options="operatorOptions" placeholder="选择上级目录" @select="change(node)"/>-->
                            </el-form-item>
                            <el-form-item label="车辆型号编码:" prop="carTypeCode">
                                <el-input v-model="addFrom.carTypeCode"></el-input>
                            </el-form-item>
                            <el-form-item label="车辆型号名称:" prop="carTypeName">
                                <el-input v-model="addFrom.carTypeName"></el-input>
                            </el-form-item>
                            <el-form-item label="车辆型号描述:" prop="carTypeDesc">
                                <el-input v-model="addFrom.carTypeDesc"></el-input>
                            </el-form-item>
                            <el-form-item label="车辆型号状态:" prop="carTypeState">
                                <el-select v-model="addFrom.carTypeState">
                                    <el-option
                                        v-for="item in selectOptions"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <span slot="footer" class="dialog-footer">
          <el-button @click="addDialog = false">取消</el-button>
        <el-button type="primary" @click="addNewFunction">确定</el-button>
      </span>
            </el-dialog>
            <!-- 批量导入 -->
            <el-dialog title="请选择导入文件" :visible.sync="dialogImport" width="30%" @close="closeLoad">
                <el-upload
                    class="upload-demo"
                    ref="upload"
                    action
                    :on-change="handleChange"
                    :on-remove="handleRemove"
                    :before-remove="beforeRemove"
                    :before-upload="beforeUpload"
                    :file-list="fileList"
                    :limit="1"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    :auto-upload="false"
                >
                    <el-button size="small" type="primary">请选择 xlsx / xls 文件</el-button>
                </el-upload>
                <span slot="footer" class="dowload dialog-footer">
        <div>
          <el-button @click="dowtemplate">下载模版</el-button>
          <span>请不要擅自修改或删除模版</span>
        </div>
        <div>
          <el-button @click="dialogImport = false">取消</el-button>
        <el-button type="primary" :disabled="uploadDisabled" style="margin-top: 16px"
                   @click="handleUpload">开始上传</el-button>
        </div>
      </span>
            </el-dialog>
        </el-row>
    </div>
</template>

<script>
import '@/assets/font/font.css';
import {
    addNewCarType, //增
    delCarType, //删
    importExcelCarTypes, //批量导入
    upCarType, //修改
    getCarAll, getCartypePage
} from "@/utils/vehicleModelManagementApi";
import moment from 'moment'

import operatorTreeVue from "@/components/tree/operatorTree"
import TreeSelect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {operatorTree} from "@/utils/operatorApi";

export default {
    name: "vehicleType",
    components: {TreeSelect, operatorTreeVue},
    data() {
        return {
            isopenShow: true,
            totalPages: 0,
            pageSize: 10,
            pageNum: 1,
            statusSelect: "",
            queryCarName: "",
            titleName: true,
            addDialog: false,
            tableData: [],
            addFrom: {
                operatorId: null,
                carTypeCode: "",
                carTypeDesc: "",
                carTypeName: "",
                carTypeState: ""
            },
            selectOptions: [
                {
                    value: 1,
                    label: "启用",
                },
                {
                    value: 0,
                    label: "禁用",
                },
            ],
            rules: {
                operatorId: [
                    {required: true, message: "请选择归属运营商", trigger: "change"},
                ],
                carTypeCode: [
                    {required: true, message: "请输入车辆型号编码", trigger: "change"},
                ],
                carTypeName: [
                    {required: true, message: "请输入车辆型号名称", trigger: "change"},
                ],
                carTypeDesc: [
                    {required: true, message: "请输入车辆型号描述", trigger: "change"},
                ],
                carTypeState: [
                    {required: true, message: "请选择车辆型号状态", trigger: "change"},
                ]
            },
            selectList: [],
            columns: [
                {label: "车辆型号编码", prop: "carTypeCode"},
                {label: "车辆型号名称", prop: "carTypeName"},
                {label: "车辆型号描述", prop: "carTypeDesc"},
                {label: "车辆型号状态", prop: "carTypeState"}
            ],
            CarList: [],
            dialogImport: false,
            uploadDisabled: true,
            fileList: [],

            treeData: {
                operatorNameTree: "",
                operatorIdTree: null,
                operatorOptions: undefined,  //运营商树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
            },

        };
    },
    computed: {
        userName() {
            return JSON.parse(this.$store.state.user).username;
        },
        downloadList() {
            if (this.selectList.length === 0) {
                return this.CarList.map(item => {
                    item.carTypeState = item.carTypeState === 0 ? '禁用' : '启用'
                    item.createTime = item.createTime !== null ? moment(item.createTime).format('YYYY-MM-DD HH:mm:ss') : null
                    // item.modifyTime = item.modifyTime !== null?moment(item.modifyTime).format('YYYY-MM-DD HH:mm:ss'):null
                    // item.disableTime = item.disableTime !== null?moment(item.disableTime).format('YYYY-MM-DD HH:mm:ss'):null
                    return item
                })
            } else {
                return this.selectList.map(item => {
                    item.carTypeState = item.carTypeState === 0 ? '禁用' : '启用'
                    item.createTime = item.createTime !== null ? moment(item.createTime).format('YYYY-MM-DD HH:mm:ss') : null
                    item.modifyTime = item.modifyTime !== null ? moment(item.modifyTime).format('YYYY-MM-DD HH:mm:ss') : null
                    item.disableTime = item.disableTime !== null ? moment(item.disableTime).format('YYYY-MM-DD HH:mm:ss') : null
                    return item
                })
            }
        }
    },
    created() {
        this.getOperatorTree();
        this.getList();
    },
    mounted() {
    },
    // 组件方法
    methods: {
        headerStyleEvent({row, column, rowIndex, columnIndex}) {
            if (columnIndex == 1) {
                return 'padding:0px;'
            }
        },
        goodsInfo(row) {
            if (row.columnIndex == 1) {
                return 'goodsInfo'
            }
        },
        isHidden(message) {
            this.isopenShow = message
        },
        closeNew() {
            this.$refs.addFrom.resetFields();
            this.addFrom = {};
            this.titleName = true
            this.addDialog = false
        },

        async getList() {
            let data = {
                name: this.queryCarName,
                status: this.statusSelect,
                page: this.pageNum,
                size: this.pageSize,
            };

            var url = "?page=" + this.pageNum + "&size=" + this.pageSize;
            if (this.statusSelect !== "") {
                url += "&status=" + this.statusSelect;
            }
            if (this.queryCarName !== "") {
                url += "&name=" + this.queryCarName;
            }
            if (this.treeData.operatorNameTree != "" && this.treeData.operatorNameTree != null) {
                url += "&operatorId=" + this.treeData.operatorIdTree
            } else if (this.treeData.operatorOptions.length > 0) {
                url += "&operatorId=" + this.treeData.operatorOptions[0].id
            }
            data.url = url;

            let res = await getCartypePage(data);
            if (res.data.code != 200) return this.$msg.error("查询失败");
            let columndata = res.data.data.content;
            this.totalPages = res.data.data.totalElements;
            this.tableData = columndata;
        },

        getSearch() {
            this.pageNum = 1;
            this.getList();
        },

        async delFunctionOfbtytype() {
            let rows = this.selectList;
            if (rows.length === 0) {
                this.$msg({
                    type: "info",
                    message: "请选中删除文件",
                });
            } else {
                const config = await this.$MessageBox.confirm(
                    "此操作将永久删除选中项, 是否继续?",
                    "提示",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }
                ).catch((e) => e);
                if (config !== "confirm") return;
                const arrayId = rows.map((item) => {
                    return item.id;
                });
                const res = await delCarType(arrayId);
                if (res.status != 200) return this.$msg.error("删除失败");
                this.tableData = this.tableData.filter((item, i) => {
                    return item.id !== arrayId[i];
                });
                this.$refs.multipleTable.clearSelection();
                if (this.pageNum > 1 && this.tableData.length === 0) {
                    this.pageNum = this.pageNum - 1;
                }
                this.$msg.success("删除成功");
                this.getList();
            }
        },
        handleEdit(row) {
            this.addDialog = true;
            this.titleName = false
            let postData = this.copyDeep(row);
            this.addFrom = postData;

        },
        renIndex(index) {
            return (this.pageNum - 1) * this.pageSize + index + 1
        },
        addNewFunction() {
            this.$refs.addFrom.validate(async (valid) => {
                if (!valid) return this.$msg.error("内容不能为空");
                try {
                    let postData = this.copyDeep(this.addFrom);
                    if (this.titleName) {
                        const res = await addNewCarType(postData);
                        if (res.data.code !== 200) {
                            this.$msg.warning("添加车辆信息失败!" + res.data.msg)
                        } else {
                            this.$msg.success("添加车辆信息成功");
                            this.addDialog = false;
                            this.getList();
                        }
                    } else {
                        const res = await upCarType(postData);
                        if (res.data.code != 200) {
                            this.$msg.error("修改车辆信息失败");
                        } else {
                            this.$msg.success("修改车辆信息成功");
                            this.addDialog = false;
                            this.getList();
                        }
                    }
                } catch (e) {
                }
            });
        },
        copyDeep(templateData) {
            // templateData 是要复制的数组或对象，这样的数组或者对象就是指向新的地址的
            return JSON.parse(JSON.stringify(templateData));
        },

        restFunctionn() {
            this.statusSelect = "";
            this.queryCarName = "";
            this.resetForm();
            this.pageNum = 1;
            this.pageSize = 10;
            this.treeData.operatorNameTree = "";
            this.treeData.operatorIdTree = null
            this.getList();
        },

        resetForm() {
            this.addFrom = {
                operatorId: null,
                carTypeCode: "",
                carTypeDesc: "",
                carTypeName: "",
                carTypeState: ""
            }
        },

        // 分页事件
        async handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        async handleCurrentChange(val) {
            this.pageNum = val;
            this.getList();
        },
        selectionChange(rows) {
            this.selectList = rows;
        },
        toggleSelection() {
            this.$refs.multipleTable.clearSelection();
        },
        async download() {
            const confirm = await this.$MessageBox.confirm('是否导出文件', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(e => e)
            if (confirm !== 'confirm') return
            const list = [
                {label: "车辆型号编码", prop: "carTypeCode"},
                {label: "车辆型号名称", prop: "carTypeName"},
                {label: "车辆型号描述", prop: "carTypeDesc"},
                {label: "车辆型号状态", prop: "carTypeState"},
                {label: "创建日期", prop: "createTime"}
            ]
            this.$download(this.downloadList, list, '车辆型号管理')
        },
        //下载模版
        dowtemplate() {
            const list = [
                {
                    carTypeCode: '00000',
                    carTypeName: '00000',
                    carTypeDesc: '00000',
                    carTypeState: '启用1禁用0'
                }
            ]
            this.$download(list, this.columns, "车辆型号管理")
        },
        //清空上传的文件
        closeLoad() {
            this.uploadDisabled = true
            this.$refs.upload.clearFiles();
        },
        //上传文件改变
        handleChange(file, fileList) {
            if (fileList.length > 0) {
                this.uploadDisabled = false
                this.fileList = fileList
            }
        },
        //上传文件之前
        beforeUpload(file) {
            let fileTemp = file.raw
            if (fileTemp) {
                if (fileTemp.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                    fileTemp.type === "application/vnd.ms-excel") {
                } else {
                    this.$msg.warning('附件格式错误，请删除后重新上传!')
                }
            }
        },
        //上传文件移除
        handleRemove(file, fileList) {
            this.fileList = fileList
            if (fileList.length < 1) {
                this.uploadDisabled = true
            }
        },
        //开始上传
        async handleUpload() {
            if (!this.fileList.length === 0) return this.$msg.info('请选择要上传的文件')
            let formData = new FormData()
            formData.append('file', this.fileList[0].raw)
            const res = await importExcelCarTypes(formData)
            if (res.status !== 200) return this.$msg.error('文件上传失败')
            this.$msg.success('文件上传成功')
            this.dialogImport = false
            this.getList()
        },
        //删除文件之前的钩子
        async beforeRemove(file) {
            return this.$MessageBox.confirm(`确定移除 ${file.name}？`)
        },

        getTreeData(data) {
            this.treeData = data
        },

        /** 查询运营商下拉树结构 */
        async getOperatorTree() {
            try {
                this.treeData.operatorOptions = [];
                let res = await operatorTree();
                if (res.status !== 200) return this.$msg.error("获取运营商信息失败");
                this.treeData.operatorOptions.push(res.data.data)
                this.treeData.operatorIdTree = res.data.data.id
                this.treeData.operatorNameTree = res.data.data.label
            } catch (error) {
            }
        },

    },
};
</script>

<style lang="less" scoped>
.app-container {
    background: rgba(255, 255, 255, 0);
    padding-left: 0px;
}


.inner-container {
    min-width: 1300px;
    height: var(--tableHeight);;
    overflow: hidden;
}

.content {
    display: flex;
    align-items: center;
}

.bg-purple-dark {
    background: #287ef7;
}

.bg-purple {
    background: #d3dce6;
}

.bg-purple-light {
    background: #e5e9f2;
}

.grid-content {
    border-radius: 4px;
    min-height: 36px;
}

.alert-text {
    color: #62b2ff;
    margin-left: 10px;
    cursor: pointer;
}

.status-item1 {
    > span {
        padding: 0 3px;
    }

    :first-child {
        width: 5px;
        height: 5px;
        display: inline-block;
        border-radius: 50%;
        background-color: #FF6165;
        transform: translateY(-2px);
    }
}

.status-item2 {
    > span {
        padding: 0 3px;
    }

    :first-child {
        width: 5px;
        height: 5px;
        display: inline-block;
        border-radius: 50%;
        background-color: #0D6CE4;
        transform: translateY(-2px);
    }
}

.dowload {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
        display: flex;
        align-items: center;

        &:first-child {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            > span {
                font-size: 12px;
                color: #e10505;
            }
        }

        > button {
            margin-top: 0 !important;
        }
    }
}

//重构新增
.side-search {
    width: 200px;
    background: #FFFFFF;
    border-right: #d3dce6 1px solid;
}

.small-side {
    width: 36px;
    height: 36px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.14);
    border-right: 1px solid #EEE;
}

.mian-content {
    flex: 1;
    background: #F5F7FA;
}

.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.head-section {
    padding: 16px;
    background: #FFFFFF;
    overflow: hidden;
}

.btn-end {
    display: flex;
    justify-content: flex-end;
}

.section-main {
    width: 100%;
    //height: calc(100vh - 24px);
    background: #FFFFFF;
    margin-top: 16px;
    padding: 24px 16px 16px 16px;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.choose-mian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
        display: inline-block;
        margin-left: 16px;
    }
}

.alert-box {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
}

.pagin {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.main-flex {
    display: flex;

}

.left-style {
    padding-left: 43px;
}

.edit-style {
    color: #0D6CE4;
    font-size: 14px;
    cursor:pointer;
}
</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';
</style>

