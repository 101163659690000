<template>
    <div class="login-contianor">

        <div class="login-from" :style="{display:isPass?'none':'flex'}">
            <el-row class="login-title">
                <img src="../assets/image/logo.png" style="width: 228px;" />
                <span style="font-size: 18px">秦 欧 换 电 云 平 台 系 统</span>
            </el-row>
            <el-form :model="userform" :rules="rules" ref="ruleForm" class="demo-ruleForm">
                <el-form-item prop="username">
                    <el-input type="text" placeholder="请输入账户名" v-model="userform.username">
                        <i slot="prefix" class="el-input__icon el-icon-user"></i>
                    </el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input type="password" placeholder="请输入密码" v-model="userform.password"
                              @keyup.enter.native="userinfologin">
                        <i slot="prefix" class="el-input__icon el-icon-unlock"></i>
                    </el-input>
                    <span @click="isPass = true" class="passDate">忘记密码?</span>
                </el-form-item>

                <div v-if="vercode">
                    <el-form-item prop="inputValue">
                        <div class="code">
                            <el-input placeholder="请输入验证码" maxlength="4" v-model="userform.inputValue">
                                <i slot="prefix" class="iconfont icon-gongjifangyu"></i>
                            </el-input>
                            <div @click="cliakCanvas" ref="contentCanvas" class="code-canvas">
                                <canvas id="canvas"></canvas>
                            </div>
                        </div>
                    </el-form-item>
                </div>

            </el-form>
            <div class="btn">
                <!--           <el-button type="primary" @click="userinfologin">登录</el-button>-->
                <div>
                    <el-button type="primary" @click="userinfologin">登录</el-button>
                </div>
                <span></span>
            </div>
        </div>
        <!-- 忘记密码 -->
        <div class="contianor-box" :style="{display:!isPass?'none':'flex'}">
            <h4>重新设置密码</h4>
            <span @click="isPass = false" class="passverify">去登录</span>
            <el-form :model="verifyform" :rules="verifyrules" ref="verifyruleForm" class="demo-ruleForm">
                <el-form-item prop="email" label="邮箱账号">
                    <el-input type="text" placeholder="请输入邮箱" v-model="verifyform.email"></el-input>
                </el-form-item>
                <el-form-item class="verify" prop="verifyValue" label="验证码">
                    <div class="code">
                        <el-input placeholder="请输入验证码" v-model="verifyform.verifyValue"></el-input>
                        <div class="primaybtn">
                            <el-button :disabled="dadisab" @click="getVerify" type="primary">获取验证码{{
                                    times
                                                                                             }}
                            </el-button>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="新密码" prop="newpassword">
                    <el-input placeholder="请输入新密码" minlength="4" maxlength="12"
                              v-model="verifyform.newpassword"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="oldpassword">
                    <el-input placeholder="请重新确认密码" minlength="4" maxlength="12"
                              v-model="verifyform.oldpassword"></el-input>
                </el-form-item>
                <el-form-item class="item-btn">
                    <el-button type="primary" @click="resetPassword">确认</el-button>
                </el-form-item>
            </el-form>
        </div>


        <!--  底部  -->
        <div class="el-login-footer">
            <span>Copyright {{
                    newTime
                  }} Suzhou Harmontronics Automation Technology Co.,Ltd. All rights reserved.</span>
        </div>

    </div>
</template>

<script>
import {login, getUsername, sendEmail, checkEmail, getAuthAll} from '@/utils/user'
import {mapMutations, mapState} from 'vuex'
import * as myStore from "@/utils/stroage";
import qs from 'qs'
export default {
    name: 'login',
    data() {
        var verifyReg = (rule, value, cb) => {
            var str = this.list.join('').toLowerCase()
            if (!value) {
                this.isverify = false
                return cb(new Error('请输入验证码'))
            }
            if (str !== value.toLowerCase()) {
                cb(new Error('验证码输入错误'))
                this.isverify = false
                this.draw(this.list)
                return
            }
            this.isverify = true
            cb()

        };

        var emailReg = (rule, value, cb) => {
            const reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
            if (value === "") return cb(new Error("邮箱不能为空"));
            if (!reg.test(value)) {
                return cb(new Error("邮箱输入错误"));
            }
            return cb();
        };

        var pwd = (rule, value, cb) => {
            if (value === '') return cb(new Error('密码不能为空'))
            if (this.verifyform.newpassword !== value) return cb(new Error('两次密码不一致'))
            return cb()
        };

        return {
            userform: {
                username: '',
                password: '',
                inputValue: ''
            },
            verifyform: {
                email: '',
                verifyValue: '',
                newpassword: '',
                oldpassword: ''
            },
            verifyrules: {
                email: [
                    {required: true, message: "邮箱地址不能为空", trigger: "blur"},
                    {
                        type: "email",
                        message: "请输入正确的邮箱地址",
                        trigger: ["blur", "change"]
                    }
                ],
                verifyValue: [
                    {required: true, message: '请输入验证码', trigger: 'blur'}
                ],
                newpassword: [
                    {required: true, message: '请输入新密码', trigger: 'blur'},
                    {min: 6, max: 12, message: '长度在 6 到 12 个字符', trigger: 'blur'}
                ],
                oldpassword: [
                    {required: true, validator: pwd, trigger: 'blur'}
                ]
            },
            list: [],
            rules: {
                username: [
                    {required: true, message: '请输入用户名', trigger: 'blur'},
                    {min: 1, max: 12, message: '长度为1至12位', trigger: 'blur'},
                ],
                password: [
                    {required: true, message: '请输入密码', trigger: 'blur'},
                    {min: 4, max: 12, message: '长度为1至12位', trigger: 'blur'},
                ],
                /**
                 inputValue: [
                 {required: true, validator: verifyReg, trigger: 'blur'}
                 ]
                 **/
            },
            isverify: false,
            widths: 0,
            heights: 0,
            isPass: false,
            times: '',
            timeNumber: 60,
            timer: null,
            dadisab: false,
            vercode: false
        }
    },
    created() {
        /**
         this.$nextTick(() => {
        this.widths = this.$refs.contentCanvas.offsetWidth
        this.heights = this.$refs.contentCanvas.offsetHeight
      })
         **/
    },
    mounted() {
        this.$nextTick(() => {
            this.draw(this.list)
        })
    },
    computed: {
        ...mapState(['imgURL']),
        newTime() {
            return new Date().getFullYear()
        }
    },
    methods: {
        ...mapMutations(['userToken', 'userfresh', 'userInfo']),
        userinfologin() {
            //validate方法进行表单预验证,valid参数表示表单是否通过验证，通过为true,不通过为false
            this.$refs.ruleForm.validate(async valid => {
                if (!valid) {
                    return
                }
                //console.log("userinfologin");
                try {
                    myStore.sessionRemoveItem("electric-token");
                    myStore.sessionRemoveItem("electric-user");
                    // removeItem("electric-token");
                    // removeItem("electric-user");
                    //console.log("userinfologin sessionremoveItem");
                    const user = qs.stringify({
                        username: this.userform.username,
                        password: this.userform.password,
                        grant_type: 'password',
                        scope: 'SCOPES',
                        client_id: 'OAUTH_CLIENT_ID',
                        client_secret: 'OAUTH_CLIENT_SECRET'
                    })
                    const res = await login(user)
                    if (res.data.code !== 200) {
                        this.$msg.error(res.data.msg)
                        return
                    }
                    this.userToken(JSON.stringify(res.data))
                    console.log("login->getUsername:",this.userform.username);
                    const result = await getUsername(this.userform.username)
                    if (result.data.code != 200) {
                        return this.$msg.error('登录失败！', result.data.msg)
                    } else {
                        this.userInfo(JSON.stringify(result.data.data));
                        //查询页面按钮权限
                        let authAllRes = await getAuthAll();
                        // console.log("getAuthAll->result:",JSON.stringify(authAllRes));
                        if (authAllRes.data.code !== 200) {
                            return this.$msg.error('获取权限列表失败!' + authAllRes.data.msg)
                        }
                        let authAllData = []; //存储按钮级别权限
                        authAllRes.data.data.forEach((item) => {
                                if (item.menuLevel === 2) {
                                    authAllData.push(item.functionCode + '')
                                }
                            }
                        );

                        myStore.sessionSetItem("function-code", JSON.stringify(authAllData));
                        this.$router.push('/index');
                    }

                } catch (e) {
                    this.draw(this.list)
                    this.$msg.error('登录时发生异常')
                }
            })
        },
        //获取邮箱验证码
        async getVerify() {
            /**
             const reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
             if (this.verifyform.email === '') return this.$msg.info('请输入邮箱')
             if (!reg.test(this.verifyform.email)) return
             this.dadisab = true
             **/
            let data = {address: this.verifyform.email};

            const result = await sendEmail(JSON.parse(JSON.stringify(data)))
            this.timer = setInterval(() => {
                this.times = '(' + this.timeNumber + '秒)'
                this.timeNumber--
                if (this.timeNumber < 0) {
                    clearInterval(this.timer)
                    this.times = ''
                    this.dadisab = false
                    this.timeNumber = 60
                }
            }, 1000)

        },

        async resetPassword() {
            let data = {
                address: this.verifyform.email,
                code: this.verifyform.verifyValue,
                newPassword: this.verifyform.newpassword
            };
            const result = await checkEmail(data)
            if (result.data.code == 200) {
                this.isPass = false
                this.$msg.success('重置密码成功，请登录！')
            } else {
                this.$msg.error('重置密码失败！', result.data.msg)
            }
        },

        cliakCanvas() {
            this.draw(this.list)
        },
        draw(show_num) {
        },
        draw2(show_num) {
            var canvas_width = this.widths
            var canvas_height = this.heights
            var canvasx = parseInt(document.body.offsetWidth / 200)
            var fonts = parseInt(document.body.offsetWidth / 60)
            var canvasy = parseInt(document.body.offsetWidth / 100)
            var canvas = document.getElementById("canvas");//获取到canvas的对象，演员
            var context = canvas.getContext("2d");//获取到canvas画图的环境，演员表演的舞台
            canvas.width = canvas_width;
            canvas.height = canvas_height;
            var sCode = "a,b,c,d,e,f,g,h,i,j,k,m,n,p,q,r,s,t,u,v,w,x,y,z,A,B,C,E,F,G,H,J,K,L,M,N,P,Q,R,S,T,W,X,Y,Z,1,2,3,4,5,6,7,8,9,0";
            var aCode = sCode.split(",");
            var aLength = aCode.length;//获取到数组的长度
            for (var i = 0; i < 4; i++) { //这里的for循环可以控制验证码位数（如果想显示6位数，4改成6即可）
                var j = Math.floor(Math.random() * aLength);//获取到随机的索引值
                // var deg = Math.random() * 30 * Math.PI / 180;//产生0~30之间的随机弧度
                var deg = Math.random() - 0.5; //产生一个随机弧度
                var txt = aCode[j];//得到随机的一个内容
                show_num[i] = txt.toLowerCase();
                var x = canvasx + i * 20;//文字在canvas上的x坐标
                var y = canvasy + 1 * 6;//文字在canvas上的y坐标
                context.font = `bold ${fonts}px 微软雅黑`
                context.translate(x, y);
                context.rotate(deg);
                context.fillStyle = this.randomColor();
                context.fillText(txt, 0, 0);
                context.rotate(-deg);
                context.translate(-x, -y);
            }
            for (var i = 0; i <= 3; i++) { //验证码上显示线条
                context.strokeStyle = this.randomColor();
                context.beginPath();
                context.moveTo(Math.random() * canvas_width, Math.random() * canvas_height);
                context.lineTo(Math.random() * canvas_width, Math.random() * canvas_height);
                context.stroke();
            }
            for (var i = 0; i <= 20; i++) { //验证码上显示小点
                context.strokeStyle = this.randomColor();
                context.beginPath();
                var x = Math.random() * canvas_width;
                var y = Math.random() * canvas_height;
                context.moveTo(x, y);
                context.lineTo(x + 1, y + 1);

                context.stroke();
            }
        },
        //得到随机的颜色值
        randomColor() {
            var r = Math.floor(Math.random() * 256);
            var g = Math.floor(Math.random() * 256);
            var b = Math.floor(Math.random() * 256);
            return "rgb(" + r + "," + g + "," + b + ")";
        }
    }
}
</script>

<style lang='less' scoped>
.login-contianor {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../assets/image/bgs.png');
    background-size: 100%;

    .login-from {
        width: 450px;
        background: rgba(225, 225, 225, .6);
        border-radius: 20px;
        padding: 25px 35px 0 35px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        box-shadow: #0046ea 0 0 50px;

        .el-form {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 0 10px 0;
        }

        .el-input {
            height: 42px;

            input {
                height: 42px;
            }
        }


        .login-title {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 20px;

            > span {
                font-size: 24px;
                color: #626262;
            }
        }

        /*.btn{*/

        /deep/ .btn {
            position: relative;
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            /*>.el-button{*/
            /*   width: 90%;*/
            /*   display: flex;*/
            /*   align-items: center;*/
            /*   justify-content: center;*/
            /*}*/

            > div {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;

                > .el-button {
                    width: 90%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            > span {
                padding: 15px 0;
            }

            .removepwd {
                position: absolute;
                left: 0;
                bottom: 0;
                cursor: pointer;
            }

            .removepwd:hover {
                color: #66b1ff;
            }

            > .regitar {

                cursor: pointer;
            }

            > .regitar:hover {
                color: #66b1ff;
            }
        }
    }
}

.contianor-box {
    position: relative;
    width: 450px;
    height: 370px;
    background: rgba(225, 225, 225, .6);
    border-radius: 20px;
    padding: 25px 35px;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    box-shadow: #0046ea 0 0 50px;

    h4 {
        font-size: 18px;
    }

    .passverify {
        position: absolute;
        bottom: 5%;
        right: 20px;
        font-size: 14px;
        cursor: pointer;
        color: #fff;
    }

    .passverify:hover {
        color: #409eff;
    }

    .el-form {
        flex: 7;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .el-form-item {
            flex: 1;
            margin-bottom: 0;
            display: flex;
            align-items: center;

            /deep/ .el-form-item__label {
                display: flex;
                justify-content: flex-start;
                width: 80px;
            }

            /deep/ .el-form-item__label:before {
                display: none;
            }

            /deep/ .el-form-item__content {
                width: calc(~'100% - 80px');
            }
        }

        .verify {
            .el-form-item__content {
                .code {
                    .el-input {
                        margin-right: 10px;
                    }
                }
            }
        }

        .item-btn {
            display: flex;
            align-items: center;
            justify-content: center;

            /deep/ .el-form-item__content {
                display: flex;
                align-items: center;
                justify-content: center;

                .el-button {
                    width: 100%;
                }
            }

        }
    }
}

.code {
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .el-input {
        width: 60%;
    }

    .code-canvas {
        width: 30%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #efefef;
        cursor: pointer;
    }
}

.passDate {
    position: absolute;
    top: 32px;
    right: 0;
    color: #424242;
    cursor: pointer;
}

.passDate:hover {
    color: #66b1ff;
}

.primaybtn {
    width: 40%;
    height: 100%;

    > .el-button {
        width: 100%;
        height: 100%;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.el-login-footer {
    height: 40px;
    line-height: 40px;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #fff;
    font-family: Arial;
    font-size: 12px;
    letter-spacing: 1px;
}
</style>
