<template>
    <div class="page flex-col">
        <div class="block_1 flex-col">
            <div class="group_1 flex-col">

                <div class="group_36 flex-row">
                    <div>
                        <div>
                            <img
                                class="image_28"
                                referrerpolicy="no-referrer"
                                src="../../assets/image/home.png"
                            />

                            <div class="enter-warp">
                                <span class="enter-text mouse-click" @click="enterSystem">
                                    进入系统
                                </span>
                            </div>
                        </div>

                        <div class="group_61 flex-col">
                            <div class="box_35 flex-row">
                                <div style="margin:0 10px 0 0" v-for="(item,index) in timearr" :key="index"
                                     :class="(activeIndex==index?'text-wrapper_2 flex-col':'text-wrapper_1 flex-col')">
                                    <span :class="(activeIndex==index?'text_4':'text_1')"
                                          @click="getSwapEnergyValAndTimes('day',index)">{{ item }}</span>
                                </div>
                                <!-- <div  style="margin:0 10px 0 0" v-for="(item,index) in timearr" :key="index" class='flex-col'
                                      :class="[{'text-wrapper_1':activeIndex!=index},{'text-wrapper_2':activeIndex==index}]" >
                                    <span :class="(activeIndex==index?'text_4':'text_1')" @click="getSwapEnergyValAndTimes('day',index)" >{{item}}</span>
                                </div> -->

                                <!-- <div class="block_2 flex-col" @click="getSwapEnergyValAndTimes('month')">
                                    <img
                                        class="image_1"
                                        referrerpolicy="no-referrer"
                                        src="../../assets/home/img/ps4ms7fm22dc59gn8f4dz8gwzzf7kjvn0vkf8885870-2411-4ae0-8489-09cb6c063863.png"
                                    />
                                    <span class="text_2">月</span>
                                    <img
                                        class="image_2"
                                        referrerpolicy="no-referrer"
                                        src="../../assets/home/img/ps6t9n7uwvgmxx90omsvazlv883cfnfjd43b23189-9e71-4fa0-8227-eff0999736e2.png"
                                    />
                                </div>
                                <div class="block_3 flex-col" @click="getSwapEnergyValAndTimes('quarter')">
                                    <img
                                        class="image_3"
                                        referrerpolicy="no-referrer"
                                        src="../../assets/home/img/pswc9lh6ttqj5xnvl3bqk7covffy40c6nb3217f25-1972-4d72-8c52-456699015648.png"
                                    />
                                    <span class="text_3"  style="display: block; margin-bottom:0.044rem">季</span>
                                    <img
                                        class="image_4"
                                        style="display: block; margin-top:0.038rem"
                                        referrerpolicy="no-referrer"
                                        src="../../assets/home/img/psm8uroy32gkcuvitfhh6eysow8ojvqmtdbfea39852-6d74-49d0-bc6f-d02360cfae8e.png"
                                    />
                                </div> -->
                                <!-- <div class="text-wrapper_1 flex-col" @click="getSwapEnergyValAndTimes('year')"><span class="text_4">年</span></div> -->
                            </div>
                            <div class="box_36 flex-row" style="margin-left:0">
                                <div class="=energy_box flex-row">
                                    <img
                                        style="width:0.526042rem;height:0.526042rem;vertical-align: bottom;"
                                        referrerpolicy="no-referrer"
                                        src="../../assets/home/img/psl87q31rb1rl3wp9bze5l95gyn7k5rf5qc04b4f622-a3e0-4949-9f77-7b3b4e3dec56.png"
                                    />
                                    <div class="block_18 flex-col justify-between">
                                        <span class="text_5">累计充电电量</span>
                                        <div class="box_37 flex-row">

                                            <!--
                                            <div style="display:block; margin: 5px 6px 0 0; position: relative;" v-for=" (item,index)  in  SwapEnergyTatol" :key="index">
                                                <img
                                                class="label_1"
                                                referrerpolicy="no-referrer"
                                                src="../../assets/home/img/psfqus1dctlovy0vipniubgiraa3a1xgcocee538ad-8ba8-4a1e-ae0d-54f2e34b5208.png"
                                            />
                                            <span class="text_7 " style="position:absolute;top:0.005041666rem;left: 0.11041666rem;">{{item}}</span>
                                            </div>
                                            -->

                                            <span class="text_8">{{ SwapEnergyTatol }} kWh</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="times_box flex-row" style="margin-left:0.262558rem">
                                    <img
                                        style="width:0.526042rem;height:0.526042rem;vertical-align: bottom;"
                                        referrerpolicy="no-referrer"
                                        src="../../assets/image/swaptimes.png"
                                    />
                                    <div class="block_18 flex-col justify-between">
                                        <span class="text_5">累计换电次数</span>
                                        <div class="box_37 flex-row">
                                            <!--
                                            <div style="display:block; margin: 5px 6px 0 0; position: relative;" v-for="(item,index) in SwapTimeTatol" :key="index">
                                                <img
                                                class="label_1"
                                                referrerpolicy="no-referrer"
                                                src="../../assets/home/img/psfqus1dctlovy0vipniubgiraa3a1xgcocee538ad-8ba8-4a1e-ae0d-54f2e34b5208.png"
                                            />
                                            <span class="text_7" style="position:absolute;top:0.0050041666rem;left: 0.11041666rem;" >{{item}}</span>
                                            </div>
                                            -->

                                            <span class="text_8">{{ SwapTimeTatol }} 次</span>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                    <div class="group_37 flex-col">

                        <div class="group_5 flex-col justify-center">
                            <div class="block_4 flex-row">
                                <img
                                    class="thumbnail_1"
                                    referrerpolicy="no-referrer"
                                    src="../../assets/home/img/ps6p8ao1bivmpns1qw9j0bb905zbnf6kuauv17e01afa-fcb3-4ed0-8613-6049ea389dee.png"
                                />
                                <span class="text_13">最新订单列表</span>
                            </div>
                        </div>

                        <div class="order_content" style="display: block;">
                            <div class="order_wrapper flex-row">
                                <div class="order_title">
                                    <div class="text_15" style="margin-left:0.15625rem">时间</div>
                                    <div class="text_16" style="margin-right:0.15625rem">车队名称</div>
                                    <div class="text_17" >车牌号</div>
                                </div>
                            </div>

                            <div class="order_list ">
                                <!--                               <div class="marquee">-->
                                <!--                                <div class="animate__animated animate__fadeInUp animate__infinite animate__delay-5s an">-->
                                <!--
                                <div class="animate__animated animate__fadeInUp animate__infinite animate__delay-5s">
                                -->
                                <vueSeamless :data="orderList" :class-option="defaultOption">
                                    <div>
                                        <div v-for="item in orderList" :key="item.id">
                                            <div id="orderShow" class="orderShow" style="padding-left:0.0695rem">
                                                <div class="order_text1 flex-col"></div>
                                                <span class="order_span1">{{ item.createTime }}</span>
                                                <span class="order_span1" style="padding-left:0.0695rem">   {{ item.teamName }}</span>
                                                <span class="order_span1" style="padding-left:0.1695rem">   {{ item.staSwapRecord.cn }}</span>
                                            </div>
                                        </div>

                                    </div>
                                </vueSeamless>

                            </div>
                        </div>
                    </div>
                    <div class="group_45 flex-row">
                        <div class="box_2 flex-col">
                            <chinaMap/>
                        </div>
                    </div>
                    <div class="group_51 flex-col justify-between">

                        <div class="block_13 flex-col">

                            <div class="block_14 flex-row justify-between">
                                <div class="total_wrapper1">
                                    <div class="text-wrapper_7_1 flex-col">
                                        <div class="total_text_1">{{ deviceTotal.allTotal }}</div>
                                    </div>

                                    <div class="text-wrapper_7_2 flex-col">
                                        <div class="total_text_2" style="color:#33E17C;">{{
                                                deviceTotal.os1Total
                                            }}
                                        </div>
                                    </div>

                                    <div class="text-wrapper_7_3 flex-col">
                                        <div class="total_text_3" style="color:#FFA900;">{{
                                                deviceTotal.os2Total
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="text-wrapper_24">
                                <div class="total_title1">场站总数</div>
                                <div class="total_title2">运营场站</div>
                                <div class="total_title3">暂停营业场站</div>
                            </div>

                            <div class="block_15 flex-row justify-between">

                                <div class="total_wrapper2">

                                    <div class="text-wrapper_7_4 flex-col">
                                        <div class="total_text_4" style="color:#12FCFF">{{ deviceTotal.os3Total }}</div>
                                    </div>

                                    <div class="text-wrapper_7_5 flex-col">
                                        <div class="total_text_5" style="color:#FF24FC;">{{
                                                deviceTotal.os4Total
                                            }}
                                        </div>
                                    </div>

                                    <div class="text-wrapper_7_6 flex-col">
                                        <div class="total_text_6" style="color:#9FCEE4;">{{
                                                deviceTotal.unknownTotal
                                            }}
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div class="text-wrapper_25">
                                <div class="total_title4">维护场站</div>
                                <div class="total_title5">歇业场站</div>
                                <div class="total_title6">离线场站</div>
                            </div>
                        </div>

                        <div class="block_16 flex-col">
                            <div class="image-text_5 flex-col justify-between">
                                <img
                                    class="image_12"
                                    referrerpolicy="no-referrer"
                                    src="../../assets/home/img/ps8aziw38pafsxcpfj01w4lbcn8pgc9dz8o9ff9748c-a2fc-4a67-9ea2-9b4cbd5218e9.png"
                                />
                            </div>

                            <div class="stationRank">
                                <div class="nol-rank">
                                    <div class="no1-group flex-row">
                                        <span class="no1_text1">1.</span>
                                        <span class="no1_text2">{{ stationRanks[0].stationName }}</span>
                                        <div class="no1-wrapper">
                                            <span class="no1_text3">{{ stationRanks[0].swapTime }}</span>
                                            <span class="no1_text4">单</span>
                                        </div>
                                    </div>
                                    <div class="no1_box flex-col">
                                        <div class="no1_section flex-col">
                                            <div class="no1_block flex-col"></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="no2-rank">
                                    <div class="no2-group flex-row">
                                        <span class="no2_text1">2.</span>
                                        <span class="no2_text2">{{ stationRanks[1].stationName }}</span>
                                        <div class="no2-wrapper">
                                            <span class="no2_text3">{{ stationRanks[1].swapTime }}</span>
                                            <span class="no2_text4">单</span>
                                        </div>
                                    </div>
                                    <div class="no2_box flex-col">
                                        <div class="no2_section flex-col">
                                            <div class="no2_block flex-col"></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="no3-rank">
                                    <div class="no3-group flex-row">
                                        <span class="no3_text1">3.</span>
                                        <span class="no3_text2">{{ stationRanks[2].stationName }}</span>
                                        <div class="no3-wrapper">
                                            <span class="no3_text3">{{ stationRanks[2].swapTime }}</span>
                                            <span class="no3_text4">单</span>
                                        </div>
                                    </div>
                                    <div class="no3_box flex-col">
                                        <div class="no3_section flex-col">
                                            <div class="no3_block flex-col"></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="no4-rank">
                                    <div class="no4-group flex-row">
                                        <span class="no4_text1">4.</span>
                                        <span class="no4_text2">{{ stationRanks[3].stationName }}</span>
                                        <div class="no4-wrapper">
                                            <span class="no4_text3">{{ stationRanks[3].swapTime }}</span>
                                            <span class="no4_text4">单</span>
                                        </div>
                                    </div>
                                    <div class="no4_box flex-col">
                                        <div class="no4_section flex-col">
                                            <div class="no4_block flex-col"></div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
                <div class="group_55 flex-row justify-between">
                    <div class="section_17 flex-col">

                        <div class="ratio_pie">
                            <div class="ratio_body">
                                <img
                                    class="image_13"
                                    referrerpolicy="no-referrer"
                                    src="../../assets/home/img/pshkykqm8fn8lvbl04i13861wegjqsovbm2d77b3f3-3f83-49ca-8687-35c08962ef3f.png"
                                />
                                <div class="ratio_pie_echart">
                                    <elecRatioPie :newPie="ratioPie"></elecRatioPie>
                                </div>
                            </div>

                            <div class="ratio-descirbe">
                                <div class="text-wrapper_26 flex-row justify-between">
                                    <span class="text_85">{{ energyVal.chargeVal }}</span>
                                    <span class="text_89">{{ energyVal.changeVal }}</span>
                                    <span class="text_90">{{ energyVal.otherVal }}</span>
                                </div>

                                <div class="box_34 flex-row justify-between">
                                    <div class="image-text_6 flex-col justify-between">
                                        <img
                                            class="image_16"
                                            referrerpolicy="no-referrer"
                                            src="../../assets/home/img/pslc0bcjm1cqp1osnkaeunifm0gjodjy7gc9a1c05c-f180-4511-86ed-0d48e30deb5f.png"
                                        />
                                        <span class="text-group_2">充电能耗(kWh)</span>
                                    </div>
                                    <div class="image-text_7 flex-col justify-between">
                                        <img
                                            class="image_18"
                                            referrerpolicy="no-referrer"
                                            src="../../assets/home/img/psn18q7aifg1w4msngnu3j1t712yu4yio0f7ced35-5cce-4c9f-a972-2e62af8f7842.png"
                                        />
                                        <span class="text-group_3">换电能耗(kWh)</span>
                                    </div>
                                    <div class="image-text_8 flex-col justify-between">
                                        <img
                                            class="image_19"
                                            referrerpolicy="no-referrer"
                                            src="../../assets/home/img/pspmiv26whnfddc8o3zg1jm8vmllbvj11c2550d315-a5f9-4810-8571-0c179e4d0d47.png"
                                        />
                                        <span class="text-group_4">其他能耗(kWh)</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="section_18 flex-col">
                        <img
                            class="image_14"
                            referrerpolicy="no-referrer"
                            src="../../assets/home/img/psksntr6nj0jasr63l83rk97lq8m58h6zha77aa9a8b-4a18-480f-b59a-acab1e8ecfbd.png"
                        />
                        <div class="block_17 flex-row">

                            <div class="times-line">
                                <swapTimesBar :newBar="swapTimeBar"></swapTimesBar>

                            </div>

                            <div class="group_57 flex-col">
                                <div class="text-wrapper_29 flex-row justify-between">
                                    <span class="text_106">维护中</span>
                                    <span class="text_107">{{ this.deviceRatio.os3Ratio }} %</span>
                                </div>
                                <div class="group_58 flex-row">
                                    <span class="text_108">歇业中</span>
                                    <span class="text_109">{{ this.deviceRatio.os4Ratio }} %</span>
                                    <div class="group_28 flex-col">
                                        <div class="box_31 flex-col"></div>
                                    </div>
                                </div>
                                <div class="group_59 flex-row">
                                    <div class="section_15 flex-col"></div>
                                    <span class="text_110">运营中</span>
                                    <span class="text_111">{{ this.deviceRatio.os1Ratio }}%</span>
                                </div>
                                <div class="group_60 flex-row justify-between">
                                    <span class="text_112">暂停营业</span>
                                    <div class="block_11 flex-col">
                                        <span class="text_113">{{ this.deviceRatio.os2Ratio }}%</span>
                                        <img
                                            class="image_26"
                                            referrerpolicy="no-referrer"
                                            src="../../assets/home/img/ps378cywwwsutsj8pmp8m27gb7hw1flbzk5b5100c3-0adc-4c4a-b3ea-0570406b57a1.png"
                                        />
                                        <img
                                            class="image_27"
                                            referrerpolicy="no-referrer"
                                            src="../../assets/home/img/ps2zr0tiaaz6hu12myvkaomr06rh0flese1627b0e6-7c65-421a-8910-5a0531cbe88a.png"
                                        />
                                        <img class="image_29" referrerpolicy="no-referrer"
                                             src="../../assets/home/img/psbfhg18kt1f4el0vhwh0ieb643pqyixc442c780a7-f7f0-4e97-a86f-fa5ce2e8e917.png"/>
                                        <div class="box_27 flex-col">
                                            <div class="group_27 flex-col">
                                                <div class="box_28 flex-col"></div>
                                            </div>
                                        </div>
                                        <div class="box_29 flex-col">
                                            <div class="box_30 flex-col">
                                                <div class="block_12 flex-col"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import axios from 'axios'
import {
    energyValRatio,
    orderStatics,
    staSwapRanking,
    stationTotalStatics,
    swapEnergyValAndTimes,
    swapTimes7Days
} from "@/utils/index";
import elecRatioPie from "@/components/elec-statistics-components/elec-ratio-pie";
import swapTimesBar from '@/components/elec-statistics-components/swaptimes-bar'
import animated from "animate.css";
import moment from "moment";
import * as echarts from 'echarts'
import chinaMap from '@/components/map/china-map'
import {getBeforeDay, getBeforeDayYMD} from "@/utils/dateUtil";

import vueSeamless from 'vue-seamless-scroll'
export default {
    name: 'index',
    components: {elecRatioPie, swapTimesBar, chinaMap,vueSeamless},
    data() {
        return {
            timearr: ['日', '月', '季', '年'],
            activeIndex: 0,
            isEcharts: true,
            orderList: [], //订单列表
            deviceTotal: {
                allTotal: 0,
                os1Total: 0,
                os2Total: 0,
                os3Total: 0,
                os4Total: 0,
                unknownTotal: 0
            },
            stationRanks: [
                {stationName: "暂无信息", swapTime: 0},
                {stationName: "暂无信息", swapTime: 0},
                {stationName: "暂无信息", swapTime: 0},
                {stationName: "暂无信息", swapTime: 0}
            ],
            mapData: [],
            energyVal: {
                chargeVal: 0,
                changeVal: 0,
                otherVal: 0,
            },
            ratioPie: [
                {value: 0, name: "充电能耗值"},
                {value: 0, name: "换电能耗值"},
                {value: 0, name: "其它能耗值"},
            ],
            deviceRatio: {
                os1Ratio: 0,
                os2Ratio: 0,
                os3Ratio: 0,
                os4Ratio: 0,
            },
            swapTimeBar: {
                arrAll: [],
                dayList: [],
                titleList: "七日内换电次数统计"
            },
            swapTimeData: {},
            swapDayList: [],
            swapDataList: [],

            // daySwapTimeTatol:0, //前一天的换电次数
            // daySwapEnergyTatol:0,//前一天的耗电量
            // monthSwapTimeTatol:0,//每月换电次数
            // monthSwapEnergyTatol:0,//每月耗电量
            // quarterSwapTimeTatol:0,//季度换电次数
            // quarterSwapEnergyTatol:0,//季度耗电量
            // yearSwapTimeTatol:0,//年度换电次数
            // yearSwapEnergyTatol:0,//年度耗电量
            SwapTimeTatol: 0, //前一天的换电次数
            SwapEnergyTatol: 0//前一天的耗电量


        };
    },

    created() {
        this.getDays();
        this.initData();
        this.tranfromBar();
        this.getOrderList();
        this.getStationStatics();
        this.getStaSwapRanking();
        this.getEnergyValRatio();
        this.getSwapTimes7Days();

        //参数: day、month、quarter、year
        this.getSwapEnergyValAndTimes("day", 0);//耗电量和换电次数统计

    },

    computed: {
        defaultOption() {
            return {
                step: 0.4, // 数值越大速度滚动越快
                limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 1, // 0向下 1向上 2向左 3向右
                openWatch: true, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 1000,// 单步运动停止的时间(默认值1000ms)
                ease: 'linear',
            }
        }
    },
    methods: {
        initData() {
            var dataList = [];
            dataList[0] = {name: this.swapDayList[0], value: 0};
            dataList[1] = {name: this.swapDayList[1], value: 0};
            dataList[2] = {name: this.swapDayList[2], value: 0};
            dataList[3] = {name: this.swapDayList[3], value: 0};
            dataList[4] = {name: this.swapDayList[4], value: 0};
            dataList[5] = {name: this.swapDayList[5], value: 0};
            dataList[6] = {name: this.swapDayList[6], value: 0};
            this.swapDataList = dataList;
        },

        async getOrderList() {
            const result = await orderStatics()
            if (result.data.code == 200 && result.data.data.length > 0) {
                this.orderList = result.data.data;
            } else {
                var time = this.showtime(0);
                this.orderList = [
                    {"et": time, "stationName": "暂无信息", "cn": "暂无信息"}, {
                        "et": time,
                        "stationName": "暂无信息",
                        "cn": "暂无信息"
                    },
                    {"et": time, "stationName": "暂无信息", "cn": "暂无信息"}, {
                        "et": time,
                        "stationName": "暂无信息",
                        "cn": "暂无信息"
                    },
                    {"et": time, "stationName": "暂无信息", "cn": "暂无信息"}, {
                        "et": time,
                        "stationName": "暂无信息",
                        "cn": "暂无信息"
                    },
                    {"et": time, "stationName": "暂无信息", "cn": "暂无信息"}, {"et": time, "stationName": "暂无信息", "cn": "暂无信息"}
                ]
            }
        },

        async getStationStatics() {
            const result = await stationTotalStatics()
            if (result.data.code == 200 && result.data.data != null) {
                this.deviceTotal = result.data.data;

                var total = this.deviceTotal.os1Total + this.deviceTotal.os2Total + this.deviceTotal.os3Total + this.deviceTotal.os4Total;
                if (total <= 0) {
                    return;
                }
                this.deviceRatio.os1Ratio = (this.deviceTotal.os1Total / total).toFixed(2) * 100;
                this.deviceRatio.os2Ratio = (this.deviceTotal.os2Total / total).toFixed(2) * 100;
                this.deviceRatio.os3Ratio = (this.deviceTotal.os3Total / total).toFixed(2) * 100;
                this.deviceRatio.os4Ratio = (this.deviceTotal.os4Total / total).toFixed(2) * 100;

            }
        },

        async getStaSwapRanking() {
            const result = await staSwapRanking()
            if (result.data.code == 200) {
                if (result.data.data.length >= 1) {
                    this.stationRanks[0]= result.data.data[0];
                }
                if (result.data.data.length >= 2) {
                    this.stationRanks[1] = result.data.data[1];
                }
                if (result.data.data.length >= 3) {
                    this.stationRanks[2] = result.data.data[2];
                }
                if (result.data.data.length >= 4) {
                    this.stationRanks[3] = result.data.data[3];
                }
            }
        },

        async getStaDistribution() {
            const result = await staDistribution()
            if (result.data.code == 200 && result.data.data.length > 0) {
                this.mapData = result.data.data;
            }
        },

        async getEnergyValRatio() {
            const result = await energyValRatio()
            if (result.data.code == 200 && result.data.data != null) {
                this.energyVal = result.data.data;
                var energyVal = result.data.data;
                var energyData = [];
                var data1 = {value: energyVal.chargeVal, name: "充电能耗"};
                var data2 = {value: energyVal.changeVal, name: "换电能耗"};
                var data3 = {value: energyVal.otherVal, name: "其它能耗"};
                energyData.push(data1);
                energyData.push(data2);
                energyData.push(data3);
                this.ratioPie = energyData;
            }
        },

        async getSwapTimes7Days() {
            let month = this.monthValue
            month = month !== '' ? moment(month).format('YYYY-MM-DD') : moment().month('YYYY-MM-DD');

            const result = await swapTimes7Days();
            if (result.data.code == 200 && result.data.data != null) {
                var swapData = result.data.data;
                this.swapTimeData = swapData;
                var dataList = [];
                for (let i = 0; i < swapData.length; i++) {
                    let obj = {};
                    obj.name = swapData[i].recordTime;
                    obj.value = swapData[i].swapTime;
                    dataList.push(obj)
                }
                if(dataList.length >0) {
                    this.swapDataList = dataList;
                    this.tranfromBar();
                }

            }
        },

        getDays(date, day) {
            let dateList = [];
            var dd = new Date();
            dateList[0] = getBeforeDayYMD(dd, 6);
            dateList[1] = getBeforeDayYMD(dd, 5);
            dateList[2] = getBeforeDayYMD(dd, 4);
            dateList[3] = getBeforeDayYMD(dd, 3);
            dateList[4] = getBeforeDayYMD(dd, 2);
            dateList[5] = getBeforeDayYMD(dd, 1);
            dateList[6] = getBeforeDayYMD(dd, 0);
            this.swapDayList = dateList;
        },



        tranfromBar() {
            var barData = [];
            var lineData = [];
            var swapData = this.swapDataList;
            var dayList = this.swapDayList;
            this.isEcharts ? barData.push({name: "换电次数", type: 'bar', data: swapData}) : lineData.push({
                name: "换电次数",
                type: 'line',
                data: swapData
            })
            let arrAll = this.isEcharts ? barData : lineData;
            this.swapTimeBar = {
                arrAll: arrAll,
                dayList,
                titleList: "七日内换电次数统计"
            }

        },

        tranfromBar2(list1, month) {
            let arrAll = []
            let arrLine = []
            let newArr = []
            let monthList = []

            let dayList = this.dayLIST(moment(month).daysInMonth())
            if (list1.length !== 0) {
                let obj = this.redcueList(list1, dayList)
                newArr = obj.newArr
                monthList = obj.monthList
            } else {
                dayList.forEach(item => {
                    newArr.push(0)
                    monthList.push(item)
                })
            }
            this.isEcharts ? arrAll.push({
                name: this.yearList.time,
                type: 'bar',
                data: newArr
            }) : arrLine.push({name: this.yearList.time, type: 'line', data: newArr})
            this.newBar = {
                arrAll: this.isEcharts ? arrAll : arrLine,
                monthList,
                dataList: [this.yearList.time]
            }
        },

        redcueList(val, dayList) {
            let newArr = [];
            let monthList = [];
            dayList.forEach(item => {
                let index = monthList.findIndex(c => c === item)
                index === -1 && monthList.push(item)
                let i = val.findIndex(c => moment(c.dt).date() === item) //判断返回数组里面的换电日期是否等于当前月份里的日期
                if (i === -1) {
                    newArr.push({name: item, value: 0})
                } else {
                    newArr.push({name: item, value: val[i].tc})
                }
            });
            return {
                newArr,
                monthList
            }
        },

        yearList() {
            let month = this.monthValue
            month = month !== '' ? moment(month).format('YYYY-MM-DD') : moment().month('YYYY-MM-DD')
            return {
                time: moment(month).format('YYYY-MM'),
            }
        },
        showtime(total) {
            var nowdate = new Date();
            var year = nowdate.getFullYear(),
                month = nowdate.getMonth() + 1,
                date = nowdate.getDate() + total,
                day = nowdate.getDay(),
                week = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"],
                h = nowdate.getHours(),
                m = nowdate.getMinutes(),
                s = nowdate.getSeconds()
            return year + "年" + month + "月" + date + "日" + h + ":" + m + ":" + s;
        },

        showtime2(total) {
            var nowdate = new Date();
            var year = nowdate.getFullYear();
            var month = nowdate.getMonth() + 1;
            var newMonth = (month < 10 ? "0" + (month) : month); //单月补0
            var date = nowdate.getDate() + total;
            var newDate = (date < 10 ? "0" + (date) : date); //单日补0
            return year + "-" + newMonth + "-" + newDate;
        },

        enterSystem() {
            this.$router.push('/force')
        },

        async getSwapEnergyValAndTimes(type, i) {
            const result = await swapEnergyValAndTimes(i)
            this.activeIndex = i;
            if (result.data.code == 200 && result.data.data != null) {
                this.SwapTimeTatol = result.data.data.swapTime;//.toString().split('');
                this.SwapEnergyTatol = result.data.data.chargeEnergyVal;//.toString().split('');
            }
        }


    },


};
</script>


<style lang='less' scoped>
body * {
    box-sizing: border-box;
    flex-shrink: 0;
}

body {
    font-family: PingFangSC-Regular, Roboto, Helvetica Neue, Helvetica, Tahoma, Arial, PingFang SC-Light, Microsoft YaHei;
}

button {
    margin: 0;
    padding: 0;
    border: 1px solid transparent;
    outline: none;
    background-color: transparent;
}

button:active {
    opacity: 0.6;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.justify-start {
    display: flex;
    justify-content: flex-start;
}

.justify-center {
    display: flex;
    justify-content: center;
}

.justify-end {
    display: flex;
    justify-content: flex-end;
}

.justify-evenly {
    display: flex;
    justify-content: space-evenly;
}

.justify-around {
    display: flex;
    justify-content: space-around;
}

.justify-between {
    display: flex;
    justify-content: space-between;
}

.align-start {
    display: flex;
    align-items: flex-start;
}

.align-center {
    display: flex;
    align-items: center;
}

.align-end {
    display: flex;
    align-items: flex-end;
}
//新的css
.order_span1{
    //padding-left: 20px;
    font-size: 14px !important;
}
.order_list{
    height: 500px;
    overflow: hidden;
}
</style>
