<template>
  <!-- 电池包分部数据 -->
  <div class="content">
    <div class="content-title">
      <el-row>
      <el-col :span="4">
        <el-cascader
          filterable
          v-model="cascaderValue"
          :options="options"
          clearable
          ref="cascader"
          :props="{checkStrictly: true}"
          placeholder="请选择省市区"
        ></el-cascader>
      </el-col>
      <el-col :span="4">
        <el-button type="primary" @click="queryFunction">查询</el-button>
        <el-button @click="restFunction">重置</el-button>
      </el-col>
    </el-row>
    </div>
    <el-row class="content-content">
      <el-col :span="8">
        <branch-pie :newPie="newPie"></branch-pie>
      </el-col>
      <el-col :span="16">
         <branch-bar :newBar="newBar"></branch-bar>
      </el-col>
    </el-row>

   <div class="content-bottom">
        <el-button type="primary" size="small" @click="importExcelData" class="el-icon-download">批量导出</el-button>
      <el-table id="branchTable" :data="tableData" style="width: 100%" ref="branchTable" height="300">
        <el-table-column type="index" label="编号" width="50"></el-table-column>
        <el-table-column prop="areaName" label="区域名称"></el-table-column>
        <el-table-column prop="btyTotalCount" label="区域电池总数"></el-table-column>
        <el-table-column prop="chargingCount" label="正在充电数量"></el-table-column>
        <el-table-column prop="canSwapCount" label="满足换电数量"></el-table-column>
        <el-table-column prop="canChargeCount" label="可以充电数量"></el-table-column>
        <el-table-column prop="btyOtherCount" label="其它电池数量"></el-table-column>
        <el-table-column prop="updateTime" :formatter="$dateFormat" label="更新时间"></el-table-column>
      </el-table>
   </div>
  </div>
</template>

<script>
import {
  getBatteryDistributingInfoOfpage
} from "@/utils/cellPutApi";
import { regionData } from "element-china-area-data"
import branchBar from '@/components/branch/branchBar'
import branchPie from '@/components/branch/branchPie'
export default {
  name: "branchName",
  components:{
   branchBar,
   branchPie
  },
  data() {
    //定义数据
    return {
      cascaderValue: [],
      options: regionData,
      isPage: true,
      tableData: [],
      totalPages: 0,
      coulm: [
        { prop: "areaCode", label: "区域代码" },
        { prop: "btyTotalCount", label: "区域电池总数" },
        { prop: "chargingCount", label: "正在充电数量" },
        { prop: "canSwapCount", label: "满足换电数量" },
        { prop: "canChargeCount", label: "可以充电数量" },
        { prop: "btyOtherCount", label: "其它电池数量" },
        { prop: "updateTime", label: "更新时间" },
      ],
      newPie:{
        list:[],
        name:'电池包分布数据记录'
      },
      newBar:{
         itemList:[],
         list:[],
         areaNameList:[]
      },

    };
  },
  computed:{
    cascader(){
      if(this.cascaderValue.length === 1){
          return this.cascaderValue[0]
      }else if(this.cascaderValue.length === 2){
         return this.cascaderValue[1]
      }else if(this.cascaderValue.length === 3){
        return this.cascaderValue[2]
      }else {
        return this.options[0].value
      }
    }
  },
  created() {
    //进入页面自动运行
    this.getAll();
  },
  methods: {
    //自定义方法
    async getAll() {
      try {
        // let data = {regionCode:this.cascader };
        this.cascaderValue = [this.options[0].value]
        let data = {regionCode:this.options[0].value };
        let res = await getBatteryDistributingInfoOfpage(data);
        if (res.status !== 200) return tis.$msg.error("获取电池分布数据失败");
        let datas = this.reduces(res.data.data)
        this.totalPages = res.data.data.totalElements;
        this.tableData = datas;
        this.handlerPie(datas)
        this.handlerBar(datas)
      } catch (error) {}
    },
    //查询
    async queryFunction() {
      if (this.cascaderValue.length === 0) return;
      try {
        let data = {
          // regionCode:this.cascader
          regionCode:this.cascaderValue.join(',')
        };
        let res = await getBatteryDistributingInfoOfpage(data);
        if (res.status != 200) return this.$msg.error("查询失败");
         let datas = this.reduces(res.data.data)
        this.totalPages = res.data.data.totalElements;
        this.tableData = datas;
        this.handlerPie(datas)
        this.handlerBar(datas)
      } catch (error) {}
    },
    restFunction() {
      this.cascaderValue = [];
      this.getAll();
    },
    cleanList() {
      this.$refs.branchTable.clearSelection();
    },
    async importExcelData() {
       const confirm =await this.$MessageBox.confirm('是否导出文件', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(e=>e)
        if(confirm !== 'confirm')return
        this.$download(this.tableData,this.coulm,'电池包分布数据')
    },
    changeCascader(){
      this.newPie.name = this.$refs.cascader.getCheckedNodes()[0].data.label
    },
    //饼图数据
    handlerPie(list){
      this.newPie.list = []
      let index1 = 0
      let index2 = 0
      let index3 = 0
      let index4 = 0
      let index5 = 0
      if(list.length === 0){
        this.newPie.list.push({value:0,name:'电池总数'},
           {value:0,name:'正在充电'},
           {value:0,name:'满足换电'},
           {value:0,name:'可以充电'},
           {value:0,name:'其它电池'})
      }else{
         list.forEach(item=>{
           index1 += item.btyTotalCount
           index2 += item.chargingCount
           index3 += item.canSwapCount
           index4 += item.canChargeCount
           index5 += item.btyOtherCount
         })
        let newcenArr = this.countPercentage([index1,index2,index3,index4,index5])
      this.newPie.list.push({value:newcenArr[0],name:'电池总数'},
           {value:newcenArr[1],name:'正在充电'},
           {value:newcenArr[2],name:'满足换电'},
           {value:newcenArr[3],name:'可以充电'},
           {value:newcenArr[4],name:'其它电池'})
      }

    },
    //柱状图数据
    handlerBar(list){
       let newItem = ['电池总数','正在充电','满足换电','可以充电','其它电池']
       let newList = []
       let areaCodeList = []
       let chargingList = []
       let canSwapList = []
       let canChargeList = []
       let btyOtherList = []
       let btyTotalList = []
       if(list.length === 0){
            newItem.forEach(item=>{
            item === '电池总数' && newList.push({name:item,type:'bar',data:0})
            item === '正在充电' && newList.push({name:item,type:'bar',data:0})
            item === '满足换电' && newList.push({name:item,type:'bar',data:0})
            item === '可以充电' && newList.push({name:item,type:'bar',data:0})
            item === '其它电池' && newList.push({name:item,type:'bar',data:0})
          })
       } else{
      let keysList = Object.keys(list[0])
       list.forEach(item=>{
         areaCodeList.push(item.areaName)
         for (const key in item) {
           if(key === keysList[2]){
             btyTotalList.push(item.btyTotalCount)
           }else if(key === keysList[3]){
             chargingList.push(item.chargingCount)
           }else if(key === keysList[4]){
             canSwapList.push(item.canSwapCount)
           }else if(key === keysList[5]){
             canChargeList.push(item.canChargeCount)
           }else if(key === keysList[6]){
              btyOtherList.push(item.btyOtherCount)
           }
         }
       })
        newItem.forEach(item=>{
         item === '电池总数' && newList.push({name:item,type:'bar',data:btyTotalList})
         item === '正在充电' && newList.push({name:item,type:'bar',data:chargingList})
         item === '满足换电' && newList.push({name:item,type:'bar',data:canSwapList})
         item === '可以充电' && newList.push({name:item,type:'bar',data:canChargeList})
         item === '其它电池' && newList.push({name:item,type:'bar',data:btyOtherList})
       })

       }
      this.newBar.list = newList
      this.newBar.itemList = newItem
      this.newBar.areaNameList = list.length === 0?[]:areaCodeList
      this.newBar.name = '电池包分布数据'
    },
    reduces(list){
       if(list.length === 0)return []
     return  list.reduce((item,row)=>{
          let i = item.findIndex(c=>c.areaCode === row.areaCode)
         i === -1 && item.push(row)
         i !== -1 && (item[i].btyOtherCount += row.btyOtherCount)
         i !== -1 && (item[i].btyTotalCount += row.btyTotalCount)
         i !== -1 && (item[i].canChargeCount += row.canChargeCount)
         i !== -1 && (item[i].canSwapCount += row.canSwapCount)
         i !== -1 && (item[i].chargingCount += row.chargingCount)
         return item
       },[])
    },
    countPercentage(countArray){
        var j = eval(countArray.join('+'));
        var resultArray = [];
        for (var i = 0 ; i < countArray.length ; i++){
          if(countArray[i] === 0){
            resultArray.push(0);
          }else{
             var k = Math.floor((countArray[i]/j)*100)
              resultArray.push(k);
          }
        }
        return resultArray;
    }
  },
};
</script>

<style lang='less' scoped>
.el-row {
  margin: 15px 0;
  .el-col {
    margin-right: 20px;
  }
}
.el-cascader {
  width: 100%;
}
.content {
  border: 0;
  box-shadow: none;
  background-color:#f0f2f5;

}
.content-title{
  background-color: #fff;
  padding: 15px;
}
.content-content{
  display: flex;
  margin: 15px 0;
  .el-col{
    background-color: #fff;
    &:nth-child(1){
      margin-right: 15px;
      flex: 2.5;
    }
    &:nth-child(2){
       flex: 7.5;
       padding: 5px;
       margin: 0;
    }
  }
}
.content-bottom{
  background-color: #fff;
  padding: 15px;
  .el-button{
    margin-bottom: 15px;
  }
}
</style>
