<template>
    <div class="app-container">
        <el-row class="main-flex">
            <el-col :span="3" class="side-search" v-if="isopenShow">
                <operatorTreeVue @emitToParent='getTreeData' @isShow="isHidden"></operatorTreeVue>
            </el-col>

            <el-col v-if="!isopenShow" class="small-side">
                <div class="change-in" @click="isopenShow = !  isopenShow "></div>
            </el-col>


            <!-- 搜索区域 -->
            <el-col :span="21" class="mian-content">
                <div class="head-section">
                    <!--              <el-col :span="5">-->
                    <!--                  <el-input placeholder="请从左侧选择运营商" v-model.trim="treeData.operatorNameTree" disabled></el-input>-->
                    <!--              </el-col>-->
                    <el-col :span="8">
                        <el-form :inline="true">
                            <el-form-item label="区域编码：">
                                <el-input placeholder="请输入云平台区域编码"
                                          v-model.trim="informationVo.node"></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="8">
                        <el-form :inline="true">
                            <el-form-item label="选择状态：">
                                <el-select v-model="informationVo.status" placeholder="请选择状态">
                                    <el-option
                                        v-for="item in selectOptions"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="8">
                        <div class="btn-end">
                            <span class="btn-rest">
                             <el-button @click="resFuntion" plain>重置</el-button>
                            </span>
                            <span class="btn-submit">
                          <el-button @click="getSearch" type="primary" plain>查询</el-button>
                            </span>
                        </div>
                    </el-col>
                </div>

                <!--        <el-col :span="5">
                          <el-button class="buttonsize" @click="getSearch" type="primary" icon="el-icon-search" plain>查询</el-button>
                          <el-button class="buttonsize" @click="resFuntion" plain icon="el-icon-refresh-right">重置</el-button>
                        </el-col>-->
                <!--  表格部分-->
                <div class="section-main">
                    <el-col class="choose-mian">
                        <div class="section-title">
                            平台区域列表
                        </div>
                        <div>
                            <!--选择项栏-->
                            <span class="alert-box">
                          已选择{{ batchNum.length }}项
                            <span @click="cleanList" style="margin-left:8px;color:#0D6CE4;cursor:pointer;">清空</span>
                        </span>
                            <!--      <el-button type="primary" icon="el-icon-upload2" size="small" @click="importExcel">批量导入</el-button>-->
                            <!--      <el-button size="small" icon="el-icon-download" @click="excelAll" type="primary">批量导出</el-button>-->

                            <span class="pain-btn">
                                <el-button @click="removeAll"
                                           plain
                                           v-hasCode="['base:node:delete']">删除
                                </el-button>
                            </span>
                            <span class="add-btn">
                                <el-button  @click="addOrUpdate =true"
                                           type="primary"
                                           plain
                                           v-hasCode="['base:node:add']"> 新增
                                </el-button>
                            </span>
                        </div>
                    </el-col>
                    <el-col :span="24">
                            <el-row class="table-box">
                                <el-table ref="equipTable" id="equipment" @selection-change="batchList" :data="tableData"
                                          :header-cell-style="headerStyleEvent" :cell-class-name="goodsInfo" height="100%">
                                    <el-table-column align="center" type="selection"></el-table-column>
                                    <!--        <el-table-column label="序号" prop="fid"></el-table-column>-->
                                    <el-table-column align="center" label="编码" type="index" width="80" :index="renIndex"></el-table-column>
                                    <el-table-column align="center" label="归属运营商" prop="operatorName"></el-table-column>
                                    <el-table-column align="center" label="云平台区域编码" prop="node"></el-table-column>
                                    <el-table-column align="center" label="状态" prop="status">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.status === 1">启用</span>
                                            <span v-else-if="scope.row.status === 0">禁用</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column align="center" label="描述" prop="description"></el-table-column>
                                    <el-table-column align="center" label="操作" fixed="right" width="150">
                                        <template slot-scope="scope">
                                            <span @click="compileEqu(scope.row)"
                                                  class="edit-style" v-hasCode="['base:node:update']">编辑
                                            </span>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-row>
                    </el-col>

                    <el-col :span="24">
                        <el-row class="pagin">
                            <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="query.pageNum"
                                :page-sizes="[10, 30, 50,100]"
                                :page-size="query.pageSize"
                                layout="total, sizes, prev, pager, next"
                                :total="total">
                            </el-pagination>
                        </el-row>
                    </el-col>
                </div>
            </el-col>



        </el-row>

        <el-dialog :title="isequipemt?'新增信息':'修改信息'" :visible.sync="addOrUpdate" width="40%" @close="close">
            <el-form size="middle" ref="equipform" :rules="equipmentRules" :model="informationForm" label-width="130px">

                <el-row>
                    <el-col>

                        <el-form-item label="归属运营商" prop="operatorId">
                            <TreeSelect v-model="informationForm.operatorId" :options="treeData.operatorOptions"
                                        placeholder="请选择运营商"/>
                            <!-- <TreeSelect v-model="addForm.operatorId" :options="operatorOptions" placeholder="选择上级目录" @select="change(node)"/>-->
                        </el-form-item>

                        <el-form-item label="云平台区域编号:" prop="node">
                            <el-input v-model="informationForm.node"></el-input>
                        </el-form-item>
                        <el-form-item label="状态:" prop="status">
                            <el-select v-model="informationForm.status">
                                <el-option
                                    v-for="item in selectOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                ></el-option>
                            </el-select>
                            <!--              <el-input v-model="informationForm.fstatus"></el-input>-->
                        </el-form-item>
                        <el-form-item label="描述:" prop="description">
                            <el-input type="textarea" :rows="5" v-model="informationForm.description"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="addOrUpdate = false">取 消</el-button>
        <el-button type="primary" @click="newequipemt">确 定</el-button>
      </span>
        </el-dialog>
    </div>
</template>

<script>
import '@/assets/font/font.css';
import {
    getNodePage,
    addNode,
    updateNode,
    deleteNode
} from '@/utils/station2'
import {mapState} from "vuex";
import operatorTreeVue from "@/components/tree/operatorTree"
import TreeSelect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {operatorTree} from "@/utils/operatorApi";

export default {
    name: 'stationAreaManager',
    components: {TreeSelect, operatorTreeVue},
    data() {
        return {
            isopenShow: true,
            selectOptions: [
                {
                    value: 1,
                    label: "启用",
                },
                {
                    value: 0,
                    label: "禁用",
                },
            ],
            coulm: [
                // {prop:'fid',label:'序号'},
                {prop: 'fthValNo', label: '报警阀值编号'},
                {prop: 'fthValName', label: '报警阀值名称'},
                {prop: 'feqmNo', label: '设备编号'},
                {prop: 'feqmNo', label: '设备编号'},
                {prop: 'feqmName', label: '设备名称'},
                {prop: 'feqmModeNo', label: '设备类型编号'},
                {prop: 'feqmModeName', label: '设备类型名称'},
                {prop: 'fthValue', label: '报警值'},
                {prop: 'fthValType', label: '报警值类别'},
                {prop: 'fthValType', label: '报警阈值类别'},
                {prop: 'fremark', label: '备注'},
                {prop: 'fcreateTime', label: '创建时间'},
                {prop: 'fmodifyTime', label: '修改时间'}
            ],
            informationVo: {
                node: null,
                status: null
            },
            isPage: true,//查询
            query: {
                pageNum: 1,
                pageSize: 10
            },
            total: 0,
            addOrUpdate: false,
            isequipemt: true,
            batchNum: [],
            tableData: [],
            allList: [],
            stateList: [
                {
                    value: 1,
                    label: '在役'
                },
                {
                    value: 0,
                    label: '退役'
                }
            ],
            informationForm: {},
            equipmentRules: {
                operatorId: [
                    {required: true, message: '请选择归属运营商', trigger: 'blur'}
                ],

                node: [
                    {required: true, message: '请输入云平台区域编号', trigger: 'blur'}
                ],
                status: [
                    {required: true, message: '请选择状态', trigger: 'blur'}
                ],
                description: [
                    {required: true, message: '请输入云平台区域描述', trigger: 'blur'}
                ],

            },
            brandList: [],
            unitTypeList: [],

            treeData: {
                operatorNameTree: "",
                operatorIdTree: null,
                operatorOptions: undefined,  //运营商树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
            },


        }
    },
    computed: {},
    created() {
        this.getOperatorTree();
        this.getAllPage()
    },


    methods: {
        headerStyleEvent({ row, column, rowIndex, columnIndex }) {
            if (columnIndex == 1) {
                return 'padding:0px;'
            }
        },
        goodsInfo(row){
            if (row.columnIndex == 1) {
                return 'goodsInfo'
            }
        },
        isHidden(message) {
            this.isopenShow = message
        },
        renIndex(index) {
            return (this.query.pageNum - 1) * this.query.pageSize + index + 1
        },

        getSearch() {
            this.query.pageNum = 1;
            this.getAllPage();
        },

        async getAllPage() {
            var data = this.informationVo;
            data.page = this.query.pageNum
            data.size = this.query.pageSize

            var url = "page=" + this.query.pageNum + "&size=" + this.query.pageSize;
            if (this.informationVo.node != null) {
                url += "&node=" + this.informationVo.node;
            }
            if (this.informationVo.status != null) {
                url += "&status=" + this.informationVo.status;
            }
            if (this.treeData.operatorNameTree != "" && this.treeData.operatorNameTree != null) {
                url += "&operatorId=" + this.treeData.operatorIdTree
            } else if (this.treeData.operatorOptions.length > 0) {
                url += "&operatorId=" + this.treeData.operatorOptions[0].id
            }
            data.url = url;

            const result = await getNodePage(data)
            // console.log(result)
            if (result.data.code !== 200)
                return this.$msg.error('获取云平台区域信息失败!')
            this.tableData = result.data.data.content;
            this.total = result.data.data.totalElements
        },
        //重置
        resFuntion() {
            this.informationVo = {}
            this.treeData.operatorNameTree = ""
            this.treeData.operatorIdTree = null
            this.getAllPage()
        },
        batchList(list) {
            this.batchNum = list
        },
        close() {
            this.$refs.equipform.resetFields()
            this.informationForm = {}
            this.isequipemt = true
        },

        //新增与修改
        newequipemt() {
            this.$refs.equipform.validate(async valid => {
                if (!valid) return this.$msg.info('信息不能为空!')
                const data = JSON.parse(JSON.stringify(this.informationForm))
                // data.releaseDate = moment(data.releaseDate).format('x')
                try {
                    if (this.isequipemt) {
                        // console.log(data)
                        const res = await addNode(data)
                        // console.log(res)
                        if (res.data.code !== 200) {
                            this.$msg.error('新增信息失败!' + res.data.msg)
                        } else {
                            this.$msg.success('新增信息成功!')
                            this.addOrUpdate = false
                            this.getAllPage()
                        }
                    } else {
                        const res = await updateNode(data)
                        if (res.data.code !== 200) {
                            this.$msg.error('修改信息失败!' + res.data.msg)
                        } else {
                            this.$msg.success('修改换信息成功!')
                            this.addOrUpdate = false
                            this.getAllPage()
                        }
                    }
                } catch (error) {
                }
            })

        },
        //修改信息填充
        compileEqu(list) {
            this.addOrUpdate = true
            this.isequipemt = false
            var data = JSON.parse(JSON.stringify(list))
            this.informationForm = data
        },

        handleSizeChange(val) {
            this.query.pageSize = val
            this.getAllPage()
        },
        handleCurrentChange(val) {
            this.query.pageNum = val
            this.getAllPage()
        },
        // //获取所有电池型号信息
        // async getAllList(){
        //   const res =await getVehicleModelsAll()
        //   this.allList = res.data.data
        //   console.log(res)
        // },
        async excelAll() {
            const confirm = await this.$MessageBox.confirm('是否导出文件', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(e => e)
            if (confirm !== 'confirm') return
            if (this.batchNum.length === 0) {
                this.$download(this.allList, this.coulm, '报警阈值管理')
            } else {
                this.$download(this.batchNum, this.coulm, '报警阈值管理')
            }
        },
        cleanList() {
            this.$refs.equipTable.clearSelection()
        },
        //批量删除
        async removeAll() {
            if (this.batchNum.length === 0) return this.$msg.info('请选择需要删除的项')
            const config = await this.$MessageBox.confirm(
                "此操作将永久删除选中项, 是否继续?",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }
            ).catch(e => e);
            if (config !== "confirm") return;
            try {
                const list = this.batchNum.map(item => {
                    return item.id
                }).join(",")
                const res = await deleteNode(list)
                if (res.data.code !== 200) return this.$msg.error('删除失败')
                this.tableData = this.tableData.filter((item, i) => {
                    return item.id !== list[i]
                })
                this.$refs.equipTable.clearSelection()
                if (this.query.pageNum > 1 && this.tableData.length === 0) {
                    this.query.pageNum = this.query.pageNum - 1
                }
                this.$msg.success('删除成功')
                this.getAllPage()
            } catch (e) {
                console.log(e);
            }
        },

        getTreeData(data) {
            this.treeData = data
        },

        /** 查询运营商下拉树结构 */
        async getOperatorTree() {
            try {
                this.treeData.operatorOptions = [];
                let res = await operatorTree();
                if (res.status !== 200) return this.$msg.error("获取运营商信息失败");
                this.treeData.operatorOptions.push(res.data.data)
                this.treeData.operatorIdTree = res.data.data.id
                this.treeData.operatorNameTree = res.data.data.label
            } catch (error) {
            }
        },

    },
}
</script>

<style lang='less' scoped>
.app-container {
    background: rgba(255, 255, 255, 0);
    padding-left: 0px;
}


.inner-container {
    min-width: 1300px;
    height: var(--tableHeight);;
    overflow: hidden;
}

//重构新增
.side-search {
    width: 200px;
    background: #FFFFFF;
    border-right: #d3dce6 1px solid;
}

.small-side {
    width: 36px;
    height: 36px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.14);
    border-right: 1px solid #EEE;
}

.mian-content {
    flex: 1;
    background: #F5F7FA;
}

.change-in {
    width: 12px;
    height: 12px;
    background: url("~@/assets/image/intoON.svg") no-repeat;
    background-size: 100% 100%;
}

.head-section {
    padding: 16px;
    background: #FFFFFF;
    overflow: hidden;
}

.btn-end {
    display: flex;
    justify-content: flex-end;
}

.section-main {
    width: 100%;
    //height: calc(100vh - 24px);
    background: #FFFFFF;
    margin-top: 16px;
    padding: 24px 16px 16px 16px;

    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #2F3133;
        font-family: PingFang SC;
    }
}

.section-main::after {
    /* 清除浮动  （clear: both;元素 作用只能是块元素，行内块元素无效）*/
    content: "";
    height: 0;
    visibility: hidden;
    clear: both;
    display: block;

}

.choose-mian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
        display: inline-block;
        margin-left: 16px;
    }
}

.alert-box {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
}

.pagin {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.main-flex {
    display: flex;

}

.left-style {
    padding-left: 43px;
}

.edit-style {
    color: #0D6CE4;
    font-size: 14px;
    cursor:pointer;
}
</style>
<style lang="less">
@import '@/assets/style/pagestyle.less';
</style>

