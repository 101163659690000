<template>
    <div>
        <div :id="amapid" class="container"></div>
    </div>
</template>

<script>
import onlineMap from "@/assets/image/onlineMap.png";
import offline from "@/assets/image/offline.png";
import goonlineCar from "@/assets/image/goCar.png";
import carAggregation from "@/assets/image/carAggregation.svg";
import caroffAggregation from "@/assets/image/caroffAggregation.svg";
import warn from "@/assets/image/warn.svg";
import {carPosition, carTrack} from "@/utils/vehiclApi";

export default {
    name: "mapLoader",
    data() {
        return {
            mapData: null,
            mapCard: null,
            cardShow: '',
            mapPosition: null,
            clusterOpen: null,//维护中聚合点实例
            offlineCluster: null,
            cardTrack: [],
            positionTrack: null,
            duration: null,
            offline: null,
            offlinePosition: null,
            secondsoldShow: '',
            secondHand: null,
            team: {},
            pathSimplifierIns: null,
            navgtr: null,
            linePath: [],
            startPoint: null,
            endPoint: null,
            totalData: [],
        }

    },
    props: {
        amapid: {
            type: String
        },
    },
    watch: {
        getduration: {
            deep: true,
            immediate: true,
            handler(newVal) {
                this.duration = newVal
            }
        },
    },
    created() {
        // console.log(this.amapid,'amapid')
        if (this.amapid == 'container_amap') {
            let p1 = new Promise((resolve, reject) => {
                this.AMap(resolve)
            })
            let p2 = new Promise((resolve, reject) => {
                this.carMapCluster(1, resolve);// 车辆监控 地图车辆点位
            })
            Promise.all([p1, p2]).then((result) => {
                this.mapData = new AMap.Map("container_amap", {
                    zooms: [4, 20],//级别
                    viewMode: '2D',//使用2D视图
                    zoom:5,
                    center: [106.021659,36.574801],
                });
                    this.initMapCluster()
                // this.timing()//定时更新点位
            }).catch((error) => {
                console.log(error, 'error')
            })
        }
    },
    mounted() {

    },
    computed: {
        getduration() {
            return this.$store.state.carDuration
        }
    },
    destroyed() {
        if (this.mapData) {
            this.mapData.destroy();
            console.log('销毁1')
        }
        if (this.mapCard) {
            this.mapCard.destroy()
            console.log('销毁2')
        }
        clearInterval(this.secondHand);
    },
    methods: {
        timing() {
            this.secondHand = setInterval(() => {
                this.carMapCluster()
            }, 15000)
        },
        // 地图车辆点位请求
        carMapCluster(type, resolve) {
            carPosition(this.team).then((res) => {
                this.totalData = res.data.data.onlineData.concat(res.data.data.offlineData)
                this.$emit("totalData", this.totalData);
                if (this.secondHand == null) {
                    this.timing()
                }
                if (this.totalData.length == 0) {
                    this.$message.success('此车队暂无车辆记录！');
                    clearInterval(this.secondHand);
                    this.secondHand = null
                }
                let online = []
                let offline = []
                if (res.data.data.onlineData) {//在线
                    let onlineData = res.data.data.onlineData.filter((item) => {
                        return item.longitude != null && item.latitude != null;
                    });
                    onlineData.forEach((item) => {
                        online.push({
                            extData: item,
                            lnglat: [item.longitude, item.latitude]
                        })
                    })
                    this.mapPosition = online
                } else {
                    this.mapPosition = []
                }
                if (res.data.data.offlineData) {//不在线
                    let offlineData = res.data.data.offlineData.filter((item) => {
                        return item.longitude != null && item.latitude != null;
                    });
                    offlineData.forEach((item) => {
                        offline.push({
                            extData: item,
                            lnglat: [item.longitude, item.latitude]
                        })
                    })
                    this.offlinePosition = offline
                } else {
                    this.offlinePosition = []
                }
                if (resolve) {
                    resolve()
                }
            }).then(() => {
                if (type != 1) {
                    this.initMapCluster()
                }
            })
        },
        // 点击侧边更改搜索
        clusterChange(data) {
            var id = data;
            if (id != "" && id != null) {
                this.team = {}
                if (id.indexOf("operator") != -1) {
                    id = id.slice(9);
                    this.team.operatorId = Number(id);
                } else {
                    id = id.slice(5);
                    this.team.teamId = Number(id);
                }
            }
            this.carMapCluster()
        },
        // 搜索跳转
        searchMoveTo(lng, lat) {
            this.mapData.setZoom(15);
            this.mapData.setCenter([lng, lat]); //设置地图中心点
        },

        callMethod(data) {//开始
            var that = this
            // console.log(that.navgtr,'that.navgtr',this.mapCard)
            that.navgtr[data]()
        },
        callstop(data) {//暂停
            var that = this
            that.navgtr[data]()
        },
        // 关闭弹窗
        destroyCard() {
            if (window.pathSimplifierIns && this.pathSimplifierIns) {
                this.pathSimplifierIns.setData([])
            }
            if (this.cardTrack > 0) {
                this.navgtr.destroy()
            }
        },
        // 打开弹窗
        async getcarTrack(e, time) {
            let data = {
                vin: e,
                startTime: time[0],
                endTime: time[1]
            }
            if (!data.startTime && !data.endTime) {
                this.$message.warning('请选择时间');
                return
            }
            await carTrack(data).then((res) => {
                this.cardTrack = this.linePath = []
                this.linePath = JSON.parse(JSON.stringify(res.data.data))
                if (res.data.data.length != 0) {
                    this.$emit('disabledChange', 1)
                    this.$emit('passedPath', 0, this.linePath.length)//初始化
                    this.linePath.forEach((item, i) => {
                        this.cardTrack.push(
                            [item.longitude, item.latitude]
                        )
                        // 当前时间戳
                        item.createTime = new Date(item.createTime).getTime()
                    })
                    this.linePath.forEach((item, i) => {
                        // 获得到下一个位置
                        let next = this.linePath[i + 1]
                        // 判断是否还有下一个
                        if (next) {
                            // 计算出间隔时间 每秒
                            item.intervalTime = (next.createTime - item.createTime) / 1000
                            // 计算出下一站
                            item.nextDistance = this.distanceFun(
                                [item.longitude, item.latitude],
                                [next.longitude, next.latitude]
                            )
                            // 求出具体的速度
                            item.nextSpeed = Math.abs(item.nextDistance / 1000 / (item.intervalTime / 60 / 60))
                        }
                    })
                    this.startPoint = this.linePath[0]
                    this.endPoint = this.linePath[this.linePath.length - 1]
                    // 计算总时间，hh:mm:ss 格式化
                    let totalTime = this.secondToTimeStr(
                        (Math.abs(this.endPoint.createTime - this.startPoint.createTime)) / 1000)

                } else {
                    this.$emit('disabledChange', 2)
                    // this.$message.success('此时暂无轨迹数据');
                }
                if (this.mapCard == null) {
                    this.initMapTrack(this.linePath.length)
                } else {
                    this.initPathSimplifier()
                }
            })
        },
        // 计算两个坐标点之间的距离
        distanceFun(point1, point2) {
            // 那数组转化为经纬度
            let p1 = new AMap.LngLat(point1[0], point1[1])
            let p2 = new AMap.LngLat(point2[0], point2[1])
            // 计算2点直接的距离
            let distance = Math.round(p1.distance(p2))
            return distance
        },
        // 格式化时间
        secondToTimeStr(t) {
            let h = parseInt(t / 60 / 60 % 24)
            let m = parseInt(t / 60 % 60)
            let s = parseInt(t % 60)
            // 因为h已经是数字型了，如果0不加引号就变成加法了
            h = h < 10 ? '0' + h : h
            m = m < 10 ? '0' + m : m
            s = s < 10 ? '0' + s : s
            return `${h}:${m}:${s}`
        },
        // 卡片地图
        initMapTrack(length) {
            var that = this
            that.mapCard = new AMap.Map("card_amap", {
                zooms: [5.7, 20],                                        //级别
                viewMode: '2D',//使用2D视图
            });
            // console.log(that.mapCard,'that.mapCard')
            new Promise((resolve, reject) => {
                that.AMapUI(resolve, reject)
            }).then(() => {
                if (length != 0) {
                    that.initPathSimplifier()
                }
            })
        },
        // 初始化组件实例
        initPathSimplifier() {
            let that = this
            //清空巡航器与轨迹
            if (window.pathSimplifierIns && that.pathSimplifierIns) {
                that.pathSimplifierIns.setData([])
            }
            if (that.cardTrack > 0) {
                that.navgtr.destroy()
            }
            if (that.linePath.length == 0) {//无数据 退出
                return
            }

            AMapUI.load(['ui/misc/PathSimplifier', 'lib/$'], (PathSimplifier) => {
                if (!PathSimplifier.supportCanvas) {
                    alert('当前环境不支持 Canvas！')
                    return
                }
                //创建一个巡航轨迹路线
                that.pathSimplifierIns = new PathSimplifier({
                    zIndex: 100,//地图层级，
                    map: that.mapCard, //所属的地图实例
                    //巡航路线轨迹列表
                    getPath: (pathData, pathIndex) => {
                        return pathData.path;
                    },
                    // 自动设置视图
                    autoSetFitView: false,
                    clickToSelectPath: false,
                    //自定义样式
                    renderOptions: {
                        // 路径导航样式
                        pathNavigatorStyle: {
                            initRotateDegree: 0,
                            width: 30,
                            height: 80,
                            // 自动旋转
                            autoRotate: true,
                            // 折线拐点连接处样式
                            // lineJoin: 'round',
                            content: PathSimplifier.Render.Canvas.getImageContent(
                                goonlineCar,
                                function onload() {
                                    //图片加载成功，重新绘制一次
                                    pathSimplifierIns.renderLater();
                                },
                                function onerror(e) {
                                    alert('图片加载失败！');
                                }
                            ),
                            pathLinePassedStyle: {
                                lineWidth: 6,
                                borderWidth: 1,
                                strokeStyle: '#60B490',
                                dirArrowStyle: false
                            }
                        },
                        dirArrowStyle: {
                            stepSpace: 35,
                            strokeStyle: '#ffffff',
                            lineWidth: 2
                        },
                        // 线条样式
                        pathLineStyle: {
                            lineWidth: 6,
                            borderWidth: 0,
                            strokeStyle: '#247AE7',
                            dirArrowStyle: true
                        },
                        // 鼠标移入之后线条的样式
                        pathLineHoverStyle: {
                            lineWidth: 6,
                            borderWidth: 0,
                            strokeStyle: '#247AE7',
                            dirArrowStyle: true
                        },
                        // 鼠标单击之后线条的样式
                        pathLineSelectedStyle: {
                            lineWidth: 6,
                            borderWidth: 0,
                            strokeStyle: '#247AE7',
                            dirArrowStyle: true
                        },
                    },
                });
                window.pathSimplifierIns = that.pathSimplifierIns

                //设置数据
                that.pathSimplifierIns.setData([{
                    name: '路线0',
                    path: that.cardTrack
                }]);
                // 开启中心自适应
                that.pathSimplifierIns.setFitView()
                let startSpeed = 0.1
                if (that.linePath[0].speed) {
                    startSpeed = that.linePath[0].speed
                } else {
                    // 初始的速度
                    startSpeed = that.linePath[0].nextSpeed === 0 ? 0.1 : that.linePath[0].nextSpeed
                }
                //对第一条线路（即索引 0）创建一个巡航器
                that.navgtr = that.pathSimplifierIns.createPathNavigator(0, {
                    loop: false, //循环播放
                    speed: startSpeed * that.duration //巡航速度，单位千米/小时
                });
                that.navgtr.on("start resume", function () {
                    that.navgtr._startTime = Date.now();
                    that.navgtr._startDist = this.getMovedDistance();
                });
                that.navgtr.on("stop pause", function () {
                    that.navgtr._movedTime = Date.now() - that.navgtr._startTime;
                    that.navgtr._movedDist = this.getMovedDistance() - that.navgtr._startDist;
                });
                that.navgtr.on("move", function (data, position) {
                    // console.log(startSpeed,'速度*',that.duration,'倍数=',startSpeed * that.duration)
                    let idx = position.dataItem.pointIndex //走到了第几个点
                    let tail = position.tail //至下一个节点的比例位置
                    let totalIdx = idx + tail
                    let len = position.dataItem.pathData.path.length

                    // 计算下一个距离速度
                    if (idx < len - 1) {
                        that.navgtr.setSpeed(that.duration);
                    }
                    // 进度条实时展示
                    let sliderVal = (totalIdx / len) * 100;
                    // 已经播放时间
                    let sTime = parseInt(
                        (((Math.abs(that.endPoint.createTime - that.startPoint.createTime)) / 1000) *
                            sliderVal) / 100)
                    // 格式化时间
                    let passedTime = that.secondToTimeStr(sTime)
                    that.$emit('passedPath', sliderVal, that.cardTrack.length, passedTime)
                    // 如果到头了，回到初始状态
                    if (that.navgtr.isCursorAtPathEnd()) {
                        that.navgtr['start']()
                        that.$emit('passedPath', 0, that.cardTrack.length)
                    }
                });
            })
        },
        // 移动小车
        moveToPoint(num, decimal) {
            this.navgtr.moveToPoint(num, Number('0.' + decimal));
            this.pathSimplifierIns.renderLater();
        },
        initMapCluster() {
            var that = this
            if (that.clusterOpen) {
                that.clusterOpen.setData([]);
            }
            if (that.offlineCluster) {
                that.offlineCluster.setData([]);
            }
            if (that.mapPosition) {//在线
                var count = that.mapPosition.length;
                var _renderClusterMarker = function (context) {
                    var div = document.createElement('div');
                    div.style.background = `url(${carAggregation})` + 'no-repeat';
                    div.style.backgroundSize = 100 + '%' + 100 + '%';
                    var size = Math.round(40 + Math.pow(context.count / count, 1 / 5) * 20);
                    div.style.width = div.style.height = size + 'px';
                    div.style.borderRadius = size / 2 + 'px';
                    div.innerHTML = context.count;
                    div.style.lineHeight = size + 'px';
                    div.style.color = 'white';
                    div.style.fontSize = '14px';
                    div.style.textAlign = 'center';
                    context.marker.setOffset(new AMap.Pixel(-size / 2, -size));
                    context.marker.setContent(div)
                };
                var _renderMarker = function (context) {
                    let direction = context.data[0].extData.positionStatus * -90;
                    // console.log(direction,'direction')
                    var renderdiv = document.createElement('div');
                    renderdiv.style.background = `url(${onlineMap})` + 'no-repeat';
                    renderdiv.style.backgroundSize = (100 + '%', 100 + '%');
                    // var rendersize = Math.round(20+ Math.pow(context.count / count, 1 / 5) * 20);
                    renderdiv.style.width = 80 + 'px';
                    renderdiv.style.height = 30 + 'px';
                    renderdiv.style.transform = `rotate(` + direction + `deg)`;

                    context.marker.on('click', function () {
                        that.setdialog(context)
                    });
                    context.marker.on('mouseover', function () {
                        that.setInfoWindow(context)
                    });
                    // context.marker.on('mouseout', function () {//离开弹窗--消失
                    //     setTimeout(()=>{
                    //         that.setInfoWindowClose(context)
                    //     },3000)
                    // });
                    context.marker.setOffset(new AMap.Pixel(-35, -15));
                    context.marker.setContent(renderdiv)
                };
                that.clusterOpen = new AMap.MarkerCluster(that.mapData, that.mapPosition, {
                    gridSize: 60, // 设置网格像素大小
                    renderClusterMarker: _renderClusterMarker, // 自定义聚合点样式
                    renderMarker: _renderMarker, // 自定义非聚合点样式
                    averageCenter: false,
                });
                that.clusterOpen.on('click', (item) => {

                    //此处是通过包含点的数量判断是否是聚合点，不是聚合点就执行上方单个点的点击方式
                    if (item.clusterData.length <= 1) {
                        return;
                    }
                    //这里是计算所有聚合点的中心点
                    let alllng2 = 0, alllat2 = 0;
                    for (const mo of item.clusterData) {
                        alllng2 += mo.lnglat.lng;
                        alllat2 += mo.lnglat.lat;
                    }
                    const lat2 = alllat2 / item.clusterData.length;
                    const lng2 = alllng2 / item.clusterData.length;
                    //这里是放大地图，此处写死了每次点击放大的级别，可以根据点的数量和当前大小适应放大，体验更佳
                    this.mapData.setZoomAndCenter(this.mapData.getZoom() + 2, [lng2, lat2]);
                });
            }
            if (that.offlinePosition) {//不在线
                var offlinecount = that.offlinePosition.length;
                var _renderClusterOffline = function (context) {
                    var div = document.createElement('div');
                    div.style.background = `url(${caroffAggregation})` + 'no-repeat';
                    div.style.backgroundSize = 100 + '%' + 100 + '%';
                    var size = Math.round(40 + Math.pow(context.count / offlinecount, 1 / 5) * 20);
                    div.style.width = div.style.height = size + 'px';
                    div.style.borderRadius = size / 2 + 'px';
                    div.innerHTML = context.count;
                    div.style.lineHeight = size + 'px';
                    div.style.color = 'white';
                    div.style.fontSize = '14px';
                    div.style.textAlign = 'center';
                    context.marker.setOffset(new AMap.Pixel(-size / 2, -size));
                    context.marker.setContent(div)
                };
                var _renderOffline = function (context) {
                    let direction = context.data[0].extData.positionStatus * -90;
                    // console.log(direction,'direction')
                    var renderdiv = document.createElement('div');
                    renderdiv.style.background = `url(${offline})` + 'no-repeat';
                    renderdiv.style.backgroundSize = (100 + '%', 100 + '%');
                    // var rendersize = Math.round(20+ Math.pow(context.offlinecount / offlinecount, 1 / 5) * 20);
                    renderdiv.style.width = 80 + 'px';
                    renderdiv.style.height = 30 + 'px';
                    renderdiv.style.transform = `rotate(` + direction + `deg)`;

                    context.marker.on('click', function () {
                        that.setdialog(context)
                    });
                    context.marker.on('mouseover', function () {
                        that.setInfoWindow(context)
                    });
                    // context.marker.on('mouseout', function () {//离开弹窗--消失
                    //     setTimeout(()=>{
                    //         that.setInfoWindowClose(context)
                    //     },3000)
                    // });
                    context.marker.setOffset(new AMap.Pixel(-35, -15));
                    context.marker.setContent(renderdiv)
                };
                that.offlineCluster = new AMap.MarkerCluster(that.mapData, that.offlinePosition, {
                    gridSize: 60, // 设置网格像素大小
                    renderClusterMarker: _renderClusterOffline, // 自定义聚合点样式
                    renderMarker: _renderOffline, // 自定义非聚合点样式
                    averageCenter: false,
                });
                that.offlineCluster.on('click', (item) => {
                    //此处是通过包含点的数量判断是否是聚合点，不是聚合点就执行上方单个点的点击方式
                    if (item.clusterData.length <= 1) {
                        return;
                    }
                    //这里是计算所有聚合点的中心点
                    let alllng2 = 0, alllat2 = 0;
                    for (const mo of item.clusterData) {
                        alllng2 += mo.lnglat.lng;
                        alllat2 += mo.lnglat.lat;
                    }
                    const lat2 = alllat2 / item.clusterData.length;
                    const lng2 = alllng2 / item.clusterData.length;
                    //这里是放大地图，此处写死了每次点击放大的级别，可以根据点的数量和当前大小适应放大，体验更佳
                    this.mapData.setZoomAndCenter(this.mapData.getZoom() + 2, [lng2, lat2]);
                });
            }
        },
        // 外部点位弹窗
        setInfoWindow(context) {
            var that = this
            let infPlant = context.data[0].extData
            that.share_data_time(infPlant.createTime)
            let content =
                '<div style="width:300px;padding:24px;font-size: 14px;color: #585858;">'
                + '<p style="font-size: 16px;color: #FF6165;margin:0 0 8px 0;display: flex;align-items: center;">' +
                `<span style="display: inline-block;width: 20px;height: 20px;line-height:20px;margin-right:8px;background: url(${warn}) no-repeat;background-size: 100% 100%"></span>` + `${infPlant.alarmLevel == 0 ? '无故障' : infPlant.alarmLevel + '级报警'}` + '</p>'
                + '<p style="margin:0 0 8px 0;color: #2F3133;">' + that.secondsoldShow + '</p>'
                + '<p style="margin-bottom: 16px;color: #2F3133;">' + infPlant.createTime + '</p>'
                + '<p style="padding:16px 0 8px 0px;border-top: 1px solid #eee">' + '<span style="display:inline-block;width:105px;margin: 0 0 0 5px">' + '车牌号：' + '</span> ' + '<span style="color: #2F3133;margin: 0 3px 0 5px">' + '</span> ' + '--' + '</p>' +
                '<p style="margin:0 0 8px 0;">' + '<span style="display:inline-block;width:105px;margin: 0 0 0 5px">' + 'VIN码：' + '</span> ' + '<span style="color: #2F3133;margin: 0 3px 0 5px">' + '</span> ' + `${infPlant.vin ? infPlant.vin : '--'}` + '</p>'
                + '<p style="margin:0 0 8px 0;">' + '<span style="display:inline-block;width:105px;margin: 0 0 0 5px">' + 'T-BOX编码：' + '</span> ' + '<span style="color: #2F3133;margin: 0 3px 0 5px">' + '</span> ' + `${infPlant.tboxCode ? infPlant.tboxCode : '--'}` + '</p>'
                + '<p style="margin:0 0 8px 0;">' + '<span style="display:inline-block;width:105px;margin: 0 0 0 5px">' + '电池编码：' + '</span> ' + '<span style="color: #2F3133;margin: 0 3px 0 5px">' + '</span> ' + `${infPlant.batteryCode ? infPlant.batteryCode : '--'}` + '</p>'
                + '<p style="margin:0 0 8px 0;">' + '<span style="display:inline-block;width:105px;margin: 0 0 0 5px">' + '车速(km/h)：' + '</span> ' + '<span style="color: #2F3133;margin: 0 3px 0 5px">' + '</span> ' + `${infPlant.speed ? infPlant.speed : '--'}` + '</p>'
                + '<p style="margin:0 0 8px 0;">' + '<span style="display:inline-block;width:105px;margin: 0 0 0 5px">' + '累计里程(km)：' + '</span> ' + '<span style="color: #2F3133;margin: 0 3px 0 5px">' + '</span> ' + `${infPlant.mileage ? infPlant.mileage : '--'}` + '</p>'
                + '<p style="margin:0 0 8px 0;">' + '<span style="display:inline-block;width:105px;margin: 0 0 0 5px">' + '车辆状态：' + '</span> ' + '<span style="color: #4C91FF;margin: 0 3px 0 5px">' + '</span> ' + `${infPlant.carStatus == 1 ? '上电模式' : infPlant.carStatus == 2 ? '驱动模式' : infPlant.carStatus == 3 ? '跛行模式' : infPlant.carStatus == 4 ? '准备就绪' : infPlant.carStatus == 5 ? '跛行准备' : '无效'}` + '</p>'
                + '<p style="margin:0 0 8px 0;">' + '<span style="display:inline-block;width:105px;margin: 0 0 0 5px">' + '充电状态：' + '</span> ' + '<span style="color: #2F3133;margin: 0 3px 0 5px">' + '</span> ' + `${infPlant.chargeSwapLink == 0 ? '未连接' : infPlant.chargeSwapLink == 1 ? '连接' : '无效'}` + '</p>'
                + '<p style="margin:0 0 8px 0;">' + '<span style="display:inline-block;width:105px;margin: 0 0 0 5px">' + '运行模式：' + '</span> ' + '<span style="color: #2F3133;margin: 0 3px 0 5px">' + '</span> ' + `${infPlant.runStatus == 1 ? '纯电' : infPlant.runStatus == 2 ? '混动' : infPlant.runStatus == 3 ? '燃油' : '异常'}` + '</p>'
                + '<p style="margin:0 0 8px 0;">' + '<span style="display:inline-block;width:105px;margin: 0 0 0 5px">' + 'SOC(%)：' + '</span> ' + '<span style="color: #2F3133;margin: 0 3px 0 5px">' + '</span> ' + `${infPlant.soc == -1 ? '异常' : infPlant.soc + '%'}` + '</p>'
                + '<p style="margin:0 0 8px 0;">' + '<span style="display:inline-block;width:105px;margin: 0 0 0 5px">' + '总电流(A)：' + '</span> ' + '<span style="color: #2F3133;margin: 0 3px 0 5px">' + '</span> ' + `${infPlant.batCurrent ? infPlant.batCurrent : '--'}` + '</p>'
                + '<div style="display: flex;justify-content: center;align-items: center;">'
                + ' <div style=" width:88px;height:36px;line-height:36px;text-align:center;border: 1px solid #0D6CE4;' +
                `color:#0D6CE4;border-radius: 4px; cursor: pointer" onclick='openTrack(${JSON.stringify(infPlant.vin)})'>` + '查看轨迹' + '</div>' + '</div>'
                + '</div>';
            this.infoWindow = new AMap.InfoWindow({
                content, // 使用默认信息窗体框样式，显示信息内容
                offset: new AMap.Pixel(0, 0),
                closeWhenClickMap: true,
                autoMove: false
            })
            this.infoWindow.open(this.mapData, context.marker.getPosition());
        },
        // setInfoWindowClose(context){//弹窗消失
        //     this.infoWindow.close(this.mapData, context.marker.getPosition());
        // },
        setdialog(data) {
            this.$emit('setdialogTable', data);
        },
        share_data_time(data) {
            // window.setTimeout("share_data_time()", 1000);
            //时间
            let BirthDay = new Date(data);
            //获取当前时间
            let today = new Date();
            let timeold = (today.getTime() - BirthDay.getTime()); //总豪秒数
            let secondsold = Math.floor(timeold / 1000);          //总秒数
            if (secondsold > 60) {
                this.secondsoldShow = 'GPS掉线'
            } else {
                this.secondsoldShow = 'GPS已连接'
            }

        }

    },

}
</script>

<style scoped lang="less">
.container {
    width: 100%;
    height: 600px;
}
</style>
<style lang="less">
/* 隐藏高德logo  */
.amap-logo {
    display: none !important;
}

/* 隐藏高德版权  */
.amap-copyright {
    display: none !important;
}
</style>
