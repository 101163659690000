<template>
  <el-card>
    <el-row>
      <el-col :span="4">
        <el-input placeholder="请输入设备类型编码" v-model.trim="informationVo.feqmModeNo"></el-input>
      </el-col>
      <el-col :span="4">
        <el-input placeholder="请输入故障码" v-model.trim="informationVo.ffaultCode"></el-input>
      </el-col>
      <el-col :span="4">
        <el-input placeholder="请输入故障等级" v-model.trim="informationVo.ffaultLevel"></el-input>
      </el-col>
      <el-col :span="4">
        <el-button @click="getAllPage()" type="primary">查询</el-button>
        <el-button @click="resFuntion">重置</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-button size="small" @click="addFaultLevel=true" class="el-icon-plus" type="primary"> 新增</el-button>
<!--      <el-button type="primary" icon="el-icon-upload2" size="small" @click="importExcel">批量导入</el-button>-->
      <el-button size="small" icon="el-icon-upload2" @click="dialogImport = true" type="primary">批量导入</el-button>
      <el-button size="small" @click="excelAll" class="el-icon-download" type="primary"> 批量导出</el-button>
      <el-button @click="removeAll" icon="el-icon-delete" size="small" type="danger">选中删除</el-button>
    </el-row>
    <el-row>
      <el-alert :closable="false">
            <span slot="title">
                已选择{{ batchNum.length }}项
                 <span @click="cleanList" style="margin-left:10px;color:#409eff;cursor:pointer;">清空</span>
            </span>
      </el-alert>
    </el-row>
    <el-row>
      <el-table ref="msgLevel" @selection-change="batchList" :data="tableData" style="width: 100%" height="300">
        <el-table-column type="selection" width="40"></el-table-column>
        <el-table-column label="编号" :index="renIndex" type="index" min-width="50"></el-table-column>
        <el-table-column prop="feqmModeNo" label="设备类型代码" min-width="110"></el-table-column>
        <el-table-column prop="ffaultCode" label="故障码" min-width="110"></el-table-column>
        <el-table-column prop="ffaultLevel" label="故障等级" min-width="110"></el-table-column>
        <el-table-column prop="ffaultContent" label="故障内容" min-width="100"></el-table-column>
        <el-table-column prop="fcause" label="产生原因" min-width="110"></el-table-column>
        <el-table-column prop="fhandlMethod" label="处理方法" min-width="110"></el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button @click="compileFaul(scope.row)" icon="el-icon-edit" size="small" type="primary">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNum"
        :page-sizes="[20, 25, 30]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length">
      </el-pagination>
    </el-row>

    <el-dialog
      :title="isLevel ? '新增信息':'修改信息'"
      :visible.sync="addFaultLevel"
      @close="close"
      width="30%">
      <el-form size="small" ref="levelForm" :rules="levelRules" :model="LevelForm" label-width="110px">
        <el-row>
          <el-col :span="20">
            <el-form-item label="设备类型代码:" prop="feqmModeNo">
              <el-input v-model="LevelForm.feqmModeNo"></el-input>
            </el-form-item>
            <el-form-item label="故障码:" prop="ffaultCode">
              <el-input v-model="LevelForm.ffaultCode"></el-input>
            </el-form-item>
            <el-form-item label="故障等级:" prop="ffaultLevel">
              <el-input v-model="LevelForm.ffaultLevel"></el-input>
            </el-form-item>
            <el-form-item label="故障内容:" prop="ffaultContent">
              <el-input v-model="LevelForm.ffaultContent"></el-input>
            </el-form-item>
            <el-form-item label="产生原因:" prop="fcause">
              <el-input v-model="LevelForm.fcause"></el-input>
            </el-form-item>
            <el-form-item label="处理方法:" prop="fhandlMethod">
              <el-input v-model="LevelForm.fhandlMethod"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="addFaultLevel = false">取 消</el-button>
          <el-button type="primary" @click="addLevel">确 定</el-button>
        </span>
    </el-dialog>

    <!-- 批量导入 -->
    <el-dialog title="请选择导入文件" :visible.sync="dialogImport" width="30%" @close="closeLoad">
      <el-upload
        class="upload-demo"
        ref="upload"
        :auto-upload="false"
        action="/myapi/cloud-platform/cloudPlatform/FaultBaseExcel"
        :on-success="fileUploadSuccess"
        :on-error="fileUploadError"
        :disabled="importBtnDisabled"
        :file-list="fileList"
      >
        <el-button slot="trigger" size="small" type="primary">选取文件 </el-button>
      </el-upload>

      <span slot="footer" class="dowload dialog-footer">

        <div>
            <el-button @click="dowtemplate" slot="trigger" size="small">下载模版</el-button>
            <el-button
              :loading="loading"
              style="margin-left: 10px;"
              size="small"
              type="success"
              @click="submitUpload">导入文件
        </el-button>
        </div>
      </span>
    </el-dialog>


  </el-card>
</template>

<script>
  import {
    getLevelPage, addLevelMsg, levelChange, getLevel,
    faultBasePage,
    addFaultBase,
    updateFaultBase,
    deleteFaultBase
  } from '@/utils/faults'
  import * as myStore from "@/utils/stroage";

  export default {
    name: 'faultLevel',
    data() {
      return {
        // 导入
        importBtnDisabled: false, // 按钮是否禁用,
        loading: false,
        dialogImport: false,
        fileList: [],//上传的文件列表
        // 导入
        levelValue: '',
        levelNameValue: '',
        informationVo: {},
        query: {
          pageNum: 1,
          pageSize: 20,
          resPageNum: 1
        },
        totalPage: 0,
        tableData: [],
        addFaultLevel: false,
        isLevel: true,
        isPage: true,
        LevelForm: {},
        levelRules: {
          feqmModeNo: [
            {required: true, message: '请输入设备类型代码', trigger: 'blur'}
          ],
          ffaultCode: [
            {required: true, message: '请选择故障码', trigger: 'change'}
          ],
          ffaultLevel: [
            {required: true, message: '请输入故障等级', trigger: 'blur'}
          ],
          ffaultContent: [
            {required: true, message: '请输入故障内容', trigger: 'blur'}
          ],
          fhandlMethod: [
            {required: true, message: '请输入处理方法', trigger: 'blur'}
          ],
          fcause: [
            {required: true, message: '请输入产生原因', trigger: 'blur'}
          ],
        },
        batchNum: [],
        selectList: [],
        coulmns: [
          {prop: 'feqmModeNo', label: '设备类型代码'},
          {prop: 'ffaultCode', label: '故障码'},
          {prop: 'ffaultLevel', label: '故障等级'},
          {prop: 'ffaultContent', label: '故障内容'},
          {prop: 'fhandlMethod', label: '处理方法'},
          {prop: 'fcause', label: '产生原因'}
        ]
      }

    },
    computed: {
      userName() {
        return JSON.parse(this.$store.state.user).username
      },
      downloadList() {
        if (this.batchNum.length === 0) {
          return this.selectList.map(item => {
            return item
          })
        } else {
          return this.batchNum.map(item => {
            return item
          })
        }
      }
    },
    created() {
      this.getAllPage()
      // this.getLevelAll()
      this.LevelForm.creator = this.userName
    },
    methods: {

      submitUpload() {
        this.importBtnDisabled = true
        this.loading = true
        this.$refs.upload.submit()
      },

      //上传成功
      fileUploadSuccess() {
        this.loading = false
        this.dialogImport = false
        this.$msg.success("添加成功")
        this.getAllPage()
      },
      //上传失败
      fileUploadError() {
        this.loading = false
        this.$msg.error("添加失败，请比对文件格式是否正确")
      },

      //下载模版
      dowtemplate() {
        const list = [
          {
          feqmModeNo: '设备类型代码',
          ffaultCode: '故障码',
          ffaultLevel: '故障等级',
          ffaultContent: '故障内容',
          fhandlMethod: '处理方法',
          fcause: '产生原因',
          }
        ]
        this.$download(list, this.coulmns, "故障基础信息")
      },
      //删除文件之前的钩子
      async beforeRemove(file) {
        return this.$MessageBox.confirm(`确定移除 ${file.name}？`)
      },
      //清空上传的文件
      closeLoad() {
        this.loading=false,
          this.$refs.upload.clearFiles();
      },

      async getAllPage() {
        const result = await faultBasePage(this.query.pageNum, this.query.pageSize, this.informationVo)
        console.log(result)
        if (result.data.code !== 20000)
          return this.$msg.error('获取信息失败!')
        this.tableData = result.data.data.rows.records
        this.total = result.data.data.rows.total
      },
      // async getLevels() {
      //   try {
      //     const data = {
      //       level: this.levelValue,
      //       levelName: this.levelNameValue,
      //       page: this.query.pageNum,
      //       size: this.query.pageSize
      //     }
      //     const res = await getLevelPage(data)
      //
      //     if (res.status !== 200) return this.$msg.error('获取故障等级信息失败')
      //     let newList = res.data.data
      //     this.tableData = newList.content
      //     this.totalPage = newList.totalElements
      //
      //
      //   } catch (error) {
      //
      //   }
      // },
      // async getLevelAll() {
      //   const result = await getLevel()
      //   this.selectList = result.data.data
      // },
      batchList(list) {
        this.batchNum = list
      },
      async excelAll() {
        const confirm = await this.$MessageBox.confirm('是否导出文件', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(e => e)
        if (confirm !== 'confirm') return
        this.$download(this.downloadList, this.coulmns, '故障基础信息')
      },
      //查询故障信息
      async searchLevel() {
        if (this.levelValue === '' && this.levelNameValue === '') return
        try {
          const data = {
            level: this.levelValue,
            levelName: this.levelNameValue
          }
          this.storage('faultMsg', data)
          data.page = this.query.resPageNum
          data.size = this.query.pageSize
          const res = await getLevelPage(data)
          if (res.status !== 200) return this.$msg.error('查询故障等级信息失败')
          let newList = res.data.data
          this.tableData = newList.content
          this.totalPage = newList.totalElements
          this.isPage = false
        } catch (error) {

        }

      },
      faultlevel() {
        const newList = JSON.parse(getItem('faultMsg'))
        if (newList && newList[1].level === this.levelValue && newList[0].index > 3) {
          this.levelValue = newList[1].level
          this.levelNameValue = newList[1].levelName
        }
      },
      resFuntion() {
        this.informationVo = {}
        this.isPage = true
        this.query = {
          pageNum: 1,
          pageSize: 20,
          resPageNum: 1
        }
        this.getAllPage()
      },
      renIndex(index) {
        return (this.query.pageNum - 1) * this.query.pageSize + index + 1
      },
      //新增故障等级
      addLevel() {
        this.$refs.levelForm.validate(async valid => {
          if (!valid) return this.$msg.error('新增故障等级信息不能为空!')
          let data = JSON.parse(JSON.stringify(this.LevelForm))
          try {
            if (this.isLevel) {
              //新增
              const res = await addFaultBase(data)
              console.log(res)
              if(res.data.code!=20000) return  this.$msg.error(res.data.message)
              this.addFaultLevel = false
              this.$msg.success('新增故障等级信息成功!')
              this.getAllPage()
            } else {
              //修改
              console.log(this.data)
              const res = await updateFaultBase(data)
              console.log(res)
              if(res.data.code!=20000) return this.$msg.error(res.data.message)
              this.addFaultLevel = false
              this.$msg.success('修改故障等级信息成功!')
              this.getAllPage()
            }
          } catch (error) {

          }
        })

      },
      //批量删除
      async removeAll() {
        if (this.batchNum.length === 0) return this.$msg.info('请选择需要删除的项')
        const config = await this.$MessageBox.confirm(
          "此操作将永久删除选中项, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }
        ).catch(e => e);
        if (config !== "confirm") return;
        try {
          const list = this.batchNum.map(item => {
            return item.fid
          })
          const res = await deleteFaultBase(list)
          if (res.data.code !== 20000) return this.$msg.error('删除失败')
          this.tableData = this.tableData.filter((item, i) => {
            return item.id !== list[i]
          })
          this.$refs.equipTable.clearSelection()
          if (this.query.pageNum > 1 && this.tableData.length === 0) {
            this.query.pageNum = this.query.pageNum - 1
          }
          this.$msg.success('删除成功')
          this.getAllPage()
        } catch (e) {
          console.log(e);
        }
      },
      close() {
        this.$refs.levelForm.resetFields()
        this.LevelForm = {creator: this.userName}
        this.isLevel = true
      },
      compileFaul(data) {
        this.addFaultLevel = true
        this.isLevel = false
        this.LevelForm = JSON.parse(JSON.stringify(data))
      },
      cleanList() {
        this.$refs.msgLevel.clearSelection()
      },
      handleSizeChange(val) {
        this.query.pageSize = val
        if (this.isPage) {
          this.getAllPage()
        } else {
          this.getAllPage()
        }
      },
      handleCurrentChange(val) {
        this.query.pageNum = val
        if (this.isPage) {
          this.getAllPage()
        } else {
          this.query.resPageNum = val
          this.getAllPage()
        }
      }
    },
  }
</script>

<style lang='less' scoped>
  .el-row {
    margin: 15px 0;

    .el-col {
      margin-right: 10px;
    }
  }

  .el-select {
    width: 100%;
  }
</style>
